import { OrderDoc, OrderProduct, OrderStatusCode, ProductStateCode, UserDoc } from '@gooduncles/gu-app-schema';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { ColDef, ColGroupDef } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { Button, message, notification } from 'antd';
import { cloneDeep, partition, uniq, uniqBy } from 'lodash-es';
import { FC, useCallback, useMemo, useState } from 'react';
import { callSendKakaoAlimTalkForProductSoldout } from 'src/utils/firebase-callable';
import { v4 } from 'uuid';

import { orderStatusKr, senderKey } from 'src/lib/1/constant';
import { RESERVED_RECEIVE_HOUR, dateFormat06, getKakaoTalkRequestOptions, isHourBefore } from 'src/lib/1/date-util';
import { ProductSoldOutTemplate } from 'src/lib/1/schema-alimtalk-template';
import { errorObjectToString, formatNumber, promiseAllSettled } from 'src/lib/1/util';
import { KakaoAlimtalkRecipientItem } from 'src/lib/2/schema-nhn-notification';
import { SupplierDoc } from 'src/lib/3/schema-supplier';
import { getAlimtalkMessage, getUser, updateOrder } from 'src/lib/4/firebase-short-cut';
import { organizeKakaotalkResultMessage } from 'src/lib/4/nhn-notification-util';
import { ConsoleLogger } from 'src/lib/5/logger';
import { OrderWithStore, productVolumeChangeValidator } from 'src/lib/5/order-util';
import { onCellValueChangedWithUpdateForOrderProduct, onValueSetterWithValidation } from 'src/lib/6/ag-grid-util';

import useProduct from 'src/hooks/useProduct';

import ProductCellRenderer from 'src/components/Common/ProductCellRenderer';
import ProductStockRenderer from 'src/components/Common/ProductStockRenderer';

import classes from './OrderProductTable.module.scss';

import SoldOutCellRenderer from '../OrderTable/SoldOutCellRenderer/SoldOutCellRenderer';

type ProductWithOrder = OrderProduct & { order: OrderWithStore };

const logName = '주문 관리(상품 보기)';
const logger = ConsoleLogger.getInstance();
const onCellValueChangedForOrderProduct = onCellValueChangedWithUpdateForOrderProduct(logName);

const defaultColDef: ColDef<ProductWithOrder> = {
  sortable: true,
  resizable: true,
  filter: true,
};

/**
 * 선택가능한 주문 상품 판별
 */
const isRowSelectable = (params: any) => {
  const { data } = params;
  const { order, state } = data as ProductWithOrder;
  // 품절된 상품은 선택할 수 없다.
  if (state === ProductStateCode.OUTOFSTOCK) {
    return false;
  }
  // 주문이 취소되거나 거절된 경우에는 선택할 수 없다.
  return ![OrderStatusCode.REJECTED, OrderStatusCode.CANCELED].includes(order.orderStatus);
};

/**
 * 상품 그룹 행의 스타일 지정
 */
const rowClassRules = {
  'order-product-group-row': (params: any) => params.node.group,
};

/**
 * 동일한 상품의 그룹행 설정
 * {@link https://www.ag-grid.com/react-data-grid/row-selection/#example-group-selection}
 */
const autoGroupColumnDef = {
  headerName: '상품',
  field: 'productId',
  minWidth: 320,
  cellRenderer: 'agGroupCellRenderer',
  cellRendererParams: {
    checkbox: true,
  },
  valueGetter: (params: any) => {
    const { fullName, productId } = (params?.data as ProductWithOrder) ?? {};
    return `${productId} - ${fullName}`;
  },
  filterParams: {
    valueFormatter: (params: any) => {
      const { value } = params;
      return value;
    },
  },
};

const getRecipientItem = (item: {
  user: UserDoc;
  orderDate: string;
  soldOutProductNames: string;
}): KakaoAlimtalkRecipientItem<ProductSoldOutTemplate> => ({
  recipientNo: item.user.userTel,
  templateParameter: {
    date: dateFormat06(new Date(item.orderDate)),
    product:
      item.soldOutProductNames.length < 14
        ? item.soldOutProductNames
        : item.soldOutProductNames.split(', ').length + '개의 상품',
  },
});

/** 주문 완료된 상품(Accepted, Delivered)을 품절 처리한다. */
const setProductsSoldOut = async (productWithOrderList: ProductWithOrder[]) => {
  // 순수하지 않은 로직이 있으므로 복제본을 사용한다.
  const productWithOrderListClone = cloneDeep(productWithOrderList);
  const targetProductIds = uniq(productWithOrderListClone.map((productWithOrder) => productWithOrder.productId));
  // 1. 변경해야하는 주문들을 추려낸다.
  const targetOrders: OrderDoc[] = uniqBy(
    productWithOrderListClone.map((productWithOrder) => {
      const {
        order: { store, productCount, ...orderDoc },
        ...orderProduct
      } = productWithOrder;
      orderProduct;
      store;
      productCount;
      return orderDoc;
    }),
    '_id'
  );
  const ordersBefore = cloneDeep(targetOrders);

  // 2. 주문들을 업데이트한다. (에러 확인을 위해 for문 사용)
  for (const productWithOrder of productWithOrderListClone) {
    const {
      order: { _id: orderId },
      productId,
    } = productWithOrder;
    const targetOrder = targetOrders.find((order) => order._id === orderId);
    if (!targetOrder) {
      throw new Error('주문 정보를 찾지 못한 상품이 있습니다.');
    }

    const orderProduct = targetOrder.products.find((product) => product.productId === productId);
    if (orderProduct) {
      orderProduct.state = ProductStateCode.OUTOFSTOCK;
      orderProduct.volume = 0;
    }
  }

  // 3. 변경된 주문들의 가격을 다시 계산한다.
  targetOrders.forEach((order) => {
    const totalAmount = order.products.reduce((acc, cur) => acc + cur.price * cur.volume, 0);
    const grandTotal = totalAmount + (order.deliveryFee ?? 0);
    order.totalAmount = totalAmount;
    order.paidAmount = grandTotal;
    order.grandTotal = grandTotal;
  });

  // 4. 주문 업데이트, 로그 기록
  const promises = targetOrders.map(async (order) => {
    try {
      const before = ordersBefore.find((o) => o._id === order._id);
      if (!before) {
        throw new Error(`주문 ${order._id}의 변경 전 주문 정보를 찾지 못했습니다.`);
      }
      const soldOutProducts = order.products.filter(
        // 현재 품절 처리하는 상품만 필터링한다.
        (product) => targetProductIds.includes(product.productId) && product.state === ProductStateCode.OUTOFSTOCK
      );
      const soldOutProductNames = soldOutProducts.map((product) => product.fullName).join(', ');
      await updateOrder(order._id, order);
      logger.logOrder('주문 상품 품절 처리', {
        orderId: order._id,
        storeId: order.storeId,
        before,
        after: order,
        reason: '품절 처리',
        reasonForUser: `구매하신 ${soldOutProductNames}의 재고가 없어 품절 처리되었습니다. 양해 부탁드립니다.`,
      });

      notification.success({
        message: '주문 상품 품절 처리 완료',
        description: `총 금액: ${before.totalAmount} -> ${order.totalAmount}`,
      });

      const user = await getUser(order.userId);
      if (!user?.userTel) {
        throw new Error(
          `품절 메시지를 받을 수신자 정보를 찾지 못했습니다.\n주문: ${order._id}, 주문자: ${order.userId}`
        );
      }
      return {
        user,
        orderDate: order.orderDate,
        soldOutProductNames,
      };
    } catch (error: any) {
      throw new Error(`주문 ${order._id}의 상품을 품절 처리하는 중에 오류가 발생했습니다.\n${error.message}`);
    }
  });
  const result = await promiseAllSettled(promises);

  // 5. 예약발송과 즉시발송건을 구분해야하는 시간인지 확인한다.
  const needToSeparateReceivers = isHourBefore(RESERVED_RECEIVE_HOUR);
  if (needToSeparateReceivers) {
    const [reserved, immediate] = partition(result.fulfilled, (item) => item.user.disableNotificationAtDawn);
    const reservedReceiverList = reserved.map((item) => getRecipientItem(item));
    const immediateReceiverList = immediate.map((item) => getRecipientItem(item));

    // 6. 즉시발송건을 발송한다.
    if (immediateReceiverList.length > 0) {
      sendSoldOutProducteMessage(immediateReceiverList);
    }
    // 7. 예약발송건을 발송한다.
    if (reservedReceiverList.length > 0) {
      const requestDate = getKakaoTalkRequestOptions();
      sendSoldOutProducteMessage(reservedReceiverList, requestDate);
    }
  } else {
    // 6. 품절된 상품 정보 카톡 발송
    sendSoldOutProducteMessage(result.fulfilled.map((item) => getRecipientItem(item)));
  }
};

/**
 * 품절된 상품에 대한 메시지를 발송한다.
 * @param user
 * @param orderDate 주문일
 * @param soldOutProductNames 품절된 상품명
 */
const sendSoldOutProducteMessage = async (
  recipientList: KakaoAlimtalkRecipientItem<ProductSoldOutTemplate>[],
  requestDate?: string
) => {
  const uiMessageKey = v4();
  message.open({
    type: 'loading',
    content: `카톡 메시지 ${requestDate ? '⏰ 예약' : '즉시'}발송 요청을 진행합니다...`,
    duration: 0,
    key: uiMessageKey,
  });

  try {
    if (!senderKey) {
      throw new Error('카카오 알림톡 발송을 위한 senderKey가 설정되지 않았습니다.');
    }

    const result = await callSendKakaoAlimTalkForProductSoldout({
      senderKey,
      templateCode: 'sold-out-product',
      recipientList,
      ...(requestDate ? { requestDate } : {}),
    });
    // 요청이 완료되면 로딩 창을 닫는다.
    message.destroy(uiMessageKey);

    if (result.data.result !== 'success') {
      throw new Error(result.data.reason);
    }

    if (!result.data.alimtalkMessageDocId) {
      throw new Error('요청은 성공했으나, ID를 받지 못했습니다.');
    }

    const alimtalkMessageDoc = await getAlimtalkMessage<ProductSoldOutTemplate>(result.data.alimtalkMessageDocId);
    if (!alimtalkMessageDoc) {
      throw new Error('요청은 성공했으나, 메시지 정보를 받지 못했습니다.');
    }

    // 요청 결과를 메시지로 정리한다.
    const description = organizeKakaotalkResultMessage(alimtalkMessageDoc.recipientWithResultList);

    notification.success({
      message: `메시지 ${requestDate ? '⏰ 예약' : '즉시'}발송 요청 완료`,
      description,
      className: 'pre-line-notification',
    });
  } catch (error) {
    console.error(error);
    message.destroy(uiMessageKey);
    const description = errorObjectToString(error);
    notification.error({
      message: `메시지 ${requestDate ? '⏰ 예약' : '즉시'}발송 요청 실패`,
      description,
    });
  }
};

const ProductTotalAmountRenderer: FC<{ productId: string; volume: number; sum: number }> = ({
  productId,
  volume,
  sum,
}) => {
  const { product, productLoading } = useProduct(productId);

  if (productLoading) {
    return <div>isLoading</div>;
  }

  return (
    <span>
      {formatNumber(sum)} ({formatNumber((product?.price ?? 0) * volume)})
    </span>
  );
};

interface OrderProductTableProps {
  rowData: OrderWithStore[];
  suppliers: SupplierDoc[] | undefined;
}

/**************************************************************************************
 * OrderProductTable
 * 주문 상품 목록을 표시한다.
 **************************************************************************************/
const OrderProductTable: FC<OrderProductTableProps> = ({ rowData, suppliers }) => {
  const [gridRef, setGridRef] = useState<AgGridReact<ProductWithOrder> | null>(null);
  const [areRowsSelected, setAreRowsSelected] = useState(false);
  const flatListForProduct = rowData
    .map((order) => order.products.map((product) => ({ ...product, order })))
    .flat()
    .sort((a, b) => (b.productId < a.productId ? 1 : b.productId === a.productId ? 0 : -1));

  const columnDefs: (ColDef<ProductWithOrder> | ColGroupDef<ProductWithOrder>)[] = useMemo(
    () => [
      {
        headerName: '상품',
        field: 'productId',
        minWidth: 120,
        rowGroup: true,
        hide: true,
        valueFormatter: (params: any) => {
          const { value, node } = params;
          const fullName = node?.childrenAfterGroup?.[0].data?.fullName;
          return `${value} - ${fullName}`;
        },
      },
      {
        headerName: '매장명(앱)',
        field: 'order.store.storeName',
        minWidth: 160,
        hide: true,
      },
      {
        headerName: '주문상태',
        field: 'order.orderStatus',
        maxWidth: 120,
        valueFormatter: ({ value }) => {
          return orderStatusKr[value];
        },
      },
      {
        headerName: '매장명(관리)',
        field: 'order.store.storeNickname',
        minWidth: 160,
      },
      { headerName: '상품명', field: 'fullName', minWidth: 220, hide: true },
      {
        headerName: '가격',
        field: 'snapshotPrice',
        width: 120,
        valueGetter: (params) => {
          const { price, snapshotPrice } = (params?.data as ProductWithOrder) ?? {};
          return snapshotPrice ?? price ?? 0;
        },
        cellRenderer: (params: any) => {
          const isGroup = params.node.group;
          const productId = params.node.key;
          return isGroup ? <ProductCellRenderer productId={productId} field='price' /> : formatNumber(params.value);
        },
      },
      {
        headerName: '주문 후 가격 변동',
        field: 'priceDiff',
        width: 150,
        valueGetter: (params) => {
          const { priceDiff } = (params?.data as ProductWithOrder) ?? {};
          return priceDiff ?? 0;
        },
        valueFormatter: ({ value }) => `${value > 0 ? '+' : ''}${formatNumber(value ?? 0)}`,
        cellRenderer: (params: any) => {
          const isGroup = params.node.group;
          return isGroup ? null : params.value;
        },
      },
      {
        headerName: '배송 담당자',
        field: 'order.deliveryPartnerName',
        width: 120,
        valueGetter: (params: any) => {
          const isGroup = params.node.group;
          const { order } = (params.data as ProductWithOrder) ?? {};
          return isGroup
            ? uniq(
                params.node.allLeafChildren.map(
                  (node: any) => (node.data as ProductWithOrder).order.deliveryPartnerName ?? '미할당'
                )
              ).join(', ')
            : order?.deliveryPartnerName ?? '미할당';
        },
      },
      {
        headerName: '매입처',
        field: 'suppliers',
        valueGetter: (params: any) => {
          const isGroup = params.node.group;
          const suppliers = (params.context.suppliers ?? []) as SupplierDoc[];
          const supplierId = !isGroup ? params.value?.[0] : params.node.allLeafChildren[0].data.suppliers[0];
          const supplier = suppliers.find((s) => s._id === supplierId);
          return supplier?.name;
        },
      },
      {
        headerName: '재고',
        field: 'stock',
        cellRenderer: (params: any) => {
          const productId = params.node.key;
          const isGroup = params.node.group;
          if (!isGroup) {
            return null;
          }
          const aggData = params.node.aggData;
          return <ProductStockRenderer productId={productId} volume={aggData.volume} />;
        },
      },
      {
        headerName: '*수량',
        field: 'volume',
        aggFunc: 'sum',
        minWidth: 100,
        maxWidth: 160,
        editable: true,
        valueFormatter: (params) => formatNumber(params.value),
        valueSetter: (params) =>
          onValueSetterWithValidation(params, ['order._id'], productVolumeChangeValidator(params), {
            type: 'number',
          }),
        onCellValueChanged: onCellValueChangedForOrderProduct,
      },
      {
        headerName: '총액',
        field: 'totalAmount',
        maxWidth: 160,
        aggFunc: 'sum',
        valueGetter: (params) => {
          const { price, snapshotPrice, volume } = (params.data as ProductWithOrder) ?? {};
          return (snapshotPrice ?? price ?? 0) * (volume ?? 0);
        },
        cellRenderer: (params: any) => {
          const isGroup = params.node.group;
          const productId = params.node.key;
          if (!isGroup) {
            return formatNumber(params.value);
          }
          const aggData = params.node.aggData;
          return <ProductTotalAmountRenderer productId={productId} volume={aggData.volume} sum={aggData.totalAmount} />;
        },
      },
      {
        headerName: '품절 처리',
        maxWidth: 160,
        cellRenderer: SoldOutCellRenderer,
      },
    ],
    []
  );

  const onSelectionChanged = useCallback((event: any) => {
    const rowCount = event.api.getSelectedNodes().length;
    setAreRowsSelected(rowCount > 0);
  }, []);

  const collapseAll = useCallback(() => {
    gridRef?.api.collapseAll();
  }, [gridRef]);

  const expandAll = useCallback(() => {
    gridRef?.api.expandAll();
  }, [gridRef]);

  /**
   * 선택된 상품들을 품절 처리한다.
   */
  const setSelectedProductsToSoldOut = useCallback(() => {
    const selectedRows = gridRef?.api.getSelectedRows();
    if (!selectedRows) {
      notification.error({ message: '품절 처리할 상품을 선택해주세요.' });
      return;
    }

    setProductsSoldOut(selectedRows);
  }, [gridRef?.api]);

  return (
    <div className={`ag-theme-alpine ${classes.orderProductTable}`}>
      <div className={`gridHeader ${classes.tableHeader}`}>
        <Button onClick={expandAll}>하위 항목 모두 열기</Button>
        <Button onClick={collapseAll}>하위 항목 모두 닫기</Button>
        <Button danger type='primary' onClick={setSelectedProductsToSoldOut} disabled={!areRowsSelected}>
          선택 상품 품절 처리
        </Button>
      </div>
      <AgGridReact
        ref={setGridRef}
        rowData={flatListForProduct}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        rowSelection={'multiple'}
        onSelectionChanged={onSelectionChanged}
        context={{ suppliers }}
        // 품절 처리를 위한 체크박스의 활성화 여부를 결정한다.
        isRowSelectable={isRowSelectable}
        groupSelectsChildren={true}
        suppressRowClickSelection={true}
        suppressAggFuncInHeader={true}
        autoGroupColumnDef={autoGroupColumnDef}
        rowClassRules={rowClassRules}
        rowStyle={{ color: 'var(--gray800)' }}
      />
    </div>
  );
};

export default OrderProductTable;
