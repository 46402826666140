import { message, notification } from 'antd';
import { Timestamp, deleteField } from 'firebase/firestore';
import { get, set } from 'lodash-es';

import { errorObjectToString } from 'src/lib/1/util';
import { createDailyProductSync, updateDailyProductSync } from 'src/lib/4/firebase-short-cut';

const removeNewFromField: Record<string, string> = {
  newCost: 'cost',
  newPrice: 'price',
};

/**
 * 일일 상품 시세 valueSetter for ag-grid
 */
export const dailyProductSyncValueSetter = (params: any) => {
  const field = params.colDef.field;
  if (!field) return false;
  const newValue = params.newValue;
  // 값이 없는 경우 삭제 처리를 위해 undefined를 넣어준다.
  if (newValue == null) {
    set(params.data, field, undefined);
    return true;
  }
  const toNumber = Number(newValue);
  if (!isNaN(toNumber)) {
    set(params.data, field, toNumber);
    return true;
  }
  message.error('값이 올바르지 않습니다.');
  return false;
};

/**
 * 일일 상품 시세 onCellValueChanged for ag-grid
 */
export const onCellValueChanged = async (params: any, date: string) => {
  const field = params.colDef.field;
  if (!field) return;
  const id = params.data.product._id;
  const newValue = get(params.data, field);
  const syncField = removeNewFromField[field];
  const { existedDailyProductSync } = params.context;
  try {
    if (existedDailyProductSync === undefined) {
      throw new Error('dailyProductSync is undefined');
    }
    if (!existedDailyProductSync) {
      await createDailyProductSync(date, {
        [id]: {
          [syncField]: newValue ? newValue : deleteField(),
          updatedAt: Timestamp.now(),
        },
      });
      message.success('일일 상품 시세 생성 완료');
      return;
    } else {
      await updateDailyProductSync(date, {
        [`${id}.${syncField}`]: newValue ? newValue : deleteField(),
        [`${id}.updatedAt`]: Timestamp.now(),
      });
      message.success('일일 상품 시세 변경 완료');
      return;
    }
  } catch (error) {
    const description = errorObjectToString(error);
    notification.error({
      message: '일일 상품 시세 변경 실패',
      description,
    });
  }
};
