import { OrderStatusCode } from '@gooduncles/gu-app-schema';
import { FC, useEffect, useState } from 'react';
import { combineLatest, map } from 'rxjs';
import { InvoiceItem } from 'src/schema/schema-invoice';
import { generateDeliveryItemsForStore, groupingDeliveryItemsByPartner } from 'src/utils/packing-sheet-util';

import { dayjsesToStringDateWhere, formatDate } from 'src/lib/1/date-util';
import { observeOrder, observeStoreIssue } from 'src/lib/4/firebase-short-cut';

import { selectPartnersUsers } from 'src/redux/slices/partners';
import { useAppSelector } from 'src/redux/store';

import useDeliveryRouteHistoryForDate from 'src/hooks/useDeliveryRouteHistoryForDate';
import useStores from 'src/hooks/useStores';
import { useTitle } from 'src/hooks/useTitle';

import classes from './InvoiceDashboard.module.scss';

import DatePickerWithArrows from '../Common/DatePickerWithArrows/DatePickerWithArrows';
import Loading from '../Loading/Loading';
import PrintWindow from '../PrintWindow/PrintWindow';
import Invoice from './InvoiceTable/Invoice';
import InvoiceTable from './InvoiceTable/InvoiceTable';

/**
 * 거래명세서는 주문이 수락된 후 배송 담당자에게 나눠줘야 하기 때문에
 * 출력할 때 담당자별로 정렬돼서 출력돼야 한다.
 * 때문에, 거래명세서를 출력하기 위해서는 배송 동선이 생성되어 있어야 한다.
 */
const InvoiceDashboard: FC = () => {
  useTitle('GU 관리자 | 거래명세서');
  const { date, setDate, dateForWhere, deliveryRouteHistory } = useDeliveryRouteHistoryForDate();
  const partnersUsers = useAppSelector(selectPartnersUsers);
  const stores = useStores();
  const [invoiceList, setInvoiceList] = useState<InvoiceItem[]>([]);
  const [selectedInvoiceList, setSelectedInvoiceList] = useState<InvoiceItem[]>([]);

  useEffect(() => {
    if (date && stores && deliveryRouteHistory && partnersUsers) {
      setSelectedInvoiceList([]);
      const [leftDate, rightDate] = dayjsesToStringDateWhere(date, date);
      const issueDate = formatDate(dateForWhere, "yyyy-MM-dd'T'00:00:00+0900");
      const acceptedOrdersObservable = observeOrder([['orderStatus', '==', OrderStatusCode.ACCEPTED]]);
      const deliveredOrdersObservable = observeOrder([['orderStatus', '==', OrderStatusCode.DELIVERED]], {
        sortKey: 'deliveredAt',
        orderBy: 'desc',
        startValue: leftDate,
        endValue: rightDate,
      });
      const storeIssuesObservable = observeStoreIssue([['date', '==', issueDate]]).pipe(
        map((issues) =>
          issues
            .filter((issue) => issue.isDeleted !== true)
            .filter((issue) => issue.message && issue.status && ['완료', '입력'].includes(issue.status))
        )
      );

      const subscription = combineLatest([
        storeIssuesObservable,
        acceptedOrdersObservable,
        deliveredOrdersObservable,
      ]).subscribe(([storeIssues, acceptedOrders, deliveredOrders]) => {
        const deliveryItemsForStore = generateDeliveryItemsForStore(
          stores,
          [...acceptedOrders, ...deliveredOrders],
          storeIssues
        );

        // 당일 배송 동선에 기록되지 않은 주문은 여기에 포함되지 않는다.
        const { date, deliverySpots } = deliveryRouteHistory;
        const groupByPartnerId = groupingDeliveryItemsByPartner(deliverySpots, deliveryItemsForStore);

        const sortedDeliverySpots = Object.entries(groupByPartnerId)
          .map(([partnerId, deliverySpots]) => {
            const partner = partnersUsers.find((partner) => partner._id === partnerId);
            return { partner, deliverySpots };
          })
          .sort(
            (a, b) => (a.partner?.deliveryDriverStatus.sortKey ?? 0) - (b.partner?.deliveryDriverStatus.sortKey ?? 0)
          );

        const dateKr = formatDate(date, 'yyyy년 L월 dd일');
        setInvoiceList(
          sortedDeliverySpots.flatMap(({ partner, deliverySpots }) => {
            // 담당자의 배송지 목록 -> 배송지 목록의 매장 목록
            return deliverySpots
              .map((spot) =>
                spot.deliveryStoresWithItems.map((item) => ({
                  ...item,
                  partnerName: partner?.name ?? '미할당',
                  sortKey: partner?.deliveryDriverStatus?.sortKey ?? 0,
                  date: dateKr,
                }))
              )
              .flat();
          })
        );
      });

      return () => subscription.unsubscribe();
    } else {
      setInvoiceList([]);
    }
  }, [dateForWhere, deliveryRouteHistory, date, stores, partnersUsers]);

  if (stores === undefined) {
    return (
      <div>
        <Loading title='매장 정보를 불러오는 중입니다.' />
      </div>
    );
  }

  return (
    <div className={classes.invoiceDashboardContainer}>
      <DatePickerWithArrows date={date} setDate={setDate} />
      <div className={classes.dashboardContent}>
        <InvoiceTable rowData={invoiceList} setSelectedRows={setSelectedInvoiceList} />
        <PrintWindow>
          {selectedInvoiceList.map((invoiceItem, idx) => {
            return <Invoice key={`invoiceItem-${idx}`} invoiceItem={invoiceItem} />;
          })}
        </PrintWindow>
      </div>
    </div>
  );
};

export default InvoiceDashboard;
