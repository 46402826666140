import { ProductStockChangeType, ProductStockHistoryDoc } from '@gooduncles/gu-app-schema';
import { Button, Popconfirm, notification } from 'antd';
import { FC, useState } from 'react';

import { getOrderDate } from 'src/lib/1/date-util';
import { errorObjectToString } from 'src/lib/1/util';
import { FirebaseManager } from 'src/lib/3/firebase-manager';
import { cancelProductStockHistory, createProductStockHistory, updateProduct } from 'src/lib/4/firebase-short-cut';
import { ConsoleLogger } from 'src/lib/5/logger';

const logger = ConsoleLogger.getInstance();
const logName = '재고 관리';
const firebaseManager = FirebaseManager.getInstance();

interface CancelStockHistoryButtonProps {
  stockHistory: ProductStockHistoryDoc;
  currentStock: number;
  userEmail: string;
}

const CancelStockHistoryButton: FC<CancelStockHistoryButtonProps> = ({ stockHistory, currentStock, userEmail }) => {
  const [loading, setLoading] = useState(false);

  const onCancelStockHistory = async () => {
    setLoading(true);
    try {
      if (!['OUTBOUND', 'INBOUND'].includes(stockHistory.type)) {
        throw new Error('취소할 수 없는 내역입니다.');
      }
      if (currentStock === Infinity) {
        throw new Error('현재 재고가 무제한인 경우 내역을 취소할 수 없습니다.');
      }
      const cancelStock = stockHistory.stock * -1;
      const newStock = currentStock + cancelStock;
      if (newStock <= 0) {
        throw new Error('재고가 0 이하가 될 수 없습니다.');
      }
      const newType: ProductStockChangeType = stockHistory.type === 'OUTBOUND' ? 'CANCEL_OUTBOUND' : 'CANCEL_INBOUND';
      firebaseManager.batchStart();
      await updateProduct(stockHistory.productId, { stock: newStock }, true);
      await createProductStockHistory(
        {
          timestamp: getOrderDate(),
          productId: stockHistory.productId,
          userEmail,
          stock: cancelStock,
          beforeStock: currentStock,
          afterStock: newStock,
          type: newType,
          reason: 'cancel',
          requestId: null,
          relatedHistoryId: stockHistory._id,
          // 이 내역은 취소된 내역이 아닌 취소후 추가되는 내역이므로 false
          canceled: false,
        },
        true
      );
      // 원본 내역을 취소 처리
      await cancelProductStockHistory(stockHistory._id, true);
      await firebaseManager.batchEnd();
      notification.success({
        message: '재고 변동 내역을 취소했습니다.',
      });
    } catch (error) {
      console.error(error);
      const description = errorObjectToString(error);
      logger.logConsole(`${logName} - ${description}`, {
        level: 'error',
      });
      notification.error({
        message: '재고 변동 내역 취소에 실패했습니다.',
        description,
      });
    }
    setLoading(false);
  };

  return (
    <Popconfirm
      title='취소하시겠습니까?'
      onConfirm={onCancelStockHistory}
      okButtonProps={{ loading }}
      cancelButtonProps={{ loading }}>
      <Button size='small' danger>
        취소
      </Button>
    </Popconfirm>
  );
};

export default CancelStockHistoryButton;
