import {
  CallCreateInvoiceDetailRequest,
  CallCreateInvoiceDetailResponse,
  HyosungCMSMember,
  HyosungCMSMemberDoc,
  Invoice,
  InvoiceDoc,
  OrderDoc,
  PaymentItemDoc,
  PaymentMethod,
  PaymentStatusCode,
  PaymentVendor,
  SettlementResult,
  SettlementResultDoc,
  StoreDoc,
  StoreIssue,
  StoreIssueDoc,
} from '@gooduncles/gu-app-schema';
import { addDays } from 'date-fns';
import { Timestamp } from 'firebase/firestore';
import { partition, sumBy } from 'lodash-es';
import {
  HyosungCMSMemberRawData,
  HyosungCMSTransactionRawData,
  KbBankTransactionRawData,
} from 'src/schema/schema-raw-payment-item';
import { v4 } from 'uuid';

import { formatDate } from 'src/lib/1/date-util';
import { encodeBase64, encryptAES } from 'src/lib/1/util';
import { jsonToExcelFile } from 'src/lib/1/xlsx-util';
import { FirebaseManager } from 'src/lib/3/firebase-manager';
import {
  createSettlementResult,
  getStoreWithWhere,
  updateInvoice,
  updateOrder,
  updatePaymentItem,
  updateStore,
  updateStoreIssue,
} from 'src/lib/4/firebase-short-cut';
import { calculatePreTaxPrice } from 'src/lib/5/order-util';

const firebaseManager = FirebaseManager.getInstance();

const KBBANK_IGNORE_PAYER_KEYWORDS = ['카드자동집금', '실시간집금'];
const HYOSUNG_PAYMENT_STATUS_MAP = {
  결제완료: PaymentStatusCode.COMPLETED,
  결제실패: PaymentStatusCode.FAILED,
  결제취소: PaymentStatusCode.CANCELLED,
};

/**
 * 국민은행의 거래내역을 결제내역으로 변환하는 함수
 */
export const kbTransToPaymentItems = (rawData: KbBankTransactionRawData[], createdBy: string): PaymentItemDoc[] => {
  const filteredData = rawData.filter((data) => !KBBANK_IGNORE_PAYER_KEYWORDS.includes(data['보낸분/받는분']));
  return filteredData
    .map((data) => {
      const amount = parseInt(data['입금액(원)'].replace(/,/g, ''));
      const transactionDate = formatDate(data['거래일시'], "yyyy-MM-dd'T'HH:mm:ss+0900");
      const paymentStatus = PaymentStatusCode.COMPLETED;
      const paymentMethod: PaymentItemDoc['paymentMethod'] = '계좌이체';
      return {
        // 타입을 맞추기 위한 임시 값으로 update과정에서 실제 값으로 대체된다.
        _id: v4(),
        _timeCreate: Timestamp.now(),
        createdBy,
        storeId: null,
        storeName: null,
        paymentStatus,
        paymentMethod,
        amount,
        supplyPrice: amount,
        tax: 0,
        paymentVendor: '국민은행' as PaymentVendor,
        payerInfo: data['보낸분/받는분'],
        extraInfo: `[${data['처리점']}] ${data['거래일시']}`,
        transactionDate,
        settledAt: null,
        settlementResultId: null,
        deletedAt: null,
      };
    })
    .sort((a, b) => a.transactionDate.localeCompare(b.transactionDate));
};

/**
 * 효성CMS의 거래내역을 결제내역으로 변환하는 함수
 * TODO: 매장정보 스태틱으로
 */
export const hyosungCmsTransToPaymentItems = async (
  rawData: HyosungCMSTransactionRawData[],
  createdBy: string
): Promise<PaymentItemDoc[]> => {
  // '결제실패', '결제취소'인 경우 청구완납일자가 없기 때문에 같은 '결제일'정보의 '청구완납일자'를 찾아서 사용한다.
  const transactionDateMap = Object.fromEntries(
    rawData
      .filter((data) => data?.청구완납일자?.length > 0)
      .map((data) => [data['결제일(납부기간)'], data.청구완납일자])
  );

  const paymentItems: PaymentItemDoc[] = [];
  for (const data of rawData) {
    const completedDate = transactionDateMap[data['결제일(납부기간)']];
    const transactionDate = formatDate(
      // '청구완납일자'가 없으면 같은 '결제일(납부기간)'을 갖는 다른 데이터에서 가져온다. 이마저도 없으면 '결제일(납부기간)'을 사용한다.
      completedDate?.length > 0
        ? completedDate
        : transactionDateMap[data['결제일(납부기간)']] ?? data['결제일(납부기간)'],
      "yyyy-MM-dd'T'HH:mm:ss+0900"
    );
    const paymentStatus = HYOSUNG_PAYMENT_STATUS_MAP[data['결제상태']] ?? 0;
    const paymentMethod = data['결제수단'];
    const amount =
      paymentStatus === PaymentStatusCode.CANCELLED
        ? Number(data['취소금액'])
        : paymentStatus === PaymentStatusCode.FAILED
        ? Number(data['미납금액'])
        : Number(data['수납금액']);
    const store = (await getStoreWithWhere([['storeCode', '==', data['회원번호']]]))?.[0] ?? null;
    const storeId = store?._id ?? null;
    const storeName = store?.storeNickname ?? null;

    paymentItems.push({
      // 타입을 맞추기 위한 임시 값으로 update과정에서 실제 값으로 대체된다.
      _id: v4(),
      _timeCreate: Timestamp.now(),
      createdBy,
      storeId,
      storeName,
      paymentStatus,
      paymentMethod,
      amount,
      supplyPrice: Number(data['공급가액']),
      tax: Number(data['부가세']),
      paymentVendor: '효성CMS',
      payerInfo: `[${data['회원번호']}] ${data['회원명']}`,
      extraInfo: `[${data['계약번호']} ${data['상품']}] ${data['청구완납일자'] ?? 'fail'} ${data['결제결과']}`,
      transactionDate,
      settledAt: null,
      settlementResultId: null,
      deletedAt: null,
    });
  }
  return paymentItems;
};

/**
 * 효성CMS의 회원정보 엑셀파일을 {@link HyosungCMSMember 회원정보}로 변환하는 함수
 */
export const hyosungCMSMemberExcelToMember = (rawData: HyosungCMSMemberRawData[]): HyosungCMSMember[] => {
  return rawData
    .map((data) => {
      const no = Number(data['NO.']);
      const productAmount = Number(data['상품금액합']);
      return {
        storeCode: data['회원번호'],
        no,
        contractNumber: data['계약번호'],
        name: data['회원명'],
        phoneNumber: data['납부자 휴대전화'],
        userStatus: data['회원상태'] as HyosungCMSMember['userStatus'],
        contractStatus: data['계약상태'] as HyosungCMSMember['contractStatus'],
        paymentDueDate: data['약정일'],
        paymentWay: data['결제방식'] as HyosungCMSMember['paymentWay'],
        paymentMethod: data['결제수단'] as HyosungCMSMember['paymentMethod'],
        paymentInfo: data['결제정보'],
        paymentStatus: data['결제등록상태'] as HyosungCMSMember['paymentStatus'],
        agreement: data['동의여부'] as HyosungCMSMember['agreement'],
        product: data['상품목록'],
        productAmount,
        contractStartDate: data['청구시작일'],
        contractEndDate: data['청구종료일'],
        manager: data['담당관리자'],
        userType: data['회원구분'] as HyosungCMSMember['userType'],
        billingMode: data['청구자동생성'] as HyosungCMSMember['billingMode'],
        sendingMode: data['발송방식'] as HyosungCMSMember['sendingMode'],
      };
    })
    .sort((a, b) => a.no - b.no);
};

/**
 * - 단가에 대한 과세
 * : 합과세
 * 품목별로 합산
 * - 어떤 매장이 10개의 과세 상품을 구매한 것에 대해
청구서를 발행할때
10개의 가격을 모두 합산후 부가세를 계산해도 상관없나요?
 */

/**
 * 매장 이슈의 총액을 구합니다.((공급가액 + 부가세) * 수량)
 */
const sumStoreIssueAmount = (storeIssue: StoreIssue) => {
  const price = storeIssue.supplyPrice ? storeIssue.supplyPrice + (storeIssue.tax ?? 0) : null;
  const volume = storeIssue.volume ?? 1;
  return price ? price * volume : 0;
};

/**
 * 매장에 필요한 청구서를 생성합니다.
 */
export const getPaymentInvoicesForStore = (
  store: StoreDoc,
  settlementResult: SettlementResultDoc | undefined,
  orders: OrderDoc[],
  storeIssues: StoreIssueDoc[],
  hyosungCMSMember?: HyosungCMSMemberDoc
): Invoice | null => {
  /** 카드 결제인 경우 과세, 면세를 나눠서 계산해야한다. */
  const orderIds = orders.map((order) => order._id);
  const storeIssueIds = storeIssues.map((storeIssue) => storeIssue._id);
  const paymentInfo: Invoice['paymentInfo'] = {
    paymentVendor: store.paymentVendor,
    paymentMethod: store.paymentMethod,
    ...(hyosungCMSMember
      ? {
          name: hyosungCMSMember.name,
          storeCode: hyosungCMSMember.storeCode,
          contractNumber: hyosungCMSMember.contractNumber,
          paymentDueDate: hyosungCMSMember.paymentDueDate,
        }
      : {}),
  };

  const invoiceBase = {
    date: formatDate(new Date(), "yyyy-MM-dd'T'HH:mm:ss+0900"),
    storeId: store._id,
    orderIds,
    storeIssueIds,
    prevSettlementResultId: settlementResult ? settlementResult._id : null,
    prevUnpaidAmount: settlementResult ? settlementResult.unpaidAmount : 0,
    settledAt: null,
    settlementResultId: null,
    deletedAt: null,
    paymentInfo,
  };

  // 과세와 면세 항목을 구분한다.
  const [taxExemptOrderProducts, taxableOrderProducts] = partition(
    orders.flatMap((order) => order.products),
    (product) => product.taxFree
  );
  const [taxableStoreIssues, taxExemptStoreIssues] = partition(
    storeIssues,
    (storeIssue) => storeIssue.tax && storeIssue.tax !== 0
  );
  // 배송비는 과세 품목이다.
  const deliveryFee = orders.reduce((acc, order) => acc + (order.deliveryFee ?? 0), 0);

  // 면세 항목의 합계를 구한다.
  // 미납금의 경우 효성의 결제내역만으로 미납한 과세, 면세금액을 구분하기 어렵다.
  // 때문에 우선 면세로 일괄 처리한다.
  const taxExemptSettlementResultsAmount = settlementResult?.unpaidAmount ?? 0;
  const taxExemptOrderProductsAmount = sumBy(
    taxExemptOrderProducts,
    (product) => (product.snapshotPrice ?? product.price) * product.volume
  );
  const taxExemptStoreIssuesAmount = sumBy(taxExemptStoreIssues, sumStoreIssueAmount);
  const taxExemptAmount = taxExemptSettlementResultsAmount + taxExemptOrderProductsAmount + taxExemptStoreIssuesAmount;

  // 과세 항목의 합계를 구한다.
  const taxableOrderProductsAmount = sumBy(
    taxableOrderProducts,
    (product) => (product.snapshotPrice ?? product.price) * product.volume
  );
  const taxableStoreIssuesAmount = sumBy(taxableStoreIssues, sumStoreIssueAmount);
  const taxableAmount = taxableOrderProductsAmount + taxableStoreIssuesAmount + deliveryFee;

  // 전체 합계를 구한다.
  const totalAmount = taxExemptAmount + taxableAmount;
  // TODO: 할인 정책이 만들어지면 추가 작업 필요
  // if (미수금 === 0 & 할인 총액 === 0)
  if (totalAmount === 0) {
    return null;
  }

  return {
    ...invoiceBase,
    invoiceAmount: totalAmount,
    taxExemptAmount,
    taxableAmount,
  };
};

/**
 * 효성CMS 청구내역을 다운로드한다.
 */
export const downloadHyosungCmsTransactions = (
  rowData: {
    invoice: Invoice;
    hyosungCMSMember: HyosungCMSMemberDoc;
  }[]
) => {
  const now = new Date();
  const month = formatDate(now, 'yyyyMM');
  /**
   * 돈이 실제로 빠져나가는 결제일은 당일로 지정할 수 없으며, 주말 또는 휴일이 포함되어 있어도 안되는 것으로 보인다.
   * 우선 이전 결제 담당자(Joseph)이 7일 후로 지정한 것을 따른다.
   */
  const paymentStartDate = formatDate(addDays(now, 7), 'yyyyMMdd');
  const data = rowData
    .map((item) => {
      const { invoice, hyosungCMSMember } = item;
      const paymentItems = [];
      if (hyosungCMSMember.paymentMethod === '카드') {
        if (invoice.taxExemptAmount > 0) {
          paymentItems.push({
            product: '카드면세',
            amount: invoice.taxExemptAmount,
          });
        }
        if (invoice.taxableAmount > 0) {
          paymentItems.push({
            product: '카드과세',
            amount: invoice.taxableAmount,
          });
        }
      } else {
        // if (invoice.invoiceAmount > 100 * 10000) {
        // TODO: 필요하다면 100만원 이상의 청구서를 분할한다.
        // }
        paymentItems.push({
          product: '기본상품01',
          amount: invoice.invoiceAmount,
        });
      }
      return paymentItems.map((paymentItem) => ({
        청구타입: '추가청구',
        회원번호: hyosungCMSMember.storeCode,
        계약번호: hyosungCMSMember.contractNumber,
        회원명: hyosungCMSMember.name,
        청구월: month,
        약정일: hyosungCMSMember.paymentDueDate,
        '결제일(결제시작일)': paymentStartDate,
        상품: paymentItem.product,
        기본금액: paymentItem.amount,
        수량: 1,
      }));
    })
    .flat();
  jsonToExcelFile(data, `효성CMS_청구내역_${month}`);
};

/**
 * 효성CMS에서 사용하는 상품명을 반환한다.
 */
export const getHyosungProductName = (paymentMethod: PaymentMethod | null, invoice: Invoice) => {
  if (paymentMethod === '카드') {
    const hasTaxExemptProduct = invoice.taxExemptAmount > 0;
    const hasTaxableProduct = invoice.taxableAmount > 0;
    return `카드 ${hasTaxExemptProduct ? '면세' : ''}${hasTaxExemptProduct && hasTaxExemptProduct ? '+' : ''}${
      hasTaxableProduct ? '과세' : ''
    }`;
  }
  return '기본상품01';
};

/**
 * 복수의 결재내역 합계를 구한다.
 */
export const sumPaymentItems = (paymentItems: PaymentItemDoc[]) => {
  const filtered = paymentItems.filter((item) => item.paymentStatus === PaymentStatusCode.COMPLETED);
  const amount = sumBy(filtered, (item) => item.amount);
  const supplyPrice = sumBy(filtered, (item) => item.supplyPrice);
  const tax = sumBy(filtered, (item) => item.tax);
  return {
    amount,
    supplyPrice,
    tax,
  };
};

type InvoiceWithPaymentItems = InvoiceDoc & {
  paymentItems: PaymentItemDoc[];
};
/**
 * 청구서와 결제내역을 정산한 결과를 생성한다.
 */
const createSettlementForInvocies = (items: InvoiceWithPaymentItems[]) => {
  const settledAt = formatDate(new Date(), "yyyy-MM-dd'T'HH:mm:ss+0900");

  const settlementItems: SettlementResult[] = items.map((item) => {
    const storeId = item.storeId;
    const invoiceId = item._id;
    const invoiceAmount = item.invoiceAmount;
    // 청구 금액
    const paymentItemIds = item.paymentItems.map((paymentItem) => paymentItem._id);
    if (item.paymentItems.length === 0) {
      return {
        storeId,
        settledAt,
        invoiceId,
        paymentItemIds,
        invoiceAmount,
        paidSupplyPrice: 0,
        paidTax: 0,
        unpaidAmount: invoiceAmount,
        invoiceIdForUnpaid: null,
        deletedAt: null,
      };
    }
    const { amount, supplyPrice, tax } = sumPaymentItems(item.paymentItems);
    // 결제 내역의 공급가 합계
    const paidSupplyPrice = supplyPrice;
    // 결제 내역의 부가세 합계
    const paidTax = tax;
    // 결제 내역의 결제 금액 합계
    const paidAmount = amount;
    // 미납금
    const unpaidAmount = invoiceAmount - paidAmount;
    return {
      storeId,
      invoiceId,
      invoiceAmount,
      paymentItemIds,
      settledAt,
      paidSupplyPrice,
      paidTax,
      unpaidAmount,
      invoiceIdForUnpaid: null,
      deletedAt: null,
    };
  });
  return settlementItems;
};

/**
 * 청구서를 정산한다.(정산결과를 생성한다.)
 * 1. 정산결과 생성
 * 2. 청구서 & 결제내역에 정산결과 추가
 * 3. 매장에 정산결과 추가
 */
export const settleInvoiceWithBatch = async (items: InvoiceWithPaymentItems[]) => {
  const settlementItems = createSettlementForInvocies(items);

  // 정산 단위별로 batch를 진행한다.
  for (const settlementItem of settlementItems) {
    firebaseManager.batchStart();
    const { invoiceId, settledAt } = settlementItem;
    // 1. 정산결과 생성
    const settlementResultId = await createSettlementResult(settlementItem);
    // 2. 청구서 & 결제내역에 정산결과 추가
    await updateInvoice(invoiceId, { settlementResultId, settledAt });
    for (const paymentItemId of settlementItem.paymentItemIds) {
      await updatePaymentItem(paymentItemId, { settlementResultId, settledAt });
    }
    // 3. 매장에 정산결과 추가
    await updateStore(settlementItem.storeId, { latestSettledAt: settledAt });
    await firebaseManager.batchEnd();
  }
  return;
};

/**
 * 청구서를 삭제한다.
 */
export const deleteInvociesWithBatch = async (items: InvoiceWithPaymentItems[]): Promise<string | undefined> => {
  const [unsettledInvoices, settledInvoices] = partition(items, (item) => item.settlementResultId === null);
  firebaseManager.batchStart();
  for (const invoice of unsettledInvoices) {
    const { _id, orderIds, storeIssueIds } = invoice;
    // 1. 청구서 삭제
    await updateInvoice(_id, { deletedAt: formatDate(new Date(), "yyyy-MM-dd'T'HH:mm:ss+0900") }, true);
    // 2. 주문 & 매장이슈의 청구서 정보 삭제
    for (const orderId of orderIds) {
      await updateOrder(orderId, { invoiceId: null }, true);
    }
    for (const storeIssueId of storeIssueIds) {
      await updateStoreIssue(storeIssueId, { invoiceId: null }, true);
    }
  }
  await firebaseManager.batchEnd();
  if (settledInvoices.length > 0) {
    return `정산된 청구서는 삭제할 수 없습니다.\n삭제 불가 목록: ${settledInvoices.map((item) => item._id).join(', ')}`;
  }
};

/**
 * 청구서 id를 url로 사용하는 경우를 위해 암호화합니다.
 */
export const encryptInvoiceId = (invoiceId: string) => {
  const encrypted = encryptAES(invoiceId);
  const base64 = encodeBase64(encrypted);
  const urlSafeEncrypted = encodeURIComponent(base64);
  return urlSafeEncrypted;
};

/**
 * 고객향 청구서 상세내역을 생성합니다.
 */
export const callCreateInvoiceDetail = firebaseManager.getCallable<
  CallCreateInvoiceDetailRequest,
  CallCreateInvoiceDetailResponse
>('callCreateInvoiceDetail');

/**
 * 주문과 매장이슈를 통일된 형태로 변환합니다.
 */
export const mergeOrderAndStoreIssue = (orders: OrderDoc[], storeIssues: StoreIssueDoc[]) => {
  // 통일 규격을 위해 주문의 항목을 공급가와 부가세로 나눈다.
  const orderItems = orders.map((order) => {
    const [taxExemptOrderProducts, taxableOrderProducts] = partition(order.products, (product) => product.taxFree);
    // 면세 항목의 합계
    const taxExemptAmount = sumBy(
      taxExemptOrderProducts,
      (product) => (product.snapshotPrice ?? product.price) * product.volume
    );
    // 과세 항목의 합계
    const deliveryFee = order.deliveryFee ?? 0;
    const taxableAmount =
      sumBy(taxableOrderProducts, (product) => (product.snapshotPrice ?? product.price) * product.volume) + deliveryFee;
    const { price: supplyPrice, tax } = calculatePreTaxPrice(taxableAmount);
    // 공급가액 = 면세 항목의 합계 + 과세 항목의 공급가 합계
    return {
      _id: order._id,
      date: order.deliveredAt ?? 'missing',
      message: `품목수 ${order.products.length}개${deliveryFee > 0 ? ` +배송비` : ''}`,
      type: '주문',
      // 총금액
      amount: order.paidAmount,
      // 공급가액
      supplyPrice: supplyPrice + taxExemptAmount,
      // 부가세
      tax,
    };
  });

  const storeIssueItems = storeIssues.map((storeIssue) => {
    const amount = sumStoreIssueAmount(storeIssue);
    const { supplyPrice, tax, volume } = storeIssue;
    return {
      _id: storeIssue._id,
      date: storeIssue.date,
      // 만약 메시지가 없다면 에러인 경우다.
      message: storeIssue.message ?? 'error',
      type: '매장이슈',
      // 총금액
      amount,
      supplyPrice: (supplyPrice ?? 0) * (volume ?? 1),
      tax: (tax ?? 0) * (volume ?? 1),
    };
  });

  return [...orderItems, ...storeIssueItems];
};

/**
 * 결제방식에 대한 셀 스타일을 반환합니다.
 */
export const cellStyleForPaymentMethod = (params: any) => {
  if (params.value === '미정') return { backgroundColor: 'var(--orange100)' };
};
