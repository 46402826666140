import { CopyOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import { useCallback } from 'react';

export const CopyCellValueRenderer = (props: any) => {
  const value = props.value;
  const name = props.name;
  const copyData = useCallback(async () => {
    if (typeof value === 'string') {
      try {
        await navigator.clipboard.writeText(value);
        notification.success({
          message: `${name} 복사 완료`,
        });
      } catch (error) {
        console.error(error);
        notification.error({
          message: '복사 실패',
        });
      }
    }
  }, [name, value]);

  if (!value || !name) return null;
  return (
    <Button icon={<CopyOutlined />} onClick={copyData}>
      {props.colDef.headerName}
    </Button>
  );
};
