import { CloudUploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { ProductBinLocation } from '@gooduncles/gu-app-schema';
import { Button, Modal, notification } from 'antd';
import { FC, useEffect } from 'react';

import { errorObjectToString } from 'src/lib/1/util';
import { readExcelFile } from 'src/lib/1/xlsx-util';
import { updateProductBinLocation } from 'src/lib/4/firebase-short-cut';

import useExcelUploadModal from 'src/hooks/useExcelUploadModal';

import FileUploadArea from 'src/components/Common/FileUploadArea/FileUploadArea';

import classes from './UploadModal.module.scss';

import ProductBinLocationExcelTable, { ProductBinLocationData } from './ProductBinLocationExcelTable';

type ProductBinLocationExcelRow = {
  상품ID: string;
  구분: string;
  매입처: string;
  상품명: string;
  재고위치: string;
};

/**
 * 상품재고 위치 엑셀 파일을 읽어 객체 배열로 변환합니다.
 */
const onReadProductBinLocationExcelFile = async (file: File): Promise<ProductBinLocationData[] | null> => {
  try {
    const sheet = await readExcelFile<ProductBinLocationExcelRow>(file, true);
    if (!Object.keys(sheet[0]).some((key) => key.includes('상품ID'))) {
      throw new Error('상품ID 컬럼이 존재하지 않습니다.');
    }
    if (!Object.keys(sheet[0]).some((key) => key.includes('재고위치'))) {
      throw new Error('재고 위치 컬럼이 존재하지 않습니다.');
    }
    const data = sheet
      .map((row) => ({
        productId: row['상품ID'],
        supplierLabel: row['구분'],
        supplierName: row['매입처'],
        fullName: row['상품명'],
        binLocation: row['재고위치'],
      }))
      .filter((row) => {
        if (!row.productId || !row.binLocation) {
          return false;
        }
        if (row.productId.length === 0 || row.binLocation.length === 0) {
          return false;
        }
        return true;
      });
    return data;
  } catch (error) {
    console.error(error);
    const description = errorObjectToString(error);
    notification.error({
      message: '파일 업로드 실패',
      description,
    });
  }
  return null;
};

const onUploadProductBinLocation = async (locationList: ProductBinLocationData[]) => {
  const productBinLocation: ProductBinLocation['productBinLocation'] = Object.fromEntries(
    locationList.map((location) => [location.productId, location.binLocation])
  );
  try {
    await updateProductBinLocation({
      productBinLocation,
    });
    notification.success({
      message: '상품 재고 위치 업데이트 완료',
    });
  } catch (error) {
    console.error(error);
    const description = errorObjectToString(error);
    notification.error({
      message: '상품 재고 위치 업데이트 실패',
      description,
    });
  }
};

/**
 * 상품재고 위치 엑셀 파일 업로드 모달
 */
const ProductBinLocationUploadModal: FC = () => {
  const {
    open,
    data,
    loading,
    setLoading,
    fileToUpload,
    setFileToUpload,
    openModal,
    removeFile,
    closeModal,
    onSubmit,
    setDataFromExcel,
  } = useExcelUploadModal<ProductBinLocationData>(onUploadProductBinLocation);

  useEffect(() => {
    if (fileToUpload) {
      setLoading(true);
      onReadProductBinLocationExcelFile(fileToUpload)
        .then((data) => {
          console.log(data);
          setDataFromExcel(data);
        })
        .finally(() => setLoading(false));
    }
  }, [fileToUpload, setDataFromExcel, setLoading]);

  return (
    <>
      <Button type='primary' icon={<CloudUploadOutlined />} style={{ fontWeight: 600 }} onClick={openModal}>
        상품 재고위치 업로드
      </Button>
      <Modal
        title='상품 재고위치 업로드'
        open={open}
        onOk={onSubmit}
        onCancel={closeModal}
        okText='상품 재고위치 업로드(덮어쓰기)'
        cancelText='취소'
        width='1200px'
        maskClosable={false}
        keyboard={false}
        centered
        okButtonProps={{ loading, disabled: !data || data?.length === 0 }}
        styles={{ body: { height: 'calc(100vh - 154px)', overflow: 'scroll' } }}>
        {/* 파일 없음 */}
        {!fileToUpload && (
          <FileUploadArea
            fileToUpload={fileToUpload}
            setFile={setFileToUpload}
            subTitle='"상품ID"와 "재고위치"컬럼이 올바른 엑셀 파일을 업로드해주세요.'
          />
        )}
        {/* 파일 있음 */}
        {fileToUpload && (
          <div className={classes.uploadModalContainer}>
            <div className={classes.uploadFile}>
              <span>{fileToUpload.name}</span>
              <Button type='text' icon={<DeleteOutlined />} danger onClick={removeFile} />
            </div>
            <section className={classes.tableContainer}>
              <ProductBinLocationExcelTable rowData={data} />
            </section>
          </div>
        )}
      </Modal>
    </>
  );
};

export default ProductBinLocationUploadModal;
