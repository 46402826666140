import { Switch, notification } from 'antd';
import { FC, useCallback, useState } from 'react';

import { errorObjectToString } from 'src/lib/1/util';
import { ProductInfoForTag } from 'src/lib/3/schema-product-group-by-category';
import { mergeProductGroupForCategory } from 'src/lib/4/firebase-short-cut';
import { ConsoleLogger } from 'src/lib/5/logger';

const logger = ConsoleLogger.getInstance();
const logName = '업종 관리';

/**
 * 상품을 미노출 처리합니다.
 */
export const ToogleProductCell: FC<{
  context: { category: string };
  data: ProductInfoForTag & { mainTag: string };
  value: boolean;
}> = ({ context, data, value }) => {
  const [loading, setLoading] = useState(false);

  const handleSwitchChange = useCallback(
    async (value: boolean) => {
      setLoading(true);
      try {
        await mergeProductGroupForCategory(context.category, {
          mainTags: {
            [data?.mainTag]: {
              productsForMainTag: {
                [data?.productId]: {
                  hide: value,
                },
              },
            },
          },
        });
        logger.logConsole(
          `${logName} - ${context.category}/${data?.mainTag}/${data?.productId} ${value ? '미노출' : '노출'} 처리`
        );
      } catch (error) {
        console.error(error);
        const description = errorObjectToString(error);
        logger.logConsole(
          `${logName} - ${context.category}/${data?.mainTag}/${data?.productId} ${
            value ? '미노출' : '노출'
          } 처리 실패\nerror: ${description}`,
          {
            level: 'error',
          }
        );
        notification.error({
          message: `노출 여부 변경에 실패했습니다.`,
          description,
        });
      }
    },
    [context.category, data?.mainTag, data?.productId]
  );

  return <Switch loading={loading} checked={value} onChange={handleSwitchChange} />;
};
