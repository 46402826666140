import { CommerceConf } from '@gooduncles/gu-app-schema';
import { add, getHours } from 'date-fns';
import { useCallback } from 'react';
import useSWRImmutable from 'swr/immutable';

import { formatDate, getKoreaDate } from 'src/lib/1/date-util';
import { FirebaseManager } from 'src/lib/3/firebase-manager';
import { updateCommerceConf } from 'src/lib/4/firebase-short-cut';

const firebaseManager = FirebaseManager.getInstance();
const commerceConfPath = 'config/commerce';

const getCommerceConf = () => firebaseManager.getDoc<CommerceConf>(commerceConfPath);

const useCommerceConf = () => {
  const {
    data: commerceConf,
    mutate,
    isValidating: isConfLoading,
  } = useSWRImmutable(commerceConfPath, getCommerceConf, {
    errorRetryCount: 2,
  });

  const mutateCommerceConf = useCallback(
    async (data: Partial<CommerceConf>) => {
      if (!commerceConf) {
        console.error('commerceConf is not defined');
        return;
      }
      mutate(
        {
          ...commerceConf,
          ...data,
        },
        false
      );
      await updateCommerceConf(data);
      mutate();
    },
    [commerceConf, mutate]
  );

  /**
   * 현재 시간에 따라 대시보드의 기준 날짜를 정한다.(대게 10시 ~ 24시 사이인 경우 내일{배송일}, 그 외는 오늘의 날짜를 반환한다.)
   */
  const getDeliveryDateForNow = useCallback(() => {
    if (!commerceConf?.deliveryDateRules) {
      return null;
    }
    const now = getKoreaDate();
    const currentHour = getHours(now);
    const deliveryDateRules = commerceConf.deliveryDateRules;
    const minHour = 10;
    // 현재 시간이 10시 보다 작으면 오늘 날짜를, 10시 이상이면 배송일을 반환한다.
    if (currentHour < minHour) {
      return formatDate(now, 'yyyy-MM-dd');
    }
    const weekday = now.getDay();
    const deliveryDate = add(now, { days: deliveryDateRules[weekday] });
    return formatDate(deliveryDate, 'yyyy-MM-dd');
  }, [commerceConf?.deliveryDateRules]);

  /**
   * 주문일로부터 배송 날짜를 계산한다.
   */
  const getDeliveryDateForOrder = useCallback(
    (orderDate: string) => {
      if (!commerceConf?.deliveryDateRules) {
        return null;
      }
      const deliveryDateRules = commerceConf.deliveryDateRules;
      const orderDay = new Date(orderDate).getDay();
      const deliveryDay = deliveryDateRules[orderDay];
      return formatDate(add(new Date(orderDate), { days: deliveryDay }), 'yyyy-MM-dd');
    },
    [commerceConf?.deliveryDateRules]
  );

  return {
    commerceConf,
    isConfLoading,
    mutateCommerceConf,
    getDeliveryDateForNow,
    getDeliveryDateForOrder,
  };
};

export default useCommerceConf;
