import { StoreDoc, StoreIssueDoc, StoreIssueStatus } from '@gooduncles/gu-app-schema';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { ColDef, ColGroupDef, NewValueParams } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { Button } from 'antd';
import { FC, useCallback, useMemo, useState } from 'react';

import { dateFormat11, orderDateFormat03 } from 'src/lib/1/date-util';
import { getProductById, getSupplierById, updateDocWithCollection } from 'src/lib/4/firebase-short-cut';
import {
  getStoreIssueRowStyle,
  loadGridState,
  onCellDataDeleted,
  resetGridState,
  saveGridState,
  suppressEnterKeyEvent,
  timestampFormatter3,
} from 'src/lib/6/ag-grid-util';

import AlgoliaSearch from 'src/components/AlgoriaSearch/AlgoriaSearch';
import DateCellEditor from 'src/components/Common/CellEditor/DateCellEditor';
import InputCellEditor from 'src/components/Common/CellEditor/InputCellEditor';
import SelectCellEditor from 'src/components/Common/CellEditor/SelectCellEditor';
import DeleteDataCell from 'src/components/Common/DeleteDataCell/DeleteDataCell';
import DownloadRawDataCell from 'src/components/Common/DownloadRawDataCell/DownloadRawDataCell';

type StoreIssueTableProps = {
  rowData: StoreIssueDoc[];
  stores?: StoreDoc[];
  storeSearchValues: string[] | null;
};

const defaultColDef: ColDef<StoreIssueDoc> = {
  editable: true,
  resizable: true,
  sortable: true,
  filter: true,
};

const storeIssuePath = 'storeIssue';
const storeIssueTable = 'storeIssueTable';
const storeIssueCategories = [
  '배송',
  '회수',
  '식당환불',
  '매입처반품',
  '매입처매입',
  '주문수정',
  '매입자료수정',
  '매출자료수정',
  '기타',
  '주문',
  '쿠폰할인',
];
const storeIssueStatusOptions = ['완료', '입력', '보류'].map((status) => ({ value: status, label: status }));
const storeIssueCategoryOptions = storeIssueCategories.map((category) => ({ value: category, label: category }));

const onSelectValueChangeWapperForProduct = (id: string) => async (value: string) => {
  const product = value ? await getProductById(value) : null;
  if (!product) {
    throw new Error('상품을 찾을 수 없습니다.');
  }
  const supplierId = product?.suppliers?.[0] ?? null;
  const supplier = supplierId ? await getSupplierById(supplierId) : null;
  if (!supplier) {
    throw new Error('매입처를 찾을 수 없습니다.');
  }

  return updateDocWithCollection<StoreIssueDoc>(storeIssuePath, id, {
    productId: product.productId,
    productName: product.fullName,
    supplier: supplier.name,
  });
};

const onSelectValueChangeWapperForStore = (id: string, stores?: StoreDoc[]) => async (value: string) => {
  const store = stores?.find((store) => store.storeCode === value);
  if (!store) {
    throw new Error('가게를 찾을 수 없습니다.');
  }
  return updateDocWithCollection<StoreIssueDoc>(storeIssuePath, id, {
    storeCode: store.storeCode ?? null,
    storeName: store.storeNickname,
  });
};

const onSelectValueChangeWapperForStatus = (id: string) => async (status: StoreIssueStatus) => {
  return updateDocWithCollection<StoreIssueDoc>(
    storeIssuePath,
    id,
    status === '완료'
      ? {
          status,
          completedAt: orderDateFormat03(),
        }
      : {
          status,
        }
  );
};

const onCellValueDeleted = (params: NewValueParams<StoreIssueDoc>) => {
  const field = params.colDef.field;
  return field
    ? onCellDataDeleted<StoreIssueDoc>(params, () =>
        updateDocWithCollection<StoreIssueDoc>(storeIssuePath, params.data._id, {
          [field]: null,
        })
      )
    : null;
};

const onSelectValueChangeWapper =
  (id: string, field: string) =>
  (value: string, isNumber = false) =>
    updateDocWithCollection(storeIssuePath, id, {
      [field]: isNumber ? Number(value) : value,
    });

const numberCellEditor = (params: any) => {
  const {
    data: { _id },
    colDef: { field },
  } = params;
  return (
    <InputCellEditor
      isNumber={true}
      placeholder=''
      defaultValue={params.value}
      onValueChange={(value) => onSelectValueChangeWapper(_id, field)(value, true)}
      stopEditing={params.stopEditing as () => void}
    />
  );
};

const textCellEditor = (params: any) => {
  const {
    data: { _id },
    colDef: { field },
  } = params;
  return (
    <InputCellEditor
      placeholder=''
      defaultValue={params.value}
      onValueChange={(value) => onSelectValueChangeWapper(_id, field)(value)}
      stopEditing={params.stopEditing as () => void}
    />
  );
};

const dateCellEditor = (params: any) => {
  const {
    data: { _id },
    colDef: { field },
  } = params;
  return (
    <DateCellEditor
      defaultValue={params.value}
      onValueChange={(value) => onSelectValueChangeWapper(_id, field)(value)}
      stopEditing={params.stopEditing as () => void}
    />
  );
};

const StoreIssueTable: FC<StoreIssueTableProps> = ({ rowData, stores, storeSearchValues }) => {
  const [gridRef, setGridRef] = useState<AgGridReact<StoreIssueDoc> | null>(null);
  const columnDefs: (ColDef<StoreIssueDoc> | ColGroupDef<StoreIssueDoc>)[] = useMemo(
    () => [
      { field: '_id', hide: true },
      {
        field: '_timeUpdate',
        headerName: '최근 수정일',
        valueFormatter: timestampFormatter3,
        hide: true,
      },
      {
        field: 'date',
        headerName: '일자(배송일)',
        width: 120,
        cellEditorPopup: true,
        cellEditor: dateCellEditor,
        suppressKeyboardEvent: suppressEnterKeyEvent,
        valueFormatter: (params) => dateFormat11(params.value),
      },
      {
        field: 'category',
        headerName: '구분',
        cellEditorPopup: true,
        cellEditor: (params: any) => (
          <SelectCellEditor
            placeholder='구분'
            defaultValue={params.value}
            onValueChange={(value) => onSelectValueChangeWapper(params.data._id, params.colDef.field)(value)}
            stopEditing={params.stopEditing as () => void}
            options={storeIssueCategoryOptions}
          />
        ),
        suppressKeyboardEvent: suppressEnterKeyEvent,
        onCellValueChanged: onCellValueDeleted,
      },
      {
        field: 'storeCode',
        headerName: '식당코드',
        cellEditorPopup: true,
        cellEditor: (params: any) => (
          <SelectCellEditor
            placeholder='매장 선택'
            defaultValue={params.value}
            onValueChange={(value) => onSelectValueChangeWapperForStore(params.data._id, stores)(value)}
            stopEditing={params.stopEditing as () => void}
            options={(storeSearchValues ?? []).map((storeKey) => {
              const storeCode = storeKey.split('-')?.[0];
              return { label: storeKey, value: storeCode };
            })}
          />
        ),
        suppressKeyboardEvent: suppressEnterKeyEvent,
        onCellValueChanged: (params) =>
          onCellDataDeleted<StoreIssueDoc>(params, () =>
            updateDocWithCollection<StoreIssueDoc>(storeIssuePath, params.data._id, {
              storeCode: null,
              storeName: null,
            })
          ),
      },
      {
        field: 'storeName',
        headerName: '매장명',
        width: 150,
        cellEditorPopup: true,
        cellEditor: textCellEditor,
        suppressKeyboardEvent: suppressEnterKeyEvent,
        onCellValueChanged: onCellValueDeleted,
      },
      {
        field: 'productId',
        headerName: '상품코드',
        cellEditorPopup: true,
        cellEditor: (params: any) => (
          <AlgoliaSearch
            placeholder='상품 선택'
            onSearch={(value) => onSelectValueChangeWapperForProduct(params.data._id)(value)}
          />
        ),
        suppressKeyboardEvent: suppressEnterKeyEvent,
        onCellValueChanged: onCellValueDeleted,
      },
      {
        field: 'supplier',
        headerName: '매입처명',
        cellEditorPopup: true,
        cellEditor: textCellEditor,
        suppressKeyboardEvent: suppressEnterKeyEvent,
        onCellValueChanged: onCellValueDeleted,
      },
      {
        field: 'productName',
        headerName: '상품명',
        cellEditorPopup: true,
        cellEditor: textCellEditor,
        suppressKeyboardEvent: suppressEnterKeyEvent,
        onCellValueChanged: onCellValueDeleted,
      },
      {
        field: 'volume',
        headerName: '수량',
        cellEditorPopup: true,
        cellEditor: numberCellEditor,
        suppressKeyboardEvent: suppressEnterKeyEvent,
        onCellValueChanged: onCellValueDeleted,
      },
      {
        field: 'supplyPrice',
        headerName: '공급가',
        editable: true,
        cellEditorPopup: true,
        cellEditor: numberCellEditor,
        suppressKeyboardEvent: suppressEnterKeyEvent,
        onCellValueChanged: onCellValueDeleted,
      },
      {
        field: 'tax',
        headerName: '세액',
        editable: true,
        cellEditorPopup: true,
        cellEditor: numberCellEditor,
        suppressKeyboardEvent: suppressEnterKeyEvent,
        onCellValueChanged: onCellValueDeleted,
      },
      {
        field: 'memo',
        headerName: '특이사항(관리자)',
        minWidth: 160,
        cellEditorPopup: true,
        cellEditor: textCellEditor,
        suppressKeyboardEvent: suppressEnterKeyEvent,
        onCellValueChanged: onCellValueDeleted,
      },
      {
        field: 'message',
        headerName: '이슈내용(고객노출)',
        cellEditorPopup: true,
        cellEditor: textCellEditor,
        suppressKeyboardEvent: suppressEnterKeyEvent,
        onCellValueChanged: onCellValueDeleted,
      },
      {
        field: 'deliveryPartnerName',
        headerName: '파트너',
        width: 114,
      },
      {
        field: 'status',
        headerName: '진행상태',
        cellEditorPopup: true,
        cellEditor: (params: any) => (
          <SelectCellEditor
            defaultValue={params.value}
            placeholder='진행상태'
            onValueChange={(value) => onSelectValueChangeWapperForStatus(params.data._id)(value as StoreIssueStatus)}
            stopEditing={params.stopEditing as () => void}
            options={storeIssueStatusOptions}
          />
        ),
        suppressKeyboardEvent: suppressEnterKeyEvent,
        onCellValueChanged: onCellValueDeleted,
      },
      {
        field: 'completedAt',
        headerName: '완료일자',
        width: 114,
        cellEditorPopup: true,
        cellEditor: dateCellEditor,
        suppressKeyboardEvent: suppressEnterKeyEvent,
        valueFormatter: (params) => (params.value ? dateFormat11(params.value) : ''),
        onCellValueChanged: onCellValueDeleted,
      },
      {
        field: '_id',
        headerName: '삭제',
        cellRenderer: (params: any) => <DeleteDataCell collection='storeIssue' id={params.value} />,
      },
      {
        headerName: '배송 담당자',
        field: 'deliveryPartnerName',
        hide: true,
      },
      {
        headerName: '액션',
        cellRenderer: DownloadRawDataCell,
        hide: true,
      },
    ],
    [storeSearchValues, stores]
  );

  const autoSizeAll = useCallback(() => {
    gridRef?.columnApi?.autoSizeAllColumns(true);
    loadGridState(gridRef, storeIssueTable);
  }, [gridRef]);

  return (
    <div className='ag-theme-alpine' style={{ flex: 1 }}>
      <div className='tableButtons'>
        <Button onClick={() => saveGridState(gridRef, storeIssueTable)}>설정 저장</Button>
        <Button onClick={() => loadGridState(gridRef, storeIssueTable)}>설정 불러오기</Button>
        <Button onClick={() => resetGridState(gridRef, storeIssueTable)}>설정 초기화</Button>
      </div>
      <AgGridReact
        ref={setGridRef}
        onGridReady={autoSizeAll}
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        detailRowAutoHeight={true}
        suppressScrollOnNewData={true}
        getRowStyle={getStoreIssueRowStyle}
      />
    </div>
  );
};

export default StoreIssueTable;
