import { DeliverySpotDoc } from '@gooduncles/gu-app-schema';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { ColDef } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { FC, useEffect, useMemo, useState } from 'react';

interface DeliverySpotTableProps {
  selectedRowId: string | null;
  map: naver.maps.Map;
  triggerClickMarkerBySeq: (id: string) => void;
  rowData: DeliverySpotDoc[];
}

const defaultColDef: ColDef<DeliverySpotDoc> = {
  sortable: true,
  resizable: true,
  filter: true,
};

const DeliverySpotTable: FC<DeliverySpotTableProps> = ({ map, rowData, selectedRowId, triggerClickMarkerBySeq }) => {
  const [gridRef, setGridRef] = useState<AgGridReact<DeliverySpotDoc> | null>(null);

  const columnDefs: ColDef<DeliverySpotDoc>[] = useMemo(
    () => [
      {
        field: '_id',
        hide: true,
      },
      {
        headerName: '순서',
        field: 'sortKey',
        sort: 'asc',
        width: 110,
      },
      {
        headerName: '배송지 명',
        field: 'title',
      },
      {
        headerName: '지번 주소',
        field: 'jibunAddress',
      },
      {
        headerName: '도로명 주소',
        field: 'roadAddress',
        hide: true,
      },
      {
        headerName: '위도',
        field: 'lat',
      },
      {
        headerName: '경도',
        field: 'lng',
      },
    ],
    []
  );

  useEffect(() => {
    if (gridRef?.api) {
      gridRef.api.forEachNode((node) => {
        if (node.data?._id === selectedRowId) {
          node.setSelected(true);
          setTimeout(() => {
            gridRef.api.ensureNodeVisible(node);
          }, 200);
        }
      });
    }
  }, [gridRef, selectedRowId]);

  return (
    <div className='ag-theme-alpine height100'>
      <AgGridReact
        ref={setGridRef}
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        // rowId를 지정해주지 않으면 데이터 변경시 refresh가 발생한다.
        getRowId={(params) => params.data._id}
        // 편집 완료후 스크롤 이동을 막는다.
        suppressScrollOnNewData={true}
        rowSelection='single'
        // row selection을 활성화 시킨경우 아래 옵션이 없으면 row의 전체 내용을 copy한다.
        suppressCopyRowsToClipboard={true}
        onRowClicked={(params) => {
          if (params.node.data?._id && params.node.data?._id !== selectedRowId) {
            map.setCenter(new naver.maps.LatLng(params.node.data?.lat, params.node.data?.lng));
            triggerClickMarkerBySeq(params.node.data?._id);
          }
        }}
      />
    </div>
  );
};

export default DeliverySpotTable;
