import { InvoiceDetailDoc } from '@gooduncles/gu-app-schema';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { ColDef, ColGroupDef } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { FC, useMemo } from 'react';
import { encryptInvoiceId } from 'src/utils/payment-util';

import { isProduction } from 'src/lib/1/constant';
import { numberFormatter, onCellValueChangedWithUpdate, timestampFormatter } from 'src/lib/6/ag-grid-util';

import SyncMessageResultButton from 'src/components/AgGrid/CellRenderer/SyncMessageResultButton/SyncMessageResultButton';
import KakaotalkContentPopover from 'src/components/AlimtalkMessage/AlimtalkContentPopover/AlimtalkContentPopover';
import DownloadRawDataCell from 'src/components/Common/DownloadRawDataCell/DownloadRawDataCell';

const onCellValueChanged = onCellValueChangedWithUpdate<InvoiceDetailDoc>('청구서 고객용');

const defaultColDef = {
  sortable: true,
  resizable: true,
  filter: true,
};

interface InvoiceDetailTableProps {
  rowData: InvoiceDetailDoc[];
  setSelectedRows: React.Dispatch<React.SetStateAction<InvoiceDetailDoc[]>>;
}

const InvoiceDetailTable: FC<InvoiceDetailTableProps> = ({ rowData, setSelectedRows }) => {
  const columnDefs: (ColDef<InvoiceDetailDoc> | ColGroupDef<InvoiceDetailDoc>)[] = useMemo(
    () => [
      { field: '_id', hide: true },
      { headerName: '매장Id', field: 'storeId', hide: true },
      // { headerName: '상호/법인명', field: 'recipientInfo.businessName' },
      {
        headerName: '청구대상',
        field: 'recipientInfo.name',
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
      },
      {
        headerName: '생성시각',
        field: '_timeCreate',
        valueFormatter: timestampFormatter,
      },
      { headerName: '사업자등록번호', field: 'recipientInfo.businessNumber', hide: true },
      {
        headerName: '수신번호',
        field: 'recipientInfo.userTel',
        editable: true,
        onCellValueChanged: (params) => onCellValueChanged(params, 'invoiceDetail'),
      },
      {
        headerName: '미납금',
        field: 'prevSettlementResult.unpaidAmount',
        valueFormatter: numberFormatter,
        width: 120,
        type: 'numericColumn',
      },
      {
        headerName: '청구금액',
        field: 'invoiceAmount',
        valueFormatter: numberFormatter,
        width: 120,
        type: 'numericColumn',
      },
      {
        headerName: '면세',
        field: 'taxExemptAmount',
        valueFormatter: numberFormatter,
        width: 120,
        type: 'numericColumn',
        hide: true,
      },
      {
        headerName: '과세',
        field: 'taxableAmount',
        valueFormatter: numberFormatter,
        width: 120,
        type: 'numericColumn',
        hide: true,
      },
      {
        headerName: '요청결과',
        width: 120,
        valueGetter: ({ data }) => {
          const { alimtalkMessage } = data as InvoiceDetailDoc;
          if (alimtalkMessage?.result?.requestResult.result) {
            return alimtalkMessage.result.requestResult.result === 'success'
              ? '🟢 성공'
              : `❌ ${alimtalkMessage.result.requestResult.error}`;
          }
          return null;
        },
      },
      {
        headerName: '발송결과',
        width: 120,
        valueGetter: ({ data }) => {
          const { alimtalkMessage } = data as InvoiceDetailDoc;
          if (alimtalkMessage?.result?.messageResult?.resultCode) {
            return alimtalkMessage.result.messageResult.resultCode === '1000'
              ? '🟢 성공'
              : `❌ ${alimtalkMessage.result.messageResult.error}`;
          }
          return null;
        },
        cellRenderer: (props: any) => {
          const { value, data } = props;
          if (value) return value;
          const { alimtalkMessage } = data as InvoiceDetailDoc;
          if (alimtalkMessage) {
            const { alimtalkMessageDocId, requestId, recipientSeq, result } = alimtalkMessage;
            if (result?.messageResult) {
              return (
                <SyncMessageResultButton
                  type='alimtalk'
                  messageDocId={alimtalkMessageDocId}
                  requestId={requestId}
                  recipientSeq={recipientSeq}
                />
              );
            }
          }
          return '⚠️ 요청 결과 없음';
        },
      },
      {
        headerName: '발송내용',
        field: 'alimtalkMessage.result.messageResult.content',
        cellRenderer: KakaotalkContentPopover,
        cellRendererParams: (params: any) => {
          const { alimtalkMessage } = params.data as InvoiceDetailDoc;
          if (alimtalkMessage?.result?.messageResult?.buttons) {
            return {
              buttons: alimtalkMessage.result.messageResult.buttons,
            };
          }
        },
      },
      {
        headerName: '메시지',
        field: 'message',
        editable: true,
        cellEditor: 'agLargeTextCellEditor',
        cellEditorParams: {
          maxLength: 3000,
        },
        cellEditorPopup: true,
        onCellValueChanged: (params) => onCellValueChanged(params, 'invoiceDetail'),
      },
      {
        headerName: '바로가기',
        field: '_id',
        width: 140,
        cellRenderer: (params: any) => {
          const data = params.data as InvoiceDetailDoc;
          const encryptedInvoiceId = encryptInvoiceId(data._id);
          const base = isProduction ? 'https://app.gooduncles.com/invoice' : 'https://dev.app.gooduncles.com/invoice';
          return (
            <a href={`${base}/${encryptedInvoiceId}`} target='_blank' rel='noreferrer'>
              링크
            </a>
          );
        },
      },
      {
        headerName: '액션',
        minWidth: 120,
        cellRenderer: DownloadRawDataCell,
      },
    ],
    []
  );

  return (
    <div className='ag-theme-alpine height100'>
      <AgGridReact
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        // 행 선택을 허용한다.
        onSelectionChanged={(e) => setSelectedRows(e.api.getSelectedRows())}
        rowSelection={'multiple'}
      />
    </div>
  );
};

export default InvoiceDetailTable;
