import { SyncOutlined } from '@ant-design/icons';
import { Competitor } from '@gooduncles/gu-app-schema';
import { ColDef, ColGroupDef } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { Button, message, notification } from 'antd';
import { deleteField } from 'firebase/firestore';
import { FC, useMemo, useState } from 'react';
import { callSetProductWeeklyPrice } from 'src/utils/firebase-callable';
import { ProductCompetitorTrendTableRowData, competitorList } from 'src/utils/product-trend-util';

import { errorObjectToString, formatNumber } from 'src/lib/1/util';
import { updateProductCompetitorTrendKeywordMap } from 'src/lib/4/firebase-short-cut';
import { numberFormatter } from 'src/lib/6/ag-grid-util';

const SetProductWeeklyPriceRenderer = (value: number | null, productId: string, yearWeek: string) => {
  const [loading, setLoading] = useState<boolean>(false);

  const onClick = async () => {
    setLoading(true);
    try {
      await callSetProductWeeklyPrice({ productId, yearWeek });
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  // TODO: 자동화
  if (value === null || isNaN(value)) {
    return <Button icon={<SyncOutlined />} onClick={onClick} loading={loading} />;
  }
  return formatNumber(value, 0);
};

const defaultColDef = {
  sortable: true,
  resizable: true,
  filter: true,
};

interface ProductCompetitorTrendTableProps {
  rowData: ProductCompetitorTrendTableRowData[];
  weekList: { year: number; week: number }[];
  checkedCompetitors: Competitor[];
  showCompetitorProductName: boolean;
}

const ProductCompetitorTrendTable: FC<ProductCompetitorTrendTableProps> = ({
  rowData,
  weekList,
  checkedCompetitors,
  showCompetitorProductName,
}) => {
  const groupColumnDefs = useMemo(() => {
    return weekList.map(({ year, week }) => {
      const groupHeader = `${year}-${week}`;
      const childeren = checkedCompetitors.map((competitor) => {
        return {
          headerName: competitor,
          field: `groupColumnData.${groupHeader}.${competitor}`,
          width: 110,
          valueFormatter: numberFormatter,
          type: 'numericColumn',
        };
      });
      return {
        headerName: groupHeader,
        children: [
          ...childeren,
          {
            headerName: '이웃삼촌',
            field: `groupColumnData.${groupHeader}.이웃삼촌`,
            width: 110,
            type: 'numericColumn',
            cellRenderer: (params: any) => {
              const value = params.value;
              return SetProductWeeklyPriceRenderer(value, params.data.productId, groupHeader);
            },
          },
        ],
      };
    });
  }, [weekList, checkedCompetitors]);

  const columnDefs: (ColDef<ProductCompetitorTrendTableRowData> | ColGroupDef<ProductCompetitorTrendTableRowData>)[] =
    useMemo(
      () => [
        { field: 'productId', hide: true },
        {
          field: 'salesAmount',
          headerName: '판매량',
          width: 120,
          type: 'numericColumn',
          sort: 'desc',
          valueFormatter: numberFormatter,
        },
        { headerName: '상품명', field: 'productName', width: 240 },
        {
          headerName: '그룹키',
          field: 'keyword',
          width: 120,
          editable: true,
          onCellValueChanged: async (params) => {
            const { newValue, oldValue, data } = params;
            if (newValue === oldValue) return;
            const value = newValue;
            const { productId } = data;
            try {
              if (!value) {
                await updateProductCompetitorTrendKeywordMap(productId, deleteField());
                message.success('삭제완료.', 1);
              } else {
                await updateProductCompetitorTrendKeywordMap(productId, value);
                message.success('변경완료.', 1);
              }
            } catch (error) {
              console.error(error);
              const description = errorObjectToString(error);
              notification.error({
                message: '그룹키워드 변경에 실패했습니다.',
                description,
              });
            }
          },
        },
        {
          headerName: '경쟁사 상품명',
          field: 'competitorProductName',
          children: competitorList.map((competitor) => ({
            hide: !showCompetitorProductName,
            headerName: competitor,
            field: `competitorProductName.${competitor}`,
            width: 120,
          })),
        },
        ...groupColumnDefs,
      ],
      [groupColumnDefs, showCompetitorProductName]
    );
  return (
    <div className='ag-theme-alpine height100 width100'>
      <AgGridReact
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        // rowId를 지정해주지 않으면 데이터 변경시 refresh가 발생한다.
        getRowId={(params) => params.data.productId}
        // 편집 완료후 스크롤 이동을 막는다.
        suppressScrollOnNewData={true}
        // 행 선택을 켜놓더라도 개별 셀의 내용을 복사할 수 있도록한다.
        enableCellTextSelection={true}
      />
    </div>
  );
};

export default ProductCompetitorTrendTable;
