import { LoadingOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { FC, useState } from 'react';

import useDeliveryTask from 'src/hooks/useDeliveryTask';

import SubTitle from 'src/atomic-components/atoms/Content/SubTitle';
import PartnerInfoRow from 'src/atomic-components/atoms/PartnerInfoRow/PartnerInfoRow';
import TaskOrderInfoBox from 'src/atomic-components/atoms/TaskOrderInfoBox/TaskOrderInfoBox';

import classes from './EditTaskModal.module.scss';

import ChangeTaskPartnerPopover from '../ChangeTaskPartnerPopover/ChangeTaskPartnerPopover';
import TaskInfoBox from '../TaskInfoBox/TaskInfoBox';

interface EditDeliveryTaskModalProps {
  deliveryTaskId: string;
}

const type = 'delivery';

const EditDeliveryTaskModal: FC<EditDeliveryTaskModalProps> = ({ deliveryTaskId }) => {
  const [open, setOpen] = useState(false);
  const { deliveryTask, isLoading } = useDeliveryTask(open ? deliveryTaskId : undefined);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Button disabled={open} type='primary' onClick={handleOpen}>
        상세
      </Button>
      <Modal
        open={open}
        centered
        width='1200px'
        cancelText='닫기'
        styles={{
          content: {
            backgroundColor: 'var(--gray75)',
          },
          header: {
            fontSize: '18px',
            fontWeight: '700',
            fontFamily: 'Pretendard',
            backgroundColor: 'inherit',
          },
        }}
        destroyOnClose={true}
        okButtonProps={{ style: { display: 'none' } }}
        onCancel={handleClose}
        title={`배송 / ${deliveryTask?.date ?? '?'}`}>
        <div className={classes.modalContentContainer}>
          {isLoading ? (
            <LoadingOutlined />
          ) : (
            <>
              <section className={classes.leftSection}>
                <SubTitle
                  title='담당 파트너'
                  rightSection={deliveryTask ? <ChangeTaskPartnerPopover type={type} task={deliveryTask} /> : undefined}
                />
                <PartnerInfoRow partnerId={deliveryTask?.partnerId} />
                <TaskInfoBox type={type} task={deliveryTask} />
              </section>
              <section className={classes.rightSection}>
                {deliveryTask && (
                  <TaskOrderInfoBox
                    collection={deliveryTask.orderColleciton}
                    orderId={deliveryTask.orderId}
                    taskItems={deliveryTask.deliveryItems}
                  />
                )}
              </section>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default EditDeliveryTaskModal;
