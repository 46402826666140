import { DeleteOutlined } from '@ant-design/icons';
import {
  CJFreshwayProductListDoc,
  CompetitorProductMappingDoc,
  DabomFoodProductListDoc,
  DawnMarketProductListDoc,
  FoodpangProductListDoc,
  GarakMarketProductListDoc,
  GarakMarketProductPriceTrendDoc,
  ProductDoc,
  ProductState,
  SehyeonFnBProductListDoc,
  productStates,
} from '@gooduncles/gu-app-schema';
import { ColDef, ColGroupDef } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { Button, Popconfirm, notification } from 'antd';
import { FC, useCallback, useMemo, useState } from 'react';

import { errorObjectToString } from 'src/lib/1/util';
import { deleteCompetitorProductMapping, deleteGarakMarketProductPriceTrend } from 'src/lib/4/firebase-short-cut';
import { suppressEnterKeyEvent } from 'src/lib/6/ag-grid-util';

import {
  ProductCategoryCellRenderer,
  ProductStateCellRenderer,
} from '../Common/ProductCellRenderer/ProductCellRenderer';
import CompetitorProductSelector from '../Selector/CompetitorProductSelector';
import GarakMarketProductSelector from '../Selector/GarakMarketProductSelector';

export type ProductPriceMappingTableRowdata = ProductDoc & {
  garakMarket: GarakMarketProductPriceTrendDoc | null;
  dadomFoodMapping: CompetitorProductMappingDoc | null;
  CJFreshwayMapping: CompetitorProductMappingDoc | null;
  SehyeonFnBMapping: CompetitorProductMappingDoc | null;
  foodpangMapping: CompetitorProductMappingDoc | null;
  dawnMarketMapping: CompetitorProductMappingDoc | null;
};

/**
 * 가락시장 경매 가격 트렌드 또는 경쟁사 상품 맵핑을 삭제하는 버튼
 * !실제로 삭제한다.
 */
const RemoveMapping: FC<{ docId: string; target: 'garakMarket' | 'competitor' }> = ({ docId, target }) => {
  const [loading, setLoading] = useState(false);
  const removeMapping = useCallback(async () => {
    const targetName = target === 'garakMarket' ? '가락시장 상품' : '경쟁사 상품';
    try {
      setLoading(true);
      if (target === 'garakMarket') {
        await deleteGarakMarketProductPriceTrend(docId);
      } else {
        await deleteCompetitorProductMapping(docId);
      }
      notification.success({ message: `${targetName} 맵핑을 삭제했습니다.` });
    } catch (error) {
      console.error(error);
      const description = errorObjectToString(error);
      notification.error({ message: `${targetName} 매칭 삭제 실패.`, description });
    }
    setLoading(false);
  }, [docId, target]);

  return (
    <Popconfirm
      title='연결을 해제하시겠습니까?'
      onConfirm={removeMapping}
      okButtonProps={{ disabled: loading, loading }}
      okText='해제'
      cancelText='취소'>
      <Button icon={<DeleteOutlined />} size='small' type='primary' disabled={loading} danger />
    </Popconfirm>
  );
};

const defaultColDef = {
  sortable: true,
  resizable: true,
  filter: true,
  floatingFilter: true,
};

interface ProductPriceMappingTableProps {
  rowData: ProductPriceMappingTableRowdata[];
  garakMarketProductList: GarakMarketProductListDoc | null;
  dabomFoodProductList: DabomFoodProductListDoc | null;
  CJFreshwayProductList: CJFreshwayProductListDoc | null;
  sehyeonFnBProductList: SehyeonFnBProductListDoc | null;
  foodpangProductList: FoodpangProductListDoc | null;
  dawnMarketProductList: DawnMarketProductListDoc | null;
}

const ProductPriceMappingTable: FC<ProductPriceMappingTableProps> = ({
  rowData,
  garakMarketProductList,
  dabomFoodProductList,
  CJFreshwayProductList,
  sehyeonFnBProductList,
  foodpangProductList,
  dawnMarketProductList,
}) => {
  const columnDefs: (ColDef<ProductPriceMappingTableRowdata> | ColGroupDef<ProductPriceMappingTableRowdata>)[] =
    useMemo(
      () => [
        { field: '_id', hide: true },
        { headerName: '코드', field: 'productId', width: 98, filter: 'agTextColumnFilter' },
        {
          headerName: '품명',
          field: 'fullName',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: '*구분',
          field: 'categories',
          width: 108,
          cellRenderer: ProductCategoryCellRenderer,
        },
        {
          headerName: '*재고',
          field: 'state',
          width: 120,
          cellRenderer: ProductStateCellRenderer,
          filterParams: {
            valueFormatter: (params: any) => productStates[params.value as ProductState],
          },
        },
        {
          headerName: '가락시장 경매품명',
          field: 'garakMarket',
          width: 300,
          cellRenderer: (params: any) => {
            const { _id, garakMarket } = params.data as ProductPriceMappingTableRowdata;
            if (!garakMarket) return '';
            return (
              <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                <RemoveMapping docId={_id} target='garakMarket' />
                <span className='textOverFlow'>{garakMarket.fullName}</span>
              </div>
            );
          },
          editable: (params) => !params.data?.garakMarket,
          cellEditorPopup: true,
          suppressKeyboardEvent: suppressEnterKeyEvent,
          cellEditor: (params: any) => {
            const { _id } = params.data as ProductPriceMappingTableRowdata;
            const selectedProduct = params.value as ProductPriceMappingTableRowdata['garakMarket'];
            return (
              <GarakMarketProductSelector
                key={_id}
                garakMarketProductList={garakMarketProductList}
                productId={_id}
                garakMarketProduct={selectedProduct}
                stopEditing={params.stopEditing}
              />
            );
          },
        },
        {
          headerName: '다봄푸드(식봄)',
          field: 'dadomFoodMapping.productName',
          width: 300,
          cellRenderer: (params: any) => {
            const { dadomFoodMapping } = params.data as ProductPriceMappingTableRowdata;
            if (!dadomFoodMapping) return '';
            return (
              <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                <RemoveMapping docId={dadomFoodMapping._id} target='competitor' />
                <span className='textOverFlow'>{dadomFoodMapping.productName}</span>
              </div>
            );
          },
          editable: (params) => !params.data?.dadomFoodMapping,
          cellEditorPopup: true,
          suppressKeyboardEvent: suppressEnterKeyEvent,
          cellEditor: (params: any) => {
            const { _id } = params.data as ProductPriceMappingTableRowdata;
            const dabomFoodProductList = params.context.dabomFoodProductList;
            const productName = params.value as string | null;
            return (
              <CompetitorProductSelector
                key={_id + productName}
                competitor='다봄푸드'
                competitorProductList={dabomFoodProductList}
                productId={_id}
                selectedProductName={productName}
                stopEditing={params.stopEditing}
              />
            );
          },
        },
        {
          headerName: 'CJ프레시웨이(식봄)',
          field: 'CJFreshwayMapping.productName',
          width: 300,
          cellRenderer: (params: any) => {
            const { CJFreshwayMapping } = params.data as ProductPriceMappingTableRowdata;
            if (!CJFreshwayMapping) return '';
            return (
              <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                <RemoveMapping docId={CJFreshwayMapping._id} target='competitor' />
                <span className='textOverFlow'>{CJFreshwayMapping.productName}</span>
              </div>
            );
          },
          editable: (params) => !params.data?.CJFreshwayMapping,
          cellEditorPopup: true,
          suppressKeyboardEvent: suppressEnterKeyEvent,
          cellEditor: (params: any) => {
            const { _id } = params.data as ProductPriceMappingTableRowdata;
            const CJFreshwayProductList = params.context.CJFreshwayProductList;
            const productName = params.value as string | null;
            return (
              <CompetitorProductSelector
                key={_id + productName}
                competitor='CJ프레시웨이'
                competitorProductList={CJFreshwayProductList}
                productId={_id}
                selectedProductName={productName}
                stopEditing={params.stopEditing}
              />
            );
          },
        },
        {
          headerName: '세현F&B(식봄)',
          field: 'SehyeonFnBMapping.productName',
          width: 300,
          cellRenderer: (params: any) => {
            const { SehyeonFnBMapping } = params.data as ProductPriceMappingTableRowdata;
            if (!SehyeonFnBMapping) return '';
            return (
              <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                <RemoveMapping docId={SehyeonFnBMapping._id} target='competitor' />
                <span className='textOverFlow'>{SehyeonFnBMapping.productName}</span>
              </div>
            );
          },
          editable: (params) => !params.data?.SehyeonFnBMapping,
          cellEditorPopup: true,
          suppressKeyboardEvent: suppressEnterKeyEvent,
          cellEditor: (params: any) => {
            const { _id } = params.data as ProductPriceMappingTableRowdata;
            const sehyeonFnBProductList = params.context.sehyeonFnBProductList;
            const productName = params.value as string | null;
            return (
              <CompetitorProductSelector
                key={_id + productName}
                competitor='세현F&B'
                competitorProductList={sehyeonFnBProductList}
                productId={_id}
                selectedProductName={productName}
                stopEditing={params.stopEditing}
              />
            );
          },
        },
        {
          headerName: '푸드팡',
          field: 'foodpangMapping.productName',
          width: 300,
          cellRenderer: (params: any) => {
            const { foodpangMapping } = params.data as ProductPriceMappingTableRowdata;
            if (!foodpangMapping) return '';
            return (
              <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                <RemoveMapping docId={foodpangMapping._id} target='competitor' />
                <span className='textOverFlow'>{foodpangMapping.productName}</span>
              </div>
            );
          },
          editable: (params) => !params.data?.foodpangMapping,
          cellEditorPopup: true,
          suppressKeyboardEvent: suppressEnterKeyEvent,
          cellEditor: (params: any) => {
            const { _id } = params.data as ProductPriceMappingTableRowdata;
            const foodpangProductList = params.context.foodpangProductList;
            const productName = params.value as string | null;
            return (
              <CompetitorProductSelector
                key={_id + productName}
                competitor='푸드팡'
                competitorProductList={foodpangProductList}
                productId={_id}
                selectedProductName={productName}
                stopEditing={params.stopEditing}
              />
            );
          },
        },
        {
          headerName: '새벽시장',
          field: 'dawnMarketMapping.productName',
          width: 300,
          cellRenderer: (params: any) => {
            const { dawnMarketMapping } = params.data as ProductPriceMappingTableRowdata;
            if (!dawnMarketMapping) return '';
            return (
              <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                <RemoveMapping docId={dawnMarketMapping._id} target='competitor' />
                <span className='textOverFlow'>{dawnMarketMapping.productName}</span>
              </div>
            );
          },
          editable: (params) => !params.data?.dawnMarketMapping,
          cellEditorPopup: true,
          suppressKeyboardEvent: suppressEnterKeyEvent,
          cellEditor: (params: any) => {
            const { _id } = params.data as ProductPriceMappingTableRowdata;
            const dawnMarketProductList = params.context.dawnMarketProductList;
            const productName = params.value as string | null;
            return (
              <CompetitorProductSelector
                key={_id + productName}
                competitor='새벽시장'
                competitorProductList={dawnMarketProductList}
                productId={_id}
                selectedProductName={productName}
                stopEditing={params.stopEditing}
              />
            );
          },
        },
      ],
      [garakMarketProductList]
    );
  return (
    <div className='ag-theme-alpine height100 width100'>
      <AgGridReact
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        context={{
          dabomFoodProductList,
          CJFreshwayProductList,
          foodpangProductList,
          dawnMarketProductList,
          sehyeonFnBProductList,
        }}
        // rowId를 지정해주지 않으면 데이터 변경시 refresh가 발생한다.
        getRowId={(params) => params.data._id}
        // 편집 완료후 스크롤 이동을 막는다.
        suppressScrollOnNewData={true}
      />
    </div>
  );
};

export default ProductPriceMappingTable;
