import {
  BusinessInfoDoc,
  CJFreshwayProduct,
  CJFreshwayProductListDoc,
  CommerceConf,
  CompetitorProductMapping,
  CompetitorProductMappingDoc,
  CustomPromotionSection,
  CustomPromotionSectionDoc,
  DabomFoodProduct,
  DabomFoodProductListDoc,
  DailyIssue,
  DailyIssueDoc,
  DawnMarketProductDoc,
  DawnMarketProductListDoc,
  DeliveryCourier,
  DeliveryCourierDoc,
  DeliveryPerformanceDoc,
  DeliveryRouteHistory,
  DeliveryRouteHistoryDoc,
  DeliverySpot,
  DeliverySpotDoc,
  DeliveryTask,
  DeliveryTaskDoc,
  FoodpangProductDoc,
  FoodpangProductListDoc,
  GarakMarketProductListDoc,
  GarakMarketProductPriceTrend,
  GarakMarketProductPriceTrendDoc,
  GuProductPriceTrendDoc,
  HyosungCMSMember,
  HyosungCMSMemberDoc,
  Invoice,
  InvoiceDetailDoc,
  InvoiceDoc,
  InvoiceMessageMapDoc,
  MemoDoc,
  OrderDoc,
  OrderStatusCode,
  PartnersDeviceDoc,
  PartnersUserDoc,
  PartnersVehicle,
  PartnersVehicleDoc,
  PaymentItem,
  PaymentItemDoc,
  PickupTask,
  PickupTaskDoc,
  Product,
  ProductBinLocation,
  ProductBinLocationDoc,
  ProductCompetitorTrendKeywordMap,
  ProductDoc,
  ProductSnapshotDoc,
  ProductStockHistory,
  ProductStockHistoryDoc,
  ProductWeeklyPriceDoc,
  SehyeonFnBProduct,
  SehyeonFnBProductListDoc,
  SettlementResult,
  SettlementResultDoc,
  StoreDoc,
  StoreIssue,
  StoreIssueDoc,
  UserDoc,
} from '@gooduncles/gu-app-schema';
import { DeepPartial } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { format } from 'date-fns';
import { FieldValue, Timestamp } from 'firebase/firestore';
import { UploadMetadata } from 'firebase/storage';
import { uniqBy } from 'lodash-es';
import { DailyProductSync, DailyProductSyncDoc } from 'src/schema/schema-daily-product-sync';
import { ProductRequest, ProductRequestDoc } from 'src/schema/schema-product-request';
import { PromotionSection, PromotionSectionDoc } from 'src/schema/schema-promotion-section';

import { imageBaseUrl } from '../1/constant';
import { formatDate, getDateWithoutTime, orderDateFormat03 } from '../1/date-util';
import { CommerceIssue, DailyOrderStatsDoc, MonthlyOrderStatsForStoreDoc } from '../2/schema';
import { FirebaseManager, WHERE } from '../3/firebase-manager';
import { AlimtalkMessageDoc } from '../3/schema-alimtalk';
import { DeviceDoc } from '../3/schema-app';
import { InstallmentBill, InstallmentBillDoc } from '../3/schema-bill';
import { FriendtalkMessageDoc } from '../3/schema-friendtalk';
import { MobileMessageDoc } from '../3/schema-mobile-message';
import { Notification, NotificationDoc } from '../3/schema-notification';
import { ProductGroupForCategoryDoc } from '../3/schema-product-group-by-category';
import { PurchaseOrder, PurchaseOrderDoc } from '../3/schema-purchase-order';
import { Supplier, SupplierDoc } from '../3/schema-supplier';

export interface QueryOptions {
  sortKey: string;
  orderBy: 'asc' | 'desc';
  startValue?: any;
  endValue?: any;
}

const firebaseManager = FirebaseManager.getInstance();
export const batchStart = () => firebaseManager.batchStart();
export const batchEnd = () => firebaseManager.batchEnd();
const userPath = 'user';
const productPath = 'product';
const configPath = 'config';
const commerceIssuePath = 'commerceIssue';
const devicePath = 'device';
const notificationPath = 'notification';
const installmentBillPath = 'installmentBill';
const monthlyOrderStatsForStorePath = 'monthlyOrderStatsForStore';
const dailyOrderStatsPath = 'dailyOrderStats';

// alimtalk
const alimtalkMessagePath = 'alimtalkMessage';
// friendtalk
const friendtalkMessagePath = 'friendtalkMessage';

export function getCollectionWithWhere<T>(collection: string, where: WHERE[] = [], options?: QueryOptions) {
  return firebaseManager.getDocsArrayWithWhere<T>(collection, where, options);
}

export const observeProduct = (where: WHERE[] = [], options?: QueryOptions) =>
  firebaseManager.observeCollection<ProductDoc>(productPath, where, options);

/**
 * @returns last productId
 */
export const getLastProductId = async () => {
  const lastProduct = await firebaseManager.getDocsArrayWithWhere<ProductDoc>(productPath, [], {
    sortKey: '_id',
    orderBy: 'desc',
    limit: 1,
  });
  return lastProduct?.[0]?._id;
};

/**
 * @returns new productId incresed by 1 from last productId
 */
export const getNewProductId = async () => {
  const lastProductId = await getLastProductId();
  if (lastProductId) {
    const productIdRegex = /([a-zA-Z]+)([0-9]+)/;
    const lastProductIdMatch = lastProductId.match(productIdRegex);
    if (lastProductIdMatch) {
      return lastProductIdMatch[1] + `${parseInt(lastProductIdMatch[2]) + 1}`.padStart(5, '0');
    }
  }
  return `${lastProductId}-error`;
};

/**
 * 상품 생성 방식은 아래와 같다.
 * 같은 상품Id가 있는지 확인한다.
 *  있다 => 같은 productId가 있으면 덮어쓴다.
 *  없다 => 생성
 * @param data
 * @returns
 */
export const setProduct = async (data: Product, bBatch: boolean) => {
  try {
    // 업로드가 되면 안되는 필드를 제거한다.
    const { suppliers, stock, ...all } = data;
    suppliers;
    stock;
    const product: Partial<Product> = {
      ...all,
      createSnapshot: true,
    };
    return firebaseManager.setDoc(productPath, product.productId, product, {
      bMerge: true,
      bBatch,
    });
  } catch (error) {
    if (error instanceof Error) {
      return error.message;
    }
    return 'Something went wrong!';
  }
};

/**
 * 신규 상품을 생성한다. 중복된 Id가 있으면 에러처리한다.
 * @returns
 */
export const createProduct = async (data: Product) => firebaseManager.createDoc(productPath, data.productId, data);

/**
 * product-image/P12559
 * @param path
 */
export const deleteProductImage = async (path: string) => {
  const [dir, id] = path.split('/');
  if (dir !== 'product-image' || !id) {
    throw new Error('Invalid path');
  }

  const product = await getProductById(id);
  if (!product) {
    throw new Error('Invalid product');
  }

  const { imageUrl, thumbnail } = product;
  if (!imageUrl || !thumbnail) {
    throw new Error(`Invalid image url\n${imageUrl}\n${thumbnail}`);
  }
  const originRegex = /product-image\/\w+(\.png)?/;
  const thumbRegex = /product-thumb\/\w+(\.jpg)?/;
  const originPath = decodeURIComponent(imageUrl)?.match(originRegex)?.[0];
  const thumbPath = decodeURIComponent(thumbnail)?.match(thumbRegex)?.[0];
  if (!originPath || !thumbPath) {
    throw new Error(`Invalid path\n${originPath}\n${thumbPath}`);
  }
  const data: Partial<ProductDoc> = {
    imageUrl: null,
    thumbnail: null,
  };
  // 1. 링크 해제.
  await firebaseManager.updateDoc(productPath, id, data);

  // 2. 서버의 파일 삭제.(thumbnail, original)
  await firebaseManager.deleteFile(originPath);
  await firebaseManager.deleteFile(thumbPath);
  return;
};

export const getProductById = async (productId: string) =>
  firebaseManager.getDoc<ProductDoc>(`${productPath}/${productId}`);

export const getProductsWithWhere = (where: WHERE[]) =>
  firebaseManager.getDocsArrayWithWhere<ProductDoc>(productPath, where);

export const createCommerceIssue = async (issue: CommerceIssue) => {
  return firebaseManager.createDoc(commerceIssuePath, undefined, issue);
};

export const updateUser = async (uid: string, user: Partial<UserDoc>) => {
  return firebaseManager.updateDoc(userPath, uid, user);
};

export const updateProduct = async (productId: string, product: Partial<ProductDoc>, bBatch = false) => {
  return firebaseManager.updateDoc(productPath, productId, product, {
    bBatch,
  });
};

export const updateCommerceIssue = async (issueId: string, issue: Partial<CommerceIssue>) => {
  return firebaseManager.updateDoc(commerceIssuePath, issueId, issue);
};

export const getDevices = () => {
  return firebaseManager.getDocsArrayWithWhere<DeviceDoc>(devicePath, []);
};

/**
 * return 되는 uploadTask를 이용해서 다양한 액션이 가능하다.
 *
 * - 일시중지, 재시작: uploadTask.pause(); uploadTask.resume();
 * - 취소: uploadTask.cancel();
 *
 * 업로드 진행상황 모니터링
 * {@link https://firebase.google.com/docs/storage/web/upload-files#monitor_upload_progress monitor_upload_progress}
 * - uploadTask.on('state_changed', (snapshot) => {...});
 *
 * @returns uploadTask
 */
export const uploadFileWithManaging = async (path: string, file: File, metadata?: UploadMetadata) => {
  return firebaseManager.uploadTask(path, file, {
    ...metadata,
    contentType: 'image/jpeg',
  });
};

export const getUsersWithWhere = async (where: WHERE[] = []) =>
  firebaseManager.getDocsArrayWithWhere<UserDoc>(userPath, where);

export const getUser = async (userId: string) => firebaseManager.getDoc<UserDoc>(`${userPath}/${userId}`);

export const getUsers = async (where: WHERE[] = []) => firebaseManager.getDocsArrayWithWhere<UserDoc>(userPath, where);

// notification
export const createNotificationWithBatch = async (requiredFields: {
  publishDate: string;
  groupId: string;
  userId: string;
  title: string;
  body: string;
  uri: string;
}) => {
  const { groupId, title, uri, body, userId, publishDate } = requiredFields;
  const notification: Notification = {
    groupId,
    userId,
    deviceId: null,
    title,
    body,
    action: {
      type: 'notion',
      uri,
    },
    isDeleted: false,
    unread: true,
    kind: 'promotion',
    publishDate,
    data: null,
    publisher: {
      id: 'admin',
      nickname: '관리자',
      avatarImage: `${imageBaseUrl}%2Fcommon%2Fic-gooduncles-orange.svg?alt=media`,
    },
  };
  return firebaseManager.createDoc(notificationPath, undefined, notification, { bBatch: true });
};

export const observeNotification = (where: WHERE[] = [], options?: QueryOptions) =>
  firebaseManager.observeCollection<NotificationDoc>(notificationPath, where, options);

/**
 * 지정한 가게의 3개월 동안의 주문 상품 목록을 가져온다.
 * @returns
 */
export const getOrderProductIdsForStoreWithRange = async (path: string, monthRange = 3) => {
  const storeId = path.split('/')[1];
  const now = new Date();
  const endDate = format(new Date(now.setMonth(now.getMonth() - monthRange)), "yyyy-MM-dd'T'HH:mm:ss+0900");
  try {
    const orders = await firebaseManager.getDocsArrayWithWhere<OrderDoc>(
      orderPath,
      [
        ['storeId', '==', storeId],
        ['orderDate', '>=', endDate],
        ['orderStatus', '==', OrderStatusCode.DELIVERED],
      ],
      {
        sortKey: 'orderDate',
        orderBy: 'desc',
      }
    );
    const orderedProducts = orders.flatMap((order) => order.products);
    const uniqueProducts = uniqBy(orderedProducts, 'productId');
    return uniqueProducts;
  } catch (error: any) {
    console.error(error);
    notification.error({
      message: '이전 주문내역을 가져오는데 실패했습니다.',
      description: error.message,
    });
  }
  return [];
};
/**
 * 매장의 최근 n개월 이내 '배송완료'주문 목록을 가져옵니다.
 * @param storeId
 * @param monthRange 입력 개월 이내의 주문을 가져옵니다.(기본 2개월)
 * @returns
 */
export const getOrderForStore = (storeId: string, monthRange: number) => {
  const now = new Date();
  const endDate = format(new Date(now.setMonth(now.getMonth() - monthRange)), "yyyy-MM-dd'T'HH:mm:ss+0900");
  return firebaseManager.getDocsArrayWithWhere<OrderDoc>(
    orderPath,
    [
      ['storeId', '==', storeId],
      ['orderDate', '>=', endDate],
      ['orderStatus', '==', OrderStatusCode.DELIVERED],
    ],
    {
      sortKey: 'orderDate',
      orderBy: 'desc',
    }
  );
};

export const observeOrder = (where: WHERE[] = [], options?: QueryOptions) =>
  firebaseManager.observeCollection<OrderDoc>(orderPath, where, options);
export const getOrder = (orderId: string) => firebaseManager.getDoc<OrderDoc>(`${orderPath}/${orderId}`);

/**
 * 정산내역을 생성합니다.
 */
export const createInstallmentBill = async (installmentBill: InstallmentBill, bBatch = false) =>
  firebaseManager.createDoc(installmentBillPath, undefined, installmentBill, { bBatch });

export const getInstallmentBill = async (docId: string) =>
  firebaseManager.getDoc<InstallmentBillDoc>(`${installmentBillPath}/${docId}`);

export const updateInstallmentBill = async (id: string, data: Partial<InstallmentBillDoc>, bBatch = false) =>
  firebaseManager.updateDoc(installmentBillPath, id, data, {
    bBatch,
  });

export const observeInstallmentBill = (where: WHERE[], options?: QueryOptions) =>
  firebaseManager.observeCollection<InstallmentBillDoc>(installmentBillPath, where, options);

// Store
const storePath = 'store';
export const observeStore = (where: WHERE[] = []) => firebaseManager.observeCollection<StoreDoc>(storePath, where);
export const getStore = (storeId: string) => firebaseManager.getDoc<StoreDoc>(`${storePath}/${storeId}`);
export const getStores = (where: WHERE[] = [], options?: QueryOptions) =>
  firebaseManager.getDocsArrayWithWhere<StoreDoc>(storePath, where, options);
export const updateStore = (storeId: string, store: Partial<StoreDoc>, bBatch = false) =>
  firebaseManager.updateDoc(storePath, storeId, store, {
    bBatch,
  });
export const getStoreWithWhere = (where: WHERE[] = []) =>
  firebaseManager.getDocsArrayWithWhere<StoreDoc>(storePath, where);

// StoreIssue
const storeIssuePath = 'storeIssue';
export const getStoreIssue = (id: string) => firebaseManager.getDoc<StoreIssueDoc>(`${storeIssuePath}/${id}`);
export const getStoreIssues = (where: WHERE[] = [], options?: QueryOptions) =>
  firebaseManager.getDocsArrayWithWhere<StoreIssueDoc>(storeIssuePath, where, options);
export const observeStoreIssue = (where: WHERE[] = [], options?: QueryOptions) =>
  firebaseManager.observeCollection<StoreIssueDoc>(storeIssuePath, where, options);

export const createStoreIssue = () => {
  const storeIssue: StoreIssue = {
    date: orderDateFormat03(getDateWithoutTime(1)),
    category: null,
    storeCode: null,
    storeName: null,
    supplier: null,
    productId: null,
    productName: null,
    volume: 1,
    supplyPrice: null,
    tax: null,
    memo: null,
    message: null,
    status: null,
    completedAt: null,
    isDeleted: false,

    deliveryPartnerId: null,
    deliveryPartnerName: null,
    deliverySpotId: null,
    deliveryTaskFinishedAt: null,
    deliveredAt: null,

    pickupPartnerId: null,
    pickupPartnerName: null,
    pickupTaskFinishedAt: null,

    courierId: null,
    courierName: null,
    settledAt: null,
    invoiceId: null,
  };
  return firebaseManager.createDoc(storeIssuePath, undefined, storeIssue);
};

export const updateStoreIssue = (id: string, data: Partial<StoreIssue>, bBatch = false) =>
  firebaseManager.updateDoc(storeIssuePath, id, data, {
    bBatch,
  });

// general
export function updateDocWithCollection<T>(colleciton: string, id: string, data: Partial<T>) {
  return firebaseManager.updateDoc(colleciton, id, data);
}

// order
const orderPath = 'order';
export const getOrders = async (where: WHERE[] = [], options?: QueryOptions) =>
  firebaseManager.getDocsArrayWithWhere<OrderDoc>(orderPath, where, options);
export const updateOrder = async (orderId: string, order: Partial<OrderDoc>, bBatch = false) => {
  return firebaseManager.updateDoc(orderPath, orderId, order, {
    bBatch,
  });
};
export const getOrdersForUserId = async (userId: string) =>
  firebaseManager.getDocsArrayWithWhere<OrderDoc>(orderPath, [['userId', '==', userId]]);

export const getDeliveredOrdersWithRange = async (startValue: string, endValue: string) =>
  firebaseManager.getDocsArrayWithWhere<OrderDoc>(orderPath, [['orderStatus', 'in', [OrderStatusCode.DELIVERED]]], {
    sortKey: 'orderDate',
    orderBy: 'desc',
    startValue,
    endValue,
  });

// monthlyOrderStatsForStore
export const getMonthlyOrderStatsForStore = async (where: WHERE[] = []) =>
  firebaseManager.getDocsArrayWithWhere<MonthlyOrderStatsForStoreDoc>(monthlyOrderStatsForStorePath, where);

// dailyOrderStats
export const getDailyOrderStats = async (where: WHERE[] = []) =>
  firebaseManager.getDocsArrayWithWhere<DailyOrderStatsDoc>(dailyOrderStatsPath, where);

// config
export const updateCommerceConf = async (data: Partial<CommerceConf>) =>
  firebaseManager.updateDoc(configPath, 'commerce', data);

export const getCommerceConf = () => firebaseManager.getDoc<CommerceConf>(`${configPath}/commerce`);
export const observeInvoiceMessageMapConf = () =>
  firebaseManager.observeDoc<InvoiceMessageMapDoc>(`${configPath}/invoiceMessageMap`);
export const updateInvoiceMessageMapConf = (data: Partial<InvoiceMessageMapDoc>) =>
  firebaseManager.updateDoc(configPath, 'invoiceMessageMap', data);
export const observeGarakMarketProductList = () =>
  firebaseManager.observeDoc<GarakMarketProductListDoc>('config/garakMarketProductList');
export const observeDabomFoodProductList = () =>
  firebaseManager.observeDoc<DabomFoodProductListDoc>('config/dabomFoodProductList');
export const observeCJFreshwayProductList = () =>
  firebaseManager.observeDoc<CJFreshwayProductListDoc>('config/CJFreshwayProductList');
export const observeSehyeonFnBProductList = () =>
  firebaseManager.observeDoc<SehyeonFnBProductListDoc>('config/sehyeonFnBProductList');
export const observeFoodpangProductList = () =>
  firebaseManager.observeDoc<FoodpangProductListDoc>('config/foodpangProductList');
export const observeDawnMarketProductList = () =>
  firebaseManager.observeDoc<DawnMarketProductListDoc>('config/dawnMarketProductList');
export const observeProductBinLocation = () =>
  firebaseManager.observeDoc<ProductBinLocationDoc>('config/productBinLocation');
export const updateProductBinLocation = (data: Partial<ProductBinLocation>) =>
  firebaseManager.updateDoc(configPath, 'productBinLocation', data);

export const observeProductCompetitorTrendKeywordMap = () =>
  firebaseManager.observeDoc<ProductCompetitorTrendKeywordMap>('config/productCompetitorTrendKeywordMap');
export const updateProductCompetitorTrendKeywordMap = (productId: string, data: string | FieldValue) =>
  firebaseManager.updateDoc(configPath, 'productCompetitorTrendKeywordMap', {
    [`productCompetitorTrendKeywordMap.${productId}`]: data,
  });

// productGroupForCategory
const productGroupForCategoryPath = 'productGroupForCategory';
export const observeProductGroupForCategoryDoc = (category: string) =>
  firebaseManager.observeDoc<ProductGroupForCategoryDoc>(`${productGroupForCategoryPath}/${category}`);

export const observeProductGroupForCategory = (where: WHERE[], options?: QueryOptions) =>
  firebaseManager.observeCollection<ProductGroupForCategoryDoc>(productGroupForCategoryPath, where, options);

export const mergeProductGroupForCategory = (category: string, data: DeepPartial<ProductGroupForCategoryDoc>) =>
  firebaseManager.setDoc(productGroupForCategoryPath, category, data, {
    bMerge: true,
  });

// alimtalk
export async function getAlimtalkMessage<T>(id: string) {
  return firebaseManager.getDoc<AlimtalkMessageDoc<T>>(`${alimtalkMessagePath}/${id}`);
}
export const observeAlimtalkMessage = (where: WHERE[] = [], options?: QueryOptions) =>
  firebaseManager.observeCollection<AlimtalkMessageDoc<any>>(alimtalkMessagePath, where, options);

// friendtalk
export async function getFriendtalkMessage(id: string) {
  return firebaseManager.getDoc<FriendtalkMessageDoc>(`${friendtalkMessagePath}/${id}`);
}
export const observeFriendtalkMessage = (where: WHERE[] = [], options?: QueryOptions) =>
  firebaseManager.observeCollection<FriendtalkMessageDoc>(friendtalkMessagePath, where, options);

// mobileMessage
const mobileMessagePath = 'mobileMessage';
export const observeMobileMessage = (where: WHERE[] = [], options?: QueryOptions) =>
  firebaseManager.observeCollection<MobileMessageDoc>(mobileMessagePath, where, options);

// supplier
const supplierPath = 'supplier';
export const getSupplierById = (id: string) => firebaseManager.getDoc<SupplierDoc>(`${supplierPath}/${id}`);
export const observeSupplier = (where: WHERE[] = [], options?: QueryOptions) =>
  firebaseManager.observeCollection<SupplierDoc>(supplierPath, where, options);
export const getSuppliers = (where: WHERE[] = [], options?: QueryOptions) =>
  firebaseManager.getDocsArrayWithWhere<SupplierDoc>(supplierPath, where, options);
export const getSuppliersForName = (name: string) =>
  firebaseManager.getDocsArrayWithWhere<SupplierDoc>(supplierPath, [
    ['name', '==', name],
    ['isDeleted', '==', false],
  ]);

export const createSupplier = (supplier: Supplier) => firebaseManager.createDoc(supplierPath, undefined, supplier);
export const updateSupplier = (id: string, data: Partial<SupplierDoc>, bBatch = false) =>
  firebaseManager.updateDoc(supplierPath, id, data, {
    bBatch,
  });
export const deleteSupplier = (id: string) => {
  const doc: Partial<SupplierDoc> = {
    isDeleted: true,
    _timeDelete: Timestamp.now(),
  };
  return firebaseManager.updateDoc(supplierPath, id, doc);
};

// purchaseOrder
const purchaseOrderPath = 'purchaseOrder';
export const getPurchaseOrder = (id: string) => firebaseManager.getDoc<PurchaseOrderDoc>(`${purchaseOrderPath}/${id}`);
export const getPurchaseOrders = (where: WHERE[] = [], options?: QueryOptions) =>
  firebaseManager.getDocsArrayWithWhere<PurchaseOrderDoc>(purchaseOrderPath, where, options);
export const observePurchaseOrder = (id: string) =>
  firebaseManager.observeDoc<PurchaseOrderDoc>(`${purchaseOrderPath}/${id}`);
export const observePurchaseOrders = (where: WHERE[] = [], options?: QueryOptions) =>
  firebaseManager.observeCollection<PurchaseOrderDoc>(purchaseOrderPath, where, options);
export const setPurchaseOrder = (id: string, purchaseOrder: PurchaseOrder) =>
  firebaseManager.setDoc(purchaseOrderPath, id, purchaseOrder, {
    bMerge: false,
    bBatch: true,
  });
export const setPurchaseOrdersWithBatch = async (purchaseOrders: (PurchaseOrder & { id: string })[]) => {
  firebaseManager.batchStart();
  for (const purchaseOrder of purchaseOrders) {
    await firebaseManager.setDoc(purchaseOrderPath, purchaseOrder.id, purchaseOrder, {
      bMerge: false,
      bBatch: true,
    });
  }
  return firebaseManager.batchEnd();
};
export const createPurchaseOrder = (purchaseOrder: PurchaseOrderDoc) =>
  firebaseManager.createDoc(purchaseOrderPath, undefined, purchaseOrder);
export const deletePurchaseOrder = (id: string) => {
  const doc: Partial<PurchaseOrderDoc> = {
    isDeleted: true,
    _timeDelete: Timestamp.now(),
  };
  return firebaseManager.updateDoc(purchaseOrderPath, id, doc);
};
export const updatePurchaseOrder = (
  id: string,
  data: Partial<PurchaseOrderDoc>,
  options?: {
    bBatch: boolean;
  }
) => firebaseManager.updateDoc(purchaseOrderPath, id, data, options);

// deliverySpot
const deliverySpotPath = 'deliverySpot';
export const getDeliverySpot = (id: string) => firebaseManager.getDoc<DeliverySpotDoc>(`${deliverySpotPath}/${id}`);
export const observeDeliverySpot = (where: WHERE[] = [], options?: QueryOptions) =>
  firebaseManager.observeCollection<DeliverySpotDoc>(deliverySpotPath, where, options);
export const getDeliverySpots = (where: WHERE[] = [], options?: QueryOptions) =>
  firebaseManager.getDocsArrayWithWhere<DeliverySpotDoc>(deliverySpotPath, where, options);
export const updateDeliverySpot = (id: string, data: Partial<DeliverySpotDoc>, bBatch?: boolean) =>
  firebaseManager.updateDoc(deliverySpotPath, id, data, { bBatch });
export const createDeliverySpot = (data: DeliverySpot, bBatch?: boolean) =>
  firebaseManager.createDoc(deliverySpotPath, undefined, data, { bBatch });
export const deleteDeliverySpot = (id: string, bBatch = false) =>
  firebaseManager.updateDoc(
    deliverySpotPath,
    id,
    { isDeleted: true, _timeDelete: Timestamp.now() },
    {
      bBatch,
    }
  );

// deliveryCourier
const deliveryCourierPath = 'deliveryCourier';
export const getDeliveryCouriers = (where: WHERE[] = [], options?: QueryOptions) =>
  firebaseManager.getDocsArrayWithWhere<DeliveryCourierDoc>(deliveryCourierPath, where, options);
export const observeDeliveryCourier = (where: WHERE[] = [], options?: QueryOptions) =>
  firebaseManager.observeCollection<DeliveryCourierDoc>(deliveryCourierPath, where, options);
export const createDeliveryCourier = (deliveryCourier: DeliveryCourier) =>
  firebaseManager.createDoc(deliveryCourierPath, undefined, deliveryCourier);
export const updateDeliveryCourier = (id: string, data: Partial<DeliveryCourierDoc>, bBatch?: boolean) =>
  firebaseManager.updateDoc(deliveryCourierPath, id, data, { bBatch });

// productStockHistory
const productStockHistoryPath = 'productStockHistory';
export const observeProductStockHistoryForProductId = (productId: string, options?: QueryOptions) =>
  firebaseManager.observeCollection<ProductStockHistoryDoc>(
    productStockHistoryPath,
    [['productId', '==', productId]],
    options
  );
export const createProductStockHistory = (data: ProductStockHistory, bBatch = false) =>
  firebaseManager.createDoc(productStockHistoryPath, undefined, data, {
    bBatch,
  });
export const cancelProductStockHistory = (id: string, bBatch = false) =>
  firebaseManager.updateDoc(productStockHistoryPath, id, { canceled: true }, { bBatch });
export const getProductStockHistories = (where: WHERE[] = [], options?: QueryOptions) =>
  firebaseManager.getDocsArrayWithWhere<ProductStockHistoryDoc>(productStockHistoryPath, where, options);
export const observeProductStockHistories = (where: WHERE[] = [], options?: QueryOptions) =>
  firebaseManager.observeCollection<ProductStockHistoryDoc>(productStockHistoryPath, where, options);

// productRequest
const productRequestPath = 'productRequest';
export const observeProductRequest = (where: WHERE[] = [], options?: QueryOptions) =>
  firebaseManager.observeCollection<ProductRequestDoc>(productRequestPath, where, options);
export const getProductRequestById = (id: string) =>
  firebaseManager.getDoc<ProductRequestDoc>(`${productRequestPath}/${id}`);
export const createProductRequest = (data: ProductRequest, options?: { bBatch: boolean }) =>
  firebaseManager.createDoc(productRequestPath, undefined, data, options);

// deliveryRouteHistory
const deliveryRouteHistoryPath = 'deliveryRouteHistory';
export const observeDeliveryRouteHistoryDoc = (id: string) =>
  firebaseManager.observeDoc<DeliveryRouteHistoryDoc>(`${deliveryRouteHistoryPath}/${id}`);
export const observeDeliveryRouteHistory = (where: WHERE[] = [], options?: QueryOptions) =>
  firebaseManager.observeCollection<DeliveryRouteHistoryDoc>(deliveryRouteHistoryPath, where, options);
export const getDeliveryRouteHistory = (id: string) =>
  firebaseManager.getDoc<DeliveryRouteHistoryDoc>(`${deliveryRouteHistoryPath}/${id}`);
export const createDeliveryRouteHistory = (id: string, data: DeliveryRouteHistory, bBatch = false) =>
  firebaseManager.createDoc(deliveryRouteHistoryPath, id, data, {
    bBatch,
  });
export const updateDeliveryRouteHistory = (id: string, data: Partial<DeliveryRouteHistoryDoc>, bBatch = false) =>
  firebaseManager.updateDoc(deliveryRouteHistoryPath, id, data, {
    bBatch,
  });
export const setDeliveryRouteHistory = (
  id: string,
  data: DeliveryRouteHistory,
  options?: { bMerge?: boolean; bBatch?: boolean }
) => firebaseManager.setDoc(deliveryRouteHistoryPath, id, data, options);

// config/businessInfo
const configBusinessInfoPath = 'config/businessInfo';
export const getConfigBusinessInfo = () => firebaseManager.getDoc<BusinessInfoDoc>(configBusinessInfoPath);

// deliveryPerformance
const deliveryPerformancePath = 'deliveryPerformance';
export const observeDeliveryPerformance = (where: WHERE[] = [], options?: QueryOptions) =>
  firebaseManager.observeCollection<DeliveryPerformanceDoc>(deliveryPerformancePath, where, options);

// dailyProductSync
const dailyProductSyncPath = 'dailyProductSync';
export const observeDailyProductSync = (id: string) =>
  firebaseManager.observeDoc<DailyProductSyncDoc>(`${dailyProductSyncPath}/${id}`);
export const getDailyProductSync = (id: string) =>
  firebaseManager.getDoc<DailyProductSyncDoc>(`${dailyProductSyncPath}/${id}`);
export const createDailyProductSync = (id: string, data: DailyProductSync) =>
  firebaseManager.createDoc(dailyProductSyncPath, id, data);
export const updateDailyProductSync = (id: string, data: Partial<DailyProductSync>) =>
  firebaseManager.updateDoc(dailyProductSyncPath, id, data);

// promotionSection
const promotionSectionPath = 'promotionSection';
export const getPromotionSection = (id: string) =>
  firebaseManager.getDoc<PromotionSectionDoc>(`${promotionSectionPath}/${id}`);
export const observePromotionSection = (where: WHERE[] = [], options?: QueryOptions) =>
  firebaseManager.observeCollection<PromotionSectionDoc>(promotionSectionPath, where, options);
export const createPromotionSection = (data: PromotionSection) =>
  firebaseManager.createDoc(promotionSectionPath, undefined, data);
export const updatePromotionSection = (id: string, data: Partial<PromotionSectionDoc>, bBatch = false) =>
  firebaseManager.updateDoc(promotionSectionPath, id, data, {
    bBatch,
  });

// customPromotionSection
const customPromotionSectionPath = 'customPromotionSection';
export const getCustomPromotionSection = (id: string) =>
  firebaseManager.getDoc<CustomPromotionSectionDoc>(`${customPromotionSectionPath}/${id}`);
export const observeCustomPromotionSection = (where: WHERE[] = [], options?: QueryOptions) =>
  firebaseManager.observeCollection<CustomPromotionSectionDoc>(customPromotionSectionPath, where, options);
export const createCustomPromotionSection = (data: CustomPromotionSection) =>
  firebaseManager.createDoc(customPromotionSectionPath, undefined, data);
export const updateCustomPromotionSection = (id: string, data: Partial<CustomPromotionSectionDoc>, bBatch = false) =>
  firebaseManager.updateDoc(customPromotionSectionPath, id, data, {
    bBatch,
  });

// productSnapshot
const productSnapshotPath = 'productSnapshot';
export const getProductSnapshots = (where: WHERE[] = [], options?: QueryOptions) =>
  firebaseManager.getDocsArrayWithWhere<ProductSnapshotDoc>(productSnapshotPath, where, options);
export const getProductSnapshot = (id: string) =>
  firebaseManager.getDoc<ProductSnapshotDoc>(`${productSnapshotPath}/${id}`);

// paymentItem
const paymentItemPath = 'paymentItem';
export const observePaymentItem = (where: WHERE[] = [], options?: QueryOptions) =>
  firebaseManager.observeCollection<PaymentItemDoc>(paymentItemPath, where, options);
export const createPaymentItem = (data: PaymentItem, bBatch = false) =>
  firebaseManager.createDoc(paymentItemPath, undefined, data, {
    bBatch,
  });
export const updatePaymentItem = (id: string, data: Partial<PaymentItem>, bBatch = false) =>
  firebaseManager.updateDoc(paymentItemPath, id, data, {
    bBatch,
  });
export const deletePaymentItem = (id: string, bBatch = false) =>
  firebaseManager.updateDoc(
    paymentItemPath,
    id,
    {
      deletedAt: formatDate(new Date(), "yyyy-MM-dd'T'HH:mm:ss+0900"),
    },
    {
      bBatch,
    }
  );

// hyosungCMSMember
const hyosungCMSMemberPath = 'hyosungCMSMember';
export const getHyosungCMSMembers = (where: WHERE[] = [], options?: QueryOptions) =>
  firebaseManager.getDocsArrayWithWhere<HyosungCMSMemberDoc>(hyosungCMSMemberPath, where, options);
export const setHyosungCMSMember = (
  id: string,
  data: HyosungCMSMember,
  options: {
    bMerge: boolean;
    bBatch: boolean;
  }
) => firebaseManager.setDoc(hyosungCMSMemberPath, id, data, options);
export const observeHyosungCMSMember = (where: WHERE[] = [], options?: QueryOptions) =>
  firebaseManager.observeCollection<HyosungCMSMemberDoc>(hyosungCMSMemberPath, where, options);

// dailyIssue
const dailyIssuePath = 'dailyIssue';
export const createDailyIssue = (id: string, data: DailyIssue) => firebaseManager.createDoc(dailyIssuePath, id, data);
export const getDailyIssue = (id: string) => firebaseManager.getDoc<DailyIssueDoc>(`${dailyIssuePath}/${id}`);
export const updateDailyIssue = (
  id: string,
  data: Partial<
    DailyIssue & {
      history: any;
    }
  >
) => firebaseManager.updateDoc(dailyIssuePath, id, data);
export const observeDailyIssueDoc = (docId: string) =>
  firebaseManager.observeDoc<DailyIssueDoc>(`${dailyIssuePath}/${docId}`);

// invoice
const invoicePath = 'invoice';
export const getInvoice = (id: string) => firebaseManager.getDoc<InvoiceDoc>(`${invoicePath}/${id}`);
export const createInvoice = (data: Invoice, bBatch = false) =>
  firebaseManager.createDoc(invoicePath, undefined, data, { bBatch });
export const updateInvoice = (id: string, data: Partial<InvoiceDoc>, bBatch = false) =>
  firebaseManager.updateDoc(invoicePath, id, data, { bBatch });
export const observeInvoices = (where: WHERE[] = [], options?: QueryOptions) =>
  firebaseManager.observeCollection<InvoiceDoc>(invoicePath, where, options);

// settlementResult
const settlementResultPath = 'settlementResult';
export const getSettlementResult = (id: string) =>
  firebaseManager.getDoc<SettlementResultDoc>(`${settlementResultPath}/${id}`);
export const observeSettlementResult = (where: WHERE[] = [], options?: QueryOptions) =>
  firebaseManager.observeCollection<SettlementResultDoc>(settlementResultPath, where, options);
export const createSettlementResult = (data: SettlementResult, bBatch = false) =>
  firebaseManager.createDoc(settlementResultPath, undefined, data, { bBatch });
export const updateSettlementResult = (id: string, data: Partial<SettlementResult>, bBatch = false) =>
  firebaseManager.updateDoc(settlementResultPath, id, data, { bBatch });

// invoiceDetail
const invoiceDetailPath = 'invoiceDetail';
export const observeInvoiceDetail = (where: WHERE[] = [], options?: QueryOptions) =>
  firebaseManager.observeCollection<InvoiceDetailDoc>(invoiceDetailPath, where, options);
export const deleteInvoiceDetail = (id: string, bBatch = false) =>
  firebaseManager.updateDoc(
    invoiceDetailPath,
    id,
    {
      deletedAt: formatDate(new Date(), "yyyy-MM-dd'T'HH:mm:ss+0900"),
    },
    {
      bBatch,
    }
  );

// memo
const memoPath = 'memo';
export const getMemo = (id: string) => firebaseManager.getDoc<MemoDoc>(`${memoPath}/${id}`);
export const observeMemoDoc = (id: string) => firebaseManager.observeDoc<MemoDoc>(`${memoPath}/${id}`);
export const updateMemo = (id: string, data: Partial<MemoDoc>) => firebaseManager.updateDoc(memoPath, id, data);

// garakMarketProductPriceTrend
const garakMarketProductPriceTrendPath = 'garakMarketProductPriceTrend';
export const observeGarakMarketProductPriceTrend = (where: WHERE[] = [], options?: QueryOptions) =>
  firebaseManager.observeCollection<GarakMarketProductPriceTrendDoc>(garakMarketProductPriceTrendPath, where, options);
export const createGarakMarketProductPriceTrend = (id: string, data: GarakMarketProductPriceTrend) =>
  firebaseManager.createDoc(garakMarketProductPriceTrendPath, id, data);
export const deleteGarakMarketProductPriceTrend = (id: string) =>
  firebaseManager.deleteDocument(`${garakMarketProductPriceTrendPath}/${id}`);

// guProductPriceTrend
const guProductPriceTrendPath = 'guProductPriceTrend';
export const observeGuProductPriceTrend = (where: WHERE[] = [], options?: QueryOptions) =>
  firebaseManager.observeCollection<GuProductPriceTrendDoc>(guProductPriceTrendPath, where, options);

// competitorProductMapping
const competitorProductMappingPath = 'competitorProductMapping';
export const observeCompetitorProductMapping = (where: WHERE[] = [], options?: QueryOptions) =>
  firebaseManager.observeCollection<CompetitorProductMappingDoc>(competitorProductMappingPath, where, options);
export const createCompetitorProductMapping = (data: CompetitorProductMapping) =>
  firebaseManager.createDoc(competitorProductMappingPath, undefined, data);
export const deleteCompetitorProductMapping = (id: string) =>
  firebaseManager.deleteDocument(`${competitorProductMappingPath}/${id}`);

// dabomFoodProduct
const dabomFoodProductPath = 'dabomFoodProduct';
export const getDabomFoodProduct = (id: string) =>
  firebaseManager.getDoc<DabomFoodProduct>(`${dabomFoodProductPath}/${id}`);

// CJFreshwayProduct
const CJFreshwayProductPath = 'CJFreshwayProduct';
export const getCJFreshwayProduct = (id: string) =>
  firebaseManager.getDoc<CJFreshwayProduct>(`${CJFreshwayProductPath}/${id}`);

// sehyeonFnBProduct
const sehyeonFnBProductPath = 'sehyeonFnBProduct';
export const getSehyeonFnBProduct = (id: string) =>
  firebaseManager.getDoc<SehyeonFnBProduct>(`${sehyeonFnBProductPath}/${id}`);

// foodpangProduct
const foodpangProductPath = 'foodpangProduct';
export const getFoodpangProduct = (id: string) =>
  firebaseManager.getDoc<FoodpangProductDoc>(`${foodpangProductPath}/${id}`);

// dawnMarketProduct
const dawnMarketProductPath = 'dawnMarketProduct';
export const getDawnMarketProduct = (id: string) =>
  firebaseManager.getDoc<DawnMarketProductDoc>(`${dawnMarketProductPath}/${id}`);

// productWeeklyPrice
const productWeeklyPricePath = 'productWeeklyPrice';
export const getProductWeeklyPrice = (id: string) =>
  firebaseManager.getDoc<ProductWeeklyPriceDoc>(`${productWeeklyPricePath}/${id}`);
export const observeProductWeeklyPrice = (where: WHERE[] = [], options?: QueryOptions) =>
  firebaseManager.observeCollection<ProductWeeklyPriceDoc>(productWeeklyPricePath, where, options);

// partnersUser
const partnersUserPath = 'partnersUser';
export const getPartnersUser = (id: string) => firebaseManager.getDoc<PartnersUserDoc>(`${partnersUserPath}/${id}`);
export const observePartnersUserDoc = (id: string) =>
  firebaseManager.observeDoc<PartnersUserDoc>(`${partnersUserPath}/${id}`);
export const getPartnersUsers = (where: WHERE[] = [], options?: QueryOptions) =>
  firebaseManager.getDocsArrayWithWhere<PartnersUserDoc>(partnersUserPath, where, options);
export const observePartnersUser = (where: WHERE[] = [], options?: QueryOptions) =>
  firebaseManager.observeCollection<PartnersUserDoc>(partnersUserPath, where, options);
export const updatePartnersUser = (id: string, data: Record<string, any>, bBatch = false) =>
  firebaseManager.updateDoc(partnersUserPath, id, data, {
    bBatch,
  });
export const updatePartnersUserColor = (id: string, color: string, bBatch = false) =>
  firebaseManager.updateDoc(
    partnersUserPath,
    id,
    { ['deliveryDriverStatus.color']: color },
    {
      bBatch,
    }
  );

// pickupTask
const pickupTaskPath = 'pickupTask';
export const getPickupTaskForOrder = (order: OrderDoc) => {
  const pickupTaskId = `${order.pickupPartnerId}-order-${order._id}`;
  return firebaseManager.getDoc<PickupTaskDoc>(`${pickupTaskPath}/${pickupTaskId}`);
};
export const observePickupTaskDoc = (id: string) =>
  firebaseManager.observeDoc<PickupTaskDoc>(`${pickupTaskPath}/${id}`);
export const getPickupTasks = (where: WHERE[] = [], options?: QueryOptions) =>
  firebaseManager.getDocsArrayWithWhere<PickupTaskDoc>(pickupTaskPath, where, options);
export const observePickupTask = (where: WHERE[] = [], options?: QueryOptions) =>
  firebaseManager.observeCollection<PickupTaskDoc>(pickupTaskPath, where, options);
export const deletePickupTask = (id: string, bBatch = false) =>
  firebaseManager.deleteDocument(`${pickupTaskPath}/${id}`, {
    bBatch,
  });
export const setPickupTask = (data: PickupTask, options?: { bMerge?: boolean; bBatch?: boolean }) =>
  firebaseManager.setDoc(pickupTaskPath, data._id, data, options);
export const updatePickupTask = (id: string, data: Partial<PickupTask>, bBatch = false) =>
  firebaseManager.updateDoc(pickupTaskPath, id, data, {
    bBatch,
  });

// deliveryTask
const deliveryTaskPath = 'deliveryTask';
export const getDeliveryTaskForOrder = (order: OrderDoc) => {
  const deliveryTaskId = `${order.deliveryPartnerId}-order-${order._id}`;
  return firebaseManager.getDoc<DeliveryTaskDoc>(`${deliveryTaskPath}/${deliveryTaskId}`);
};
export const observeDeliveryTaskDoc = (id: string) =>
  firebaseManager.observeDoc<DeliveryTaskDoc>(`${deliveryTaskPath}/${id}`);
export const getDeliveryTasks = (where: WHERE[] = [], options?: QueryOptions) =>
  firebaseManager.getDocsArrayWithWhere<DeliveryTaskDoc>(deliveryTaskPath, where, options);
export const observeDeliveryTask = (where: WHERE[] = [], options?: QueryOptions) =>
  firebaseManager.observeCollection<DeliveryTaskDoc>(deliveryTaskPath, where, options);
export const deleteDeliveryTask = (id: string, bBatch = false) =>
  firebaseManager.deleteDocument(`${deliveryTaskPath}/${id}`, {
    bBatch,
  });
export const setDeliveryTask = (data: DeliveryTask, options?: { bMerge?: boolean; bBatch?: boolean }) =>
  firebaseManager.setDoc(deliveryTaskPath, data._id, data, options);
export const updateDeliveryTask = (id: string, data: Partial<DeliveryTask>, bBatch = false) =>
  firebaseManager.updateDoc(deliveryTaskPath, id, data, {
    bBatch,
  });

// partnersVehicle
const partnersVehiclePath = 'partnersVehicle';
export const observePartnersVehicle = (where: WHERE[] = [], options?: QueryOptions) =>
  firebaseManager.observeCollection<PartnersVehicleDoc>(partnersVehiclePath, where, options);
export const createPartnersVehicle = (data: PartnersVehicle) =>
  firebaseManager.createDoc(partnersVehiclePath, undefined, data);
export const deletePartnersVehicle = (id: string) => firebaseManager.deleteDocument(`${partnersVehiclePath}/${id}`);

// partnersDevice
const partnersDevicePath = 'partnersDevice';
export const observePartnersDevices = (where: WHERE[] = [], options?: QueryOptions) =>
  firebaseManager.observeCollection<PartnersDeviceDoc>(partnersDevicePath, where, options);
export const getPartnersDevices = (where: WHERE[] = [], options?: QueryOptions) =>
  firebaseManager.getDocsArrayWithWhere<PartnersDeviceDoc>(partnersDevicePath, where, options);
