import { Input, InputNumber, message } from 'antd';
import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { parseIfObject } from 'src/lib/1/util';

interface InputCellEditorProps {
  placeholder: string;
  defaultValue: number;
  onValueChange: (value: string) => Promise<string | void>;
  stopEditing: () => void;
  minWidth?: number;
  isNumber?: boolean;
}

const InputCellEditor: FC<InputCellEditorProps> = ({
  placeholder,
  defaultValue,
  stopEditing,
  onValueChange,
  minWidth = 200,
  isNumber = false,
}) => {
  const inputRef = useRef<any>(null);
  const [loading, setLoading] = useState(false);

  const handleOnKeyDown = useCallback(
    async (e: any) => {
      /**
       * Enter key를 누르면 반영한다.
       */
      if (e.key === 'Enter') {
        if (isNumber) {
          const isNumberValue = !Number.isNaN(Number(e.target.value));
          if (!isNumberValue) {
            message.error('숫자만 입력 가능합니다.');
            return;
          }
        }
        setLoading(true);
        try {
          if (e.target.value !== defaultValue) {
            await onValueChange(e.target.value);
            message.success('변경 완료');
            stopEditing();
          }
        } catch (error) {
          console.error(error);
          message.error(parseIfObject(error));
        }
        setLoading(false);
      }
    },
    [defaultValue, isNumber, onValueChange, stopEditing]
  );

  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  return isNumber ? (
    <InputNumber
      size='large'
      disabled={loading}
      placeholder={placeholder}
      ref={inputRef}
      defaultValue={defaultValue}
      onKeyDown={handleOnKeyDown}
      style={{ minWidth }}
    />
  ) : (
    <Input
      size='large'
      disabled={loading}
      placeholder={placeholder}
      ref={inputRef}
      defaultValue={defaultValue}
      onKeyDown={handleOnKeyDown}
      style={{ minWidth }}
    />
  );
};

export default InputCellEditor;
