import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import { Button, Modal, message, notification } from 'antd';
import { FC, createRef, useCallback, useEffect, useState } from 'react';

import { FirebaseManager } from 'src/lib/3/firebase-manager';
import { InstallmentBill } from 'src/lib/3/schema-bill';
import { createInstallmentBill } from 'src/lib/4/firebase-short-cut';
import { parsingFile } from 'src/lib/4/installment-bill-util';

import useBill from 'src/redux/hooks/useBIll';
import { selectBills } from 'src/redux/slices/bill';
import { useAppSelector } from 'src/redux/store';

import Bill, { BillHandle } from 'src/components/Common/Bill/Bill';
import FileUploadArea from 'src/components/Common/FileUploadArea/FileUploadArea';

import classes from './InstallmentBillUploadModal.module.scss';

const firebaseManager = FirebaseManager.getInstance();

type InstallmentBillUploadModalProps = {
  receiverList: { [storeCode: string]: string };
};

type ParsedResult = {
  billsWithReceiver: InstallmentBill[];
  error: string | null;
};

const InstallmentBillUploadModal: FC<InstallmentBillUploadModalProps> = ({ receiverList }) => {
  const bills = useAppSelector(selectBills);
  const { onSetBills, onSetBill, onClearBills } = useBill();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileToUpload, setFileToUpload] = useState<File>();
  const [rawBills, setRawBills] = useState<ParsedResult | null>(null);
  const billRefs = Object.keys(bills).map(() => createRef<BillHandle>());
  const isDocCreated = Object.keys(bills).length > 0;
  const validBills = rawBills?.billsWithReceiver.filter((bill) => bill.receiverTel !== null) || [];
  const invalidBills = rawBills?.billsWithReceiver.filter((bill) => !bill.receiverTel) || [];

  const createBillDocs = useCallback(
    async (bills: InstallmentBill[] | undefined) => {
      if (!bills || bills.length === 0) {
        message.error('생성할 정산서가 없습니다.');
        return;
      }
      setLoading(true);
      // 1. 서버에 업로드할 정산목록을 작성합니다.
      onSetBills(bills);

      firebaseManager.batchStart();
      // 2. 서버에 정산목록을 업로드합니다.
      for (const bill of bills) {
        try {
          await createInstallmentBill(bill, true);
          onSetBill(bill.storeCode, {
            ...bill,
            _ui: {
              isDocExist: true,
            },
          });
        } catch (error: any) {
          onSetBill(bill.storeCode, {
            ...bill,
            _ui: {
              isDocExist: false,
            },
          });
          console.error(error);
          notification.error({
            message: '오류가 발생했습니다.',
            description: error.message,
          });
        }
      }

      await firebaseManager.batchEnd();
      setLoading(false);
    },
    [onSetBill, onSetBills]
  );

  const reTryCreateBillDoc = useCallback(
    async (bill: InstallmentBill | undefined) => {
      if (!bill) {
        message.error('생성할 정산서가 없습니다.');
        return;
      }
      setLoading(true);
      try {
        await createInstallmentBill(bill, true);
        onSetBill(bill.storeCode, {
          ...bill,
          _ui: {
            isDocExist: true,
          },
        });
      } catch (error: any) {
        onSetBill(bill.storeCode, {
          ...bill,
          _ui: {
            isDocExist: false,
          },
        });
        console.error(error);
        notification.error({
          message: '오류가 발생했습니다.',
          description: error.message,
        });
      }
      setLoading(false);
    },
    [onSetBill]
  );

  const removeFile = useCallback(() => {
    setFileToUpload(undefined);
    setRawBills(null);
    onClearBills();
  }, [onClearBills]);

  const openModal = useCallback(() => {
    setOpen(true);
  }, []);

  const handleCancel = useCallback(() => {
    removeFile();
    setOpen(false);
  }, [removeFile]);

  const downloadImage = useCallback(
    async (storeCode: string) => {
      if (!billRefs) {
        message.error('생성된 이미지가 없습니다.');
        return;
      }
      const billRef = billRefs.find((ref) => ref.current?.storeCode === storeCode);
      billRef?.current?.downloadBillImage();
    },
    [billRefs]
  );

  /** 수신자 정보가 잘못되거나 없어서 보낼 수 없는 정산서를 이미지로 다운로드합니다. */
  const downloadImagesForNotSendable = useCallback(async () => {
    if (!billRefs) {
      message.error('생성된 이미지가 없습니다.');
      return;
    }
    for (const billRef of billRefs) {
      if (!billRef.current?.isSendable) {
        await billRef.current?.downloadBillImage();
      }
    }
  }, [billRefs]);

  /** 사용자가 올린 엑셀 파일을 파싱하여 bill로 변환합니다. */
  useEffect(() => {
    if (fileToUpload) {
      setLoading(true);
      parsingFile(fileToUpload, receiverList).then((result) => {
        if (result.error) {
          notification.error({
            message: result.error,
          });
          setLoading(false);
          return;
        } else {
          setRawBills(result);
          setLoading(false);
        }
      });
    }
  }, [fileToUpload, receiverList]);

  return (
    <>
      <Button onClick={openModal}>정산 파일 업로드</Button>
      <Modal
        title='정산 파일 업로드'
        open={open}
        onCancel={handleCancel}
        width='1200px'
        bodyStyle={{ height: 'calc(100vh - 154px)' }}
        maskClosable={false}
        keyboard={false}
        centered
        footer={<Button onClick={handleCancel}>닫기</Button>}>
        <div className={classes.installmentBillUploadContainer}>
          {!fileToUpload ? (
            <FileUploadArea
              fileToUpload={fileToUpload}
              setFile={setFileToUpload}
              subTitle='정산 목록 엑셀파일만 지원합니다.'
            />
          ) : (
            <div className={classes.uploadedFileContainer}>
              <header>
                <div className={classes.uploadedFile}>
                  <p>{fileToUpload.name}</p>
                  <Button type='text' danger icon={<DeleteOutlined />} onClick={removeFile} />
                </div>
              </header>
              <div className={classes.sections}>
                <section className={classes.action}>
                  <div className={classes.actionHeader}>
                    <div className={classes.top}>
                      <p className={classes.total}>
                        입력된 정산서 총: <b>{[...validBills, ...invalidBills].length}개 </b>
                        (발송 가능: <b>{validBills.length}개</b>,{' '}
                        <span className={classes.invalid}>
                          발송 불가: <b>{invalidBills.length}개</b>
                        </span>
                        )
                      </p>
                    </div>

                    <div className={classes.bottom}>
                      {isDocCreated ? (
                        <Button
                          loading={loading}
                          disabled={loading}
                          onClick={() => downloadImagesForNotSendable()}
                          icon={<DownloadOutlined />}>
                          발송 불가 정산서 모두 받기
                        </Button>
                      ) : (
                        <Button
                          type={isDocCreated ? 'default' : 'primary'}
                          loading={loading}
                          disabled={loading}
                          onClick={() => createBillDocs(rawBills?.billsWithReceiver)}>
                          정산서 생성
                        </Button>
                      )}
                    </div>
                  </div>

                  {bills && (
                    <div className={classes.billList}>
                      <div className={`${classes.billItem} ${classes.itemHeader}`}>
                        <span className={classes.alignCenter}>코드</span>
                        <span className={classes.alignCenter}>업소명</span>
                        <span className={classes.alignCenter}>수신인</span>
                        <span className={classes.alignCenter}>생성여부</span>
                        <span className={classes.alignCenter}>액션</span>
                      </div>
                      {Object.entries(bills).map(([id, bill]) => {
                        const { storeName, storeCode, receiverTel, _ui } = bill;
                        return (
                          <div key={id} className={`${classes.billItem} ${receiverTel ? '' : classes.manual}`}>
                            <span>{storeCode}</span>
                            <span>{storeName}</span>
                            <span>{receiverTel ? receiverTel : '발송불가'}</span>
                            {_ui ? (
                              <span className={classes.alignCenter}>
                                {_ui.isDocExist ? (
                                  '🟢'
                                ) : (
                                  <Button size='small' onClick={() => reTryCreateBillDoc(bill)}>
                                    재시도
                                  </Button>
                                )}
                              </span>
                            ) : (
                              <span>.</span>
                            )}
                            <Button size='small' onClick={() => downloadImage(storeCode)}>
                              정산서 받기
                            </Button>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </section>
                {bills && (
                  <section className={classes.preview}>
                    {Object.entries(bills).map(([key, bill], idx) => (
                      <Bill key={key} bill={bill} ref={billRefs[idx]} noMessage={!!bill.receiverTel} />
                    ))}
                  </section>
                )}
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default InstallmentBillUploadModal;
