import { Button, Form, Input, notification } from 'antd';
import { format } from 'date-fns';
import { ko } from 'date-fns/locale';
import { FC, useState } from 'react';
import { useAuth } from 'src/stores/auth-context';
import { v4 } from 'uuid';

import { imageBaseUrl } from 'src/lib/1/constant';
import { orderDateFormat03 } from 'src/lib/1/date-util';
import { FirebaseManager } from 'src/lib/3/firebase-manager';
import { createNotificationWithBatch, getUsersWithWhere } from 'src/lib/4/firebase-short-cut';

import classes from './NotificationForm.module.scss';

const firebaseManager = FirebaseManager.getInstance();

type FormValues = {
  title: string;
  body: string;
  uri: string;
};

const NotificationForm: FC = () => {
  const { setAuthLoading } = useAuth();
  const [form] = Form.useForm<FormValues>();
  const [previewTitle, setPreviewTitle] = useState('');
  const [previewBody, setPreviewBody] = useState('');
  const [previewLink, setPreviewLink] = useState('');

  const onFinish = async (values: FormValues) => {
    setAuthLoading(true);
    try {
      const users = await getUsersWithWhere([]);
      const publishDate = orderDateFormat03();
      firebaseManager.batchStart();
      for (const user of users) {
        await createNotificationWithBatch({
          groupId: v4(),
          title: values.title,
          body: values.body,
          uri: values.uri,
          userId: user._id,
          publishDate,
        });
      }
      await firebaseManager.batchEnd();
      notification.success({
        message: '알림 전송에 성공했습니다.',
      });
    } catch (error: any) {
      notification.error({
        message: '알림 전송에 실패했습니다.',
        description: error.message,
      });
    }
    setAuthLoading(false);
  };

  const notiDate = format(new Date(), 'L월 d일', {
    locale: ko,
  });

  return (
    <div className={classes.formContainer}>
      <Form form={form} name='noti' onFinish={onFinish} style={{ flex: 1, maxWidth: 480 }}>
        <Form.Item label='제목' name='title' rules={[{ required: true, message: '제목을 입력해주세요.' }]}>
          <Input
            placeholder='🥒 새로운 상품이 추가되었습니다!'
            onChange={(e) => setPreviewTitle(e.target.value ?? '')}
          />
        </Form.Item>
        <Form.Item label='소제목' name='body' rules={[{ required: true, message: '소제목을 입력해주세요.' }]}>
          <Input placeholder='#전처리채소' onChange={(e) => setPreviewBody(e.target.value ?? '')} />
        </Form.Item>
        <Form.Item label='Notion Link' name='uri' rules={[{ required: true, message: '노션 링크를 넣어주세요.' }]}>
          <Input
            placeholder='https://post.gooduncles.com/cebd3e9a-c0fc-4cf9-ad08-69cdb8b1b6d2'
            onChange={(e) => setPreviewLink(e.target.value ?? '')}
          />
        </Form.Item>
        <Form.Item shouldUpdate>
          {() => (
            <Button type='primary' htmlType='submit' size='large'>
              🔔 이웃삼촌 알림 일괄전송
            </Button>
          )}
        </Form.Item>
      </Form>
      <div className={classes.preview}>
        <p>미리보기</p>
        <div className={classes.notiItem} onClick={() => window.open(previewLink)}>
          <div className={classes.notiIcon}>
            <img src={`${imageBaseUrl}%2Fcommon%2Fic-gooduncles-orange.svg?alt=media`} alt='notification' />
          </div>
          <div className={classes.notiContent}>
            <p>{previewTitle}</p>
            <div className={classes.notiInfo}>{previewBody}</div>
            <div className={classes.notiDate}>{notiDate}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationForm;
