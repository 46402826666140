import { PartnersUserDoc } from '@gooduncles/gu-app-schema';
import { useEffect, useState } from 'react';

import { observePartnersUserDoc } from 'src/lib/4/firebase-short-cut';

const usePartnersUser = (partnerId: string | undefined) => {
  const [isLoading, setIsLoading] = useState(true);
  const [partnersUser, setPartnersUser] = useState<PartnersUserDoc | undefined>();

  useEffect(() => {
    if (partnerId) {
      setIsLoading(true);
      const subscription = observePartnersUserDoc(partnerId).subscribe((doc) => {
        setPartnersUser(doc);
        setIsLoading(false);
      });
      return () => {
        subscription.unsubscribe();
        setPartnersUser(undefined);
        setIsLoading(false);
      };
    } else {
      setPartnersUser(undefined);
      setIsLoading(false);
    }
  }, [partnerId]);

  return { partnersUser, isLoading };
};

export default usePartnersUser;
