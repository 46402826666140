import {
  Invoice,
  OrderDoc,
  PaymentMethod,
  PaymentVendor,
  StoreIssueDoc,
  paymentMethods,
  paymentVendors,
} from '@gooduncles/gu-app-schema';
import { ColDef, RowClassParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { FC, useMemo } from 'react';
import { cellStyleForPaymentMethod, getHyosungProductName, mergeOrderAndStoreIssue } from 'src/utils/payment-util';

import { formatDate } from 'src/lib/1/date-util';
import { calculatePreTaxPrice } from 'src/lib/5/order-util';
import { numberFormatter, onCellValueChangedWithUpdate } from 'src/lib/6/ag-grid-util';

const dateFormatter = ({ value }: { value: string | null }) => (value ? formatDate(value, 'yyyy년 L월 dd일') : '');
const onCellValueChanged = onCellValueChangedWithUpdate<InvoiceCreationItem>('청구서 미리보기');

export interface InvoiceCreationItem {
  storeId: string;
  storeCode: string | null;
  storeNickname: string;
  paymentVendor: PaymentVendor | null;
  paymentMethod: PaymentMethod | null;
  invoice: Invoice;
  orders: OrderDoc[];
  storeIssues: StoreIssueDoc[];
  errorMsg: string | null;
}

const defaultColDef: ColDef<InvoiceCreationItem> = {
  sortable: true,
  resizable: true,
  filter: true,
};

interface InvoiceCreationTableProps {
  invoiceCreationItems: InvoiceCreationItem[];
  setSelectedRows: React.Dispatch<React.SetStateAction<InvoiceCreationItem[]>>;
}

const InvoiceCreationTable: FC<InvoiceCreationTableProps> = ({ invoiceCreationItems, setSelectedRows }) => {
  const columnDefs: ColDef<InvoiceCreationItem>[] = useMemo(
    () => [
      {
        headerName: '매장명',
        field: 'storeNickname',
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
      },
      { headerName: '매장Id', field: 'storeId', hide: true },
      { headerName: '매장코드', field: 'storeCode', hide: true },
      {
        headerName: '결제처',
        field: 'paymentVendor',
        width: 100,
        editable: true,
        cellEditorPopup: true,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: {
          values: paymentVendors,
        },
        onCellValueChanged: (params) => onCellValueChanged(params, 'store', params.data.storeId),
      },
      {
        headerName: '결제방식',
        field: 'paymentMethod',
        width: 100,
        editable: true,
        cellEditorPopup: true,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: {
          values: paymentMethods,
        },
        cellStyle: cellStyleForPaymentMethod,
        onCellValueChanged: (params) => onCellValueChanged(params, 'store', params.data.storeId),
      },
      { headerName: 'ⓗ회원번호', field: 'invoice.paymentInfo.storeCode', width: 110 },
      { headerName: 'ⓗ계약번호', field: 'invoice.paymentInfo.contractNumber', width: 110 },
      { headerName: 'ⓗ회원명', field: 'invoice.paymentInfo.name', width: 100 },
      {
        headerName: 'ⓗ상품',
        valueGetter: (params: any) => {
          const data = params.data as InvoiceCreationItem;
          if (data.paymentVendor === '효성CMS') {
            return getHyosungProductName(data.paymentMethod, data.invoice);
          }
          return '';
        },
        cellStyle: (params: any) => {
          if (params.value.includes('카드')) return { color: 'var(--blue400)' };
        },
        width: 130,
      },
      { headerName: '오류', field: 'errorMsg' },
      {
        cellRenderer: 'agGroupCellRenderer',
        headerName: '청구금액',
        field: 'invoice.invoiceAmount',
        valueFormatter: numberFormatter,
        width: 150,
        type: 'numericColumn',
      },
      {
        headerName: '이전 미납금',
        field: 'invoice.prevUnpaidAmount',
        valueFormatter: numberFormatter,
        width: 120,
        type: 'numericColumn',
        cellStyle: (params: any) => {
          const value = params.value as number;
          if (value > 0)
            return {
              color: 'var(--red600)',
            };
        },
      },
      {
        headerName: '면세',
        field: 'invoice.taxExemptAmount',
        valueFormatter: numberFormatter,
        width: 120,
        type: 'numericColumn',
      },
      {
        headerName: '과세',
        field: 'invoice.taxableAmount',
        valueFormatter: numberFormatter,
        width: 120,
        type: 'numericColumn',
      },
      {
        headerName: '공급가액',
        field: 'supplyPrice',
        valueGetter: (params: any) => {
          const { price } = calculatePreTaxPrice(params.data.invoice.taxableAmount ?? 0);
          return price + params.data.invoice.taxExemptAmount;
        },
        valueFormatter: numberFormatter,
        width: 120,
        cellStyle: { color: 'var(--gray700)' },
        type: 'numericColumn',
      },
      {
        headerName: '부가세',
        field: 'tax',
        valueGetter: (params: any) => {
          const { tax } = calculatePreTaxPrice(params.data.invoice.taxableAmount ?? 0);
          return tax;
        },
        valueFormatter: numberFormatter,
        width: 120,
        cellStyle: { color: 'var(--gray700)' },
        type: 'numericColumn',
      },
    ],
    []
  );

  /**
   * 청구서의 상세내역을 보여주기 위한 렌더러 설정
   */
  const detailCellRendererParams = useMemo(() => {
    const detailColumnDefs: ColDef<InvoiceCreationItem>[] = [
      { headerName: 'id', field: '_id', hide: true },
      { headerName: '종류', field: 'type', width: 100 },
      { headerName: '배송일(적용일)', field: 'date', sort: 'desc', valueFormatter: dateFormatter },
      { headerName: '내용', field: 'message' },
      { headerName: '금액', field: 'amount', valueFormatter: numberFormatter, width: 100, type: 'numericColumn' },
      {
        headerName: '공급가액',
        field: 'supplyPrice',
        valueFormatter: numberFormatter,
        width: 100,
        type: 'numericColumn',
      },
      { headerName: '부가세', field: 'tax', valueFormatter: numberFormatter, width: 100, type: 'numericColumn' },
    ];

    return {
      detailGridOptions: {
        columnDefs: detailColumnDefs,
        defaultColDef,
      },
      getDetailRowData: (params: any) => {
        const invoice = params.data as InvoiceCreationItem;
        const { orders, storeIssues } = invoice;
        const items = mergeOrderAndStoreIssue(orders, storeIssues);
        params.successCallback(items);
      },
    };
  }, []);

  return (
    <div className='ag-theme-alpine height100 width100'>
      <AgGridReact
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        rowData={invoiceCreationItems}
        getRowStyle={(params: RowClassParams): any => {
          const typedData = params.data as InvoiceCreationItem;
          return typedData.errorMsg ? { background: 'var(--red100)' } : null;
        }}
        // detailRow
        keepDetailRows={true}
        masterDetail={true}
        detailRowAutoHeight={true}
        detailCellRendererParams={detailCellRendererParams}
        // 행 선택을 허용한다.
        onSelectionChanged={(e) => setSelectedRows(e.api.getSelectedRows())}
      />
    </div>
  );
};

export default InvoiceCreationTable;
