import { Switch, message, notification } from 'antd';
import { FC, useCallback, useState } from 'react';

import { parseIfObject } from 'src/lib/1/util';
import { updateProduct } from 'src/lib/4/firebase-short-cut';
import { ConsoleLogger } from 'src/lib/5/logger';

const logger = ConsoleLogger.getInstance();
const logName = '상품 관리';

type ProductToggleField = 'dailyNotification' | 'hidden';

interface ProductToggleCellProps {
  id: string;
  value: boolean;
  field: ProductToggleField;
}

const ProductToggleCell: FC<ProductToggleCellProps> = ({ id, value, field }) => {
  const [loading, setLoading] = useState(false);

  const handleSwitchChange = useCallback(async (value: boolean, productId: string, togglefield: ProductToggleField) => {
    setLoading(true);
    try {
      await updateProduct(productId, { [togglefield]: value });
      message.success('변경완료.');
    } catch (error: any) {
      notification.error({
        message: '에러발생',
        description: error.message,
      });
      logger.logConsole(`${logName} - product/${productId}.${togglefield}:: ${parseIfObject(error)}`, {
        level: 'error',
      });
    }
    setLoading(false);
  }, []);

  return <Switch loading={loading} checked={value} onChange={(v) => handleSwitchChange(v, id, field)} />;
};

export default ProductToggleCell;
