import { SortDescendingOutlined } from '@ant-design/icons';
import { Button, Popconfirm, notification } from 'antd';
import { FC, useState } from 'react';

import { errorObjectToString } from 'src/lib/1/util';
import { ProductGroupForTag } from 'src/lib/3/schema-product-group-by-category';
import { mergeProductGroupForCategory } from 'src/lib/4/firebase-short-cut';
import { ConsoleLogger } from 'src/lib/5/logger';

const logger = ConsoleLogger.getInstance();
const logName = '업종 관리';

interface TagHeaderWithSortButtonProps {
  field: '판매금액' | '판매량';
  category: string;
  mainTag: string;
  productsForMainTag: ProductGroupForTag['productsForMainTag'];
}

const TagHeaderWithSortButton: FC<TagHeaderWithSortButtonProps> = ({
  field,
  category,
  mainTag,
  productsForMainTag,
}) => {
  const [loading, setLoading] = useState(false);
  if (!category || !mainTag || !productsForMainTag) return null;

  const changeIndexForAmount = async () => {
    setLoading(true);
    try {
      const sortedProductsForMainTag = Object.fromEntries(
        Object.entries(productsForMainTag)
          .sort((a, b) => {
            // 판매기록이 없는 상품은 맨 뒤로 보냅니다.
            if (b[1].hasSales === false) return -1;
            if (field === '판매금액') {
              return (b[1].totalAmount ?? 0) - (a[1].totalAmount ?? 0);
            } else {
              return (b[1].totalVolume ?? 0) - (a[1].totalVolume ?? 0);
            }
          })
          .map(([key, value], index) => {
            return [key, { ...value, index: index + 1 }];
          })
      );
      await mergeProductGroupForCategory(category, {
        mainTags: {
          [mainTag]: {
            productsForMainTag: sortedProductsForMainTag,
          },
        },
      });
      logger.logConsole(`${logName} - ${category}/${mainTag} 상품 순서 ${field}순으로 일괄 변경`);
    } catch (error) {
      console.error(error);
      const description = errorObjectToString(error);
      logger.logConsole(`${logName} - ${category}/${mainTag} ${field}순으로 순서 변경 실패\nerror: ${description}`, {
        level: 'error',
      });
      notification.error({
        message: `${field}순으로 순서 변경에 실패했습니다.`,
        description,
      });
    }
    setLoading(false);
  };

  return (
    <div>
      {field}{' '}
      <Popconfirm
        title={`${field} 순으로 순서를 변경하시겠습니까?`}
        okText='네'
        cancelText='아니요'
        okButtonProps={{ loading }}
        onConfirm={changeIndexForAmount}>
        <Button icon={<SortDescendingOutlined />} size='small' loading={loading} type='text' />
      </Popconfirm>
    </div>
  );
};

export default TagHeaderWithSortButton;
