import { GridApi } from 'ag-grid-enterprise';
import { Button, message, notification } from 'antd';
import { FC, useCallback, useEffect, useState } from 'react';
import { map } from 'rxjs';
import { PromotionSectionDoc, PromotionSectionItem } from 'src/schema/schema-promotion-section';

import { errorObjectToString } from 'src/lib/1/util';
import {
  createPromotionSection,
  getProductById,
  getPromotionSection,
  observePromotionSection,
  updatePromotionSection,
} from 'src/lib/4/firebase-short-cut';
import { createOnRowDragEndHandler, onCellValueChangedWithUpdate } from 'src/lib/6/ag-grid-util';

import PromotionSectionTable from './PromotionSectionTable';

const onCellValueChanged = onCellValueChangedWithUpdate<PromotionSectionDoc>('공통 프로모션');
const onRowDragEnd = createOnRowDragEndHandler('promotionSection', 'positionIndex');
const onRowDragEndForChildren = async (params: any) => {
  const promotionId: string = params.node.data._id;
  const currentItems: PromotionSectionItem[] = params.node.data.items;
  if (!promotionId || !currentItems) {
    notification.error({ message: '프로모션 정보를 찾을 수 없습니다.' });
    return;
  }
  const newItems: PromotionSectionItem[] = [];
  (params?.api as GridApi)?.forEachNode((node, idx) => {
    if (node.data._id === promotionId) {
      newItems.push({
        id: node.data.id,
        index: idx + 1,
      });
    }
  });

  if (!newItems || newItems?.length === 0) {
    return;
  }

  await updatePromotionSection(promotionId, {
    items: newItems,
  });
  message.success('프로모션 상품 순서를 변경했습니다.');
};
/**
 * 프로모션 상품을 추가한다.
 */
const onSelectValueChangeWapper = async (params: any, value: string) => {
  const promotionId: string = params.data._id;
  const promotion = await getPromotionSection(promotionId);
  if (!promotion) {
    notification.error({ message: '프로모션 정보를 찾을 수 없습니다.' });
    return;
  }

  if (promotion.items.map((i) => i.id).includes(value)) {
    notification.error({ message: '이미 추가된 상품입니다.' });
    return;
  }
  const product = value ? await getProductById(value) : null;
  if (!product) {
    notification.error({ message: '상품을 찾을 수 없습니다.' });
    return;
  }

  const newItems = [
    ...(promotion.items.length > 0 ? promotion.items : []),
    { id: value, index: promotion.items.length + 1 },
  ];

  await updatePromotionSection(promotionId, {
    items: newItems,
  });
  message.success('프로모션에 상품을 추가했습니다.');
};

const CommonPromotionSectionDashboard: FC = () => {
  const [rowData, setRowData] = useState<PromotionSectionDoc[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const onCreatePromotionSection = useCallback(async () => {
    setLoading(true);
    try {
      await createPromotionSection({
        positionIndex: rowData.length + 1,
        storeCategory: null,
        isActive: false,
        title: '신규 프로모션',
        description: null,
        items: [],
        maxItemsToPreview: 10,
        direction: 'horizontal',
        isDeleted: false,
      });
    } catch (error) {
      console.error(error);
      const description = errorObjectToString(error);
      notification.error({ message: '프로모션 생성 실패', description });
    }
    setLoading(false);
  }, [rowData.length]);

  useEffect(() => {
    const promotionSubscription = observePromotionSection([['isDeleted', '==', false]])
      .pipe(map((docs) => docs.sort((a, b) => a.positionIndex - b.positionIndex)))
      .subscribe(setRowData);
    return () => {
      promotionSubscription.unsubscribe();
    };
  }, []);

  return (
    <div className='tabBody height100'>
      <div className='ag-theme-alpine height100 flexColumn'>
        <div className='flexRow flexEnd'>
          <Button onClick={onCreatePromotionSection} loading={loading}>
            신규 공통 프로모션 생성
          </Button>
        </div>
        <PromotionSectionTable
          type='common'
          rowData={rowData}
          onCellValueChanged={onCellValueChanged}
          onSelectValueChangeWapper={onSelectValueChangeWapper}
          onRowDragEndForChildren={onRowDragEndForChildren}
          onRowDragEnd={onRowDragEnd}
        />
      </div>
    </div>
  );
};

export default CommonPromotionSectionDashboard;
