import { StoreIssueDoc } from '@gooduncles/gu-app-schema';
import { Checkbox } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import dayjs from 'dayjs';
import { FC, useEffect, useState } from 'react';
import { map } from 'rxjs';

import { formatDate } from 'src/lib/1/date-util';
import { observeStoreIssue } from 'src/lib/4/firebase-short-cut';

import DatePickerWithArrows from 'src/components/Common/DatePickerWithArrows/DatePickerWithArrows';
import PrintWindow from 'src/components/PrintWindow/PrintWindow';

import classes from './StoreIssuePrint.module.scss';

const StoreIssuePrint: FC = () => {
  const [rowData, setRowData] = useState<StoreIssueDoc[]>([]);
  const [date, setDate] = useState<dayjs.Dayjs | null>(dayjs());
  const [selectedStatus, setSelectedStatus] = useState(['입력']);

  const onSelectedStatusChange = (checkedValues: CheckboxValueType[]) => {
    setSelectedStatus(checkedValues as string[]);
  };
  useEffect(() => {
    if (date) {
      const whereDate = formatDate(date.toDate(), "yyyy-MM-dd'T'00:00:00+0900");
      const subscription = observeStoreIssue([['date', '==', whereDate]])
        .pipe(
          map((docs) =>
            docs
              .filter((issue) => issue.isDeleted !== true)
              .filter(
                (doc) => selectedStatus.includes(doc.status ?? '') && ['배송', '회수'].includes(doc.category ?? '')
              )
          )
        )
        .subscribe(setRowData);
      return () => {
        subscription.unsubscribe();
      };
    }
  }, [date, selectedStatus]);

  return (
    <div className='height100 flexColumn'>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <DatePickerWithArrows date={date} setDate={setDate} />
        <Checkbox.Group
          options={[
            {
              label: '입력',
              value: '입력',
            },
            {
              label: '완료',
              value: '완료',
            },
          ]}
          value={selectedStatus}
          onChange={onSelectedStatusChange}
        />
      </div>
      <PrintWindow>
        <div className={classes.storeIssuePrintContainer}>
          <div className={`${classes.gridRow} ${classes.gridHeader}`}>
            <div>일자(배송일)</div>
            <div>구분</div>
            <div>매장</div>
            <div>특이사항</div>
            <div>고객노출</div>
          </div>
          {rowData.map((row) => (
            <div key={row._id} className={classes.gridRow}>
              <div>{formatDate(row.date, 'yyyy. M. d')}</div>
              <div>{row.category}</div>
              <div>{`${row.storeName ?? ''}(${row.storeCode ?? '?'})`}</div>
              <div className={classes.memo}>{row.memo}</div>
              <div>{row.message}</div>
            </div>
          ))}
        </div>
      </PrintWindow>
    </div>
  );
};

export default StoreIssuePrint;
