import { DeliveryPerformanceDoc } from '@gooduncles/gu-app-schema';
import { Tabs } from 'antd';
import { Dayjs } from 'dayjs';
import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { RangeEventValue, formatDate, getDefaultDates } from 'src/lib/1/date-util';
import { observeDeliveryPerformance } from 'src/lib/4/firebase-short-cut';

import { useTitle } from 'src/hooks/useTitle';

import TableOptions from 'src/components/Common/TableOptions/TableOptions';

import DeliveryPerformanceChart from './DeliveryPerformanceChart';
import DeliveryPerformanceTable from './DeliveryPerformanceTable';

const defaultValue = getDefaultDates(7, 0);
const dayjsesToStringDateWhere = (left: Dayjs, right: Dayjs): [string, string] => {
  const leftDate = formatDate(left.toDate(), 'yyyy-MM-dd');
  const rightDate = formatDate(right.toDate(), 'yyyy-MM-dd');
  return [leftDate, rightDate];
};

interface DeliveryPerformanceDashboardProps {
  tab: string;
}

const DeliveryPerformanceDashboard: FC<DeliveryPerformanceDashboardProps> = ({ tab }) => {
  useTitle(`GU 관리자 | 배송 실적 - ${tab}`);
  const navigate = useNavigate();
  const [rowData, setRowData] = useState<DeliveryPerformanceDoc[]>([]);
  const [dates, setDates] = useState<RangeEventValue>(null);
  const [leftDate, rightDate] = useMemo(
    () =>
      dates?.[0] && dates?.[1]
        ? dayjsesToStringDateWhere(dates[0], dates[1])
        : dayjsesToStringDateWhere(defaultValue[0], defaultValue[1]),
    [dates]
  );

  const onTabChange = (key: string) => {
    navigate(`../${key}`);
  };

  useEffect(() => {
    const subscription = observeDeliveryPerformance([], {
      sortKey: 'date',
      orderBy: 'desc',
      startValue: leftDate,
      endValue: rightDate,
    }).subscribe(setRowData);
    return () => subscription.unsubscribe();
  }, [leftDate, rightDate]);

  return (
    <div className='height100 flexColumn'>
      <TableOptions setDates={setDates} defaultValue={defaultValue} />
      <Tabs
        type='card'
        activeKey={tab}
        style={{ height: '100%', overflow: 'scroll' }}
        tabBarStyle={{ marginBottom: 0 }}
        onChange={onTabChange}
        items={[
          {
            key: 'table',
            label: '테이블',
            children: <DeliveryPerformanceTable rowData={rowData} />,
          },
          {
            key: 'chart-order-amount',
            label: '배송금액 차트',
            children: <DeliveryPerformanceChart rowData={rowData} seriesYKey='OrderAmount' />,
          },
          {
            key: 'chart-spot',
            label: '배송스팟 차트',
            children: <DeliveryPerformanceChart rowData={rowData} seriesYKey='SpotCount' />,
          },
          {
            key: 'chart-store',
            label: '배송매장 차트',
            children: <DeliveryPerformanceChart rowData={rowData} seriesYKey='StoreCount' />,
          },
        ]}
      />
    </div>
  );
};

export default DeliveryPerformanceDashboard;
