import { ProductDoc } from '@gooduncles/gu-app-schema';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { ColDef, IsRowSelectable } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { ForwardRefRenderFunction, forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import { useAuth } from 'src/stores/auth-context';
import { dailyProductSyncValueSetter, onCellValueChanged } from 'src/utils/daily-product-sync-util';

import { SupplierPurchaseConversionItem } from 'src/lib/1/schema-purchase-conversion';
import { numberFormatter } from 'src/lib/6/ag-grid-util';

import useSuppliersByIds from 'src/hooks/useSuppliersByIds';

import { ProductRequestTableRowData } from '../ProductRequestDashboard';
import CreateManualRequestModal from './CreateManualRequestModal/CreateManualRequestModal';

interface ProductRequestTableProps {
  /** 'yyyy-MM-dd' */
  date: string;
  rowData: ProductRequestTableRowData[];
  products: ProductDoc[];
  /** null: 로딩중 */
  existedDailyProductSync: boolean | null;
  setSelectedRows: React.Dispatch<React.SetStateAction<ProductRequestTableRowData[]>>;
}

export type ProductRequestTableHandler = {
  deselectAll: () => void;
};

const defaultColDef: ColDef<ProductRequestTableRowData> = {
  sortable: true,
  resizable: true,
  filter: true,
  floatingFilter: true,
};

const 제이엘엠통상 = 'gJdjyxtyTFPlFOanruJY';
const 프레시원 = 'vHvBxonMffASupril333';

const isRowSelectable: IsRowSelectable<ProductRequestTableRowData> = (params) => {
  const { newCost, newPrice } = params.data ?? {};
  return !!newCost || !!newPrice;
};

const ProductRequestTableForCost: ForwardRefRenderFunction<ProductRequestTableHandler, ProductRequestTableProps> = (
  { rowData, date, products, existedDailyProductSync, setSelectedRows },
  forwardRef
) => {
  const { user } = useAuth();
  const [gridRef, setGridRef] = useState<AgGridReact<ProductRequestTableRowData> | null>(null);
  const { suppliers } = useSuppliersByIds([제이엘엠통상, 프레시원]);
  const conversionTable = useMemo(
    () => (suppliers ? suppliers?.flatMap((supplier) => supplier?.conversionTable ?? []) : []),
    [suppliers]
  );

  const columnDefs: ColDef<ProductRequestTableRowData>[] = useMemo(
    () => [
      {
        headerName: '코드',
        field: 'product.productId',
        width: 134,
        filter: 'agTextColumnFilter',
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
      },
      {
        headerName: '구분',
        field: 'supplierLabel',
        width: 100,
      },
      {
        headerName: '품명',
        field: 'product.fullName',
        width: 260,
        filter: 'agTextColumnFilter',
        wrapText: true,
        autoHeight: true,
        cellStyle: () => ({
          lineHeight: '1.1em',
          display: 'flex',
          alignItems: 'center',
          letterSpacing: '-0.025em',
          wordBreak: 'keep-all',
        }),
      },
      {
        headerName: '필요',
        field: 'totalRequestVolume',
        width: 100,
        filter: 'agNumberColumnFilter',
        cellStyle: { backgroundColor: '#ffff00' },
      },
      {
        headerName: '발주',
        field: 'totalPurchaseVolume',
        width: 100,
        filter: 'agNumberColumnFilter',
        cellStyle: (params) => {
          return params.value > 0 ? { backgroundColor: '#b6e1cd' } : null;
        },
      },
      {
        headerName: '창고',
        field: 'totalOutboundVolume',
        width: 100,
        filter: 'agNumberColumnFilter',
        cellStyle: (params) => {
          return params.value > 0 ? { backgroundColor: '#d0e2f4' } : null;
        },
      },
      {
        headerName: '매입처',
        field: 'supplierName',
        width: 110,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: '발주코드',
        width: 140,
        filter: 'agTextColumnFilter',
        valueGetter: (params: any) => {
          const { product } = params.data as ProductRequestTableRowData;
          const conversionTable = params.context.conversionTable as SupplierPurchaseConversionItem[];
          const purchaseProductName = conversionTable?.find(
            (item) => item?.productId === product.productId
          )?.purchaseProductName;
          return purchaseProductName;
        },
      },
      {
        headerName: '현재 매입가',
        field: 'product.cost',
        width: 120,
        filter: 'agNumberColumnFilter',
        valueFormatter: numberFormatter,
      },
      {
        headerName: '*신규 매입가',
        field: 'newCost',
        width: 120,
        filter: 'agNumberColumnFilter',
        editable: (params) => {
          const { existedDailyProductSync } = params.context;
          return existedDailyProductSync !== null;
        },
        valueFormatter: numberFormatter,
        valueSetter: dailyProductSyncValueSetter,
        onCellValueChanged: (params) => onCellValueChanged(params, date),
      },
      {
        headerName: '요청 변경',
        field: 'requests',
        width: 120,
        filter: false,
        cellRenderer: (params: any) => {
          const { requests, product, supplierName } = params.data as ProductRequestTableRowData;
          return user?.email ? (
            <CreateManualRequestModal
              date={date}
              products={products}
              productRequests={requests}
              product={product}
              supplierName={supplierName ?? '알 수 없음'}
              userEmail={user.email}
            />
          ) : null;
        },
      },
    ],
    [date, products, user?.email]
  );

  useImperativeHandle(forwardRef, () => ({
    deselectAll: () => {
      gridRef?.api?.deselectAll();
    },
  }));

  return (
    <div className='ag-theme-alpine height100' style={{ backgroundColor: 'white' }}>
      <AgGridReact
        ref={setGridRef}
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        alwaysShowHorizontalScroll={true}
        getRowId={(params) => params.data.product._id}
        context={{ conversionTable, existedDailyProductSync }}
        onSelectionChanged={(e) => setSelectedRows(e.api.getSelectedRows())}
        // 행 선택
        rowSelection={'multiple'}
        isRowSelectable={isRowSelectable}
        // 행 선택을 켜놓더라도 개별 셀의 내용을 복사할 수 있도록한다.
        enableCellTextSelection={true}
      />
    </div>
  );
};

export default forwardRef(ProductRequestTableForCost);
