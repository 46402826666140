import { SearchOutlined } from '@ant-design/icons';
import { Input, InputRef, message } from 'antd';
import { debounce } from 'lodash-es';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { escapeStringRegExp } from 'src/lib/1/util';

import classes from './SearchWithPreview.module.scss';

const { Search } = Input;

interface SearchWithPreviewProps {
  rowData: string[] | null;
  onSearch: (value: string) => void;
  placeholder?: string;
  /**
   * 검색어를 선택하는 방식으로만 사용할 때 true로 설정
   * 선택하지 않으면 이벤트가 발생하지 않음
   */
  selectOnly?: boolean;
  /** 마우스 클릭으로 선택할 때에 미리보기 창을 닫을지를 설정한다. */
  closeOnClick?: boolean;
  disabled?: boolean;
}

const SearchWithPreview: FC<SearchWithPreviewProps> = ({
  placeholder = '검색어를 입력해주세요',
  rowData,
  selectOnly = false,
  onSearch,
  closeOnClick,
  disabled,
}) => {
  // 사용자가 입력하는 값
  const [searchValue, setSearchValue] = useState<string>('');
  const searchValueRegex = escapeStringRegExp(searchValue);
  const [selectedItemIdx, setSelectedItemIdx] = useState<number | null>(null);
  const [focus, setFocus] = useState<boolean>(false);
  const handleInputChangeWithDebounce = useMemo(() => debounce((e) => setFocus(e.target.value.length > 0), 200), []);
  const inputRef = useRef<InputRef>(null);
  const searchPreviewRef = useRef<HTMLDivElement>(null);
  const rowDataWithMatch = rowData
    ?.map((sKeyword) => {
      const match = searchValueRegex.exec(sKeyword);
      return [match, sKeyword] as [RegExpExecArray | null, string];
    })
    .filter(([match]) => match);

  const handleOnSearch = useCallback(
    (value: string) => {
      if (value.length > 0 && selectedItemIdx !== null) {
        const selectedItem = rowDataWithMatch?.[selectedItemIdx]?.[1];
        if (selectedItem) {
          onSearch(selectedItem);
          inputRef.current?.blur();
        }
        return;
      }
      if (selectOnly) {
        message.info('검색 결과를 선택해주세요');
        return;
      }
      onSearch(value);
      inputRef.current?.blur();
    },
    [onSearch, rowDataWithMatch, selectOnly, selectedItemIdx]
  );

  const onKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>, previewLength: number) => {
    if (!('key' in event)) {
      return;
    }
    if (event.key === 'Escape') {
      setSearchValue('');
      setSelectedItemIdx(null);
      return;
    }

    if (event.key === 'ArrowUp') {
      setSelectedItemIdx((prev) => {
        return prev === null || prev === 0 ? null : prev - 1;
      });
      return;
    }
    if (event.key === 'ArrowDown') {
      setSelectedItemIdx((prev) => {
        return prev === null ? 0 : prev === previewLength - 1 ? prev : prev + 1;
      });
      return;
    }

    if (event.key !== 'Enter') {
      setSelectedItemIdx(null);
      return;
    }
  }, []);

  const PreviewItem = (
    match: RegExpExecArray | null,
    keyword: string,
    idx: number,
    value: string,
    selectedIdx: number | null
  ) => {
    if (match) {
      const index = match.index;
      const word = match[0];
      const prefix = index > 0 ? keyword.substring(0, index) : '';
      const suffix = index !== keyword.length - 1 ? keyword.substring(index + value.length, keyword.length) : '';
      return (
        <p
          key={keyword}
          className={selectedIdx === idx ? classes.selected : ''}
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onClick={() => {
            onSearch(keyword);
            if (closeOnClick) {
              setFocus(false);
            }
          }}>
          <SearchOutlined />
          {prefix}
          <b>{word}</b>
          {suffix}
        </p>
      );
    }
  };

  useEffect(() => {
    if (searchPreviewRef) {
      const searchPreview = searchPreviewRef.current;
      if (searchPreview) {
        const selected = searchPreview.querySelector(`.${classes.selected}`);
        if (selected) {
          searchPreview.scrollTop = (selected as any).offsetTop - 100;
        }
      }
    }
  }, [searchPreviewRef, selectedItemIdx]);

  return (
    <div className={classes.searchContainer}>
      <div className={classes.searchBar}>
        <Search
          allowClear
          size='large'
          placeholder={placeholder}
          ref={inputRef}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          value={searchValue}
          loading={!rowData}
          disabled={disabled}
          onSearch={handleOnSearch}
          onChange={(e) => {
            setSearchValue(e.target.value);
            handleInputChangeWithDebounce(e);
            setSelectedItemIdx(null);
          }}
          onKeyDown={debounce((e) => onKeyDown(e, rowDataWithMatch?.length ?? 0), 10)}
        />
      </div>
      <div className={`${classes.searchPreview} ${focus ? '' : classes.hide}`} ref={searchPreviewRef}>
        {rowDataWithMatch?.map(([match, sKeyword], idx) =>
          PreviewItem(match, sKeyword, idx, searchValue, selectedItemIdx)
        )}
      </div>
    </div>
  );
};

export default SearchWithPreview;
