import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { ColDef } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { FC, useCallback, useMemo, useState } from 'react';

import { StoreDoc } from '@gooduncles/gu-app-schema';
import { NotificationDoc } from 'src/lib/3/schema-notification';
import { timestampFormatter } from 'src/lib/6/ag-grid-util';

import DownloadRawDataCell from 'src/components/Common/DownloadRawDataCell/DownloadRawDataCell';

type RowData = NotificationDoc & {
  store?: StoreDoc;
};

interface NotificationTableProps {
  rowData: RowData[];
}

const defaultColDef: ColDef<RowData> = {
  flex: 1,
  sortable: true,
  resizable: true,
  filter: true,
  enableRowGroup: true,
};

const NotificationTable: FC<NotificationTableProps> = ({ rowData }) => {
  const [gridRef, setGridRef] = useState<AgGridReact<RowData> | null>(null);
  const columnDefs: ColDef<RowData>[] = useMemo(() => {
    return [
      { field: '_id', hide: true },
      {
        field: '_timeCreate',
        hide: true,
        valueFormatter: timestampFormatter,
      },
      {
        field: 'store.storeName',
        headerName: '수신자',
      },
      {
        field: 'title',
        headerName: '제목',
      },
      {
        field: 'body',
        headerName: '내용',
      },
      {
        field: 'unread',
        headerName: '읽음 여부',
        valueGetter: (params) => (params.data?.unread ? '✉️ 읽지 않음' : '✅ 읽음'),
      },
      {
        field: 'action.type',
        headerName: '알림 타입',
      },
      {
        field: 'action.uri',
        headerName: '링크',
      },
      {
        headerName: '액션',
        cellRenderer: DownloadRawDataCell,
      },
    ];
  }, []);

  const autoSizeAll = useCallback(() => {
    gridRef?.columnApi?.autoSizeAllColumns(true);
  }, [gridRef]);

  return (
    <section className='ag-theme-alpine' style={{ flex: 1 }}>
      <AgGridReact
        ref={setGridRef}
        rowData={rowData}
        onGridReady={autoSizeAll}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        // 편집 완료후 스크롤 이동을 막는다.
        suppressScrollOnNewData={true}
        alwaysShowHorizontalScroll={true}
        // group 관련
        autoGroupColumnDef={{
          minWidth: 200,
        }}
        rowGroupPanelShow={'always'}
      />
    </section>
  );
};

export default NotificationTable;
