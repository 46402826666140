import { OrderStatusCode } from '@gooduncles/gu-app-schema';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { groupBy } from 'lodash-es';
import { FC, useEffect, useState } from 'react';
import { combineLatest } from 'rxjs';
import { v4 } from 'uuid';

import { formatDate } from 'src/lib/1/date-util';
import { PurchaseOrderPreview } from 'src/lib/3/schema-purchase-order';
import { observeOrder, observeStore, observeSupplier } from 'src/lib/4/firebase-short-cut';
import {
  createPurchaseOrderPreviewMessage,
  getPurchaseOrderDate,
  getPurchaseOrderProducts,
  groupPurchaseItemsBySupplier,
} from 'src/lib/4/purchase-order-util';

import { useTitle } from 'src/hooks/useTitle';

import PurchaseOrderPreviewTable from './PurchaseOrderPreviewTable/PurchaseOrderPreviewTable';

interface PurchaseOrderPreviewDashboardProps {
  deliveryDateRules: number[] | undefined;
}

const PurchaseOrderPreviewDashboard: FC<PurchaseOrderPreviewDashboardProps> = ({ deliveryDateRules }) => {
  useTitle('GU 관리자 | 상품 발주 미리보기');
  const [rowData, setRowData] = useState<PurchaseOrderPreview[]>([]);
  const [showHeader, setShowHeader] = useState(false);
  const [includeSubmitted, setIncludeSubmitted] = useState<boolean>(false);

  const onChangeShowHeader = (e: CheckboxChangeEvent) => setShowHeader(e.target.checked);
  const onChangeIncludeSubmitted = (e: CheckboxChangeEvent) => setIncludeSubmitted(e.target.checked);

  useEffect(() => {
    if (!deliveryDateRules) {
      return;
    }
    const storeSubscription = observeStore();
    const supplierSubscription = observeSupplier([['isDeleted', '==', false]]);
    const acceptedOrderSubscription = observeOrder([
      includeSubmitted
        ? ['orderStatus', 'in', [OrderStatusCode.ACCEPTED, OrderStatusCode.SUBMITTED]]
        : ['orderStatus', '==', OrderStatusCode.ACCEPTED],
    ]);
    /**
     * 상품에 등록된 매입처ID로 매입처를 찾으면 안된다... 상품 구매 시점에 확정된 데이터와 추후 어드민에서
     * 변경한 데이터가 다를 수 있다.
     * 때문에 상품 ID를 갖는 매입처를 역으로 찾아야한다.
     */
    const subscription = combineLatest([storeSubscription, supplierSubscription, acceptedOrderSubscription]).subscribe(
      ([stores, suppliers, orders]) => {
        // 주문일을 기준으로 발주일을 계산한다.
        const date = getPurchaseOrderDate(deliveryDateRules, orders?.[0]?.orderDate ?? Date.now());
        const messagePrefix = `안녕하세요. 이웃삼촌입니다.\n${formatDate(
          new Date(date),
          'L월 d일'
        )} 주문 드립니다.\n\n`;
        const messageSuffix = '\n\n감사합니다.';
        // 발주 대상 상품 목록 생성
        const purchaseOrderProducts = getPurchaseOrderProducts(orders, stores);
        // 매입처 별로 묶은 발주 상품 목록
        const purchaseItemGroupBySupplier = groupPurchaseItemsBySupplier(purchaseOrderProducts, suppliers);

        // 4. 맞춤표를 적용한다.
        const purchaseOrderList = purchaseItemGroupBySupplier.map((group) => {
          const groupByProductId = groupBy(group.parchaseOrderProducts, (product) => product.productId);
          const purchaseList = Object.entries(groupByProductId)
            .map(([productId, parchaseOrderProducts]) =>
              createPurchaseOrderPreviewMessage(productId, group.supplier, parchaseOrderProducts)
            )
            .map((item) => item.trim());

          const unknown = group.supplier?._id ?? 'unknown';
          return {
            date,
            supplierId: v4(),
            supplierName: group.supplier?.name ?? unknown,
            supplierTel: group.supplier?.tel ?? 'unknown',
            purchaseMethod: group.supplier?.purchaseMethod ?? 'etc',
            messagePrefix,
            message: purchaseList.join('\n'),
            purchaseList,
            messageSuffix,
            parchaseOrderProducts: group.parchaseOrderProducts.map((product) => {
              const purchaseProductName =
                group.supplier?.conversionTable?.find((c) => c.productId === product.productId)?.purchaseProductName ??
                'unknown';
              const purchaseMultiplyUnit =
                group.supplier?.conversionTable?.find((c) => c.productId === product.productId)?.purchaseMultiplyUnit ??
                1;
              return {
                ...product,
                purchaseProductName,
                purchaseMultiplyUnit,
              };
            }),
            isDeleted: false,
          };
        });
        setRowData(purchaseOrderList);
      }
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [deliveryDateRules, includeSubmitted]);

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <div style={{ paddingBottom: 8, display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
        <p style={{ lineHeight: 1.5 }}>
          • 오늘의 주문과 매입처 정보에 따른 발주서를 미리볼 수 있습니다.
          <br />• 미생성된 발주내용으로 편집이 불가능합니다.
        </p>
        <div>
          <Checkbox checked={showHeader} onChange={onChangeShowHeader}>
            머릿말 표시
          </Checkbox>
          <Checkbox checked={includeSubmitted} onChange={onChangeIncludeSubmitted}>
            수락대기 주문 포함
          </Checkbox>
        </div>
      </div>
      <PurchaseOrderPreviewTable rowData={rowData} showHeader={showHeader} />
    </div>
  );
};

export default PurchaseOrderPreviewDashboard;
