import { LoadingOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { FC, useState } from 'react';

import usePickupTask from 'src/hooks/usePickupTask';

import SubTitle from 'src/atomic-components/atoms/Content/SubTitle';
import PartnerInfoRow from 'src/atomic-components/atoms/PartnerInfoRow/PartnerInfoRow';
import TaskOrderInfoBox from 'src/atomic-components/atoms/TaskOrderInfoBox/TaskOrderInfoBox';

import classes from './EditTaskModal.module.scss';

import ChangeTaskPartnerPopover from '../ChangeTaskPartnerPopover/ChangeTaskPartnerPopover';
import TaskInfoBox from '../TaskInfoBox/TaskInfoBox';

interface EditPickupTaskModalProps {
  pickupTaskId: string;
}

const type = 'pickup';

const EditPickupTaskModal: FC<EditPickupTaskModalProps> = ({ pickupTaskId }) => {
  const [open, setOpen] = useState(false);
  const { pickupTask, isLoading } = usePickupTask(open ? pickupTaskId : undefined);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Button disabled={open} type='primary' onClick={handleOpen}>
        상세
      </Button>
      <Modal
        open={open}
        maskClosable={false}
        centered
        width='1200px'
        cancelText='닫기'
        styles={{
          content: {
            backgroundColor: 'var(--gray75)',
          },
          header: {
            fontSize: '18px',
            fontWeight: '700',
            fontFamily: 'Pretendard',
            backgroundColor: 'inherit',
          },
        }}
        okButtonProps={{ style: { display: 'none' } }}
        onCancel={handleClose}
        title={`픽업 / ${pickupTask?.date ?? '?'}`}>
        <div className={classes.modalContentContainer}>
          {isLoading ? (
            <LoadingOutlined />
          ) : (
            <>
              <section className={classes.leftSection}>
                <SubTitle
                  title='담당 파트너'
                  rightSection={pickupTask ? <ChangeTaskPartnerPopover type={type} task={pickupTask} /> : undefined}
                />
                <PartnerInfoRow partnerId={pickupTask?.partnerId} />
                <TaskInfoBox type={type} task={pickupTask} />
              </section>
              <section className={classes.rightSection}>
                {pickupTask && (
                  <TaskOrderInfoBox
                    collection={pickupTask.orderColleciton}
                    orderId={pickupTask.orderId}
                    taskItems={pickupTask.pickupItems}
                  />
                )}
              </section>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default EditPickupTaskModal;
