import { FileExcelOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { findIndex, groupBy, map, partition, sortBy, sumBy } from 'lodash-es';

import { jsonToExcelFile } from 'src/lib/1/xlsx-util';

/**
 * '상품명 등등 기타 내용 2개' -> ['상품명 등등 기타 내용', '2개']
 */
const nonGreedySpaceRegex = /(.*?)\s(\S+)$/;

/**
 * 같은 코드의 상품이 있는 경우 병합한다.
 */
const aggregateByKey = (items: [string, number][]): [string, string][] => {
  const aggregated: [string, string][] = map(
    groupBy(items, (item) => item[0]),
    (group, key) => {
      const [arr, hasError] = partition(group, (item) => item[1] > 0);
      const sum = sumBy(arr, (item) => item[1]);
      return [key, sum + (hasError.length > 0 ? ' (일부 값 오류)' : '')];
    }
  );
  // 최대한 원본 순서에 가깝게 정렬한다.
  return sortBy(aggregated, (item) => {
    return findIndex(items, ([key]) => key === item[0]);
  });
};

/**
 * 매입처코드를 엑셀파일의 컬럼으로 매핑한다.
 */
const mapKeyToSheetColumn = (items: [string, string][], supplierName: string) => {
  if (supplierName === '제이엘엠통상') {
    return items.map((item) => ['', item[0], '', item[1], '', '', '', '', '', '', '']);
  }

  return items.map((item) => [item[0], item[1]]);
};

const DownloadPurchaseOrderCellRenderer = (props: any) => {
  const value: string[] = props.value;
  const name = props.name ?? '이름 생성 실패';

  const onDownloadToExcel = () => {
    const items: [string, number][] = value.map((item) => {
      const matches = item.match(nonGreedySpaceRegex);
      if (!matches) return [item, -1];
      const number = matches[2].match(/(\d+\.\d+|\d+)/g)?.join('') ?? '-1';
      return [matches[1], Number(number)];
    });

    const aggregatedItems = aggregateByKey(items);
    const mappedItems = mapKeyToSheetColumn(aggregatedItems, props.data.supplierName);

    if (props.data.supplierName === '제이엘엠통상') {
      jsonToExcelFile(mappedItems, name, [
        ['No', '품목코드', '품명', '수량', '규격', '기본(낱개)', '단가', '금액', '할인율', '비고', '과세유형'],
      ]);
    } else {
      jsonToExcelFile(mappedItems, name, [['매입처코드', '수량']]);
    }
  };

  if (!value) return null;

  return (
    <Button icon={<FileExcelOutlined style={{ color: 'var(--green700)' }} />} onClick={onDownloadToExcel}>
      엑셀Export
    </Button>
  );
};

export default DownloadPurchaseOrderCellRenderer;
