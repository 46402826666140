import { PartnersUserDoc } from '@gooduncles/gu-app-schema';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { PartnersState } from '../models/partnersModel';

const initialState: PartnersState = {
  partnersUsers: [],
};

const partnersSlice = createSlice({
  name: 'partners',
  initialState,
  reducers: {
    setPartnersUsers: (state, action: PayloadAction<PartnersUserDoc[]>) => {
      state.partnersUsers = action.payload;
    },
  },
});

export const selectPartnersUsers = (state: { partners: PartnersState }) => state.partners.partnersUsers;
export const selectActiveDriverPartnersUsers = (state: { partners: PartnersState }) =>
  state.partners.partnersUsers.filter((user) => user.deliveryDriverStatus.isActive);

export const { setPartnersUsers } = partnersSlice.actions;

export default partnersSlice.reducer;
