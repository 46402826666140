import { DeliveryRouteHistoryDoc } from '@gooduncles/gu-app-schema';
import { notification } from 'antd';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAuth } from 'src/stores/auth-context';

import { formatDate, getDefaultDeliveryDate } from 'src/lib/1/date-util';
import { errorObjectToString } from 'src/lib/1/util';
import { getDeliveryRouteHistory } from 'src/lib/4/firebase-short-cut';

const defaultValue = getDefaultDeliveryDate();

const useDeliveryRouteHistoryForDate = () => {
  const { setAuthLoading } = useAuth();
  const [date, setDate] = useState<dayjs.Dayjs | null>(defaultValue);
  const dateForWhere = useMemo(() => formatDate(date ? date?.toDate() : new Date(), 'yyyy-MM-dd'), [date]);
  const [deliveryRouteHistory, setDeliveryRouteHistory] = useState<DeliveryRouteHistoryDoc>();

  /**
   * 날짜에 해당하는 배송 동선을 불러온다.
   */
  const getDeliveryRouteHistoryForDate = useCallback(
    async (date0: string) => {
      setAuthLoading(true);
      try {
        const dateForWhere = formatDate(date0, 'yyyy-MM-dd');
        const response = await getDeliveryRouteHistory(dateForWhere);
        setDeliveryRouteHistory(response);
      } catch (error) {
        console.error(error);
        const description = errorObjectToString(error);
        notification.error({
          message: '배송 동선을 불러오는 중 오류가 발생했습니다.',
          description,
        });
      }
      setAuthLoading(false);
    },
    [setAuthLoading]
  );

  useEffect(() => {
    if (dateForWhere) {
      getDeliveryRouteHistoryForDate(dateForWhere);
    }
  }, [dateForWhere, getDeliveryRouteHistoryForDate]);

  return {
    date,
    dateForWhere,
    setDate,
    deliveryRouteHistory,
  };
};

export default useDeliveryRouteHistoryForDate;
