import { CloudUploadOutlined } from '@ant-design/icons';
import { ChangeEvent, DragEvent, FC, useCallback, useState } from 'react';

import classes from './FileUploadArea.module.scss';

interface FileUploadAreaProps {
  fileToUpload: File | undefined;
  setFile: (file: File) => void;
  subTitle?: string;
}

/**
 * 파일을 드래그해서 업로드 할 수 있다
 */
const FileUploadArea: FC<FileUploadAreaProps> = ({
  fileToUpload,
  setFile,
  subTitle = '상품 목록 규격의 엑셀파일만 지원합니다.',
}) => {
  const [fileOnDropZone, setFileOnDropZone] = useState<string>();

  /** over, enter, leave 이벤트를 받습니다. */
  const onDragEvent = useCallback(
    (e: DragEvent<HTMLDivElement>, action?: 'enter' | 'leave') => {
      // Drop을 허용하기 위해 기본 동작을 무시한다.
      e.preventDefault();
      e.stopPropagation();

      if (action === 'leave' && fileOnDropZone !== undefined) {
        setFileOnDropZone(undefined);
        return;
      }

      if (fileToUpload === undefined) {
        // 현재 아무 파일도 존재하지 않는다면 전체 스크린에 해당한다.
        fileOnDropZone !== 'multi' && setFileOnDropZone('multi');
        return;
      } else {
        const type = e.currentTarget.getAttribute('type');
        if (type && type !== fileOnDropZone) {
          setFileOnDropZone(type);
          return;
        }
      }

      setFileOnDropZone(undefined);
    },
    [fileToUpload, fileOnDropZone]
  );

  /** input 이벤트로 얻은 파일을 처리한다. */
  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    // 기본 동작(open link)을 무시한다.
    e.preventDefault();

    const { files } = e.target;

    if (files) {
      setFile(files[0]);
    }
  };

  /** drag & drop 이벤트로 얻은 파일을 처리한다. */
  const onDrop = useCallback(
    (e: DragEvent<HTMLDivElement>) => {
      // 기본 동작(open link)을 무시한다.
      e.preventDefault();
      e.stopPropagation();

      setFileOnDropZone(undefined);

      const { files } = e.dataTransfer;
      if (files.length > 0) {
        setFile(files[0]);
      }
    },
    [setFile]
  );

  return (
    <div className={`${classes.uploadArea} ${fileOnDropZone ? classes['file-over'] : ''}`}>
      <label htmlFor='multi_file_input'>
        <div
          className={classes.dropZone}
          onDragOver={onDragEvent}
          onDragEnter={(e) => onDragEvent(e, 'enter')}
          onDragLeave={(e) => onDragEvent(e, 'leave')}
          onDrop={onDrop}
        />
        <CloudUploadOutlined className='upload-icon' />
        <h2>클릭 또는 파일을 영역 위로 드래그해주세요.</h2>
        <p>{subTitle}</p>
      </label>
      <input
        className={classes.noneDisplayInput}
        id='multi_file_input'
        type='file'
        accept='.xlsx,.xls'
        onChange={onInputChange}
        multiple
        // 동일한 이름의 파일을 추가하는 경우에도 이벤트가 발생하도록 초기화한다.
        onClick={(e) => ((e.target as any).value = null)}
      />
    </div>
  );
};

export default FileUploadArea;
