import { BareFetcher } from 'swr';
import useImmutableSWR from 'swr/immutable';

import { AvailableYearForStats } from 'src/lib/1/constant';
import { MonthlyOrderStatsForStoreDoc } from 'src/lib/2/schema';
import { WHERE } from 'src/lib/3/firebase-manager';
import { getMonthlyOrderStatsForStore } from 'src/lib/4/firebase-short-cut';

type options = {
  selectedYears: AvailableYearForStats[];
};

const fetcher: BareFetcher<MonthlyOrderStatsForStoreDoc[]> = ([, options]: [string, options]) => {
  const where: WHERE[] = options.selectedYears ? [['year', 'in', options.selectedYears]] : [];
  return getMonthlyOrderStatsForStore(where);
};

const useMonthlyOrderStatsForStore = (options: options) => {
  const { data: monthlyOrderStatsForStore, isValidating: monthlyOrderStatsForStoreLoading } = useImmutableSWR(
    options.selectedYears.length > 0 ? ['monthlyOrderStatsForStore', options] : null,
    fetcher
  );
  return {
    monthlyOrderStatsForStore,
    monthlyOrderStatsForStoreLoading,
  };
};

export default useMonthlyOrderStatsForStore;
