import { FC, useEffect, useMemo, useState } from 'react';
import { combineLatest } from 'rxjs';

import { RangeEventValue, dayjsesToDateWhere, getDefaultDates } from 'src/lib/1/date-util';
import { FirebaseManager } from 'src/lib/3/firebase-manager';
import { LogSerachDoc } from 'src/lib/3/schema-log';
import { observeStore } from 'src/lib/4/firebase-short-cut';

import TableOptions from '../Common/TableOptions/TableOptions';
import SearchLogTable, { SearchLogWithStore } from './SearchLogTable/SearchLogTable';

const defaultDates = getDefaultDates();

const firebaseManager = FirebaseManager.getInstance();
const logSearchPath = 'logSearch';

const SearchLog: FC = () => {
  const [rowData, setRowData] = useState<SearchLogWithStore[]>([]);
  const [dates, setDates] = useState<RangeEventValue>(null);
  const [leftDate, rightDate] = useMemo(() => {
    return dates?.[0] && dates?.[1]
      ? dayjsesToDateWhere(dates[0], dates[1])
      : dayjsesToDateWhere(defaultDates[0], defaultDates[1]);
  }, [dates]);

  useEffect(() => {
    const storeObervable = observeStore();
    const logSearchObervable = firebaseManager.observeCollection<LogSerachDoc>(logSearchPath, [], {
      sortKey: '_timeCreate',
      orderBy: 'desc',
      startValue: leftDate,
      endValue: rightDate,
    });

    const subscription = combineLatest([storeObervable, logSearchObervable]).subscribe(([stores, logSearch]) => {
      const logsWithStore: SearchLogWithStore[] = logSearch.map((log) => {
        const store = stores.find((store) => store._id === log.body.user?.storeId) ?? null;
        return {
          ...log,
          store,
        };
      });
      setRowData(logsWithStore);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [leftDate, rightDate]);

  return (
    <div className='height100 flexColumn'>
      <TableOptions setDates={setDates} defaultValue={defaultDates} />
      <SearchLogTable rowData={rowData} />
    </div>
  );
};

export default SearchLog;
