import {
  CJFreshwayProductListDoc,
  DabomFoodProductListDoc,
  DawnMarketProductListDoc,
  FoodpangProductListDoc,
  GarakMarketProductListDoc,
  SehyeonFnBProductListDoc,
} from '@gooduncles/gu-app-schema';
import { Select } from 'antd';
import { FC, useEffect, useState } from 'react';
import { combineLatest } from 'rxjs';

import { productCategories } from 'src/lib/1/constant';
import {
  observeCJFreshwayProductList,
  observeCompetitorProductMapping,
  observeDabomFoodProductList,
  observeDawnMarketProductList,
  observeFoodpangProductList,
  observeGarakMarketProductList,
  observeGarakMarketProductPriceTrend,
  observeProduct,
  observeSehyeonFnBProductList,
} from 'src/lib/4/firebase-short-cut';

import { useTitle } from 'src/hooks/useTitle';

import ProductPriceMappingTable, {
  ProductPriceMappingTableRowdata,
} from 'src/components/ProductPriceMapping/ProductPriceMappingTable';

const ProductPriceMappingPage: FC = () => {
  useTitle('GU 관리자 | 상품 가격 매핑');
  const [selectedCategory, setSelectedCategory] = useState<keyof typeof productCategories | null>('all');
  const [rowData, setRowData] = useState<ProductPriceMappingTableRowdata[]>([]);
  const [garakMarketProductList, setGarakMarketProductList] = useState<GarakMarketProductListDoc | undefined>(
    undefined
  );
  const [dabomFoodProductList, setDabomFoodProductList] = useState<DabomFoodProductListDoc | undefined>(undefined);
  const [CJFreshwayProductList, setCJFreshwayProductList] = useState<CJFreshwayProductListDoc | undefined>(undefined);
  const [sehyeonFnBProductList, setSehyeonFnBProductList] = useState<SehyeonFnBProductListDoc | undefined>(undefined);
  const [foodpangProductList, setFoodpangProductList] = useState<FoodpangProductListDoc | undefined>(undefined);
  const [dawnMarketProductList, setDawnMarketProductList] = useState<DawnMarketProductListDoc | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const productObservable = observeProduct([['categories', 'array-contains', selectedCategory]]);
    const garakMarketProductPriceTrendObservable = observeGarakMarketProductPriceTrend();
    const competitorProductMappingObservable = observeCompetitorProductMapping();
    const subscription = combineLatest([
      productObservable,
      garakMarketProductPriceTrendObservable,
      competitorProductMappingObservable,
    ]).subscribe(([products, garakMarketProductPriceTrends, competitorProductMappings]) => {
      const productPriceMappingTableData = products.map((product) => {
        const garakMarketProductPriceTrend = garakMarketProductPriceTrends.find((trend) => trend._id === product._id);
        const dadomFoodMapping =
          competitorProductMappings.find(
            (mapping) => mapping.guProductId === product._id && mapping.competitor === '다봄푸드'
          ) ?? null;
        const CJFreshwayMapping =
          competitorProductMappings.find(
            (mapping) => mapping.guProductId === product._id && mapping.competitor === 'CJ프레시웨이'
          ) ?? null;
        const SehyeonFnBMapping =
          competitorProductMappings.find(
            (mapping) => mapping.guProductId === product._id && mapping.competitor === '세현F&B'
          ) ?? null;
        const foodpangMapping =
          competitorProductMappings.find(
            (mapping) => mapping.guProductId === product._id && mapping.competitor === '푸드팡'
          ) ?? null;
        const dawnMarketMapping =
          competitorProductMappings.find(
            (mapping) => mapping.guProductId === product._id && mapping.competitor === '새벽시장'
          ) ?? null;
        return {
          ...product,
          garakMarket: garakMarketProductPriceTrend ?? null,
          CJFreshwayMapping,
          SehyeonFnBMapping,
          dadomFoodMapping,
          foodpangMapping,
          dawnMarketMapping,
        };
      });
      setRowData(productPriceMappingTableData);
      setLoading(false);
    });

    const configSubscription = observeGarakMarketProductList().subscribe(setGarakMarketProductList);
    const dabomFoodProductListSubscription = observeDabomFoodProductList().subscribe(setDabomFoodProductList);
    const CJFreshwayProductListSubscription = observeCJFreshwayProductList().subscribe(setCJFreshwayProductList);
    const sehyeonFnBProductListSubscription = observeSehyeonFnBProductList().subscribe(setSehyeonFnBProductList);
    const foodpangProductListSubscription = observeFoodpangProductList().subscribe(setFoodpangProductList);
    const dawnMarketProductListSubscription = observeDawnMarketProductList().subscribe(setDawnMarketProductList);
    return () => {
      subscription.unsubscribe();
      configSubscription.unsubscribe();
      dabomFoodProductListSubscription.unsubscribe();
      CJFreshwayProductListSubscription.unsubscribe();
      sehyeonFnBProductListSubscription.unsubscribe();
      foodpangProductListSubscription.unsubscribe();
      dawnMarketProductListSubscription.unsubscribe();
    };
  }, [selectedCategory]);

  return (
    <div className='tabBody flexColumn height100'>
      <section className='flexRow flexSpaceBetween'>
        <Select
          showSearch
          style={{ width: 160 }}
          loading={loading}
          options={[
            {
              label: '전체',
              value: 'all',
            },
            ...Object.entries(productCategories).map(([value, label]) => ({
              label,
              value,
            })),
          ]}
          value={selectedCategory}
          onChange={(e) => {
            setSelectedCategory(e);
          }}
        />
      </section>
      <section className='height100'>
        <ProductPriceMappingTable
          rowData={rowData}
          garakMarketProductList={garakMarketProductList ?? null}
          CJFreshwayProductList={CJFreshwayProductList ?? null}
          sehyeonFnBProductList={sehyeonFnBProductList ?? null}
          dabomFoodProductList={dabomFoodProductList ?? null}
          foodpangProductList={foodpangProductList ?? null}
          dawnMarketProductList={dawnMarketProductList ?? null}
        />
      </section>
    </div>
  );
};

export default ProductPriceMappingPage;
