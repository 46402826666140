import { useCallback, useEffect, useState } from 'react';

import { goodunclesCoords } from 'src/lib/1/constant';
import { updateMarkers } from 'src/lib/1/ncp-map-api-util';

export interface MarkerGroup {
  /** partner id */
  title: string;
  /** deg */
  hue: number;
  show: boolean;
  markers: naver.maps.Marker[];
}

/**
 * {@link https://navermaps.github.io/maps.js.ncp/docs/tutorial-digest.example.html example}
 * {@link https://navermaps.github.io/maps.js.ncp/docs/naver.maps.Map.html method}
 * 네이버 지도 API v3를 로드하는 커스텀 훅
 * @returns
 */
const useNaverMapApi = (clientId?: string, zoom?: number) => {
  const [error, setError] = useState<string | null>(null);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [map, setMap] = useState<naver.maps.Map | null>(null);
  const [markerGroups, setMarkerGroups] = useState<MarkerGroup[]>([]);

  const triggerClickMarkerBySeq = useCallback(
    (seq: string) => {
      const marker = markerGroups.flatMap((group) => group.markers).find((marker) => marker.get('seq') === seq);
      if (map && marker) {
        naver.maps.Event.trigger(marker, 'click');
      }
    },
    [map, markerGroups]
  );

  // 네이버 map API 스크립트를 로드한다.
  useEffect(() => {
    (window as any).naver_script_load_callback = () => setScriptLoaded(true);
    const script = document.createElement('script');
    script.src = `https://oapi.map.naver.com/openapi/v3/maps.js?ncpClientId=${clientId}&callback=naver_script_load_callback`;
    script.defer = true;
    script.onload = () => setScriptLoaded(true);

    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
      delete (window as any).naver_script_load_callback;
    };
  }, [clientId]);

  // 네이버 map API 스크립트가 로드되면, 지도를 생성한다.
  useEffect(() => {
    const mapDiv = document.getElementById('map');
    if (!mapDiv) {
      setError('map div not found');
      return;
    }
    if (scriptLoaded && mapDiv) {
      const map0 = new naver.maps.Map('map', {
        center: new naver.maps.LatLng(goodunclesCoords.lat, goodunclesCoords.lng),
        zoom: zoom ?? 13,
      });
      setMap(map0);

      return () => {
        map0.destroy();
        console.log('map destroyed');
      };
    }
  }, [scriptLoaded, zoom]);

  const clearMarkers = useCallback(() => {
    markerGroups.forEach(({ markers }) => {
      markers.forEach((marker) => {
        marker.setMap(null);
      });
    });
    setMarkerGroups([]);
  }, [markerGroups]);

  // 마커가 변경되면, 지도에 마커를 표시한다.
  useEffect(() => {
    if (map && markerGroups.length > 0) {
      const updateMarkers0 = () => {
        markerGroups.forEach(({ show, markers }) => {
          updateMarkers(map, markers, show);
        });
      };
      const listener = naver.maps.Event.addListener(map, 'idle', updateMarkers0);

      return () => {
        naver.maps.Event.removeListener(listener);
        markerGroups.forEach(({ markers }) => {
          markers.forEach((marker) => {
            marker.setMap(null);
          });
        });
      };
    }
  }, [map, markerGroups]);

  return {
    scriptLoaded,
    map,
    error,
    markerGroups,
    setMarkerGroups,
    clearMarkers,
    triggerClickMarkerBySeq,
  };
};

export default useNaverMapApi;
