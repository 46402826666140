import { ProductDoc } from '@gooduncles/gu-app-schema';
import useSWR from 'swr';

import { FirebaseManager } from 'src/lib/3/firebase-manager';

const firebaseManager = FirebaseManager.getInstance();
const productPath = 'product';

const getProrduct = async (id: string) => {
  return firebaseManager.getDoc<ProductDoc>(`${productPath}/${id}`);
};

const useProduct = (id?: string) => {
  const { data: product, isValidating: productLoading } = useSWR(id ? id : null, getProrduct, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    errorRetryCount: 2,
  });
  return {
    product,
    productLoading,
  };
};

export default useProduct;
