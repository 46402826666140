import { Product } from '@gooduncles/gu-app-schema';
import { message } from 'antd';

import { SupplierPurchaseConversionItem } from '../1/schema-purchase-conversion';
import { errorObjectToString } from '../1/util';
import { SupplierDoc } from '../3/schema-supplier';
import { getSupplierById, updateProduct, updateSupplier } from './firebase-short-cut';

/**
 * 상품에 매입처를 변경한다.
 */
export const setProductSupplier = async (oldSuppliers: string[], product: Product, supplier: SupplierDoc) => {
  const { productId, fullName, packing } = product;
  const conversionItem: SupplierPurchaseConversionItem = {
    productId,
    purchaseProductName: fullName,
    purchaseBundle: 'all',
    purchaseMaxBundle: null,
    purchaseMultiplyUnit: 1,
    purchaseUnit: packing === 'box' ? '박스' : '개',
  };

  if (supplier.conversionTable.findIndex((item) => item.productId === productId) !== -1) {
    message.error('이미 등록된 상품입니다.\n오류 상황이니 개발자에게 알려주세요.');
    return false;
  }

  try {
    const oldSupplier = await getSupplierById(oldSuppliers?.[0]);
    if (oldSupplier) {
      await updateSupplier(oldSupplier._id, {
        conversionTable: oldSupplier.conversionTable.filter((item) => item.productId !== productId),
      });
      message.info(`기존 매입처(${oldSupplier.name})에서 상품을 제거했습니다.`);
    }
    await updateSupplier(supplier._id, {
      conversionTable: [...supplier.conversionTable, conversionItem],
    });
    await updateProduct(productId, {
      suppliers: [supplier._id],
    });
    message.success(`매입처(${supplier.name})에 상품을 등록했습니다.`);
    return true;
  } catch (error) {
    console.error(error);
    const errorMessage = errorObjectToString(error);
    message.error(errorMessage);
  }
  return false;
};

/**
 * 상품의 발주 정보를 변경한다.
 */
export const updateSupplierConversionItem = async (
  supplier: SupplierDoc,
  newConversionItem: SupplierPurchaseConversionItem
) => {
  const { _id, conversionTable } = supplier;
  const { productId } = newConversionItem;
  const index = conversionTable.findIndex((item) => item.productId === productId);
  if (index === -1) {
    message.error('등록되지 않은 상품입니다.\n오류 상황이니 개발자에게 알려주세요.');
    return false;
  }

  try {
    const newConversionTable = [...conversionTable];
    newConversionTable[index] = newConversionItem;
    await updateSupplier(_id, {
      conversionTable: newConversionTable,
    });
    message.success('매입처 정보를 수정했습니다.');
    return true;
  } catch (error) {
    console.error(error);
    const errorMessage = errorObjectToString(error);
    message.error(errorMessage);
  }
  return false;
};
