import { Select, message, notification } from 'antd';
import { BaseOptionType } from 'antd/es/select';
import { FC, useState } from 'react';

type SelectableCellProps = {
  options: BaseOptionType[];
  value: string;
  onChange: (value: any) => Promise<any>;
  isNumber?: boolean;
  minWidth?: number;
};

const SelectableCell: FC<SelectableCellProps> = ({ options, value, onChange, isNumber, minWidth }) => {
  const [loading, setLoading] = useState(false);
  const handleChange = async (newValue: string) => {
    const curValue = isNumber ? Number(newValue) : newValue;
    if (value === curValue) return;
    setLoading(true);
    try {
      const result = await onChange(curValue);
      if (result) {
        message.success('변경 완료');
      }
    } catch (error: any) {
      console.error(error);
      notification.error({
        message: '변경에 실패했습니다.',
        description: error.message,
        duration: 10,
        style: {
          whiteSpace: 'pre-line',
        },
      });
    }
    setLoading(false);
  };

  return (
    <Select
      style={{ minWidth: minWidth ?? 130 }}
      onChange={handleChange}
      loading={loading}
      value={String(value) ?? options[0]}
      options={options}
    />
  );
};

export default SelectableCell;
