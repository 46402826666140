import useSWRImmutable from 'swr/immutable';

import { getConfigBusinessInfo } from 'src/lib/4/firebase-short-cut';

const useBusinessInfo = () => {
  const { data: businessInfo, isValidating } = useSWRImmutable('config/business-info', getConfigBusinessInfo);

  return {
    businessInfo,
    isValidating,
  };
};

export default useBusinessInfo;
