import { UserDoc } from '@gooduncles/gu-app-schema';

import { isProduction } from '../1/constant';
import { sessionId } from '../1/util';
import { FirebaseManager } from '../3/firebase-manager';
import { Log, LogConsole, LogLevel, LogOrder } from '../3/schema-log';
import version from '../../version.json';

const firebaseManager = FirebaseManager.getInstance();
const logConsolePath = 'logConsole';
const logOrderPath = 'logOrder';

export class ConsoleLogger {
  private static instance: ConsoleLogger;
  private user: UserDoc | null;
  private version: string;
  private publicIp: string;

  private constructor() {
    this.publicIp = 'unknown';
    this.user = null;
    this.version = isProduction ? version.prod : version.dev;
  }

  public static getInstance() {
    if (ConsoleLogger.instance === undefined) {
      ConsoleLogger.instance = new ConsoleLogger();
    }
    return ConsoleLogger.instance;
  }

  public setLoggerUser(user: UserDoc | null) {
    this.user = user;
  }

  public setPublicIp(ip: string) {
    this.publicIp = ip;
  }

  public getPublicIp() {
    return this.publicIp;
  }

  public logOrder(
    message: string,
    body: Omit<LogOrder, 'user'>,
    option?: {
      level?: LogLevel;
      channel?: '4-앱-알림' | 'dev';
      showBody?: boolean;
    }
  ) {
    const { level, channel, showBody } = option || {};

    const log: Log<LogOrder> = {
      level: level ?? 'log',
      sessionId,
      publicIp: this.publicIp,
      version: this.version,
      message,
      slack: {
        channel,
        showBody,
      },
      body: {
        ...body,
        user: this.user,
      },
    };

    if (!channel && !showBody) {
      delete log.slack;
    }

    return firebaseManager.createDoc(logOrderPath, undefined, log);
  }

  public logConsole(
    message: string,
    option?: {
      level?: LogLevel;
      channel?: '4-앱-알림' | 'dev';
      // extraInfo?: Partial<Log<LogConsole>['body']>;
      showBody?: boolean;
      extraData?: any;
    }
  ) {
    const path = window.location.pathname;
    const { level, channel, showBody, extraData } = option || {};

    const log: Log<LogConsole> = {
      level: level ?? 'log',
      sessionId,
      publicIp: this.publicIp,
      version: this.version,
      message,
      slack: {
        channel,
        showBody,
      },
      body: {
        path,
        user: this.user,
        extraData,
      },
    };

    if (!channel && !showBody) {
      delete log.slack;
    }

    return firebaseManager.createDoc(logConsolePath, undefined, log);
  }
}
