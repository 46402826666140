import { ICellRendererParams } from 'ag-grid-community';
import { Checkbox, Popconfirm, notification } from 'antd';
import { isEqual } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';

import { StoreCategory, storeCategories } from 'src/lib/1/constant';
import { errorObjectToString } from 'src/lib/1/util';
import { StoreDoc } from '@gooduncles/gu-app-schema';
import { updateStore } from 'src/lib/4/firebase-short-cut';

const options = Object.entries(storeCategories)
  .sort((a, b) => a[1].localeCompare(b[1]))
  .map(([key, value]) => ({
    label: value,
    value: key as StoreCategory,
  }));

const StoreCategoryCellRenderer = (params: ICellRendererParams<StoreDoc, StoreCategory[]>) => {
  const [selectedCategories, setSelectedValue] = useState<StoreCategory[]>([]);
  const [loading, setLoading] = useState(false);

  const onOpenChange = useCallback(
    async (isOpen: boolean) => {
      if (isOpen) return;
      if (!params.data?._id) return;
      if (isEqual(selectedCategories, params.data.categories)) return;

      setLoading(true);
      try {
        await updateStore(params.data._id, { categories: selectedCategories });
        notification.success({ message: '업종 변경 성공', description: params.data.storeNickname });
      } catch (error) {
        console.error(error);
        const description = errorObjectToString(error);
        notification.error({ message: '업종 변경 실패', description });
      }
      setLoading(false);
    },
    [params.data?._id, params.data?.categories, params.data?.storeNickname, selectedCategories]
  );

  useEffect(() => {
    setSelectedValue(params.data?.categories ?? []);
  }, [params.data?.categories]);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Popconfirm
        title={null}
        icon={null}
        showCancel={false}
        okButtonProps={{ loading, disabled: isEqual(selectedCategories, params.data?.categories) }}
        okText='수정'
        description={
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}>
            {options.map((option) => {
              return (
                <span key={option.value}>
                  <Checkbox
                    checked={selectedCategories?.includes(option.value)}
                    onChange={(e) => {
                      const newValue = e.target.checked
                        ? [...(selectedCategories ?? []), option.value]
                        : (selectedCategories ?? []).filter((value) => value !== option.value);
                      setSelectedValue(newValue);
                    }}>
                    {option.label}
                  </Checkbox>
                </span>
              );
            })}
          </div>
        }
        trigger='click'
        onOpenChange={onOpenChange}
        arrow={false}>
        <div style={{ height: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.value?.join(', ')}</div>
      </Popconfirm>
    </div>
  );
};

export default StoreCategoryCellRenderer;
