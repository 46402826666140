import { ProductSnapshotDoc } from '@gooduncles/gu-app-schema';
import useSWRImmutable from 'swr/immutable';

import { getDaysForMonths } from 'src/lib/1/date-util';
import { promiseAllSettled } from 'src/lib/1/util';
import { getProductSnapshot } from 'src/lib/4/firebase-short-cut';

const getProductSnapshotsForId = async (productId: string, monthRange = 3) => {
  const days = getDaysForMonths(monthRange);
  const promises = days.map((day) => getProductSnapshot(`${day}-${productId}`));
  const result = await promiseAllSettled(promises);
  return result.fulfilled.filter((item) => item) as ProductSnapshotDoc[];
};

const useProductSnapshotsForId = (id?: string) => {
  const {
    data,
    isValidating: snapshotsLoading,
    error: snapshotsError,
  } = useSWRImmutable(id ? id : null, getProductSnapshotsForId, {
    errorRetryCount: 2,
  });

  return {
    snapshots: data,
    snapshotsLoading,
    snapshotsError,
  };
};

export default useProductSnapshotsForId;
