import { Button, notification } from 'antd';
import dayjs from 'dayjs';
import { FC, useCallback, useEffect, useState } from 'react';
import { combineLatest, map } from 'rxjs';
import { callCreateInvoiceDetail } from 'src/utils/payment-util';

import { dayjsesToStringDateWhere, getDefaultDate } from 'src/lib/1/date-util';
import { errorObjectToString } from 'src/lib/1/util';
import {
  QueryOptions,
  observeInvoices,
  observePaymentItem,
  observeSettlementResult,
} from 'src/lib/4/firebase-short-cut';

import useStores from 'src/hooks/useStores';

import DatePickerWithArrows from 'src/components/Common/DatePickerWithArrows/DatePickerWithArrows';
import SettledInvoiceTable, {
  SettledInvoiceTableRowData,
} from 'src/components/NewSettlement/InvoiceTable/SettledInvoiceTable';

const defaultValue = getDefaultDate();

const SettledInvociePage: FC = () => {
  const stores = useStores();
  const [date, setDate] = useState<dayjs.Dayjs | null>(defaultValue);
  const [rowData, setRowData] = useState<SettledInvoiceTableRowData[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState<SettledInvoiceTableRowData[]>([]);

  const handleSendInvoice = useCallback(async () => {
    try {
      setLoading(true);
      const invoiceIds = selectedRows.map((row) => row._id);
      if (invoiceIds.length === 0) return;
      await callCreateInvoiceDetail({ invoiceIds });
      notification.success({ message: '청구서 상세 내역 생성 성공' });
    } catch (error) {
      console.error(error);
      const description = errorObjectToString(error);
      notification.error({ message: '청구서 상세 내역 생성 실패', description });
    }
    setLoading(false);
  }, [selectedRows]);

  useEffect(() => {
    if (date && stores) {
      const [leftDate, rightDate] = dayjsesToStringDateWhere(date, date);
      const options: QueryOptions = {
        sortKey: 'settledAt',
        orderBy: 'desc',
        startValue: leftDate,
        endValue: rightDate,
      };
      const paymentItemsObservable = observePaymentItem([], options).pipe(
        map((paymentItems) => paymentItems.filter((paymentItem) => paymentItem.deletedAt === null))
      );
      const invoicesObservable = observeInvoices([], options).pipe(
        map((invoices) => invoices.filter((invoice) => invoice.deletedAt === null))
      );
      const settlementResultsObservable = observeSettlementResult([], options).pipe(
        map((settlementResults) => settlementResults.filter((settlementResult) => settlementResult.deletedAt === null))
      );

      const subscription = combineLatest([
        paymentItemsObservable,
        invoicesObservable,
        settlementResultsObservable,
      ]).subscribe(([paymentItems0, invoices, settlementResults]) => {
        const invoicesWithResult = invoices.map((invoice) => {
          const store = stores.find((store) => store._id === invoice.storeId);
          const paymentItems = paymentItems0.filter((paymentItem) => paymentItem.storeId === invoice.storeId);
          const settlementResult =
            settlementResults.find((settlementResult) => settlementResult.invoiceId === invoice._id) ?? null;
          return {
            ...invoice,
            storeNickname: store?.storeNickname || null,
            paymentItems,
            settlementResult,
          };
        });
        setRowData(invoicesWithResult);
      });

      return () => {
        subscription.unsubscribe();
      };
    }
  }, [date, stores]);

  return (
    <div className='tabBody flexColumn height100'>
      <section className='flexRow flexSpaceBetween'>
        <DatePickerWithArrows date={date} setDate={setDate} />
        <Button disabled={selectedRows.length <= 0} loading={loading} onClick={handleSendInvoice}>
          {selectedRows.length}개 청구서 상세 내역 생성
        </Button>
      </section>
      <section className='height100'>
        <SettledInvoiceTable rowData={rowData} setSelectedRows={setSelectedRows} />
      </section>
    </div>
  );
};

export default SettledInvociePage;
