import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { ColDef, ColumnState } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { FC, useMemo, useState } from 'react';

import { LocalStorageKey } from 'src/lib/1/constant';
import { getLocalStorageValue, setLocalStorageValue } from 'src/lib/1/util';

import PrintWindow from 'src/components/PrintWindow/PrintWindow';

import { ProductRequestTableRowData } from '../ProductRequestDashboard';

interface ProductRequestTableProps {
  rowData: ProductRequestTableRowData[];
}

const defaultColDef: ColDef<ProductRequestTableRowData> = {
  sortable: true,
  resizable: true,
  filter: true,
};

const ProductRequestTableForPrint: FC<ProductRequestTableProps> = ({ rowData }) => {
  const [, setGridRef] = useState<AgGridReact<ProductRequestTableRowData> | null>(null);

  const columnDefs: ColDef<ProductRequestTableRowData>[] = useMemo(
    () => [
      {
        headerName: '코드',
        field: 'product.productId',
        width: 88,
      },
      {
        headerName: '구분',
        field: 'supplierLabel',
        width: 76,
      },
      {
        headerName: '품명',
        field: 'product.fullName',
        width: 210,
        wrapText: true,
        autoHeight: true,
        cellStyle: () => ({
          lineHeight: '1.1em',
          display: 'flex',
          alignItems: 'center',
          letterSpacing: '-0.025em',
          wordBreak: 'keep-all',
        }),
      },
      {
        headerName: '필요',
        field: 'totalRequestVolume',
        width: 76,
        cellStyle: { backgroundColor: '#ffff00' },
      },
      {
        headerName: '발주',
        field: 'totalPurchaseVolume',
        width: 76,
        cellStyle: (params) => {
          return params.value > 0 ? { backgroundColor: '#b6e1cd' } : null;
        },
      },
      {
        headerName: '창고',
        field: 'totalOutboundVolume',
        width: 76,
        cellStyle: (params) => {
          return params.value > 0 ? { backgroundColor: '#d0e2f4' } : null;
        },
      },
      {
        headerName: '위치',
        field: 'binLocation',
        width: 76,
      },
      {
        headerName: '매입처',
        field: 'supplierName',
        width: 110,
      },
    ],
    []
  );

  return (
    <PrintWindow>
      <div className='ag-theme-alpine' style={{ backgroundColor: 'white' }}>
        <AgGridReact
          onGridReady={(e) => {
            const filterModel = getLocalStorageValue<any>(LocalStorageKey.productRequestTalbeFilterModel);
            const columnState = getLocalStorageValue<ColumnState[]>(LocalStorageKey.productRequestTalbeColumnState);
            if (filterModel) {
              e.api.setFilterModel(filterModel);
            }
            if (columnState) {
              e.columnApi.applyColumnState({ state: columnState, applyOrder: true });
            }
          }}
          ref={setGridRef}
          rowData={rowData}
          getRowId={(params) => params.data.product.productId}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          domLayout='print'
          rowHeight={48}
          onFilterChanged={(e) => {
            const filterModel = e.api.getFilterModel();
            setLocalStorageValue(LocalStorageKey.productRequestTalbeFilterModel, filterModel);
          }}
          onSortChanged={(e) => {
            const columnState = e.columnApi.getColumnState();
            setLocalStorageValue(LocalStorageKey.productRequestTalbeColumnState, columnState);
          }}
          alwaysShowHorizontalScroll={true}
        />
      </div>
    </PrintWindow>
  );
};

export default ProductRequestTableForPrint;
