import { ExclamationCircleOutlined } from '@ant-design/icons';
import { OrderDoc, OrderStatusCode, ProductStateCode } from '@gooduncles/gu-app-schema';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { ColDef, ColGroupDef, RowClassParams } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { Modal, message } from 'antd';
import { BaseOptionType } from 'antd/es/select';
import { FC, useCallback, useMemo, useState } from 'react';

import { orderStatusKr } from 'src/lib/1/constant';
import { orderDateFormat01, orderDateFormat03 } from 'src/lib/1/date-util';
import { formatNumber, parseIfObject } from 'src/lib/1/util';
import { updateOrder } from 'src/lib/4/firebase-short-cut';
import { OrderWithStore, ProductWithOrder, cancelOrder, productVolumeChangeValidator } from 'src/lib/5/order-util';
import {
  onCellValueChangedWithUpdateForOrderProduct,
  onValueSetterWithValidation,
  timestampFormatter,
} from 'src/lib/6/ag-grid-util';
import { onCellValueChangedWithUpdateForDeliveryFee } from 'src/lib/6/ag-grid-util';

import useCommerceConf from 'src/hooks/useCommerceConf';

import DownloadRawDataCell from 'src/components/Common/DownloadRawDataCell/DownloadRawDataCell';
import SelectableCell from 'src/components/Common/SelectableCell/SelectableCell';

import classes from './OrderTable.module.scss';

import SoldOutCellRenderer from './SoldOutCellRenderer/SoldOutCellRenderer';

const logName = '주문 관리';

const defaultColDef: ColDef<OrderWithStore> = {
  flex: 1,
  sortable: true,
  resizable: true,
  filter: true,
};

const onCellValueChangedForOrderProduct = onCellValueChangedWithUpdateForOrderProduct(logName);
const onCellValueChangedForDeliveryFee = onCellValueChangedWithUpdateForDeliveryFee(logName);

const getDetailRowStyle = (params: RowClassParams<ProductWithOrder>) => {
  if (params.data?.state === ProductStateCode.OUTOFSTOCK) {
    return { backgroundColor: 'hsl(357, 100%, 95%)' };
  }
};

const OrderTable: FC<{ rowData: OrderWithStore[]; summary?: OrderWithStore }> = ({ rowData, summary }) => {
  const { commerceConf } = useCommerceConf();
  const [, setGridRef] = useState<AgGridReact<OrderWithStore> | null>(null);
  const isRowMaster = useMemo(() => {
    return (dataItem: OrderWithStore) => {
      return dataItem ? dataItem.products && dataItem.products.length > 0 : false;
    };
  }, []);

  const columnDefs: (ColDef<OrderWithStore> | ColGroupDef<OrderWithStore>)[] = useMemo(
    () => [
      { headerName: 'OrderId', field: '_id', minWidth: 300, hide: true },
      {
        headerName: '_timeCreate',
        field: '_timeCreate',
        minWidth: 300,
        valueFormatter: timestampFormatter,
        hide: true,
      },
      {
        headerName: '기준일',
        field: 'date',
        hide: true,
      },
      {
        headerName: '주문상태',
        field: 'orderStatus',
        minWidth: 160,
        cellRenderer: (params: any) => {
          if (params.data._id === 'summary') return params.value;
          const { value } = params;
          const options: BaseOptionType[] = Object.entries(orderStatusKr).map(([value, label]) => ({ value, label }));
          const onChange = async (newValue: number) => {
            if (value > newValue) {
              throw new Error(
                '⛔️ 주문상태는 뒤로 되돌릴 수 없습니다. ⛔️\n\n수락대기 > 주문수락 > 배송완료 >\n주문취소 > 주문거절\n수락대기 주문을 원하시는 경우 새로 생성해주세요.'
              );
            }
            if (newValue === OrderStatusCode.ACCEPTED) {
              Modal.confirm({
                title: '주문수락',
                icon: <ExclamationCircleOutlined />,
                content: '주문을 수동으로 수락합니다.',
                okText: '수락',
                cancelText: '취소',
                onOk: async () => {
                  try {
                    await updateOrder(params.data._id, { orderStatus: newValue });
                    message.success('변경 완료');
                  } catch (error: any) {
                    console.error(error);
                    message.error(`변경 실패: ${parseIfObject(error.message)}`);
                  }
                },
              });
              return false;
            } else if (newValue === OrderStatusCode.DELIVERED) {
              return updateOrder(params.data._id, { orderStatus: newValue, deliveredAt: orderDateFormat03() });
            } else if ([OrderStatusCode.CANCELED, OrderStatusCode.REJECTED].includes(newValue)) {
              return cancelOrder(params.data, newValue);
            } else {
              return updateOrder(params.data._id, { orderStatus: newValue });
            }
          };
          return <SelectableCell options={options} value={value} onChange={onChange} isNumber={true} minWidth={118} />;
        },
      },
      {
        headerName: '매장명(앱)',
        field: 'store.storeName',
        minWidth: 160,
        cellRenderer: 'agGroupCellRenderer',
        hide: true,
      },
      { headerName: '매장명(관리)', field: 'store.storeNickname', minWidth: 160, cellRenderer: 'agGroupCellRenderer' },
      { headerName: '매장코드', field: 'store.storeCode', minWidth: 100 },
      {
        headerName: '주문시각',
        field: 'orderDate',
        minWidth: 160,
        valueFormatter: ({ value }) => (value ? orderDateFormat01(value) : ''),
      },
      {
        hide: true,
        headerName: '주문금액(세금포함)',
        field: 'totalAmount',
        minWidth: 140,
        valueFormatter: ({ value }) => formatNumber(value),
      },
      {
        headerName: '주문금액(배송비포함)',
        field: 'paidAmount',
        minWidth: 140,
        valueFormatter: ({ value }) => formatNumber(value),
      },
      {
        headerName: '*배송비',
        field: 'deliveryFee',
        minWidth: 88,
        editable: true,
        // TODO: 임시 코드
        cellStyle: (params: any) => {
          if (!params.data || commerceConf?.minAmountForFreeDelivery === undefined) {
            return;
          }
          const deliveryFee: number = params.data.deliveryFee ?? 0;
          const totalAmount: number = params.data.totalAmount;
          // 1. 배송비 부과 대상인가?
          if (params.data.store?.chargeDeliveryFee) {
            // 2. 배송비 면제 금액보다 작은가?
            if (commerceConf.minAmountForFreeDelivery > totalAmount) {
              // 3. 배송비가 0인가?
              if (deliveryFee === 0) {
                return { backgroundColor: 'hsl(357, 100%, 70%)' };
              }
            }
          }
        },
        valueFormatter: ({ value }) => formatNumber(value ?? 0),
        valueSetter: (params) =>
          onValueSetterWithValidation(params, ['_id'], undefined, {
            type: 'number',
          }),
        onCellValueChanged: (params) => onCellValueChangedForDeliveryFee(params),
      },
      {
        headerName: '배송비 부과',
        field: 'store.chargeDeliveryFee',
        minWidth: 140,
        hide: true,
        valueFormatter: ({ value }) => (value ? '🟢' : ''),
      },
      { headerName: '품목수', minWidth: 88, valueGetter: (params: any) => params.data.productCount ?? '' },
      {
        headerName: '가격동기화',
        field: 'products',
        pinned: 'right',
        maxWidth: 120,
        valueFormatter: ({ value, data }) => {
          if (data?._id === 'summary') return (data as any).isAllSynced ? '✅' : '⚪️';
          const products = value as OrderDoc['products'];
          const isAllSynced = products.every((product) => product.priceDiff !== null);
          return products.length > 0 ? (isAllSynced ? '✅' : '⚪️') : '';
        },
      },
      {
        headerName: '배송 담당자',
        field: 'deliveryPartnerName',
      },
      {
        headerName: '액션',
        minWidth: 120,
        cellRenderer: DownloadRawDataCell,
      },
    ],
    [commerceConf?.minAmountForFreeDelivery]
  );

  const detailCellRendererParams = useMemo(() => {
    const detailColumnDefs: (ColDef<ProductWithOrder> | ColGroupDef<ProductWithOrder>)[] = [
      {
        headerName: 'Id',
        field: 'productId',
        width: 72,
      },
      {
        headerName: '상품명',
        field: 'fullName',
        width: 160,
      },
      {
        headerName: '가격',
        field: 'snapshotPrice',
        width: 100,
        valueFormatter: (params) => {
          const { value } = params;
          const { price } = params.data as ProductWithOrder;
          return formatNumber(value ?? price ?? 0);
        },
      },
      {
        headerName: '주문 후 가격 변동',
        field: 'priceDiff',
        width: 140,
        valueFormatter: (params) => `${params.value > 0 ? '+' : ''}${formatNumber(params.value ?? 0)}`,
      },
      {
        headerName: '*수량',
        field: 'volume',
        width: 100,
        editable: true,
        valueFormatter: (params) => formatNumber(params.value),
        valueSetter: (params) =>
          onValueSetterWithValidation(params, ['order._id'], productVolumeChangeValidator(params), {
            type: 'number',
          }),
        onCellValueChanged: onCellValueChangedForOrderProduct,
      },
      {
        headerName: '총액',
        width: 100,
        valueFormatter: (params) => {
          const { price, snapshotPrice } = params.data as ProductWithOrder;
          return formatNumber((snapshotPrice ?? price ?? 0) * (params.data?.volume ?? 0));
        },
      },
      {
        headerName: '품절 처리',
        width: 100,
        cellRenderer: SoldOutCellRenderer,
      },
    ];

    return {
      refreshStrategy: 'rows',
      detailGridOptions: {
        columnDefs: detailColumnDefs,
        defaultColDef: {
          sortable: true,
          resizable: true,
          filter: true,
        },
        enableCellChangeFlash: true,
        onFirstDataRendered: (params: any) => {
          params.api.sizeColumnsToFit();
        },
        getRowStyle: getDetailRowStyle,
      },
      getDetailRowData: (params: any) => {
        const order = params.data as OrderWithStore;
        const data = order.products.map((product: any) => ({ ...product, order }));
        params.successCallback(data);
      },
    };
  }, []);

  const getRowId = useCallback(function (params: any) {
    return params.data._id;
  }, []);

  return (
    <div className={`ag-theme-alpine ${classes.tableContainer}`}>
      <div style={{ flex: '1 1 auto' }}>
        <AgGridReact
          ref={setGridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          getRowId={getRowId}
          // 편집 완료후 스크롤 이동을 막는다.
          suppressScrollOnNewData={true}
          alwaysShowHorizontalScroll={true}
          animateRows={true}
          isRowMaster={isRowMaster}
          masterDetail={true}
          keepDetailRows={true}
          detailRowAutoHeight={true}
          enableCellChangeFlash={true}
          detailCellRendererParams={detailCellRendererParams}
          alignedGrids={[]}
        />
      </div>
      {summary && (
        <div style={{ flex: 'none', height: '46px' }}>
          <AgGridReact
            rowData={[summary]}
            columnDefs={columnDefs}
            isRowMaster={isRowMaster}
            defaultColDef={defaultColDef}
            headerHeight={0}
            rowStyle={{ fontWeight: 'bold' }}
          />
        </div>
      )}
    </div>
  );
};

export default OrderTable;
