import { InvoiceMessageMapDoc } from '@gooduncles/gu-app-schema';
import { Button, Form, Input, notification } from 'antd';
import { reduce, set } from 'lodash-es';
import { FC, useCallback, useEffect, useState } from 'react';

import { errorObjectToString } from 'src/lib/1/util';
import { observeInvoiceMessageMapConf, updateInvoiceMessageMapConf } from 'src/lib/4/firebase-short-cut';

import Loading from 'src/components/Loading/Loading';

import classes from './InvoiceMessageSettings.module.scss';

const formLayout = {
  content: {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  },
  tail: {
    wrapperCol: { offset: 8, span: 16 },
  },
};

const transformObject = (obj: object) =>
  reduce(
    obj,
    (result, value, key) => {
      return set(result, key, value);
    },
    {}
  );

const InvoiceMessageSettings: FC = () => {
  const [loading, setLoading] = useState(true);
  const [InvoiceMessageMap, setInvoiceMessageMap] = useState<InvoiceMessageMapDoc>();
  const [invoiceMessageMapForm] = Form.useForm();

  const onFormFinish = useCallback(async (values: InvoiceMessageMapDoc['messageMap']) => {
    setLoading(true);
    try {
      await updateInvoiceMessageMapConf({ messageMap: transformObject(values) });
      notification.success({ message: '알림', description: '변경되었습니다.' });
    } catch (error) {
      console.error(error);
      const description = errorObjectToString(error);
      notification.error({ message: '알림', description });
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    const subscription = observeInvoiceMessageMapConf().subscribe((doc) => {
      setInvoiceMessageMap(doc);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <>
      {loading ? (
        <Loading title='잠시만 기다려주세요.' />
      ) : (
        <div className={classes.appConfigContainer}>
          <h2>기본 메시지 편집</h2>
          <div className={classes.formContainer}>
            <Form form={invoiceMessageMapForm} {...formLayout.content} layout='horizontal' onFinish={onFormFinish}>
              {InvoiceMessageMap &&
                Object.entries(InvoiceMessageMap?.messageMap)
                  .sort(([aKey], [bKey]) => bKey.localeCompare(aKey))
                  .map(([vendorKey, vendorValue]) => {
                    if (vendorValue) {
                      return Object.entries(vendorValue)
                        .sort(([aKey], [bKey]) => aKey.localeCompare(bKey))
                        .map(([methodKey, methodValue]) => (
                          <Form.Item
                            key={`${vendorKey}.${methodKey}`}
                            name={`${vendorKey}.${methodKey}`}
                            label={`${vendorKey} / ${methodKey}`}
                            initialValue={methodValue}
                            style={{ flex: 1 }}>
                            <Input.TextArea rows={4} />
                          </Form.Item>
                        ));
                    }
                  })}
              <Form.Item {...formLayout.tail}>
                <Button type='primary' htmlType='submit'>
                  일괄 변경
                </Button>
              </Form.Item>
            </Form>
          </div>
          {/* <>
            <h2>기본 메시지 추가</h2>
            <div className={classes.formContainer}>
              <Select
                defaultValue={provinceData[0]}
                style={{ width: 120 }}
                onChange={handleProvinceChange}
                options={provinceData.map((province) => ({ label: province, value: province }))}
              />
              <Select
                style={{ width: 120 }}
                value={secondCity}
                onChange={onSecondCityChange}
                options={cities.map((city) => ({ label: city, value: city }))}
              />
              <Button>추가</Button>
            </div>
          </> */}
        </div>
      )}
    </>
  );
};

export default InvoiceMessageSettings;
