import { Button } from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';

import { RangeEventValue, dayjsesToDateWhere, formatDate, getDefaultDates } from 'src/lib/1/date-util';
import { jsonToExcelFile } from 'src/lib/1/xlsx-util';
import { FirebaseManager } from 'src/lib/3/firebase-manager';
import { LogCommerce, LogDoc } from 'src/lib/3/schema-log';

import TableOptions from '../Common/TableOptions/TableOptions';
import CommerceLogTable from './CommerceLogTable/CommerceLogTable';

const defaultValue = getDefaultDates();

const firebaseManager = FirebaseManager.getInstance();
const logCommercePath = 'logCommerce';

const downloadCommerceLogToExcel = async (data: LogDoc<LogCommerce>[]) => {
  const logs = data.map((d) => {
    const date = formatDate(d._timeCreate.toDate(), 'yyyy-MM-dd HH:mm:ss');
    return {
      date,
      user: d.body.user?.email,
      message: d.message,
      session: d.sessionId,
      deviceId: d.body.deviceId,
      ip: d.publicIp,
      orderId: d.body.orderId,
    };
  });
  const now = formatDate(new Date(), 'L월 d일');
  jsonToExcelFile(logs, now + '-앱 로그', [['시각', '사용자', '내용', 'sessionId', 'deviceId', 'publicIp', 'orderId']]);
};

const CommerceLog: FC = () => {
  const [rowData, setRowData] = useState<LogDoc<LogCommerce>[]>([]);
  const [dates, setDates] = useState<RangeEventValue>(null);
  const [leftDate, rightDate] = useMemo(() => {
    return dates?.[0] && dates?.[1]
      ? dayjsesToDateWhere(dates[0], dates[1])
      : dayjsesToDateWhere(defaultValue[0], defaultValue[1]);
  }, [dates]);

  useEffect(() => {
    const subscription = firebaseManager
      .observeCollection<LogDoc<LogCommerce>>(logCommercePath, [], {
        sortKey: '_timeCreate',
        orderBy: 'desc',
        startValue: leftDate,
        endValue: rightDate,
      })
      .subscribe((commerceLogs) => {
        setRowData(commerceLogs);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [leftDate, rightDate]);

  return (
    <div className='height100 flexColumn'>
      <TableOptions setDates={setDates} defaultValue={defaultValue}>
        <Button onClick={() => downloadCommerceLogToExcel(rowData)}>엑셀 다운로드</Button>
      </TableOptions>
      <CommerceLogTable rowData={rowData} />
    </div>
  );
};

export default CommerceLog;
