import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import useStoresForSearch from 'src/hooks/useStoresForSearch';
import { useTitle } from 'src/hooks/useTitle';

import StoreIssue from 'src/components/StoreIssue/StoreIssue';
import StoreIssuePrint from 'src/components/StoreIssue/StoreIssuePrint/StoreIssuePrint';

const StoreIssuePage: FC<{ title: string }> = ({ title }) => {
  useTitle(`GU 관리자 | ${title}`);
  const { storeSearchValues, stores } = useStoresForSearch([['state', '!=', 'closed']]);
  return (
    <Routes>
      <Route path='/print' element={<StoreIssuePrint />} />
      <Route path='/list' element={<StoreIssue stores={stores} storeSearchValues={storeSearchValues} />} />
      <Route path='*' element={<Navigate to='list' replace />} />
    </Routes>
  );
};

export default StoreIssuePage;
