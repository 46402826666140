import useProductById from 'src/hooks/useProductById';

const ProductNameCellRenderer = (params: any) => {
  const id = params.value;
  const { product } = useProductById(id);
  const productName = product?.fullName ?? '';
  return <span>{productName}</span>;
};

export default ProductNameCellRenderer;
