import { FC } from 'react';

import { useTitle } from 'src/hooks/useTitle';

import Settlement from 'src/components/Settlement/Settlement';

const SettleAccountsPage: FC<{ title: string }> = ({ title }) => {
  useTitle(`GU 관리자 | ${title}`);
  return <Settlement />;
};

export default SettleAccountsPage;
