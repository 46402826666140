import { Badge } from 'antd';

import { StoreState, storeStateList } from 'src/lib/1/constant';

export const StateCellRenderer = (props: any) => {
  const storeState: StoreState = props.value;
  if (!storeState) return null;
  return (
    <span>
      <Badge status={storeState === 'open' ? 'success' : storeState === 'guest' ? 'warning' : 'error'} />
      &nbsp;{storeStateList[storeState]}
    </span>
  );
};
