import { InvoiceDoc, PaymentItemDoc, PaymentStatusCode, paymentStatusCodeKrMap } from '@gooduncles/gu-app-schema';
import { ColDef, ColGroupDef } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { FC, useMemo } from 'react';
import { cellStyleForPaymentMethod, getHyosungProductName, sumPaymentItems } from 'src/utils/payment-util';

import { formatDate } from 'src/lib/1/date-util';
import { calculatePreTaxPrice } from 'src/lib/5/order-util';
import { getCellClassForPaymentStatus, numberFormatter } from 'src/lib/6/ag-grid-util';

const dateFormatter = ({ value }: { value: string | null }) => (value ? formatDate(value, 'yyyy-MM-dd HH:mm:ss') : '');

export type PrevUnsettledInvoiceTableRowData = InvoiceDoc & {
  storeNickname: string | null;
  paymentItems: PaymentItemDoc[];
};

const defaultColDef = {
  sortable: true,
  resizable: true,
  filter: true,
};

interface PrevUnsettledInvoiceTableProps {
  rowData: PrevUnsettledInvoiceTableRowData[];
  setSelectedRows: React.Dispatch<React.SetStateAction<PrevUnsettledInvoiceTableRowData[]>>;
}

const PrevUnsettledInvoiceTable: FC<PrevUnsettledInvoiceTableProps> = ({ rowData, setSelectedRows }) => {
  const columnDefs: (ColDef<PrevUnsettledInvoiceTableRowData> | ColGroupDef<PrevUnsettledInvoiceTableRowData>)[] =
    useMemo(
      () => [
        { field: '_id', hide: true },
        {
          headerName: '매장명',
          field: 'storeNickname',
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
        },
        { headerName: '매장Id', field: 'storeId', hide: true },
        { headerName: '매장코드', field: 'storeCode', hide: true },
        { headerName: '결제처', field: 'paymentInfo.paymentVendor', width: 100 },
        { headerName: '결제방식', field: 'paymentInfo.paymentMethod', width: 100 },
        { headerName: 'ⓗ회원번호', field: 'paymentInfo.storeCode', width: 110 },
        { headerName: 'ⓗ계약번호', field: 'paymentInfo.contractNumber', width: 110, hide: true },
        { headerName: 'ⓗ회원명', field: 'paymentInfo.name', width: 100 },
        {
          headerName: 'ⓗ상품',
          valueGetter: (params: any) => {
            const data = params.data as PrevUnsettledInvoiceTableRowData;
            if (data.paymentInfo.paymentVendor === '효성CMS') {
              return getHyosungProductName(data.paymentInfo.paymentMethod, data);
            }
            return '';
          },
          cellStyle: (params: any) => {
            if (params.value.includes('카드')) return { color: 'var(--blue400)' };
          },
          width: 130,
        },
        {
          headerName: '정산금액',
          cellRenderer: 'agGroupCellRenderer',
          width: 140,
          type: 'numericColumn',
          valueGetter: (params: any) => {
            const { paymentItems } = params.data as PrevUnsettledInvoiceTableRowData;
            const { amount } = sumPaymentItems(paymentItems);
            return amount;
          },
          valueFormatter: numberFormatter,
          cellStyle: (params: any) => {
            const { invoiceAmount } = params.data as PrevUnsettledInvoiceTableRowData;
            const value = params.value as number;
            if (invoiceAmount > 0) {
              if (value === invoiceAmount)
                return {
                  backgroundColor: 'var(--green200)',
                };
              if (value < invoiceAmount)
                return {
                  backgroundColor: 'var(--yellow200)',
                };
              if (value > invoiceAmount || value === 0)
                return {
                  backgroundColor: 'var(--red100)',
                };
            }
          },
        },
        {
          headerName: '청구금액',
          field: 'invoiceAmount',
          valueFormatter: numberFormatter,
          width: 120,
          type: 'numericColumn',
        },
        {
          headerName: '이전 미납금',
          field: 'prevUnpaidAmount',
          valueFormatter: numberFormatter,
          width: 120,
          type: 'numericColumn',
          cellStyle: (params: any) => {
            const value = params.value as number;
            if (value > 0)
              return {
                color: 'var(--red600)',
              };
          },
        },
        {
          headerName: '면세',
          field: 'taxExemptAmount',
          valueFormatter: numberFormatter,
          width: 120,
          type: 'numericColumn',
        },
        {
          headerName: '과세',
          field: 'taxableAmount',
          valueFormatter: numberFormatter,
          width: 120,
          type: 'numericColumn',
        },
        {
          headerName: '공급가액',
          field: 'supplyPrice',
          valueGetter: (params: any) => {
            const { price } = calculatePreTaxPrice(params.data.taxableAmount ?? 0);
            return price;
          },
          valueFormatter: numberFormatter,
          width: 120,
          type: 'numericColumn',
        },
        {
          headerName: '부가세',
          field: 'tax',
          valueGetter: (params: any) => {
            const { tax } = calculatePreTaxPrice(params.data.taxableAmount ?? 0);
            return tax;
          },
          valueFormatter: numberFormatter,
          width: 120,
          type: 'numericColumn',
        },
      ],
      []
    );

  /**
   * 정산금액의 상세 내역을 보여주기 위한 렌더러 설정
   */
  const detailCellRendererParams = useMemo(() => {
    const detailColumnDefs: (ColDef<PaymentItemDoc> | ColGroupDef<PaymentItemDoc>)[] = [
      {
        field: 'transactionDate',
        headerName: '결제일시',
        valueFormatter: dateFormatter,
      },
      {
        field: 'payerInfo',
        headerName: '결제자',
      },
      {
        field: 'paymentStatus',
        headerName: '결제상태',
        width: 100,
        valueFormatter: ({ value }) => paymentStatusCodeKrMap[value as unknown as PaymentStatusCode],
        cellClass: getCellClassForPaymentStatus,
        filterParams: {
          valueFormatter: (params: any) => paymentStatusCodeKrMap[params.value as unknown as PaymentStatusCode],
        },
      },
      {
        field: 'paymentMethod',
        headerName: '결제수단',
        cellStyle: cellStyleForPaymentMethod,
        width: 100,
      },
      {
        field: 'paymentVendor',
        headerName: '출처',
        width: 100,
      },
      {
        field: 'amount',
        headerName: '결제금액',
        width: 120,
        valueFormatter: numberFormatter,
      },
      {
        field: 'supplyPrice',
        headerName: '공급가액',
        width: 120,
        valueFormatter: numberFormatter,
      },
      {
        field: 'tax',
        headerName: '부가세',
        width: 120,
        valueFormatter: numberFormatter,
      },
      {
        field: 'extraInfo',
        headerName: '기타정보',
      },
      {
        field: 'settledAt',
        headerName: '정산시각',
        valueFormatter: dateFormatter,
      },
      {
        field: 'settlementResultId',
        headerName: '정산결과',
        width: 100,
        valueFormatter: ({ value }) => (value ? '정산' : '미정산'),
      },
      {
        field: 'createdBy',
        headerName: '생성자',
      },
    ];

    return {
      detailGridOptions: {
        columnDefs: detailColumnDefs,
        defaultColDef,
      },
      getDetailRowData: (params: any) => {
        const paymentItems = params.data.paymentItems as PaymentItemDoc[];
        params.successCallback(paymentItems);
      },
    };
  }, []);

  const isRowMaster = (data: PrevUnsettledInvoiceTableRowData) => data.paymentItems.length > 0;

  return (
    <div className='ag-theme-alpine height100 width100'>
      <AgGridReact
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        // rowId를 지정해주지 않으면 데이터 변경시 refresh가 발생한다.
        getRowId={(params) => params.data._id}
        // 편집 완료후 스크롤 이동을 막는다.
        suppressScrollOnNewData={true}
        // detailRow
        isRowMaster={isRowMaster}
        keepDetailRows={true}
        masterDetail={true}
        detailRowAutoHeight={true}
        detailCellRendererParams={detailCellRendererParams}
        // 행 선택을 허용한다.
        onSelectionChanged={(e) => setSelectedRows(e.api.getSelectedRows())}
        rowSelection={'multiple'}
      />
    </div>
  );
};

export default PrevUnsettledInvoiceTable;
