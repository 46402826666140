import { LoadingOutlined, SendOutlined } from '@ant-design/icons';
import { ProductDoc } from '@gooduncles/gu-app-schema';
import { Button, Modal, Timeline, TimelineItemProps, notification } from 'antd';
import { chunk, partition } from 'lodash-es';
import { FC, useCallback, useState } from 'react';
import { callKakaoFriendtalk } from 'src/utils/firebase-callable';

import { isProduction, senderKey } from 'src/lib/1/constant';
import { errorObjectToString, formatNumber } from 'src/lib/1/util';
import { KakaoFriendtalkRecipientItem } from 'src/lib/2/schema-nhn-notification';
import { getProductsWithWhere, getStores } from 'src/lib/4/firebase-short-cut';
import { ConsoleLogger } from 'src/lib/5/logger';
import { getAllowedNotiUsers, getInterestProductListForUser, messageDivider } from 'src/lib/6/friendtalk-util';

const logger = ConsoleLogger.getInstance();
const logName = '일일 가격 알림 발송';

const initTimelineItems: TimelineItemProps[] = [
  {
    key: 1,
    color: 'gray',
    children: '알림 전용 상품 목록 조회',
  },
  {
    key: 2,
    color: 'gray',
    children: '매장 목록 조회',
  },
  {
    key: 3,
    color: 'gray',
    children: '사용자 목록 조회',
  },
  {
    key: 4,
    color: 'gray',
    children: '사용자별 관심 상품 목록 조회',
  },
  {
    key: 5,
    color: 'gray',
    dot: <SendOutlined />,
    children: '발송 요청',
  },
];

interface DailyProductListKakaotalkModalProps {
  title: string;
  month: number;
  isAd?: boolean;
}

const DailyProductListKakaotalkModal: FC<DailyProductListKakaotalkModalProps> = ({ title, month, isAd = false }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState(initTimelineItems);

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setLoading(false);
    setItems(initTimelineItems);
    setOpen(false);
  };

  const setTimelineWithKey = useCallback((key: number, text?: string, dot?: React.ReactNode) => {
    setItems((prev) => {
      const next = [...prev];
      next[key - 1].color = 'blue';
      if (text) {
        next[key - 1].children = text;
      }
      if (dot) {
        next[key - 1].dot = dot;
      }
      return next;
    });
  }, []);

  const sendDailyPriceList = useCallback(async () => {
    try {
      if (!senderKey) {
        throw new Error('senderKey가 없습니다.');
      }

      setLoading(true);
      // 1. 알림 전용 상품 목록 조회
      const products = await getProductsWithWhere([['dailyNotification', '==', true]]);
      setTimelineWithKey(1);
      // 2. 매장 목록 조회
      const stores = await getStores();
      setTimelineWithKey(2);
      // 3. 사용자 목록 조회
      const users = await getAllowedNotiUsers(
        isProduction
          ? null
          : ['cj95ejRgVecjXjgI6rxUqwMS1Q73', 'KMwQFzDm6Td1rJsZA0COC0P3USq2', '4MxliPHcfvSYXn9BWYhGR5MCtHp1']
      );
      setTimelineWithKey(3);

      // 4. 관심 상품 목록 조회
      const promises = users.map(async (user) => getInterestProductListForUser(user, month, products));
      const usersWithProductList = await Promise.all(promises);
      const [targets, nonTargets] = partition(usersWithProductList, ([, list]) => list);
      const recipientList: KakaoFriendtalkRecipientItem[] = targets
        .map(([user, list]) => {
          const interestProductList = list as ProductDoc[]; // undefined type 제거
          // 글자 제한을 넘기는 경우 메시지를 분할한다.
          const messageList = messageDivider(
            interestProductList.map((item) => item.fullName + ' ' + formatNumber(item.price) + '원')
          );
          return messageList.map((content) => ({
            recipientNo: user.userTel,
            content: title + '\n\n' + content,
            recipientGroupingKey: stores.find((store) => store._id === user.storeId)?.storeNickname ?? user.email,
            isAd,
          }));
        })
        .flat();
      setTimelineWithKey(
        4,
        `사용자별 관심 상품 목록 조회 - 발송: ${targets.length}명, 발송 제외: ${nonTargets.length}명`
      );

      const chunkSize = 20;
      const chunkedRecipientList = chunk(recipientList, chunkSize);
      const count = {
        all: recipientList.length,
        success: 0,
        fail: 0,
      };
      setTimelineWithKey(
        5,
        `${count.all}건 발송 요청 - 성공 ${count.success} / 실패 ${count.fail} `,
        <LoadingOutlined />
      );
      for (const chunkedRecipientListItem of chunkedRecipientList) {
        const repsonse = await callKakaoFriendtalk({
          senderKey,
          recipientList: chunkedRecipientListItem,
          senderGroupingKey: 'daily-price-list',
        });
        if (repsonse.data.result !== 'success') {
          notification.error({
            message: `${chunkedRecipientListItem.length}건 발송 실패`,
            description: repsonse.data.reason,
          });
          count.fail += chunkedRecipientListItem.length;
        } else {
          count.success += chunkedRecipientListItem.length;
        }
        setTimelineWithKey(5, `${count.all}건 발송 요청 - 성공 ${count.success} / 실패 ${count.fail} `);
      }
      notification.success({
        message: '메시지 발송 요청을 완료했습니다.',
        description: `전체 ${count.all}건 중 성공 ${count.success}건, 실패 ${count.fail}건\n발송결과는 '카톡 메시지 결과 - 친구톡'에서 확인해주세요`,
        className: 'pre-line-notification',
      });
      logger.logConsole(
        `${logName} - 발송 요청 완료\n전체 ${count.all}건 중 성공 ${count.success}건, 실패 ${count.fail}건`
      );
      closeModal();
    } catch (error) {
      console.error(error);
      const errorMessage = errorObjectToString(error);
      notification.error({
        message: '메시지 발송 요청 실패',
        description: errorMessage ?? '알 수 없는 에러 발생!',
      });
      logger.logConsole(`${logName} - 에러 발생\nerror: ${errorMessage}`, {
        level: 'error',
      });
    }
  }, [isAd, month, setTimelineWithKey, title]);

  return (
    <>
      <Button type='primary' size='large' icon={<SendOutlined />} onClick={openModal}>
        일일 가격 알림 일괄전송
      </Button>
      <Modal
        open={open}
        centered
        title='일일 가격 알림 발송'
        onCancel={closeModal}
        okText='전송시작'
        okButtonProps={{ loading }}
        onOk={sendDailyPriceList}>
        <div style={{ height: 20 }} />
        <Timeline items={items} />
      </Modal>
    </>
  );
};

export default DailyProductListKakaotalkModal;
