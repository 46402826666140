import { notification } from 'antd';
import { useCallback, useState } from 'react';

/**
 * setData: 읽은 파일에서 파싱된 데이터를 설정한다.
 * onSubmitData: 읽은 데이터를 서버로 전송한다.
 */
function useExcelUploadModal<T>(onSubmitData: (data: T[]) => Promise<void>) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileToUpload, setFileToUpload] = useState<File>();
  const [data, setData] = useState<T[]>();

  const openModal = () => setOpen(true);
  const removeFile = useCallback(() => {
    if (loading) {
      notification.warning({
        message: '파일 업로드 중',
        description: '파일 업로드 중에는 삭제 및 취소할 수 없습니다.',
      });
      return;
    }
    setFileToUpload(undefined);
    setData(undefined);
  }, [loading, setFileToUpload]);

  const closeModal = useCallback(() => {
    removeFile();
    setOpen(false);
  }, [removeFile, setOpen]);

  const onSubmit = useCallback(async () => {
    if (data && data.length > 0) {
      setLoading(true);
      await onSubmitData(data);
      setLoading(false);
      closeModal();
    }
  }, [closeModal, data, setLoading, onSubmitData]);

  const setDataFromExcel = useCallback((data: T[] | null) => {
    if (data) {
      setData(data);
    } else {
      setFileToUpload(undefined);
    }
  }, []);

  return {
    open,
    loading,
    data,
    fileToUpload,
    setLoading,
    setFileToUpload,
    openModal,
    removeFile,
    closeModal,
    onSubmit,
    setDataFromExcel,
  };
}

export default useExcelUploadModal;
