import { PrinterOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import classes from './PrintWindow.module.scss';

/**
 * 프린트할 요소만 렌더링하기 위해 사용된다.
 */
const PrintWindow: FC<PropsWithChildren> = ({ children }) => {
  const [printWindowElement, setPrintWindowElement] = useState<HTMLDivElement | null>(null);

  const handleOpenPrintWindow = useCallback(() => {
    const printWindowElement0 = document.getElementById('print-window');
    if (printWindowElement0 && printWindowElement0 instanceof HTMLDivElement) {
      setPrintWindowElement(printWindowElement0);
      setTimeout(() => {
        window.print();
      }, 300);
      return;
    }
    notification.error({
      message: '인쇄 창을 열 수 없습니다.',
    });
  }, []);

  useEffect(() => {
    const handleAfterprintEvent = () => {
      setPrintWindowElement(null);
    };
    window.addEventListener('afterprint', handleAfterprintEvent);
    return () => {
      window.removeEventListener('afterprint', handleAfterprintEvent);
    };
  }, []);

  return (
    <div className={classes.printWindowContainer}>
      <div className={classes.toolbar}>
        <Button
          icon={<PrinterOutlined />}
          onClick={handleOpenPrintWindow}
          type='primary'
          className={classes.printButton}
        />
      </div>
      {children}
      {printWindowElement && createPortal(children, printWindowElement)}
    </div>
  );
};

export default PrintWindow;
