import { FC } from 'react';

import { useTitle } from 'src/hooks/useTitle';

import InvoiceMessageSettings from 'src/components/NewSettlement/InvoiceMessageSettings/InvoiceMessageSettings';

const InvoiceMessagePage: FC = () => {
  useTitle('GU 관리자 | 청구서 기본 메시지 관리');
  return <InvoiceMessageSettings />;
};

export default InvoiceMessagePage;
