import useSWR from 'swr';

import { FirebaseManager } from 'src/lib/3/firebase-manager';
import { LogCommerce, LogDoc } from 'src/lib/3/schema-log';

const firebaseManager = FirebaseManager.getInstance();
const logCommercePath = 'logCommerce';

const getCommerceLogsForUser = (request: [string, Date, Date, string]) => {
  const [userId, leftDate, rightDate] = request;
  return firebaseManager.getDocsArrayWithWhere<LogDoc<LogCommerce>>(
    logCommercePath,
    [['body.user._id', '==', userId]],
    {
      sortKey: '_timeCreate',
      orderBy: 'desc',
      startValue: leftDate,
      endValue: rightDate,
    }
  );
};

const useCommerceLogForUser = (leftDate: Date, rightDate: Date, userId?: string) => {
  const {
    data: commerceLogForUser,
    error,
    isValidating: commerceLogForUserLoading,
  } = useSWR(userId ? [userId, leftDate, rightDate, 'commerce-logs'] : null, getCommerceLogsForUser, {
    revalidateOnReconnect: false,
    revalidateOnFocus: false,
  });

  return { commerceLogForUser, error, commerceLogForUserLoading };
};

export default useCommerceLogForUser;
