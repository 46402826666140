import { Button, message } from 'antd';
import { FC, useCallback, useState } from 'react';

import { parseIfObject } from 'src/lib/1/util';
import { createStoreIssue } from 'src/lib/4/firebase-short-cut';

const CreateIssueButton: FC = () => {
  const [loading, setLoading] = useState(false);

  const handleOnClick = useCallback(async () => {
    setLoading(true);
    try {
      await createStoreIssue();
    } catch (error) {
      console.error(error);
      message.error(parseIfObject(error));
    }
    setLoading(false);
  }, []);

  return (
    <Button type='primary' onClick={handleOnClick} loading={loading}>
      신규 이슈 생성
    </Button>
  );
};

export default CreateIssueButton;
