import { DeleteOutlined } from '@ant-design/icons';
import { PaymentItemDoc } from '@gooduncles/gu-app-schema';
import { Button, Popconfirm, notification } from 'antd';
import { FC, useCallback, useEffect, useState } from 'react';
import { map } from 'rxjs';

import { errorObjectToString } from 'src/lib/1/util';
import { FirebaseManager } from 'src/lib/3/firebase-manager';
import { deletePaymentItem, observePaymentItem } from 'src/lib/4/firebase-short-cut';

import PaymentItemForm from 'src/components/NewSettlement/PaymentItemForm/PaymentItemForm';
import PaymentItemTable from 'src/components/NewSettlement/PaymentItemTable/PaymentItemTable';
import RawPaymentItemUploadModal from 'src/components/NewSettlement/UploadModal/RawPaymentItemUploadModal';

const firebaseManager = FirebaseManager.getInstance();
const deleteSelectedRows = async (selectedRows: PaymentItemDoc[]) => {
  try {
    firebaseManager.batchStart();
    for (const paymentItem of selectedRows) {
      await deletePaymentItem(paymentItem._id, true);
    }
    await firebaseManager.batchEnd();
  } catch (error) {
    console.error(error);
    const description = errorObjectToString(error);
    notification.error({
      message: '결제내역 삭제 실패',
      description,
    });
  }
};

const UnsettledPaymentItemPage: FC = () => {
  const [rowData, setRowData] = useState<PaymentItemDoc[]>([]);
  const [selectedRows, setSelectedRows] = useState<PaymentItemDoc[]>([]);
  const [loading, setLoading] = useState(false);
  const [openMore, setOpenMore] = useState(false);

  const onConfirm = useCallback(async () => {
    setLoading(true);
    await deleteSelectedRows(selectedRows);
    setSelectedRows([]);
    setLoading(false);
  }, [selectedRows]);

  const toggleOpenMore = useCallback(() => {
    setOpenMore((prev) => !prev);
  }, []);

  useEffect(() => {
    const subscription = observePaymentItem([
      ['settlementResultId', '==', null],
      ['deletedAt', '==', null],
    ])
      .pipe(map((docs) => docs.sort((a, b) => b.transactionDate?.localeCompare(a.transactionDate ?? '') ?? 0)))
      .subscribe(setRowData);
    return () => subscription.unsubscribe();
  }, []);

  return (
    <div className='tabBody flexColumn height100'>
      <section className='flexRow flexSpaceBetween'>
        <div className='flexRow'>
          <RawPaymentItemUploadModal type='kb' unSettledItems={rowData.filter((r) => r.paymentVendor === '국민은행')} />
          <RawPaymentItemUploadModal type='hyosung' />
        </div>
        <div className='flexRow'>
          <Popconfirm title='삭제를 진행하시겠습니까?' onConfirm={onConfirm} okButtonProps={{ loading }}>
            <Button icon={<DeleteOutlined />} danger loading={loading} disabled={selectedRows.length === 0}>
              {`${selectedRows.length}개`} 항목 삭제
            </Button>
          </Popconfirm>
          <Button onClick={toggleOpenMore}>수동입력 {openMore ? '닫기' : '열기'}</Button>
        </div>
      </section>
      <section className='flexRow height100'>
        <div style={{ flex: 2, height: '100%' }}>
          <PaymentItemTable rowData={rowData} setSelectedRows={setSelectedRows} />
        </div>
        {openMore && (
          <div style={{ width: 360, height: '100%' }}>
            <PaymentItemForm />
          </div>
        )}
      </section>
    </div>
  );
};

export default UnsettledPaymentItemPage;
