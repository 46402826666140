import { OrderStatusCode } from '@gooduncles/gu-app-schema';
import { Select } from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';
import { combineLatest, map } from 'rxjs';
import { PackingSheet } from 'src/schema/schema-packing-sheet';
import { generatePackingSheets } from 'src/utils/packing-sheet-util';

import { dayjsesToStringDateWhere, formatDate } from 'src/lib/1/date-util';
import { observeOrder, observeStoreIssue } from 'src/lib/4/firebase-short-cut';

import { selectPartnersUsers } from 'src/redux/slices/partners';
import { useAppSelector } from 'src/redux/store';

import useDeliveryRouteHistoryForDate from 'src/hooks/useDeliveryRouteHistoryForDate';
import useStores from 'src/hooks/useStores';
import { useTitle } from 'src/hooks/useTitle';

import classes from './PackingSheetDashboard.module.scss';

import DatePickerWithArrows from '../Common/DatePickerWithArrows/DatePickerWithArrows';
import PrintWindow from '../PrintWindow/PrintWindow';
import PackingSheetTable from './PackingSheetTable/PackingSheetTable';

const PackingSheetDashboard: FC = () => {
  useTitle('GU 관리자 | 패킹표');
  const { date, setDate, dateForWhere, deliveryRouteHistory } = useDeliveryRouteHistoryForDate();
  const stores = useStores();
  const partnersUsers = useAppSelector(selectPartnersUsers);
  const [packingSheets, setPackingSheets] = useState<Record<string, PackingSheet>>();
  const [packingSheetsLoading, setPackingSheetsLoading] = useState(true);
  const [selectedPartner, setSelectedPartner] = useState<string>();
  const activePartners = useMemo(() => Object.keys(packingSheets ?? {}), [packingSheets]);
  const options = useMemo(
    () =>
      [
        ...partnersUsers
          .map((partner) => ({ value: partner._id, label: `${partner.deliveryDriverStatus.sortKey}-${partner.name}` }))
          .sort((a, b) => a.label.localeCompare(b.label)),
        {
          value: 'undefined',
          label: '미지정',
        },
      ].filter((c) => activePartners?.includes(c?.value ?? '') ?? false),
    [activePartners, partnersUsers]
  );

  useEffect(() => {
    if (date && stores && deliveryRouteHistory) {
      const [leftDate, rightDate] = dayjsesToStringDateWhere(date, date);
      const issueDate = formatDate(dateForWhere, "yyyy-MM-dd'T'00:00:00+0900");
      const acceptedOrdersObservable = observeOrder([['orderStatus', '==', OrderStatusCode.ACCEPTED]]);
      const deliveredOrdersObservable = observeOrder([['orderStatus', '==', OrderStatusCode.DELIVERED]], {
        sortKey: 'deliveredAt',
        orderBy: 'desc',
        startValue: leftDate,
        endValue: rightDate,
      });
      const storeIssuesObservable = observeStoreIssue([['date', '==', issueDate]]).pipe(
        map((issues) =>
          issues
            .filter((issue) => issue.isDeleted !== true)
            .filter((issue) => issue.status === '입력' && ['배송', '회수', '보관'].includes(issue.category ?? ''))
        )
      );
      setPackingSheetsLoading(true);
      const subscription = combineLatest([
        storeIssuesObservable,
        acceptedOrdersObservable,
        deliveredOrdersObservable,
      ]).subscribe(([storeIssues, acceptedOrders, deliveredOrders]) => {
        const packingSheets = generatePackingSheets(
          deliveryRouteHistory,
          stores,
          [...acceptedOrders, ...deliveredOrders],
          storeIssues
        );
        setPackingSheets(packingSheets);
        setPackingSheetsLoading(false);
      });

      return () => {
        setPackingSheetsLoading(false);
        subscription.unsubscribe();
      };
    } else {
      setPackingSheets(undefined);
    }
  }, [stores, deliveryRouteHistory, date, dateForWhere]);

  useEffect(() => {
    if (options.length > 0) {
      // 기존에 선택한 파트너가 데이터 변경으로 없어진 경우
      if (!options.map((o) => o.value).includes(selectedPartner ?? '')) {
        setSelectedPartner(options[0]?.value);
      }
    } else {
      setSelectedPartner(undefined);
    }
  }, [options, selectedPartner]);

  return (
    <div className={classes.dashboardConatiner}>
      <div className={classes.dashboardToolbar}>
        <DatePickerWithArrows date={date} setDate={setDate} />
        <Select
          value={selectedPartner}
          onChange={setSelectedPartner}
          options={options}
          style={{ minWidth: 200 }}
          loading={!partnersUsers || packingSheetsLoading}
        />
        <p>* {"'완료'"}된 이슈는 표시되지 않습니다.</p>
      </div>
      <div className={classes.dashboardContent}>
        {!packingSheetsLoading && (
          <PrintWindow>
            {packingSheets && selectedPartner && packingSheets[selectedPartner] && (
              <>
                <div className={classes.packingSheetHeader}>
                  {partnersUsers.find((c) => c._id === selectedPartner)?.name} / 스팟{' '}
                  {packingSheets[selectedPartner].deliverySpots.length}개 / 매장{' '}
                  {packingSheets[selectedPartner].deliverySpots.reduce((acc, s) => acc + s.stores.length, 0)}개
                </div>
                <PackingSheetTable packingSheet={packingSheets[selectedPartner]} />
              </>
            )}
          </PrintWindow>
        )}
      </div>
    </div>
  );
};

export default PackingSheetDashboard;
