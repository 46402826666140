import { Button, Popconfirm, notification } from 'antd';
import { useState } from 'react';

import { errorObjectToString } from 'src/lib/1/util';
import { deleteSupplier } from 'src/lib/4/firebase-short-cut';

/**
 * 매입처 삭제
 */
export const DeleteSupplierCellRenderer = (props: any) => {
  const [loading, setLoading] = useState(false);
  const supplierId: string = props.value;
  if (!supplierId) return null;
  const onDeleteSupplier = async () => {
    try {
      setLoading(true);
      await deleteSupplier(supplierId);
      notification.success({
        message: '매입처 삭제 성공',
        description: '매입처를 삭제했습니다.',
      });
    } catch (error) {
      console.error(error);
      const errorMessage = errorObjectToString(error);
      notification.error({
        message: '매입처 삭제 실패',
        description: errorMessage ?? '알 수 없는 에러 발생!',
      });
    }
    setLoading(false);
  };
  return (
    <Popconfirm
      title='매입처를 삭제하시겠습니까?'
      onConfirm={onDeleteSupplier}
      okButtonProps={{
        loading,
      }}>
      <Button danger>삭제</Button>
    </Popconfirm>
  );
};
