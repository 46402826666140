import { InvoiceDetailDoc, InvoiceDoc, PaymentItemDoc, SettlementResultDoc } from '@gooduncles/gu-app-schema';
import { useEffect, useState } from 'react';
import { combineLatest, map } from 'rxjs';

import {
  observeInvoiceDetail,
  observeInvoices,
  observePaymentItem,
  observeSettlementResult,
} from 'src/lib/4/firebase-short-cut';

export type SettlementByStoreRowData = {
  invoice: InvoiceDoc;
  settlementResult: SettlementResultDoc | null;
  paymentItems: PaymentItemDoc[] | null;
  invoiceDetail: InvoiceDetailDoc | null;
};

const useSettlementByStore = (storeId?: string) => {
  const [rowData, setRowData] = useState<SettlementByStoreRowData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (storeId) {
      setIsLoading(true);
      const invoiceObservable = observeInvoices([['storeId', '==', storeId]]).pipe(
        map((docs) => docs.filter((doc) => doc.deletedAt === null))
      );
      const settlementResultObservable = observeSettlementResult([['storeId', '==', storeId]]).pipe(
        map((docs) => docs.filter((doc) => doc.deletedAt === null))
      );
      const paymentItemObservable = observePaymentItem([['storeId', '==', storeId]]).pipe(
        map((docs) => docs.filter((doc) => doc.deletedAt === null))
      );
      const invoiceDetailObservable = observeInvoiceDetail([['storeId', '==', storeId]]).pipe(
        map((docs) => docs.filter((doc) => doc.deletedAt === null))
      );
      const subscription = combineLatest([
        invoiceObservable,
        settlementResultObservable,
        paymentItemObservable,
        invoiceDetailObservable,
      ]).subscribe(([invoices0, settlementResults0, paymentItems0, invoiceDetails0]) => {
        setRowData(
          invoices0.map((invoice) => {
            const settlementResult = settlementResults0.find((s) => s.invoiceId === invoice._id) ?? null;
            const paymentItems = settlementResult
              ? paymentItems0.filter((p) => p.settlementResultId === settlementResult._id)
              : null;
            const invoiceDetail = invoiceDetails0.find((d) => d.invoiceId === invoice._id) ?? null;
            return {
              invoice,
              settlementResult,
              paymentItems,
              invoiceDetail,
            };
          })
        );
        setIsLoading(false);
      });
      return () => {
        subscription.unsubscribe();
      };
    } else {
      setRowData([]);
      setIsLoading(false);
    }
  }, [storeId]);

  return {
    rowData,
    isLoading,
  };
};

export default useSettlementByStore;
