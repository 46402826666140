import { FC, useEffect, useState } from 'react';
import { combineLatest } from 'rxjs';

import { observeDeliverySpot, observeStore } from 'src/lib/4/firebase-short-cut';

import { useTitle } from 'src/hooks/useTitle';

import DeliverySpotWithShopTable, { DeliverySpotWithShopRowData } from './DeliverySpotWithShopTable';

const DeliverySpotWithStore: FC = () => {
  useTitle('GU 관리자 | 배송지 관리');
  const [rowData, setRowData] = useState<DeliverySpotWithShopRowData[]>([]);

  useEffect(() => {
    const storeObserver = observeStore();
    const deliverySpotObserver = observeDeliverySpot([['isDeleted', '!=', true]]);

    const subscription = combineLatest([storeObserver, deliverySpotObserver]).subscribe(
      ([storeDocs, deliverySpots]) => {
        const newData = deliverySpots
          .map((spot) => {
            const stores = storeDocs.filter((store) => store.deliverySpotId === spot._id);
            return {
              ...spot,
              partnerId: spot.partnerId ?? 'undefined',
              stores,
            };
          })
          .sort((a, b) => {
            return a.partnerId.localeCompare(b.partnerId);
          });

        setRowData(newData);
      }
    );

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return <DeliverySpotWithShopTable rowData={rowData} />;
};

export default DeliverySpotWithStore;
