import { DeleteOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Modal, Popconfirm } from 'antd';
import { ChangeEvent, FC, ImgHTMLAttributes, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import classes from './ImageEditor.module.scss';

type ImageEditorProps = ImgHTMLAttributes<HTMLImageElement> & {
  imageUrl?: string;
  onInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onDeleteInputFile: () => void;
  /** 서버로 이미지 업로드 */
  onUploadInputFile: () => Promise<void>;
  /** 서버에 있는 이미지 삭제 */
  onDeleteImage?: () => Promise<void>;
};

/**
 * 현재 이미지를 보여주는 컴포넌트
 * over시 확대, 삭제, 변경 버튼이 노출되야힌다.
 */
const ImageEditor: FC<ImageEditorProps> = ({
  src,
  imageUrl,
  onInputChange,
  onDeleteInputFile,
  onUploadInputFile,
  onDeleteImage,
  ...props
}) => {
  const inputId = useMemo(() => uuidv4(), []);
  const [isError, setIsError] = useState(false);
  const [showFullImage, setShowFullImage] = useState(false);
  const [deleting, setDeleting] = useState(false);

  // 이미지 크게보기
  const openFullImage = () => {
    setShowFullImage(true);
  };

  // 이미지 크게보기 닫기
  const closeFullImage = () => {
    setShowFullImage(false);
  };

  const onErrorCapture = () => {
    setIsError(true);
  };

  const onDeleteImageConfirm = async () => {
    if (onDeleteImage) {
      setDeleting(true);
      await onDeleteImage();
      setDeleting(false);
    }
  };

  return (
    <>
      <div className={`${classes.imageEditorContainer} ${src || imageUrl ? '' : classes.uploadMode}`}>
        {/* 이미지가 없는 경우 추가하기 버튼을 노출한다. */}
        {src && !isError ? (
          <>
            <img src={src} {...props} loading='lazy' onErrorCapture={onErrorCapture} />
            <div className={classes.imageEditorButtons}>
              {/* 이미지 크게 보기 버튼 */}
              <Button type='text' size='small' icon={<EyeOutlined />} onClick={openFullImage} />
              {/* 이미지 삭제 버튼 */}
              <Popconfirm
                title='선택한 상품 이미지를 삭제합니다.'
                onConfirm={onDeleteImageConfirm}
                okButtonProps={{
                  loading: deleting,
                }}>
                <Button type='text' size='small' icon={<DeleteOutlined style={{ color: 'var(--red300)' }} />} />
              </Popconfirm>
            </div>
          </>
        ) : imageUrl ? (
          <>
            <img src={imageUrl} {...props} loading='lazy' />
            <div className={`${classes.imageEditorButtons} ${classes.forImageUrl}`}>
              <Button type='primary' size='small' onClick={onUploadInputFile}>
                업로드
              </Button>
              <Button type='primary' size='small' danger onClick={onDeleteInputFile}>
                삭제
              </Button>
            </div>
          </>
        ) : (
          <>
            <label htmlFor={inputId} className={classes.inputLabel}>
              <PlusOutlined />
              <p>업로드</p>
            </label>
            <input
              id={inputId}
              type='file'
              accept='.jpg, .png, image/jpeg, image/png'
              onChange={onInputChange}
              // 동일한 이름의 파일을 추가하는 경우에도 이벤트가 발생하도록 초기화한다.
              onClick={(e) => ((e.target as any).value = null)}
            />
          </>
        )}
      </div>

      {/* 이미지 크게보기 */}
      <Modal open={showFullImage} centered width={800} onCancel={closeFullImage} footer={null}>
        <div className={classes.imageBox}>
          <img src={src} {...props} loading='lazy' />
        </div>
      </Modal>
    </>
  );
};

export default ImageEditor;
