import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Form, Input, InputNumber, Modal, Radio, message, notification } from 'antd';
import confirm from 'antd/es/modal/confirm';
import { FC, useCallback, useState } from 'react';

import { requiredRule } from 'src/lib/1/constant';
import { SupplierPurchaseConversionItem } from 'src/lib/1/schema-purchase-conversion';
import { errorObjectToString } from 'src/lib/1/util';
import { FirebaseManager } from 'src/lib/3/firebase-manager';
import { SupplierDoc } from 'src/lib/3/schema-supplier';
import { getProductById, updateProduct, updateSupplier } from 'src/lib/4/firebase-short-cut';
import { ConsoleLogger } from 'src/lib/5/logger';

import AlgoliaSearch from 'src/components/AlgoriaSearch/AlgoriaSearch';

const firebaseManager = FirebaseManager.getInstance();
const logger = ConsoleLogger.getInstance();
const logName = '발주 상품 규칙 추가';

const initialValues: Omit<SupplierPurchaseConversionItem, 'productId'> = {
  purchaseProductName: null,
  purchaseBundle: 'all',
  purchaseMaxBundle: null,
  purchaseMultiplyUnit: 1,
  purchaseUnit: '개',
};

interface AddConversionItemModalProps {
  suppliers: SupplierDoc[];
  supplier: SupplierDoc;
}

const AddConversionItemModal: FC<AddConversionItemModalProps> = ({ suppliers, supplier }) => {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const openModal = useCallback(() => setOpen(true), []);
  const closeModal = useCallback(() => {
    setLoading(false);
    setOpen(false);
    form.resetFields();
  }, [form]);

  const onAddNewConversionItem = useCallback(async () => {
    const conversionTable = [...supplier.conversionTable];
    try {
      setLoading(true);
      const values: SupplierPurchaseConversionItem = await form.validateFields();
      const { productId } = values;
      if (conversionTable.findIndex((item) => item.productId === productId) !== -1) {
        throw new Error('이미 등록된 상품입니다!');
      }
      const product = await getProductById(productId);
      if (!product) {
        throw new Error('존재하지 않는 상품입니다!');
      }

      // 1. 다른 매입처로 등록된 상품인지 확인한다.
      const duplicatedSuppliers = suppliers.filter((s) =>
        s.conversionTable.map((c) => c.productId).includes(productId)
      );
      if (duplicatedSuppliers.length > 0) {
        confirm({
          title: `${duplicatedSuppliers.map((s) => s.name).join(', ')} 매입처에서 이미 등록된 상품입니다.`,
          icon: <ExclamationCircleFilled />,
          content: '이 상품을 현재 매입처로 등록하시겠습니까?',
          cancelText: '취소',
          onCancel: () => {
            setLoading(false);
          },
          onOk: async () => {
            conversionTable.push(values);
            firebaseManager.batchStart();
            const promises = duplicatedSuppliers.map(async (s) => {
              await updateSupplier(
                s._id,
                { conversionTable: s.conversionTable.filter((i) => i.productId !== productId) },
                true
              );
              message.success(`${s.name} 매입처에서 상품이 제거되었습니다.`);
            });
            await Promise.all(promises);
            await updateSupplier(supplier._id, { conversionTable }, true);
            await updateProduct(productId, { suppliers: [supplier._id] }, true);
            await firebaseManager.batchEnd();
            form.resetFields();
            message.success(`${supplier.name} 매입처에 상품을 추가했습니다.`);
            logger.logConsole(`[${logName}] 발주 상품 규칙 추가\n${productId} - ${values.purchaseProductName}`);
          },
        });
        setLoading(false);
        return;
      }

      conversionTable.push(values);
      firebaseManager.batchStart();
      await updateSupplier(supplier._id, { conversionTable }, true);
      await updateProduct(productId, { suppliers: [supplier._id] }, true);
      await firebaseManager.batchEnd();
      form.resetFields();
      message.success(`${supplier.name} 매입처에 상품을 추가했습니다.`);
      logger.logConsole(`[${logName}] 발주 상품 규칙 추가\n${productId} - ${values.purchaseProductName}`);
    } catch (error) {
      console.error(error);
      const errorMessage = errorObjectToString(error);
      notification.error({
        message: '발주 상품 규칙 추가 실패',
        description: errorMessage ?? '알 수 없는 에러 발생!',
      });
    }
    setLoading(false);
  }, [form, supplier._id, supplier.conversionTable, supplier.name, suppliers]);

  if (!supplier._id) return null;

  return (
    <>
      <Button loading={loading} onClick={openModal} type='primary'>
        매입 상품 추가
      </Button>
      <Modal
        title='매입 상품 추가'
        open={open}
        onOk={form.submit}
        onCancel={closeModal}
        okText='추가'
        cancelText='닫기'
        closable={false}
        maskClosable={false}
        okButtonProps={{ loading }}
        cancelButtonProps={{ loading }}>
        <Form
          form={form}
          initialValues={initialValues}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          layout='horizontal'
          style={{ maxWidth: 720, marginTop: 40 }}
          onFinish={onAddNewConversionItem}>
          <Form.Item label='상품검색'>
            <AlgoliaSearch
              placeholder='검색어를 입력하세요'
              onSearch={(productId, productFullName) => {
                form.setFieldValue('productId', productId);
                form.setFieldValue('purchaseProductName', productFullName);
              }}
            />
          </Form.Item>
          <Form.Item label='상품' name='productId' rules={requiredRule}>
            <Input disabled />
          </Form.Item>
          <Form.Item label='발주명' name='purchaseProductName'>
            <Input />
          </Form.Item>
          <Form.Item label='수량 분할' name='purchaseBundle' rules={requiredRule}>
            <Radio.Group>
              <Radio value='all'>전체</Radio>
              <Radio value='store'>매장별</Radio>
              <Radio value='max'>최대 묶음</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label='최대 묶음' name='purchaseMaxBundle'>
            <Input />
          </Form.Item>
          <Form.Item label='단위 변환 값(곱셈)' name='purchaseMultiplyUnit' rules={requiredRule}>
            <InputNumber />
          </Form.Item>
          <Form.Item label='단위' name='purchaseUnit'>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddConversionItemModal;
