import { SettlementResultDoc } from '@gooduncles/gu-app-schema';
import { ColDef, ColGroupDef } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { FC, useMemo } from 'react';

import { formatDate } from 'src/lib/1/date-util';
import { numberFormatter } from 'src/lib/6/ag-grid-util';

const dateFormatter = ({ value }: { value: string | null }) => (value ? formatDate(value, 'yyyy-MM-dd HH:mm:ss') : '');

export type SettlementResultTableRowData = SettlementResultDoc & {
  storeNickname: string | null;
};

const defaultColDef = {
  sortable: true,
  resizable: true,
  filter: true,
};

interface SettledInvoiceTableProps {
  rowData: SettlementResultTableRowData[];
}

const SettlementResultTable: FC<SettledInvoiceTableProps> = ({ rowData }) => {
  const columnDefs: (ColDef<SettlementResultTableRowData> | ColGroupDef<SettlementResultTableRowData>)[] = useMemo(
    () => [
      { headerName: 'id', field: '_id', hide: true },
      { headerName: '정산시각', field: 'settledAt', valueFormatter: dateFormatter },
      {
        headerName: '매장명',
        field: 'storeNickname',
      },
      { headerName: '매장Id', field: 'storeId', hide: true },
      {
        headerName: '청구금액',
        field: 'invoiceAmount',
        width: 120,
        type: 'numericColumn',
        valueFormatter: numberFormatter,
      },
      {
        headerName: '결재 공급가 합계',
        field: 'paidSupplyPrice',
        width: 160,
        type: 'numericColumn',
        valueFormatter: numberFormatter,
      },
      {
        headerName: '결재 세금 합계',
        field: 'paidTax',
        width: 150,
        type: 'numericColumn',
        valueFormatter: numberFormatter,
      },
      {
        headerName: '미수금',
        field: 'unpaidAmount',
        width: 150,
        type: 'numericColumn',
        valueFormatter: numberFormatter,
      },
      {
        headerName: '재청구 여부',
        field: 'invoiceIdForUnpaid',
        width: 120,
        valueFormatter: ({ value }) => (value ? '✅' : '⚪️'),
      },
    ],
    []
  );

  return (
    <div className='ag-theme-alpine height100 width100'>
      <AgGridReact rowData={rowData} columnDefs={columnDefs} defaultColDef={defaultColDef} />
    </div>
  );
};

export default SettlementResultTable;
