import { DeliveryTaskDoc, PickupTaskDoc } from '@gooduncles/gu-app-schema';
import { Button, Popconfirm, Popover, Radio, RadioChangeEvent, message } from 'antd';
import { FC, useState } from 'react';
import { changeDeliveryTaskPartner, changePickupTaskPartner } from 'src/utils/task-util';

import { selectActiveDriverPartnersUsers } from 'src/redux/slices/partners';
import { useAppSelector } from 'src/redux/store';

interface ChangePartnerPopoverProps {
  type: 'pickup' | 'delivery';
  task: PickupTaskDoc | DeliveryTaskDoc;
}

// TODO: Dropdown으로 변경
const ChangeTaskPartnerPopover: FC<ChangePartnerPopoverProps> = ({ type, task }) => {
  const partners = useAppSelector(selectActiveDriverPartnersUsers);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(task.partnerId);

  const handleClosePopover = () => setOpen(false);

  const handleOpenChange = (newOpen: boolean) => {
    setValue(task.partnerId);
    setOpen(newOpen);
  };

  const handleRadioChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  const handleConfirm = async () => {
    setLoading(true);
    if (type === 'pickup') {
      await changePickupTaskPartner(task as PickupTaskDoc, value);
      message.success('픽업 담당자가 변경되었습니다.');
    } else {
      await changeDeliveryTaskPartner(task as DeliveryTaskDoc, value);
      message.success('배송 담당자가 변경되었습니다.');
    }
    setLoading(false);
    setOpen(false);
  };

  return (
    <Popover
      title='활성화된 담당자만 표시됩니다.'
      trigger='click'
      placement='rightTop'
      open={open}
      onOpenChange={handleOpenChange}
      content={
        <div className='flexColumn'>
          <p>※ 인쇄물 및 이미 생성된 실적에는 반영되지않습니다.</p>
          <Radio.Group onChange={handleRadioChange} value={value}>
            <div className='flexColumn'>
              {partners.map((partner) => (
                <Radio key={partner._id} value={partner._id}>
                  {partner.nickname}
                </Radio>
              ))}
            </div>
          </Radio.Group>
          <div className='flexRow'>
            <Button onClick={handleClosePopover}>취소</Button>
            <Popconfirm
              title='변경하시겠습니까?'
              description='연결된 주문 또는 이슈의 담당자도 변경됩니다.'
              okText='변경'
              cancelText='취소'
              okButtonProps={{ loading }}
              onConfirm={handleConfirm}>
              <Button type='primary' disabled={task.partnerId === value} loading={loading}>
                변경
              </Button>
            </Popconfirm>
          </div>
        </div>
      }>
      <Button size='small' loading={loading}>
        담당자 변경
      </Button>
    </Popover>
  );
};

export default ChangeTaskPartnerPopover;
