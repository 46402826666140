import { OrderDoc, OrderProduct, Product, ProductDoc, StoreDoc } from '@gooduncles/gu-app-schema';
import { format } from 'date-fns';
import { countBy, groupBy } from 'lodash-es';
import { TypedPreProductRequest } from 'src/schema/schema-product-request';

import { StorePurchaseConversionItem } from '../1/schema-purchase-conversion';
import { ParchaseOrderProduct } from '../3/schema-purchase-order';
import { SupplierDoc } from '../3/schema-supplier';

/**
 * 주문을 상품단위로 분할하고,
 * 필요한 경우 매장별 맞춤 발주표 정보를 추가하여 발주를 위한 기본 목록을 구성한다.
 * @return 발주 상품 목록
 */
export const getPurchaseOrderProducts = (orders: OrderDoc[], stores: StoreDoc[]): ParchaseOrderProduct[] => {
  // 1. 주문과 매장 정보를 join한다.
  const ordersWithStore = orders.map((order) => {
    const store = stores.find((store) => store._id === order.storeId);
    return { ...order, store };
  });

  // 2. 상품단위로 분할하고 매장의 맞춤 발주표 정보를 join한다.
  return ordersWithStore.flatMap((order) =>
    order.products.map((product) => ({
      ...product,
      storeId: order.storeId,
      storeNickname: order.store?.storeNickname,
    }))
  );
};

/**
 * 발주표는 매입처 별로 작성되며 이를 위해 발주 상품 목록을 가져와서
 * 매입처 별로 그룹핑한다.
 * @returns 매입처별로 그룹핑된 발주 상품 목록
 */
export const groupPurchaseItemsBySupplier = (
  purchaseOrderProducts: ParchaseOrderProduct[],
  suppliers: SupplierDoc[]
) => {
  /**
   * 발주 상품별 매입처 join
   */
  const purchaseOrderProductsWithSupplier = purchaseOrderProducts.map((p) => {
    // const suppliersForProduct = suppliers.filter(s => s.conversionTable.map(c => c.productId).includes(p.productId));
    const supplier = suppliers.find((s) => s.conversionTable.map((c) => c.productId).includes(p.productId));
    return {
      ...p,
      supplierId: supplier?._id,
    };
  });

  /**
   * 매입처는 여러개 등록할 수 있으나,
   * 별도의 매입처 우선순위 필드를 두기 전까지는 첫번째 매입처로만 그룹핑한다.
   */
  const purchaseOrderProductsGroup = groupBy(
    purchaseOrderProductsWithSupplier,
    (parchaseOrderProduct) => parchaseOrderProduct.supplierId
  );
  return Object.entries(purchaseOrderProductsGroup).map(([supplierId, parchaseOrderProducts]) => {
    const supplier = suppliers.find((s) => s._id === supplierId);

    // 순서 정렬
    if (supplier?.conversionTable) {
      parchaseOrderProducts.sort((a, b) => {
        const indexA = supplier.conversionTable.findIndex((i) => i.productId === a.productId);
        const indexB = supplier.conversionTable.findIndex((i) => i.productId === b.productId);
        return indexA - indexB;
      });
    }

    return { supplier, parchaseOrderProducts };
  });
};

/**
 * 다수의 상품 요청을 묶어 하나의 발주 메시지를 생성한다.
 *
 * @param productId
 * @param supplier
 * @param productRequests productId에 해당하는 상품 요청 목록
 * @returns
 */
export const createPurchaseOrderMessage = (
  product: ProductDoc,
  supplier: SupplierDoc | undefined,
  productRequests: TypedPreProductRequest[]
) => {
  // 매칭되는 매입처 정보가 없는 경우는 무시한다.
  if (!supplier) return '매칭된 매입처 정보가 없는 상품입니다.';
  if (!product) return '상품 정보가 없습니다.';

  // 1. 매입처의 발주표에서 현재 상품에 대한 발주 규칙을 가져온다.
  const supplierConversionItem = supplier.conversionTable.find((i) => i.productId === product.productId);
  /**
   * 신규 상품 등록 및 매입처 맵핑시에 발주표 정보는 기본값으로 자동 생성된다.
   * 이 정보가 없다는 것은 오류이므로 걸러내야한다.
   */
  if (!supplierConversionItem) return '매칭된 발주표 정보가 없는 상품입니다.';
  const purchaseProductName = supplierConversionItem.purchaseProductName ?? product.fullName;

  const { purchaseBundle, purchaseMaxBundle, purchaseMultiplyUnit } = supplierConversionItem;
  const purchaseUnit = supplierConversionItem.purchaseUnit ?? '';

  /**
   * 1) 묶음별 분할
   * A매장: 손질양배추(1개/대/국내산) - 9개
   * B매장: 손질양배추(1개/대/국내산) - 5개
   * C매장: 손질양배추(1개/대/국내산) - 4개
   * -> 손질양배추
   * 1개짜리 x 1
   * 2개짜리 x 1
   * 3개짜리 x 5
   */
  if (purchaseBundle === 'max' && purchaseMaxBundle) {
    const bundleForSupplier = getBundleList(productRequests, purchaseMultiplyUnit, purchaseMaxBundle);
    const quantityMessage = toQuantityMessage(bundleForSupplier, purchaseUnit);
    return `${purchaseProductName}\n${quantityMessage}`;
  }

  /**
   * 2) 총 합계(실수량 합계)
   * A매장: 백오이(5개/국내산) - 2개
   * B매장: 백오이(5개/국내산) - 2개
   * C매장: 백오이(5개/국내산) - 1개
   * -> 백오이 25개
   */
  if (purchaseBundle === 'all') {
    const totalQuantity = productRequests.reduce((acc, cur) => acc + cur.volume * purchaseMultiplyUnit, 0);
    return `${purchaseProductName} ${totalQuantity}${purchaseUnit}`;
  }

  /**
   * 3) 매장별 합계
   * A매장: 감자(1kg/왕특/국내산) - 3개
   * B매장: 감자(1kg/왕특/국내산) - 1개
   * C매장: 감자(1kg/왕특/국내산) - 4개
   * -> 감자 왕특(kg) 1, 3, 4 (총 8kg)
   */
  if (purchaseBundle === 'store') {
    const totalQuantity = productRequests.reduce((acc, cur) => acc + cur.volume * purchaseMultiplyUnit, 0);

    const groupByStore = groupBy(productRequests, (orderProduct) => orderProduct.storeId);
    const quantityMessage = Object.values(groupByStore)
      .map((bySupplier) => bySupplier.reduce((acc, product) => acc + product.volume * purchaseMultiplyUnit, 0))
      .sort();
    return `${purchaseProductName} ${quantityMessage.join(', ')} (총 ${totalQuantity}${purchaseUnit})`;
  }

  /**
   * 4) 매장별 수량 묶음
   * A매장: 대파 (A급/1단/국내산) 1kg - 1개
   * B매장: 대파 (A급/1단/국내산) 1kg - 1개
   * C매장: 대파 (A급/1단/국내산) 1kg - 1개
   * D매장: 대파 (A급/1단/국내산) 1kg - 2개
   * -> 대파
   * 1단 x 3
   * 2단 x 1
   * (총 5단)
   */
  if (purchaseBundle === 'storeGroup') {
    const totalQuantity = productRequests.reduce((acc, cur) => acc + cur.volume * purchaseMultiplyUnit, 0);

    const groupByStore = groupBy(productRequests, (orderProduct) => orderProduct.storeId);
    const quantities = Object.values(groupByStore).map((bySupplier) =>
      bySupplier.reduce((acc, product) => acc + product.volume * purchaseMultiplyUnit, 0)
    );
    const countByQuantity = countBy(quantities);
    const quantityMessage = Object.entries(countByQuantity)
      .sort()
      .map(([quantity, count]) => `${quantity}${purchaseUnit} x ${count}`)
      .join('\n');
    return `${purchaseProductName}\n${quantityMessage}\n(총 ${totalQuantity}${purchaseUnit})`;
  }

  return `변환 실패 - 상품 코드: ${product.productId}`;
};

/**
 * 매장의 묶음 규칙을 적용한 목록을 가져온다.
 * input에 product.conversionItem?.purchaseMaxBundle이 필수인 값만 받는다.
 */
export const getBundleListForStore = (parchaseOrderProducts: ParchaseOrderProduct[], purchaseMultiplyUnit: number) =>
  parchaseOrderProducts.map(
    (orderProduct) =>
      getQuantityForStoreConversion(orderProduct, purchaseMultiplyUnit) as {
        remainder: number;
        amount: number;
        bundle: number;
      }
  );

/**
 * 매입처의 묶음 규칙을 적용한 목록을 가져온다.
 */
export const getBundleList = (
  productRequests: TypedPreProductRequest[],
  purchaseMultiplyUnit: number,
  purchaseMaxBundle: number
) => {
  /**
   * 수량 묶음은 모든 상품 합계에 대한 것이 아닌
   * 매장별로 나뉘어 계산되어야한다.
   * 예)
   * - A매장: 손질양배추(1개/대/국내산) - 9개
   * - B매장: 손질양배추(1개/대/국내산) - 5개
   * - C매장: 손질양배추(1개/대/국내산) - 4개
   * 발주 메시지 -> 손질양배추 1개짜리 x 1 / 2개짜리 x 1 / 3개짜리 x 5
   */
  const groupByStore = groupBy(productRequests, (orderProduct) => orderProduct.storeId);
  return Object.values(groupByStore).map((bySupplier) =>
    getQuantityForSupplierConversion(bySupplier, purchaseMultiplyUnit, purchaseMaxBundle)
  );
};

/**
 * 나머지 수량 메시지를 생성한다. ex) 1200 % 500 = 200 => 200g x 1
 */
export const getRemainderQuantityMessage = (remainder: number, purchaseUnit: string) =>
  remainder && remainder > 0 ? `${remainder}${purchaseUnit} x 1,` : '';

/**
 * 묶음 수량 메시지를 생성한다. ex) 1200 / 500 = 2 => 500g x 2
 */
export const getBundleQuantityMessage = (bundle: number, amount: number, purchaseUnit: string) =>
  `${bundle}${purchaseUnit} x ${amount}`;

/**
 * 매장별 맞춤 발주를 위한 묶음과 수량을 구한다.
 * @param orderProduct
 * @returns bundleItem [묶음, 수량]
 */
export const getQuantityForStoreConversion = (
  orderProduct: OrderProduct & { conversionItem?: StorePurchaseConversionItem },
  purchaseMultiplyUnit: number
) => {
  if (!orderProduct.conversionItem?.purchaseMaxBundle) return null;
  // 1.7 * 1000 = 1700
  const totalVolume = orderProduct.volume * purchaseMultiplyUnit;
  // 500
  const divider = orderProduct.conversionItem.purchaseMaxBundle;
  // 1700 / 500 = 3
  const quotient = Math.floor(totalVolume / divider);
  // 1700 % 500 = 200
  const remainder = totalVolume % divider;
  // [200, 3]
  return { remainder, amount: quotient, bundle: divider };
};

export const getQuantityForSupplierConversion = (
  productRequests: TypedPreProductRequest[],
  purchaseMultiplyUnit: number,
  purchaseMaxBundle: number
) => {
  const totalVolume = productRequests.reduce(
    // 1.5 * 1000 = 1500
    (acc, orderProduct) => acc + orderProduct.volume * purchaseMultiplyUnit,
    0
  );
  const divider = purchaseMaxBundle;
  const quotient = Math.floor(totalVolume / divider);
  const remainder = totalVolume % divider;
  return { remainder, amount: quotient, bundle: divider };
};

/**
 * 묶음과 수량 목록을 메시지로 변환한다.
 * @param bundleList [묶음, 수량][]
 * @param purchaseUnit 단위 (g, kg, ...)
 * @returns
 */
export const toQuantityMessage = (
  bundleList: { remainder: number; amount: number; bundle: number }[],
  purchaseUnit: string
) => {
  const volumeByStore = bundleList.reduce((acc, i) => {
    if (acc[i.remainder]) {
      acc[i.remainder] = acc[i.remainder] + 1;
    } else {
      acc[i.remainder] = 1;
    }

    if (acc[i.bundle]) {
      acc[i.bundle] = acc[i.bundle] + i.amount;
    } else {
      acc[i.bundle] = i.amount;
    }

    return acc;
  }, {} as Record<number, number>);

  return Object.entries(volumeByStore)
    .filter(([bundle, amount]) => Number(bundle) > 0 && amount > 0)
    .map(([bundle, amount]) => `${bundle}${purchaseUnit} x ${amount}`)
    .join('\n');
};

/**
 * 주문일로부터 발주일을 계산한다.
 */
export const getPurchaseOrderDate = (deliveryDateRules: number[], orderDate0: string) => {
  const orderDate = new Date(orderDate0);
  const weekday = orderDate.getDay();
  const days = deliveryDateRules[weekday];
  return format(new Date(orderDate.setDate(orderDate.getDate() + days)), "yyyy-MM-dd'T'00:10:00+0900");
};

/**
 * 발주표는 매입처 별로 작성되며 이를 위해 발주 상품 목록을 가져와서
 * 매입처 별로 그룹핑한다.
 * @returns 매입처별로 그룹핑된 상품 요청 목록
 */
export const groupProductRequestsBySupplier = (
  requestGroupList: {
    product: Product;
    purchaseRequests: TypedPreProductRequest[];
  }[],
  suppliers: SupplierDoc[]
) => {
  /**
   * 발주 상품별 매입처 join
   */
  const productRequestsWithSupplier = requestGroupList.map((group) => {
    const supplier = suppliers.find((s) => s.conversionTable.map((c) => c.productId).includes(group.product.productId));
    return {
      ...group,
      supplierId: supplier?._id,
    };
  });

  /**
   * 매입처는 여러개 등록할 수 있으나,
   * 별도의 매입처 우선순위 필드를 두기 전까지는 첫번째 매입처로만 그룹핑한다.
   */
  const productRequestsGroup = groupBy(
    productRequestsWithSupplier,
    (parchaseOrderProduct) => parchaseOrderProduct.supplierId
  );
  return Object.entries(productRequestsGroup).map(([supplierId, requestListForSupplier]) => {
    const supplier = suppliers.find((s) => s._id === supplierId);

    // 순서 정렬
    if (supplier?.conversionTable) {
      requestListForSupplier.sort((a, b) => {
        const indexA = supplier.conversionTable.findIndex((i) => i.productId === a.product.productId);
        const indexB = supplier.conversionTable.findIndex((i) => i.productId === b.product.productId);
        return indexA - indexB;
      });
    }

    return { supplier, requestListForSupplier };
  });
};

/**
 * 다수의 발주 상품 목록으로 하나의 발주 미리보기 메시지를 생성한다.
 */
export const createPurchaseOrderPreviewMessage = (
  productId: string,
  supplier: SupplierDoc | undefined,
  purchaseOrderProducts: ParchaseOrderProduct[]
) => {
  // 매칭되는 매입처 정보가 없는 경우는 무시한다.
  if (!supplier) return '매칭된 매입처 정보가 없는 상품입니다.';

  const typedPreProductRequests = purchaseOrderProducts.map((p) => {
    const typedPreProductRequest: TypedPreProductRequest = {
      productId: p.productId,
      volume: p.volume,
      storeId: p.storeId,
      type: 'purchase',
      status: 'requested',
      requiredDate: '',
      path: 'order',
      documentId: '',
    };
    return typedPreProductRequest;
  });

  // 1. 매입처의 발주표에서 현재 상품에 대한 발주 규칙을 가져온다.
  const supplierConversionItem = supplier.conversionTable.find((i) => i.productId === productId);
  /**
   * 신규 상품 등록 및 매입처 맵핑시에 발주표 정보는 기본값으로 자동 생성된다.
   * 이 정보가 없다는 것은 오류이므로 걸러내야한다.
   */
  if (!supplierConversionItem) return '매칭된 발주표 정보가 없는 상품입니다.';
  const purchaseProductName = supplierConversionItem.purchaseProductName ?? purchaseOrderProducts[0].fullName;

  const { purchaseBundle, purchaseMaxBundle, purchaseMultiplyUnit } = supplierConversionItem;
  const purchaseUnit = supplierConversionItem.purchaseUnit ?? '';

  /**
   * 1) 묶음별 분할
   * A매장: 손질양배추(1개/대/국내산) - 9개
   * B매장: 손질양배추(1개/대/국내산) - 5개
   * C매장: 손질양배추(1개/대/국내산) - 4개
   * -> 손질양배추
   * 1개짜리 x 1
   * 2개짜리 x 1
   * 3개짜리 x 5
   */
  if (purchaseBundle === 'max' && purchaseMaxBundle) {
    const bundleForSupplier = getBundleList(typedPreProductRequests, purchaseMultiplyUnit, purchaseMaxBundle);
    const quantityMessage = toQuantityMessage(bundleForSupplier, purchaseUnit);
    return `${purchaseProductName}\n${quantityMessage}`;
  }

  /**
   * 2) 총 합계(실수량 합계)
   * A매장: 백오이(5개/국내산) - 2개
   * B매장: 백오이(5개/국내산) - 2개
   * C매장: 백오이(5개/국내산) - 1개
   * -> 백오이 25개
   */
  if (purchaseBundle === 'all') {
    const totalQuantity = typedPreProductRequests.reduce((acc, cur) => acc + cur.volume * purchaseMultiplyUnit, 0);
    return `${purchaseProductName} ${totalQuantity}${purchaseUnit}`;
  }

  /**
   * 3) 매장별 합계
   * A매장: 감자(1kg/왕특/국내산) - 3개
   * B매장: 감자(1kg/왕특/국내산) - 1개
   * C매장: 감자(1kg/왕특/국내산) - 4개
   * -> 감자 왕특(kg) 1, 3, 4 (총 8kg)
   */
  if (purchaseBundle === 'store') {
    const totalQuantity = typedPreProductRequests.reduce((acc, cur) => acc + cur.volume * purchaseMultiplyUnit, 0);

    const groupByStore = groupBy(typedPreProductRequests, (orderProduct) => orderProduct.storeId);
    const quantityMessage = Object.values(groupByStore)
      .map((bySupplier) => bySupplier.reduce((acc, product) => acc + product.volume * purchaseMultiplyUnit, 0))
      .sort();
    return `${purchaseProductName} ${quantityMessage.join(', ')} (총 ${totalQuantity}${purchaseUnit})`;
  }

  /**
   * 4) 매장별 수량 묶음
   * A매장: 대파 (A급/1단/국내산) 1kg - 1개
   * B매장: 대파 (A급/1단/국내산) 1kg - 1개
   * C매장: 대파 (A급/1단/국내산) 1kg - 1개
   * D매장: 대파 (A급/1단/국내산) 1kg - 2개
   * -> 대파
   * 1단 x 3
   * 2단 x 1
   * (총 5단)
   */
  if (purchaseBundle === 'storeGroup') {
    const totalQuantity = typedPreProductRequests.reduce((acc, cur) => acc + cur.volume * purchaseMultiplyUnit, 0);

    const groupByStore = groupBy(typedPreProductRequests, (orderProduct) => orderProduct.storeId);
    const quantities = Object.values(groupByStore).map((bySupplier) =>
      bySupplier.reduce((acc, product) => acc + product.volume * purchaseMultiplyUnit, 0)
    );
    const countByQuantity = countBy(quantities);
    const quantityMessage = Object.entries(countByQuantity)
      .sort()
      .map(([quantity, count]) => `${quantity}${purchaseUnit} x ${count}`)
      .join('\n');
    return `${purchaseProductName}\n${quantityMessage}\n(총 ${totalQuantity}${purchaseUnit})`;
  }

  return `변환 실패 - 상품 코드: ${productId}`;
};
