import { InvoiceDetailDoc, InvoiceTemplate } from '@gooduncles/gu-app-schema';
import { Button, Popconfirm, notification } from 'antd';
import dayjs from 'dayjs';
import { chunk } from 'lodash-es';
import { FC, useCallback, useEffect, useState } from 'react';
import { map } from 'rxjs';
import { callSendKakaoAlimTalkForInvoice } from 'src/utils/firebase-callable';
import { encryptInvoiceId } from 'src/utils/payment-util';

import { isProduction, senderKey } from 'src/lib/1/constant';
import { dayjsesToStringDateWhere, formatDate, getDefaultDate } from 'src/lib/1/date-util';
import { InstallmentBillTemplate } from 'src/lib/1/schema-alimtalk-template';
import { errorObjectToString, formatNumber, sleep } from 'src/lib/1/util';
import { KakaoAlimtalkRecipientItem } from 'src/lib/2/schema-nhn-notification';
import { FirebaseManager } from 'src/lib/3/firebase-manager';
import { deleteInvoiceDetail, getAlimtalkMessage, observeInvoiceDetail } from 'src/lib/4/firebase-short-cut';
import { organizeKakaotalkResultMessage } from 'src/lib/4/nhn-notification-util';

import DatePickerWithArrows from 'src/components/Common/DatePickerWithArrows/DatePickerWithArrows';
import Loading from 'src/components/Loading/Loading';
import InvoiceDetailTable from 'src/components/NewSettlement/InvoiceTable/InvoiceDetailTable';

const defaultValue = getDefaultDate();
const firebsaeManager = FirebaseManager.getInstance();

/**
 * 카카오톡 알림톡을 발송하고 해당 row를 업데이트한다.
 */
const onSendKakaoAlimTalk = async (recipientList: KakaoAlimtalkRecipientItem<InvoiceTemplate>[]) => {
  try {
    // 1. 카카오톡 전송
    if (!senderKey) {
      throw new Error('senderKey가 없습니다.');
    }

    const result = await callSendKakaoAlimTalkForInvoice({
      senderKey,
      recipientList,
      templateCode: 'invoice',
    });

    if (result.data.result !== 'success') {
      throw new Error(`카카오톡 전송 요청에 실패했습니다. ${result.data.reason ?? '알 수 없는 오류'}`);
    }

    if (!result.data.alimtalkMessageDocId) {
      throw new Error('요청은 성공했으나, ID를 받지 못했습니다.');
    }

    const alimtalkMessageDoc = await getAlimtalkMessage<InstallmentBillTemplate>(result.data.alimtalkMessageDocId);
    if (!alimtalkMessageDoc) {
      throw new Error('요청은 성공했으나, 메시지 정보를 받지 못했습니다.');
    }

    // 요청 결과를 메시지로 정리한다.
    const description = organizeKakaotalkResultMessage(alimtalkMessageDoc.recipientWithResultList);

    notification.success({
      message: '전송 요청 성공',
      description,
      className: 'pre-line-notification',
    });
  } catch (error) {
    console.error(error);
    const errorMessage = errorObjectToString(error);
    notification.error({
      message: '정산 목록 전송에 실패했습니다.',
      description: errorMessage,
    });
  }
};

const handleDeleteInvoiceDetail = async (rows: InvoiceDetailDoc[]) => {
  try {
    firebsaeManager.batchStart();
    for (const row of rows) {
      await deleteInvoiceDetail(row._id, true);
    }
    await firebsaeManager.batchEnd();
    notification.success({ message: '삭제 성공' });
  } catch (error) {
    console.error(error);
    const errorMessage = errorObjectToString(error);
    notification.error({
      message: '삭제에 실패했습니다.',
      description: errorMessage,
    });
  }
};

const InvoiceDetailPage: FC = () => {
  const [date, setDate] = useState<dayjs.Dayjs | null>(defaultValue);
  const [rowData, setRowData] = useState<InvoiceDetailDoc[]>([]);
  const [selectedRows, setSelectedRows] = useState<InvoiceDetailDoc[]>([]);
  const [loading, setLoading] = useState(false);

  const onDeleteInvoiceDetail = useCallback(async () => {
    if (selectedRows.length === 0) {
      notification.info({ message: '삭제할 청구서를 선택해주세요.' });
      return;
    }
    setLoading(true);
    await handleDeleteInvoiceDetail(selectedRows);
    setSelectedRows([]);
    setLoading(false);
  }, [selectedRows]);

  const sendInvoiceDetails = useCallback(async () => {
    if (selectedRows.length === 0) {
      notification.info({ message: '청구서를 선택해주세요.' });
      return;
    }

    const recipientList = selectedRows
      .filter((row) => row.recipientInfo.userTel.length > 8)
      .map((invoiceDetail) => {
        const encryptedInvoiceId = encryptInvoiceId(invoiceDetail._id);
        const alimtalkRecipientItem: KakaoAlimtalkRecipientItem<InvoiceTemplate> = {
          recipientNo: invoiceDetail.recipientInfo.userTel,
          templateParameter: {
            date: formatDate(invoiceDetail.date, 'yy년 L월 d일(EEEEEE)'),
            store: invoiceDetail.recipientInfo.name,
            amount: formatNumber(invoiceDetail.invoiceAmount),
            base: isProduction ? 'app.gooduncles.com/invoice' : 'dev.app.gooduncles.com/invoice',
            invoice: encryptedInvoiceId,
          },
          recipientGroupingKey: invoiceDetail._id,
        };
        return alimtalkRecipientItem;
      });

    setLoading(true);
    // 대량 발송의 경우 간헐적으로 콜백을 받지 못하는 에러가 발생하여 20개씩 나눠서 보낸다.
    const chunkSize = 20;
    const chunkedRecipientList = chunk(recipientList, chunkSize);
    for (const chunkedRecipientListItem of chunkedRecipientList) {
      await onSendKakaoAlimTalk(chunkedRecipientListItem);
      // 정상 콜백 처리를 위해 연달아 발송시 300ms 딜레이를 준다.
      await sleep(300);
    }
    setSelectedRows([]);
    setLoading(false);
  }, [selectedRows]);

  useEffect(() => {
    if (date) {
      setLoading(true);
      const rightDateForADay = dayjs(formatDate(date.toDate(), "yyyy-MM-dd'T'23:59:59+0900"));
      const [leftDate, rightDate] = dayjsesToStringDateWhere(date, rightDateForADay);
      const subscription = observeInvoiceDetail([], {
        sortKey: 'date',
        orderBy: 'desc',
        startValue: leftDate,
        endValue: rightDate,
      })
        .pipe(map((docs) => docs.filter((d) => d.deletedAt === null)))
        .subscribe((docs) => {
          setRowData(docs);
          setLoading(false);
        });
      return () => subscription.unsubscribe();
    }
  }, [date]);

  return (
    <>
      {loading && <Loading title='잠시만 기다려주세요.' />}
      <div className='tabBody flexColumn height100'>
        <section className='flexRow flexSpaceBetween'>
          <div className='flexRow'>
            <DatePickerWithArrows date={date} setDate={setDate} />
          </div>
          <div className='flexRow'>
            <Popconfirm title='삭제를 진행하시겠습니까?' onConfirm={onDeleteInvoiceDetail} okButtonProps={{ loading }}>
              <Button disabled={selectedRows.length <= 0} danger loading={loading}>
                {`${selectedRows.length}개 삭제`}
              </Button>
            </Popconfirm>
            <Popconfirm title='발송을 진행하시겠습니까?' onConfirm={sendInvoiceDetails} okButtonProps={{ loading }}>
              <Button disabled={selectedRows.length <= 0} loading={loading}>
                {`${selectedRows.length}개 청구서 발송`}
              </Button>
            </Popconfirm>
          </div>
        </section>
        <section className='height100'>
          <InvoiceDetailTable rowData={rowData} setSelectedRows={setSelectedRows} />
        </section>
      </div>
    </>
  );
};

export default InvoiceDetailPage;
