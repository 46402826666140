import { MailOutlined } from '@ant-design/icons';
import { Button, Popconfirm, notification } from 'antd';
import { useState } from 'react';
import { callSendMobileMessage } from 'src/utils/firebase-callable';

import { sendNo } from 'src/lib/1/constant';
import { errorObjectToString } from 'src/lib/1/util';
import { PurchaseOrderDoc } from 'src/lib/3/schema-purchase-order';
import { getPurchaseOrder } from 'src/lib/4/firebase-short-cut';

export const SendSmsCellRenderer = (props: any) => {
  const [loading, setLoading] = useState(false);
  const purchaseOrderId = props.value;
  const [purchaseOrder, setPurchaseOrder] = useState<PurchaseOrderDoc | null>(null);

  const onSendPurchaseOrder = async () => {
    if (!purchaseOrder) return;
    setLoading(true);
    try {
      // const message = purchaseOrder.messagePrefix + purchaseOrder.message + purchaseOrder.messageSuffix ;
      const message =
        purchaseOrder.messagePrefix +
        purchaseOrder.message +
        purchaseOrder.messageSuffix +
        '\n내용문의는 010-6236-1736으로 연락주세요. ';
      const recipientList = (
        purchaseOrder.supplierSubTels
          ? [purchaseOrder.supplierTel, ...purchaseOrder.supplierSubTels]
          : [purchaseOrder.supplierTel]
      ).map((tel) => ({
        recipientNo: tel.replace(/-/g, ''),
        recipientGroupingKey: purchaseOrder.supplierName,
      }));
      const result = await callSendMobileMessage({
        body: message,
        sendNo,
        recipientList,
      });
      if (result.data.reason) {
        throw new Error(result.data.reason);
      }
    } catch (error) {
      console.error(error);
      const description = errorObjectToString(error);
      notification.error({
        message: '발송에 실패했습니다.',
        description,
      });
    }
    setLoading(false);
  };

  const onOpenChange = async (open: boolean) => {
    if (open) {
      setLoading(true);
      try {
        const purchaseOrder = await getPurchaseOrder(purchaseOrderId);
        setPurchaseOrder(purchaseOrder ?? null);
      } catch (error) {
        console.error(error);
        const description = errorObjectToString(error);
        notification.error({
          message: '정보를 가져오는데 실패했습니다.',
          description,
        });
      }
      setLoading(false);
    }
  };

  return (
    <Popconfirm
      title={'문자를 발송합니다.'}
      description={
        purchaseOrder
          ? `번호: ${
              (purchaseOrder.supplierSubTels
                ? [purchaseOrder.supplierTel, ...purchaseOrder.supplierSubTels]
                : [purchaseOrder.supplierTel]
              ).join(', ') ?? ''
            }`
          : undefined
      }
      onOpenChange={onOpenChange}
      onConfirm={onSendPurchaseOrder}
      okButtonProps={{
        disabled: purchaseOrder === null,
        loading,
      }}
      cancelButtonProps={{
        loading,
      }}>
      <Button icon={<MailOutlined />} />
    </Popconfirm>
  );
};
