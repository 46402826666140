import { UserDoc } from '@gooduncles/gu-app-schema';
import { FC, useEffect, useState } from 'react';
import { combineLatest } from 'rxjs';

import { FirebaseManager } from 'src/lib/3/firebase-manager';
import { observeStore } from 'src/lib/4/firebase-short-cut';

import UserTable, { UserWithStore } from './UserTable/UserTable';

const firebaseManager = FirebaseManager.getInstance();
const userPath = 'user';

const UserList: FC = () => {
  const [rowData, setRowData] = useState<UserWithStore[]>([]);

  useEffect(() => {
    const usersObservable = firebaseManager.observeCollection<UserDoc>(userPath, [], {
      sortKey: '_timeCreate',
      orderBy: 'desc',
    });
    const storeObervable = observeStore();

    const subscription = combineLatest([usersObservable, storeObervable]).subscribe(([userDocs, storeDocs]) => {
      const data: UserWithStore[] = userDocs.map((userDoc) => {
        const matchingStore = storeDocs.find((store) => store._id === userDoc.storeId);
        return {
          key: userDoc._id,
          ...userDoc,
          store: matchingStore,
        };
      });
      setRowData(data);
    });

    return () => {
      subscription?.unsubscribe();
    };
  }, []);

  return (
    <div className='height100 flexColumn'>
      <UserTable rowData={rowData} />
    </div>
  );
};

export default UserList;
