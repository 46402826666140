import { CloseOutlined, FormOutlined } from '@ant-design/icons';
import { MemoDoc } from '@gooduncles/gu-app-schema';
import { Button, Input, InputNumber, Tabs, message } from 'antd';
import { debounce } from 'lodash-es';
import { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { LocalStorageKey } from 'src/lib/1/constant';
import { getLocalStorageValue, setLocalStorageValue } from 'src/lib/1/util';
import { getMemo, updateMemo } from 'src/lib/4/firebase-short-cut';

import { useTitle } from 'src/hooks/useTitle';

import HyosungMemberPage from './HyosungMemberPage';
import InvoiceCreationPage from './InvoiceCreationPage';
import InvoiceDetailPage from './InvoiceDetailPage';
import NewUnsettledInvoicePage from './NewUnsettledInvoicePage';
import PrevUnsettledInvoicePage from './PrevUnsettledInvoicePage';
import UnsettledPaymentItemPage from './UnsettledPaymentItemPage';

const memoDocId = 'settlement-invoice';
const settlementProcess = [
  {
    key: 'payment-item',
    title: '결제 내역 업데이트',
    description: '기존 내역에 대한 정산을 진행하기에 앞서 최신 수납 및 입금 내역을 업로드합니다.',
    component: <UnsettledPaymentItemPage />,
  },
  {
    key: 'settlement',
    title: '정산',
    description:
      '청구서와 결제 내역을 묶어서 가게별로 정산을 진행합니다. 청구서는 있으나 결제 내역이 존재하지 않는 경우에는 정산시 청구된 금액이 미납금으로 처리됩니다.',
    component: <PrevUnsettledInvoicePage />,
  },
  {
    key: 'hyosung-member',
    title: 'ⓗ CMS 회원',
    description: '신규 회원이 있을 수 있으므로 청구서 발행전에 CMS회원정보를 미리 업데이트합니다.',
    component: <HyosungMemberPage />,
  },
  {
    key: 'preview',
    title: '청구서 미리보기',
    // TODO: 가게별 결제 주기 정보가 입력되면 주기별 청구서 생성 기능을 추가한다.
    description:
      '지난 정산 이후의 주문과 이슈내역 그리고 미납금을 가게별로 묶은 청구서를 미리보기 및 생성 할 수 있습니다. 가게마다 정산 주기가 다를 수 있기 때문에 청구서 생성은 관리자가 선택해서 진행합니다.',
    component: <InvoiceCreationPage />,
  },
  {
    key: 'unsettled',
    title: '청구서(관리)',
    // 정산 완료되지 않은 오래된 청구서가 있는 경우 UI로 표시한다.
    // 미납금을 클릭하면 기존 청구서와 정산결과를 보여줍니다.
    description:
      '새로운 청구서를 관리합니다. 미납금이 있는 경우 면세 과세를 구분해주거나, 잘못 생성된 청구서를 삭제합니다. 편집이 완료되면 고객용 청구서를 생성하고 CMS청구 파일을 다운로드합니다.',
    component: <NewUnsettledInvoicePage />,
  },
  {
    key: 'published',
    title: '청구서(고객용)',
    description: '고객에게 발송하는 청구서 목록을 확인하고, 발송을 진행합니다. 필요시 내용을 직접 수정합니다.',
    component: <InvoiceDetailPage />,
  },
];

const InvoicePage: FC = () => {
  const [memoWidth, setMemoWidth] = useState(getLocalStorageValue(LocalStorageKey.memoWidth) ?? 240);
  const [loadingMemo, setLoadingMemo] = useState(false);
  const [openMemo, setOpenMemo] = useState(false);
  const [memo, setMemo] = useState<MemoDoc>();
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab') || settlementProcess[0].key;
  useTitle(`GU 관리자 | 청구서 - ${settlementProcess.find((item) => item.key === tab)?.title}`);

  const onTabChange = (key: string) => {
    setSearchParams({ tab: key });
  };

  const handleOpenMemo = () => {
    setOpenMemo((prev) => !prev);
  };

  const handleMemoWidthChange = (value: number | null) => {
    if (!value || value < 190) {
      message.error('190보다 큰 값을 입력해주세요.');
      setMemoWidth(190);
    } else {
      setMemoWidth(value);
      setLocalStorageValue(LocalStorageKey.memoWidth, value);
    }
  };

  const handleMemoChange = async (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setLoadingMemo(true);
    try {
      await updateMemo(memoDocId, {
        content: value,
      });
    } catch (error) {
      console.error(error);
      message.error('메모를 업데이트하는데 실패했습니다.');
    }
    setLoadingMemo(false);
  };

  useEffect(() => {
    setLoadingMemo(true);
    getMemo(memoDocId).then((doc) => {
      setMemo(doc);
      setLoadingMemo(false);
    });
  }, [openMemo]);

  return (
    <div className='flexRow height100' style={{ position: 'relative' }}>
      <Tabs
        type='card'
        activeKey={tab}
        style={{ height: '100%', flex: 1 }}
        tabBarStyle={{ marginBottom: 0 }}
        onChange={onTabChange}
        destroyInactiveTabPane
        items={settlementProcess.map((item, idx) => ({
          key: item.key,
          label: `${idx + 1}. ${item.title}`,
          children: item.component,
        }))}
      />
      <div style={{ position: 'absolute', top: 0, right: 0 }}>
        <Button
          loading={loadingMemo}
          disabled={!memo}
          icon={openMemo ? <CloseOutlined /> : <FormOutlined />}
          style={{ marginRight: 8 }}
          onClick={handleOpenMemo}
        />
        {openMemo && <InputNumber defaultValue={memoWidth} onChange={debounce(handleMemoWidthChange, 200)} />}
      </div>
      {openMemo && (
        <div style={{ marginTop: 40, minWidth: memoWidth }}>
          <Input.TextArea
            defaultValue={memo?.content}
            style={{ height: '100%' }}
            onChange={debounce(handleMemoChange, 1000)}
          />
        </div>
      )}
    </div>
  );
};

export default InvoicePage;
