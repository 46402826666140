import { Checkbox, Input, InputNumber, Radio, RadioChangeEvent } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { FC, useEffect, useState } from 'react';

import { dateFormat06 } from 'src/lib/1/date-util';
import { KakaoTalkType } from 'src/lib/2/schema-nhn-notification';

import classes from './NotificationDashboard.module.scss';

import DailyProductListKakaotalkModal from './DailyProductListKakaotalkModal/DailyProductListKakaotalkModal';
import NotificationForm from './NotificationForm/NotificationForm';

const kakaoTalkTypeOptions: { label: string; value: KakaoTalkType; disabled?: boolean }[] = [
  { label: '친구톡(건당 15원)', value: 'friendtalk' },
  { label: '알림톡(건당 7.9원)', value: 'alimtalk', disabled: true },
];

const kakaoTalkTypeInfo = {
  alimtalk: '※ 알림톡은 채널을 추가하지 않은 사용자에게도 전송할 수 있습니다.',
  friendtalk: '※ 친구톡은 채널을 추가한 사용자에게만 전송됩니다.',
};

const Notification: FC = () => {
  const [defaultTitle, setDefaultTitle] = useState<string>(`[이웃삼촌-${dateFormat06()} 야채가격 알려드립니다.]`);
  const [kakaoTalkType, setKakaoTalkType] = useState<KakaoTalkType>('friendtalk');
  const [isCustomTitle, setIsCustomTitle] = useState(false);
  const [customTitle, setCustomTitle] = useState('');
  const [dailyTalkMonth, setDailyTalkMonth] = useState(2);

  const onChangeKakaoTalkType = ({ target: { value } }: RadioChangeEvent) => {
    setKakaoTalkType(value);
  };

  const onCustomTitleCheck = (e: CheckboxChangeEvent) => {
    setIsCustomTitle(e.target.checked);
  };

  const onCustomTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomTitle(e.target.value);
  };

  const onChangeDailyTalkMonth = (value: number | null) => {
    setDailyTalkMonth(value ?? 1);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setDefaultTitle(`[이웃삼촌-${dateFormat06()} 야채가격 알려드립니다.]`);
    }, 1000 * 60);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <>
      <div className={classes.notificationContaincer}>
        <h1>알림</h1>
        <p>사용자에게 알림을 보내거나 관련 설정을 변경할 수 있습니다.</p>

        <section>
          <h2>카카오톡 일일 가격 알림</h2>
          <p>
            설정한 기간의 주문과 즐겨찾기를 기반으로 일일 가격 알림을 전송합니다.
            <br />
            이중 전송을 방지하기 위해 최근 결과 내역을 확인해주세요.
          </p>
          <div className={classes.dailyTalkContainer}>
            <div className={classes.dailyPriceContent}>
              <Radio.Group options={kakaoTalkTypeOptions} onChange={onChangeKakaoTalkType} value={kakaoTalkType} />
              <p className={classes.ratioTip}>{kakaoTalkTypeInfo[kakaoTalkType]}</p>

              <p style={{ marginBottom: 8 }}>관심 상품에 포함할 주문 기간</p>
              <InputNumber
                min={1}
                max={24}
                value={dailyTalkMonth}
                onChange={onChangeDailyTalkMonth}
                addonAfter='개월'
              />
              <br />
              <Checkbox onChange={onCustomTitleCheck} checked={isCustomTitle} style={{ marginBottom: 10 }}>
                제목 직접 입력
              </Checkbox>
              <Input disabled={!isCustomTitle} placeholder={defaultTitle} onChange={onCustomTitleChange} />
            </div>
            <div className={classes.right}>
              <p className={classes.messageSample}>
                {isCustomTitle ? customTitle : defaultTitle}
                <br />
                <br />
                깐마늘(1kg/대/국내산) 9,800원
                <br />
                깐생강(1kg/A급/국내산) 9,000원
                <br />
                ...
              </p>
            </div>
          </div>
          <DailyProductListKakaotalkModal title={isCustomTitle ? customTitle : defaultTitle} month={dailyTalkMonth} />
        </section>
        <div className={classes.divider} />
        <section>
          <h2>이웃삼촌 알림</h2>
          <p>이웃삼촌 서비스의 우측 상단에 표시되는 알림을 생성합니다.</p>
          <div>
            <NotificationForm />
          </div>
        </section>
      </div>
    </>
  );
};

export default Notification;
