import { DownloadOutlined } from '@ant-design/icons';
import { ProductBinLocationDoc } from '@gooduncles/gu-app-schema';
import { Button } from 'antd';
import { FC, useCallback, useEffect, useState } from 'react';
import { combineLatest } from 'rxjs';
import { compareBinLocation } from 'src/utils/product-request-util';

import { formatDate } from 'src/lib/1/date-util';
import { jsonToExcelFile } from 'src/lib/1/xlsx-util';
import { observeProduct, observeSupplier } from 'src/lib/4/firebase-short-cut';

import useProductBinLocation from 'src/hooks/useProductBinLocation';
import { useTitle } from 'src/hooks/useTitle';

import Loading from '../Loading/Loading';
import ProductBinLocationTable, { ProductBinLocationTableRowData } from './ProductBinLocationTable';
import ProductBinLocationUploadModal from './UploadModal/ProductBinLocationUploadModal';

/**
 * 재고 위치 편집을 위한 엑셀 파일을 다운로드합니다.
 */
const DownloadBinLocationExcel = (rowData: ProductBinLocationTableRowData[]) => {
  const date = formatDate(new Date(), 'yyyy-MM-dd');
  const data = rowData.map((row) => ({
    상품ID: row._id,
    구분: row.supplier?.label ?? '',
    매입처: row.supplier?.name ?? '',
    상품명: row.fullName,
    재고위치: row.binLocation ?? '',
  }));
  jsonToExcelFile(data, `재고위치_${date}`);
};

/**
 * 창고에서 출고되는 상품의 경우 해당 상품의 위치를 맵핑하고,
 * 위치별로 정렬하여 출고 담당자의 작업을 돕는다.
 * @param products
 * @param binLocation
 * @returns
 */
const mergeWithBinLocation = (
  products: Omit<ProductBinLocationTableRowData, 'binLocation'>[],
  binLocation?: ProductBinLocationDoc
): ProductBinLocationTableRowData[] => {
  const productsWithLocation = products.map((product) => ({ ...product, binLocation: null }));
  if (!binLocation) return productsWithLocation;
  const { productBinLocation } = binLocation;
  return (
    productsWithLocation
      .map((product) => {
        const binLocation: string | null = productBinLocation[product._id] ?? product.binLocation;
        return { ...product, binLocation };
      })
      // 4순위 - 매입처명
      .sort((a, b) => a.supplier?.name?.localeCompare(b.supplier?.name || '') ?? 0)
      // 3순위 - 상품명
      .sort((a, b) => a.fullName.localeCompare(b.fullName))
      // 2순위 - 재고 위치
      .sort((a, b) => compareBinLocation(a.binLocation, b.binLocation))
      // 1순위 - 구분(E, A, B, C, D)
      .sort((a, b) => {
        // 먼저, label이 undefined인 경우를 처리
        if (a.supplier?.label === undefined && b.supplier?.label !== undefined) {
          return 1; // a가 undefined이면, b보다 뒤에 위치
        } else if (a.supplier?.label !== undefined && b.supplier?.label === undefined) {
          return -1; // b가 undefined이면, a가 b보다 앞에 위치
        }

        if (a.supplier?.label === 'E' && b.supplier?.label !== 'E') {
          // a가 'E'라면, 항상 b보다 앞서도록 함
          return -1;
        } else if (a.supplier?.label !== 'E' && b.supplier?.label === 'E') {
          // b가 'E'라면, 항상 a보다 앞서도록 함
          return 1;
        }
        return (a.supplier?.label ?? '').localeCompare(b.supplier?.label ?? '') ?? 0;
      })
  );
};

const ProductBinLocationDashboard: FC = () => {
  useTitle('GU 관리자 | 재고 위치 관리');
  const [products, setProducts] = useState<Omit<ProductBinLocationTableRowData, 'binLocation'>[]>([]);
  const productBinLocation = useProductBinLocation();
  const productsWithLocation = mergeWithBinLocation(products, productBinLocation);
  const [excelLoading, setExcelLoading] = useState(false);

  const handleDownloadExcel = useCallback(() => {
    setExcelLoading(true);
    DownloadBinLocationExcel(productsWithLocation);
    setExcelLoading(false);
  }, [productsWithLocation]);

  useEffect(() => {
    const productObservable = observeProduct();
    const supplierObservable = observeSupplier();
    const subscription = combineLatest([productObservable, supplierObservable]).subscribe(([products0, suppliers]) => {
      const products1 = products0.map((product) => {
        const supplierId = product.suppliers[0] ?? null;
        const supplier = supplierId ? suppliers.find((supplier) => supplier._id === supplierId) : undefined;
        return {
          ...product,
          supplier: supplier ?? null,
        };
      });
      setProducts(products1);
    });
    return () => subscription.unsubscribe();
  }, []);

  return (
    <div className='height100 flexColumn'>
      {excelLoading && <Loading title='잠시만 기다려주세요.' />}
      <div className='flexRow flexEnd'>
        <ProductBinLocationUploadModal />
        <Button icon={<DownloadOutlined />} onClick={handleDownloadExcel}>
          엑셀 다운로드
        </Button>
      </div>
      {productBinLocation ? (
        <ProductBinLocationTable rowData={productsWithLocation} />
      ) : (
        <Loading title='정렬 데이터를 불러오고 있습니다.' />
      )}
    </div>
  );
};

export default ProductBinLocationDashboard;
