import { FC } from 'react';

import { formatNumber } from 'src/lib/1/util';

import useProduct from 'src/hooks/useProduct';

const ProductStockRenderer: FC<{ productId: string; volume: number }> = ({ productId, volume }) => {
  const { product, productLoading } = useProduct(productId);

  if (productLoading) {
    return <div>isLoading</div>;
  }

  if (product?.stock === undefined) {
    return <span>?</span>;
  }

  const isExceed = product.stock < volume;
  const exceed = product.stock - volume;

  return (
    <span style={isExceed ? { color: 'var(--red400)', fontWeight: 'bold' } : undefined}>
      {product.stock === Infinity ? '' : formatNumber(product.stock)}
      {isExceed ? `(${exceed})` : ''}
    </span>
  );
};

export default ProductStockRenderer;
