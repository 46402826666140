import { FC, useEffect, useMemo, useState } from 'react';

import { RangeEventValue, dayjsesToDateWhere, getDefaultDates } from 'src/lib/1/date-util';
import { FirebaseManager } from 'src/lib/3/firebase-manager';
import { LogConsole, LogDoc } from 'src/lib/3/schema-log';

import TableOptions from '../Common/TableOptions/TableOptions';
import ConsoleLogTable from './ConsoleLogTable/ConsoleLogTable';

const defaultDates = getDefaultDates();

const firebaseManager = FirebaseManager.getInstance();
const logConsolePath = 'logConsole';

const ConsoleLog: FC = () => {
  const [rowData, setRowData] = useState<LogDoc<LogConsole>[]>([]);
  const [dates, setDates] = useState<RangeEventValue>(null);
  const [leftDate, rightDate] = useMemo(() => {
    return dates?.[0] && dates?.[1]
      ? dayjsesToDateWhere(dates[0], dates[1])
      : dayjsesToDateWhere(defaultDates[0], defaultDates[1]);
  }, [dates]);

  useEffect(() => {
    const subscription = firebaseManager
      .observeCollection<LogDoc<LogConsole>>(logConsolePath, [], {
        sortKey: '_timeCreate',
        orderBy: 'desc',
        startValue: leftDate,
        endValue: rightDate,
      })
      .subscribe((consoleLogs) => {
        setRowData(consoleLogs);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [leftDate, rightDate]);

  return (
    <div className='height100 flexColumn'>
      <TableOptions setDates={setDates} defaultValue={defaultDates} />
      <ConsoleLogTable rowData={rowData} />
    </div>
  );
};

export default ConsoleLog;
