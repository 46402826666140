import { LoadingOutlined } from '@ant-design/icons';
import { Button, Progress, notification } from 'antd';
import { ChangeEvent, FC, ImgHTMLAttributes, useCallback, useEffect, useMemo, useState } from 'react';

import { validateImageFile } from 'src/lib/1/image-util';
import { ConsoleLogger } from 'src/lib/5/logger';

import useUploadTask from 'src/hooks/useUploadTask';

import classes from './ImageUploader.module.scss';

import ImageEditor from '../ImageEditor/ImageEditor';

type ImageUploaderProps = ImgHTMLAttributes<HTMLImageElement> & {
  /**
   * 이미지 path
   * ex) product-image/P12559
   * 앞부분에 REACT_APP_STORAGE_BUCKET_DIR에 해당하는 env값이 자동으로 붙으므로 실제로는
   * gu-files/product-image/P12559 로 저장된다.
   */
  path: string;
  size?: number;
  onDeleteImage?: () => Promise<void>;
};

const logger = ConsoleLogger.getInstance();

const ImageUploader: FC<ImageUploaderProps> = ({ path, size = 60, onDeleteImage, ...props }) => {
  const { uploadFile, initUploadTask, uploadStatus, percent, error } = useUploadTask(path);
  const [file, setFile] = useState<File>();
  const imageUrl = useMemo(() => (file ? URL.createObjectURL(file) : undefined), [file]);

  /** input 이벤트로 얻은 파일을 처리한다. */
  const onInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    // 기본 동작(open link)을 무시한다.
    e.preventDefault();
    if (e.target.files) {
      const fileList = validateImageFile(e.target.files);
      // mutiple 속성이 없으므로 첫번째 파일만 사용한다.
      setFile(fileList[0]);
    }
  }, []);

  const onDeleteInputFile = useCallback(() => {
    if (imageUrl) {
      URL.revokeObjectURL(imageUrl);
      setFile(undefined);
    }
  }, [imageUrl]);

  const onUploadInputFile = useCallback(async () => {
    if (file) {
      const result = await uploadFile(file);
      if (result) {
        setFile(undefined);
        notification.success({
          message: '이미지 업로드 성공',
        });
      } else {
        logger.logConsole('이미지 업로드 실패', {
          level: 'error',
        });
      }
    }
  }, [file, uploadFile]);

  useEffect(() => {
    if (error) {
      notification.error({
        message: '이미지 업로드 실패',
        description: error,
      });
    }
  }, [error]);

  // 컴포넌트가 언마운트 되면 revokeObjectURL을 호출하여 메모리 누수를 방지한다.
  useEffect(() => {
    return () => {
      if (imageUrl) {
        URL.revokeObjectURL(imageUrl);
      }
    };
  }, [imageUrl]);

  useEffect(() => {
    initUploadTask();
  }, [initUploadTask, props.src]);

  return (
    <div className={classes.imageUploaderContainer} style={{ width: size, height: size }}>
      {uploadStatus === 'uploading' && (
        <div className={classes.uploadStatusBox}>
          <Progress type='circle' percent={percent} size={size * 0.7} />
        </div>
      )}
      {uploadStatus === 'error' && (
        <div className={classes.uploadStatusBox}>
          <Button size='small' onClick={initUploadTask} danger>
            Error
          </Button>
        </div>
      )}
      {uploadStatus === 'success' && (
        <div className={classes.uploadStatusBox}>
          <LoadingOutlined style={{ color: 'var(--blue400)' }} />
        </div>
      )}
      <ImageEditor
        imageUrl={imageUrl}
        onInputChange={onInputChange}
        onDeleteInputFile={onDeleteInputFile}
        onUploadInputFile={onUploadInputFile}
        onDeleteImage={onDeleteImage}
        {...props}
      />
    </div>
  );
};

export default ImageUploader;
