import { ProductCompetitorTrendKeywordMap } from '@gooduncles/gu-app-schema';
import { useEffect, useState } from 'react';

import { observeProductCompetitorTrendKeywordMap } from 'src/lib/4/firebase-short-cut';

const useCompetitorTrendKeywordMap = () => {
  const [productCompetitorTrendKeywordMap, setProductCompetitorTrendKeywordMap] =
    useState<ProductCompetitorTrendKeywordMap>();

  useEffect(() => {
    const subscription = observeProductCompetitorTrendKeywordMap().subscribe(setProductCompetitorTrendKeywordMap);
    return () => subscription.unsubscribe();
  }, []);

  return productCompetitorTrendKeywordMap;
};

export default useCompetitorTrendKeywordMap;
