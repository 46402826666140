import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { InstallmentBill } from 'src/lib/3/schema-bill';

import { BillState } from '../models/billModel';
import { RootState } from '../store';

const initialState: BillState = {
  bills: {},
};

export const billSlice = createSlice({
  name: 'bill',
  initialState,
  reducers: {
    clearBills: () => initialState,
    setBills: (state, action: PayloadAction<InstallmentBill[]>) => {
      const bills = action.payload;
      const newBills = Object.fromEntries(bills.map((bill) => [bill.storeCode, bill]));
      state.bills = newBills;
    },
    setBillWithId: (state, action: PayloadAction<{ bill: InstallmentBill; storeCode: string }>) => {
      const { bill, storeCode } = action.payload;
      state.bills[storeCode] = bill;
    },
  },
});

export const selectBills = (state: RootState) => state.bill.bills;

export const { clearBills, setBills, setBillWithId } = billSlice.actions;

export default billSlice.reducer;
