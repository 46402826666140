import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { ProductBinLocationDoc, ProductDoc } from '@gooduncles/gu-app-schema';
import { Button, DatePicker, Popconfirm, Tabs, message, notification } from 'antd';
import dayjs from 'dayjs';
import { groupBy } from 'lodash-es';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { combineLatest } from 'rxjs';
import { ProductRequestDoc } from 'src/schema/schema-product-request';
import { calcProductRequestVolume, compareBinLocation } from 'src/utils/product-request-util';

import { LocalStorageKey } from 'src/lib/1/constant';
import { formatDate, getDefaultDeliveryDate, getOrderDate } from 'src/lib/1/date-util';
import { errorObjectToString, removeLocalStorageValue } from 'src/lib/1/util';
import { FirebaseManager } from 'src/lib/3/firebase-manager';
import { SupplierDoc } from 'src/lib/3/schema-supplier';
import {
  observeDailyProductSync,
  observeProduct,
  observeProductRequest,
  observeSupplier,
  updateProduct,
} from 'src/lib/4/firebase-short-cut';

import useProductBinLocation from 'src/hooks/useProductBinLocation';
import { useTitle } from 'src/hooks/useTitle';

import classes from './ProductRequestDashboard.module.scss';

import ProductRequestTableForCost, {
  ProductRequestTableHandler,
} from './ProductRequestTableForCost/ProductRequestTableForCost';
import ProductRequestTableForPrint from './ProductRequestTableForPrint/ProductRequestTableForPrint';

const firebaseManager = FirebaseManager.getInstance();
const defaultValue = getDefaultDeliveryDate();

export type ProductRequestTableRowData = {
  product: ProductDoc;
  requests: ProductRequestDoc[];
  totalRequestVolume: number;
  totalOutboundVolume: number;
  totalPurchaseVolume: number;
  supplierName: string | undefined;
  supplierLabel: string | undefined;
  newCost: number | undefined;
  newPrice: number | undefined;
  binLocation: string | null;
};

const mergeWithBinLocation = (
  productRequestList: Omit<ProductRequestTableRowData, 'binLocation'>[],
  binLocation?: ProductBinLocationDoc
): ProductRequestTableRowData[] => {
  const productsWithLocation = productRequestList.map((request) => ({ ...request, binLocation: null }));
  if (!binLocation) return productsWithLocation;
  const { productBinLocation } = binLocation;
  return (
    productsWithLocation
      // 상품 재고 위치를 맵핑한다.
      .map((request) => {
        const binLocation: string | null = productBinLocation[request.product._id] ?? request.binLocation;
        return { ...request, binLocation };
      })
      // 4순위 - 매입처명
      .sort((a, b) => a.supplierName?.localeCompare(b.supplierName || '') ?? 0)
      // 3순위 - 상품명
      .sort((a, b) => a.product.fullName.localeCompare(b.product.fullName))
      // 2순위 - '창고 위치' (알파벳 오름차순 > 숫자 오름차순)
      .sort((a, b) => compareBinLocation(a.binLocation, b.binLocation))
      // 1순위 - '구분'
      .sort((a, b) => a.supplierLabel?.localeCompare(b.supplierLabel || '') ?? 0)
  );
};

const ProductRequestDashboard: FC = () => {
  useTitle('GU 관리자 | 발주 관리');
  const productBinLocation = useProductBinLocation();
  const [date, setDate] = useState<dayjs.Dayjs | null>(defaultValue);
  const disableAddADay = useMemo(() => (date?.unix() ? date?.unix() >= Date.now() / 1000 : false), [date]);
  const dateForWhere = useMemo(() => formatDate(date ? date?.toDate() : new Date(), 'yyyy-MM-dd'), [date]);
  const [existedDailyProductSync, setExistedDailyProductSync] = useState<boolean | null>(null);

  const tableRef = useRef<ProductRequestTableHandler>(null);
  const [productRequestList, setProductRequestList] = useState<Omit<ProductRequestTableRowData, 'binLocation'>[]>([]);
  const rowData = useMemo(
    () => mergeWithBinLocation(productRequestList, productBinLocation),
    [productBinLocation, productRequestList]
  );
  const [selectedTab, setSelectedTab] = useState<string>('preview');
  const [selectedRows, setSelectedRows] = useState<ProductRequestTableRowData[]>([]);
  const [suppliers, setSuppliers] = useState<SupplierDoc[]>([]);
  const [products, setProducts] = useState<ProductDoc[]>([]);

  const addADay = () => setDate((prev) => (prev ? prev.add(1, 'day') : null));
  const subADay = () => setDate((prev) => (prev ? prev.subtract(1, 'day') : null));

  const updateProductSync = useCallback(async () => {
    if (selectedRows.length === 0) {
      message.error('신규 가격을 반영할 상품을 선택해주세요.');
      return;
    }
    try {
      const productsForSync = selectedRows.map((row) => {
        const cost = row.newCost
          ? {
              cost: row.newCost,
              costUpdatedAt: getOrderDate(),
            }
          : {};
        const price = row.newPrice
          ? {
              price: row.newPrice,
            }
          : {};
        return [
          row.product._id,
          {
            ...cost,
            ...price,
          },
        ] as [string, Partial<ProductDoc>];
      });

      firebaseManager.batchStart();
      for (const [productId, data] of productsForSync) {
        await updateProduct(productId, data, true);
      }
      await firebaseManager.batchEnd();
      notification.success({
        message: '신규 가격 반영 성공',
        description: `${selectedRows.length}건의 신규 가격이 반영되었습니다.`,
      });
      setSelectedRows([]);
      tableRef.current?.deselectAll();
    } catch (error) {
      console.error(error);
      const description = errorObjectToString(error);
      notification.error({
        message: '신규 가격 반영 실패',
        description,
      });
    }
  }, [selectedRows]);

  useEffect(() => {
    if (!dateForWhere || suppliers.length === 0 || products.length === 0) {
      return;
    }
    setExistedDailyProductSync(null);

    const productRequestObservable = observeProductRequest([['requiredDate', '==', dateForWhere]]);
    const dailyProductSyncObservable = observeDailyProductSync(dateForWhere);

    const subscription = combineLatest([productRequestObservable, dailyProductSyncObservable]).subscribe(
      async ([productRequests, dailyProductSync]) => {
        setExistedDailyProductSync(!!dailyProductSync);
        const productRequestGroup = groupBy(productRequests, 'productId');
        const productWithRequestList = products
          .filter((product) => productRequestGroup[product.productId])
          .map((product) => {
            const supplier = suppliers.find((supplier) => supplier._id === product?.suppliers?.[0]);
            const supplierName = supplier?.name;
            const supplierLabel = supplier?.label;
            const requests = productRequestGroup[product.productId];
            const { totalRequestVolume, totalOutboundVolume, totalPurchaseVolume } = calcProductRequestVolume(requests);

            const productSync = dailyProductSync ? dailyProductSync[product._id] : undefined;
            return {
              product,
              supplierName,
              supplierLabel,
              totalRequestVolume,
              // + (발주 -> 출고값), - (출고 -> 발주값)
              totalOutboundVolume,
              // - (발주 -> 출고값), + (출고 -> 발주값)
              totalPurchaseVolume,
              requests,
              newCost: productSync?.cost,
              newPrice: productSync?.price,
            };
          });
        setProductRequestList(productWithRequestList);
      }
    );
    return () => subscription.unsubscribe();
  }, [dateForWhere, products, suppliers]);

  useEffect(() => {
    const productsSubscription = observeProduct().subscribe(setProducts);
    const suppliersSubscription = observeSupplier([['isDeleted', '==', false]]).subscribe(setSuppliers);

    removeLocalStorageValue(LocalStorageKey.productRequestTalbeFilterModel);
    removeLocalStorageValue(LocalStorageKey.productRequestTalbeColumnState);
    return () => {
      productsSubscription.unsubscribe();
      suppliersSubscription.unsubscribe();
    };
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.buttonToolbar}>
        <div className={classes.buttons}>
          <Button icon={<LeftOutlined />} onClick={subADay} />
          <DatePicker onChange={setDate} defaultValue={defaultValue} value={date} />
          <Button icon={<RightOutlined />} onClick={addADay} disabled={disableAddADay} />
        </div>
        <Popconfirm title={`신규 매입가, 판매가를 현재 매입가 판매가에 적용합니다.`} onConfirm={updateProductSync}>
          <Button disabled={selectedRows.length === 0 || selectedTab !== 'preview'}>
            {selectedRows.length}건의 신규 가격 반영
          </Button>
        </Popconfirm>
      </div>
      <Tabs
        type='card'
        style={{ height: '100%', width: '100%', overflow: 'scroll' }}
        tabBarStyle={{ marginBottom: 0 }}
        activeKey={selectedTab}
        onChange={(e) => setSelectedTab(e)}
        items={[
          {
            key: 'preview',
            label: '관리전용',
            children: (
              <ProductRequestTableForCost
                ref={tableRef}
                products={products}
                rowData={rowData}
                date={dateForWhere}
                existedDailyProductSync={existedDailyProductSync}
                setSelectedRows={setSelectedRows}
              />
            ),
          },
          {
            key: 'print',
            label: '프린트',
            children: <ProductRequestTableForPrint rowData={rowData} />,
          },
        ]}
      />
    </div>
  );
};

export default ProductRequestDashboard;
