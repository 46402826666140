import { Select, message } from 'antd';
import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { parseIfObject } from 'src/lib/1/util';

interface SelectCellEditorProps {
  placeholder: string;
  defaultValue?: string;
  minWidth?: number;
  onValueChange: (value: string) => Promise<string | void>;
  stopEditing: () => void;
  options: {
    label: string;
    value: string;
  }[];
  size?: 'small' | 'middle' | 'large';
}

const SelectCellEditor: FC<SelectCellEditorProps> = ({
  placeholder,
  defaultValue,
  stopEditing,
  options,
  onValueChange,
  minWidth = 200,
  size = 'large',
}) => {
  const selectRef = useRef<any>(null);
  const [loading, setLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState<string | undefined>();

  const handleOnValueChange = useCallback(
    async (value: string) => {
      setSelectedValue(value);
      if (value) {
        setLoading(true);
        try {
          await onValueChange(value);
          message.success('변경 완료');
          setTimeout(() => {
            stopEditing();
          }, 10);
        } catch (error) {
          console.error(error);
          message.error(parseIfObject(error));
        }
        setLoading(false);
      }
    },
    [onValueChange, stopEditing]
  );

  useEffect(() => {
    if (selectRef?.current) {
      selectRef.current.focus();
    }
  }, [selectRef]);

  return (
    <Select
      showSearch
      loading={loading}
      ref={selectRef}
      defaultValue={defaultValue}
      placeholder={placeholder}
      style={{ minWidth }}
      size={size}
      value={selectedValue}
      onChange={handleOnValueChange}
      filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
      options={options}
    />
  );
};

export default SelectCellEditor;
