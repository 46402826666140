import { productPackings } from 'src/lib/1/constant';

import useProductById from 'src/hooks/useProductById';

const ProductPackingRenderer = (params: any) => {
  const id = params.value;
  const { product } = useProductById(id);
  const packing = product?.packing ? productPackings[product?.packing] : '';
  return <span>{packing}</span>;
};

export default ProductPackingRenderer;
