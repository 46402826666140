import { Product, ProductMeasure } from '@gooduncles/gu-app-schema';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { ColDef, ColGroupDef } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { FC, useCallback, useMemo, useState } from 'react';

import { formatNumber } from 'src/lib/1/util';
import { arrayValueFormatter } from 'src/lib/6/ag-grid-util';

import ProductImg from 'src/components/Common/ProductImg/ProductImg';

import classes from './RawProductTable.module.scss';

import {
  ProductCategoryCellRenderer,
  ProductPackingCellRenderer,
  ProductStateCellRenderer,
  ProductStoragesCellRenderer,
} from '../../Common/ProductCellRenderer/ProductCellRenderer';
import TableHeader from '../TableHeader/TableHeader';

interface NewProductTableProps {
  rowData: Partial<Product>[];
  buttons: React.ReactNode;
}

const defaultColDef: ColDef<Partial<Product>> = {
  flex: 1,
  sortable: true,
  resizable: true,
  filter: true,
};

/**
 * 현재는 상품의 가격 데이터를 엑셀에서 관리하며,
 * 하루 한 번 가격 데이터를 서버에 업로드하는 방식으로 데이터를 동기화하고 있다.
 * 현 운영 시스템을 통합하게 되면 이 테이블은 더 이상 필요하지 않을 것이다.
 */
const RawProductTable: FC<NewProductTableProps> = ({ rowData, buttons }) => {
  const [gridRef, setGridRef] = useState<AgGridReact<Partial<Product>> | null>(null);

  const columnDefs: (ColDef<Partial<Product>> | ColGroupDef<Partial<Product>>)[] = useMemo(
    () => [
      { headerName: '품목코드', field: 'productId', minWidth: 96 },
      { headerName: '이미지', field: 'imageUrl', minWidth: 72, cellRenderer: ProductImg, hide: !rowData[0].imageUrl },
      {
        headerName: '품명',
        field: 'fullName',
        minWidth: 240,
        hide: rowData[0].fullName === undefined,
      },
      {
        hide: true,
        headerName: '품명1',
        field: 'name',
        minWidth: 120,
      },
      {
        headerName: '구분',
        field: 'categories',
        minWidth: 108,
        hide: rowData[0].categories === undefined,
        cellRenderer: ProductCategoryCellRenderer,
      },
      {
        headerName: '과세/면세',
        field: 'taxFree',
        minWidth: 80,
        hide: rowData[0].taxFree === undefined,
        valueFormatter: ({ value }) => (value ? '면세' : '과세'),
      },
      {
        headerName: '판매가',
        field: 'price',
        minWidth: 120,
        hide: rowData[0].price === undefined,
        valueFormatter: ({ value }) => formatNumber(value),
      },
      {
        headerName: '단위가격',
        field: 'unitPrice',
        minWidth: 120,
        hide: rowData[0].unitPrice === undefined,
        valueFormatter: ({ value }) => {
          const unitPrice = value as Product['unitPrice'];
          if (!unitPrice) return '';
          return formatNumber(unitPrice.price) + ` / ${unitPrice.amount}${unitPrice.unit}`;
        },
      },
      {
        headerName: '매입가',
        field: 'cost',
        minWidth: 120,
        hide: rowData[0].cost === undefined,
        valueFormatter: ({ value }) => formatNumber(value),
      },
      {
        headerName: '재고',
        field: 'state',
        minWidth: 120,
        hide: rowData[0].state === undefined,
        cellRenderer: ProductStateCellRenderer,
      },
      {
        headerName: '숨김',
        field: 'hidden',
        minWidth: 96,
        hide: rowData[0].hidden === undefined,
        valueFormatter: ({ value }) => (value ? '숨김' : '보임'),
      },
      {
        headerName: '가격알림',
        field: 'dailyNotification',
        minWidth: 96,
        hide: rowData[0].dailyNotification === undefined,
        valueFormatter: ({ value }) => (value ? '🟢' : '🔴'),
      },
      {
        headerName: '보관',
        field: 'storage',
        minWidth: 96,
        hide: rowData[0].storage === undefined,
        cellRenderer: ProductStoragesCellRenderer,
      },
      {
        headerName: '패킹',
        field: 'packing',
        minWidth: 96,
        hide: rowData[0].packing === undefined,
        cellRenderer: ProductPackingCellRenderer,
      },
      {
        headerName: '매입처',
        field: 'suppliers',
        minWidth: 100,
        hide: rowData[0].suppliers === undefined,
        valueFormatter: arrayValueFormatter,
      },
      {
        headerName: '원산지',
        field: 'origin',
        minWidth: 88,
        hide: rowData[0].origin === undefined,
      },
      {
        headerName: '특징',
        field: 'features',
        minWidth: 96,
        hide: rowData[0].features === undefined,
        valueFormatter: arrayValueFormatter,
      },
      {
        headerName: '단위',
        field: 'measure',
        minWidth: 120,
        hide: rowData[0].measure === undefined,
        valueFormatter: ({ value }) => {
          const measure = value as ProductMeasure[];
          return measure ? measure.map((m) => `${m.amount}${m.unit}`).join('*') : '';
        },
      },
      {
        headerName: '대표태그',
        field: 'mainTag',
        hide: rowData[0].mainTag === undefined,
        minWidth: 108,
      },
      {
        headerName: '태그',
        field: 'tags',
        minWidth: 96,
        hide: rowData[0].tags === undefined,
        valueFormatter: arrayValueFormatter,
      },
      {
        headerName: '배지',
        field: 'badge',
        minWidth: 96,
        hide: rowData[0].badge === undefined,
        valueFormatter: arrayValueFormatter,
      },
      {
        headerName: '이미지설명',
        field: 'imageDescription',
        hide: rowData[0].imageDescription === undefined,
        minWidth: 116,
      },
      {
        headerName: '패킹정보',
        field: 'packingInfo',
        hide: rowData[0].packingInfo === undefined,
        minWidth: 116,
      },
    ],
    [rowData]
  );

  const autoSizeAll = useCallback(() => {
    gridRef?.columnApi?.autoSizeAllColumns(true);
  }, [gridRef]);

  return (
    <>
      <TableHeader justifyContent='space-between'>{buttons}</TableHeader>
      <div className={`ag-theme-alpine ${classes.tableContainer}`}>
        <AgGridReact
          ref={setGridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          gridOptions={{ rowHeight: 50 }}
          onGridReady={autoSizeAll}
          // 편집 완료후 스크롤 이동을 막는다.
          suppressScrollOnNewData={true}
          alwaysShowHorizontalScroll={true}
        />
      </div>
    </>
  );
};

export default RawProductTable;
