import {
  ProductPacking,
  ProductState,
  ProductStorage,
  productCategories,
  productPackings,
  productStates,
  productStorages,
} from 'src/lib/1/constant';

export const ProductStateCellRenderer = (props: any) => (
  <span className={props.value}>{productStates[props.value as ProductState]}</span>
);

export const ProductCategoryCellRenderer = (props: any) => {
  if (!props.value) return null;
  if (typeof props.value === 'string') {
    return <span>{productCategories[props.value]}</span>;
  } else {
    return (
      <span>
        {(props.value as string[])
          .filter((cg) => cg !== 'all')
          .map((category: string) => productCategories[category])
          .join(', ')}
      </span>
    );
  }
};

export const ProductPackingCellRenderer = (props: any) => productPackings[props.value as ProductPacking];
export const ProductStoragesCellRenderer = (props: any) => (
  <span className={props.value}>{productStorages[props.value as ProductStorage]}</span>
);
