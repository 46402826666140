import { FC } from 'react';
import { LoadingOutlined } from '@ant-design/icons';

import classes from './Loading.module.scss';

const Loading: FC<{ title: string }> = ({ title }) => {
  return (
    <div className={classes.loadingContainer}>
      <h3>{title}</h3>
      <LoadingOutlined className={classes.loadingIcon}/>
    </div>
  )
}

export default Loading;
