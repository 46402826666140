import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { ColDef, ColGroupDef } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { FC, useMemo, useState } from 'react';

import { RangeEventValue, dayjsesToDateWhere, getDefaultDates } from 'src/lib/1/date-util';
import { LogCommerce, LogDoc } from 'src/lib/3/schema-log';
import {
  defaultValueGetter,
  orderStatusCellClass,
  timestampFormatter,
  unknownValueCellClass,
} from 'src/lib/6/ag-grid-util';

import useCommerceLogForUser from 'src/hooks/useCommerceLogForUser';

import DownloadRawDataCell from 'src/components/Common/DownloadRawDataCell/DownloadRawDataCell';
import TableOptions from 'src/components/Common/TableOptions/TableOptions';

type UserCommerceLogProps = {
  userId: string;
};

const defaultColDef: ColDef<LogDoc<LogCommerce>> = {
  flex: 1,
  sortable: true,
  resizable: true,
  filter: true,
};

const defaultValue = getDefaultDates(3);

const UserCommerceLog: FC<UserCommerceLogProps> = ({ userId }) => {
  const [dates, setDates] = useState<RangeEventValue>(null);
  const [leftDate, rightDate] = useMemo(() => {
    return dates?.[0] && dates?.[1]
      ? dayjsesToDateWhere(dates[0], dates[1])
      : dayjsesToDateWhere(defaultValue[0], defaultValue[1]);
  }, [dates]);
  const { commerceLogForUser } = useCommerceLogForUser(leftDate, rightDate, userId);

  const columnDefs: (ColDef<LogDoc<LogCommerce>> | ColGroupDef<LogDoc<LogCommerce>>)[] = useMemo(
    () => [
      { field: '_id', filter: true, hide: true },
      {
        headerName: '시각',
        field: '_timeCreate',
        filter: true,
        maxWidth: 200,
        valueFormatter: timestampFormatter,
      },
      {
        headerName: '액션',
        minWidth: 120,
        hide: true,
        cellRenderer: DownloadRawDataCell,
      },
      {
        field: 'level',
        filter: true,
        maxWidth: 88,
        hide: true,
      },
      {
        headerName: '내용',
        field: 'message',
        filter: true,
        cellClass: orderStatusCellClass,
      },
      {
        headerName: 'session',
        field: 'sessionId',
        filter: true,
        maxWidth: 340,
        enableRowGroup: true,
        valueGetter: defaultValueGetter,
        cellClass: unknownValueCellClass,
      },
      {
        headerName: 'deviceId',
        field: 'body.deviceId',
        maxWidth: 160,
        filter: true,
        enableRowGroup: true,
        valueGetter: defaultValueGetter,
        cellClass: unknownValueCellClass,
      },
      {
        headerName: 'ip',
        field: 'publicIp',
        maxWidth: 160,
        filter: true,
        enableRowGroup: true,
        valueGetter: defaultValueGetter,
        cellClass: unknownValueCellClass,
      },
      {
        headerName: '주문번호',
        field: 'body.orderId',
        maxWidth: 220,
        filter: true,
        enableRowGroup: true,
        valueGetter: defaultValueGetter,
        cellClass: unknownValueCellClass,
      },
    ],
    []
  );

  return (
    <div className='ag-theme-alpine' style={{ height: '100%' }}>
      <div
        style={{
          padding: '6px 8px',
          border: 'solid 1px var(--gray400)',
          borderBottom: 'none',
          borderTop: 'none',
        }}>
        <TableOptions setDates={setDates} defaultValue={defaultValue} marginBottom={0} />
      </div>
      <AgGridReact
        rowData={commerceLogForUser}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        suppressScrollOnNewData={true}
        alwaysShowHorizontalScroll={true}
        detailRowAutoHeight={true}
      />
    </div>
  );
};

export default UserCommerceLog;
