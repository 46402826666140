import { CloseOutlined } from '@ant-design/icons';
import { DeliverySpotDoc } from '@gooduncles/gu-app-schema';
import { Button, InputNumber, Select, notification } from 'antd';
import { FC, useCallback, useState } from 'react';

import { updateDeliverySpot } from 'src/lib/4/firebase-short-cut';

import classes from './DeliverySpotMapModal.module.scss';

interface DeliverySpotMapModalProps {
  deliverySpot: DeliverySpotDoc | null;
  partnerList: { value: string; label: string }[];
  closeModal: () => void;
}

const DeliverySpotMapModal: FC<DeliverySpotMapModalProps> = ({ deliverySpot, partnerList, closeModal }) => {
  const [loading, setLoading] = useState(false);
  const [newSortKey, setNewSortKey] = useState<number | null>(deliverySpot?.sortKey ?? null);

  const onChangePartner = useCallback(
    async (partnerId: string) => {
      if (!deliverySpot) {
        notification.error({ message: '변경할 대상의 정보가 없습니다.' });
        return;
      }
      try {
        setLoading(true);
        await updateDeliverySpot(deliverySpot._id, { partnerId });
        notification.success({ message: '배송지 담당자를 수정했습니다.' });
      } catch (error) {
        console.error(error);
        notification.error({ message: '배송지 담당자 수정에 실패했습니다.' });
      }
      setLoading(false);
    },
    [deliverySpot]
  );

  const onChangeSortKey = useCallback(
    async (sortKey: number | null) => {
      if (!deliverySpot) {
        notification.error({ message: '변경할 대상의 정보가 없습니다.' });
        return;
      }

      if (sortKey === deliverySpot.sortKey) {
        return;
      }

      if (sortKey === null) {
        notification.error({ message: '숫자를 입력해주세요.' });
        return;
      }
      setNewSortKey(sortKey);
    },
    [deliverySpot]
  );

  const onUpdateSortKey = useCallback(async () => {
    if (!deliverySpot) {
      notification.error({ message: '변경할 대상의 정보가 없습니다.' });
      return;
    }

    if (newSortKey === null) {
      notification.error({ message: '숫자를 입력해주세요.' });
      return;
    }

    try {
      setLoading(true);
      await updateDeliverySpot(deliverySpot._id, { sortKey: newSortKey });
      notification.success({ message: '배송지 순서를 수정했습니다.' });
    } catch (error) {
      console.error(error);
      notification.error({ message: '배송지 순서 수정에 실패했습니다.' });
    }
    setLoading(false);
  }, [deliverySpot, newSortKey]);

  if (!deliverySpot) return null;
  return (
    <div className={classes.markerPopover}>
      <div className={classes.toolbar}>
        <Button
          type='text'
          icon={<CloseOutlined />}
          loading={loading}
          onClick={(e) => {
            e.stopPropagation();
            closeModal();
          }}
        />
      </div>
      <div className={classes.content}>
        <Select
          defaultValue={deliverySpot.partnerId}
          loading={loading}
          options={partnerList}
          onClick={(e) => e.stopPropagation()}
          onChange={onChangePartner}
        />
        <div className={classes.sortKeyInputBox}>
          <InputNumber defaultValue={deliverySpot.sortKey} onChange={onChangeSortKey} step='0.1' />
          <Button loading={loading} disabled={deliverySpot.sortKey === newSortKey} onClick={onUpdateSortKey}>
            순서 변경
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DeliverySpotMapModal;
