import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm, message } from 'antd';
import { Timestamp } from 'firebase/firestore';
import { FC, useCallback, useState } from 'react';

import { parseIfObject } from 'src/lib/1/util';
import { updateDocWithCollection } from 'src/lib/4/firebase-short-cut';

type DeleteDataCellProps = {
  collection: string;
  id: string;
};

const DeleteDataCell: FC<DeleteDataCellProps> = ({ collection, id }) => {
  const [loading, setLoading] = useState(false);

  const handleOnClick = useCallback(async () => {
    setLoading(true);
    try {
      await updateDocWithCollection(collection, id, {
        isDeleted: true,
        _timeDelete: Timestamp.now(),
      });
      message.success('삭제 완료');
    } catch (error) {
      console.error(error);
      message.error(parseIfObject(error));
    }
    setLoading(false);
  }, [collection, id]);

  return (
    <Popconfirm
      placement='bottomRight'
      title='삭제하시겠습니까?'
      onConfirm={handleOnClick}
      okText='삭제'
      cancelText='취소'
      okButtonProps={{ loading, danger: true }}>
      <Button
        size='small'
        type='primary'
        danger
        loading={loading}
        style={{ fontSize: 12, marginLeft: 4 }}
        icon={<DeleteOutlined />}>
        삭제
      </Button>
    </Popconfirm>
  );
};

export default DeleteDataCell;
