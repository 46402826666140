import { StoreDoc } from '@gooduncles/gu-app-schema';
import { DeliverySpotDoc } from '@gooduncles/gu-app-schema';
import { Select, notification } from 'antd';
import { FC } from 'react';

import { errorObjectToString } from 'src/lib/1/util';
import { updateStore } from 'src/lib/4/firebase-short-cut';

import DeliverySpotModal from '../DeliverySpotModal/DeliverySpotModal';

interface DeliverySpotSelectorProps {
  store: StoreDoc;
  storeDeliverySpotId: string | null;
  spots: DeliverySpotDoc[];
}

const DeliverySpotSelector: FC<DeliverySpotSelectorProps> = ({ store, storeDeliverySpotId, spots }) => {
  const options = spots
    .map((spot) => ({
      label: spot.title,
      value: spot._id,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const onChange = async (value: string) => {
    try {
      await updateStore(store._id, {
        deliverySpotId: value,
      });
      notification.success({
        message: '배송지점이 수정되었습니다.',
      });
    } catch (error) {
      const errorMessage = errorObjectToString(error);
      notification.error({
        message: '배송지점을 수정하는 중에 오류가 발생했습니다.',
        description: errorMessage,
      });
    }
  };

  return (
    <div>
      <Select
        allowClear
        showSearch
        value={storeDeliverySpotId}
        placeholder='배송지점을 선택해주세요.'
        options={options}
        onChange={onChange}
        style={{ minWidth: 160 }}
        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
      />
      <DeliverySpotModal store={store} />
    </div>
  );
};

export default DeliverySpotSelector;
