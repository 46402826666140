import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { useTitle } from '../hooks/useTitle';

import NotificationDashboard from 'src/components/Notification/NotificationDashboard/NotificationDashboard';
import NotificationList from 'src/components/Notification/NotificationList/NotificationList';

const NotificationPage: FC<{ title: string }> = ({ title }) => {
  useTitle(`GU 관리자 | ${title}`);
  return (
    <Routes>
      <Route path='/dashboard' element={<NotificationDashboard />} />
      <Route path='/list' element={<NotificationList />} />
      <Route path='/' element={<Navigate to='/notification/dashboard' replace />} />
    </Routes>
  );
};

export default NotificationPage;
