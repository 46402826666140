import { Drawer } from 'antd';
import { FC, useEffect, useState } from 'react';

import { DaumPostcodeResponse } from '../../../lib/1/schema-daum-postcode-api';

const daum = (window as any).daum;

interface DaumPostcodeModalProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onComplete: (data: DaumPostcodeResponse) => void;
}

const DaumPostcodeModal: FC<DaumPostcodeModalProps> = ({ visible, setVisible, onComplete }) => {
  const [searchKeyword, setSearchKeyword] = useState('');
  const [modalRef, setModalRef] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (daum && modalRef && visible) {
      const postcode = new daum.Postcode({
        onsearch: (data: { q: string; count: number }) => {
          setSearchKeyword(data.q);
        },
        oncomplete: async (data: DaumPostcodeResponse) => {
          onComplete(data);
          setVisible(false);
        },
        width: '100%',
        height: '100%',
        autoClose: false,
      });

      postcode.embed(modalRef, searchKeyword ? { q: searchKeyword } : undefined);
    }

    // searchKeyword 변경에 따른 초기화를 막기 위함.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalRef, onComplete, setVisible, visible]);

  return (
    <Drawer
      title='주소검색'
      open={visible}
      placement='right'
      width='400px'
      bodyStyle={{ padding: 0 }}
      keyboard={true}
      onClose={() => setVisible(false)}>
      <div ref={setModalRef} style={{ height: '100%' }} />
    </Drawer>
  );
};

export default DaumPostcodeModal;
