import { Layout, Menu, MenuProps } from 'antd';
import { FC, useCallback, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from 'src/stores/auth-context';

import { isProduction } from 'src/lib/1/constant';

import logoWhite from 'src/assets/icon/ic-gooduncles-white.svg';

import classes from './MainNavigation.module.scss';

import { siteMap } from '../../Router';

const { Sider } = Layout;

/** xs: '480px', sm: '576px', md: '768px', lg: '992px', xl: '1200px', xxl: '1600px', */
const breakpoint = 'md';

type MenuItem = Required<MenuProps>['items'][number];

const MainNavigation: FC = () => {
  const { user } = useAuth();
  const [collapsed, setCollapsed] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const items: MenuItem[] = useMemo(() => {
    if (user?.role === undefined) return [];

    return Object.values(siteMap)
      .filter((page) => page.icon !== undefined && (page.role === undefined || page.role.includes(user.role)))
      .map(({ title, icon, children, key }) => ({
        label: title,
        icon,
        key,
        children: children?.filter((page) => page.role === undefined || page.role.includes(user.role)),
        className: classes.menuItem,
      }));
  }, [user?.role]);

  const onClickMenu: MenuProps['onClick'] = useCallback(
    (e: { key: string }) => {
      navigate(e.key);
    },
    [navigate]
  );

  return (
    <Sider
      theme='light'
      breakpoint={breakpoint}
      collapsible
      collapsed={collapsed}
      onCollapse={setCollapsed}
      style={{ overflow: 'scroll' }}>
      <Link className={`${classes.logo} ${isProduction ? '' : classes.dev}`} to='/'>
        {collapsed ? <img alt='logo' src={logoWhite} /> : <span>Gooduncles</span>}
      </Link>
      <Menu mode='inline' selectedKeys={[pathname]} items={items} onClick={onClickMenu}></Menu>
    </Sider>
  );
};

export default MainNavigation;
