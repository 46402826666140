import { DeleteOutlined } from '@ant-design/icons';
import { OrderProduct, ProductStateCode } from '@gooduncles/gu-app-schema';
import { Button, InputNumber } from 'antd';
import { FC, useEffect, useState } from 'react';

import { formatNumber } from 'src/lib/1/util';

import classes from './OrderProductItem.module.scss';

interface OrderProductItemProps {
  orderProduct: OrderProduct;
  onVolumeChange: (productId: string, volume: number) => void;
  onRemove: (productId: string) => void;
  style?: React.CSSProperties;
}

const OrderProductItem: FC<OrderProductItemProps> = ({ orderProduct, onVolumeChange, onRemove, style }) => {
  const [volume, setVolume] = useState<number>(orderProduct.volume);

  const handleVolumeChange = (value: number | string | null) => {
    const numberValue = Number(value);
    setVolume(numberValue);
    onVolumeChange(orderProduct.productId, numberValue);
  };

  useEffect(() => {
    setVolume(orderProduct.volume);
  }, [orderProduct.volume]);

  return (
    <div className={classes.orderProductItem} style={style}>
      <div className={classes.row}>
        <div className={`${classes.name} ${orderProduct.state !== ProductStateCode.STOCK ? classes.warn : ''}`}>
          {orderProduct.fullName}
        </div>
        <Button
          size='small'
          type='text'
          danger
          icon={<DeleteOutlined />}
          onClick={() => onRemove(orderProduct.productId)}
        />
      </div>
      <div className={classes.row}>
        <div className={classes.price}>{formatNumber(orderProduct.price)}</div>
        <InputNumber onChange={handleVolumeChange} value={volume} min={0.1} />
      </div>
    </div>
  );
};

export default OrderProductItem;
