import {
  Order,
  OrderDoc,
  OrderProduct,
  OrderStatusCode,
  ProductDoc,
  ProductStateCode,
  StoreDoc,
} from '@gooduncles/gu-app-schema';
import { message, notification } from 'antd';
import { useCallback, useState } from 'react';
import { convertOrderProductsToTaskItems, getTasksForOrder } from 'src/utils/task-util';

import { orderStatusEn } from 'src/lib/1/constant';
import { formatNumber, parseIfObject } from 'src/lib/1/util';
import { FirebaseManager } from 'src/lib/3/firebase-manager';
import { getDeliveryRouteHistory, updateDeliveryTask, updatePickupTask } from 'src/lib/4/firebase-short-cut';
import { ConsoleLogger } from 'src/lib/5/logger';
import { createOrderData, createOrderDataForModify, isTaskAssignedAceeptedOrder } from 'src/lib/5/order-util';

const logger = ConsoleLogger.getInstance();
const firebaseManager = FirebaseManager.getInstance();
const orderPath = 'order';
const productPath = 'product';

const createOrderDoc = async (docData: Order) => {
  // 상품수량 및 상태체크
  try {
    const promises = docData.products.map((p) => firebaseManager.getDoc<ProductDoc>(`${productPath}/${p.productId}`));
    const products = await Promise.all(promises);
    const soldOutProducts = products.filter((p) => p?.state !== ProductStateCode.STOCK);
    if (soldOutProducts.length > 0) {
      throw new Error(`구매 불가능한 상품이 있습니다. ${soldOutProducts.map((p) => p?.fullName).join(', ')}`);
    }
  } catch (error: any) {
    throw new Error(error.message ?? '알 수 없는 에러발생!');
  }
  return firebaseManager.createDoc(orderPath, undefined, docData);
};
const updateOrderDoc = async (orderId: string, docData: Partial<Order>) =>
  firebaseManager.updateDoc(orderPath, orderId, docData);
const cancelOrderDoc = async (orderId: string) =>
  firebaseManager.updateDoc(orderPath, orderId, { orderStatus: OrderStatusCode.CANCELED });

const useOrder = () => {
  const [isLoading, setIsLoading] = useState(false);

  const onCreateOrder = useCallback(async (storeManagerId: string, store: StoreDoc, products: OrderProduct[]) => {
    setIsLoading(true);
    try {
      // 1. 주문을 생성합니다.
      const orderData = await createOrderData(storeManagerId, store, products);
      const orderId = await createOrderDoc(orderData);
      logger.logConsole(
        `주문생성(${orderStatusEn[orderData.orderStatus]}): ${orderId}, 업소명: ${store.storeNickname}, 주문금액: ${
          orderData.grandTotal
        }`
      );
      notification.success({
        message: '주문을 생성했습니다.',
        description: `총 금액: ${formatNumber(orderData.grandTotal)}`,
      });
      // 2. 이미 배송 동선이 생성된 상태라면 경고 메시지를 띄웁니다.
      if (orderData.date) {
        const deliveryRouteHistory = await getDeliveryRouteHistory(orderData.date);
        if (deliveryRouteHistory) {
          notification.warning({
            message: '이미 생성된 배송 동선이 있습니다.',
            description: '새로 생성한 주문을 파트너스에게 배정하려면 배송 동선 확정을 다시 해야합니다.',
            duration: 0,
          });
        }
      }

      setIsLoading(false);
      return true;
    } catch (error: any) {
      logger.logConsole(parseIfObject(error), {
        level: 'error',
      });
      notification.error({
        message: '주문 생성 실패',
        description: error.message ?? '알 수 없는 에러발생!',
      });
    }
    setIsLoading(false);
    return false;
  }, []);

  const onUpdateOrderDoc = useCallback(
    async (store: StoreDoc, matchedOrder: OrderDoc, orderProducts: OrderProduct[]) => {
      setIsLoading(true);
      try {
        const beforeGrandTotal = matchedOrder.grandTotal;
        const orderData = await createOrderDataForModify(store, matchedOrder, orderProducts);
        // 1. 주문을 변경합니다.
        await updateOrderDoc(matchedOrder._id, orderData);
        logger.logConsole(
          `주문수정(${orderStatusEn[matchedOrder.orderStatus]}): ${matchedOrder._id}, 업소명: ${
            store.storeNickname
          }, 주문금액: ${beforeGrandTotal} -> ${orderData.grandTotal}`,
          {
            extraData: {
              before: matchedOrder,
            },
          }
        );
        notification.success({
          message: '주문을 수정했습니다.',
          description: `총 금액: ${formatNumber(beforeGrandTotal)} -> ${formatNumber(orderData.grandTotal)}`,
        });

        // 2. 물류 업무 변경이 필요한 경우 함께 변경합니다.
        const needTaskChange = isTaskAssignedAceeptedOrder(matchedOrder);
        if (needTaskChange) {
          const { pickupTask, deliveryTask } = await getTasksForOrder(matchedOrder);
          const taskItems = convertOrderProductsToTaskItems(orderProducts);
          if (pickupTask) {
            await updatePickupTask(pickupTask._id, { pickupItems: taskItems });
            message.success('연관된 픽업 업무를 변경했습니다.');
          }
          if (deliveryTask) {
            await updateDeliveryTask(deliveryTask._id, { deliveryItems: taskItems });
            message.success('연관된 배송 업무를 변경했습니다.');
          }
        }
      } catch (error: any) {
        logger.logConsole(parseIfObject(error), {
          level: 'error',
        });
      }
      setIsLoading(false);
    },
    []
  );

  const cancelOrder = useCallback(async (orderId: string) => {
    setIsLoading(true);
    try {
      await cancelOrderDoc(orderId);
      logger.logConsole(`주문취소 - 주문번호: ${orderId}`);
    } catch (error: any) {
      logger.logConsole(parseIfObject(error), {
        level: 'error',
      });
    }
    setIsLoading(false);
  }, []);

  return {
    isLoading,
    onCreateOrder,
    onUpdateOrderDoc,
    cancelOrder,
  };
};

export default useOrder;
