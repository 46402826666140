import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, DatePicker } from 'antd';
import Checkbox, { CheckboxChangeEvent } from 'antd/es/checkbox';
import dayjs from 'dayjs';
import { FC, useEffect, useMemo, useState } from 'react';

import { sendNo } from 'src/lib/1/constant';
import { formatDate, getDefaultDate } from 'src/lib/1/date-util';
import { PurchaseOrderDoc } from 'src/lib/3/schema-purchase-order';
import { observePurchaseOrders } from 'src/lib/4/firebase-short-cut';

import { useTitle } from 'src/hooks/useTitle';

import classes from './PurchaseOrderDashboard.module.scss';

import PurchaseOrderTable from './PurchaseOrderTable/PurchaseOrderTable';
import SendSmsRequestModal from './PurchaseOrderTable/SendSmsRequestModal/SendSmsRequestModal';

const defaultValue = getDefaultDate(1);

const PurchaseOrderDashboard: FC = () => {
  useTitle('GU 관리자 | 발주 관리');
  const [rowData, setRowData] = useState<PurchaseOrderDoc[]>([]);
  const [showHeader, setShowHeader] = useState(false);
  const [date, setDate] = useState<dayjs.Dayjs | null>(defaultValue);
  const disableAddADay = useMemo(() => (date?.unix() ? date?.unix() >= Date.now() / 1000 : false), [date]);
  const dateForWhere = useMemo(() => formatDate(date ? date?.toDate() : new Date(), 'yyyy-MM-dd'), [date]);

  const [selectedRows, setSelectedRows] = useState<PurchaseOrderDoc[]>([]);
  const requests = useMemo(() => {
    return selectedRows.map((row) => ({
      body: row.messagePrefix + row.message + row.messageSuffix + '\n내용문의는 010-6236-1736으로 연락주세요.',
      sendNo,
      recipientList: (row.supplierSubTels ? [row.supplierTel, ...row.supplierSubTels] : [row.supplierTel]).map(
        (tel) => ({
          recipientNo: tel.replace(/-/g, ''),
          recipientGroupingKey: row.supplierName,
        })
      ),
    }));
  }, [selectedRows]);

  const addADay = () => setDate((prev) => (prev ? prev.add(1, 'day') : null));
  const subADay = () => setDate((prev) => (prev ? prev.subtract(1, 'day') : null));
  const onChangeShowHeader = (e: CheckboxChangeEvent) => setShowHeader(e.target.checked);

  useEffect(() => {
    if (!dateForWhere) {
      return;
    }
    const subscription = observePurchaseOrders([['date', '==', dateForWhere]]).subscribe(setRowData);
    return () => {
      subscription.unsubscribe();
    };
  }, [dateForWhere]);

  return (
    <div className={classes.dashboardContainer}>
      <div className={classes.buttonToolbar}>
        <div className={classes.buttons}>
          <Button icon={<LeftOutlined />} onClick={subADay} />
          <DatePicker onChange={setDate} defaultValue={defaultValue} value={date} />
          <Button icon={<RightOutlined />} onClick={addADay} disabled={disableAddADay} />
          <Checkbox checked={showHeader} onChange={onChangeShowHeader}>
            머릿말 표시
          </Checkbox>
        </div>
        <SendSmsRequestModal requests={requests} />
      </div>
      <PurchaseOrderTable rowData={rowData} setSelectedRows={setSelectedRows} showHeader={showHeader} />
    </div>
  );
};

export default PurchaseOrderDashboard;
