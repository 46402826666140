import { FC } from 'react';

import { useTitle } from '../../hooks/useTitle';

import ProductUpload from '../../components/ProductUpload/ProductUpload';

const ProductUploadPage: FC<{ title: string }> = ({ title }) => {
  useTitle(`GU 관리자 | ${title}`);
  return <ProductUpload />;
};

export default ProductUploadPage;
