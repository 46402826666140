import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, DatePicker } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import { FC, useMemo } from 'react';

interface DatePickerWithArrowsProps {
  date: dayjs.Dayjs | null;
  setDate: React.Dispatch<React.SetStateAction<dayjs.Dayjs | null>>;
  disabledDate?: RangePickerProps['disabledDate'];
}

const DatePickerWithArrows: FC<DatePickerWithArrowsProps> = ({ date, setDate, disabledDate }) => {
  const disableAddADay = useMemo(() => (date?.unix() ? date?.unix() >= Date.now() / 1000 : false), [date]);
  const addADay = () => setDate((prev) => (prev ? prev.add(1, 'day') : null));
  const subADay = () => setDate((prev) => (prev ? prev.subtract(1, 'day') : null));

  return (
    <div
      style={{
        display: 'flex',
        gap: 8,
      }}>
      <Button icon={<LeftOutlined />} onClick={subADay} />
      <DatePicker onChange={setDate} value={date} disabledDate={disabledDate} />
      <Button icon={<RightOutlined />} onClick={addADay} disabled={disableAddADay} />
    </div>
  );
};

export default DatePickerWithArrows;
