import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button, Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { FC, useState } from 'react';

import classes from './DropdownCheckList.module.scss';

interface DropdownCheckListProps {
  title: string;
  checkList: { label: string; key: string; checked: boolean }[];
  onChange: (e: CheckboxChangeEvent, id: string) => Promise<void> | void;
}

const DropdownCheckList: FC<DropdownCheckListProps> = ({ title, checkList, onChange }) => {
  const [open, setOpen] = useState(false);

  const toggleDropdown = () => setOpen((prev) => !prev);

  return (
    <div className={classes.checkListContainer}>
      <Button onClick={toggleDropdown}>
        {title} {open ? <UpOutlined /> : <DownOutlined />}
      </Button>
      <div className={`${classes.listContent} ${open ? classes.open : ''}`}>
        <div className={classes.checkList}>
          {checkList.map((item) => (
            <div key={item.key} className={classes.checkItem}>
              <Checkbox checked={item.checked} onChange={(e) => onChange(e, item.key)}>
                <span className={item.checked ? '' : classes.inactive}>{item.label}</span>
              </Checkbox>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DropdownCheckList;
