import { DeliveryTaskDoc, PartnersUserDoc, PickupTaskDoc, TaskOrderCollection } from '@gooduncles/gu-app-schema';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { ColDef, ColGroupDef, RowNode } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { Image } from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';
import { combineLatest } from 'rxjs';

import { formatDate } from 'src/lib/1/date-util';
import { observeDeliveryTask, observePickupTask } from 'src/lib/4/firebase-short-cut';

import AgCellPartnerName from 'src/atomic-components/atoms/AgCellPartnerName/AgCellPartnerName';
import EditDeliveryTaskModal from 'src/atomic-components/molecules/EditTaskModal/EditDeliveryTaskModal';
import Loading from 'src/components/Loading/Loading';

export type DeliveryTaskRowData = DeliveryTaskDoc & {
  pickupTask: PickupTaskDoc | null;
  partnersUser: PartnersUserDoc | null;
};

interface DeliveryTaskTableProps {
  date: string;
  partnersUsers: PartnersUserDoc[];
  setSelectedRows: React.Dispatch<React.SetStateAction<DeliveryTaskRowData[]>>;
}

const defaultColDef: ColDef<DeliveryTaskRowData> = {
  sortable: true,
  resizable: true,
  filter: true,
};

const orderCollectionMap = {
  [TaskOrderCollection.Order]: '주문',
  [TaskOrderCollection.StoreIssue]: '이슈',
  [TaskOrderCollection.PurchaseOrder]: '사입',
};

const dateFormatter = ({ value }: { value: string | null }) => (value ? formatDate(value, 'HH:mm:ss') : '');
const isRowSelectable = (node: RowNode<DeliveryTaskRowData>) => {
  const data = node.data;
  if (!data) return false;
  // 이미 완료된 작업은 선택할 수 없다.
  if (data.finishedAt) return false;
  // 픽업 작업이 완료되지 않은 경우 선택할 수 없다.
  if (!data.pickupTask?.finishedAt) return false;
  return true;
};

const DeliveryTaskTable: FC<DeliveryTaskTableProps> = ({ date, partnersUsers, setSelectedRows }) => {
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState<DeliveryTaskRowData[]>([]);

  const columnDefs: (ColDef<DeliveryTaskRowData> | ColGroupDef<DeliveryTaskRowData>)[] = useMemo(
    () => [
      {
        width: 50,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
      },
      {
        field: 'partnersUser.nickname',
        headerName: '파트너',
        width: 120,
        cellRenderer: AgCellPartnerName,
      },
      {
        field: '_id',
        hide: true,
      },
      {
        field: 'orderId',
        hide: true,
      },
      {
        field: 'locationName',
        headerName: '배송장소',
      },
      {
        field: 'deliveryImages',
        headerName: '배송 이미지',
        cellRenderer: (params: any) => {
          const data = params.data as DeliveryTaskRowData;
          return data.deliveryImages ? (
            <div style={{ display: 'flex', alignItems: 'center', height: '100%', gap: '2px' }}>
              <Image.PreviewGroup>
                {data.deliveryImages.map((image, index) => (
                  <Image
                    key={index}
                    width={48}
                    height={48}
                    style={{ objectFit: 'cover' }}
                    src={image.thumbnail.url}
                    preview={{
                      src: image.original.url,
                    }}
                  />
                ))}
              </Image.PreviewGroup>
            </div>
          ) : null;
        },
      },
      {
        field: 'pickupTask.finishedAt',
        headerName: '픽업 시각',
        width: 120,
        valueFormatter: dateFormatter,
      },
      {
        field: 'finishedAt',
        headerName: '완료 시각',
        width: 120,
        valueFormatter: dateFormatter,
      },
      {
        field: 'taskIssue',
        headerName: '배송 이슈',
        width: 120,
        valueFormatter: (params: any) => params.value ?? '',
      },
      {
        field: 'cancelReason',
        headerName: '취소여부',
        width: 120,
        valueFormatter: (params: any) => params.value ?? '',
      },
      {
        field: 'orderCollection',
        headerName: '주문/이슈',
        width: 120,
        valueGetter: (params: any) => {
          const data = params.data as DeliveryTaskRowData;
          return data ? orderCollectionMap[data.orderColleciton] : '';
        },
      },
      {
        field: '_id',
        headerName: '상세',
        width: 100,
        cellRenderer: (params: any) => {
          const value: string = params.value;
          return <EditDeliveryTaskModal deliveryTaskId={value} />;
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (!date || partnersUsers.length === 0) return;
    setLoading(true);
    const pickupTaskObservable = observePickupTask([['date', '==', date]]);
    const deliveryTaskObservable = observeDeliveryTask([['date', '==', date]]);
    const subscription = combineLatest([pickupTaskObservable, deliveryTaskObservable]).subscribe(
      ([pickupDocs, deliveryDocs]) => {
        const rowData: DeliveryTaskRowData[] = deliveryDocs
          .map((doc) => {
            const partnersUser = partnersUsers.find((partner) => partner._id === doc.partnerId) ?? null;
            const pickupTask = pickupDocs.find((pickup) => pickup.orderId === doc.orderId) ?? null;
            return { ...doc, pickupTask, partnersUser };
          })
          .sort((a, b) => a.locationGroupSortKey - b.locationGroupSortKey);
        setSelectedRows([]);
        setRowData(rowData);
        setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  }, [date, partnersUsers, setSelectedRows]);

  return (
    <div className='ag-theme-alpine height100'>
      {loading && <Loading title='데이터를 불러오고 있습니다.' />}
      <AgGridReact
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        gridOptions={{ rowHeight: 63 }}
        // 행 선택을 허용한다.
        rowSelection='multiple'
        isRowSelectable={isRowSelectable}
        onSelectionChanged={(e) => setSelectedRows(e.api.getSelectedRows())}
        // 행 선택을 켜놓더라도 개별 셀의 내용을 복사할 수 있도록한다.
        enableCellTextSelection={true}
      />
    </div>
  );
};

export default DeliveryTaskTable;
