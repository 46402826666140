import { EnvironmentOutlined } from '@ant-design/icons';
import { DeliverySpotDoc } from '@gooduncles/gu-app-schema';
import { Button } from 'antd';
import { FC, useCallback, useEffect, useState } from 'react';

import { getDeliverySpot } from 'src/lib/4/firebase-short-cut';

interface OpenDeliverySpotMapButtonProps {
  deliverySpotId: string;
}

const OpenDeliverySpotMapButton: FC<OpenDeliverySpotMapButtonProps> = ({ deliverySpotId }) => {
  const [deliverySpot, setDeliverySpot] = useState<DeliverySpotDoc>();

  const handleOpenMap = useCallback(() => {
    if (!deliverySpot) return;
    window.open(`https://map.naver.com/v5/search/${deliverySpot.lat},${deliverySpot.lng}`);
  }, [deliverySpot]);

  useEffect(() => {
    getDeliverySpot(deliverySpotId).then(setDeliverySpot);
  }, [deliverySpotId]);

  return deliverySpot ? (
    <Button
      type='text'
      icon={<EnvironmentOutlined style={{ color: 'var(--green400)' }} />}
      disabled={!deliverySpot}
      onClick={handleOpenMap}
    />
  ) : null;
};

export default OpenDeliverySpotMapButton;
