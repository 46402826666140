import {
  CallSetProductWeeklyPriceRequest,
  CallSetProductWeeklyPriceResponse,
  CallSyncProductPriceTrendRequest,
  CallSyncProductPriceTrendResponse,
  ChangeVolumeTemplate,
  InstallmentBillTemplate,
  InvoiceTemplate,
  ProductSoldOutTemplate,
} from '@gooduncles/gu-app-schema';

import { FirebaseManager } from 'src/lib/3/firebase-manager';
import {
  CallAnalyzeStoreCategoryProductsRequest,
  CallAnalyzeStoreCategoryProductsResponse,
  CallGetSeoulStoresRequest,
  CallGetSeoulStoresResponse,
  CallKakaoAlimtalkRequest,
  CallKakaoAlimtalkResponse,
  CallKakaoFriendtalkRequest,
  CallKakaoFriendtalkResponse,
  CallSendMobileMessageRequest,
  CallSendMobileMessageResponse,
  CallSetUserStatusRequest,
  CallSetUserStatusResponse,
  CallSyncKakaoTalkResultRequest,
  CallSyncKakaoTalkResultResponse,
  CallSyncMobileMessageResultRequest,
  CallSyncMobileMessageResultResponse,
} from 'src/lib/3/schema-on-call';

const firebaseManager = FirebaseManager.getInstance();

/**
 * 카카오 친구톡 발송
 */
export const callKakaoFriendtalk = firebaseManager.getCallable<CallKakaoFriendtalkRequest, CallKakaoFriendtalkResponse>(
  'callKakaoFriendtalk'
);

/**
 * 수량 변경 알림톡
 */
export const callSendKakaoAlimTalkForChangeVolume = firebaseManager.getCallable<
  CallKakaoAlimtalkRequest<ChangeVolumeTemplate>,
  CallKakaoAlimtalkResponse
>('callKakaoAlimtalk');

/**
 * 품절 알림톡
 */
export const callSendKakaoAlimTalkForProductSoldout = firebaseManager.getCallable<
  CallKakaoAlimtalkRequest<ProductSoldOutTemplate>,
  CallKakaoAlimtalkResponse
>('callKakaoAlimtalk');

/**
 * 정산서 발송 알림톡
 */
export const callSendKakaoAlimTalkForBill = firebaseManager.getCallable<
  CallKakaoAlimtalkRequest<InstallmentBillTemplate>,
  CallKakaoAlimtalkResponse
>('callKakaoAlimtalk');

/**
 * 청구안내 발송 알림톡
 */
export const callSendKakaoAlimTalkForInvoice = firebaseManager.getCallable<
  CallKakaoAlimtalkRequest<InvoiceTemplate>,
  CallKakaoAlimtalkResponse
>('callKakaoAlimtalk');

/**
 * 카카오 알림톡의 개별 결과 동기화
 */
export const callSyncKakaoTalkResult = firebaseManager.getCallable<
  CallSyncKakaoTalkResultRequest,
  CallSyncKakaoTalkResultResponse
>('callSyncKakaoTalkResult');

/**
 * SMS/LMS(한글 45자 이상의 장문은 LMS로 보낸다.) 발송
 */
export const callSendMobileMessage = firebaseManager.getCallable<
  CallSendMobileMessageRequest,
  CallSendMobileMessageResponse
>('callSendMobileMessage');

/**
 * MobileMessage의 개별 결과 동기화
 */
export const callSyncMobileMessageResult = firebaseManager.getCallable<
  CallSyncMobileMessageResultRequest,
  CallSyncMobileMessageResultResponse
>('callSyncMobileMessageResult');

export const callGetSeoulStores = firebaseManager.getCallable<CallGetSeoulStoresRequest, CallGetSeoulStoresResponse>(
  'callGetSeoulStores'
);

export const callSetUserStatus = firebaseManager.getCallable<CallSetUserStatusRequest, CallSetUserStatusResponse>(
  'callSetUserStatus'
);

/**
 * 업종별 상품군(MainTag) 순위를 작성합니다.
 */
export const callAnalyzeStoreCategoryProducts = firebaseManager.getCallable<
  CallAnalyzeStoreCategoryProductsRequest,
  CallAnalyzeStoreCategoryProductsResponse
>('callAnalyzeStoreCategoryProducts');

/**
 * 현재까지 생성된 가락시장 가격 동향과 자사의 매입가 동향을 최근 30일간의 데이터로 업데이트합니다.
 */
export const callSyncProductPriceTrend = firebaseManager.getCallable<
  CallSyncProductPriceTrendRequest,
  CallSyncProductPriceTrendResponse
>('callSyncProductPriceTrend');

/**
 * 상품의 주간 가격 데이터를 생성합니다.
 */
export const callSetProductWeeklyPrice = firebaseManager.getCallable<
  CallSetProductWeeklyPriceRequest,
  CallSetProductWeeklyPriceResponse
>('callSetProductWeeklyPrice');
