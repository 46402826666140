import type { XYCoord } from 'dnd-core';
import { DOMAttributes, FC, PropsWithChildren, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { DndCardDragResult, DndCardItem, DndItemType } from 'src/schema/schema-drag-and-drop';

import classes from './DndCard.module.scss';

type DndCardProps = DOMAttributes<HTMLDivElement> & {
  item: DndCardItem;
  isSelected: boolean;
  selectedItems: DndCardItem[];
  insertLineTop: boolean;
  insertLineBottom: boolean;
  clearSelection: () => void;
  setInsertPosition: (groupId: string, hoverIndex: number, insertIndex: number) => void;
  onChangeSelectedItems: (index: number, groupId: string, cmdKey: boolean, shiftKey: boolean) => void;
  moveItems: (dragResult: DndCardDragResult) => void;
  style?: React.CSSProperties;
};

const DndCard: FC<PropsWithChildren<DndCardProps>> = ({
  item,
  isSelected,
  selectedItems,
  insertLineTop,
  insertLineBottom,
  clearSelection,
  setInsertPosition,
  onChangeSelectedItems,
  moveItems,
  children,
  style,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);

  // 드래그 이벤트 처리
  const [{ isDragging }, drag] = useDrag<DndCardDragResult, any, { isDragging: boolean }>({
    type: DndItemType.CARD,
    item: () => {
      // 1. 선택된 카드를 움직이는 건지, 아니면 선택하지 않은 새로운 카드를 움직이는지 확인한다.
      const filteredSelectedItems = selectedItems.filter((i) => i.id !== item.id);
      // 선택된 카드에서 현재 카드를 뺀 길이과 빼지 않은 길이가 같으면, 현재 카드는 선택된 카드에 포함되지 않은 카드이다.
      const isCurrentItemSelected = selectedItems.length !== filteredSelectedItems.length;

      const draggedItems = isCurrentItemSelected ? selectedItems : [item];
      // 현재 드래그중인 카드가 선택된 카드에 포함된게 아니라면, 선택된 카드를 모두 해제한다.
      if (!isCurrentItemSelected) {
        clearSelection();
      }

      // 2. 카드의 정렬 순서와는 별개로 드래그 중인 미리보기에서는
      // 사용자가 끌어당긴 카드를 가장 위로 보여줘야 하기 때문에, 별도의 stack을 만든다.
      const previewStack = [item, ...filteredSelectedItems];
      return { item, draggedItems, previewStack };
    },
    end: (item) => {
      moveItems(item);
      clearSelection();
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // 드롭 이벤트 처리(덮혀지는 카드)
  const [{ hovered }, drop] = useDrop<void, any, { hovered: boolean }>({
    accept: DndItemType.CARD,
    // 선택된 카드가 들어갈 곳의 위치를 잡는다.
    hover: (_, monitor) => {
      if (!ref.current) return;

      const hoverIndex = item.index;
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const mousePointerY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
      const insertIndex = mousePointerY < hoverMiddleY ? hoverIndex : hoverIndex + 1;

      setInsertPosition(item.groupId, hoverIndex, insertIndex);
    },
    collect: (monitor) => ({
      hovered: monitor.isOver(),
    }),
  });

  drag(drop(ref));
  return (
    <div
      ref={ref}
      className={`${classes.dndCard} ${isSelected ? classes.selected : ''}`}
      style={style}
      onClick={(e) => onChangeSelectedItems(item.index, item.groupId, e.metaKey, e.shiftKey)}>
      {insertLineTop && hovered && <div className={classes.insertLineTop} />}
      <div style={{ opacity: isDragging ? 0.3 : 1 }}>{children}</div>
      {insertLineBottom && hovered && <div className={classes.insertLineBottom} />}
    </div>
  );
};

export default DndCard;
