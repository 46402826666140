/**
 * {@link https://navermaps.github.io/maps.js.ncp/docs/tutorial-digest.example.html example}
 * {@link https://navermaps.github.io/maps.js.ncp/docs/naver.maps.Map.html method}
 */

/**
 * 마커의 겹침 상황을 해결하기 위한 클래스
 */
export class MouseOverMarkerHandler {
  private static instance: MouseOverMarkerHandler;
  private zIndex: number;

  private constructor() {
    this.zIndex = 0;
    // 다른 함수의 callback으로 전달되어 사용되고 있으므로 this를 별도로 바인딩한다.
    this.getZIndex = this.getZIndex.bind(this);
    this.handler = this.handler.bind(this);
  }

  public static getInstance(): MouseOverMarkerHandler {
    if (!MouseOverMarkerHandler.instance) {
      MouseOverMarkerHandler.instance = new MouseOverMarkerHandler();
    }

    MouseOverMarkerHandler.instance.resetZIndex();
    return MouseOverMarkerHandler.instance;
  }

  public resetZIndex(): void {
    this.zIndex = 0;
  }

  public getZIndex(): number {
    return this.zIndex++;
  }

  public handler(e: any): void {
    const marker = e.overlay as naver.maps.Marker;
    marker.setZIndex(this.zIndex++);
  }
}

const showMarker = (map: naver.maps.Map, marker: naver.maps.Marker) => {
  if (marker.getMap()) return;
  marker.setMap(map);
};

const removeMarker = (marker: naver.maps.Marker) => {
  if (!marker.getMap()) return;
  marker.setMap(null);
};

export const updateMarkers = (map: naver.maps.Map, markers: naver.maps.Marker[], show = true) => {
  const mapBounds = map.getBounds() as naver.maps.LatLngBounds;
  for (const marker of markers) {
    if (mapBounds.hasLatLng(marker.getPosition()) && show) {
      showMarker(map, marker);
    } else {
      removeMarker(marker);
    }
  }
};

/**
 * 마커 색상 그룹을 생성합니다.
 * @param hue 0 ~ 360
 * @param saturation 100 | 88 | 75 | 63
 * @returns
 */
const markerColorPlatte = (hue: number, saturation: number) => {
  if (hue === 0) {
    return `hsl(0 0% 30% / 88%)`;
  }
  switch (saturation) {
    case 75:
      return `hsl(${hue} ${saturation}% 58% / 88%)`;
    case 72:
      return `hsl(${hue} ${saturation}% 54% / 88%)`;
    case 63:
      return `hsl(${hue} ${saturation}% 48% / 88%)`;
    case 64:
      return `hsl(${hue} ${saturation}% 43% / 88%)`;
    default:
      return `hsl(${hue} ${saturation}% 34% / 88%)`;
  }
};

/**
 * 클러스터의 메인 색상 각도에 맞춰서 색상 그룹을 생성합니다.
 * (기본 4개)
 */
export const createClusterIcons = (deg: number) => {
  return [75, 72, 63, 64].map((saturation) => {
    const color = markerColorPlatte(deg, saturation);
    const mSize = 120 - saturation;
    const size = new naver.maps.Size(mSize, mSize);
    const anchor = new naver.maps.Point(mSize / 2, mSize / 2);
    return {
      content: `<div style='width: ${mSize}px; height: ${mSize}px; background: ${color}; font-size: ${
        9 + mSize / 10
      }px' class='clusterMarker' />`,
      size,
      anchor,
    };
  });
};

const createMarkerIcon = (
  content: {
    title: string;
    body: any;
    color: number;
    id: string;
  },
  selected = false
) => {
  const color = markerColorPlatte(content.color, 100);
  return {
    content: `<div id="${content.id}" class="partnerMarker ${selected ? 'selected' : ''}">
      <p>${content.title}</p>
      <span>${content.body}</span>
      <span class="markerBg" style="background-color: ${color}">
        <span class="markerTail" style="border-top-color: ${color};"></span>
      </span>
      <span class="markerShadow">
        <span class="markerTail"></span>
      </span>
    </div>`,
    size: new naver.maps.Size(50, 36),
    anchor: new naver.maps.Point(0, 42),
  };
};

/**
 * 마커 아이콘의 클래스를 변경합니다.
 * @param htmlString
 * @param selected
 * @returns
 */
export const setMarkerIconClass = (htmlString: string, selected: boolean) => {
  const tempElement = document.createElement('div');
  tempElement.innerHTML = htmlString;

  const markerElement = tempElement.querySelector('.partnerMarker') as HTMLElement;
  if (selected) {
    markerElement.classList.add('selected');
  } else {
    markerElement.classList.remove('selected');
  }

  return {
    content: markerElement.outerHTML,
    size: new naver.maps.Size(50, 36),
    anchor: new naver.maps.Point(0, 42),
  };
};

/**
 * 기본 마커를 생성합니다.
 * @param map
 * @param lat
 * @param lng
 * @param id
 * @param content
 * @returns
 */
export const createMarker = (
  map: naver.maps.Map,
  lat: number,
  lng: number,
  id: string,
  content: {
    title: string;
    body: any;
    color: number;
  },
  draggable = false
) => {
  const marker = new naver.maps.Marker({
    position: new naver.maps.LatLng(lat, lng),
    zIndex: 0,
    map,
    title: content.title,
    icon: createMarkerIcon({ ...content, id }),
    draggable,
  });
  marker.set('seq', id);
  return marker;
};

export const createMouseOverMarkerHandler = () => {
  let zIndex = 0;
  return {
    getZIndex: () => zIndex,
    handler: (e: any) => {
      const marker = e.overlay as naver.maps.Marker;
      marker.setZIndex(zIndex++);
    },
  };
};

/**
 * 마커에 이벤트를 등록합니다.
 */
export const createClickEventToMarker = (map: naver.maps.Map) => {
  const zIndexHandlerInstance = MouseOverMarkerHandler.getInstance();
  return (
    marker: naver.maps.Marker,
    callback: (id: string) => void,
    dragendCallBack: (lat: number, lng: number) => void
  ) => {
    const { getZIndex, handler } = zIndexHandlerInstance;

    // 1. 클릭 이벤트 등록
    naver.maps.Event.addListener(marker, 'click', (e) => {
      const marker = e.overlay as naver.maps.Marker;
      const markerId = marker.get('seq');
      const { x: lng, y: lat } = marker.getPosition();
      const zIndex = getZIndex();
      marker.setZIndex(zIndex);
      map.panTo(
        { lat, lng },
        {
          duration: 300,
        }
      );
      callback(markerId);
    });

    // 2. 마우스 오버 이벤트 등록
    naver.maps.Event.addListener(marker, 'mouseover', handler);

    // 3.
    naver.maps.Event.addListener(marker, 'dragend', (e) => {
      const marker = e.overlay as naver.maps.Marker;
      const { x: lng, y: lat } = marker.getPosition();
      dragendCallBack(lat, lng);
    });
  };
};
