import { PickupTaskDoc } from '@gooduncles/gu-app-schema';
import { FC } from 'react';

import { taskTypeToKr } from 'src/lib/1/constant';

import classes from './TaskLocationRow.module.scss';

import OpenDeliverySpotMapButton from '../Button/OpenDeliverySpotMapButton';

interface PickupTaskLocationRowProps {
  pickupTask: PickupTaskDoc;
}

const PickupTaskLocationRow: FC<PickupTaskLocationRowProps> = ({ pickupTask }) => {
  return (
    <div className={classes.taskLocationRowContainer}>
      <div className={classes.locationCard}>
        <div className='flexColumn'>
          <span>픽업 위치</span>
          <p>{pickupTask.locationName}</p>
        </div>
        <OpenDeliverySpotMapButton deliverySpotId={pickupTask.locationGroupId} />
      </div>
      <div className={classes.arrowBox}>
        <span>{taskTypeToKr[pickupTask.taskType]}</span>
        <span className={classes.arrow}>→</span>
      </div>
      <div className={classes.locationCard}>
        <div className='flexColumn'>
          <span>배송 위치</span>
          <p>{pickupTask.deliveryLocation.locationName}</p>
        </div>
        <OpenDeliverySpotMapButton deliverySpotId={pickupTask.deliveryLocation.locationGroupId} />
      </div>
    </div>
  );
};

export default PickupTaskLocationRow;
