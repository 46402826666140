import { Tabs } from 'antd';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import CommonPromotionSectionDashboard from './CommonPromotionSectionDashboard';
import CustomPromotionSectionDashboard from './CustomPromotionSectionDashboard';

const PromotionSectionTab: FC = () => {
  const navigate = useNavigate();
  const { tab } = useParams();

  const onTabChange = (key: string) => {
    navigate(`../${key}`);
  };

  return (
    <div className='height100 flexColumn'>
      <Tabs
        type='card'
        activeKey={tab}
        style={{ height: '100%', overflow: 'scroll' }}
        tabBarStyle={{ marginBottom: 0 }}
        onChange={onTabChange}
        items={[
          {
            key: 'common',
            label: '공통 프로모션',
            children: <CommonPromotionSectionDashboard />,
          },
          {
            key: 'custom',
            label: '매장별 프로모션',
            children: <CustomPromotionSectionDashboard />,
          },
        ]}
      />
    </div>
  );
};

export default PromotionSectionTab;
