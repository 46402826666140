/* eslint-disable @typescript-eslint/no-var-requires */
import EditorJS, { OutputData } from '@editorjs/editorjs';
import Header from '@editorjs/header';
import {
  ForwardRefRenderFunction,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import classes from './EditorJSBoard.module.scss';

const List = require('@editorjs/list');
const CheckList = require('@editorjs/checklist');

export interface EditorJSBoardHandle {
  content: OutputData | null;
}

interface EditorJSBoardProps {
  data: OutputData;
  lastModified?: number;
  /**
   * 현재는 수동으로 저장한다.
   * 자동 저장을 원하는 경우 이 함수를 onChage에 등록한다.
   */
  autoSave?: (data: OutputData) => Promise<void>;
}

const EditorJSBoard: ForwardRefRenderFunction<EditorJSBoardHandle, EditorJSBoardProps> = (
  { data, lastModified },
  forwardRef
) => {
  const [isInit, setIsInit] = useState(false);
  const [editorRef, setEditorRef] = useState<HTMLDivElement | null>(null);
  const editorId = editorRef?.id;
  const ejInstance = useRef<EditorJS | null>();
  const [content, setContent] = useState<OutputData | null>(null);

  /**
   * 에디터의 데이터를 초기화한다.
   */
  const initEditor = useCallback(
    (holder: string) => {
      const editor = new EditorJS({
        holder,
        onReady: () => {
          ejInstance.current = editor;
        },
        autofocus: true,
        data,
        onChange: async (api) => {
          const content0 = await api.saver.save();
          setContent(content0);
        },
        tools: {
          header: Header,
          list: List,
          checklist: CheckList,
        },
      });
    },
    [data]
  );

  /**
   * 최초 렌더링후, 데이터를 초기화한다.
   */
  useEffect(() => {
    if (editorId && !isInit) {
      initEditor(editorId);
      setIsInit(true);

      return () => {
        ejInstance?.current?.destroy();
        ejInstance.current = null;
      };
    }
  }, [editorId, initEditor, isInit]);

  useEffect(() => {
    if (lastModified) {
      setIsInit(false);
    }
  }, [lastModified]);

  useImperativeHandle(
    forwardRef,
    () => ({
      content,
    }),
    [content]
  );

  return <div className={classes.editorJS} style={{ flex: 1 }} ref={setEditorRef} id='editorjs' />;
};

export default forwardRef(EditorJSBoard);
