import { Tabs } from 'antd';
import { FC } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import useUserWithStore from 'src/hooks/useUserWithStore';

import Loading from 'src/components/Loading/Loading';

import classes from './UserDetail.module.scss';

import UserSelector from '../UserSelector/UserSelector';
import UserCommerceLog from './UserCommerceLog/UserCommerceLog';
import UserInfo from './UserInfo/UserInfo';
import UserOrderList from './UserOrderList/UserOrderList';
import UserOrderProductList from './UserOrderProductList/UserOrderProductList';

const UserDetail: FC = () => {
  const { userId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentTab = searchParams.get('tab');

  const { userWithStore, userLoading, mutateUser, mutateStore } = useUserWithStore(userId);

  return (
    <div className='height100 flexColumn'>
      <div className={classes.userDetailHead}>
        <UserSelector userId={userId} />
      </div>
      <div className={classes.userDetailContainer}>
        {!userId ? (
          <div className={classes.userDetail}>사용자를 선택해주세요.</div>
        ) : userLoading ? (
          <Loading title='잠시만 기다려주세요.' />
        ) : userWithStore ? (
          <Tabs
            type='card'
            defaultActiveKey={currentTab ?? 'info'}
            style={{ height: '100%', overflow: 'scroll' }}
            tabBarStyle={{ marginBottom: 0 }}
            onTabClick={(key) => setSearchParams({ tab: key })}
            items={[
              {
                key: 'info',
                label: '정보',
                children: <UserInfo userWithStore={userWithStore} mutateUser={mutateUser} mutateStore={mutateStore} />,
              },
              {
                key: 'order',
                label: '주문',
                children: <UserOrderList userId={userWithStore._id} />,
              },
              {
                key: 'order-product',
                label: '주문(상품)',
                children: <UserOrderProductList userId={userWithStore._id} />,
              },
              {
                key: 'log',
                label: '로그',
                children: <UserCommerceLog userId={userWithStore._id} />,
              },
            ]}
          />
        ) : (
          <div className={classes.userDetail}>데이터가 없습니다.</div>
        )}
      </div>
    </div>
  );
};

export default UserDetail;
