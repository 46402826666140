import { PickupTaskDoc } from '@gooduncles/gu-app-schema';
import { useEffect, useState } from 'react';

import { observePickupTaskDoc } from 'src/lib/4/firebase-short-cut';

const usePickupTask = (pickupTaskId: string | undefined) => {
  const [isLoading, setIsLoading] = useState(true);
  const [pickupTask, setPickupTask] = useState<PickupTaskDoc | undefined>();

  useEffect(() => {
    if (pickupTaskId) {
      setIsLoading(true);
      const subscription = observePickupTaskDoc(pickupTaskId).subscribe((doc) => {
        setPickupTask(doc);
        setIsLoading(false);
      });
      return () => {
        subscription.unsubscribe();
        setPickupTask(undefined);
        setIsLoading(false);
      };
    } else {
      setPickupTask(undefined);
      setIsLoading(false);
    }
  }, [pickupTaskId]);

  return { pickupTask, isLoading };
};

export default usePickupTask;
