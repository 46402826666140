import { ProductBinLocationDoc } from '@gooduncles/gu-app-schema';
import { useEffect, useState } from 'react';

import { observeProductBinLocation } from 'src/lib/4/firebase-short-cut';

const useProductBinLocation = () => {
  const [productBinLocation, setProductBinLocation] = useState<ProductBinLocationDoc>();
  useEffect(() => {
    const subscription = observeProductBinLocation().subscribe(setProductBinLocation);
    return () => subscription.unsubscribe();
  }, []);

  return productBinLocation;
};

export default useProductBinLocation;
