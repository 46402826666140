import { OrderProduct } from '@gooduncles/gu-app-schema';
import { cloneDeep } from 'lodash-es';
import { Dispatch } from 'react';
import { ActionType, createReducer } from 'typesafe-actions';

import * as OrderProductsActions from '../actions/order-products';

export type OrderProductsState = {
  orderProducts: {
    [productId: string]: OrderProduct;
  };
  length: number;
  totalPrice: number;
};

export type OrderProductsDispatch = Dispatch<ActionType<typeof OrderProductsActions>>;

export const initialState: OrderProductsState = { orderProducts: {}, length: 0, totalPrice: 0 };

export const orderProductsReducer = createReducer<OrderProductsState, ActionType<typeof OrderProductsActions>>(
  initialState
)
  .handleAction(OrderProductsActions.setOrderProducts, (_, action) => {
    const { payload } = action;
    const orderProducts = Object.fromEntries(payload.map((orderProduct) => [orderProduct.productId, orderProduct]));
    const totalPrice = Object.values(orderProducts).reduce((acc, v) => acc + v.price * v.volume, 0) ?? 0;
    return {
      orderProducts,
      length: Object.keys(orderProducts).length,
      totalPrice,
    };
  })
  .handleAction(OrderProductsActions.addProduct, (state, action) => {
    const { payload } = action;
    const orderProducts = { ...state.orderProducts, [payload.productId]: payload };
    const totalPrice = Object.values(orderProducts).reduce((acc, v) => acc + v.price * v.volume, 0) ?? 0;
    return {
      orderProducts,
      length: Object.keys(orderProducts).length,
      totalPrice,
    };
  })
  .handleAction(OrderProductsActions.setProductVolume, (state, action) => {
    const { productId, volume } = action.payload;
    const orderProducts = cloneDeep(state.orderProducts);
    orderProducts[productId].volume = volume;
    const totalPrice = Object.values(orderProducts).reduce((acc, v) => acc + v.price * v.volume, 0) ?? 0;
    return {
      orderProducts,
      length: state.length,
      totalPrice,
    };
  })
  .handleAction(OrderProductsActions.removeProduct, (state, action) => {
    const { payload } = action;
    const orderProducts = cloneDeep(state.orderProducts);
    delete orderProducts[payload];
    const totalPrice = Object.values(orderProducts).reduce((acc, v) => acc + v.price * v.volume, 0) ?? 0;
    return {
      orderProducts,
      length: Object.keys(orderProducts).length,
      totalPrice,
    };
  })
  .handleAction(OrderProductsActions.resetProducts, () => initialState);
