import { Checkbox, Tabs } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { FC, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

import { AvailableYearForStats, availableYearsForStats } from 'src/lib/1/constant';

import useMonthlyOrderStatsForStore from 'src/hooks/useMonthlyOrderStats';
import { useTitle } from 'src/hooks/useTitle';

import Loading from 'src/components/Loading/Loading';
import MonthlyOrderStackedBar from 'src/components/Stats/MonthlyOrderStackedBar';

const yearOptions = Object.entries(availableYearsForStats).map(([value, label]) => ({ label, value }));
const stringToYear = (value: string[]) => value.map((v) => Number(v) as AvailableYearForStats);

const MonthlyOrderStatsPage: FC<{ title: string }> = ({ title }) => {
  useTitle(`GU 관리자 | ${title}`);
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab') ?? 'stacked-bar';
  const selectedYears = searchParams.getAll('year');
  const { monthlyOrderStatsForStore, monthlyOrderStatsForStoreLoading } = useMonthlyOrderStatsForStore({
    selectedYears: stringToYear(selectedYears),
  });

  const onYearsChange = useCallback(
    (years: CheckboxValueType[]) => {
      setSearchParams({ year: years as string[], tab });
    },
    [setSearchParams, tab]
  );

  const onTabChange = useCallback(
    (key: string) => {
      setSearchParams({ tab: key, year: selectedYears });
    },
    [selectedYears, setSearchParams]
  );

  return (
    <div className='height100 flexColumn'>
      <p style={{ marginBottom: 8 }}>
        <span style={{ paddingRight: 6 }}>통계 연도:</span>
        <Checkbox.Group options={yearOptions} value={selectedYears} onChange={onYearsChange} />
      </p>
      {monthlyOrderStatsForStoreLoading ? (
        <Loading title='데이터를 불러오고 있습니다.' />
      ) : (
        <Tabs
          type='card'
          defaultActiveKey={tab}
          style={{ height: '100%', overflow: 'scroll' }}
          tabBarStyle={{ marginBottom: 0 }}
          onTabClick={onTabChange}
          items={[
            {
              key: 'stacked-bar',
              label: 'Stacked bar chart',
              children: <MonthlyOrderStackedBar monthlyOrderForStoreStats={monthlyOrderStatsForStore} />,
            },
          ]}
        />
      )}
    </div>
  );
};

export default MonthlyOrderStatsPage;
