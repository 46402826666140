import { PaymentItemDoc } from '@gooduncles/gu-app-schema';
import dayjs from 'dayjs';
import { FC, useEffect, useState } from 'react';
import { map } from 'rxjs';

import { dayjsesToStringDateWhere, getDefaultDate } from 'src/lib/1/date-util';
import { observePaymentItem } from 'src/lib/4/firebase-short-cut';

import DatePickerWithArrows from 'src/components/Common/DatePickerWithArrows/DatePickerWithArrows';
import PaymentItemTable from 'src/components/NewSettlement/PaymentItemTable/PaymentItemTable';

const defaultValue = getDefaultDate();

const SettledPaymentItemPage: FC = () => {
  const [rowData, setRowData] = useState<PaymentItemDoc[]>([]);
  const [date, setDate] = useState<dayjs.Dayjs | null>(defaultValue);

  useEffect(() => {
    if (date) {
      const [leftDate, rightDate] = dayjsesToStringDateWhere(date, date);
      const subscription = observePaymentItem([], {
        sortKey: 'settledAt',
        orderBy: 'desc',
        startValue: leftDate,
        endValue: rightDate,
      })
        .pipe(map((docs) => docs.sort((a, b) => b.transactionDate?.localeCompare(a.transactionDate ?? '') ?? 0)))
        .subscribe(setRowData);
      return () => subscription.unsubscribe();
    }
  }, [date]);

  return (
    <div className='tabBody flexColumn height100'>
      <section className='flexRow flexSpaceBetween'>
        <DatePickerWithArrows date={date} setDate={setDate} />
      </section>
      <section className='height100'>
        <PaymentItemTable rowData={rowData} />
      </section>
    </div>
  );
};

export default SettledPaymentItemPage;
