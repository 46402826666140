import { CloudSyncOutlined } from '@ant-design/icons';
import { Button, Checkbox, Popconfirm, Segmented, message, notification } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { FC, useCallback, useEffect, useState } from 'react';
import { combineLatest } from 'rxjs';
import { callSyncProductPriceTrend } from 'src/utils/firebase-callable';
import {
  ProductMarketTrendTableRowOption,
  generateMarketPriceTrendRowData,
  marketTrendRowOptions,
} from 'src/utils/product-trend-util';

import { errorObjectToString } from 'src/lib/1/util';
import { observeGarakMarketProductPriceTrend, observeGuProductPriceTrend } from 'src/lib/4/firebase-short-cut';

import { useTitle } from 'src/hooks/useTitle';

import ProductMarketTrendTable, {
  ProductMarketTrendTableRowData,
} from 'src/components/ProductTrend/ProductMarketTrendTable';

type SegmentOption = 'Daily' | 'Weekly';
const segmentOptions: SegmentOption[] = ['Daily', 'Weekly'];

const ProductMarketTrendPage: FC = () => {
  useTitle('GU 관리자 | 경매 가격 동향');
  const [segmentOption, setSegmentOption] = useState(segmentOptions[0]);
  const [rowData, setRowData] = useState<ProductMarketTrendTableRowData[]>([]);
  const [checkedList, setCheckedList] = useState<ProductMarketTrendTableRowOption[]>(marketTrendRowOptions);
  const [loading, setLoading] = useState(false);

  const onChange = (checkedValues: CheckboxValueType[]) => {
    if (checkedValues.length === 0) {
      message.error('최소 하나의 옵션은 선택해야 합니다.');
      return;
    }
    setCheckedList(checkedValues as ProductMarketTrendTableRowOption[]);
  };

  const requestUpdateData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await callSyncProductPriceTrend();
      if (response.data.result !== 'success') throw new Error(response.data.message);
      notification.success({
        message: '상품 가격 동향 데이터 갱신 성공',
        description: response.data.message,
      });
    } catch (error) {
      const description = errorObjectToString(error);
      notification.error({
        message: '상품 가격 동향 데이터 갱신 실패',
        description,
      });
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    // LatestProductInfo를 가져오는 Observable 추가예정
    const guProductPriceTrendObservable = observeGuProductPriceTrend();
    const garakMarketProductPriceTrendObservable = observeGarakMarketProductPriceTrend();
    const subscription = combineLatest([
      garakMarketProductPriceTrendObservable,
      guProductPriceTrendObservable,
    ]).subscribe(([garakMarketProductPriceTrendDocs, guProductPriceTrendDocs]) => {
      generateMarketPriceTrendRowData(garakMarketProductPriceTrendDocs, guProductPriceTrendDocs).then(setRowData);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <div className='tabBody flexColumn height100'>
      <section className='flexRow flexSpaceBetween'>
        <div>
          <Checkbox.Group
            options={marketTrendRowOptions}
            defaultValue={marketTrendRowOptions}
            value={checkedList}
            onChange={onChange}
          />
        </div>
        <div>
          <Segmented
            options={segmentOptions}
            value={segmentOption}
            onChange={(e) => setSegmentOption(e as SegmentOption)}
          />
          <Popconfirm
            title='데이터를 갱신하시겠습니까?'
            description={
              <div>
                데이터 갱신시 가락시랑 API를 대량 호출하므로 하루에 한 두번만 진행해주세요.
                <br />
                데이터 갱신을 요청한 후 다른 페이지로 이동하셔도됩니다.
              </div>
            }
            onConfirm={requestUpdateData}
            okButtonProps={{ loading }}
            okText='갱신'
            cancelText='취소'>
            <Button loading={loading} icon={<CloudSyncOutlined />} style={{ marginLeft: 8 }}>
              데이터 갱신
            </Button>
          </Popconfirm>
        </div>
      </section>
      <section className='height100'>
        <ProductMarketTrendTable rowData={rowData} options={checkedList} column={segmentOption} />
      </section>
    </div>
  );
};

export default ProductMarketTrendPage;
