import { PaymentItemDoc, PaymentStatusCode, StoreDoc, paymentStatusCodeKrMap } from '@gooduncles/gu-app-schema';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { ColDef, RowClassParams } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { FC, useMemo } from 'react';

import { formatDate } from 'src/lib/1/date-util';
import { updatePaymentItem } from 'src/lib/4/firebase-short-cut';
import {
  getCellClassForPaymentStatus,
  numberFormatter,
  onCellDataDeleted,
  suppressEnterKeyEvent,
} from 'src/lib/6/ag-grid-util';

import useStoresForSearch from 'src/hooks/useStoresForSearch';

import SelectCellEditor from 'src/components/Common/CellEditor/SelectCellEditor';

const dateFormatter = ({ value }: { value: string | null }) => (value ? formatDate(value, 'yyyy-MM-dd HH:mm:ss') : '');

const onSelectValueChangeWapperForStore = (id: string, stores?: StoreDoc[]) => async (value: string) => {
  const store = stores?.find((store) => store.storeCode === value);
  if (!store) {
    throw new Error('가게를 찾을 수 없습니다.');
  }
  return updatePaymentItem(id, {
    storeId: store._id,
    storeName: store.storeNickname,
  });
};

const defaultColDef: ColDef<PaymentItemDoc> = {
  sortable: true,
  resizable: true,
  filter: true,
};

interface PaymentItemTableProps {
  rowData: PaymentItemDoc[];
  setSelectedRows?: React.Dispatch<React.SetStateAction<PaymentItemDoc[]>>;
  forUpload?: boolean;
  listForDuplicateCheck?: PaymentItemDoc[];
}

/**
 * 결제내역을 표시하는 테이블
 */
const PaymentItemTable: FC<PaymentItemTableProps> = ({
  rowData,
  setSelectedRows,
  forUpload = false,
  listForDuplicateCheck,
}) => {
  const { stores, storeSearchValues } = useStoresForSearch();
  const selectable = useMemo(() => setSelectedRows !== undefined, [setSelectedRows]);

  const columnDefs: ColDef<PaymentItemDoc>[] = useMemo(
    () => [
      {
        valueGetter: (params) => (params.node?.rowIndex ?? 0) + 1,
        headerCheckboxSelection: selectable,
        headerCheckboxSelectionFilteredOnly: selectable,
        checkboxSelection: selectable,
        width: 88,
      },
      {
        field: 'transactionDate',
        headerName: '결제일시',
        valueFormatter: dateFormatter,
      },
      {
        field: 'payerInfo',
        headerName: '결제자',
      },
      {
        hide: true,
        field: 'storeId',
        headerName: '매장Id',
      },
      {
        field: 'storeName',
        headerName: '매장',
        editable: true,
        valueFormatter: ({ value }) => value ?? '🚨',
        cellEditor: (params: any) => (
          <SelectCellEditor
            placeholder='매장 선택'
            defaultValue={params.value}
            onValueChange={(value) => onSelectValueChangeWapperForStore(params.data._id, stores)(value)}
            stopEditing={params.stopEditing as () => void}
            options={(storeSearchValues ?? []).map((storeKey) => {
              const storeCode = storeKey.split('-')?.[0];
              return { label: storeKey, value: storeCode };
            })}
          />
        ),
        suppressKeyboardEvent: suppressEnterKeyEvent,
        onCellValueChanged: (params) =>
          onCellDataDeleted<PaymentItemDoc>(params, () =>
            updatePaymentItem(params.data._id, {
              storeId: null,
              storeName: null,
            })
          ),
      },
      {
        field: 'paymentStatus',
        headerName: '결제상태',
        width: 100,
        valueFormatter: ({ value }) => paymentStatusCodeKrMap[value as unknown as PaymentStatusCode],
        cellClass: getCellClassForPaymentStatus,
        filterParams: {
          valueFormatter: (params: any) => paymentStatusCodeKrMap[params.value as unknown as PaymentStatusCode],
        },
      },
      {
        field: 'paymentMethod',
        headerName: '결제수단',
        width: 100,
      },
      {
        field: 'paymentVendor',
        headerName: '출처',
        width: 100,
      },
      {
        field: 'amount',
        headerName: '결제금액',
        width: 120,
        valueFormatter: numberFormatter,
      },
      {
        field: 'supplyPrice',
        headerName: '공급가액',
        width: 120,
        valueFormatter: numberFormatter,
      },
      {
        field: 'tax',
        headerName: '부가세',
        width: 120,
        valueFormatter: numberFormatter,
      },
      {
        field: 'extraInfo',
        headerName: '기타정보',
      },
      {
        hide: forUpload,
        field: 'settledAt',
        headerName: '정산시각',
        valueFormatter: dateFormatter,
      },
      {
        hide: forUpload,
        field: 'settlementResultId',
        headerName: '정산결과',
        width: 100,
        valueFormatter: ({ value }) => (value ? '정산완료' : '미정산'),
        cellStyle: (params: any) => {
          if (params.value) {
            return { color: 'var(--green400)' };
          }
          return null;
        },
      },
      {
        hide: forUpload,
        field: 'createdBy',
        headerName: '생성자',
      },
    ],
    [forUpload, selectable, storeSearchValues, stores]
  );

  return (
    <div className='ag-theme-alpine height100'>
      <AgGridReact
        rowData={rowData}
        columnDefs={columnDefs}
        rowSelection={selectable ? 'multiple' : undefined}
        defaultColDef={defaultColDef}
        onSelectionChanged={setSelectedRows ? (e) => setSelectedRows(e.api.getSelectedRows()) : undefined}
        context={{
          listForDuplicateCheck,
        }}
        getRowStyle={(params: RowClassParams<PaymentItemDoc>): any => {
          const { listForDuplicateCheck }: { listForDuplicateCheck: PaymentItemDoc[] } = params.context;
          const data = params.data as PaymentItemDoc;
          if (!listForDuplicateCheck) return null;
          const duplicated = listForDuplicateCheck.some(
            (item: PaymentItemDoc) => item.transactionDate === data.transactionDate
          );
          if (duplicated) {
            return { backgroundColor: 'hsl(0 74% 95% / 1)' };
          }
          return null;
        }}
        // 행 선택을 켜놓더라도 개별 셀의 내용을 복사할 수 있도록한다.
        enableCellTextSelection={true}
      />
    </div>
  );
};

export default PaymentItemTable;
