import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { useMemo, useState } from 'react';

import { LogConsole, LogDoc } from 'src/lib/3/schema-log';
import {
  defaultValueGetter,
  orderStatusCellClass,
  timestampFormatter,
  unknownValueCellClass,
} from 'src/lib/6/ag-grid-util';

import DownloadRawDataCell from 'src/components/Common/DownloadRawDataCell/DownloadRawDataCell';

const defaultColDef = {
  sortable: true,
  resizable: true,
  filter: true,
};

const autoGroupColumnDef = {
  width: 240,
};

const ConsoleLogTable = ({ rowData }: { rowData: LogDoc<LogConsole>[] }) => {
  const [, setGridRef] = useState<AgGridReact<LogDoc<LogConsole>> | null>(null);

  const columnDefs: any = useMemo(
    () => [
      { field: '_id', hide: true },
      {
        headerName: '시각',
        field: '_timeCreate',

        width: 200,
        valueFormatter: timestampFormatter,
      },
      {
        field: 'version',
        width: 200,
      },
      {
        headerName: '사용자',
        field: 'body.user.email',
        width: 230,

        enableRowGroup: true,
        valueGetter: defaultValueGetter,
        cellClass: unknownValueCellClass,
      },
      {
        field: 'level',

        width: 88,
        hide: true,
      },
      {
        headerName: '내용',
        field: 'message',

        cellClass: orderStatusCellClass,
      },
      {
        headerName: '권한',
        field: 'body.user.role',
        width: 80,

        valueGetter: defaultValueGetter,
        cellClass: unknownValueCellClass,
      },
      {
        headerName: 'session',
        field: 'sessionId',

        width: 340,
        enableRowGroup: true,
        valueGetter: defaultValueGetter,
        cellClass: unknownValueCellClass,
      },
      {
        headerName: 'ip',
        field: 'publicIp',
        width: 160,

        enableRowGroup: true,
        valueGetter: defaultValueGetter,
        cellClass: unknownValueCellClass,
      },
      {
        headerName: '액션',
        minWidth: 120,
        cellRenderer: DownloadRawDataCell,
      },
    ],
    []
  );

  return (
    <div className='ag-theme-alpine height100'>
      <AgGridReact
        ref={setGridRef}
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        autoGroupColumnDef={autoGroupColumnDef}
        animateRows={true}
        rowGroupPanelShow={'always'}
      />
    </div>
  );
};

export default ConsoleLogTable;
