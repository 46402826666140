import { Tabs } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { StoreCategory, storeCategories } from 'src/lib/1/constant';
import { ProductGroupForCategoryDoc } from 'src/lib/3/schema-product-group-by-category';
import { observeProductGroupForCategoryDoc } from 'src/lib/4/firebase-short-cut';

// import SetStoreCategoryModal from './SetStoreCategoryModal/SetStoreCategoryModal';
import StoreCategoryTable from './StoreCategoryTable/StoreCategoryTable';

const StoreCategoryComponent: FC = () => {
  const navigate = useNavigate();
  const { tab } = useParams();
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState<ProductGroupForCategoryDoc>();

  const onTabChange = (key: string) => {
    navigate(`../${key}`);
  };

  useEffect(() => {
    if (tab) {
      setLoading(true);
      const subscription = observeProductGroupForCategoryDoc(tab).subscribe((data) => {
        setRowData(data);
        setLoading(false);
      });
      return () => {
        subscription.unsubscribe();
      };
    }
  }, [tab]);

  return (
    <div className='height100 flexColumn'>
      {/* <div className='tableButtons'>
        <SetStoreCategoryModal />
      </div> */}
      {tab && (
        <Tabs
          type='card'
          activeKey={tab}
          style={{ height: '100%', overflow: 'scroll' }}
          tabBarStyle={{ marginBottom: 0 }}
          onChange={onTabChange}
          items={Object.entries(storeCategories).map(([key, label]) => ({
            key,
            label,
            children: (
              <div className='tabBody height100'>
                <StoreCategoryTable category={tab as StoreCategory} rowData={rowData} loading={loading} />
              </div>
            ),
          }))}
        />
      )}
    </div>
  );
};

export default StoreCategoryComponent;
