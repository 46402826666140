import useSWR from 'swr';

import { getProductById } from 'src/lib/4/firebase-short-cut';

const useProductById = (id: string) => {
  const {
    data,
    isValidating: productLoading,
    error: productError,
  } = useSWR(id ? id : null, getProductById, {
    revalidateOnFocus: false,
    errorRetryCount: 2,
  });

  return {
    product: data,
    productLoading,
    productError,
  };
};

export default useProductById;
