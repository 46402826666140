import {
  CustomPromotionSectionDoc,
  PromotionSectionDoc,
  PromotionSectionItem,
  StoreCategory,
} from '@gooduncles/gu-app-schema';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { ColDef } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { FC, useCallback, useMemo } from 'react';

import { productStates, storeCategories } from 'src/lib/1/constant';
import { onValueSetterWithValidation, suppressEnterKeyEvent } from 'src/lib/6/ag-grid-util';

import DeletePromotionSectionItemRenderer from 'src/atomic-components/atoms/Button/DeletePromotionSectionItemButton';
import ProductInfoCellRenderer from 'src/components/AgGrid/CellRenderer/ProductInfoCellRenderer/ProductInfoCellRenderer';
import ProductNameCellRenderer from 'src/components/AgGrid/CellRenderer/ProductNameCellRenderer/ProductNameCellRenderer';
import AlgoliaSearch from 'src/components/AlgoriaSearch/AlgoriaSearch';
import DeleteDataCell from 'src/components/Common/DeleteDataCell/DeleteDataCell';

type PromotionSectionTableRowData =
  | PromotionSectionDoc
  | (CustomPromotionSectionDoc & {
      storeNickname: string;
    });

interface PromotionSectionTableProps {
  type: 'common' | 'custom';
  rowData: PromotionSectionTableRowData[];
  onCellValueChanged: (
    params: any,
    collection: string,
    id: string,
    key?: string,
    isNumber?: boolean,
    isEnum?: boolean
  ) => Promise<void>;
  onSelectValueChangeWapper: (params: any, value: string) => Promise<void>;
  onRowDragEndForChildren: (params: any) => Promise<void>;
  onRowDragEnd?: (params: any) => void;
}

const defaultColDef: ColDef<PromotionSectionTableRowData> = {
  sortable: true,
  resizable: true,
  filter: true,
};

const PromotionSectionTable: FC<PromotionSectionTableProps> = ({
  type,
  rowData,
  onCellValueChanged,
  onSelectValueChangeWapper,
  onRowDragEndForChildren,
  onRowDragEnd,
}) => {
  const collection = type === 'common' ? 'promotionSection' : 'customPromotionSection';
  /** 상품군 목록 */
  const columnDefs: ColDef<PromotionSectionDoc>[] = useMemo(
    () => [
      {
        field: '_id',
        headerName: '_id',
        hide: true,
      },
      {
        field: 'storeNickname',
        headerName: '매장명',
        hide: collection === 'promotionSection',
      },
      {
        field: 'positionIndex',
        headerName: '배치 순서',
        width: 120,
        rowDrag: onRowDragEnd ? true : false,
        editable: onRowDragEnd ? false : true,
        valueSetter: onRowDragEnd
          ? undefined
          : (params) => onValueSetterWithValidation(params, ['_id'], undefined, { type: 'number' }),
        onCellValueChanged: onRowDragEnd
          ? undefined
          : (params) => onCellValueChanged(params, collection, params.data._id),
      },
      {
        field: 'title',
        headerName: '제목',
        cellRenderer: 'agGroupCellRenderer',
        editable: true,
        onCellValueChanged: (params) => onCellValueChanged(params, collection, params.data._id),
      },
      {
        field: 'description',
        headerName: '설명',
        editable: true,
        onCellValueChanged: (params) => onCellValueChanged(params, collection, params.data._id, undefined, true),
      },
      {
        field: 'storeCategory',
        headerName: '업종 전용',
        hide: collection === 'customPromotionSection',
        editable: true,
        cellEditorPopup: true,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: {
          values: [...Object.keys(storeCategories), null],
        },
        filterParams: {
          valueFormatter: (params: any) => {
            const value: StoreCategory | null = params.value;
            return value ? storeCategories[value] : '업종 제한 없음';
          },
        },
        valueFormatter: (params: any) => {
          const value: StoreCategory | null = params.value;
          return value ? storeCategories[value] : '업종 제한 없음';
        },
        onCellValueChanged: (params) => onCellValueChanged(params, collection, params.data._id, undefined, false, true),
      },
      {
        width: 120,
        field: 'isActive',
        headerName: '사용 여부',
        editable: true,
        cellEditorPopup: true,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: {
          values: [true, false],
        },
        valueFormatter: ({ value }) => (value ? '🟢' : '🔴'),
        onCellValueChanged: (params) => onCellValueChanged(params, collection, params.data._id),
      },
      {
        width: 120,
        field: 'maxItemsToPreview',
        headerName: '최초 표시 수',
        editable: true,
        valueSetter: (params) => onValueSetterWithValidation(params, ['_id'], undefined, { type: 'number' }),
        onCellValueChanged: (params) => onCellValueChanged(params, collection, params.data._id),
      },
      {
        width: 120,
        field: 'direction',
        headerName: '스크롤 방향',
        editable: true,
        cellEditorPopup: true,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: {
          values: ['horizontal', 'vertical'],
          cellRenderer: ({ value }: any) => <span>{value === 'horizontal' ? '→' : '↓'}</span>,
        },
        cellRenderer: ({ value }: any) => <span>{value === 'horizontal' ? '→' : '↓'}</span>,
        onCellValueChanged: (params) => onCellValueChanged(params, collection, params.data._id),
      },
      {
        field: 'items',
        headerName: '상품 추가',
        editable: true,
        cellEditorPopup: true,
        cellEditor: (params: any) => (
          <AlgoliaSearch placeholder='상품 선택' onSearch={(value) => onSelectValueChangeWapper(params, value)} />
        ),
        suppressKeyboardEvent: suppressEnterKeyEvent,
        valueFormatter: ({ value }) => value?.length ?? 0,
      },
      {
        field: '_id',
        headerName: '삭제',
        cellRenderer: (params: any) => <DeleteDataCell collection={collection} id={params.value} />,
      },
    ],
    [collection, onCellValueChanged, onRowDragEnd, onSelectValueChangeWapper]
  );

  /** 상품 목록 */
  const detailCellRendererParams = useCallback(() => {
    const detailColumnDefs: ColDef<PromotionSectionItem>[] = [
      {
        field: 'index',
        headerName: '상품순서',
        rowDrag: true,
        width: 120,
      },
      {
        field: 'id',
        headerName: '재고',
        width: 120,
        cellRenderer: (params: any) => ProductInfoCellRenderer(params, 'state', productStates),
      },
      {
        field: 'id',
        headerName: '숨김',
        width: 96,
        cellRenderer: (params: any) =>
          ProductInfoCellRenderer(params, 'hidden', {
            true: '숨김 처리중',
            false: '',
          }),
      },
      {
        field: 'id',
        headerName: '상품코드',
        width: 120,
      },
      {
        field: 'id',
        headerName: '상품명',
        width: 300,
        cellRenderer: ProductNameCellRenderer,
      },
      {
        field: 'id',
        headerName: '가격',
        cellRenderer: (params: any) => ProductInfoCellRenderer(params, 'price'),
        width: 120,
      },
      {
        field: 'id',
        headerName: '상품군',
        cellRenderer: (params: any) => ProductInfoCellRenderer(params, 'mainTag'),
      },
      {
        field: 'id',
        headerName: '삭제',
        width: 100,
        cellRenderer: DeletePromotionSectionItemRenderer,
      },
    ];

    return {
      refreshStrategy: 'rows',
      detailGridOptions: {
        columnDefs: detailColumnDefs,
        defaultColDef,
        onRowDragEnd: onRowDragEndForChildren,
        suppressScrollOnNewData: true,
        rowDragManaged: true,
        rowDragMultiRow: true,
        rowSelection: 'multiple',
        suppressMoveWhenRowDragging: true,
        // 행 선택을 켜놓더라도 개별 셀의 내용을 복사할 수 있도록한다.
        enableCellTextSelection: true,
      },
      getDetailRowData: (params: any) => {
        const { _id, items } = params.data as PromotionSectionDoc;
        params.successCallback(items.map((item) => ({ ...item, items, _id })));
      },
    };
  }, [onRowDragEndForChildren]);

  return (
    <AgGridReact
      rowData={rowData}
      columnDefs={columnDefs}
      defaultColDef={defaultColDef}
      // rowId를 지정해주지 않으면 데이터 변경시 refresh가 발생한다.
      getRowId={(params) => params.data._id}
      // 편집 완료후 스크롤 이동을 막는다.
      suppressScrollOnNewData={true}
      // 순서 드래그
      rowDragManaged={true}
      rowDragMultiRow={true}
      rowSelection='multiple'
      animateRows={true}
      onRowDragEnd={onRowDragEnd}
      suppressMoveWhenRowDragging={true}
      // 하위노드
      keepDetailRows={true}
      masterDetail={true}
      detailRowAutoHeight={true}
      detailCellRendererParams={detailCellRendererParams}
    />
  );
};

export default PromotionSectionTable;
