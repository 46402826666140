import {
  CommerceConf,
  DeliveryTaskInfo,
  Order,
  OrderDoc,
  OrderProduct,
  OrderStatusCode,
  PickupTaskInfo,
  ProductDoc,
  ProductMeasure,
  ProductSnapshotDoc,
  StoreAddressBase,
  StoreDoc,
  StoreIssueDoc,
} from '@gooduncles/gu-app-schema';
import { message, notification } from 'antd';
import { add, differenceInSeconds, format, subDays } from 'date-fns';
import { getTasksForOrder } from 'src/utils/task-util';

import { commerceIssueCategoryTable, isProduction, productCategories, settlementDayTable } from '../1/constant';
import { formatDate, getKoreaDate, getOrderDate, orderDateFormat04, orderDateFormat05 } from '../1/date-util';
import { errorObjectToString, getDeliveryDate, handleErrorsForPromiseAll, roundToNearestTen } from '../1/util';
import { jsonToExcelFile } from '../1/xlsx-util';
import { FirebaseManager } from '../3/firebase-manager';
import { SupplierDoc } from '../3/schema-supplier';
import {
  batchEnd,
  batchStart,
  deleteDeliveryTask,
  deletePickupTask,
  getCommerceConf,
  updateOrder,
} from '../4/firebase-short-cut';
import { ConsoleLogger } from './logger';

const logger = ConsoleLogger.getInstance();

export type OrderWithStore = OrderDoc & {
  store?: StoreDoc;
  productCount?: number;
};

export type RawData = {
  // 월
  orderMonth: string;
  // 일
  orderDate: string;
  // 매장코드
  storeCode: string | null;
  // 식당명
  storeName: string | null;
  // 품목코드
  productId: string | null;
  // 구분
  category: string | null;
  // 과/면세
  taxFree: string | null;
  // 매입처1
  supplier1: string | null;
  // 품명
  fullName: string | null;
  // 판매가
  price: number | null;
  // 수량
  volume: number | null;
  // 단가
  supply: number | null;
  // 공급가
  sumOfSupply: number | null;
  // 세액
  vat: number | null;
  // 총 금액
  totalPrice: number | null;
  // 매입가
  cost: number | null;
  // 매입총액
  totalCost: number | null;
};

const firebaseManager = FirebaseManager.getInstance();
const storePath = 'store';
const productPath = 'product';
const productSnapshotPath = 'productSnapshot';

/**
 * product snapshot id의 앞부분을 생성한다.
 * 개발 서버의 경우 불필요한 데이터 생성을 막기 위해 매일의 스냅샷 생성을 하지 않으므로 고정값을 사용한다.
 * @returns
 */
export const getSnapshotIdPrefix = (date: Date) => {
  return isProduction ? formatDate(date, 'yyyy-MM-dd') : '2022-12-18';
};

/** 단가 */
export const getValueOfSupply = (price: number) => Math.round(price / 1.1);
/** 부가세 */
export const getValueAddedTax = (price: number) => price - Math.round(price / 1.1);
/** 부가세가 포함된 가격을 원래의 가격과 세금으로 변환한다 */
export const getOriginalPriceAndVAT = (price: number) => {
  const vat = getValueAddedTax(price);
  const supply = price - vat;
  return { supply, vat };
};
/** 부가세로 본래 가격 역산 */
export const getOriginalPriceFromVAT = (vat: number) => roundToNearestTen((vat / 0.1) * 1.1);

/** 수량을 단위로 표현한다. */
export const calcProductUnitVolume = (measure: ProductMeasure[], volume: number) => {
  const total = measure.reduce((acc, cur) => acc * cur.amount, 1);
  return total * volume + measure[0].unit;
};

/**
 * 필요한 세금 항목을 계산한다.
 * return { supply: 공급단가, sumOfSupply: 총 공급가, vat: 부가세 }
 */
const getValuesForCalc = (price: number, volume: number, taxFree: boolean) => {
  // 면세항목인 경우 세금계산을 별도로 하지 않는다.
  if (taxFree) {
    return {
      supply: price,
      sumOfSupply: price * volume,
      vat: 0,
    };
  }
  // 상품가격 / 1.1 = 단가
  const supply = getValueOfSupply(price);
  // 단가 * 수량 = 공급가
  const sumOfSupply = supply * volume;

  // 상품가격 * 수량 = 판매가
  const sumOfPrice = price * volume;
  const vat = getValueAddedTax(sumOfPrice);
  return {
    supply,
    sumOfSupply,
    vat,
  };
};

const convertOrderToExcelRows = async (orders: OrderWithStore[], statusList: OrderStatusCode[]) => {
  const promises = orders
    .filter((order) => (statusList.length > 0 ? statusList.includes(order.orderStatus) : true))
    .map(async (order) => {
      const store = await firebaseManager.getDoc<StoreDoc>(`${storePath}/${order.storeId}`);
      if (!store) {
        return null;
      }

      if (!store.storeCode) {
        throw new TypeError(store.storeName);
      }

      const nestedAsync = order.products.map(async (orderProduct) => {
        const product = orderProduct.snapshotId
          ? await firebaseManager.getDoc<ProductSnapshotDoc>(`${productSnapshotPath}/${orderProduct.snapshotId}`)
          : await firebaseManager.getDoc<ProductDoc>(`${productPath}/${orderProduct.productId}`);
        if (!product) {
          return null;
        }

        return {
          // 식당코드
          storeCode: store.storeCode,
          // 식당명
          storeName: store.storeNickname ?? store.storeName,
          // 품목코드
          productId: product.productId,
          // 구분
          category: product.categories
            .filter((cg) => cg !== 'all')
            .map((category) => productCategories[category])
            .join(', '),
          // 과/면세
          taxFree: product.taxFree ? '면세' : '과세',
          // 매입처1
          supplier1: product.suppliers[0],
          // 매입처2
          supplier2: product.suppliers[1] ?? '',
          // 품명
          fullName: product.fullName,
          // 판매가
          price: product.price,
          // 수량
          volume: orderProduct.volume,
          // 총 금액
          totalPrice: product.price * orderProduct.volume,
          // 매입가
          cost: product.cost,
          // 매입총액
          totalCost: product.cost * orderProduct.volume,
        };
      });

      return (await Promise.all(nestedAsync)).filter((product) => product !== null);
    });

  const rows = (await handleErrorsForPromiseAll(promises)).filter((i) => i !== null).flat();
  return rows;
};

/**
 * 배송비를 기타 품목으로 추가한다.
 */
const convertDeliveryFeeToRawData = (data: any, deliveryFee: number) => {
  const { supply, vat } = getOriginalPriceAndVAT(deliveryFee);
  return {
    ...data,
    // 품목코드
    productId: 'P10000',
    // 구분
    category: '기타',
    // 과/면세
    taxFree: '과세',
    // 매입처1
    supplier1: '기타',
    // 품명
    fullName: '※배송비',
    // 판매가
    price: deliveryFee,
    // 수량
    volume: 1,
    // 단가
    supply,
    // 공급가
    sumOfSupply: supply,
    // 세액
    vat,
    // 총 금액
    totalPrice: deliveryFee,
    // 매입가
    cost: null,
    // 매입총액
    totalCost: null,
  };
};

const convertOrderForRawData = async (
  orders: OrderWithStore[],
  deliveryDateRules: number[],
  statusList: OrderStatusCode[],
  suppliers: SupplierDoc[]
) => {
  const promises = orders
    .filter((order) => (statusList.length > 0 ? statusList.includes(order.orderStatus) : true))
    .map(async (order) => {
      const store = await firebaseManager.getDoc<StoreDoc>(`${storePath}/${order.storeId}`);
      if (!store) {
        return null;
      }

      if (!store.storeCode) {
        throw new TypeError(store.storeName);
      }

      const nestedAsync = order.products.map(async (orderProduct) => {
        const product = orderProduct.snapshotId
          ? await firebaseManager.getDoc<ProductSnapshotDoc>(`${productSnapshotPath}/${orderProduct.snapshotId}`)
          : await firebaseManager.getDoc<ProductDoc>(`${productPath}/${orderProduct.productId}`);
        if (!product) {
          return null;
        }

        const { supply, sumOfSupply, vat } = getValuesForCalc(product.price, orderProduct.volume, product.taxFree);
        const weekday = new Date(order.orderDate).getDay();
        const deliveryDate = getDeliveryDate(deliveryDateRules[weekday], new Date(order.orderDate));
        const supplier = suppliers.find((s) => s._id === product.suppliers[0]);

        return {
          // 월
          orderMonth: orderDateFormat05(deliveryDate),
          // 일
          orderDate: orderDateFormat04(deliveryDate),
          // 매장코드
          storeCode: store.storeCode,
          // 식당명
          storeName: store.storeNickname ?? store.storeName,
          // 품목코드
          productId: product.productId,
          // 구분
          category: product.categories
            .filter((cg) => cg !== 'all')
            .map((category) => productCategories[category])
            .join(', '),
          // 과/면세
          taxFree: product.taxFree ? '면세' : '과세',
          // 매입처1
          supplier1: supplier?.name ?? null,
          // 품명
          fullName: product.fullName,
          // 판매가
          price: product.price,
          // 수량
          volume: orderProduct.volume,
          // 단가
          supply,
          // 공급가
          sumOfSupply,
          // 세액
          vat,
          // 총 금액
          totalPrice: product.price * orderProduct.volume,
          // 매입가
          cost: product.cost,
          // 매입총액
          totalCost: product.cost * orderProduct.volume,
        };
      });

      const rows = (await Promise.all(nestedAsync)).filter((product) => product !== null);
      const extraData =
        rows?.[0] && order.deliveryFee && order.deliveryFee > 0
          ? convertDeliveryFeeToRawData(rows[0], order.deliveryFee)
          : null;
      return extraData ? [...rows, extraData] : rows;
    });

  const rows = (await handleErrorsForPromiseAll(promises)).filter((i) => i !== null).flat();
  return rows;
};

/**
 * 이슈를 매출 raw 데이터로 변환
 * @param storeIssues
 * @returns
 */
export const storeIssueToRawData = (storeIssues: StoreIssueDoc[]) => {
  return storeIssues.map((storeIssue) => {
    const supply = storeIssue.supplyPrice ?? 0;
    const vat = storeIssue.tax ?? 0;
    const price = supply + vat;
    const volume = storeIssue.volume ?? 1;
    const taxFree =
      // 공급가액이 없으면 '기타'
      supply === 0
        ? '기타'
        : // 공급가액이 있고, 부가세가 없으면 '면세'
        vat === 0
        ? '면세'
        : // 공급가액이 있고, 부가세가 있으면 '과세'
          '과세';

    const prefix = storeIssue.category ? commerceIssueCategoryTable[storeIssue.category] ?? '' : '';
    const fullName = storeIssue.message ? prefix + storeIssue.message : storeIssue.memo;

    return {
      // 월
      orderMonth: orderDateFormat05(storeIssue.date),
      // 일
      orderDate: orderDateFormat04(storeIssue.date),
      // 매장코드
      storeCode: storeIssue.storeCode,
      // 식당명
      storeName: storeIssue.storeName,
      // 품목코드
      productId: 'P10000',
      // 구분
      category: '기타',
      // 과/면세
      taxFree,
      // 매입처1
      supplier1: storeIssue.supplier ?? '기타',
      // 품명
      fullName,
      // 판매가
      price: price !== 0 ? price : null,
      // 수량
      volume,
      // 단가
      supply,
      // 공급가
      sumOfSupply: supply * volume,
      // 세액
      vat: storeIssue.tax ?? 0,
      // 총 금액
      totalPrice: price !== 0 ? price * volume : null,
      // 매입가
      cost: null,
      // 매입총액
      totalCost: null,
    };
  });
};

export const downloadOrderProductsToExcelOld = async (orders: OrderWithStore[], statusList: OrderStatusCode[]) => {
  const products = await convertOrderToExcelRows(orders, statusList);
  const heading = [
    [
      '식당코드',
      '식당명',
      '품목코드',
      '구분',
      '과/면세',
      '매입처1',
      '매입처2',
      '품명',
      '판매가',
      '수량',
      '총 금액',
      '매입가',
      '매입총액',
    ],
  ];
  jsonToExcelFile(products, '주문내역(old)', heading);
  return products.length;
};

export const downloadOrderProductsToExcel = async (
  orders: OrderWithStore[],
  issueRawData: RawData[],
  options: {
    deliveryDateRules: number[];
    statusList: OrderStatusCode[];
  },
  suppliers: SupplierDoc[]
) => {
  const { statusList, deliveryDateRules } = options;
  const productRawData = await convertOrderForRawData(orders, deliveryDateRules, statusList, suppliers);
  const mergedRawData = [...productRawData, ...issueRawData];
  const heading = [
    [
      '월',
      '일',
      '매장코드',
      '식당명',
      '품목코드',
      '구분',
      '과/면세',
      '매입처1',
      '품명',
      '판매가',
      '수량',
      '단가',
      '공급가',
      '세액',
      '총 금액',
      '매입가',
      '매입총액',
    ],
  ];
  jsonToExcelFile(mergedRawData, '주문내역', heading);
  return productRawData.length;
};

export type ProductWithOrder = OrderProduct & {
  order: OrderDoc;
};

/** 주문에 속한 상품의 개별 수량을 변경시 유효성을 검증한다. */
export const productVolumeChangeValidator = (params: any) => (value: number) => {
  const data: ProductWithOrder = params.data;
  if (value === 0) {
    message.error('유효한 값이 아닙니다.');
    return false;
  }

  if (!data.productId) {
    message.error('상품 id가 없습니다.');
    return false;
  }

  if (![OrderStatusCode.DELIVERED, OrderStatusCode.ACCEPTED].includes(data.order.orderStatus)) {
    message.error('수락완료 또는 배송완료 주문만 수량을 변경할 수 있습니다.');
    return false;
  }

  const orderDay = new Date(data.order.orderDate).getDay();
  const today = new Date().getDay();
  const unsettled = settlementDayTable[today].some((settlementDay) => settlementDay === orderDay);
  if (!unsettled) {
    message.error('정산일이 지난 주문입니다.');
    return false;
  }

  if (data.order.settledAt) {
    message.error('정산완료된 주문입니다.');
    return false;
  }

  return true;
};

/**
 * 주문 시간과 배송일 설정에 따라
 * 현재 주문 상태와 snapshotId('수락완료' 주문만 해당)를 반환한다.
 */
export const getCurrentOrderStatus = (deliveryDateRules: number[]): [OrderStatusCode, string | null] => {
  const now = new Date();
  const nowDay = now.getDay();
  const yesterWeekDay = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1).getDay();
  const deliveredAt = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 9, 0, 0);
  const acceptedAt = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 5, 0);
  // 현재 시간이 배송 완료시간보다 빠른가?
  const isBeforeDeliveredAt = differenceInSeconds(now, deliveredAt) < 0;
  // 현재 시간이 주문 수락시간보다 늦은가?
  const isAfterAccecptedAt = differenceInSeconds(now, acceptedAt) > 0;
  // 다음날이 배송휴무인가? 오늘의 규칙일이 1보다 크면 다음날은 휴무일이다.
  const isNextDayoff = deliveryDateRules[nowDay] > 1;
  // 오늘이 배송휴무인가? 어제의 규칙일이 1보다 크면 오늘은 휴무일이다.
  const isNowDayoff = deliveryDateRules[yesterWeekDay] > 1;

  // 1. 주문은 수락했으며 배송 완료 전 주문인가? (23 ~ 00) && 다음날이 배송 휴무가 아닌가?
  if (isAfterAccecptedAt && !isNextDayoff) {
    const snapshotIdPrefix = getSnapshotIdPrefix(now);
    // '수락완료' 주문
    return [OrderStatusCode.ACCEPTED, snapshotIdPrefix];
  }

  // 2. 주문은 수락했으나 배송 완료 전 주문인가? (00 ~ 09) && 오늘이 배송 휴무가 아닌가?
  if (isBeforeDeliveredAt && !isNowDayoff) {
    const snapshotIdPrefix = getSnapshotIdPrefix(subDays(now, 1));
    // '수락완료' 주문
    return [OrderStatusCode.ACCEPTED, snapshotIdPrefix];
  }

  // 수락전('주문완료') 주문
  return [OrderStatusCode.SUBMITTED, null];
};

/**
 * '수락완료'상태의 주문을 수정 또는 만드는 경우, snapshotId를 수동으로 넣어야하는 경우가 존재한다.
 */
const addSnapshotIfNeeded = (
  products: OrderProduct[],
  snapshotIdPrefix: string | null,
  orderStatus: OrderStatusCode
) => {
  // '주문완료' 주문의 경우 snapshotId를 기록하지 않는다.(snapshotId는 자동 주문수락 과정에서 채워진다.)
  if (orderStatus === OrderStatusCode.SUBMITTED) {
    return products;
  }

  // '수락완료' 주문이지만 snapshotIdPrefix가 없는 경우는 에러를 발생시킨다.
  if (snapshotIdPrefix === null) {
    throw new Error("'수락완료' 주문 생성에 필요한 snapshotIdPrefix가 없습니다.");
  }

  // 여기서 스냅샷을 추가하는 경우는 오직 '수락완료' 주문으로,
  // 이미 상품의 가격은 스냅샷과 동일하다고 가정할 수 있다.(관리자가 이후에 임의로 바꾸지 않았다면...)
  // 원칙적으로 수량변경을 주문 편집기에서 하면 안되지만.
  // 만약 수량변경을 하는 경우 이미 있는 스냅샷 정보가 있을 것이다.
  return products.map((p) => ({
    ...p,
    // 이미 snapshotId가 있는 경우는 그대로 사용한다.
    snapshotId: p.snapshotId ? p.snapshotId : `${snapshotIdPrefix}-${p.productId}`,
    priceDiff: p.priceDiff ? p.priceDiff : 0,
    snapshotPrice: p.snapshotPrice ? p.snapshotPrice : p.price,
  }));
};

/**
 * 배송비를 계산합니다.
 */
export const getDeliveryFee = (store: StoreDoc | null, conf: CommerceConf, totalAmount: number) => {
  if (store?.chargeDeliveryFee && totalAmount < conf.minAmountForFreeDelivery) {
    return conf.deliveryFee;
  }
  return 0;
};

/**
 * 수락된 주문의 배송일을 계산합니다.
 */
export const getDateForAcceptedOrder = (commerceConf: CommerceConf) => {
  const now = getKoreaDate();
  const weekday = now.getDay();
  const deliveryDate = add(now, { days: commerceConf.deliveryDateRules[weekday] });
  return formatDate(deliveryDate, 'yyyy-MM-dd');
};

/**
 * 관리자 전용 주문 생성
 * : 생성가능한 주문 상태는 '주문완료', '수락완료' 두가지이다.
 * 주문 생성 시간에 따라 주문 상태가 결정된다.
 * 1. 주문 생성 시간이 23:05 ~ 00:00 & 00:00 ~ 09:00 사이인 경우 사이인 경우 '수락완료' 주문이 생성된다.
 *  - 위 시간대를 두 구간으로 나눈 이유는 정각을 기준으로 배송 휴무일 계산이 달라지기 때문이며,
 *   배송 휴무일 계산에 따라 '수락완료'가 아닌 '주문완료'가 될 수있다.
 * 2. 주문 생성 시간이 09:00 ~ 23:05 사이인 경우 '주문완료' 주문이 생성된다.(이 시간대에는 '수락완료' 주문은 불가능하다.)
 *
 * '주문완료' 주문의 상품은 snapshotId를 가지지 않는다.
 * '수락완료' 주문의 상품은 snapshotId를 가진다.
 */
export const createOrderData = async (
  userId: string,
  store: StoreDoc,
  orderProducts: OrderProduct[]
): Promise<Order> => {
  const commerceConf = await getCommerceConf();
  if (!commerceConf) {
    throw new Error('앱 설정 값을 가져오지 못했습니다.');
  }
  const [orderStatus, snapshotIdPrefix] = getCurrentOrderStatus(commerceConf.deliveryDateRules);
  const orderDate = getOrderDate();
  const products = addSnapshotIfNeeded(orderProducts, snapshotIdPrefix, orderStatus);
  const totalAmount = orderProducts.reduce((acc, product) => {
    return acc + product.volume * (product.snapshotPrice ?? product.price);
  }, 0);
  const deliveryFee = getDeliveryFee(store, commerceConf, totalAmount);

  // 수락된 주문이라면 date를 채워준다.
  const date = orderStatus === OrderStatusCode.ACCEPTED ? getDateForAcceptedOrder(commerceConf) : null;

  const storeAddress: StoreAddressBase = {
    address: store.address ?? '',
    roadAddress: store.roadAddress ?? '',
    jibunAddress: store.jibunAddress ?? '',
    sido: store.sido ?? '',
    sigungu: store.sigungu ?? '',
    bname: store.bname ?? '',
    hname: store.hname ?? '',
    roadname: store.roadname ?? '',
  };

  const invoiceDetails = {
    settledAt: null,
    invoiceId: null,
  };

  const deliveryDetails: DeliveryTaskInfo = {
    deliveryPartnerId: null,
    deliveryPartnerName: null,
    deliverySpotId: null,
    deliveryTaskFinishedAt: null,
    deliveredAt: null,
    courierId: null,
    courierName: null,
  };

  const pickupDetails: PickupTaskInfo = {
    pickupPartnerId: null,
    pickupPartnerName: null,
    pickupTaskFinishedAt: null,
  };

  const grandTotal = totalAmount + deliveryFee;

  return {
    date,
    orderDate,
    products,
    orderStatus,
    totalAmount,
    totalAmountDiff: null,
    paidAmount: grandTotal,
    grandTotal,
    userId,
    storeId: store._id,
    deliveryFee,
    storeAddress,
    ...invoiceDetails,
    ...deliveryDetails,
    ...pickupDetails,
  };
};

/**
 * 관리자 전용 주문 수정
 * : 수정가능한 주문 상태는 '주문완료', '수락완료' 두가지이다.
 *   '수락완료'주문의 경우 snapshotId를 수동으로 넣어야하는 경우가 존재한다.
 */
export const createOrderDataForModify = async (
  store: StoreDoc,
  order: OrderDoc,
  orderProducts: OrderProduct[]
): Promise<{
  products: OrderProduct[];
  totalAmount: number;
  paidAmount: number;
  grandTotal: number;
  deliveryFee: number;
}> => {
  const commerceConf = await getCommerceConf();
  if (!commerceConf) {
    throw new Error('앱 설정 값을 가져오지 못했습니다.');
  }
  const date = new Date(order.orderDate);
  const snapshotIdPrefix = getSnapshotIdPrefix(date);
  const products = addSnapshotIfNeeded(orderProducts, snapshotIdPrefix, order.orderStatus);
  const totalAmount = products.reduce((acc, product) => {
    return acc + product.volume * (product.snapshotPrice ?? product.price);
  }, 0);
  const deliveryFee = getDeliveryFee(store, commerceConf, totalAmount);

  return {
    products,
    totalAmount,
    paidAmount: totalAmount + deliveryFee,
    grandTotal: totalAmount + deliveryFee,
    deliveryFee,
  };
};

/**
 * 주문일로부터 배송(발주) 날짜를 계산한다.
 * 배송일: yyyy-MM-dd'T'08:00:00+0900
 * 발주 or 배송 동선 기준일: yyyy-MM-dd
 */
export const getFinishDateFromOrder = async (
  orderDate0: string,
  dateFormat: "yyyy-MM-dd'T'08:00:00+0900" | 'yyyy-MM-dd'
) => {
  const orderDate = new Date(orderDate0);
  const weekday = orderDate.getDay();
  const commerceConf = await getCommerceConf();
  if (!commerceConf) {
    throw new Error('앱 설정 값을 가져오지 못했습니다.');
  }
  const days = commerceConf.deliveryDateRules[weekday];
  return format(new Date(orderDate.setDate(orderDate.getDate() + days)), dateFormat);
};

/**
 * 세율이 10%인 경우 가격에서 세금을 계산한다.
 */
export const calculatePreTaxPrice = (priceWithTax: number, taxFree = false) => {
  if (taxFree) {
    return {
      price: priceWithTax,
      tax: 0,
    };
  }
  // 세전 가격 = 세후 가격 / 1.1
  const preTaxPrice = Math.round(priceWithTax / 1.1);
  const tax = priceWithTax - preTaxPrice;

  return {
    price: preTaxPrice,
    tax,
  };
};

/**
 * 이미 승인되었으며, 업무가 할당된 주문을 수정하는 경우인지 확인한다.
 * (파트너에게 관련 업무가 할당된 주문인 경우, 함께 변경해야한다.)
 */
export const isTaskAssignedAceeptedOrder = (order: OrderDoc) => {
  return order.orderStatus === OrderStatusCode.ACCEPTED && (order.pickupPartnerId || order.deliveryPartnerId);
};

/**
 * 주문을 수동으로 취소 또는 거부합니다.
 */
export const cancelOrder = async (
  order: OrderDoc,
  orderStatus: OrderStatusCode.CANCELED | OrderStatusCode.REJECTED
) => {
  try {
    batchStart();
    await updateOrder(
      order._id,
      {
        orderStatus,
      },
      true
    );
    const extraMessages = [];
    if (order.pickupPartnerId || order.deliveryPartnerId) {
      const { pickupTask, deliveryTask } = await getTasksForOrder(order);
      if (pickupTask) {
        await deletePickupTask(pickupTask._id, true);
        extraMessages.push('픽업 업무 취소');
      }
      if (deliveryTask) {
        await deleteDeliveryTask(deliveryTask._id, true);
        extraMessages.push('배송 업무 취소');
      }
    }
    await batchEnd();
    const finishMessage = `주문 ${order._id}을 ${
      orderStatus === OrderStatusCode.CANCELED ? '취소' : '거부'
    }했습니다. ${extraMessages.join(', ')}`;
    logger.logConsole(finishMessage);
    notification.success({
      message: '주문 처리 완료',
      description: finishMessage,
    });
  } catch (error) {
    console.error(error);
    const errorMessage = errorObjectToString(error);
    logger.logConsole(`주문 취소 또는 거부 과정에서 에러 발생\nerror: ${errorMessage}`, {
      level: 'error',
    });
  }
};
