/**
 * {@link https://www.ag-grid.com/react-charts/gallery/stacked-bar 쌓인 막대 그래프}
 * {@link https://www.ag-grid.com/react-charts/api-explorer/ 차트 옵션}
 */
import { AgChartOptions } from 'ag-charts-community';
import { AgChartsReact } from 'ag-charts-react';
import { groupBy } from 'lodash-es';
import { FC, useEffect, useState } from 'react';

import { MonthlyOrderStatsForStoreDoc } from 'src/lib/2/schema';

interface MonthlyOrderStackedBarProps {
  monthlyOrderForStoreStats: MonthlyOrderStatsForStoreDoc[] | undefined;
}

const initialChartOptions = (monthlyOrderStats: MonthlyOrderStatsForStoreDoc[]) => {
  const groupByStore = groupBy(monthlyOrderStats, 'storeName');
  // x축은 매장의 수
  const data = Object.entries(groupByStore)
    .map(([storeName, stats]) => {
      const byDate = Object.fromEntries(stats.map((m) => [`${m.year}-${m.month}`, m.orderSum]));
      const total = stats.reduce((acc, cur) => acc + cur.orderSum, 0);
      return {
        storeName,
        total,
        ...byDate,
      };
    })
    .sort((a, b) => b.total - a.total);
  const groupByDate = groupBy(monthlyOrderStats, (m) => `${m.year}-${m.month}`);
  const series: AgChartOptions['series'] = Object.keys(groupByDate).map((date) => ({
    type: 'bar',
    xKey: 'storeName',
    yKey: date,
    yName: date,
    stacked: true,
  }));

  const options: AgChartOptions = {
    // autoSize: false,
    width: 1280,
    height: data.length * 28,
    data,
    theme: {
      overrides: {
        bar: {
          series: {
            strokeWidth: 0,
            highlightStyle: {
              series: {
                strokeWidth: 1,
                dimOpacity: 0.3,
              },
            },
          },
        },
      },
    },
    title: {
      text: '월별 매장 주문 통계',
      fontSize: 18,
    },
    footnote: {
      text: '지난 달까지 집계된 데이터입니다.',
    },
    series,
    axes: [
      {
        type: 'category',
        position: 'left',
      },
      {
        type: 'number',
        position: 'top',
        nice: false,
      },
    ],
    legend: {
      position: 'top',
    },
  };
  return options;
};

const MonthlyOrderStackedBar: FC<MonthlyOrderStackedBarProps> = ({ monthlyOrderForStoreStats }) => {
  const [chartOptions, setChartOptions] = useState<AgChartOptions>({});
  useEffect(() => {
    if (monthlyOrderForStoreStats) {
      setChartOptions(initialChartOptions(monthlyOrderForStoreStats));
    }
  }, [monthlyOrderForStoreStats]);

  if (!monthlyOrderForStoreStats) return <div>데이터가 존재하지 않습니다.</div>;

  return <AgChartsReact options={chartOptions} />;
};

export default MonthlyOrderStackedBar;
