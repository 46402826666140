import { ProductStockHistoryDoc } from '@gooduncles/gu-app-schema';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { ColDef, ColGroupDef } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { FC, useMemo } from 'react';
import { stockHistoryTypeOrder } from 'src/utils/product-stock-history-util';

import { formatDate } from 'src/lib/1/date-util';
import { formatNumber } from 'src/lib/1/util';

import ProductName from 'src/components/Common/ProductName';

const defaultColDef = {
  sortable: true,
  resizable: true,
  filter: true,
};

interface ProductStockIssueTableProps {
  rowData: ProductStockHistoryDoc[];
}

const ProductStockIssueTable: FC<ProductStockIssueTableProps> = ({ rowData }) => {
  const columnDefs: (ColDef<ProductStockHistoryDoc> | ColGroupDef<ProductStockHistoryDoc>)[] = useMemo(
    () => [
      { field: '_id', hide: true },
      {
        headerName: 'timestamp',
        field: 'timestamp',
        sort: 'desc',
        valueFormatter: (params) => formatDate(params.value, 'yyyy-MM-dd HH:mm:ss'),
      },
      {
        field: 'type',
        hide: true,
      },
      {
        headerName: '상품',
        field: 'productId',
        cellRenderer: (params: any) => {
          const value = params.value;
          return <ProductName productId={value} />;
        },
      },
      {
        headerName: '메모',
        field: 'reason',
        minWidth: 80,
        valueFormatter: (params) => {
          const data = params.data as ProductStockHistoryDoc;
          const prefix = data?.userEmail === null ? '[자동] ' : '';
          return `${prefix}${params.value ?? ''}`;
        },
      },
      {
        headerName: '변동 값',
        field: 'stock',
        aggFunc: (params) => {
          return Math.abs(
            params.rowNode.allLeafChildren.reduce((acc, cur) => {
              if (cur.data) {
                if (['INBOUND', 'CANCEL_OUTBOUND'].includes(cur.data.type ?? '')) return acc - cur.data.stock;
                return acc + cur.data.stock;
              }
              return acc;
            }, 0)
          );
        },
        minWidth: 96,
        cellRenderer: (params: any) => {
          const formattedValue = params.value === Infinity ? '∞' : formatNumber(params.value);
          const type: string = params.data?.type;
          return <span className={`stock-cell ${type?.toLowerCase() ?? ''}`}>{formattedValue}</span>;
        },
      },
      {
        headerName: '변경 내용',
        valueGetter: (params: any) => {
          if (params.node.group) {
            const history = params.node.allLeafChildren
              .sort((a: any, b: any) => a.data.afterStock - b.data.afterStock)
              .sort(
                (a: any, b: any) =>
                  stockHistoryTypeOrder.indexOf(a.data.type) - stockHistoryTypeOrder.indexOf(b.data.type)
              );
            if (history.length === 1) return `${history[0].data.beforeStock} → ${history[0].data.afterStock}`;
            const first = history[0].data as ProductStockHistoryDoc;
            const last = history[history.length - 1].data as ProductStockHistoryDoc;
            return `${last.beforeStock} → ${first.afterStock}`;
          }
          const data = params.data;
          return data ? `${data.beforeStock} → ${data.afterStock}` : '';
        },
        cellRenderer: (params: any) => <span>{params.value}</span>,
      },
      {
        headerName: '사용자',
        field: 'userEmail',
        valueFormatter: (params) => (params.value ? params.value.split('@')[0] : ''),
      },
    ],
    []
  );
  return (
    <div className='ag-theme-alpine height100'>
      <AgGridReact
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        // 행 선택을 켜놓더라도 개별 셀의 내용을 복사할 수 있도록한다.
        enableCellTextSelection={true}
      />
    </div>
  );
};

export default ProductStockIssueTable;
