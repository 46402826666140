import { UserDoc } from '@gooduncles/gu-app-schema';
import { message } from 'antd';
import { User, UserCredential } from 'firebase/auth';
import { FC, PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { getPublicIp, sessionId } from '../lib/1/util';
import { FirebaseManager } from '../lib/3/firebase-manager';
import { ConsoleLogger } from '../lib/5/logger';
import { observePartnersUser } from 'src/lib/4/firebase-short-cut';

import usePartners from 'src/redux/hooks/usePartners';

const logger = ConsoleLogger.getInstance();

type AuthUser = {
  uid: string;
  email: string | null;
  name: string | null;
  provider: string;
  photoUrl: string | null;
  stripeRole: string | object;
  token: string;
};

interface AuthContext {
  user?: UserDoc;
  authUser: false | AuthUser | null;
  authLoading: boolean;
  isLoggedIn: boolean;
  createUserWithEmailAndPassword: (email: string, password: string) => Promise<UserCredential>;
  signinWithEmail: (email: string, password: string) => Promise<UserCredential>;
  signout: () => Promise<void>;
  setAuthLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const authContext = createContext<AuthContext | null>(null);
const firebaseManager = FirebaseManager.getInstance();
const userPath = 'user';
const updateUser = (uid: string, userDoc: Partial<UserDoc>) => firebaseManager.updateDoc(userPath, uid, userDoc);

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(authContext);
  if (context == null) {
    throw new Error('Out of context: authProvider is not set');
  }
  return context;
};

const useProvideAuth = () => {
  const navigate = useNavigate();
  // 1. 최초 진입시 유저 정보는 null이다.
  const [authUser, setAuthUser] = useState<AuthUser | null | false>(null);
  const [authLoading, setAuthLoading] = useState(true);
  const [user, setUser] = useState<UserDoc>();
  const isLoggedIn = useMemo(() => !!user?._id, [user?._id]);
  const authUserId = useMemo(() => authUser && authUser.uid, [authUser]);
  const { onSetPartnersUsers } = usePartners();

  const createUserWithEmailAndPassword = (email: string, password: string) => {
    setAuthLoading(true);
    return firebaseManager.createUserWithEmailAndPassword(email, password);
  };

  const signinWithEmail = (email: string, password: string) => {
    setAuthLoading(true);
    return firebaseManager.signIn(email, password);
  };

  const signout = useCallback(() => {
    navigate('/');
    return firebaseManager.signOut();
  }, [navigate]);

  /**
   * 로그인 상태 변화에 따라 유저 정보에 변동이 있으면
   * 해당 내용에 따른 authUser의 상태를 업데이트한다.
   */
  const handleUser = useCallback(async (authUser: User | null) => {
    try {
      setAuthLoading(true);
      getPublicIp().then((ip) => {
        logger.setPublicIp(ip);
      });

      if (authUser) {
        const user = await formatUser(authUser);
        const { email, uid } = user;
        if (email === null) {
          throw new Error('email is null');
        }

        // 1. firebase의 가장 기본 user 정보를 업데이트한다.
        // 사용자의 auth정보에서 이메일이 변경된 경우에 해당할 수 있다.
        const userDoc: Partial<UserDoc> = {
          email,
          ip: logger.getPublicIp(),
          sessionId,
        };
        setAuthUser(user);

        await updateUser(uid, userDoc);
        setAuthLoading(false);
        return user;
      }
    } catch (error) {
      console.error(error);
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error('알 수 없는 에러 발생');
      }
    }

    // 위에서 return에 실패한 경우
    logger.setLoggerUser(null);
    setAuthUser(false);
    setAuthLoading(false);
    return false;
  }, []);

  // 최초 앱 실행후 auth를 Provide하는 순간부터
  // 하위 컴포넌트를 기준으로
  // 로그인 상태변화는 계속 관찰상태에 놓인다.
  useEffect(() => {
    const subscription = firebaseManager.observeAuthState().subscribe(handleUser);
    return () => subscription.unsubscribe();
  }, [handleUser]);

  useEffect(() => {
    if (authUserId) {
      const subscription = firebaseManager.observeDoc<UserDoc>(`${userPath}/${authUserId}`).subscribe(async (user0) => {
        if (!user0) {
          return;
        }
        const isAuthorized = ['manager', 'admin'].includes(user0.role);
        if (!isAuthorized) {
          message.error('사용권한이 없습니다. 로그아웃 합니다.');
          logger.logConsole('사용권한이 없습니다. 로그아웃 합니다.', {
            level: 'error',
          });
          await firebaseManager.signOut();
          return;
        }
        setUser(user0);
        logger.setLoggerUser(user0);
        logger.logConsole(`사용자 로그인: ${user0.email}`);
      });

      return () => {
        setUser(undefined);
        subscription.unsubscribe();
      };
    } else {
      setUser(undefined);
    }
  }, [authUserId]);

  useEffect(() => {
    if (isLoggedIn) {
      const subscription = observePartnersUser().subscribe(onSetPartnersUsers);
      return () => subscription.unsubscribe();
    }
  }, [isLoggedIn, onSetPartnersUsers]);

  return {
    user,
    authUser,
    authLoading,
    isLoggedIn,
    createUserWithEmailAndPassword,
    signinWithEmail,
    signout,
    setAuthLoading,
  };
};

const getStripeRole = async () => {
  await firebaseManager.getCurrentUser()?.getIdToken(true);
  const decodedToken = await firebaseManager.getCurrentUser()?.getIdTokenResult();

  return decodedToken?.claims.stripeRole || 'free';
};

const formatUser = async (user: User): Promise<AuthUser> => {
  const token = await user.getIdToken();
  return {
    uid: user.uid,
    email: user.email,
    name: user.displayName,
    provider: user.providerData[0].providerId,
    photoUrl: user.photoURL,
    stripeRole: await getStripeRole(),
    token,
  };
};
