import useSWR from 'swr';

import { getSupplierById } from 'src/lib/4/firebase-short-cut';

const getSuppliersByIds = async (ids: string[]) => {
  const suppliers = await Promise.all(ids.map((id) => getSupplierById(id)));
  return suppliers;
};

const useSuppliersByIds = (ids: string[] | undefined) => {
  const {
    data,
    isValidating: suppliersLoading,
    error: suppliersError,
  } = useSWR(ids ? [ids] : null, getSuppliersByIds, {
    revalidateOnFocus: false,
    errorRetryCount: 2,
  });

  return {
    suppliers: data,
    suppliersLoading,
    suppliersError,
  };
};

export default useSuppliersByIds;
