import { FC } from 'react';

import { useTitle } from 'src/hooks/useTitle';

import ConsoleLog from 'src/components/ConsoleLog/ConsoleLog';

const ConsoleLogPage: FC<{ title: string }> = ({ title }) => {
  useTitle(`GU 관리자 | ${title}`);
  return <ConsoleLog />;
};

export default ConsoleLogPage;
