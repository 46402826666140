import { AimOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { FC, useCallback, useEffect, useState } from 'react';
import { v4 } from 'uuid';

import { createMarker } from 'src/lib/1/ncp-map-api-util';

import useNaverMapApi from 'src/hooks/useNaverMapApi';

import classes from './NaverMapBox.module.scss';

interface NaverMapBoxProps {
  lat?: number;
  lng?: number;
  title?: string;
  setCoords?: (lat: number, lng: number) => void;
}

const NaverMapBox: FC<NaverMapBoxProps> = ({ lat = 37.5123525, lng = 127.05323, title = 'undefined', setCoords }) => {
  const [marker, setMarker] = useState<naver.maps.Marker | null>(null);
  const { scriptLoaded, map } = useNaverMapApi(process.env.REACT_APP_NCP_CLIENT_ID, 18);
  const onSetMapCenter = useCallback(() => {
    if (map) {
      map.panTo(new naver.maps.LatLng(lat, lng));
    }
  }, [lat, lng, map]);

  useEffect(() => {
    if (map) {
      map.setCenter(new naver.maps.LatLng(lat, lng));
      map.autoResize();
      return () => {
        map.destroy();
      };
    }
  }, [lat, lng, map]);

  useEffect(() => {
    if (map) {
      if (marker === null) {
        const newMarker = createMarker(
          map,
          lat,
          lng,
          v4(),
          {
            title,
            body: 0,
            color: 300,
          },
          !!setCoords
        );
        setMarker(newMarker);
        if (setCoords) {
          naver.maps.Event.addListener(newMarker, 'dragend', (e) => {
            const targetMarker = e.overlay as naver.maps.Marker;
            const { x: lng, y: lat } = targetMarker.getPosition();
            setCoords(lat, lng);
          });
        }
        newMarker.setMap(map);

        return () => {
          naver.maps.Event.clearListeners(newMarker, 'dragend');
          newMarker.setMap(null);
        };
      } else {
        marker.setPosition(new naver.maps.LatLng(lat, lng));
        marker.setDraggable(!!setCoords);
        if (setCoords) {
          naver.maps.Event.addListener(marker, 'dragend', (e) => {
            const targetMarker = e.overlay as naver.maps.Marker;
            const { x: lng, y: lat } = targetMarker.getPosition();
            setCoords(lat, lng);
          });
        }
        marker.setMap(map);
        return () => {
          naver.maps.Event.clearListeners(marker, 'dragend');
          marker.setMap(null);
        };
      }
    }
  }, [lat, lng, map, marker, setCoords, title]);

  return (
    <div className={classes.mapContainer}>
      {!scriptLoaded && (
        <div className={classes.mapLoadingOverlay}>
          <LoadingOutlined style={{ fontSize: '3rem' }} />
        </div>
      )}
      <div id='map' style={{ width: '100%', height: 280, border: '1px solid #babfc7' }}>
        <div className={classes.mapFloatButtons}>
          <Button onClick={onSetMapCenter} icon={<AimOutlined />} />
        </div>
      </div>
    </div>
  );
};

export default NaverMapBox;
