import { UploadOutlined } from '@ant-design/icons';
import { Product, ProductStateCode } from '@gooduncles/gu-app-schema';
import { Button, notification } from 'antd';
import { FC, useCallback, useState } from 'react';

import { PRODUCT_MAX_STOCK } from 'src/lib/1/constant';
import { errorObjectToString } from 'src/lib/1/util';
import { createProduct, getNewProductId } from 'src/lib/4/firebase-short-cut';

const CreateProductButton: FC = () => {
  const [loading, setLoading] = useState(false);

  const onCreateNewProduct = useCallback(async () => {
    setLoading(true);
    try {
      // 1. 기존과 중복되지 않도록 새로운 상품 코드를 생성한다.
      const productId = await getNewProductId();
      // 2. 상품 데이터를 초기화한다.
      const newProduct: Product = {
        productId,
        categories: ['unknown', 'all'],
        fullName: '신규상품(신규)',
        name: '신규상품',
        state: ProductStateCode.DISCONTINUED,
        taxFree: false,
        price: 0,
        cost: 0,
        suppliers: ['기타'],
        storage: 'freezer',
        packing: 'others',
        hidden: false,
        createSnapshot: true,
        dailyNotification: false,
        unitPrice: null,
        stock: PRODUCT_MAX_STOCK,

        brand: null,
        origin: null,
        features: null,
        measure: [{ amount: 1, unit: '개' }],
        mainTag: null,
        tags: null,
        badge: null,
        imageDescription: null,

        imageUrl: null,
        thumbnail: null,
        packingInfo: null,
        memo: null,
        salesAmount: 0,
        costUpdatedAt: null,
      };
      // 3. 신규상품 데이터를 저장한다.
      await createProduct(newProduct);
    } catch (error) {
      console.error(error);
      const msg = errorObjectToString(error);
      notification.error({
        message: '신규 상품 생성 실패',
        description: msg,
      });
    }
    setLoading(false);
  }, []);

  return (
    <Button type='primary' loading={loading} icon={<UploadOutlined />} onClick={onCreateNewProduct}>
      신규 상품 추가
    </Button>
  );
};

export default CreateProductButton;
