import { CheckCircleOutlined } from '@ant-design/icons';
import { OrderDoc, StoreIssueDoc, TaskItem, TaskItemStatus, TaskOrderCollection } from '@gooduncles/gu-app-schema';
import { FC, useEffect, useState } from 'react';

import { formatNumber } from 'src/lib/1/util';
import { getOrder, getStoreIssue } from 'src/lib/4/firebase-short-cut';

import classes from './TaskOrderInfoBox.module.scss';

type TaskOrderInfo = {
  totalAmount: number;
  products: {
    productId: string;
    name: string;
    price: number;
    volume: number;
    totalPrice: number;
    status: TaskItemStatus | undefined;
    issue: string | undefined;
  }[];
};

/**
 * 주문 또는 이슈를 공통된 값으로 정규화합니다.
 */
const normalizeTaskOrder = (
  collection: TaskOrderCollection,
  taskOrder: OrderDoc | StoreIssueDoc,
  taskItems: Record<string, TaskItem>
): TaskOrderInfo | undefined => {
  if (collection === TaskOrderCollection.Order) {
    const order = taskOrder as OrderDoc;
    const products = order.products.map((product) => {
      const taskItem = taskItems[product.productId];
      return {
        productId: product.productId,
        name: product.fullName,
        price: product.price,
        volume: product.volume,
        totalPrice: product.price * product.volume,
        status: taskItem?.itemStatus,
        issue: taskItem?.issue ?? undefined,
      };
    });
    return {
      totalAmount: order.totalAmount,
      products,
    };
  } else if (collection === TaskOrderCollection.StoreIssue) {
    const storeIssue = taskOrder as StoreIssueDoc;
    const taskItem = taskItems[storeIssue._id];
    const price = (storeIssue.supplyPrice ?? 0) + (storeIssue.tax ?? 0);
    const volume = storeIssue.volume ?? 1;
    const totalPrice = price * volume;
    const products = [
      {
        productId: storeIssue._id,
        name: storeIssue.message ?? '내용 없음',
        price,
        volume,
        totalPrice,
        status: taskItem?.itemStatus,
        issue: taskItem?.issue ?? undefined,
      },
    ];
    return {
      totalAmount: totalPrice,
      products,
    };
  }
};

const LabelWithText: FC<{ label: string | JSX.Element; text: string | JSX.Element; bold?: boolean }> = ({
  label,
  text,
  bold = false,
}) => {
  return (
    <div className={`${classes.labelWithText} ${bold ? classes.bold : ''}`}>
      <span className={classes.label}>{label}</span>
      <span className={classes.text}>{text}</span>
    </div>
  );
};

interface TaskOrderInfoBoxProps {
  collection: TaskOrderCollection;
  orderId: string;
  taskItems: Record<string, TaskItem>;
}

const TaskOrderInfoBox: FC<TaskOrderInfoBoxProps> = ({ collection, orderId, taskItems }) => {
  const [taskOrderInfo, setTaskOrderInfo] = useState<TaskOrderInfo | undefined>();

  useEffect(() => {
    if (orderId) {
      if (collection === TaskOrderCollection.Order) {
        getOrder(orderId).then((doc) => {
          if (doc) {
            const normalized = normalizeTaskOrder(collection, doc, taskItems);
            setTaskOrderInfo(normalized);
          }
        });
      } else if (collection === TaskOrderCollection.StoreIssue) {
        getStoreIssue(orderId).then((doc) => {
          if (doc) {
            const normalized = normalizeTaskOrder(collection, doc, taskItems);
            setTaskOrderInfo(normalized);
          }
        });
      }
    }
  }, [orderId, taskItems, collection]);

  if (!taskOrderInfo) {
    return <div className={classes.taskOrderInfoBox}>주문을 불러오지 못했습니다.</div>;
  }

  return (
    <div className={classes.taskOrderInfoBox}>
      <div className={classes.titleBox}>
        <b>주문 및 상세</b>
      </div>
      <div className={classes.contentBox}>
        <LabelWithText
          label={`주문 금액 (${taskOrderInfo.products.length}종)`}
          text={`${formatNumber(taskOrderInfo.totalAmount)}원`}
          bold
        />
        {taskOrderInfo.products.map((product, idx) => (
          <LabelWithText
            key={product.productId + idx}
            label={
              <div className='flexColumn' style={{ gap: 0 }}>
                <span>{product.name}</span>
                <span>
                  {formatNumber(product.price)}원 x {product.volume}
                </span>
              </div>
            }
            text={
              <div className='flexColumn' style={{ gap: 0, alignItems: 'flex-end' }}>
                {product.status === TaskItemStatus.Assigned ? null : product.issue ? (
                  <span className={classes.taskIssue}>{product.issue}</span>
                ) : (
                  <CheckCircleOutlined style={{ color: 'var(--green400)', fontSize: 16 }} />
                )}
                <span>{formatNumber(product.totalPrice)}원</span>
              </div>
            }
          />
        ))}
      </div>
    </div>
  );
};

export default TaskOrderInfoBox;
