import { ProductStockHistoryDoc } from '@gooduncles/gu-app-schema';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { ColDef, ColGroupDef } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { differenceInDays } from 'date-fns';
import { uniq } from 'lodash-es';
import { FC, HTMLAttributes, useMemo } from 'react';
import { stockHistoryTypeOrder } from 'src/utils/product-stock-history-util';

import { formatDate } from 'src/lib/1/date-util';
import { formatNumber } from 'src/lib/1/util';

import CancelStockHistoryButton from './CancelStockHistoryButton';

type ProductStockHistoryTableProps = HTMLAttributes<HTMLDivElement> & {
  stockHistories: ProductStockHistoryDoc[];
  currentStock?: number;
  userEmail?: string;
};

const defaultColDef: ColDef<ProductStockHistoryDoc> = {
  flex: 1,
  sortable: true,
  resizable: true,
  filter: true,
};

const ProductStockHistoryTable: FC<ProductStockHistoryTableProps> = ({
  stockHistories,
  currentStock,
  userEmail,
  ...props
}) => {
  const columnDefs: (ColDef<ProductStockHistoryDoc> | ColGroupDef<ProductStockHistoryDoc>)[] = useMemo(
    () => [
      { field: '_id', hide: true },
      {
        field: 'timestamp',
        sortable: true,
        sort: 'desc',
        rowGroup: true,
        hide: true,
        valueGetter: (params) => `${params.data?.productId}@${params.data?.timestamp ? params.data.timestamp : ''}`,
        valueFormatter: (params) => formatDate(params.value.split('@')[1], 'L.dd(EEEEEE) HH:mm:ss'),
      },
      {
        field: 'type',
        hide: true,
      },
      {
        headerName: '변동 값',
        field: 'stock',
        aggFunc: (params) => {
          return Math.abs(
            params.rowNode.allLeafChildren.reduce((acc, cur) => {
              if (cur.data) {
                if (['INBOUND', 'CANCEL_OUTBOUND'].includes(cur.data.type ?? '')) return acc - cur.data.stock;
                return acc + cur.data.stock;
              }
              return acc;
            }, 0)
          );
        },
        minWidth: 96,
        cellRenderer: (params: any) => {
          const formattedValue = params.value === Infinity ? '∞' : formatNumber(params.value);
          if (params.node.group) {
            const types = uniq<string>(params.node.allLeafChildren.map((c: any) => c.data.type));
            return params.value === 0 ? (
              <span className='stock-cell unchanged'>-</span>
            ) : (
              <span className={`stock-cell ${types[0]?.toLowerCase() ?? ''}`}>{formattedValue}</span>
            );
          }
          const type: string = params.data?.type;
          return <span className={`stock-cell ${type?.toLowerCase() ?? ''}`}>{formattedValue}</span>;
        },
      },
      {
        headerName: '변경 내용',
        valueGetter: (params: any) => {
          if (params.node.group) {
            const history = params.node.allLeafChildren
              .sort((a: any, b: any) => a.data.afterStock - b.data.afterStock)
              .sort(
                (a: any, b: any) =>
                  stockHistoryTypeOrder.indexOf(a.data.type) - stockHistoryTypeOrder.indexOf(b.data.type)
              );
            if (history.length === 1) return `${history[0].data.beforeStock} → ${history[0].data.afterStock}`;
            const first = history[0].data as ProductStockHistoryDoc;
            const last = history[history.length - 1].data as ProductStockHistoryDoc;
            return `${last.beforeStock} → ${first.afterStock}`;
          }
          const data = params.data;
          return data ? `${data.beforeStock} → ${data.afterStock}` : '';
        },
        cellRenderer: (params: any) => <span>{params.value}</span>,
      },
      {
        headerName: '메모',
        field: 'reason',
        minWidth: 80,
        valueFormatter: (params) => {
          const data = params.data as ProductStockHistoryDoc;
          const prefix = data?.userEmail === null ? '[자동] ' : '';
          return `${prefix}${params.value ?? ''}`;
        },
      },
      {
        headerName: '액션',
        cellRenderer: (params: any) => {
          const data = params.data as ProductStockHistoryDoc;
          if (!data) return null;
          // OUTBOUND 취소는 좀 더 고려해보자.
          if (
            !userEmail ||
            !currentStock ||
            data.relatedHistoryId ||
            data.type !== 'INBOUND' ||
            data.canceled ||
            data.beforeStock === Infinity
          ) {
            return null;
          }
          const cancelable = differenceInDays(new Date(), new Date(data.timestamp)) <= 7;
          return cancelable ? (
            <CancelStockHistoryButton userEmail={userEmail} currentStock={currentStock} stockHistory={data} />
          ) : null;
        },
      },
      {
        headerName: '관리자',
        field: 'userEmail',
        hide: true,
      },
    ],
    [currentStock, userEmail]
  );
  return (
    <div className='ag-theme-alpine' {...props}>
      <AgGridReact
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        rowData={stockHistories}
        getRowId={(params) => params.data._id}
        autoGroupColumnDef={{
          headerName: 'Timestamp',
          minWidth: 220,
        }}
        suppressAggFuncInHeader={true}
        rowClassRules={{
          'canceled-stock-history': (params) => !!params?.data?.canceled,
        }}
      />
    </div>
  );
};

export default ProductStockHistoryTable;
