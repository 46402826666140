import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { ColDef, ColGroupDef, GridApi } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { Button, message, notification } from 'antd';
import { countBy } from 'lodash-es';
import { FC, useCallback, useMemo, useState } from 'react';

import { purchaseBundleToKr, purchaseMethodToKr } from 'src/lib/1/constant';
import { SupplierPurchaseConversionItem } from 'src/lib/1/schema-purchase-conversion';
import { errorObjectToString } from 'src/lib/1/util';
import { SupplierDoc } from 'src/lib/3/schema-supplier';
import { updateSupplier } from 'src/lib/4/firebase-short-cut';
import { toQuantityMessage } from 'src/lib/4/purchase-order-util';
import {
  onCellValueChangedForSupplierConversionItem,
  onCellValueChangedWithUpdate,
  onValueSetterWithValidation,
} from 'src/lib/6/ag-grid-util';

import ProductNameCellRenderer from 'src/components/AgGrid/CellRenderer/ProductNameCellRenderer/ProductNameCellRenderer';
import ProductPackingRenderer from 'src/components/AgGrid/CellRenderer/ProductPackingCellRenderer/ProductPackingCellRenderer';
import KakaotalkContentPopover from 'src/components/AlimtalkMessage/AlimtalkContentPopover/AlimtalkContentPopover';
import DownloadRawDataCell from 'src/components/Common/DownloadRawDataCell/DownloadRawDataCell';

import classes from './SupplierTable.module.scss';

import AddConversionItemModal from '../AddConversionItemModal/AddConversionItemModal';
import CreateSupplierModal from '../CreateSupplierModal/CreateSupplierModal';
import { DeleteConversionItemCellRenderer } from './DeleteConversionItemCellRenderer';
import { DeleteSupplierCellRenderer } from './DeleteSupplierCellRenderer';

const onCellValueChanged = onCellValueChangedWithUpdate<SupplierDoc>('매입처 관리');

interface SupplierTableProps {
  rowData: SupplierDoc[];
}

const defaultColDef: ColDef<SupplierDoc> = {
  flex: 1,
  sortable: true,
  resizable: true,
  filter: true,
};

/**
 * @description 그리드에서 드래그가 끝난 후 호출되는 콜백
 */
const onRowDragEnd = async (params: any) => {
  const supplier: SupplierDoc = params.node.data.supplier;
  const oldConversionTable = supplier?.conversionTable;
  if (!oldConversionTable) {
    return;
  }

  const chagnedConversionTable: SupplierPurchaseConversionItem[] = [];
  (params?.api as GridApi)?.forEachNode((node) => {
    const { supplier, ...data } = node.data;
    supplier;
    chagnedConversionTable.push(data);
  });

  try {
    await updateSupplier(supplier._id, { conversionTable: chagnedConversionTable });
    message.success('순서를 변경했습니다.');
  } catch (error) {
    console.error(error);
    const description = errorObjectToString(error);
    notification.error({
      message: '순서 변경에 실패했습니다.',
      description,
    });
  }
};

const SupplierTable: FC<SupplierTableProps> = ({ rowData }) => {
  const [gridRef, setGridRef] = useState<AgGridReact<SupplierDoc> | null>(null);

  const columnDefs: ColDef<SupplierDoc>[] = useMemo(
    () => [
      {
        field: '_id',
        hide: true,
      },
      {
        headerName: '구분',
        field: 'label',
        editable: true,
        cellEditor: 'agTextCellEditor',
        valueSetter: (params) => onValueSetterWithValidation(params, ['_id']),
        onCellValueChanged: (params) => onCellValueChanged(params, 'supplier', params.data._id),
      },
      {
        headerName: '매입처 이름',
        field: 'name',
        cellRenderer: 'agGroupCellRenderer',
        editable: true,
        cellEditor: 'agTextCellEditor',
        valueFormatter: (props) => {
          const supplier = props.data;
          return `${props.value} (${supplier?.conversionTable.length ?? 0})`;
        },
        valueSetter: (params) => onValueSetterWithValidation(params, ['_id']),
        onCellValueChanged: (params) => onCellValueChanged(params, 'supplier', params.data._id),
      },
      {
        headerName: '연락처',
        field: 'tel',
        editable: true,
        cellEditor: 'agTextCellEditor',
        valueSetter: (params) => onValueSetterWithValidation(params, ['_id']),
        onCellValueChanged: (params) => onCellValueChanged(params, 'supplier', params.data._id),
      },
      {
        headerName: '연락처 추가',
        field: 'subTels',
        editable: true,
        valueSetter: (params) =>
          onValueSetterWithValidation(params, ['_id'], undefined, {
            type: 'array',
            removable: true,
          }),
        onCellValueChanged: (params) => onCellValueChanged(params, 'supplier', params.data._id, undefined, true),
      },
      {
        headerName: '발주 방식',
        field: 'purchaseMethod',
        valueFormatter: ({ value }) => {
          return purchaseMethodToKr[value as keyof typeof purchaseMethodToKr] ?? '';
        },
        editable: true,
        cellEditorPopup: true,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: {
          values: Object.keys(purchaseMethodToKr),
        },
        valueSetter: (params) => onValueSetterWithValidation(params, ['_id']),
        onCellValueChanged: (params) => onCellValueChanged(params, 'supplier', params.data._id),
        filter: 'agSetColumnFilter',
        filterParams: {
          valueFormatter: (params: any) => {
            const value: string = params.value;
            return purchaseMethodToKr[value as keyof typeof purchaseMethodToKr] ?? '';
          },
        },
      },
      {
        headerName: '상품 추가',
        cellRenderer: (props: any) => {
          const supplier: SupplierDoc = props.data;
          return <AddConversionItemModal supplier={supplier} suppliers={rowData} />;
        },
      },
      {
        field: '_id',
        headerName: '매입처 삭제',
        cellRenderer: DeleteSupplierCellRenderer,
      },
      {
        headerName: '액션',
        cellRenderer: DownloadRawDataCell,
        hide: true,
      },
    ],
    [rowData]
  );

  const detailCellRendererParams = useMemo(() => {
    const detailColumnDefs: (ColDef<SupplierPurchaseConversionItem> | ColGroupDef<SupplierPurchaseConversionItem>)[] = [
      {
        headerName: '상품ID',
        field: 'productId',
        maxWidth: 120,
        rowDrag: true,
      },
      {
        headerName: '*발주품명',
        field: 'purchaseProductName',
        editable: true,
        onCellValueChanged: onCellValueChangedForSupplierConversionItem,
      },
      {
        headerName: '*묶음방식',
        field: 'purchaseBundle',
        maxWidth: 160,
        valueFormatter: ({ value }) => {
          return purchaseBundleToKr[value as keyof typeof purchaseBundleToKr] ?? '';
        },
        editable: true,
        cellEditorPopup: true,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: {
          values: Object.keys(purchaseBundleToKr),
          cellRenderer: ({ value }: any) => <span>{purchaseBundleToKr[value] ?? ''}</span>,
        },
        cellRenderer: ({ value }: any) => <span>{purchaseBundleToKr[value] ?? ''}</span>,
        onCellValueChanged: onCellValueChangedForSupplierConversionItem,
      },
      {
        headerName: '*최대묶음',
        field: 'purchaseMaxBundle',
        maxWidth: 120,
        editable: true,
        onCellValueChanged: onCellValueChangedForSupplierConversionItem,
      },
      {
        headerName: '*단위 변환 값',
        field: 'purchaseMultiplyUnit',
        maxWidth: 140,
        editable: true,
        onCellValueChanged: onCellValueChangedForSupplierConversionItem,
      },
      {
        headerName: '*단위',
        field: 'purchaseUnit',
        maxWidth: 120,
        editable: true,
        onCellValueChanged: onCellValueChangedForSupplierConversionItem,
      },
      {
        headerName: '미리보기(기준수량: 10)',
        cellRenderer: KakaotalkContentPopover,
        valueGetter: ({ data }) => {
          if (!data) return '';
          const { purchaseProductName, purchaseBundle, purchaseMaxBundle, purchaseMultiplyUnit, purchaseUnit } = data;
          if (purchaseBundle === 'all') {
            return `${purchaseProductName} ${10 * purchaseMultiplyUnit}${purchaseUnit ?? ''}`;
          }

          if (purchaseBundle === 'store') {
            const sample = [1, 2, 3, 4];
            const total = sample.reduce((acc, cur) => acc + cur * purchaseMultiplyUnit, 0);
            return `${purchaseProductName} ${sample.map((s) => s * purchaseMultiplyUnit).join(', ')} (총 ${total}${
              purchaseUnit ?? ''
            })`;
          }

          if (purchaseBundle === 'storeGroup') {
            const sample = [1, 1, 2, 2, 4];
            const countByQuantity = countBy(sample);
            const quantityMessage = Object.entries(countByQuantity)
              .sort()
              .map(([quantity, count]) => `${quantity}${purchaseUnit ?? ''} x ${count}`)
              .join('\n');
            const total = sample.reduce((acc, cur) => acc + cur * purchaseMultiplyUnit, 0);
            return `${purchaseProductName}\n${quantityMessage}\n(총 ${total}${purchaseUnit ?? ''})`;
          }

          if (purchaseBundle === 'max' && purchaseMaxBundle) {
            const totalVolume = 10 * purchaseMultiplyUnit;
            const bundle = purchaseMaxBundle;
            const amount = Math.floor(totalVolume / bundle);
            const remainder = totalVolume % bundle;
            const quantityMessage = toQuantityMessage([{ remainder, amount, bundle }], purchaseUnit ?? '개');
            return `${purchaseProductName}\n${quantityMessage}`;
          }
          return '';
        },
      },
      {
        headerName: '상품명',
        field: 'productId',
        cellRenderer: ProductNameCellRenderer,
      },
      {
        headerName: '포장단위',
        field: 'productId',
        cellRenderer: ProductPackingRenderer,
      },
      {
        headerName: '매입 상품 삭제',
        field: 'productId',
        cellRenderer: DeleteConversionItemCellRenderer,
      },
    ];

    return {
      // 부모 행이 변경될 때 같이 변경된다.
      refreshStrategy: 'rows',
      detailGridOptions: {
        columnDefs: detailColumnDefs,
        defaultColDef,
        enableCellChangeFlash: true,
        suppressScrollOnNewData: true,
        rowDragManaged: true,
        rowDragMultiRow: true,
        alwaysShowHorizontalScroll: true,
        suppressCopyRowsToClipboard: true,
        rowSelection: 'multiple',
        suppressMoveWhenRowDragging: true,
        onRowDragEnd,
      },
      getDetailRowData: (params: any) => {
        const supplier = params.data as SupplierDoc;
        params.successCallback(supplier.conversionTable.map((item) => ({ ...item, supplier })));
      },
    };
  }, []);

  const autoSizeAll = useCallback(() => {
    gridRef?.columnApi?.autoSizeAllColumns(true);
  }, [gridRef]);

  const expandAll = useCallback(() => {
    gridRef?.api.forEachNode((node) => {
      if (!node.expanded) {
        node.setExpanded(true);
      }
    });
  }, [gridRef?.api]);

  const collapseAll = useCallback(() => {
    gridRef?.api.forEachNode((node) => {
      if (node.expanded) {
        node.setExpanded(false);
      }
    });
  }, [gridRef?.api]);

  const isRowMaster = useMemo(() => {
    return (dataItem: SupplierDoc) => {
      return dataItem ? dataItem.conversionTable && dataItem.conversionTable.length > 0 : false;
    };
  }, []);

  return (
    <div className={classes.supplierTableContainer}>
      <div className={classes.toolbar}>
        <CreateSupplierModal />
        <Button onClick={expandAll}>전체 펼치기</Button>
        <Button onClick={collapseAll}>전체 접기</Button>
      </div>
      <div className='ag-theme-alpine height100'>
        <AgGridReact
          ref={setGridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onGridReady={autoSizeAll}
          // rowId를 지정해주지 않으면 데이터 변경시 refresh가 발생한다.
          getRowId={(params) => params.data._id}
          // 편집 완료후 스크롤 이동을 막는다.
          suppressScrollOnNewData={true}
          // rowGroup 최초 오픈 설정
          // 하위 노드 관련 설정
          isRowMaster={isRowMaster}
          masterDetail={true}
          keepDetailRows={true}
          detailRowAutoHeight={true}
          detailCellRendererParams={detailCellRendererParams}
        />
      </div>
    </div>
  );
};

export default SupplierTable;
