import { PlusCircleOutlined } from '@ant-design/icons';
import { StoreDoc } from '@gooduncles/gu-app-schema';
import { DeliverySpot } from '@gooduncles/gu-app-schema';
import { Button, Form, Input, InputNumber, Modal, notification } from 'antd';
import { FC, useCallback, useMemo, useState } from 'react';

import { goodunclesCoords, requiredRule, seoulCoordsRange } from 'src/lib/1/constant';
import { errorObjectToString, getStepFromNumber } from 'src/lib/1/util';
import { createDeliverySpot, updateStore } from 'src/lib/4/firebase-short-cut';

import useDeliverySpots from 'src/hooks/useDeliverySpots';

import NaverMapBox from 'src/atomic-components/molecules/NaverMapBox/NaverMapBox';

interface DeliverySpotModalProps {
  store: StoreDoc;
}

const DeliverySpotModal: FC<DeliverySpotModalProps> = ({ store }) => {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lat, setLat] = useState<number>(store.lat ?? goodunclesCoords.lat);
  const [lng, setLng] = useState<number>(store.lng ?? goodunclesCoords.lng);
  const initMap = useMemo(() => form && open, [form, open]);
  const { deliverySpots } = useDeliverySpots();

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const onAddNewDeliverySpot = useCallback(
    async (values: DeliverySpot) => {
      setLoading(true);
      try {
        if (!deliverySpots || deliverySpots.map((spot) => spot.title).includes(values.title)) {
          notification.error({
            message: '배송지 추가 실패',
            description: '이미 존재하는 배송지명입니다.',
          });
          setLoading(false);
          return;
        }
        const deliverySpotId = await createDeliverySpot({
          ...values,
          partnerId: 'undefined',
          courierId: 'unassigned', // 제거 예정
          sortKey: 0,
          isDeleted: false,
        });
        notification.success({
          message: `배송지(${values.title}) 추가 성공`,
        });
        await updateStore(store._id, { deliverySpotId });
        notification.success({
          message: `${store.storeNickname}에 배송지(${values.title}) 등록 완료`,
        });
        closeModal();
      } catch (error) {
        const description = errorObjectToString(error);
        notification.error({
          message: '배송지 추가 실패',
          description,
        });
      }
      setLoading(false);
    },
    [deliverySpots, store._id, store.storeNickname]
  );

  const setCoords = useCallback(
    (lat: number, lng: number) => {
      setLat(lat);
      setLng(lng);
      form.setFieldsValue({ lat, lng });
    },
    [form]
  );

  return (
    <>
      <Button
        type='text'
        icon={<PlusCircleOutlined />}
        style={{
          marginLeft: 4,
          color: 'var(--blue400)',
        }}
        onClick={openModal}
        loading={deliverySpots === undefined}
      />
      <Modal
        title='배송 지점 추가'
        open={open}
        onOk={form.submit}
        onCancel={closeModal}
        okText='추가'
        cancelText='닫기'
        closable={false}
        maskClosable={false}
        destroyOnClose={true}
        okButtonProps={{ loading }}
        cancelButtonProps={{ loading }}>
        <Form
          form={form}
          initialValues={{
            title: store.storeNickname,
            roadAddress: store.roadAddress,
            jibunAddress: store.jibunAddress,
            lat: store.lat ?? goodunclesCoords.lat,
            lng: store.lng ?? goodunclesCoords.lng,
          }}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          layout='horizontal'
          style={{ maxWidth: 720, marginTop: 40 }}
          onFinish={onAddNewDeliverySpot}>
          <Form.Item label='배송지명' name='title' rules={requiredRule}>
            <Input />
          </Form.Item>
          <Form.Item label='도로명주소' name='roadAddress' rules={requiredRule}>
            <Input />
          </Form.Item>
          <Form.Item label='지번주소' name='jibunAddress'>
            <Input />
          </Form.Item>
          <Form.Item label='위도' name='lat' rules={requiredRule}>
            <InputNumber
              style={{ width: '100%' }}
              min={seoulCoordsRange.lat.min}
              max={seoulCoordsRange.lat.max}
              step={getStepFromNumber(lat)}
              onChange={(v) => setLat(v ?? goodunclesCoords.lat)}
            />
          </Form.Item>
          <Form.Item label='경도' name='lng' rules={requiredRule}>
            <InputNumber
              style={{ width: '100%' }}
              min={seoulCoordsRange.lng.min}
              max={seoulCoordsRange.lng.max}
              step={getStepFromNumber(lng)}
              onChange={(v) => setLng(v ?? goodunclesCoords.lng)}
            />
          </Form.Item>
        </Form>
        {initMap && <NaverMapBox lat={lat} lng={lng} setCoords={setCoords} />}
      </Modal>
    </>
  );
};

export default DeliverySpotModal;
