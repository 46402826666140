import { PaymentItemDoc, PaymentStatusCode, paymentStatusCodeKrMap } from '@gooduncles/gu-app-schema';
import { ColDef, ColGroupDef } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { message, notification } from 'antd';
import { FC, useMemo } from 'react';
import { cellStyleForPaymentMethod, getHyosungProductName } from 'src/utils/payment-util';

import { formatDate } from 'src/lib/1/date-util';
import { errorObjectToString } from 'src/lib/1/util';
import { updateInvoice } from 'src/lib/4/firebase-short-cut';
import { calculatePreTaxPrice } from 'src/lib/5/order-util';
import { getCellClassForPaymentStatus, numberFormatter } from 'src/lib/6/ag-grid-util';

import { SettlementByStoreRowData } from 'src/hooks/useSettlementByStore';

const dateFormatter = ({ value }: { value: string | null }) => (value ? formatDate(value, 'yyyy-MM-dd HH:mm:ss') : '');

const defaultColDef = {
  sortable: true,
  resizable: true,
  filter: true,
};

interface SettlementByStoreTableProps {
  rowData: SettlementByStoreRowData[];
}

const SettlementByStoreTable: FC<SettlementByStoreTableProps> = ({ rowData }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const columnDefs: (ColDef<SettlementByStoreRowData> | ColGroupDef<SettlementByStoreRowData>)[] = useMemo(
    () => [
      { field: 'invoice._id', hide: true },
      { headerName: '청구일', field: 'invoice.date', width: 180, valueFormatter: dateFormatter, sort: 'desc' },
      { headerName: '결제처', field: 'invoice.paymentInfo.paymentVendor', width: 100 },
      { headerName: '결제방식', field: 'invoice.paymentInfo.paymentMethod', width: 100 },
      {
        headerName: '정산',
        field: 'settlementResult',
        width: 100,
        valueGetter: (params: any) => (params.data.settlementResult ? '🟢' : '미정산'),
      },
      {
        headerName: '정산금액',
        cellRenderer: 'agGroupCellRenderer',
        width: 140,
        type: 'numericColumn',
        valueGetter: (params: any) => {
          const { settlementResult } = params.data as SettlementByStoreRowData;
          if (settlementResult) {
            return settlementResult.paidSupplyPrice + settlementResult.paidTax;
          }
        },
        valueFormatter: numberFormatter,
        cellStyle: (params: any) => {
          const { settlementResult } = params.data as SettlementByStoreRowData;
          if (!settlementResult) return;
          const value = params.value as number;
          if (settlementResult.invoiceAmount > 0) {
            if (value === settlementResult.invoiceAmount)
              return {
                backgroundColor: 'var(--green200)',
              };
            if (value < settlementResult.invoiceAmount)
              return {
                backgroundColor: 'var(--yellow200)',
              };
            if (value > settlementResult.invoiceAmount || value === 0)
              return {
                backgroundColor: 'var(--blue200)',
              };
          }
        },
      },
      {
        headerName: '청구금액',
        field: 'invoice.invoiceAmount',
        valueFormatter: numberFormatter,
        width: 120,
        type: 'numericColumn',
      },
      {
        headerName: '면세',
        field: 'invoice.taxExemptAmount',
        width: 120,
        type: 'numericColumn',
        editable: (params) => {
          const data = params.data;
          return data?.invoice.settledAt === null;
        },
        valueSetter: (params) => {
          const newValue = Number(params.newValue);
          if (isNaN(newValue)) {
            notification.error({
              message: '면세금액 수정 실패',
              description: '숫자만 입력해주세요.',
            });
            return false;
          }
          params.data.invoice.taxExemptAmount = newValue;
          return true;
        },
        onCellValueChanged: async (params) => {
          const oldValue = params.oldValue;
          const newValue = params.newValue;
          const { invoice } = params.data;
          if (oldValue === newValue) return;
          // 청구금액 - 면세금액 = 과세금액
          const newTaxableAmount = invoice.invoiceAmount - newValue;
          try {
            const key = invoice._id;
            messageApi.open({
              key,
              type: 'loading',
              content: '면세 & 과세 금액 수정 중...',
              duration: 0,
            });
            await updateInvoice(invoice._id, { taxExemptAmount: newValue, taxableAmount: newTaxableAmount });
            messageApi.open({
              key,
              type: 'success',
              content: `면세 & 과세 금액 수정 완료: 면세 ${newValue}원, 과세 ${newTaxableAmount}원`,
              duration: 2,
            });
          } catch (error) {
            console.error(error);
            const description = errorObjectToString(error);
            notification.error({
              message: '면세 & 과세 금액 수정 실패',
              description,
            });
          }
        },
        valueFormatter: numberFormatter,
      },
      {
        headerName: '과세',
        field: 'invoice.taxableAmount',
        valueFormatter: numberFormatter,
        width: 120,
        type: 'numericColumn',
      },
      {
        headerName: '공급가액',
        valueGetter: (params: any) => {
          const { invoice } = params.data as SettlementByStoreRowData;
          const { price } = calculatePreTaxPrice(invoice.taxableAmount ?? 0);
          return price;
        },
        valueFormatter: numberFormatter,
        width: 120,
        type: 'numericColumn',
      },
      {
        headerName: '부가세',
        valueGetter: (params: any) => {
          const { invoice } = params.data as SettlementByStoreRowData;
          const { tax } = calculatePreTaxPrice(invoice.taxableAmount ?? 0);
          return tax;
        },
        valueFormatter: numberFormatter,
        width: 120,
        type: 'numericColumn',
      },
      { headerName: 'ⓗ회원번호', field: 'invoice.paymentInfo.storeCode', width: 110 },
      { headerName: 'ⓗ계약번호', field: 'invoice.paymentInfo.contractNumber', width: 110 },
      { headerName: 'ⓗ회원명', field: 'invoice.paymentInfo.name', width: 100 },
      {
        headerName: 'ⓗ상품',
        valueGetter: (params: any) => {
          const { invoice } = params.data as SettlementByStoreRowData;
          if (invoice.paymentInfo.paymentVendor === '효성CMS') {
            return getHyosungProductName(invoice.paymentInfo.paymentMethod, invoice);
          }
          return '';
        },
        cellStyle: (params: any) => {
          if (params.value.includes('카드')) return { color: 'var(--blue400)' };
        },
        width: 130,
      },
    ],
    [messageApi]
  );

  /**
   * 정산금액의 상세 내역을 보여주기 위한 렌더러 설정
   */
  const detailCellRendererParams = useMemo(() => {
    const detailColumnDefs: (ColDef<PaymentItemDoc> | ColGroupDef<PaymentItemDoc>)[] = [
      {
        field: 'transactionDate',
        headerName: '결제일시',
        valueFormatter: dateFormatter,
      },
      {
        field: 'payerInfo',
        headerName: '결제자',
      },
      {
        field: 'paymentStatus',
        headerName: '결제상태',
        width: 100,
        valueFormatter: ({ value }) => paymentStatusCodeKrMap[value as unknown as PaymentStatusCode],
        cellClass: getCellClassForPaymentStatus,
        filterParams: {
          valueFormatter: (params: any) => paymentStatusCodeKrMap[params.value as unknown as PaymentStatusCode],
        },
      },
      {
        field: 'paymentMethod',
        headerName: '결제수단',
        cellStyle: cellStyleForPaymentMethod,
        width: 100,
      },
      {
        field: 'paymentVendor',
        headerName: '출처',
        width: 100,
      },
      {
        field: 'amount',
        headerName: '결제금액',
        width: 120,
        valueFormatter: numberFormatter,
      },
      {
        field: 'supplyPrice',
        headerName: '공급가액',
        width: 120,
        valueFormatter: numberFormatter,
      },
      {
        field: 'tax',
        headerName: '부가세',
        width: 120,
        valueFormatter: numberFormatter,
      },
      {
        field: 'extraInfo',
        headerName: '기타정보',
      },
      {
        field: 'settledAt',
        headerName: '정산시각',
        valueFormatter: dateFormatter,
      },
      {
        field: 'settlementResultId',
        headerName: '정산결과',
        width: 100,
        valueFormatter: ({ value }) => (value ? '정산' : '미정산'),
      },
      {
        field: 'createdBy',
        headerName: '생성자',
      },
    ];

    return {
      detailGridOptions: {
        columnDefs: detailColumnDefs,
        defaultColDef,
      },
      getDetailRowData: (params: any) => {
        const paymentItems = params.data.paymentItems as PaymentItemDoc[];
        params.successCallback(paymentItems);
      },
    };
  }, []);

  const isRowMaster = (data: SettlementByStoreRowData) => !!(data.paymentItems && data.paymentItems.length > 0);

  return (
    <div className='ag-theme-alpine height100'>
      {contextHolder}
      <AgGridReact
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        // rowId를 지정해주지 않으면 데이터 변경시 refresh가 발생한다.
        getRowId={(params) => params.data.invoice._id}
        // 편집 완료후 스크롤 이동을 막는다.
        suppressScrollOnNewData={true}
        // detailRow
        isRowMaster={isRowMaster}
        keepDetailRows={true}
        masterDetail={true}
        detailRowAutoHeight={true}
        detailCellRendererParams={detailCellRendererParams}
      />
    </div>
  );
};

export default SettlementByStoreTable;
