import { ExclamationCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Product } from '@gooduncles/gu-app-schema';
import { Button, Modal, message, notification } from 'antd';
import { FC, useCallback, useEffect, useState } from 'react';

import { errorObjectToString, formatNumber } from '../../lib/1/util';
import { readExcelFile } from '../../lib/1/xlsx-util';
import { FirebaseManager } from '../../lib/3/firebase-manager';
import { CallGetProductIdListResponse } from '../../lib/3/schema-on-call';
import { setProduct, updateProduct } from '../../lib/4/firebase-short-cut';
import { ProductExcelItem } from 'src/lib/1/schema-excel-product';
import { initialProductBase, parseExcelProduct, productExcelItemFieldMapReverse } from 'src/lib/4/product-util';
import { ConsoleLogger } from 'src/lib/5/logger';

import classes from './ProductUpload.module.scss';

import FileUploadArea from '../Common/FileUploadArea/FileUploadArea';
import Loading from '../Loading/Loading';
import RawProductTable from '../Table/RawProductTable/RawProductTable';

const logger = ConsoleLogger.getInstance();
const logName = '상품 추가';

const { confirm } = Modal;

const firebaseManager = FirebaseManager.getInstance();
const callGetProductIdList = firebaseManager.getCallable<undefined, CallGetProductIdListResponse>(
  'callGetProductIdList'
);

/**
 * 1. 업로드 하려는 상품이 기존에 등록된 상품인지 확인한다.
 * @param products
 * @returns
 */
const checkDuplicatedProduct = async (products: Product[]) => {
  const callableResponse = await callGetProductIdList();
  if (callableResponse.data.result === 'success') {
    const { productIdList } = callableResponse.data;
    const duplicated = products.filter((product) => productIdList.includes(product.productId));
    return duplicated.length > 0 ? duplicated : undefined;
  }
};

/**
 * 기존에 없는 상품이 있는지 확인한다.
 */
const partitionByExist = async (products: (Partial<Product> & { productId: string })[]) => {
  try {
    const callableResponse = await callGetProductIdList();
    if (callableResponse.data.result === 'success') {
      const { productIdList } = callableResponse.data;
      const existProducts = products.filter((product) => productIdList.includes(product.productId));
      const newProducts = products.filter((product) => !productIdList.includes(product.productId));
      return {
        existProducts,
        newProducts,
      };
    }
  } catch (error) {
    console.error(error);
    const description = errorObjectToString(error);
    notification.error({
      message: '상품 중복체크에 실패했습니다.',
      description,
    });
  }
  return null;
};

const uploadProducts = async (products: Product[]) => {
  firebaseManager.batchStart();
  for (const product of products) {
    await setProduct(product, true);
  }
  firebaseManager.batchEnd();
};

const partialUpdateProduct = async (products: (Partial<Product> & { productId: string })[]) => {
  firebaseManager.batchStart();
  for (const product of products) {
    await updateProduct(product.productId, product, true);
  }
  firebaseManager.batchEnd();
};

const ProductUpload: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [fileToUpload, setFileToUpload] = useState<File>();
  const [products, setProducts] = useState<(Partial<Product> & { productId: string })[]>();
  const [error, setError] = useState<string | null>(null);

  /**
   * 기존 상품 업데이트
   */
  const handlePartialUpdateProducts = useCallback(async () => {
    if (products === undefined) {
      message.error('업로드할 상품이 없습니다.');
      return;
    }

    setIsLoading(true);
    const result = await partitionByExist(products);
    if (!result) {
      message.error('상품 중복체크에 실패했습니다.');
      return;
    }
    setIsLoading(false);

    const { existProducts, newProducts } = result;

    confirm({
      title: '상품 데이터를 업데이트합니다.',
      icon: <ExclamationCircleOutlined />,
      content: (
        <p>
          업데이트 하는 필드:{' '}
          {Object.keys(existProducts[0])
            .filter((key) => key !== 'productId')
            .map((key) => productExcelItemFieldMapReverse[key])
            .join(', ')}
          <br />
          {newProducts.length > 0 && (
            <span>등록되지 않은 상품으로 업로드 불가능한 상품: {newProducts.map((p) => p.productId).join(', ')}</span>
          )}
        </p>
      ),
      okText: '업데이트 진행',
      cancelText: '취소',
      onOk: async () => {
        try {
          setIsLoading(true);
          await partialUpdateProduct(existProducts);
          notification.success({
            message: '업로드 완료',
            description: `${formatNumber(existProducts.length)}개의 상품이 업로드 되었습니다.`,
          });
          logger.logConsole(`${logName} - 상품 업로드 완료: ${formatNumber(existProducts.length)}개`);
          setIsLoading(false);
        } catch (error) {
          console.error(error);
          const description = errorObjectToString(error);
          notification.error({
            message: '부분 업데이트에 실패했습니다.',
            description,
          });
          logger.logConsole(`${logName} - 상품 내용 업데이트 실패: ${description}`, {
            level: 'error',
          });
        }
      },
    });
  }, [products]);

  /**
   * 신규 상품 업로드
   */
  const handleUploadProducts = useCallback(async () => {
    if (products === undefined) {
      message.error('업로드할 상품이 없습니다.');
      return;
    }

    // const unfilledField = Object.keys(initialProductBase).filter(
    //   (key) => key !== 'stock' && !Object.keys(products[0]).includes(key)
    // );
    // if (unfilledField.length > 0) {
    //   const fieldNames = unfilledField.map((key) => productExcelItemFieldMapReverse[key]).join(', ');
    //   message.error(`${fieldNames} 열을 추가해주세요.`);
    //   return;
    // }

    // 상품 생성전 중복 체크
    setIsLoading(true);
    try {
      const products0 = products as Product[];
      const isDuplicated = await checkDuplicatedProduct(products0);
      if (isDuplicated && isDuplicated.length > 0) {
        notification.info({
          message: '중복된 상품이 있습니다.',
          description: `${isDuplicated.map((p) => p.productId).join(', ')}`,
        });
        setIsLoading(false);
        return;
      }
    } catch (error) {
      console.error(error);
      const description = errorObjectToString(error);
      notification.error({
        message: '상품 중복체크에 실패했습니다.',
        description,
      });
      logger.logConsole(`${logName} - 상품 중복체크 실패: ${description}`, {
        level: 'error',
      });
    }
    setIsLoading(false);

    confirm({
      title: '신규 상품을 생성합니다.',
      icon: <PlusOutlined />,
      content: <p>{products.length}개의 상품을 생성합니다.</p>,
      okText: '업로드 진행',
      cancelText: '취소',
      onOk: async () => {
        try {
          setIsLoading(true);
          const products0 = products.map((p) => ({ ...initialProductBase, ...p })) as Product[];
          await uploadProducts(products0);
          notification.success({
            message: '업로드 완료',
            description: `${formatNumber(products.length)}개의 상품이 업로드 되었습니다.`,
          });
          logger.logConsole(`${logName} - 상품 업로드 완료: ${formatNumber(products.length)}개`);
          setIsLoading(false);
        } catch (error) {
          console.error(error);
          const description = errorObjectToString(error);
          notification.error({
            message: '상품 업로드에 실패했습니다.',
            description,
          });
          logger.logConsole(`${logName} - 신규 상품 업로드 실패: ${description}`, {
            level: 'error',
          });
        }
      },
    });
  }, [products]);

  const handleOnCancel = useCallback(() => {
    setFileToUpload(undefined);
    setProducts(undefined);
    setError(null);
  }, []);

  const onReadExcelFile = useCallback(async (file: File) => {
    try {
      const sheet = await readExcelFile<ProductExcelItem>(file);
      const result = parseExcelProduct(sheet);
      setProducts(result);
    } catch (error) {
      console.error(error);
      const description = errorObjectToString(error);
      notification.error({
        message: '엑셀 파일을 읽는데 실패했습니다.',
        description,
      });
      setError(description);
    }
  }, []);

  useEffect(() => {
    if (fileToUpload) {
      onReadExcelFile(fileToUpload);
    }
  }, [fileToUpload, onReadExcelFile]);

  return (
    <>
      <div className={classes.productUploadContainer}>
        {!fileToUpload ? (
          <FileUploadArea fileToUpload={fileToUpload} setFile={setFileToUpload} />
        ) : products ? (
          <RawProductTable
            rowData={products}
            buttons={
              <>
                <Button disabled={isLoading} onClick={handleOnCancel}>
                  다시 올리기
                </Button>
                <div className={classes.buttons}>
                  <Button
                    type='primary'
                    icon={<UploadOutlined />}
                    loading={isLoading}
                    onClick={handlePartialUpdateProducts}>
                    상품 업데이트
                  </Button>
                  <Button icon={<PlusOutlined />} loading={isLoading} onClick={handleUploadProducts}>
                    신규 상품 업로드
                  </Button>
                </div>
              </>
            }
          />
        ) : error ? (
          <div className={classes.errorContainer}>
            <h1>상품 생성 실패</h1>
            <p>업로드한 데이터에 문제가 있는 것 같습니다.</p>
            <div className={classes.errorCode}>
              <p>{error}</p>
            </div>
            <Button onClick={handleOnCancel} size='large'>
              다시 올리기
            </Button>
          </div>
        ) : (
          <Loading title='파일을 읽고 있습니다.' />
        )}
      </div>
      {isLoading && <Loading title='잠시만 기다려주세요.' />}
    </>
  );
};

export default ProductUpload;
