import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { FC } from 'react';

import { downloadJSON } from 'src/lib/1/util';

const DownloadRawDataCell: FC = (params: any) => {
  const { data } = params ?? {};
  if (!data?._id || data._id === 'summary') {
    return null;
  }
  return (
    <Button
      size='small'
      type='primary'
      style={{ fontSize: 12, marginLeft: 4 }}
      onClick={() => downloadJSON(data, data._id)}
      icon={<DownloadOutlined />}>
      JSON
    </Button>
  );
};

export default DownloadRawDataCell;
