import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { store } from './redux/store';

import './theme/naver-map.scss';

import './index.scss';

import App from './App';

const root = createRoot(document.getElementById('app') as HTMLElement);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
