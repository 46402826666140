import { HyosungCMSMemberDoc } from '@gooduncles/gu-app-schema';
import { useEffect, useState } from 'react';

import { getHyosungCMSMembers } from 'src/lib/4/firebase-short-cut';

const useHyosungCMSMembers = () => {
  const [hyosungCMSMembers, setHyosungCMSMembers] = useState<HyosungCMSMemberDoc[]>();

  useEffect(() => {
    getHyosungCMSMembers().then((members) => {
      setHyosungCMSMembers(members);
    });
  }, []);
  return hyosungCMSMembers;
};

export default useHyosungCMSMembers;
