import {
  ShopOutlined, // MinusCircleOutlined, PlusOutlined,
} from '@ant-design/icons';
import { Button, Form, Input, Modal, Radio, notification } from 'antd';
import { FC, useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { purchaseMethodToKr } from 'src/lib/1/constant';
import { errorObjectToString, normalizeTel } from 'src/lib/1/util';
import { Supplier } from 'src/lib/3/schema-supplier';
import { createSupplier, getSuppliersForName } from 'src/lib/4/firebase-short-cut';
import { ConsoleLogger } from 'src/lib/5/logger';

const logger = ConsoleLogger.getInstance();
const logName = '매입처 추가';

const initialValues = {
  purchaseMethod: 'kakaotalk',
};
const requiredRule = [{ required: true, message: '필수 항목입니다!' }];

const CreateSupplierModal: FC = () => {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = useCallback(() => {
    setLoading(false);
    setOpen(false);
    form.resetFields();
  }, [form]);

  const onCreateSupplier = useCallback(async () => {
    try {
      const values = await form.validateFields();
      const { name, tel, purchaseMethod, label } = values;
      const isDuplicate = (await getSuppliersForName(name)).length > 0;
      if (isDuplicate) {
        throw new Error(`이미 존재하는 매입처(${name})입니다!`);
      }
      const supplier: Supplier = {
        name,
        tel,
        purchaseMethod,
        conversionTable: [],
        label,
        isDeleted: false,
      };
      await createSupplier(supplier);
      notification.success({
        message: '매입처 추가 성공',
        description: `${name} 매입처가 추가되었습니다.`,
      });
      // 발주표 작성 창에서 매입처 추가 시 매입처 화면으로 보내주자.
      if (pathname !== '/purchase-order/supplier') {
        navigate('/purchase-order/supplier');
      }
      closeModal();
    } catch (error) {
      console.error(error);
      const errorMessage = errorObjectToString(error);
      notification.error({
        message: '매입처 추가 실패',
        description: errorMessage ?? '알 수 없는 에러 발생!',
      });
      logger.logConsole(`${logName} - 에러 발생\nerror: ${errorMessage}`, {
        level: 'error',
      });
      setLoading(false);
    }
  }, [closeModal, form, navigate, pathname]);

  return (
    <>
      <Button type='primary' icon={<ShopOutlined />} onClick={openModal}>
        매입처 추가
      </Button>
      <Modal
        title='매입처 추가'
        open={open}
        onOk={() => form.submit()}
        onCancel={closeModal}
        okText='추가'
        cancelText='취소'
        closable={false}
        maskClosable={false}
        okButtonProps={{ loading }}
        cancelButtonProps={{ loading }}>
        <Form
          form={form}
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 18 }}
          layout='horizontal'
          initialValues={initialValues}
          style={{ maxWidth: 600, marginTop: 40 }}
          onFinish={onCreateSupplier}>
          <Form.Item label='매입처 이름' name='name' rules={requiredRule}>
            <Input />
          </Form.Item>
          <Form.Item label='구분' name='label' rules={requiredRule}>
            <Input />
          </Form.Item>
          <Form.Item
            label='연락처'
            name='tel'
            rules={[
              ...requiredRule,
              {
                min: 10,
                message: '전화번호 형식이 올바르지 않습니다.',
              },
            ]}
            normalize={(e) => normalizeTel(e)}>
            <Input />
          </Form.Item>
          {/* <Form.List name='subTels'>
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    wrapperCol={index === 0 ? { span: 18 } : { span: 18, offset: 5 }}
                    label={index === 0 ? '추가 번호' : ''}
                    key={field.key}>
                    <Form.Item
                      {...field}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={[
                        {
                          min: 10,
                          message: '전화번호 형식이 올바르지 않습니다.',
                        },
                      ]}
                      noStyle>
                      <Input style={{ width: 'calc(100% - 34px)', alignItems: 'center' }} />
                    </Form.Item>
                    {fields.length > 0 ? (
                      <Button
                        type='text'
                        icon={
                          <MinusCircleOutlined
                            onClick={() => remove(field.name)}
                            style={{ fontSize: '20px', verticalAlign: 'middle', flex: 1 }}
                          />
                        }
                      />
                    ) : null}
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                ))}
                <Form.Item wrapperCol={{ span: 18, offset: 5 }}>
                  <Button type='dashed' onClick={() => add()} icon={<PlusOutlined />}>
                    전화번호 추가
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List> */}
          <Form.Item label='발주 방식' name='purchaseMethod' rules={requiredRule}>
            <Radio.Group>
              {Object.entries(purchaseMethodToKr).map(([key, value]) => (
                <Radio key={key} value={key}>
                  {value}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CreateSupplierModal;
