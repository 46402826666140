import { StoreDoc, UserDoc } from '@gooduncles/gu-app-schema';
import { Select } from 'antd';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { storeStateList } from 'src/lib/1/constant';

import useStoresForSearch from 'src/hooks/useStoresForSearch';
import useUsers from 'src/hooks/userUsers';

type UserSelectorProps = {
  userId?: string;
};

type UserMatchedStore = UserDoc & {
  store?: StoreDoc;
};

const usersMatchedStore = (users: UserDoc[] | undefined, stores: StoreDoc[] | undefined): UserMatchedStore[] => {
  if (!users) return [];
  if (!stores) return users;

  return users.map((user) => {
    const store = stores.find((store) => store._id === user.storeId);
    return { ...user, store };
  });
};

const userStatus: Record<string, string> = {
  active: '🟢',
  inactive: '⚪️',
  deleted: '⚫️',
};

const UserSelector: FC<UserSelectorProps> = ({ userId }) => {
  const navigate = useNavigate();
  const [selectedUserId, setSelectedUserId] = useState<string | undefined>(userId);
  const { stores } = useStoresForSearch();
  const { users, usersLoading } = useUsers();
  const options = usersMatchedStore(users, stores).map(({ _id, email, status, store }) => {
    const statusIcon = userStatus[status];
    const storeState = store?.state ? storeStateList[store?.state] : '??';
    return {
      label: `[${storeState}] ${store?.storeNickname ?? '미등록'}_${email} ${statusIcon}`,
      value: _id,
    };
  });

  const onUserChange = (value: string) => {
    setSelectedUserId(value);
    navigate(`/user/detail/${value}`);
  };

  return (
    <Select
      showSearch
      placeholder='사용자 선택'
      options={options}
      loading={usersLoading}
      style={{ width: '100%' }}
      value={selectedUserId}
      onChange={onUserChange}
      filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
    />
  );
};

export default UserSelector;
