import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { ColDef, ColGroupDef, RowClassParams } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { useCallback, useMemo, useState } from 'react';

import { StoreDoc } from '@gooduncles/gu-app-schema';
import { LogSerachDoc } from 'src/lib/3/schema-log';
import { defaultValueGetter, timestampFormatter, unknownValueCellClass } from 'src/lib/6/ag-grid-util';

export type SearchLogWithStore = LogSerachDoc & { store: StoreDoc | null };

const defaultColDef = {
  flex: 1,
  sortable: true,
  resizable: true,
  filter: true,
};

const autoGroupColumnDef = {};

const getRowStyle = (params: RowClassParams<SearchLogWithStore>): any => {
  const { event, result } = params.node.data ?? {};
  if (event === 'result' && result === 0) {
    return { backgroundColor: '#fff2cc' };
  }
};

const SearchLogTable = ({ rowData }: { rowData: SearchLogWithStore[] }) => {
  const [gridRef, setGridRef] = useState<AgGridReact<SearchLogWithStore> | null>(null);

  const columnDefs: (ColDef<SearchLogWithStore> | ColGroupDef<SearchLogWithStore>)[] = useMemo(
    () => [
      { field: '_id', hide: true },
      {
        headerName: '시각',
        field: '_timeCreate',
        maxWidth: 200,
        valueFormatter: timestampFormatter,
      },
      {
        headerName: '검색 세션',
        field: 'searchId',
        rowGroup: true,
        enableRowGroup: true,
        hide: true,
      },
      {
        headerName: '사용자',
        field: 'body.user.email',
        maxWidth: 230,
        hide: true,
        enableRowGroup: true,
        valueGetter: defaultValueGetter,
        cellClass: unknownValueCellClass,
      },
      {
        headerName: '매장명',
        field: 'store.storeNickname',
        minWidth: 160,
        enableRowGroup: true,
        valueGetter: defaultValueGetter,
        cellClass: unknownValueCellClass,
      },
      {
        headerName: '이벤트',
        field: 'event',
        minWidth: 80,
        maxWidth: 120,
      },
      {
        headerName: '검색 키워드',
        field: 'keyword',
        minWidth: 280,
      },
      {
        headerName: '상세 내용',
        minWidth: 280,
        valueFormatter: (params) => {
          const searchLog = params.data;
          if (['search', 'select'].includes(searchLog?.event ?? '')) {
            return `검색실행: ${searchLog?.keyword ?? ''}`;
          } else if (searchLog?.event === 'product') {
            if (searchLog.product) {
              return searchLog.product.isFavorite !== null
                ? `상품 ${searchLog.product.isFavorite ? '즐겨찾기 추가' : '즐겨찾기 제거'}: [${
                    searchLog.product.id
                  }] ${searchLog.product.fullName}`
                : `상품 수량 변경(${searchLog.product.volumeUnit}): ${searchLog.product.id}-${searchLog.product.fullName}`;
            }
          } else if (searchLog?.event === 'result') {
            return `검색결과: ${searchLog?.result ?? 0}건`;
          }
          return `입력`;
        },
      },
      {
        headerName: '검색 결과',
        field: 'result',
        maxWidth: 120,
        valueGetter: (params) => {
          if (params.data?.event === 'result') {
            return params.data.result === null ? '' : params.data.result > 0 ? params.data.result : '없음';
          }
          return null;
        },
        cellClass: (params: any) => {
          return params.value === '없음' ? 'warn' : '';
        },
      },
      {
        headerName: 'session',
        field: 'sessionId',
        maxWidth: 340,
        enableRowGroup: true,
        valueGetter: defaultValueGetter,
        cellClass: unknownValueCellClass,
      },
      {
        headerName: 'ip',
        field: 'publicIp',
        maxWidth: 160,
        enableRowGroup: true,
        valueGetter: defaultValueGetter,
        cellClass: unknownValueCellClass,
      },
    ],
    []
  );

  const autoSizeAll = useCallback(() => {
    gridRef?.columnApi?.autoSizeAllColumns(true);
  }, [gridRef]);

  return (
    <div className='ag-theme-alpine height100'>
      <AgGridReact
        ref={setGridRef}
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        autoGroupColumnDef={autoGroupColumnDef}
        animateRows={true}
        rowGroupPanelShow={'always'}
        groupDisplayType={'groupRows'}
        groupDefaultExpanded={2}
        onGridReady={autoSizeAll}
        // 편집 완료후 스크롤 이동을 막는다.
        suppressScrollOnNewData={true}
        getRowStyle={getRowStyle}
      />
    </div>
  );
};

export default SearchLogTable;
