import { FC } from 'react';

import { formatNumber } from 'src/lib/1/util';

import useProductStockHistory from 'src/hooks/useProductStockHistory';

interface StockDepletionDateCellRendererProps {
  productId: string;
  stock: number;
}

const StockDepletionDateCellRenderer: FC<StockDepletionDateCellRendererProps> = ({ productId, stock }) => {
  const { stockHistories } = useProductStockHistory(productId);
  const outboundHistories = stockHistories?.filter((history) => history.type === 'OUTBOUND') ?? [];
  if (outboundHistories.length === 0) {
    return <span>-</span>;
  }
  const outboundSum = outboundHistories.reduce((acc, cur) => acc + cur.stock, 0);
  const estimatedStock = stock / (outboundSum / 30);
  return (
    <span>
      {formatNumber(estimatedStock, 1)} <span className='gray600'>({outboundSum})</span>
    </span>
  );
};

export default StockDepletionDateCellRenderer;
