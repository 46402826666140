import {
  DeliveryTaskDoc,
  OrderDoc,
  OrderProduct,
  PickupTask,
  PickupTaskDoc,
  StoreIssueDoc,
  TaskItem,
  TaskItemStatus,
  TaskOrderCollection,
} from '@gooduncles/gu-app-schema';
import { message, notification } from 'antd';
import { Timestamp } from 'firebase/firestore';

import { formatDate } from 'src/lib/1/date-util';
import { errorObjectToString } from 'src/lib/1/util';
import {
  batchEnd,
  batchStart,
  getDeliveryTaskForOrder,
  getOrder,
  getPartnersUser,
  getPickupTaskForOrder,
  getStoreIssue,
  updateDeliveryTask,
  updateOrder,
  updatePickupTask,
  updateStoreIssue,
} from 'src/lib/4/firebase-short-cut';
import { ConsoleLogger } from 'src/lib/5/logger';

import { TaskSpot } from './partners-util';

const logger = ConsoleLogger.getInstance();

/**
 * spot에 할당된 taskBundle을 order와 storeIssue로 분리한다.
 */
export const separateTaskSpotToOrder = (taskSpot: TaskSpot): { orders: OrderDoc[]; storeIssues: StoreIssueDoc[] } => {
  const taskBundles = taskSpot.stores.flatMap((s) => s.taskBundles);
  return taskBundles.reduce<{
    orders: OrderDoc[];
    storeIssues: StoreIssueDoc[];
  }>(
    (acc, cur) => {
      if (cur.orderColleciton === TaskOrderCollection.Order) {
        acc.orders.push(cur.data as OrderDoc);
      }
      if (cur.orderColleciton === TaskOrderCollection.StoreIssue) {
        acc.storeIssues.push(cur.data as StoreIssueDoc);
      }
      return acc;
    },
    {
      orders: [],
      storeIssues: [],
    }
  );
};

/**
 * 픽업 업무를 수동으로 완료처리합니다.
 */
export const finishPickupTask = async (pickupTask: PickupTaskDoc) => {
  const finishedPickupItems = Object.fromEntries(
    Object.entries(pickupTask.pickupItems).map(([key, value]) => [
      key,
      { ...value, itemStatus: TaskItemStatus.Completed },
    ])
  );
  const finishedAt = formatDate(Timestamp.now().toDate(), "yyyy-MM-dd'T'HH:mm:ss+0900");
  const updateData: Partial<PickupTask> = {
    finishedAt,
    pickupItems: finishedPickupItems,
  };
  await updatePickupTask(pickupTask._id, updateData, true);
};

/**
 * 픽업 업무의 담당 파트너를 변경합니다.
 */
export const changePickupTaskPartner = async (pickupTask: PickupTaskDoc, to: string) => {
  try {
    // 1. 업무를 받을 파트너의 상태를 확인합니다.
    const partner = await getPartnersUser(to);
    if (!partner) {
      throw new Error('파트너 정보를 찾을 수 없습니다.');
    }

    // 2. 연관된 주문 또는 이슈를 가져옵니다.
    if (pickupTask.orderColleciton === TaskOrderCollection.PurchaseOrder) {
      throw new Error('매입 주문은 파트너 변경이 불가능합니다.');
    }

    const taskOrder =
      pickupTask.orderColleciton === TaskOrderCollection.Order
        ? await getOrder(pickupTask.orderId)
        : await getStoreIssue(pickupTask.orderId);
    if (!taskOrder) {
      throw new Error('연관된 주문 또는 이슈를 찾을 수 없습니다.');
    }

    // 3. 파트너 변경
    batchStart();
    // 3-1. 업무 담당 파트너 변경
    await updatePickupTask(pickupTask._id, { partnerId: partner._id }, true);
    // 3-2. 주문 또는 이슈 담당 파트너 변경
    if (pickupTask.orderColleciton === TaskOrderCollection.Order) {
      await updateOrder(
        taskOrder._id,
        {
          pickupPartnerId: partner._id,
          pickupPartnerName: partner.name,
        },
        true
      );
      message.success('주문의 픽업 담당자 변경.');
    } else {
      await updateStoreIssue(
        taskOrder._id,
        {
          pickupPartnerId: partner._id,
          pickupPartnerName: partner.name,
        },
        true
      );
      message.success('이슈의 픽업 담당자 변경.');
    }
    await batchEnd();
  } catch (error) {
    console.error(error);
    const description = errorObjectToString(error);
    notification.error({
      message: '픽업 업무 파트너 변경 오류',
      description,
    });
    logger.logConsole(`픽업 업무 파트너 변경에서 오류가 발생했습니다.\n${description}`, {
      level: 'error',
    });
  }
};

/**
 * 배송 업무의 담당 파트너를 변경합니다.
 */
export const changeDeliveryTaskPartner = async (deliveryTask: DeliveryTaskDoc, to: string) => {
  try {
    // 1. 업무를 받을 파트너의 상태를 확인합니다.
    const partner = await getPartnersUser(to);
    if (!partner) {
      throw new Error('파트너 정보를 찾을 수 없습니다.');
    }

    // 2. 연관된 주문 또는 이슈를 가져옵니다.
    if (deliveryTask.orderColleciton === TaskOrderCollection.PurchaseOrder) {
      throw new Error('매입 주문은 파트너 변경이 불가능합니다.');
    }

    const taskOrder =
      deliveryTask.orderColleciton === TaskOrderCollection.Order
        ? await getOrder(deliveryTask.orderId)
        : await getStoreIssue(deliveryTask.orderId);
    if (!taskOrder) {
      throw new Error('연관된 주문 또는 이슈를 찾을 수 없습니다.');
    }

    // 3. 파트너 변경
    batchStart();
    // 3-1. 업무 담당 파트너 변경
    await updateDeliveryTask(deliveryTask._id, { partnerId: partner._id }, true);
    // 3-2. 주문 또는 이슈 담당 파트너 변경
    if (deliveryTask.orderColleciton === TaskOrderCollection.Order) {
      await updateOrder(
        taskOrder._id,
        {
          deliveryPartnerId: partner._id,
          deliveryPartnerName: partner.name,
        },
        true
      );
      message.success('주문의 배송 담당자 변경.');
    } else {
      await updateStoreIssue(
        taskOrder._id,
        {
          deliveryPartnerId: partner._id,
          deliveryPartnerName: partner.name,
        },
        true
      );
      message.success('이슈의 배송 담당자 변경.');
    }
    await batchEnd();
  } catch (error) {
    console.error(error);
    const description = errorObjectToString(error);
    notification.error({
      message: '배송 업무 파트너 변경 오류',
      description,
    });
    logger.logConsole(`배송 업무 파트너 변경에서 오류가 발생했습니다.\n${description}`, {
      level: 'error',
    });
  }
};

/**
 * 주문 정보로 업무를 조회합니다.
 */
export const getTasksForOrder = async (order: OrderDoc) => {
  const pickupTask = await getPickupTaskForOrder(order);
  const deliveryTask = await getDeliveryTaskForOrder(order);
  return { pickupTask, deliveryTask };
};

/**
 * 주문 상품을 업무로 변경합니다.
 */
export const convertOrderProductsToTaskItems = (orderProducts: OrderProduct[]): Record<string, TaskItem> => {
  return Object.fromEntries(
    orderProducts.map((product) => [
      product.productId,
      {
        productId: product.productId,
        itemStatus: TaskItemStatus.Assigned,
        issue: null,
      },
    ])
  );
};
