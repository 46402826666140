import { Dispatch } from 'react';
import { DndCardItem } from 'src/schema/schema-drag-and-drop';
import { ActionType, createReducer } from 'typesafe-actions';

import * as KanbanBoardActions from '../actions/kanban-board';

export type KanbanBoardState = {
  items: DndCardItem[];
  insertGroupId: string;
  insertIndex: number;
  lastSelectedIndex: number;
  hoverIndex: number;
  selectedItems: DndCardItem[];
  showDetail: boolean;
};

export type KanbanBoardDispatch = Dispatch<ActionType<typeof KanbanBoardActions>>;

export const initialState: KanbanBoardState = {
  items: [],
  insertGroupId: '',
  insertIndex: -1,
  lastSelectedIndex: -1,
  hoverIndex: -1,
  selectedItems: [],
  showDetail: false,
};

const getSelectionItems = (
  items: DndCardItem[],
  groupId: string,
  index: number,
  cmdKey: boolean,
  shiftKey: boolean,
  lastSelectedIndex: number,
  selectedItems: DndCardItem[]
) => {
  // 같은 그룹의 아이템들만 선택 가능하다.
  const filteredItems: DndCardItem[] = items.filter((i) => i.groupId === groupId);
  const item = filteredItems[index];

  if (!cmdKey && !shiftKey) {
    return [item];
  } else if (shiftKey) {
    if (lastSelectedIndex >= index) {
      return [...selectedItems, ...filteredItems.slice(index, lastSelectedIndex)];
    }
    return [...selectedItems, ...filteredItems.slice(lastSelectedIndex + 1, index + 1)];
  } else if (cmdKey) {
    const selectedIndex = selectedItems.findIndex((i) => i.id === item.id);
    if (selectedIndex > 0) {
      return [...selectedItems.slice(0, selectedIndex), ...selectedItems.slice(selectedIndex + 1)];
    }
  }
  return [...selectedItems, item];
};

const filterOutDraggedCards = (originalCards: DndCardItem[], draggedCardIds: string[]) => {
  return originalCards.filter((c) => !draggedCardIds.includes(c.id));
};

export const kanbanBoardReducer = createReducer<KanbanBoardState, ActionType<typeof KanbanBoardActions>>(initialState)
  .handleAction(KanbanBoardActions.setItems, (state, action) => {
    const { payload } = action;
    return {
      ...state,
      items: payload,
    };
  })
  .handleAction(KanbanBoardActions.setInsertGroupId, (state, action) => {
    const { payload } = action;
    return {
      ...state,
      insertGroupId: payload,
    };
  })
  .handleAction(KanbanBoardActions.setInsertIndex, (state, action) => {
    const { payload } = action;
    return {
      ...state,
      insertIndex: payload,
    };
  })
  .handleAction(KanbanBoardActions.setLastSelectedIndex, (state, action) => {
    const { payload } = action;
    return {
      ...state,
      lastSelectedIndex: payload,
    };
  })
  .handleAction(KanbanBoardActions.setHoverIndex, (state, action) => {
    const { payload } = action;
    return {
      ...state,
      hoverIndex: payload,
    };
  })
  .handleAction(KanbanBoardActions.setSelectedItems, (state, action) => {
    const { payload } = action;
    return {
      ...state,
      selectedItems: payload,
    };
  })
  .handleAction(KanbanBoardActions.setShowDetail, (state, action) => {
    const { payload } = action;
    return {
      ...state,
      showDetail: payload,
    };
  })
  .handleAction(KanbanBoardActions.clearSelection, (state) => {
    return {
      ...state,
      selectedItems: [],
    };
  })
  .handleAction(KanbanBoardActions.setInsertPosition, (state, action) => {
    const { groupId, hoverIndex, insertIndex } = action.payload;
    return {
      ...state,
      insertGroupId: groupId,
      hoverIndex,
      insertIndex,
    };
  })
  // 복합 처리
  .handleAction(KanbanBoardActions.changeSelectedItems, (state, action) => {
    const { items, lastSelectedIndex, selectedItems } = state;
    const { index, groupId, cmdKey, shiftKey } = action.payload;

    const newSelectedItems = getSelectionItems(
      items,
      groupId,
      index,
      cmdKey,
      shiftKey,
      lastSelectedIndex,
      selectedItems
    );

    return {
      ...state,
      lastSelectedIndex: index,
      selectedItems: newSelectedItems,
    };
  })
  .handleAction(KanbanBoardActions.moveItems, (state, action) => {
    const { items, insertGroupId, insertIndex } = state;
    const { draggedItems } = action.payload;
    const draggedItemIds = draggedItems.map(({ id }) => id);
    const movedItems = items
      .filter((i) => draggedItemIds.includes(i.id))
      .map((item) => ({
        ...item,
        groupId: insertGroupId,
      }));

    const groupItemsItems = items.filter((i) => i.groupId === insertGroupId);
    const otherItems = filterOutDraggedCards(
      items.filter((i) => i.groupId !== insertGroupId),
      draggedItemIds
    );
    const dividerIndex =
      insertIndex >= 0 && insertIndex < groupItemsItems.length ? insertIndex : groupItemsItems.length;

    const remainingItems = filterOutDraggedCards(groupItemsItems, draggedItemIds);
    const newItems = [
      ...otherItems,
      ...remainingItems.slice(0, dividerIndex),
      ...movedItems,
      ...remainingItems.slice(dividerIndex),
    ];

    return {
      ...state,
      items: newItems,
    };
  });
