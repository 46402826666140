import { Competitor } from '@gooduncles/gu-app-schema';
import { Checkbox, message } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { FC, useState } from 'react';
import {
  ProductCompetitorTrendTableRowOption,
  competitorList,
  competitorTrendRowOptions,
  generateCompetitorPriceTrendRowData,
} from 'src/utils/product-trend-util';

import { useCompetitorTrend } from 'src/hooks/useCompetitorTrend';
import useCompetitorTrendKeywordMap from 'src/hooks/useCompetitorTrendKeywordMap';
import { useTitle } from 'src/hooks/useTitle';

import ProductCompetitorTrendTable from 'src/components/ProductTrend/ProductCompetitorTrendTable';

/**
 * 경쟁사 가격 동향 페이지
 */
const ProductCompetitorTrendPage: FC = () => {
  useTitle('GU 관리자 | 경쟁사 가격 동향');
  const {
    weekList,
    productMap,
    productWeeklyPriceList,
    matchedDabomFoodProducts,
    matchedCJFreshwayProducts,
    matchedSehyeonFnBProducts,
    matchedFoodpangProducts,
    matchedDawnMarketProducts,
  } = useCompetitorTrend();
  const keywordMapConfig = useCompetitorTrendKeywordMap();
  const [showCompetitorProductName, setShowCompetitorProductName] = useState<boolean>(false);
  const [checkedRowOptions, setCheckedRowOptions] = useState(competitorTrendRowOptions);
  const [checkedGroupColumnOptions, setCheckedGroupColumnOptions] = useState(competitorList);
  const rowData = generateCompetitorPriceTrendRowData(
    weekList,
    productMap,
    productWeeklyPriceList,
    [
      ...matchedDabomFoodProducts,
      ...matchedCJFreshwayProducts,
      ...matchedSehyeonFnBProducts,
      ...matchedFoodpangProducts,
      ...matchedDawnMarketProducts,
    ],
    keywordMapConfig?.productCompetitorTrendKeywordMap ?? null
  );

  const onChangeRowOptions = (checkedValues: CheckboxValueType[]) => {
    if (checkedValues.length === 0) {
      message.error('최소 하나의 옵션은 선택해야 합니다.');
      return;
    }
    setCheckedRowOptions(checkedValues as ProductCompetitorTrendTableRowOption[]);
  };

  const onChangeGroupColumnOptions = (checkedValues: CheckboxValueType[]) => {
    if (checkedValues.length === 0) {
      message.error('최소 하나의 옵션은 선택해야 합니다.');
      return;
    }
    setCheckedGroupColumnOptions(checkedValues as Competitor[]);
  };

  return (
    <div className='tabBody flexColumn height100'>
      <section className='flexRow flexSpaceBetween'>
        <div>
          <Checkbox
            checked={showCompetitorProductName}
            onChange={(e) => setShowCompetitorProductName(e.target.checked)}>
            경쟁사 상품명 표시
          </Checkbox>
          {/* <Checkbox.Group
            options={competitorTrendRowOptions}
            defaultValue={competitorTrendRowOptions}
            value={checkedRowOptions}
            onChange={onChangeRowOptions}
          /> */}
        </div>
        <div>
          <Checkbox.Group
            options={competitorList}
            defaultValue={competitorList}
            value={checkedGroupColumnOptions}
            onChange={onChangeGroupColumnOptions}
          />
        </div>
      </section>
      <section className='height100'>
        <ProductCompetitorTrendTable
          rowData={rowData}
          weekList={weekList}
          showCompetitorProductName={showCompetitorProductName}
          checkedCompetitors={checkedGroupColumnOptions}
        />
      </section>
    </div>
  );
};

export default ProductCompetitorTrendPage;
