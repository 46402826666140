import { DeliveryTaskDoc } from '@gooduncles/gu-app-schema';
import { useEffect, useState } from 'react';

import { observeDeliveryTaskDoc } from 'src/lib/4/firebase-short-cut';

const useDeliveryTask = (deliveryTaskId: string | undefined) => {
  const [isLoading, setIsLoading] = useState(true);
  const [deliveryTask, setDeliveryTask] = useState<DeliveryTaskDoc | undefined>();

  useEffect(() => {
    if (deliveryTaskId) {
      setIsLoading(true);
      const subscription = observeDeliveryTaskDoc(deliveryTaskId).subscribe((doc) => {
        setDeliveryTask(doc);
        setIsLoading(false);
      });
      return () => {
        subscription.unsubscribe();
        setDeliveryTask(undefined);
        setIsLoading(false);
      };
    } else {
      setDeliveryTask(undefined);
      setIsLoading(false);
    }
  }, [deliveryTaskId]);

  return { deliveryTask, isLoading };
};

export default useDeliveryTask;
