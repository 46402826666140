import { FC, useEffect, useMemo, useState } from 'react';
import { combineLatest, from } from 'rxjs';

import { RangeEventValue, dayjsesToStringDateWhere, getDefaultDates } from 'src/lib/1/date-util';
import { NotificationDoc } from 'src/lib/3/schema-notification';
import { getStores, observeNotification } from 'src/lib/4/firebase-short-cut';

import TableOptions from 'src/components/Common/TableOptions/TableOptions';

import NotificationTable from './NotificationTable/NotificationTable';

const defaultValue = getDefaultDates(3, 3);

const NotificationList: FC = () => {
  const [rowData, setRowData] = useState<NotificationDoc[]>([]);
  const [dates, setDates] = useState<RangeEventValue>(null);
  const [startValue, endValue] = useMemo(() => {
    return dates?.[0] && dates?.[1]
      ? dayjsesToStringDateWhere(dates[0], dates[1])
      : dayjsesToStringDateWhere(defaultValue[0], defaultValue[1]);
  }, [dates]);

  useEffect(() => {
    const storesSubscription = from(getStores());
    const notificationSubscription = observeNotification([], {
      sortKey: 'publishDate',
      orderBy: 'desc',
      startValue,
      endValue,
    });

    const subscription = combineLatest([storesSubscription, notificationSubscription]).subscribe(
      ([stores, notifications]) => {
        const notiListWithStore = notifications.map((noti) => {
          const store = stores.find((store) => store.storeManagerId === noti.userId);
          return {
            ...noti,
            store,
          };
        });
        setRowData(notiListWithStore);
      }
    );
    return () => subscription.unsubscribe();
  }, [endValue, startValue]);

  return (
    <div className='height100 flexColumn'>
      <TableOptions setDates={setDates} defaultValue={defaultValue} />
      <NotificationTable rowData={rowData} />
    </div>
  );
};

export default NotificationList;
