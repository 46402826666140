import { ProductDoc } from '@gooduncles/gu-app-schema';
import { isArray } from 'lodash-es';

import { formatNumber } from 'src/lib/1/util';

import useProductById from 'src/hooks/useProductById';

const ProductInfoCellRenderer = (params: any, field: keyof ProductDoc, mapping?: Record<string, string>) => {
  const id = params.value;
  const { product } = useProductById(id);
  if (!product) return null;
  const value = product[field];
  if (value === null) return null;
  if (mapping) {
    return <span>{mapping[value as string]}</span>;
  }
  if (typeof value === 'boolean') return <span>{value ? '🟢' : '❌'}</span>;
  if (typeof value === 'string') return <span>{value}</span>;
  if (typeof value === 'number') return <span>{formatNumber(value)}</span>;
  if (isArray(value)) return <span>{value.join(', ')}</span>;
  if (typeof value === 'object') return <span>{JSON.stringify(value)}</span>;
  return null;
};

export default ProductInfoCellRenderer;
