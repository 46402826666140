import { StoreDoc, StoreIssueDoc } from '@gooduncles/gu-app-schema';
import { Button } from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';

import { RangeEventValue, dayjsesToStringDateWhere, getDefaultDates } from 'src/lib/1/date-util';
import { jsonToExcelFile } from 'src/lib/1/xlsx-util';
import { observeStoreIssue } from 'src/lib/4/firebase-short-cut';

import TableOptions from '../Common/TableOptions/TableOptions';
import CreateIssueButton from './StoreIssueTable/CreateIssueButton';
import StoreIssueTable from './StoreIssueTable/StoreIssueTable';

const defaultValue = getDefaultDates(3, 3);

type StoreIssueProps = {
  stores?: StoreDoc[];
  storeSearchValues: string[] | null;
};

const downloadStoreIssueToExcel = async (data: StoreIssueDoc[]) => {
  const issues = data.map((d) => {
    return {
      date: d.date,
      category: d.category,
      storeCode: d.storeCode,
      storeName: d.storeName,
      supplier: d.supplier,
      productId: d.productId,
      productName: d.productName,
      volume: d.volume,
      supplyPrice: d.supplyPrice,
      tax: d.tax,
      message: d.message,
      status: d.status,
      completedAt: d.completedAt,
    };
  });
  jsonToExcelFile(issues, '일일운영이슈', [
    [
      '일자',
      '구분',
      '식당코드',
      '매장명',
      '매입처명',
      '상품코드',
      '상품명',
      '수량',
      '공급가',
      '세액',
      '이슈내용(고객노출)',
      '진행상태',
      '완료일자',
    ],
  ]);
};

const StoreIssue: FC<StoreIssueProps> = ({ stores, storeSearchValues }) => {
  const [rowData, setRowData] = useState<StoreIssueDoc[]>([]);
  const [dates, setDates] = useState<RangeEventValue>(null);
  const [leftDate, rightDate] = useMemo(() => {
    return dates?.[0] && dates?.[1]
      ? dayjsesToStringDateWhere(dates[0], dates[1])
      : dayjsesToStringDateWhere(defaultValue[0], defaultValue[1]);
  }, [dates]);

  useEffect(() => {
    const subscription = observeStoreIssue([], {
      sortKey: 'date',
      orderBy: 'desc',
      startValue: leftDate,
      endValue: rightDate,
    }).subscribe((data) => {
      setRowData(data.filter((d) => !d.isDeleted));
    });
    return () => subscription.unsubscribe();
  }, [leftDate, rightDate]);

  return (
    <div className='height100 flexColumn'>
      <TableOptions setDates={setDates} defaultValue={defaultValue}>
        <Button onClick={() => downloadStoreIssueToExcel(rowData)} style={{ marginRight: 8 }}>
          엑셀 다운로드
        </Button>
        <CreateIssueButton />
      </TableOptions>
      <StoreIssueTable rowData={rowData} stores={stores} storeSearchValues={storeSearchValues} />
    </div>
  );
};

export default StoreIssue;
