import { EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import {
  OrderDoc,
  OrderProduct,
  OrderStatusCode,
  ProductDoc,
  ProductSnapshotDoc,
  StoreDoc,
} from '@gooduncles/gu-app-schema';
import { Button, Modal, Tooltip, message, notification } from 'antd';
import { FC, useCallback, useEffect, useReducer, useRef, useState } from 'react';
import {
  addProduct,
  removeProduct,
  resetProducts,
  setOrderProducts,
  setProductVolume,
} from 'src/actions/order-products';
import { initialState, orderProductsReducer } from 'src/reducers/order-products';

import { formatNumber } from 'src/lib/1/util';
import { getProductById } from 'src/lib/4/firebase-short-cut';
import { getProductBaseFromProduct } from 'src/lib/4/product-util';
import { OrderWithStore } from 'src/lib/5/order-util';

import useOrder from 'src/hooks/useOrder';
import useOrderProductsForStore from 'src/hooks/useOrderProductsForStore';
import useStoresForSearch from 'src/hooks/useStoresForSearch';

import AlgoliaSearch from 'src/components/AlgoriaSearch/AlgoriaSearch';
import FlexRowItem from 'src/components/Common/FlexRowItem/FlexRowItem';
import OrderProductItem from 'src/components/Common/OrderProductItem/OrderProductItem';
import SearchWithPreview from 'src/components/Common/SearchWithPreview/SearchWithPreview';

import classes from './EditOrderModal.module.scss';

const getOrderProduct = (product: ProductDoc | ProductSnapshotDoc): OrderProduct => {
  const productBase = getProductBaseFromProduct(product);
  return {
    ...productBase,
    volume: 1,
    snapshotId: null,
    snapshotPrice: null,
    priceDiff: null,
  };
};

interface EditOrderModalProps {
  ordersWithStore: OrderWithStore[];
  disabled: boolean;
}

const EditOrderModal: FC<EditOrderModalProps> = ({ ordersWithStore, disabled }) => {
  const cartRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [state, dispatch] = useReducer(orderProductsReducer, initialState);
  const [matchedOrder, setMatchedOrder] = useState<OrderDoc | null>(null);
  const { isLoading, onCreateOrder, onUpdateOrderDoc } = useOrder();

  const [selectedStore, setSelectedStore] = useState<StoreDoc | null>(null);
  const { orderProducts, orderProductsLoading } = useOrderProductsForStore(selectedStore?._id ?? null);

  const { stores, storeSearchValues } = useStoresForSearch([['state', '!=', 'closed']]);

  const onStoreSearch = useCallback(
    (value: string) => {
      const selectedStoreCode = value.split('-')?.[0] ?? null;
      if (selectedStoreCode) {
        const store = stores?.find((store) => selectedStoreCode === store.storeCode);
        if (store) {
          message.success(store.storeNickname);
          dispatch(resetProducts());
          setSelectedStore(store);
        } else {
          notification.error({ message: '매장을 찾을 수 없습니다.' });
          setSelectedStore(null);
        }
      }
    },
    [stores]
  );

  // 추후 다른 주문을 생성하기 위해서는 snapshot이 필요하기 때문.
  const onProductSearch = useCallback(
    async (value: string) => {
      const selectedProductId = value.split('_')?.[0] ?? null;
      if (selectedProductId) {
        if (state.orderProducts[selectedProductId]) {
          notification.info({ message: '이미 추가된 상품입니다.' });
          return;
        }

        try {
          const product = await getProductById(selectedProductId);
          if (product) {
            const orderProduct = getOrderProduct(product);
            dispatch(addProduct(orderProduct));
            message.success(orderProduct.fullName);
          } else {
            notification.error({ message: '상품을 찾을 수 없습니다.' });
          }
        } catch (error) {
          notification.error({ message: '에러 발생' });
        }
      }
    },
    [state.orderProducts]
  );

  const onVolumeChange = (productId: string, volume: number) => {
    dispatch(setProductVolume({ productId, volume }));
  };

  const onRemove = (productId: string) => {
    dispatch(removeProduct(productId));
  };

  const reset = useCallback((showMsg = true) => {
    setSelectedStore(null);
    setMatchedOrder(null);
    dispatch(resetProducts());
    if (showMsg) {
      message.success('초기화 완료');
    }
  }, []);

  const onOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const onCancel = useCallback(() => {
    reset(false);
    setOpen(false);
  }, [reset]);

  /**
   * 최종 주문 액션을 처리한다.
   */
  const onEditOrder = useCallback(async () => {
    if (!selectedStore || !state.orderProducts) {
      notification.error({ message: '매장 또는 장바구니 정보가 없습니다.' });
      return;
    }

    if (!selectedStore.storeManagerId) {
      notification.error({ message: '매장 관리자 정보가 없습니다.' });
      return;
    }

    if (matchedOrder) {
      await onUpdateOrderDoc(selectedStore, matchedOrder, Object.values(state.orderProducts));
      reset(false);
      setOpen(false);
    } else {
      const result = await onCreateOrder(
        selectedStore.storeManagerId,
        selectedStore,
        Object.values(state.orderProducts)
      );
      if (result) {
        reset(false);
        setOpen(false);
      }
    }
    return;
  }, [selectedStore, state.orderProducts, matchedOrder, reset, onUpdateOrderDoc, onCreateOrder]);

  useEffect(() => {
    if (cartRef.current) {
      cartRef.current.scrollTop = cartRef.current.scrollHeight;
    }
  }, [state.length]);

  // 입력하려는 매장의 주문이 이미 존재하는지 확인하고, 존재한다면 해당 주문을 불러온다.
  useEffect(() => {
    if (open) {
      const order = ordersWithStore
        .filter((o) => [OrderStatusCode.SUBMITTED, OrderStatusCode.ACCEPTED].includes(o.orderStatus))
        .find((s) => s.store?.storeCode === selectedStore?.storeCode);
      if (order) {
        setMatchedOrder((prev) => {
          if (prev?._id === order._id && prev?._timeUpdate?.seconds === order._timeUpdate?.seconds) {
            return prev;
          }
          return order;
        });
      } else {
        setMatchedOrder(null);
      }
    }
  }, [open, ordersWithStore, selectedStore?.storeCode]);

  useEffect(() => {
    if (open) {
      if (matchedOrder) {
        message.info('주문을 동기화합니다.');
        dispatch(setOrderProducts(matchedOrder.products));
      } else {
        dispatch(resetProducts());
      }
    }
  }, [open, matchedOrder]);

  return (
    <>
      <div className={classes.tableButtons}>
        <Tooltip title="'수락대기', '주문수락' 체크박스를 모두 선택한 후 사용할 수 있습니다.">
          <Button onClick={onOpen} type='primary' disabled={disabled} icon={<EditOutlined />}>
            주문 편집기
          </Button>
        </Tooltip>
      </div>
      <Modal
        open={open}
        title={
          <Tooltip title="사용법: 매장 검색 → 선택 → 상품 검색 → 선택 → 추가된 상품의 수량조절 → '주문 생성'">
            주문 편집기 <InfoCircleOutlined />
          </Tooltip>
        }
        onCancel={onCancel}
        width='900px'
        centered
        keyboard={false}
        maskClosable={false}
        okText={matchedOrder ? '주문 수정' : '주문 생성'}
        cancelText='닫기'
        confirmLoading={isLoading}
        onOk={onEditOrder}
        okButtonProps={{ disabled: state.length < 1 }}>
        <div className={classes.editOrderContainer}>
          <div className={classes.searchPanel}>
            <FlexRowItem label='매장' labelStyle={{ color: 'var(--gray800)', fontWeight: 700 }} />
            <SearchWithPreview
              rowData={storeSearchValues}
              onSearch={onStoreSearch}
              selectOnly={true}
              closeOnClick={true}
              placeholder='매장코드 또는 매장명(관리)으로 검색'
            />
            <div className={classes.storeInfo}>
              <FlexRowItem
                label='매장명(관리)'
                value={selectedStore?.storeNickname ?? '미입력'}
                valueStyle={!selectedStore?.storeNickname ? { color: 'red' } : undefined}
              />
              <FlexRowItem
                label='매장코드'
                value={selectedStore?.storeCode ?? '미입력'}
                valueStyle={!selectedStore?.storeCode ? { color: 'red' } : undefined}
              />
            </div>
            <FlexRowItem
              label={selectedStore ? '상품' : '매장을 먼저 선택해주세요.'}
              labelStyle={{ color: 'var(--gray800)', fontWeight: 700 }}
            />
            <AlgoliaSearch placeholder='상품코드 또는 상품명으로 검색' onSearch={onProductSearch} />
            <p className={classes.tip}>※ 상품 선택시 오른쪽 장바구니에 추가됩니다.</p>

            <FlexRowItem
              label={
                selectedStore
                  ? `이전 주문 상품: ${
                      orderProductsLoading ? '읽는중...' : orderProducts?.length ? `${orderProducts?.length}개` : '없음'
                    }`
                  : '매장을 먼저 선택해주세요.'
              }
              labelStyle={{ color: 'var(--gray800)', fontWeight: 700 }}
            />
            <SearchWithPreview
              rowData={orderProducts?.map((p) => `${p.productId}_${p.fullName}`) ?? null}
              onSearch={onProductSearch}
              selectOnly={true}
              placeholder={!orderProducts?.length ? '3개월간 주문한 상품이 없습니다.' : '상품코드 또는 상품명으로 검색'}
              disabled={!orderProducts?.length}
            />
            {/* 즐겨찾기 상품 추가 */}
            <div className={classes.actions}>
              <Button onClick={() => reset()}>입력정보 초기화</Button>
            </div>
          </div>

          <div className={classes.cartPanel}>
            <FlexRowItem label='장바구니' labelStyle={{ color: 'var(--gray800)', fontWeight: 700 }} />
            <div className={classes.cartContainer} ref={cartRef}>
              <div className={classes.marginBox} />
              {state.orderProducts &&
                Object.entries(state.orderProducts).map(([key, product]) => (
                  <OrderProductItem
                    key={key}
                    orderProduct={product}
                    onVolumeChange={onVolumeChange}
                    onRemove={onRemove}
                    style={{ borderBottom: '1px dashed var(--gray200)' }}
                  />
                ))}
            </div>
            <div className={classes.summary}>
              <FlexRowItem
                label='총 주문금액'
                value={`${formatNumber(state.totalPrice)} 원`}
                valueStyle={{
                  fontSize: '16px',
                  fontWeight: '600',
                  textAlign: 'right',
                }}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditOrderModal;
