import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import InvoiceMessagePage from './InvoiceMessagePage';
import InvoicePage from './InvoicePage/InvoicePage';
import SettlementByStorePage from './SettlementByStorePage';
import SettlementHistoryPage from './SettlementHistoryPage/SettlementHistoryPage';

const SettlementPage: FC = () => {
  return (
    <Routes>
      <Route path='/invoice' element={<InvoicePage />} />
      <Route path='/store/:code' element={<SettlementByStorePage />} />
      <Route path='/store' element={<SettlementByStorePage />} />
      <Route path='/history' element={<SettlementHistoryPage />} />
      <Route path='/message' element={<InvoiceMessagePage />} />
      <Route path='*' element={<Navigate to='./invoice' replace />} />
    </Routes>
  );
};

export default SettlementPage;
