import { Button, Switch } from 'antd';
import { FC, useCallback, useEffect, useReducer } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import withScrolling from 'react-dnd-scrolling';
import {
  changeSelectedItems,
  clearSelection,
  moveItems,
  setInsertPosition,
  setItems,
  setShowDetail,
} from 'src/actions/kanban-board';
import { initialState, kanbanBoardReducer } from 'src/reducers/kanban-board';
import { DndCardDragResult, DndCardItem, DndColumnData } from 'src/schema/schema-drag-and-drop';

import DndCard from 'src/atomic-components/molecules/DndCard/DndCard';
import DndColumn from 'src/atomic-components/organisms/DndColumn/DndColumn';

import classes from './KanbanBoard.module.scss';

/**
 * {@link https://www.npmjs.com/package/react-dnd-scrolling react-dnd-scrolling}
 * 카드를 드래그할 때 스크롤이 자동으로 움직이도록 한다.
 */
const ScrollingComponent = withScrolling('div');

interface KanbanBoardProps {
  columns: DndColumnData[];
  rowData: DndCardItem[];
  /** 위치 변경된 카드들을 데이터에 반영하기 위한 함수 */
  onItemsChange: (items: DndCardItem[], columns: DndColumnData[]) => Promise<void>;
  /** 위치 변경된 카드들을 임시로 저장하기 위한 함수 */
  onItemsChangeTemporary?: (items: DndCardItem[], columns: DndColumnData[]) => Promise<void>;
  onClearItemsChangeTemporary?: () => void;
  disableItemsChangeTemporary?: boolean;
  disableItemsChange?: boolean;
  headerMaker?: (column: DndColumnData, items: DndCardItem[]) => JSX.Element;
}

const KanbanBoard: FC<KanbanBoardProps> = ({
  columns,
  rowData,
  onItemsChange,
  onItemsChangeTemporary,
  onClearItemsChangeTemporary,
  disableItemsChangeTemporary,
  disableItemsChange,
  headerMaker,
}) => {
  const [state, dispatch] = useReducer(kanbanBoardReducer, initialState);

  const onSetShowDetail = (value: boolean) => dispatch(setShowDetail(value));
  const onResetItems = () => dispatch(setItems(rowData));
  const onClearSelection = () => dispatch(clearSelection());
  const onSetInsertPosition = (groupId: string, hoverIndex: number, insertIndex: number) =>
    dispatch(setInsertPosition({ groupId, hoverIndex, insertIndex }));

  const onChangeSelectedItems = (index: number, groupId: string, cmdKey: boolean, shiftKey: boolean) =>
    dispatch(
      changeSelectedItems({
        index,
        groupId,
        cmdKey,
        shiftKey,
      })
    );

  const onMoveItems = (dragResult: DndCardDragResult) => dispatch(moveItems(dragResult));

  const getCardItems = useCallback(
    (columnId: string) => {
      const { items, hoverIndex, insertGroupId, insertIndex, selectedItems, showDetail } = state;
      return items
        .filter((i) => i.groupId === columnId)
        .map((item, index) => {
          const showInsertLine = hoverIndex === index && item.groupId === insertGroupId;
          const insertTop = showInsertLine && insertIndex === index;
          const insertBottom = showInsertLine && insertIndex === index + 1;
          return (
            <DndCard
              key={item.id}
              isSelected={selectedItems.some((i) => i.id === item.id)}
              item={{ ...item, index }}
              selectedItems={selectedItems}
              insertLineTop={insertTop}
              insertLineBottom={insertBottom}
              clearSelection={onClearSelection}
              setInsertPosition={onSetInsertPosition}
              onChangeSelectedItems={onChangeSelectedItems}
              moveItems={onMoveItems}
              style={item.cardStyle}>
              {/* {item.element} */}
              {showDetail && item.detailElement ? item.detailElement : item.element}
            </DndCard>
          );
        });
    },
    [state]
  );

  useEffect(() => {
    dispatch(setItems(rowData));
  }, [rowData]);

  return (
    <DndProvider backend={HTML5Backend}>
      <ScrollingComponent className={classes.boardContainer}>
        <div className={classes.boardToolbar}>
          <div className={classes.buttons}>
            <Button onClick={onResetItems}>되돌리기</Button>
            {onItemsChangeTemporary && (
              <>
                <Button
                  onClick={() => onItemsChangeTemporary(state.items, columns)}
                  disabled={disableItemsChangeTemporary}>
                  임시저장
                </Button>
                <Button onClick={onClearItemsChangeTemporary} disabled={disableItemsChangeTemporary} danger>
                  임시저장 초기화
                </Button>
              </>
            )}
            <Button type='primary' onClick={() => onItemsChange(state.items, columns)} disabled={disableItemsChange}>
              확정
            </Button>
          </div>
          <div className={classes.options}>
            <Switch
              checkedChildren='상세보기'
              unCheckedChildren='작게보기'
              checked={state.showDetail}
              onChange={(e) => onSetShowDetail(e)}
            />
          </div>
        </div>

        <div className={classes.kanbanBoard}>
          <section className={classes.leftSection}>
            {columns
              .filter((column) => !column.pinRight)
              .map((column) => (
                <DndColumn
                  key={column.id}
                  title={column.title}
                  groupId={column.id}
                  headerComponent={headerMaker ? headerMaker(column, state.items) : undefined}
                  selectedItems={state.selectedItems}
                  clearSelection={clearSelection}
                  setInsertPosition={onSetInsertPosition}>
                  {getCardItems(column.id)}
                </DndColumn>
              ))}
          </section>
          <div className={classes.horizontal}>
            <div className={classes.bar} />
          </div>
          <section className={classes.rightSection}>
            {columns
              .filter((column) => column.pinRight)
              .map((column) => (
                <DndColumn
                  key={column.id}
                  title={column.title}
                  groupId={column.id}
                  headerComponent={headerMaker ? headerMaker(column, state.items) : undefined}
                  selectedItems={state.selectedItems}
                  clearSelection={clearSelection}
                  setInsertPosition={onSetInsertPosition}
                  pinRight={column.pinRight}>
                  {getCardItems(column.id)}
                </DndColumn>
              ))}
          </section>
        </div>
      </ScrollingComponent>
    </DndProvider>
  );
};

export default KanbanBoard;
