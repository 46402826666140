import { FC, useEffect, useState } from 'react';
import { combineLatest } from 'rxjs';

import { SupplierDoc } from 'src/lib/3/schema-supplier';
import { observeProduct, observeSupplier } from 'src/lib/4/firebase-short-cut';

import { useTitle } from 'src/hooks/useTitle';

import ProductTable, { RowData } from './ProductTable/ProductTable';

const Product: FC = () => {
  useTitle('GU 관리자 | 상품 관리');
  const [rowData, setRowData] = useState<RowData[]>([]);
  const [suppliers, setSuppliers] = useState<SupplierDoc[]>([]);

  useEffect(() => {
    const productSubscription = observeProduct();
    const supplierSubscription = observeSupplier([['isDeleted', '==', false]]);
    const subscription = combineLatest([productSubscription, supplierSubscription]).subscribe(
      ([products, suppliers]) => {
        const conversionTable = suppliers.flatMap((s) => s.conversionTable);
        const rowData: RowData[] = products.map((product) => {
          const conversionItem = conversionTable.find((c) => c.productId === product.productId);
          // const suppliersForProduct = suppliers.filter((s) => s.conversionTable.map((c) => c.productId).includes(product.productId));
          const supplier = suppliers.find((s) => s.conversionTable.map((c) => c.productId).includes(product.productId));
          return {
            ...product,
            conversionItem,
            supplierName: supplier?.name ?? 'unknown',
          };
        });
        setRowData(rowData);
        setSuppliers(suppliers);
      }
    );

    return () => subscription.unsubscribe();
  }, []);

  return <ProductTable rowData={rowData} suppliers={suppliers} />;
};

export default Product;
