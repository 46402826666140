import { FC } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import AlimtalkMessage from 'src/components/AlimtalkMessage/AlimtalkMessage';
import FriendtalkMessage from 'src/components/FriendtalkMessage/FriendtalkMessage';
import MobileMessageDashboard from 'src/components/MobileMessage/MobileMessageDashboard';

const KakaoMessagePage: FC = () => {
  return (
    <Routes>
      <Route
        path='/alimtalk'
        element={
          <div className='height100 flexColumn'>
            <Outlet />
          </div>
        }>
        <Route index element={<Navigate to='installment-bill' replace />} />
        <Route path='installment-bill' element={<AlimtalkMessage tab='installment-bill' />} />
        <Route path='volume-change' element={<AlimtalkMessage tab='volume-change' />} />
        <Route path='sold-out-product' element={<AlimtalkMessage tab='sold-out-product' />} />
        <Route path='deadline-notice' element={<AlimtalkMessage tab='deadline-notice' />} />
      </Route>

      <Route
        path='/friendtalk'
        element={
          <div className='height100 flexColumn'>
            <Outlet />
          </div>
        }>
        <Route path='daily-price-list' element={<FriendtalkMessage tab='daily-price-list' />} />
        <Route path='custom' element={<FriendtalkMessage tab='custom' />} />
        <Route path='*' element={<Navigate to='daily-price-list' replace />} />
      </Route>
      <Route path='/sms' element={<MobileMessageDashboard />} />
      <Route path='/' element={<Navigate to='/alimtalk' replace />} />
    </Routes>
  );
};

export default KakaoMessagePage;
