import { partition } from 'lodash-es';

import { AlimtalkRecipientWithResultItem } from '../3/schema-alimtalk';
import { FriendtalkRecipientWithResultItem } from '../3/schema-friendtalk';

/**
 * 요청 결과를 정리한 문자열을 생성한다.
 * @param recipientWithResultList
 */
export function organizeKakaotalkResultMessage<T>(
  recipientWithResultList: (AlimtalkRecipientWithResultItem<T> | FriendtalkRecipientWithResultItem)[]
) {
  const [success, fail] = partition(recipientWithResultList, (item) => item.result?.requestResult.result === 'success');
  // 수신자가 한 명인 메시지의 경우는 짧은 텍스트로 요약한다.
  if (recipientWithResultList.length === 1) {
    return success.length > 0 ? "요청 성공\n실제 수신여부는 '카톡 메시지 결과'에서 확인해주세요." : '요청 실패';
  }
  return `요청: ${recipientWithResultList.length}건, 성공: ${success.length}건, 실패: ${fail.length}건\n실제 수신여부는 '카톡 메시지 결과'에서 확인해주세요.`;
}

// import axios, { AxiosResponse } from 'axios';

// import { isProduction } from '../1/constant';
// import { FriendTalkImageUploadResponse } from '../2/schema-nhn-notification';

// const url = isProduction
//   ? 'https://us-central1-gooduncles-dev.cloudfunctions.net/friendtalkImageUpload'
//   : 'https://us-central1-gooduncles-dev.cloudfunctions.net/friendtalkImageUpload';

// /**
//  * 카카오 친구톡에 사용할 이미지를 업로드한다.
//  * @param image
//  * @returns
//  */
// export const uploadImageForFriendtalk = async (image: Blob, imageName: string) => {
//   const formData = new FormData();
//   const fileName = imageName + '.png';
//   const file = new File([image], fileName, { type: 'image/png' });
//   formData.append('image', file, fileName);
//   const { data } = await axios.post<any, AxiosResponse<FriendTalkImageUploadResponse>>(url, formData, {
//     headers: { 'Content-Type': 'multipart/form-data' },
//   });
//   return data;
// };
