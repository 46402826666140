import useSWRImmutable from 'swr/immutable';

import { getOrderProductIdsForStoreWithRange } from 'src/lib/4/firebase-short-cut';

const useOrderProductsForStore = (storeId: string | null) => {
  const { data: orderProducts, isValidating: orderProductsLoading } = useSWRImmutable(
    storeId ? `orders/${storeId}` : null,
    getOrderProductIdsForStoreWithRange,
    {
      errorRetryCount: 2,
    }
  );

  return {
    orderProducts,
    orderProductsLoading,
  };
};

export default useOrderProductsForStore;
