import { DeleteOutlined, SendOutlined } from '@ant-design/icons';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { ColDef, ColGroupDef } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { Button, Popconfirm, notification } from 'antd';
import { Timestamp } from 'firebase/firestore';
import { chunk } from 'lodash-es';
import { FC, useCallback, useMemo, useState } from 'react';
import { callSendKakaoAlimTalkForBill } from 'src/utils/firebase-callable';

import { isProduction, senderKey } from 'src/lib/1/constant';
import { dateFormat07, dateFormat08 } from 'src/lib/1/date-util';
import { InstallmentBillTemplate } from 'src/lib/1/schema-alimtalk-template';
import { encryptAES, errorObjectToString, formatNumber, sleep } from 'src/lib/1/util';
import { KakaoAlimtalkRecipientItem } from 'src/lib/2/schema-nhn-notification';
import { FirebaseManager } from 'src/lib/3/firebase-manager';
import { InstallmentBillDoc } from 'src/lib/3/schema-bill';
import { getAlimtalkMessage, updateInstallmentBill } from 'src/lib/4/firebase-short-cut';
import { organizeKakaotalkResultMessage } from 'src/lib/4/nhn-notification-util';
import { onValueSetterWithValidation, timestampFormatter } from 'src/lib/6/ag-grid-util';
import { onCellValueChangedWithUpdate } from 'src/lib/6/ag-grid-util';

import SyncMessageResultButton from 'src/components/AgGrid/CellRenderer/SyncMessageResultButton/SyncMessageResultButton';
import KakaotalkContentPopover from 'src/components/AlimtalkMessage/AlimtalkContentPopover/AlimtalkContentPopover';
import DeleteDataCell from 'src/components/Common/DeleteDataCell/DeleteDataCell';
import DownloadRawDataCell from 'src/components/Common/DownloadRawDataCell/DownloadRawDataCell';

import InstallmentBillModal from './InstallmentBillModal';

const firebaseManager = FirebaseManager.getInstance();
const installmentBillPath = 'installmentBill';

const defaultColDef: ColDef<InstallmentBillDoc> = {
  flex: 1,
  resizable: true,
  sortable: true,
  filter: true,
  minWidth: 120,
};

type InstallmentBillTableProps = {
  rowData: InstallmentBillDoc[];
};

const onCellValueChanged = onCellValueChangedWithUpdate<InstallmentBillDoc>('정산 관리');

/**
 * 카카오톡 알림톡을 발송하고 해당 row를 업데이트한다.
 */
const onSendKakaoAlimTalk = async (recipientList: KakaoAlimtalkRecipientItem<InstallmentBillTemplate>[]) => {
  try {
    // 1. 카카오톡 전송
    if (!senderKey) {
      throw new Error('senderKey가 없습니다.');
    }

    const result = await callSendKakaoAlimTalkForBill({
      senderKey,
      recipientList,
      templateCode: 'installment-bill',
    });

    if (result.data.result !== 'success') {
      throw new Error(`카카오톡 전송 요청에 실패했습니다. ${result.data.reason ?? '알 수 없는 오류'}`);
    }

    if (!result.data.alimtalkMessageDocId) {
      throw new Error('요청은 성공했으나, ID를 받지 못했습니다.');
    }

    const alimtalkMessageDoc = await getAlimtalkMessage<InstallmentBillTemplate>(result.data.alimtalkMessageDocId);
    if (!alimtalkMessageDoc) {
      throw new Error('요청은 성공했으나, 메시지 정보를 받지 못했습니다.');
    }

    // 요청 결과를 메시지로 정리한다.
    const description = organizeKakaotalkResultMessage(alimtalkMessageDoc.recipientWithResultList);

    notification.success({
      message: '전송 요청 성공',
      description,
      className: 'pre-line-notification',
    });
  } catch (error) {
    console.error(error);
    const errorMessage = errorObjectToString(error);
    notification.error({
      message: '정산 목록 전송에 실패했습니다.',
      description: errorMessage,
    });
  }
};

const InstallmentBillTable: FC<InstallmentBillTableProps> = ({ rowData }) => {
  const [gridRef, setGridRef] = useState<AgGridReact<InstallmentBillDoc> | null>(null);
  const [loading, setLoading] = useState(false);

  const columnDefs: (ColDef<InstallmentBillDoc> | ColGroupDef<InstallmentBillDoc>)[] = useMemo(
    () => [
      { field: '_id', hide: true },
      {
        field: '_timeCreate',
        hide: true,
        valueFormatter: timestampFormatter,
      },
      {
        headerName: 'raw data',
        minWidth: 120,
        hide: true,
        cellRenderer: DownloadRawDataCell,
      },
      {
        headerName: '개별',
        pinned: 'right',
        maxWidth: 130,
        minWidth: 124,
        cellRenderer: InstallmentBillModal,
      },
      {
        field: 'settlementDate',
        headerName: '정산일',
        minWidth: 160,
        valueFormatter: ({ value }) => (value ? dateFormat07(value) : ''),
        cellRenderer: 'agGroupCellRenderer',
      },
      {
        field: 'storeCode',
        headerName: '코드',
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        showDisabledCheckboxes: true,
      },
      { field: 'storeName', headerName: '매장명', minWidth: 180 },
      {
        field: 'receiverTel',
        headerName: '수신인',
        minWidth: 150,
      },
      {
        headerName: '요청결과',
        valueGetter: ({ data }) => {
          const { alimtalkMessage } = data as InstallmentBillDoc;
          if (alimtalkMessage?.result?.requestResult.result) {
            return alimtalkMessage.result.requestResult.result === 'success'
              ? '🟢 성공'
              : `❌ ${alimtalkMessage.result.requestResult.error}`;
          }
          return null;
        },
      },
      {
        headerName: '발송결과',
        valueGetter: ({ data }) => {
          const { alimtalkMessage } = data as InstallmentBillDoc;
          if (alimtalkMessage?.result?.messageResult?.resultCode) {
            return alimtalkMessage.result.messageResult.resultCode === '1000'
              ? '🟢 성공'
              : `❌ ${alimtalkMessage.result.messageResult.error}`;
          }
          return null;
        },
        cellRenderer: (props: any) => {
          const { value, data } = props;
          if (value) return value;
          const { alimtalkMessage } = data as InstallmentBillDoc;
          if (alimtalkMessage) {
            const { alimtalkMessageDocId, requestId, recipientSeq, result } = alimtalkMessage;
            if (result?.messageResult) {
              return (
                <SyncMessageResultButton
                  type='alimtalk'
                  messageDocId={alimtalkMessageDocId}
                  requestId={requestId}
                  recipientSeq={recipientSeq}
                />
              );
            }
          }
          return '⚠️ 요청 결과 없음';
        },
      },
      {
        headerName: '발송내용',
        field: 'alimtalkMessage.result.messageResult.content',
        cellRenderer: KakaotalkContentPopover,
        cellRendererParams: (props: any) => {
          const { alimtalkMessage } = props.data as InstallmentBillDoc;
          if (alimtalkMessage?.result?.messageResult?.buttons) {
            return {
              buttons: alimtalkMessage.result.messageResult.buttons,
            };
          }
        },
      },
      {
        field: 'manualSending',
        headerName: '수동발송',
        editable: true,
        valueFormatter: ({ value }) => (value ? '🟢' : '-'),
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: {
          values: [true, false],
        },
        valueSetter: (params) => onValueSetterWithValidation(params, ['_id']),
        onCellValueChanged: (params) =>
          onCellValueChanged(params, installmentBillPath, params.data._id, 'manualSending'),
      },
      { field: 'settlementType', headerName: '정산방식', maxWidth: 100 },
      {
        field: 'orderAmount',
        headerName: '금주 주문금액',
        valueFormatter: ({ value }) => formatNumber(value),
      },
      {
        field: 'prevUnpaidAmount',
        headerName: '이전 미수금',
        valueFormatter: ({ value }) => formatNumber(value),
      },
      {
        field: 'settlementAmount',
        headerName: '금주 정산액',
        valueFormatter: ({ value }) => formatNumber(value),
      },
      {
        field: 'unpaidAmount',
        headerName: '미정산 금액',
        valueFormatter: ({ value }) => formatNumber(value),
      },
      {
        field: 'message',
        headerName: '안내문구',
      },
      {
        headerName: '삭제',
        field: '_id',
        cellRenderer: (params: any) => <DeleteDataCell collection={installmentBillPath} id={params.value} />,
      },
    ],
    []
  );

  const detailCellRendererParams = useMemo(() => {
    const detailColumnDefs: (ColDef<InstallmentBillDoc> | ColGroupDef<InstallmentBillDoc>)[] = [
      {
        headerName: '일자',
        field: 'date',
      },
      {
        headerName: '총 금액',
        field: 'totalAmount',
        valueFormatter: ({ value }) => formatNumber(value),
      },
      {
        headerName: '공급가',
        field: 'supplyAmount',
        valueFormatter: ({ value }) => formatNumber(value),
      },
      {
        headerName: '세액',
        field: 'tax',
        valueFormatter: ({ value }) => formatNumber(value),
      },
    ];

    return {
      detailGridOptions: {
        columnDefs: detailColumnDefs,
        defaultColDef,
        onFirstDataRendered: (params: any) => {
          params.api.sizeColumnsToFit();
        },
      },
      getDetailRowData: (params: any) => {
        const bill = params.data as InstallmentBillDoc;
        const data = bill.detail;
        params.successCallback(data);
      },
    };
  }, []);

  const isRowSelectable = useMemo(() => {
    return (params: any) => {
      const data: InstallmentBillDoc = params.data;
      return !!data?.receiverTel;
    };
  }, []);

  /**
   * 카톡으로 정산서 전송
   */
  const sendBills = useCallback(async () => {
    if (gridRef) {
      const rows = gridRef.api.getSelectedRows();
      if (rows.length === 0) {
        notification.info({ message: '선택된 정산서가 없습니다.' });
        return;
      }

      const recipientList = rows
        .filter((b) => b.receiverTel)
        .map((bill) => ({
          recipientNo: bill.receiverTel,
          templateParameter: {
            date: dateFormat08(new Date(bill.settlementDate)),
            store: bill.storeName,
            amount: formatNumber(bill.unpaidAmount),
            base: isProduction ? 'app.gooduncles.com/bill' : 'dev.app.gooduncles.com/bill',
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            user: encodeURIComponent(encryptAES(bill.receiverTel!)),
            bill: bill._id,
          },
          recipientGroupingKey: bill._id,
        }))
        .filter((r) => r) as KakaoAlimtalkRecipientItem<InstallmentBillTemplate>[];

      setLoading(true);

      // 대량 발송의 경우 간헐적으로 콜백을 받지 못하는 에러가 발생하여 20개씩 나눠서 보낸다.
      const chunkSize = 20;
      const chunkedRecipientList = chunk(recipientList, chunkSize);
      for (const chunkedRecipientListItem of chunkedRecipientList) {
        await onSendKakaoAlimTalk(chunkedRecipientListItem);
        // 정상 콜백 처리를 위해 1초씩 딜레이를 준다.
        await sleep(300);
      }
      setLoading(false);
    }
  }, [gridRef]);

  /** 화면에 보이는 모든 정산서를 삭제 처리합니다. */
  const deleteSelectedRows = useCallback(async () => {
    setLoading(true);
    firebaseManager.batchStart();
    const length = rowData.length;
    try {
      for (const row of rowData) {
        updateInstallmentBill(row._id, { isDeleted: true, _timeDelete: Timestamp.now() }, true);
      }
      await firebaseManager.batchEnd();
      notification.success({
        message: `${length}개의 정산서를 삭제했습니다.`,
      });
    } catch (error: any) {
      console.error(error);
      notification.error({
        message: '정산서 삭제에 실패했습니다.',
        description: error.message,
      });
    }
    setLoading(false);
  }, [rowData]);

  const autoSizeAll = useCallback(() => {
    gridRef?.columnApi?.autoSizeAllColumns(true);
  }, [gridRef]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }} className='height100'>
      <div className='gridHeader'>
        <Popconfirm
          title='발송후 취소할 수 없습니다. 발송하시겠습니까?'
          okButtonProps={{ loading }}
          onConfirm={sendBills}>
          <Button type='primary' loading={loading} icon={<SendOutlined />}>
            선택 항목 일괄 발송
          </Button>
        </Popconfirm>
        <Popconfirm
          title={`화면에 보이는 정산서(${rowData.length}개)를 모두 삭제하시겠습니까?`}
          okButtonProps={{ loading }}
          onConfirm={deleteSelectedRows}>
          <Button
            danger
            loading={loading}
            disabled={!rowData.length}
            icon={<DeleteOutlined />}
            style={{ marginLeft: 8 }}>
            정산서({rowData.length}개) 모두 삭제
          </Button>
        </Popconfirm>
      </div>
      <div className='ag-theme-alpine' style={{ flex: 1 }}>
        <AgGridReact
          ref={setGridRef}
          onGridReady={autoSizeAll}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={true}
          rowSelection='multiple'
          keepDetailRows={true}
          masterDetail={true}
          isRowSelectable={isRowSelectable}
          detailRowAutoHeight={true}
          detailCellRendererParams={detailCellRendererParams}
          enableCellTextSelection={true}
          getRowId={(params) => params.data._id}
          alwaysShowHorizontalScroll={true}
          // 편집 완료후 스크롤 이동을 막는다.
          suppressScrollOnNewData={true}
        />
      </div>
    </div>
  );
};

export default InstallmentBillTable;
