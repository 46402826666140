import { Invoice, OrderStatusCode } from '@gooduncles/gu-app-schema';
import { Button, notification } from 'antd';
import { groupBy, sumBy } from 'lodash-es';
import { FC, useEffect, useState } from 'react';
import { combineLatest, map } from 'rxjs';
import { getPaymentInvoicesForStore } from 'src/utils/payment-util';

import { formatDate } from 'src/lib/1/date-util';
import { errorObjectToString, formatNumber } from 'src/lib/1/util';
import { FirebaseManager } from 'src/lib/3/firebase-manager';
import {
  createInvoice,
  observeOrder,
  observeSettlementResult,
  observeStoreIssue,
  updateOrder,
  updateSettlementResult,
  updateStoreIssue,
} from 'src/lib/4/firebase-short-cut';

import useHyosungCMSMembers from 'src/hooks/useHyosungCMSMembers';
import useStores from 'src/hooks/useStores';

import Loading from 'src/components/Loading/Loading';
import InvoiceCreationTable, {
  InvoiceCreationItem,
} from 'src/components/NewSettlement/InvoiceTable/InvoiceCreationTable';

const firebaseMananger = FirebaseManager.getInstance();
/**
 * Invoice를 생성합니다.
 * @description invocie 생성시 functions에서 관련된 주문과 매장이슈의 invoiceId를 업데이트 합니다.
 */
const createInvociesWithUpdateChildren = async (invoices: Invoice[]) => {
  firebaseMananger.batchStart();
  for (const invoice of invoices) {
    const { orderIds, storeIssueIds, prevSettlementResultId } = invoice;
    const docId = await createInvoice(
      {
        ...invoice,
        date: formatDate(new Date(), "yyyy-MM-dd'T'HH:mm:ss+0900"),
      },
      true
    );
    for (const orderId of orderIds) {
      await updateOrder(orderId, { invoiceId: docId }, true);
    }
    for (const storeIssueId of storeIssueIds) {
      await updateStoreIssue(storeIssueId, { invoiceId: docId }, true);
    }
    if (prevSettlementResultId) {
      await updateSettlementResult(prevSettlementResultId, { invoiceIdForUnpaid: docId }, true);
    }
  }
  return firebaseMananger.batchEnd();
};

const InvoiceCreationPage: FC = () => {
  const stores = useStores();
  const hyosungCMSMembers = useHyosungCMSMembers();
  const [invoiceCreationItems, setInvoiceCreationItems] = useState<InvoiceCreationItem[]>([]);
  const [selectedRows, setSelectedRows] = useState<InvoiceCreationItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalUnpaidInfo, setTotalUnpaidInfo] = useState({
    totalUnpaidAmount: 0,
    unpaidStoreCount: 0,
  });

  const handleCreateInvoices = async () => {
    try {
      if (selectedRows.length <= 0) {
        notification.error({ message: '선택된 매장이 없습니다.' });
        return;
      }
      setLoading(true);
      await createInvociesWithUpdateChildren(selectedRows.map((item) => item.invoice));
      notification.success({ message: `${selectedRows.length}개의 청구서를 생성했습니다.` });
      setSelectedRows([]);
    } catch (error) {
      console.error(error);
      const description = errorObjectToString(error);
      notification.error({ message: '청구서 생성 실패', description });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (stores && hyosungCMSMembers) {
      // 미납금이 있으면서, 다시 청구가 필요한 결제결과
      const settlementResultObservable = observeSettlementResult([
        ['invoiceIdForUnpaid', '==', null],
        ['unpaidAmount', '!=', 0],
        ['deletedAt', '==', null],
      ]);
      const orderObservable = observeOrder([
        ['invoiceId', '==', null],
        ['orderStatus', '==', OrderStatusCode.DELIVERED],
      ]);
      const storeIssueObservable = observeStoreIssue([
        ['invoiceId', '==', null],
        ['status', '==', '완료'],
      ]).pipe(
        map((storeIssues) =>
          storeIssues.filter((issue) => issue.isDeleted === false && issue.message && issue.message.length > 0)
        )
      );

      const subscription = combineLatest([settlementResultObservable, orderObservable, storeIssueObservable]).subscribe(
        ([settlementResults, orders, storeIssues]) => {
          const resultsGroupByStoreId = groupBy(settlementResults, 'storeId');
          const ordersGroupByStoreId = groupBy(orders, 'storeId');
          const storeIssuesGroupByStoreId = groupBy(
            storeIssues,
            (issue) => stores.find((s) => s.storeCode === issue.storeCode)?._id ?? 'unknown'
          );

          // TODO: 매장이 아닌 결제정보를 기준으로 청구서를 생성하도록 변경하자.
          const invoiceCreationItems = stores
            .map((store) => {
              const unpaidResults = resultsGroupByStoreId[store._id] ?? [];
              const settlementResult = unpaidResults.find((result) => result.settledAt === store.latestSettledAt);
              const orders = ordersGroupByStoreId[store._id] ?? [];
              const storeIssues = storeIssuesGroupByStoreId[store._id] ?? [];
              const hyosungCMSMember = hyosungCMSMembers.find((member) => member.storeCode === store.storeCode);
              // TODO: 결제 정보가 없는 매장에 대한 처리
              let errorMsg = store.paymentMethod && store.paymentVendor ? null : '결제정보가 없습니다.';
              if (store.paymentVendor === '효성CMS' && !hyosungCMSMember) {
                errorMsg = errorMsg ? errorMsg + ' 효성CMS 회원정보가 없습니다.' : '효성CMS 회원정보가 없습니다.';
              }
              const invoice = getPaymentInvoicesForStore(
                store,
                settlementResult,
                orders,
                storeIssues,
                hyosungCMSMember
              );
              return {
                storeId: store._id,
                storeCode: store.storeCode,
                storeNickname: store.storeNickname,
                paymentVendor: store.paymentVendor,
                paymentMethod: store.paymentMethod,
                invoice,
                orders,
                storeIssues,
                errorMsg,
              };
            })
            .filter((item) => item.invoice) as InvoiceCreationItem[];
          setInvoiceCreationItems(invoiceCreationItems);

          // 미수금 통계
          const unpaidStoreList = settlementResults.filter((settlementResult) => settlementResult.unpaidAmount > 0);
          setTotalUnpaidInfo({
            totalUnpaidAmount: sumBy(unpaidStoreList, 'unpaidAmount'),
            unpaidStoreCount: unpaidStoreList.length,
          });
        }
      );

      return () => {
        subscription.unsubscribe();
      };
    }
  }, [stores, hyosungCMSMembers]);

  return (
    <>
      {loading && <Loading title='잠시만 기다려주세요.' />}
      <div className='tabBody flexColumn height100'>
        <section className='flexRow flexSpaceBetween bgWhite'>
          <Button type='primary' loading={loading} disabled={selectedRows.length <= 0} onClick={handleCreateInvoices}>
            {selectedRows.length}개 청구서 생성
          </Button>
          <div className='flexRow'>
            <span>
              미수금 합계: <b>{formatNumber(totalUnpaidInfo.totalUnpaidAmount)}</b>원
            </span>
            <span> / 미수 매장 수: {totalUnpaidInfo.unpaidStoreCount}</span>
          </div>
        </section>
        <section className='flexRow height100'>
          <InvoiceCreationTable invoiceCreationItems={invoiceCreationItems} setSelectedRows={setSelectedRows} />
        </section>
      </div>
    </>
  );
};

export default InvoiceCreationPage;
