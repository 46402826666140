import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { useTitle } from 'src/hooks/useTitle';

import Settings from 'src/components/Settings/Settings';

const SettingsPage: FC<{ title: string }> = ({ title }) => {
  useTitle(`GU 관리자 | ${title}`);
  return (
    <Routes>
      <Route path=':tab' element={<Settings />} />
      <Route path='/' element={<Navigate to='/settings/app' replace />} />
    </Routes>
  );
};

export default SettingsPage;
