import { Button, Popconfirm, notification } from 'antd';
import { FC, useState } from 'react';

import { getOrderDate } from 'src/lib/1/date-util';
import { errorObjectToString } from 'src/lib/1/util';
import { FirebaseManager } from 'src/lib/3/firebase-manager';
import { createProductStockHistory, updateProduct } from 'src/lib/4/firebase-short-cut';
import { ConsoleLogger } from 'src/lib/5/logger';

const logger = ConsoleLogger.getInstance();
const logName = '재고 관리';
const firebaseManager = FirebaseManager.getInstance();

const CancleStockButton: FC<{ productId: string; stock: number; userEmail: string }> = ({
  productId,
  stock,
  userEmail,
}) => {
  const [loading, setLoading] = useState(false);
  const updateStockToInfinity = async () => {
    setLoading(true);
    try {
      firebaseManager.batchStart();
      await updateProduct(productId, { stock: Infinity }, true);
      await createProductStockHistory(
        {
          timestamp: getOrderDate(),
          productId,
          userEmail,
          stock: Infinity,
          beforeStock: stock,
          afterStock: Infinity,
          type: 'INFINITY',
          reason: null,
          requestId: null,
          relatedHistoryId: null,
          canceled: false,
        },
        true
      );
      await firebaseManager.batchEnd();
      notification.success({
        message: '재고를 무제한으로 변경했습니다.',
      });
    } catch (error) {
      console.error(error);
      const description = errorObjectToString(error);
      logger.logConsole(`${logName} - ${description}`, {
        level: 'error',
      });
      notification.error({
        message: '재고 변경에 실패했습니다.',
        description,
      });
    }
    setLoading(false);
  };
  return (
    <Popconfirm
      title='재고를 무제한으로 변경합니다.'
      onConfirm={updateStockToInfinity}
      okButtonProps={{ loading }}
      cancelButtonProps={{ loading }}>
      <Button>무제한</Button>
    </Popconfirm>
  );
};

export default CancleStockButton;
