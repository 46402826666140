import { HyosungCMSMember } from '@gooduncles/gu-app-schema';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { ColDef, RowClassParams } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { FC, useMemo } from 'react';

const defaultColDef: ColDef<HyosungCMSMember> = {
  sortable: true,
  resizable: true,
  filter: true,
};

interface HyosungCMSMemberTableProps {
  rowData: HyosungCMSMember[];
}

const HyosungCMSMemberTable: FC<HyosungCMSMemberTableProps> = ({ rowData }) => {
  const columnDefs: ColDef<HyosungCMSMember & { isNew?: boolean; diff?: boolean }>[] = useMemo(
    () => [
      {
        field: 'no',
        headerName: 'No.',
        width: 100,
        sort: 'asc',
        comparator: (a, b, nodeA, nodeB) => {
          // isNew가 true인 항목을 먼저 정렬
          if (nodeA.data?.isNew && !nodeB.data?.isNew) return -1;
          if (!nodeA.data?.isNew && nodeB.data?.isNew) return 1;

          // diff가 true인 항목을 그 다음으로 정렬
          if (nodeA.data?.diff && !nodeB.data?.diff) return -1;
          if (!nodeA.data?.diff && nodeB.data?.diff) return 1;
          return a - b;
        },
      },
      { field: 'storeCode', headerName: '회원번호', width: 100 },
      { field: 'contractNumber', headerName: '계약번호', width: 100 },
      { field: 'name', headerName: '회원명' },
      { field: 'phoneNumber', headerName: '납부자 휴대전화', width: 148 },
      { field: 'userStatus', headerName: '회원상태', width: 100 },
      { field: 'contractStatus', headerName: '계약상태', width: 100 },
      { field: 'paymentDueDate', headerName: '약정일', width: 88 },
      { field: 'paymentWay', headerName: '결제방식', width: 100 },
      { field: 'paymentMethod', headerName: '결제수단', width: 120 },
      { field: 'paymentInfo', headerName: '결제정보' },
      { field: 'paymentStatus', headerName: '결제등록상태', width: 120 },
      { field: 'agreement', headerName: '동의여부', width: 100 },
      { field: 'product', headerName: '상품목록', width: 120 },
      { field: 'productAmount', headerName: '상품금액합', width: 110 },
      { field: 'contractStartDate', headerName: '청구시작일', width: 120 },
      { field: 'contractEndDate', headerName: '청구종료일', width: 120 },
      { field: 'manager', headerName: '담당관리자', width: 110 },
      { field: 'userType', headerName: '회원구분', width: 100 },
      { field: 'billingMode', headerName: '청구자동생성', width: 120 },
      { field: 'sendingMode', headerName: '발송방식', width: 100 },
    ],
    []
  );
  return (
    <div className='ag-theme-alpine flex1 height100'>
      <AgGridReact
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        getRowStyle={(params: RowClassParams): any => {
          const typedData = params.data as HyosungCMSMember & { isNew?: boolean; diff?: boolean };
          if (typedData.isNew) {
            return { background: 'var(--green200)' };
          } else if (typedData.diff) {
            return { background: 'var(--yellow200)' };
          }
          return null;
        }}
      />
    </div>
  );
};

export default HyosungCMSMemberTable;
