import { Button, notification } from 'antd';
import { FC, useCallback, useState } from 'react';
import { PromotionSectionItem } from 'src/schema/schema-promotion-section';

import { errorObjectToString } from 'src/lib/1/util';
import { updatePromotionSection } from 'src/lib/4/firebase-short-cut';

const DeletePromotionSectionItemRenderer: FC = (params: any) => {
  const [loading, setLoading] = useState(false);
  const id: string = params.value;
  const promotionId: string = params.data._id;
  const items: PromotionSectionItem[] = params.data.items;

  const onDelete = useCallback(async () => {
    setLoading(true);
    try {
      const newItems = items
        .filter((item) => item.id !== id)
        .sort((a, b) => a.index - b.index)
        .map((item, idx) => ({ ...item, index: idx + 1 }));
      await updatePromotionSection(promotionId, {
        items: newItems,
      });
      notification.success({ message: '프로모션에서 상품을 삭제했습니다.' });
    } catch (error) {
      console.error(error);
      const description = errorObjectToString(error);
      notification.error({ message: '프로모션에서 상품 삭제 실패', description });
    }
    setLoading(false);
  }, [id, items, promotionId]);

  if (!id || !promotionId) return null;

  return (
    <Button danger onClick={onDelete} loading={loading}>
      삭제
    </Button>
  );
};

export default DeletePromotionSectionItemRenderer;
