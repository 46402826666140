import { Product } from '@gooduncles/gu-app-schema';
import { isNumber, isString } from 'lodash-es';
import { FC } from 'react';

import { formatNumber } from 'src/lib/1/util';

import useProduct from 'src/hooks/useProduct';

const ProductCellRenderer: FC<{ productId: string; field: keyof Product }> = ({ productId, field }) => {
  const { product, productLoading } = useProduct(productId);

  if (productLoading) {
    return <div>isLoading</div>;
  }

  const value = product?.[field] ?? '';
  return <span>{isNumber(value) ? formatNumber(value) : isString(value) ? value : JSON.stringify(value)}</span>;
};

export default ProductCellRenderer;
