import { EditOutlined } from '@ant-design/icons';
import { DailyIssue, DailyIssueDoc } from '@gooduncles/gu-app-schema';
import { Button, notification } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import { Timestamp, arrayUnion } from 'firebase/firestore';
import { isEqual } from 'lodash-es';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useAuth } from 'src/stores/auth-context';

import { formatDate, getDefaultDate } from 'src/lib/1/date-util';
import { errorObjectToString } from 'src/lib/1/util';
import { createDailyIssue, observeDailyIssueDoc, updateDailyIssue } from 'src/lib/4/firebase-short-cut';

import DatePickerWithArrows from 'src/components/Common/DatePickerWithArrows/DatePickerWithArrows';
import EditorJSBoard, { EditorJSBoardHandle } from 'src/components/EditorJS/EditorJSBoard';
import Loading from 'src/components/Loading/Loading';

import classes from './DailyIssueDashboard.module.scss';

const defaultValue = getDefaultDate();
/**
 * 미래의 날짜를 선택하지 못하도록 하는 함수
 */
const disableFutureDate: RangePickerProps['disabledDate'] = (current) => {
  return current && current > dayjs().add(3, 'hour').endOf('day');
};

const createDailyIssueWithTitle = async (email: string, id: string) => {
  const text = formatDate(id, 'yyyy년 L월 dd일');
  const data: DailyIssue = {
    createdBy: email,
    history: [],
    time: Date.now(),
    version: '2.29.0',
    blocks: [
      {
        type: 'header',
        data: {
          text,
          level: 1,
        },
      },
    ],
  };
  await createDailyIssue(id, data);
};

const DailyIssueDashboard: FC = () => {
  const { authUser } = useAuth();
  const email = authUser ? authUser?.email : null;
  const [date, setDate] = useState<dayjs.Dayjs | null>(defaultValue);
  const dailyIssueId = useMemo(() => (date ? formatDate(date.toDate(), 'yyyy-MM-dd') : ''), [date]);
  const [dailyIssue, setDailyIssue] = useState<DailyIssueDoc>();
  const lastModified = useMemo(() => dailyIssue?.history.at(-1)?.updatedAt.toMillis() ?? 0, [dailyIssue]);
  const [editorJSRef, setEditorJSRef] = useState<EditorJSBoardHandle | null>(null);
  const [loadingFetch, setLoadingFetch] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);

  const onCreateDailyIssue = useCallback(async () => {
    try {
      if (!email) {
        throw new Error('로그인 정보가 없습니다.');
      }
      if (!dailyIssueId || dailyIssueId.length === 0) {
        throw new Error('dailyIssueId가 없습니다.');
      }
      setLoadingCreate(true);
      await createDailyIssueWithTitle(email, dailyIssueId);
    } catch (error) {
      const description = errorObjectToString(error);
      notification.error({
        message: '문서 생성 실패',
        description,
      });
    }
    setLoadingCreate(false);
  }, [dailyIssueId, email]);

  const onSave = useCallback(async () => {
    try {
      if (!editorJSRef) {
        throw new Error('editorJSRef가 없습니다.');
      }
      const content = editorJSRef.content;
      if (!content) {
        throw new Error('저장할 data가 없습니다.');
      }

      if (isEqual(dailyIssue?.blocks, content.blocks)) {
        notification.info({
          message: '변경사항이 없습니다.',
        });
        return;
      }

      setLoadingSave(true);

      await updateDailyIssue(dailyIssueId, {
        blocks: content.blocks,
        history: arrayUnion({
          updatedBy: email,
          updatedAt: Timestamp.now(),
        }),
      });
    } catch (error) {
      const description = errorObjectToString(error);
      notification.error({
        message: '문서 업데이트 실패',
        description,
      });
    }
    setLoadingSave(false);
  }, [dailyIssue?.blocks, dailyIssueId, editorJSRef, email]);

  useEffect(() => {
    if (dailyIssueId) {
      setLoadingFetch(true);
      const subscription = observeDailyIssueDoc(dailyIssueId).subscribe((doc) => {
        setDailyIssue(doc);
        setLoadingFetch(false);
      });
      return () => {
        subscription.unsubscribe();
      };
    }
  }, [dailyIssueId]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8, height: '100%' }}>
      {loadingFetch && <Loading title='잠시만 기다려주세요.' />}
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <DatePickerWithArrows date={date} setDate={setDate} disabledDate={disableFutureDate} />
        <div>
          <Button type='primary' loading={loadingSave} onClick={onSave} disabled={!editorJSRef}>
            저장
          </Button>
        </div>
      </div>
      {dailyIssue ? (
        <div className={classes.boardContainer}>
          <EditorJSBoard
            ref={setEditorJSRef}
            lastModified={lastModified}
            data={{
              version: dailyIssue.version,
              time: dailyIssue.time,
              blocks: dailyIssue.blocks,
            }}
          />
          <div className={classes.historyBox}>
            <b>히스토리</b>
            <div className={classes.historyList}>
              <p>
                [created] {dailyIssue?.createdBy.split('@')[0]}
                <br />
                {dailyIssue?._timeCreate.toDate() ? formatDate(dailyIssue?._timeCreate.toDate(), 'L월 d일 HH:mm') : ''}
              </p>
              {dailyIssue?.history.map((item, index) => {
                return (
                  <p key={index}>
                    {item.updatedBy.split('@')[0]}
                    <br />
                    {formatDate(item.updatedAt.toDate(), 'L월 d일 HH:mm')}
                  </p>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <Button
            type='primary'
            loading={loadingCreate}
            disabled={!dailyIssueId || dailyIssueId.length === 0}
            onClick={onCreateDailyIssue}
            size='large'
            icon={<EditOutlined />}>
            {dailyIssueId} 데일리 이슈 생성
          </Button>
        </div>
      )}
    </div>
  );
};

export default DailyIssueDashboard;
