import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { ColDef, ColGroupDef, RowClassParams } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { FC, useMemo, useState } from 'react';
import { InvoiceItem } from 'src/schema/schema-invoice';

import { numberFormatter } from 'src/lib/6/ag-grid-util';

interface InvoiceTableProps {
  rowData: InvoiceItem[];
  setSelectedRows: (rows: InvoiceItem[]) => void;
}

const defaultColDef: ColDef<InvoiceItem> = {
  sortable: true,
  resizable: true,
  filter: true,
};

const getStoreIssueRowStyle = (params: RowClassParams<InvoiceItem>): any => {
  const { partnerName } = params.node.data ?? {};
  // 미할당 & 미할당(동선x)
  if (partnerName?.includes('미할당')) {
    return { backgroundColor: 'hsl(0 66% 80% / 1)' };
  }
};

const InvoiceTable: FC<InvoiceTableProps> = ({ rowData, setSelectedRows }) => {
  const [, setGridRef] = useState<AgGridReact<InvoiceItem> | null>(null);

  const columnDefs: (ColDef<InvoiceItem> | ColGroupDef<InvoiceItem>)[] = useMemo(
    () => [
      {
        field: 'date',
        headerName: '날짜',
        width: 160,
      },
      {
        field: 'store.storeNickname',
        headerName: '매장명(관리)',
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
      },
      {
        headerName: '총액',
        valueGetter: ({ data }) => {
          const paidAmount = data?.order?.paidAmount ?? 0;
          const storeIssuesAmount =
            data?.storeIssues.reduce(
              (acc, cur) => acc + ((cur.supplyPrice ?? 0) + (cur.tax ?? 0) * (cur.volume ?? 1)),
              0
            ) ?? 0;
          return paidAmount + storeIssuesAmount;
        },
        valueFormatter: numberFormatter,
      },
      {
        field: 'partnerName',
        headerName: '담당자',
      },
    ],
    []
  );

  return (
    <div className='ag-theme-alpine height100' style={{ flex: 1, maxWidth: 540 }}>
      <AgGridReact
        ref={setGridRef}
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        rowSelection={'multiple'}
        onSelectionChanged={(e) => setSelectedRows(e.api.getSelectedRows())}
        getRowStyle={getStoreIssueRowStyle}
      />
    </div>
  );
};

export default InvoiceTable;
