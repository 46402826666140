import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { FC, useMemo, useState } from 'react';

import { LogCommerce, LogDoc } from 'src/lib/3/schema-log';
import {
  defaultValueGetter,
  orderStatusCellClass,
  timestampFormatter,
  unknownValueCellClass,
} from 'src/lib/6/ag-grid-util';

import DownloadRawDataCell from 'src/components/Common/DownloadRawDataCell/DownloadRawDataCell';

const defaultColDef = {
  flex: 1,
  sortable: true,
  resizable: true,
};

const autoGroupColumnDef = {
  maxWidth: 240,
};

const CommerceLogTable: FC<{ rowData: LogDoc<LogCommerce>[] }> = ({ rowData }) => {
  const [, setGridRef] = useState<AgGridReact<LogDoc<LogCommerce>> | null>(null);
  const columnDefs: any = useMemo(
    () => [
      { field: '_id', filter: true, hide: true },
      {
        headerName: '시각',
        field: '_timeCreate',
        filter: true,
        maxWidth: 200,
        valueFormatter: timestampFormatter,
      },
      {
        headerName: '액션',
        minWidth: 120,
        hide: true,
        cellRenderer: DownloadRawDataCell,
      },
      {
        headerName: '사용자',
        field: 'body.user.email',
        maxWidth: 230,
        filter: true,
        enableRowGroup: true,
        valueGetter: defaultValueGetter,
        cellClass: unknownValueCellClass,
      },
      {
        field: 'level',
        filter: true,
        maxWidth: 88,
        hide: true,
      },
      {
        headerName: '내용',
        field: 'message',
        filter: true,
        cellClass: orderStatusCellClass,
      },
      {
        headerName: 'session',
        field: 'sessionId',
        filter: true,
        maxWidth: 340,
        enableRowGroup: true,
        valueGetter: defaultValueGetter,
        cellClass: unknownValueCellClass,
      },
      {
        headerName: 'deviceId',
        field: 'body.deviceId',
        maxWidth: 160,
        filter: true,
        enableRowGroup: true,
        valueGetter: defaultValueGetter,
        cellClass: unknownValueCellClass,
      },
      {
        headerName: 'ip',
        field: 'publicIp',
        maxWidth: 160,
        filter: true,
        enableRowGroup: true,
        valueGetter: defaultValueGetter,
        cellClass: unknownValueCellClass,
      },
      {
        headerName: '주문번호',
        field: 'body.orderId',
        maxWidth: 220,
        filter: true,
        enableRowGroup: true,
        valueGetter: defaultValueGetter,
        cellClass: unknownValueCellClass,
      },
    ],
    []
  );

  return (
    <div className='ag-theme-alpine height100'>
      <AgGridReact
        ref={setGridRef}
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        autoGroupColumnDef={autoGroupColumnDef}
        animateRows={true}
        rowGroupPanelShow={'always'}
      />
    </div>
  );
};

export default CommerceLogTable;
