import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { ColDef } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { FC, useCallback, useMemo, useState } from 'react';

import { FriendtalkMessageDoc, FriendtalkRecipientWithResultItem } from 'src/lib/3/schema-friendtalk';
import { timestampFormatter3 } from 'src/lib/6/ag-grid-util';

import SyncMessageResultButton from '../AgGrid/CellRenderer/SyncMessageResultButton/SyncMessageResultButton';
import KakaotalkContentPopover from '../AlimtalkMessage/AlimtalkContentPopover/AlimtalkContentPopover';

export type FriendtalkMessageTableRowData = Omit<FriendtalkMessageDoc, 'recipientWithResultList'> &
  FriendtalkRecipientWithResultItem & {
    recipientSeq: number;
  };

interface FriendtalkMessageTableProps {
  rowData: FriendtalkMessageTableRowData[];
}

const defaultColDef: ColDef<FriendtalkMessageTableRowData> = {
  flex: 1,
  sortable: true,
  resizable: true,
  filter: true,
};

const FriendtalkMessageTable: FC<FriendtalkMessageTableProps> = ({ rowData }) => {
  const [gridRef, setGridRef] = useState<AgGridReact<FriendtalkMessageTableRowData> | null>(null);

  const columnDefs: ColDef<FriendtalkMessageTableRowData>[] = useMemo(() => {
    return [
      {
        field: 'senderKey',
        hide: true,
      },
      {
        field: '_id',
        hide: true,
      },
      {
        headerName: 'requestId',
        field: 'friendtalkPostResponseId',
        hide: true,
      },
      {
        headerName: '요청일시',
        field: '_timeCreate',
        valueFormatter: timestampFormatter3,
      },
      {
        headerName: '수신번호',
        field: 'recipientNo',
      },
      {
        headerName: '수신자',
        field: 'recipientGroupingKey',
      },
      {
        headerName: '요청결과',
        valueGetter: ({ data }) => {
          const { result } = data as FriendtalkMessageTableRowData;
          if (result?.requestResult.result) {
            return result?.requestResult.result === 'success' ? '🟢 성공' : `❌ ${result?.requestResult.error}`;
          }
          return null;
        },
      },
      {
        headerName: '발송결과',
        valueGetter: ({ data }) => {
          const { result, requestDate } = data as FriendtalkMessageTableRowData;
          if (result?.messageResult?.resultCode) {
            return result?.messageResult?.resultCode === '1000' ? '🟢 성공' : `❌ ${result?.messageResult.error}`;
          }

          if (requestDate) {
            return `⏰ 예약발송: ${requestDate}`;
          }
          return null;
        },
        cellRenderer: (props: any) => {
          const { value, data } = props;
          if (value) return value;
          const { _id, friendtalkPostResponseId, recipientSeq, result } = data as FriendtalkMessageTableRowData;
          return friendtalkPostResponseId && result?.messageResult ? (
            <SyncMessageResultButton
              type='friendtalk'
              messageDocId={_id}
              requestId={friendtalkPostResponseId}
              recipientSeq={recipientSeq}
            />
          ) : (
            '⚠️ 요청 결과 없음'
          );
        },
      },
      {
        headerName: '발송내용',
        field: 'result.messageResult.content',
        cellRenderer: KakaotalkContentPopover,
        cellRendererParams: (props: any) => {
          const { result } = props.data as FriendtalkMessageTableRowData;
          if (result?.messageResult?.buttons) {
            return {
              buttons: result.messageResult.buttons,
            };
          }
        },
      },
    ];
  }, []);

  const autoSizeAll = useCallback(() => {
    gridRef?.columnApi?.autoSizeAllColumns(true);
  }, [gridRef]);

  return (
    <div className='ag-theme-alpine height100'>
      <AgGridReact
        ref={setGridRef}
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        onGridReady={autoSizeAll}
        // rowId를 지정해주지 않으면 데이터 변경시 refresh가 발생한다.
        getRowId={(params) => `${params.data._id}-${params.data.recipientSeq}`}
        // 편집 완료후 스크롤 이동을 막는다.
        suppressScrollOnNewData={true}
        // 툴팁의 노출 속도
        tooltipShowDelay={0}
      />
    </div>
  );
};

export default FriendtalkMessageTable;
