import { DatePicker, DatePickerProps, message } from 'antd';
import dayjs from 'dayjs';
import { FC, useCallback, useEffect, useRef } from 'react';

import { orderDateFormat03 } from 'src/lib/1/date-util';
import { parseIfObject } from 'src/lib/1/util';

interface DateCellEditorProps {
  defaultValue: number;
  minWidth?: number;
  onValueChange: (value: string) => Promise<string>;
  stopEditing: () => void;
}

const DateCellEditor: FC<DateCellEditorProps> = ({ defaultValue, stopEditing, onValueChange }) => {
  const datePickerRef = useRef<any>(null);

  const onChange: DatePickerProps['onChange'] = useCallback(
    async (dayjsDate: dayjs.Dayjs | null) => {
      const date = dayjsDate ? dayjsDate.toDate() : null;
      if (date) {
        try {
          const dateString = orderDateFormat03(date);
          await onValueChange(dateString);
          message.success('변경 완료');
          stopEditing();
        } catch (error) {
          console.error(error);
          message.error(parseIfObject(error));
        }
      }
    },
    [onValueChange, stopEditing]
  );

  useEffect(() => {
    if (datePickerRef?.current) {
      datePickerRef.current.focus();
    }
  }, [datePickerRef]);

  return (
    <DatePicker
      size='large'
      ref={datePickerRef}
      onChange={onChange}
      defaultValue={defaultValue ? dayjs(defaultValue) : undefined}
    />
  );
};

export default DateCellEditor;
