import { OrderDoc, OrderProduct, ProductStateCode } from '@gooduncles/gu-app-schema';
import { Button, Checkbox, Popconfirm, message, notification } from 'antd';
import { FC, useState } from 'react';
import { callSendKakaoAlimTalkForProductSoldout } from 'src/utils/firebase-callable';
import { v4 } from 'uuid';

import { senderKey, settlementDayTable } from 'src/lib/1/constant';
import { dateFormat06, getKakaoTalkRequestOptions } from 'src/lib/1/date-util';
import { ProductSoldOutTemplate } from 'src/lib/1/schema-alimtalk-template';
import { errorObjectToString } from 'src/lib/1/util';
import { getAlimtalkMessage, getUser, updateOrder } from 'src/lib/4/firebase-short-cut';
import { organizeKakaotalkResultMessage } from 'src/lib/4/nhn-notification-util';
import { ConsoleLogger } from 'src/lib/5/logger';

const logger = ConsoleLogger.getInstance();
const logName = '주문 관리';

type ProductWithOrder = OrderProduct & {
  order: OrderDoc;
};

/**
 * 품절 버튼
 * 정산일이 지나지 않았고, 정산이 완료되지 않은 주문의 상품에만 노출된다.
 */
const SoldOutCellRenderer: FC = (params: any) => {
  const [sendKakaoAlimTalk, setSendKakaoAlimTalk] = useState(true);
  const [loading, setLoading] = useState(false);
  const data: ProductWithOrder = params.data;
  if (!data) {
    return null;
  }
  const order = data.order;
  const orderDay = new Date(data.order.orderDate).getDay();
  const today = new Date().getDay();
  const unsettled = settlementDayTable[today].some((settlementDay) => settlementDay === orderDay);
  // 정산일이 지나거나, 정산이 완료된 주문의 항목을 품절 처리할 수 없다.
  if (!unsettled || data.order.settledAt) {
    return null;
  }

  if (data.state === ProductStateCode.OUTOFSTOCK) {
    return <span>품절 처리됨</span>;
  }

  /** 주문완료된 상품을 품절 처리한다. */
  const onClickSoldOut = async () => {
    setLoading(true);
    try {
      // 1. 변경된 품목에 따라 주문 내용 수정
      const newOrderProducts = order.products.map((orderProduct) => {
        if (orderProduct.productId === params.data.productId) {
          return {
            ...orderProduct,
            volume: 0,
            state: ProductStateCode.OUTOFSTOCK,
          };
        }
        return orderProduct;
      });
      const totalAmount = newOrderProducts.reduce((acc, cur) => acc + cur.price * cur.volume, 0);
      const grandTotal = totalAmount + (order.deliveryFee ?? 0);

      // 2. 주문 업데이트
      await updateOrder(order._id, {
        products: newOrderProducts,
        totalAmount,
        paidAmount: grandTotal,
        grandTotal,
      });

      // 3. 변경 내용 기록
      logger.logOrder('주문 상품 품절 처리', {
        orderId: order._id,
        storeId: order.storeId,
        before: order,
        after: {
          ...order,
          products: newOrderProducts,
          totalAmount,
          paidAmount: grandTotal,
          grandTotal,
        },
        reason: '품절 처리',
        reasonForUser: `구매하신 ${params.data.fullName}의 재고가 없어 품절 처리되었습니다. 양해 부탁드립니다.`,
      });

      notification.success({
        message: '주문 상품 품절 처리 완료',
        description: `총 금액: ${order.grandTotal} -> ${grandTotal}`,
      });

      if (!sendKakaoAlimTalk) {
        setLoading(false);
        return;
      }

      // 4. 카톡 메시지 발송
      const uiMessageKey = v4();
      message.open({
        type: 'loading',
        content: '카톡 메시지 발송 요청을 진행합니다...',
        duration: 0,
        key: uiMessageKey,
      });
      const user = await getUser(order.userId);
      if (!user?.userTel) {
        throw new Error('유효한 전화번호를 찾지 못했습니다.');
      }

      if (!senderKey) {
        throw new Error('카카오 알림톡 발송을 위한 senderKey키가 없습니다.');
      }

      // 5. 새벽 알림 수신을 꺼리는 고객을 위한 옵션 설정
      const requestDate = user.disableNotificationAtDawn ? getKakaoTalkRequestOptions() : undefined;

      const result = await callSendKakaoAlimTalkForProductSoldout({
        senderKey,
        templateCode: 'sold-out-product',
        recipientList: [
          {
            recipientNo: user?.userTel ?? '',
            templateParameter: {
              date: dateFormat06(new Date(order.orderDate)),
              product: params.data.fullName,
            },
            recipientGroupingKey: user?.storeId,
          },
        ],
        ...(requestDate ? { requestDate } : {}),
      });
      // 요청이 완료된 로딩창을 닫는다.
      message.destroy(uiMessageKey);

      if (result.data.result !== 'success') {
        throw new Error(result.data.reason);
      }

      if (!result.data.alimtalkMessageDocId) {
        throw new Error('요청은 성공했으나, ID를 받지 못했습니다.');
      }

      const alimtalkMessageDoc = await getAlimtalkMessage<ProductSoldOutTemplate>(result.data.alimtalkMessageDocId);
      if (!alimtalkMessageDoc) {
        throw new Error('요청은 성공했으나, 메시지 정보를 받지 못했습니다.');
      }

      // 요청 결과를 메시지로 정리한다.
      const description = organizeKakaotalkResultMessage(alimtalkMessageDoc.recipientWithResultList);

      notification.success({
        message: `카톡 메시지 ${requestDate ? '⏰ 예약' : '즉시'}발송 요청 완료`,
        description,
        className: 'pre-line-notification',
      });
    } catch (error) {
      console.error(error);
      const errorMessage = errorObjectToString(error);
      message.error(errorMessage ?? '알 수 없는 에러 발생!');
      logger.logConsole(`${logName} - order/${order._id}:: ${errorMessage}`, {
        level: 'error',
      });
    }
    setLoading(false);
  };

  return (
    <Popconfirm
      placement='topRight'
      title={
        <div>
          <p>{data.fullName} 상품을 품절 처리합니다.</p>
          <Checkbox checked={sendKakaoAlimTalk} onChange={(e) => setSendKakaoAlimTalk(e.target.checked)}>
            자동 카톡 발송
          </Checkbox>
        </div>
      }
      okText='Yes'
      cancelText='No'
      onConfirm={onClickSoldOut}
      cancelButtonProps={{ loading }}
      okButtonProps={{ danger: true, loading }}>
      <Button danger loading={loading}>
        품절
      </Button>
    </Popconfirm>
  );
};

export default SoldOutCellRenderer;
