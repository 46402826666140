import { ProductDoc, ProductStockChangeType, ProductStockHistory } from '@gooduncles/gu-app-schema';
import { groupBy, partition, sumBy } from 'lodash-es';
import { ProductRequest, ProductRequestDoc } from 'src/schema/schema-product-request';

import { formatDate } from 'src/lib/1/date-util';
import { FirebaseManager } from 'src/lib/3/firebase-manager';
import { PurchaseOrder } from 'src/lib/3/schema-purchase-order';
import { SupplierDoc } from 'src/lib/3/schema-supplier';
import {
  createProductRequest,
  createProductStockHistory,
  getProductRequestById,
  getPurchaseOrders,
  getSupplierById,
  updateProduct,
  updatePurchaseOrder,
} from 'src/lib/4/firebase-short-cut';
import { createPurchaseOrderMessage } from 'src/lib/4/purchase-order-util';

import { calcAfterStock, createProductStockHistoryForSync } from './product-stock-history-util';

const fb = FirebaseManager.getInstance();

const productStockHsitoryReasonMap: Record<string, string> = {
  outboundToPurchase: '출고 → 발주',
  purchaseToOutbound: '발주 → 출고',
};

const productStockTypeMap: Record<string, ProductStockChangeType> = {
  // 출고를 발주로 변경하는 경우 기존 출고를 취소해야한다.
  outboundToPurchase: 'CANCEL_OUTBOUND',
  // 발주를 출고로 변경시 신규 출고를 생성해야한다.
  purchaseToOutbound: 'OUTBOUND',
};

/**
 * 상품 요청을 합산하여 필요 수량 / 출고 수량 / 발주 수량을 반환합니다.
 */
export const calcProductRequestVolume = (requests: ProductRequestDoc[]) => {
  const requestsGroupByType = groupBy(requests, 'type');
  const totalOutboundVolume = sumBy(requestsGroupByType['outbound'] ?? [], 'volume');
  const totalPurchaseVolume = sumBy(requestsGroupByType['purchase'] ?? [], 'volume');
  const totalRequestVolume = totalOutboundVolume + totalPurchaseVolume;

  // 출고 -> 발주량
  const totalOutboundToPurchaseVolume = sumBy(requestsGroupByType['outboundToPurchase'] ?? [], 'volume');
  // 발주 -> 출고량(음수)
  const totalPurchaseToOutboundVolume = sumBy(requestsGroupByType['purchaseToOutbound'] ?? [], 'volume') * -1;

  return {
    totalRequestVolume,
    // + (발주 -> 출고값), - (출고 -> 발주값)
    totalOutboundVolume: totalOutboundVolume + totalPurchaseToOutboundVolume - totalOutboundToPurchaseVolume,
    // - (발주 -> 출고값), + (출고 -> 발주값)
    totalPurchaseVolume: totalPurchaseVolume - totalPurchaseToOutboundVolume + totalOutboundToPurchaseVolume,
  };
};

/**
 * 상품 요청의 필요 수량 범위 안에서 발주과 출고의 수량을 변경합니다.
 * [발주 -> 출고] 또는 [출고 -> 발주] 두 가지의 경우를 지원합니다.
 *
 * @param userEmail
 * @param date
 * @param target
 * @param syncAutomatically
 */
export const updateProductRequestManually = async (
  userEmail: string,
  date: string,
  products: ProductDoc[],
  target: {
    product: ProductDoc;
    purchaseVolumeForChange: number;
    outboundVolumeForChange: number;
  },
  syncAutomatically: boolean
) => {
  const { product, purchaseVolumeForChange, outboundVolumeForChange } = target;
  if (purchaseVolumeForChange === 0 || outboundVolumeForChange === 0) {
    throw new Error('변경할 발주 또는 출고량이 0입니다. 변경값은 0이 될 수 없으며, 1:1 변환만 가능합니다.');
  }

  // 1. 수동 요청 사항을 기록합니다.
  const newProductRequest = createProductRequestDataManually(product._id, purchaseVolumeForChange, date);

  // 2-1. 요청 사항에 따라 수정된 발주서 데이터를 생성합니다.
  const { purchaseOrderId, newPurchaseOrderData, productRequestIds } = await updatePurchaseProductRequestManually(
    products,
    product,
    newProductRequest
  );

  // 2-2. 요청 사항에 따라 신규 출고 내역을 생성합니다.
  const { newProductStockHistory, newProductStockHistoryForSync } = updateOutboundProductRequestManually(
    userEmail,
    product,
    newProductRequest,
    syncAutomatically
  );

  fb.batchStart();
  // 3. 변경 내역을 일괄 적용합니다.
  // 3-1. 신규 상품 요청 생성
  const requestId = await createProductRequest(newProductRequest, {
    bBatch: true,
  });
  // 3-2. 기존 발주서 업데이트
  await updatePurchaseOrder(
    purchaseOrderId,
    {
      ...newPurchaseOrderData,
      productRequestIds: [...productRequestIds, requestId],
    },
    {
      bBatch: true,
    }
  );
  // 3-3. 신규 입/출고 내역 생성
  await createProductStockHistory(newProductStockHistory, true);
  if (newProductStockHistoryForSync) {
    // 4. 신규 입/출고 내역 생성 (동기화)
    await createProductStockHistory(newProductStockHistoryForSync, true);
  }
  // 5. 상품 재고 업데이트
  await updateProduct(
    product._id,
    {
      stock: newProductStockHistoryForSync
        ? newProductStockHistoryForSync.afterStock
        : newProductStockHistory.afterStock,
    },
    true
  );
  await fb.batchEnd();
};

/**
 * 관리자가 상품의 발주, 출고를 수동으로 수정하여,
 * 해당 출고량에 따라 수정된 출고 내역과 상품 재고량을 반환합니다.
 *
 * @param product 발주, 출고 내역이 변동된 상품
 * @param productRequest 신규 발주, 출고 내역
 */
const updateOutboundProductRequestManually = (
  userEmail: string,
  product: ProductDoc,
  productRequest: ProductRequest,
  syncAutomatically: boolean
) => {
  const type = productStockTypeMap[productRequest.type];
  const stock = Math.abs(productRequest.volume);
  const afterStock = calcAfterStock(product.stock, stock, type);
  const newProductStockHistory: ProductStockHistory = {
    timestamp: productRequest.timestamp,
    userEmail,
    productId: productRequest.productId,
    requestId: null,
    reason: productStockHsitoryReasonMap[productRequest.type],
    type,
    stock,
    beforeStock: product.stock,
    afterStock,
    relatedHistoryId: null,
    canceled: false,
  };

  /**
   * 만약 신규 출고를 생성해야하는데, 상품 재고가 충분하지 않거나
   * 실재 재고가 충분하지 않아서 출고를 발주로 변경하는 경우
   * 해당 변경값을 관리자의 선택에 따라 입고와 출고를 동시에 생성한다.
   */
  if (syncAutomatically) {
    const newProductStockHistoryForSync = createProductStockHistoryForSync(newProductStockHistory);
    return {
      newProductStockHistory,
      newProductStockHistoryForSync,
    };
  }
  return {
    newProductStockHistory,
  };
};

/**
 * 관리자가 상품 요청의 발주, 출고를 수동으로 수정하여,
 * 해당 발주량에 따라 수정된 발주서를 반환합니다.
 * @description 만약 발주 내역 수정에 따라 발주할 상품의 수량이 0인 경우 해당 메시지를 제외합니다.
 *
 * @param targetProduct 발주, 출고 내역이 변동된 상품
 * @param productRequest 신규 발주, 출고 내역
 */
const updatePurchaseProductRequestManually = async (
  products: ProductDoc[],
  targetProduct: ProductDoc,
  productRequest: ProductRequest
) => {
  // 1. 발주서를 가져옵니다.
  const purchaseOrder = await getPurchaseOrderForProduct(productRequest.requiredDate, targetProduct);
  if (!purchaseOrder) {
    throw new Error('수정할 발주서가 없습니다.');
  }

  // 2. 발주서에 속한 요청 내역을 모두 가져옵니다.
  const productRequests = await getProductRequestsForPurchaseOrder(purchaseOrder);
  if (!productRequests || productRequests.length === 0) {
    throw new Error('발주서에 있는 상품 요청 정보가 비어있거나, 가져오지 못했습니다.');
  }

  // 3. 발주서를 새로 만들기 위해 매입처 정보를 가져옵니다.
  const supplier = await getSupplierById(targetProduct.suppliers[0]);
  if (!supplier) {
    throw new Error('매입처 정보를 가져오지 못했습니다.');
  }

  const newPurchaseOrderData = createNewPurchaseOrderData(supplier, products, productRequests, productRequest);
  return {
    purchaseOrderId: purchaseOrder._id,
    newPurchaseOrderData,
    productRequestIds: productRequests.map((productRequest) => productRequest._id),
  };
};

/**
 * 수동으로 상품 요청 데이터를 생성합니다.
 * 발주량 변경값이 양수: 'outboundToPurchase' (출고 -> 발주)
 * 발주량 변경값이 음수: 'purchaseToOutbound' (발주 -> 출고)
 * @param purchaseVolumeForChange 발주량 변경값을 기준으로 사용한다. 음수 사용 가능
 * @param requiredDate '2023-02-06'
 */
const createProductRequestDataManually = (productId: string, purchaseVolumeForChange: number, requiredDate: string) => {
  const productRequest: ProductRequest = {
    timestamp: formatDate(new Date(), "yyyy-MM-dd'T'HH:mm:ss+0900"),
    requiredDate: requiredDate,
    path: 'manual',
    documentId: 'manual',
    storeId: 'manual',
    type: purchaseVolumeForChange > 0 ? 'outboundToPurchase' : 'purchaseToOutbound',
    status: 'requested',
    productId,
    volume: purchaseVolumeForChange,
  };
  return productRequest;
};

/**
 * 상품의 발주서를 가져온다.
 */
const getPurchaseOrderForProduct = async (date: string, product: ProductDoc) => {
  const supplierId = product.suppliers[0];
  if (!supplierId) {
    throw new Error('supplierId is not found');
  }
  const purchaseOrders = await getPurchaseOrders([
    ['date', '==', date],
    ['supplierId', '==', supplierId],
  ]);
  if (purchaseOrders.length === 0) {
    throw new Error(`purchaseOrder is not found: ${date} ${supplierId}`);
  }
  return purchaseOrders[0];
};

/**
 * 발주서에 속한 요청 내역을 수동 요청을 제외하고 모두 가져옵니다.
 */
const getProductRequestsForPurchaseOrder = async (purchaseOrder: PurchaseOrder) => {
  const productRequestIds = purchaseOrder.productRequestIds;
  const promises = productRequestIds.map(async (productRequestId) => getProductRequestById(productRequestId));
  const response = await Promise.all(promises);
  const productRequests = response.filter((productRequest) => productRequest) as ProductRequestDoc[];

  return productRequests;
};

/**
 * 기존 요청내역과 새로운 요청내역을 종합하여 발주서를 다시 생성합니다.
 */
const createNewPurchaseOrderData = (
  supplier: SupplierDoc,
  products: ProductDoc[],
  productRequests: ProductRequest[],
  newProductRequest: ProductRequest
) => {
  // 발주서의 이전 요청과 신규 요청을 묶어서 상품별로 분류합니다.
  const groupByProductId = groupBy([...productRequests, newProductRequest], 'productId');

  // 상품 요청과 상품 정보를 매치시킵니다.
  const [requestGroupList, withoutProduct] = partition(
    Object.entries(groupByProductId).map(([productId, productRequests]) => {
      const product = products.find((product) => product._id === productId);
      return {
        product,
        productRequests,
      };
    }),
    ({ product }) => product
  );
  if (withoutProduct.length > 0) {
    throw new Error(`상품 매칭에 실패한 요청이 있습니다. ${withoutProduct[0].productRequests[0].productId}`);
  }

  // 새로운 발주서 내용을 생성합니다.
  const purchaseList = (
    requestGroupList as {
      product: ProductDoc;
      productRequests: ProductRequestDoc[];
    }[]
  )
    // 만약 발주 내역 수정에 따라 발주할 상품의 수량이 0인경우 해당 메시지를 제외합니다.
    .filter(({ productRequests }) => sumBy(productRequests, 'volume') !== 0)
    .map(({ product, productRequests }) => createPurchaseOrderMessage(product, supplier, productRequests));

  const updateData: Partial<PurchaseOrder> = {
    message: purchaseList.join('\n'),
    purchaseList,
  };
  return updateData;
};

/**
 * 재고 위치를 비교합니다.
 * 'A-01-F1' > 'A-02-F1' > 'B-04-P1'
 */
export const compareBinLocation = (aLocation: string | null, bLocation: string | null) => {
  if (!aLocation === null && !bLocation === null) {
    return 0; // 순서를 변경하지 않음
  }
  if (aLocation === null) {
    return 1; // a를 배열의 뒤로 보냄
  }
  if (bLocation === null) {
    return -1; // b를 배열의 뒤로 보냄
  }
  return aLocation.localeCompare(bLocation, 'en', { numeric: true });
};
