import { PlusOutlined } from '@ant-design/icons';
import { ProductDoc } from '@gooduncles/gu-app-schema';
import { Button, Checkbox, Modal, Tooltip, notification } from 'antd';
import { FC, useCallback, useState } from 'react';
import { ProductRequestDoc } from 'src/schema/schema-product-request';
import { calcProductRequestVolume, updateProductRequestManually } from 'src/utils/product-request-util';

import { errorObjectToString } from 'src/lib/1/util';

import classes from './CreateManualRequestModal.module.scss';

interface CreateManualRequestModalProps {
  date: string;
  products: ProductDoc[];
  productRequests: ProductRequestDoc[];
  product: ProductDoc;
  supplierName: string;
  userEmail: string;
}

const CreateManualRequestModal: FC<CreateManualRequestModalProps> = ({
  date,
  products,
  productRequests,
  product,
  supplierName,
  userEmail,
}) => {
  const { totalRequestVolume, totalPurchaseVolume, totalOutboundVolume } = calcProductRequestVolume(productRequests);

  const [open, setOpen] = useState(false);
  const [onProductStockAutoSync, setOnProductStockAutoSync] = useState<boolean>(true);
  const [loading, setLoading] = useState(false);
  const [purchaseVolume, setPurchaseVolume] = useState(totalPurchaseVolume);
  const [outboundVolume, setOutboundVolume] = useState(totalOutboundVolume);
  const editable =
    purchaseVolume + outboundVolume === totalRequestVolume &&
    (purchaseVolume !== totalPurchaseVolume || outboundVolume !== totalOutboundVolume);
  // 출고량의 변화보다 현재 재고가 적은 경우
  const isEnoughStock = product.stock !== Infinity && product.stock >= outboundVolume - totalOutboundVolume;
  const disabled = !isEnoughStock && !onProductStockAutoSync;

  const initialVolume = useCallback(() => {
    setPurchaseVolume(totalPurchaseVolume);
    setOutboundVolume(totalOutboundVolume);
  }, [totalOutboundVolume, totalPurchaseVolume]);

  const onOpen = useCallback(() => {
    initialVolume();
    setOnProductStockAutoSync(true);
    setOpen(true);
  }, [initialVolume]);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const onAddPurchaseVolume = useCallback(() => {
    if (outboundVolume > 0) {
      setPurchaseVolume((prev) => prev + 1);
      setOutboundVolume((prev) => prev - 1);
    }
  }, [outboundVolume]);

  const onAddOutboundVolume = useCallback(() => {
    if (purchaseVolume > 0) {
      setOutboundVolume((prev) => prev + 1);
      setPurchaseVolume((prev) => prev - 1);
    }
  }, [purchaseVolume]);

  const onOk = useCallback(async () => {
    const purchaseVolumeForChange = purchaseVolume - totalPurchaseVolume;
    const outboundVolumeForChange = outboundVolume - totalOutboundVolume;
    setLoading(true);
    try {
      await updateProductRequestManually(
        userEmail,
        date,
        products,
        {
          product,
          purchaseVolumeForChange,
          outboundVolumeForChange,
        },
        onProductStockAutoSync
      );
      notification.success({
        message: '발주/출고량 변경 성공',
        description: `발주량: ${purchaseVolumeForChange} \n출고량: ${outboundVolumeForChange}`,
      });
    } catch (error) {
      console.error(error);
      const description = errorObjectToString(error);
      notification.error({
        message: '발주/출고량 변경 실패',
        description,
      });
    }
    setLoading(false);
    onClose();
  }, [
    date,
    onClose,
    onProductStockAutoSync,
    outboundVolume,
    product,
    products,
    purchaseVolume,
    totalOutboundVolume,
    totalPurchaseVolume,
    userEmail,
  ]);

  return (
    <>
      <Button onClick={onOpen} loading={loading}>
        수정
      </Button>
      <Modal
        title='상품 요청내역 수정'
        open={open}
        destroyOnClose
        okText='적용하기'
        cancelText='취소'
        onOk={onOk}
        onCancel={onClose}
        closable={false}
        okButtonProps={{ disabled: !editable || disabled, danger: !isEnoughStock, loading }}
        cancelButtonProps={{ loading }}>
        <div className={classes.manualModalBody}>
          <div className={classes.requestInfo}>
            <div className={classes.requestInfoItem}>
              <span>{product.fullName}</span>
              <span>{product.productId}</span>
            </div>
            <div className={classes.requestInfoItem}>
              <span>{supplierName}</span>
              <span className={`${!isEnoughStock ? classes.warning : ''}`}>현재 재고: {product.stock}</span>
            </div>
          </div>
          <div className={classes.syncOption}>
            <Tooltip title='선택된 경우 출고량 변화에 맞춰 자동으로 재고를 조정합니다.'>
              <Checkbox checked={onProductStockAutoSync} onChange={(e) => setOnProductStockAutoSync(e.target.checked)}>
                재고 자동 동기화
              </Checkbox>
            </Tooltip>
            <div className={classes.isError}>
              {disabled ? '⚠️ 현재 재고가 출고량보다 부족한 경우 재고 자동 동기화를 활성화해주세요.' : ''}
            </div>
          </div>
          <div className={`${classes.gridItem} ${classes.header}`}>
            <div>필요 수량</div>
            <div>발주량</div>
            <div>출고량</div>
          </div>
          <div className={classes.gridItem}>
            <div className={classes.request}>{totalRequestVolume}</div>
            <div className={classes.purchase}>{purchaseVolume}</div>
            <div className={classes.volume}>{outboundVolume}</div>
          </div>
          <div className={`${classes.gridItem} ${classes.footer}`}>
            <div>
              <Button onClick={initialVolume}>초기화</Button>
            </div>
            <div>
              <Button
                icon={<PlusOutlined />}
                disabled={purchaseVolume >= totalRequestVolume}
                onClick={onAddPurchaseVolume}>
                발주량
              </Button>
            </div>
            <div>
              <Button
                icon={<PlusOutlined />}
                disabled={outboundVolume >= totalRequestVolume}
                onClick={onAddOutboundVolume}>
                출고량
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CreateManualRequestModal;
