import { ProductDoc } from '@gooduncles/gu-app-schema';
import { ColDef, ColGroupDef, RowClassParams } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { message, notification } from 'antd';
import { deleteField } from 'firebase/firestore';
import { set } from 'lodash-es';
import { FC, useMemo } from 'react';

import { errorObjectToString } from 'src/lib/1/util';
import { SupplierDoc } from 'src/lib/3/schema-supplier';
import { updateProductBinLocation } from 'src/lib/4/firebase-short-cut';

export type ProductBinLocationTableRowData = ProductDoc & {
  supplier: SupplierDoc | null;
  binLocation: string | null;
};

interface ProductBinLocationTableProps {
  rowData: ProductBinLocationTableRowData[];
}

const defaultColDef = {
  sortable: true,
  resizable: true,
  filter: true,
};

/**
 * 1. 앞자리는 대문자 알파벳 한 글자가 들어가야 한다.
 * 2. 뒷자리는 숫자 0~9까지 최대 4자리까지 허용한다.
 */
const binLocationRegex = /^[A-Z][0-9]{0,4}$/;

const ProductBinLocationTable: FC<ProductBinLocationTableProps> = ({ rowData }) => {
  const columnDefs: (ColDef<ProductBinLocationTableRowData> | ColGroupDef<ProductBinLocationTableRowData>)[] = useMemo(
    () => [
      { field: '_id', hide: true },
      {
        headerName: '구분',
        field: 'supplier.label',
        width: 100,
      },
      {
        headerName: '매입처',
        field: 'supplier.name',
        width: 140,
      },
      {
        headerName: '상품명',
        field: 'fullName',
        width: 300,
      },
      {
        headerName: '재고 위치',
        field: 'binLocation',
        width: 120,
        editable: true,
        valueSetter: (params) => {
          const value: string | null = params.newValue;
          // 삭제도 허용한다.
          if (value === null) {
            set(params.data, 'binLocation', value);
            return true;
          }
          const upperCaseValue = value.toUpperCase();
          if (!binLocationRegex.test(upperCaseValue)) {
            notification.error({
              message: '위치 정보는 알파벳 대문자 한 글자와 숫자 0~9까지 0~9자리로 구성되어야 합니다.',
              description: '예) A1, B2, C1234, D9999',
            });
            return false;
          }
          set(params.data, 'binLocation', upperCaseValue);
          return true;
        },
        onCellValueChanged: async (params) => {
          const { newValue, oldValue, data } = params;
          if (newValue === oldValue) return;
          const binLocation = newValue;
          const { _id } = data;
          try {
            if (!binLocation) {
              await updateProductBinLocation({ [`productBinLocation.${_id}`]: deleteField() });
              message.success('삭제완료.', 1);
            } else {
              await updateProductBinLocation({ [`productBinLocation.${_id}`]: binLocation });
              message.success('변경완료.', 1);
            }
          } catch (error) {
            console.error(error);
            const description = errorObjectToString(error);
            notification.error({
              message: '재고 위치 변경에 실패했습니다.',
              description,
            });
          }
        },
      },
    ],
    []
  );

  return (
    <div className='ag-theme-alpine height100 flex1'>
      <AgGridReact
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        // rowId를 지정해주지 않으면 데이터 변경시 refresh가 발생한다.
        getRowId={(params) => params.data._id}
        // 편집 완료후 스크롤 이동을 막는다.
        suppressScrollOnNewData={true}
        getRowStyle={(params: RowClassParams<ProductBinLocationTableRowData>) => ({
          opacity: params.data?.supplier?.label === 'E' ? 1 : 0.5,
          backgroundColor: params.data?.supplier?.label === 'E' ? 'white' : 'var(--gray200)',
        })}
      />
    </div>
  );
};

export default ProductBinLocationTable;
