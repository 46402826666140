import { CustomPromotionSectionDoc, PromotionSectionItem, StoreDoc } from '@gooduncles/gu-app-schema';
import { GridApi } from 'ag-grid-community';
import { Button, message, notification } from 'antd';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { map } from 'rxjs';

import { errorObjectToString } from 'src/lib/1/util';
import {
  createCustomPromotionSection,
  getCustomPromotionSection,
  getProductById,
  observeCustomPromotionSection,
  updateCustomPromotionSection,
} from 'src/lib/4/firebase-short-cut';
import { onCellValueChangedWithUpdate } from 'src/lib/6/ag-grid-util';

import useStoresForSearch from 'src/hooks/useStoresForSearch';

import StoreSelector from 'src/components/Selector/StoreSelector';

import PromotionSectionTable from './PromotionSectionTable';

const onCellValueChanged = onCellValueChangedWithUpdate<CustomPromotionSectionDoc>('매장별 프로모션');
const onRowDragEndForChildren = async (params: any) => {
  const customPromotionId: string = params.node.data._id;
  const currentItems: PromotionSectionItem[] = params.node.data.items;
  if (!customPromotionId || !currentItems) {
    notification.error({ message: '프로모션 정보를 찾을 수 없습니다.' });
    return;
  }
  const newItems: PromotionSectionItem[] = [];
  (params?.api as GridApi)?.forEachNode((node, idx) => {
    if (node.data._id === customPromotionId) {
      newItems.push({
        id: node.data.id,
        index: idx + 1,
      });
    }
  });

  if (!newItems || newItems?.length === 0) {
    return;
  }

  await updateCustomPromotionSection(customPromotionId, {
    items: newItems,
  });
  message.success('프로모션 상품 순서를 변경했습니다.');
};
/**
 * 프로모션 상품을 추가한다.
 */
const onSelectValueChangeWapper = async (params: any, value: string) => {
  const customPromotionId: string = params.data._id;
  const customPromotion = await getCustomPromotionSection(customPromotionId);
  if (!customPromotion) {
    notification.error({ message: '프로모션 정보를 찾을 수 없습니다.' });
    return;
  }

  if (customPromotion.items.map((i) => i.id).includes(value)) {
    notification.error({ message: '이미 추가된 상품입니다.' });
    return;
  }
  const product = value ? await getProductById(value) : null;
  if (!product) {
    notification.error({ message: '상품을 찾을 수 없습니다.' });
    return;
  }

  const newItems = [
    ...(customPromotion.items.length > 0 ? customPromotion.items : []),
    { id: value, index: customPromotion.items.length + 1 },
  ];

  await updateCustomPromotionSection(customPromotionId, {
    items: newItems,
  });
  message.success('프로모션에 상품을 추가했습니다.');
};

const CustomPromotionSectionDashboard: FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const store = searchParams.get('store') ?? null;
  const selectedStoreCode = store || null;
  const { stores } = useStoresForSearch();
  const selectedStore: StoreDoc | null = useMemo(() => {
    return selectedStoreCode ? (stores ?? []).find((store) => store.storeCode === selectedStoreCode) ?? null : null;
  }, [stores, selectedStoreCode]);
  const [rowData, setRowData] = useState<CustomPromotionSectionDoc[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSetSelectedStoreCode = (value: string | null) => {
    if (value === null) {
      navigate('/promotion/custom');
      return;
    }
    navigate(`/promotion/custom?store=${value}`);
  };

  const onCreatePromotionSectionForStore = useCallback(async () => {
    if (!selectedStore?._id) {
      notification.error({ message: '선택한 매장의 id가 유효하지 않습니다.' });
      return;
    }
    setLoading(true);
    try {
      await createCustomPromotionSection({
        positionIndex: rowData.length + 1,
        storeCategory: null,
        isActive: false,
        title: '신규 프로모션',
        description: null,
        items: [],
        maxItemsToPreview: 10,
        direction: 'horizontal',
        isDeleted: false,
        storeId: selectedStore._id,
      });
    } catch (error) {
      console.error(error);
      const description = errorObjectToString(error);
      notification.error({ message: '매장별 프로모션 생성 실패', description });
    }
    setLoading(false);
  }, [rowData.length, selectedStore?._id]);

  useEffect(() => {
    const promotionSubscription = observeCustomPromotionSection([['isDeleted', '!=', true]])
      .pipe(
        map((docs) =>
          docs
            .filter((doc) => doc.isDeleted !== true)
            .sort((a, b) => a.positionIndex - b.positionIndex)
            .sort((a, b) => a.storeId.localeCompare(b.storeId))
        )
      )
      .subscribe((docs) => {
        const promotionsWithStoreName = docs
          .map((doc) => {
            const store = stores?.find((store) => store._id === doc.storeId);
            return {
              ...doc,
              storeNickname: store?.storeNickname ?? '',
            };
          })
          .filter((doc) => (selectedStore?._id ? doc.storeId === selectedStore?._id : true));
        setRowData(promotionsWithStoreName);
      });
    return () => {
      promotionSubscription.unsubscribe();
    };
  }, [selectedStore?._id, stores]);

  return (
    <div className='tabBody height100'>
      <div className='ag-theme-alpine height100 flexColumn'>
        <div className='flexRow flexSpaceBetween'>
          <StoreSelector
            selectedStoreCode={selectedStoreCode}
            setSelectedStoreCode={handleSetSelectedStoreCode}
            maxWidth={400}
          />
          {selectedStore && (
            <Button onClick={onCreatePromotionSectionForStore} loading={loading}>
              {selectedStore.storeNickname} 신규 프로모션 생성
            </Button>
          )}
        </div>
        <PromotionSectionTable
          type='custom'
          rowData={rowData}
          onCellValueChanged={onCellValueChanged}
          onSelectValueChangeWapper={onSelectValueChangeWapper}
          onRowDragEndForChildren={onRowDragEndForChildren}
        />
      </div>
    </div>
  );
};

export default CustomPromotionSectionDashboard;
