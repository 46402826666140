import { FC } from 'react';

import { formatNumber } from 'src/lib/1/util';

import classes from './DeliveryColumnHeader.module.scss';

interface DeliveryColumnHeaderProps {
  totalAmount: number;
  spotCount: number;
  storeCount: number;
}

const DeliveryColumnHeader: FC<DeliveryColumnHeaderProps> = ({ totalAmount, spotCount, storeCount }) => {
  return (
    <div className={classes.spotColumnHeader}>
      <p className={classes.totalAmount}>
        <span className={classes.label}>총 금액</span>
        <span className={classes.price}>
          <b>{formatNumber(totalAmount)}</b>원
        </span>
      </p>
      <div className={classes.spotCount}>
        <div>
          <span className={classes.label}>스팟</span>
          <span>{spotCount}</span>
        </div>
        <div>
          <span className={classes.label}>매장</span>
          <span>{storeCount}</span>
        </div>
      </div>
    </div>
  );
};

export default DeliveryColumnHeader;
