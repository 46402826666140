import { FC } from 'react';

import useProduct from 'src/hooks/useProduct';

const ProductName: FC<{ productId: string }> = ({ productId }) => {
	const { product, productLoading } = useProduct(productId);

	if (productLoading) {
		return <div>isLoading</div>;
	}

	return <span>{product?.fullName ?? ''}</span>;
};

export default ProductName;
