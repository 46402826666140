import { StoreDoc } from '@gooduncles/gu-app-schema';
import { useMemo } from 'react';
import useSWRImmutable from 'swr/immutable';

import { textSort } from 'src/lib/1/util';
import { FirebaseManager, WHERE } from 'src/lib/3/firebase-manager';

const firebaseManager = FirebaseManager.getInstance();
const storePath = 'store';

const getStoresForSearch = (params: [string, WHERE[]]) =>
  firebaseManager.getDocsArrayWithWhere<StoreDoc>(storePath, params[1]);

const useStoresForSearch = (where: WHERE[] = []) => {
  const { data: stores, isValidating } = useSWRImmutable(['getStoresForSearch', where], getStoresForSearch, {
    errorRetryCount: 2,
  });
  const storeSearchValues = useMemo(
    () =>
      stores
        ?.filter((store) => store.storeCode)
        // ?.filter((store) => store.storeCode && storeCodeRegex.test(store.storeCode))
        .sort((a, b) => textSort(a.storeCode ?? '', b.storeCode ?? ''))
        .map((store) => `${store.storeCode}-${store.storeNickname ?? store.storeName}`) ?? null,
    [stores]
  );

  return {
    stores,
    storeSearchValues,
    isValidating,
  };
};

export default useStoresForSearch;
