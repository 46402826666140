import { FC } from 'react';

import { useTitle } from 'src/hooks/useTitle';

import SearchLog from 'src/components/SearchLog/SearchLog';

const SearchLogPage: FC<{ title: string }> = ({ title }) => {
  useTitle(`GU 관리자 | ${title}`);
  return <SearchLog />;
};

export default SearchLogPage;
