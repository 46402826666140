import { FC, PropsWithChildren } from 'react';
import React from 'react';
import { useDrop } from 'react-dnd';
import { DndBoxItem, DndCardDragResult, DndCardItem, DndItemType } from 'src/schema/schema-drag-and-drop';

import classes from './DndColumn.module.scss';

interface DndColumnProps {
  title: string;
  groupId: string;
  selectedItems: DndCardItem[];
  clearSelection: () => void;
  setInsertPosition: (groupId: string, hoverIndex: number, insertIndex: number) => void;
  headerComponent?: JSX.Element;
  pinRight?: boolean;
}

const DndColumn: FC<PropsWithChildren<DndColumnProps>> = ({
  title,
  groupId,
  selectedItems,
  clearSelection,
  setInsertPosition,
  children,
  headerComponent,
  pinRight,
}) => {
  const childrenLength = React.Children.count(children);
  const [{ isOver }, drop] = useDrop<DndBoxItem, DndCardDragResult, { isOver: boolean }>({
    accept: DndItemType.CARD,
    drop: (item, monitor) => {
      const didDrop = monitor.didDrop();
      if (didDrop) {
        return;
      }

      // 1. 선택된 카드를 움직이는 건지, 아니면 선택하지 않은 새로운 카드를 움직이는지 확인한다.
      const filteredSelectedItems = selectedItems.filter((i) => i.id !== item.id);
      // 선택된 카드에서 현재 카드를 뺀 길이과 빼지 않은 길이가 같으면, 현재 카드는 선택된 카드에 포함되지 않은 카드이다.
      const isCurrentItemSelected = selectedItems.length !== filteredSelectedItems.length;

      const draggedItems = isCurrentItemSelected ? selectedItems : [item];
      // 현재 드래그중인 카드가 선택된 카드에 포함된게 아니라면, 선택된 카드를 모두 해제한다.
      if (!isCurrentItemSelected) {
        clearSelection();
      }

      // 2. 카드의 정렬 순서와는 별개로 드래그 중인 미리보기에서는
      // 사용자가 끌어당긴 카드를 가장 위로 보여줘야 하기 때문에, 별도의 stack을 만든다.
      const previewStack = [item, ...filteredSelectedItems];
      return { item, draggedItems, previewStack };
    },
    hover: (item, monitor) => {
      setInsertPosition(groupId, monitor.getItem().index, item.index);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  return (
    <div className={classes.columnContainer}>
      <div className={classes.columnHeader}>
        <h3>{title}</h3>
        {headerComponent}
      </div>
      <div
        ref={drop}
        className={`${classes.column} ${isOver ? classes.isOver : ''} ${pinRight ? classes.pinRight : ''} ${
          childrenLength === 0 ? classes.columnEmpty : ''
        }`}>
        {childrenLength === 0 ? (
          <div className={classes.emptyText}>Empty</div>
        ) : (
          <div className={classes.columnBody}>{children}</div>
        )}
      </div>
    </div>
  );
};

export default DndColumn;
