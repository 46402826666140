import { Switch, message, notification } from 'antd';
import { FC, useState } from 'react';

import { errorObjectToString } from 'src/lib/1/util';
import { StoreDoc } from '@gooduncles/gu-app-schema';
import { updateStore } from 'src/lib/4/firebase-short-cut';

const ChargeDeliveryFeeCellRenderer: FC = (params: any) => {
  const [loading, setLoading] = useState<boolean>(false);
  const store = params.data as StoreDoc;
  const checked = !!store.chargeDeliveryFee;

  const onChange = async (checked: boolean) => {
    setLoading(true);
    try {
      await updateStore(store._id, { chargeDeliveryFee: checked });
      message.success(`배송비 부과 ${checked ? 'on' : 'off'}`);
    } catch (error) {
      console.error(error);
      const msg = errorObjectToString(error);
      notification.error({
        message: '배송비 부과 여부 변경 실패',
        description: msg,
      });
    }
    setLoading(false);
  };

  return <Switch checked={checked} onChange={onChange} loading={loading} />;
};

export default ChargeDeliveryFeeCellRenderer;
