import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { useTitle } from '../hooks/useTitle';

import StoreCategoryComponent from 'src/components/StoreCategory/StoreCategoryComponent';

const StoreCategoryPage: FC<{ title: string }> = ({ title }) => {
  useTitle(`GU 관리자 | ${title}`);
  return (
    <Routes>
      <Route path=':tab' element={<StoreCategoryComponent />} />
      <Route path='/' element={<Navigate to='/store-category/korean' replace />} />
    </Routes>
  );
};

export default StoreCategoryPage;
