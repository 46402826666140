import useImmutableSWR from 'swr/immutable';

import { getUsers } from 'src/lib/4/firebase-short-cut';

const useUsers = () => {
  const { data: users, error, isValidating: usersLoading } = useImmutableSWR('users', () => getUsers());
  return { users, error, usersLoading };
};

export default useUsers;
