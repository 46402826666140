import { Input, InputRef, message } from 'antd';
import { debounce } from 'lodash-es';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { preventMouseDown } from 'src/lib/1/util';

import useAlgoliaSearch from 'src/hooks/useAlgoliaSearch';

import classes from './AlgoriaSearch.module.scss';

const { Search } = Input;
const pageSize = 20;

interface AlgoliaSearchProps {
  onSearch: (productId: string, productFullName: string) => void;
  placeholder?: string;
  disabled?: boolean;
}

interface PreviewItemProps extends React.HTMLAttributes<HTMLDivElement> {
  value: string | undefined;
  focused: boolean;
  productId: string;
}

const PreviewItem: FC<PreviewItemProps> = ({ value, focused, productId, ...props }) => {
  if (!value) {
    return null;
  }
  return (
    <div {...props} className={focused ? classes.focused : ''}>
      <span className={classes.productId}>{productId}</span>
      <span dangerouslySetInnerHTML={{ __html: value }} />
    </div>
  );
};

const AlgoliaSearch: FC<AlgoliaSearchProps> = ({ onSearch, placeholder, disabled }) => {
  const inputRef = useRef<InputRef>(null);
  const searchPreviewRef = useRef<HTMLDivElement>(null);
  const [selectedItemIdx, setSelectedItemIdx] = useState<number | null>(null);
  const [userInput, setUserInput] = useState<string>('');
  const [keyword, setKeyword] = useState<string>('');
  const [hitsPerPage, setHitsPerPage] = useState<number>(pageSize);

  const [focus, setFocus] = useState<boolean>(false);
  const handleInputChangeWithDebounce = useMemo(
    () =>
      debounce((e) => {
        setKeyword(e.target.value);
        setFocus(e.target.value.length > 0);
        setHitsPerPage(pageSize);
      }, 300),
    [setKeyword]
  );
  const { hits, total, loading, error } = useAlgoliaSearch(keyword, hitsPerPage);

  const handleOnSearch = useCallback(
    (value: string) => {
      if (value.length > 0 && selectedItemIdx !== null) {
        const selectedItem = hits?.[selectedItemIdx];
        if (selectedItem) {
          onSearch(selectedItem.objectID, selectedItem.fullName);
          inputRef.current?.blur();
        }
        return;
      }
      message.info('검색 결과를 선택해주세요');
      inputRef.current?.blur();
    },
    [hits, onSearch, selectedItemIdx]
  );

  const onKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>, previewLength: number) => {
    if (!('key' in event)) {
      return;
    }
    if (event.key === 'Escape') {
      setUserInput('');
      setSelectedItemIdx(null);
      return;
    }

    if (event.key === 'ArrowUp') {
      setSelectedItemIdx((prev) => {
        return prev === null || prev === 0 ? null : prev - 1;
      });
      return;
    }
    if (event.key === 'ArrowDown') {
      setSelectedItemIdx((prev) => {
        return prev === null ? 0 : prev === previewLength - 1 ? prev : prev + 1;
      });
      return;
    }

    if (event.key !== 'Enter') {
      setSelectedItemIdx(null);
      return;
    }
  }, []);

  const showMore = useCallback(() => {
    setHitsPerPage((prev) => prev + pageSize);
  }, []);

  useEffect(() => {
    if (searchPreviewRef) {
      const searchPreview = searchPreviewRef.current;
      if (searchPreview) {
        const selected = searchPreview.querySelector(`.${classes.focused}`);
        if (selected) {
          searchPreview.scrollTop = (selected as any).offsetTop - 100;
        }
      }
    }
  }, [searchPreviewRef, selectedItemIdx]);

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  useEffect(() => {
    inputRef.current?.focus();
  }, [inputRef]);

  return (
    <div className={classes.searchContainer}>
      <div className={classes.searchBar}>
        <Search
          allowClear
          size='large'
          ref={inputRef}
          loading={loading}
          disabled={disabled}
          placeholder={placeholder}
          value={userInput}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          onKeyDown={debounce((e) => onKeyDown(e, hits?.length ?? 0), 10)}
          onSearch={handleOnSearch}
          onChange={(e) => {
            setUserInput(e.target.value);
            handleInputChangeWithDebounce(e);
            setSelectedItemIdx(null);
          }}
        />
      </div>
      <div className={`${classes.searchPreview} ${focus ? '' : classes.hide}`} ref={searchPreviewRef}>
        {hits?.map((h, idx) => (
          <PreviewItem
            key={h.objectID}
            productId={h.objectID}
            value={h._highlightResult?.fullName?.value}
            focused={idx === selectedItemIdx}
            onMouseDown={preventMouseDown}
            onClick={() => {
              onSearch(h.objectID, h.fullName);
              setFocus(false);
              inputRef.current?.blur();
            }}
          />
        ))}
        {total && total > hitsPerPage ? (
          <button className={classes.showMore} onMouseDown={preventMouseDown} onClick={showMore}>
            더보기
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default AlgoliaSearch;
