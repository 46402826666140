import { CloudUploadOutlined, DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import { HyosungCMSMember, HyosungCMSMemberDoc } from '@gooduncles/gu-app-schema';
import { Button, ConfigProvider, Modal, notification } from 'antd';
import { isEqual } from 'lodash-es';
import { FC, useEffect } from 'react';
import { HyosungCMSMemberRawData } from 'src/schema/schema-raw-payment-item';
import { hyosungCMSMemberExcelToMember } from 'src/utils/payment-util';

import { errorObjectToString } from 'src/lib/1/util';
import { readExcelFile } from 'src/lib/1/xlsx-util';
import { FirebaseManager } from 'src/lib/3/firebase-manager';
import { setHyosungCMSMember } from 'src/lib/4/firebase-short-cut';

import useExcelUploadModal from 'src/hooks/useExcelUploadModal';

import FileUploadArea from 'src/components/Common/FileUploadArea/FileUploadArea';

import classes from './UploadModal.module.scss';

import HyosungCMSMemberTable from '../HyosungCMSMemberTable/HyosungCMSMemberTable';

type HyosungCMSMemberForUpload = HyosungCMSMember & { isNew: boolean; diff: boolean };

const firebaseManager = FirebaseManager.getInstance();

/**
 * 효성CMS회원정보 엑셀파일을 객체 배열로 변환합니다.
 */
const onReadExcelFile = async (
  file: File,
  currentMembers: HyosungCMSMemberDoc[]
): Promise<HyosungCMSMemberForUpload[] | null> => {
  try {
    const sheet = await readExcelFile<HyosungCMSMemberRawData>(file, true);
    if (!Object.keys(sheet[0]).some((key) => key.includes('회원번호'))) {
      throw new Error('회원번호 컬럼이 존재하지 않습니다.');
    }
    const data = hyosungCMSMemberExcelToMember(sheet);
    const dataWithDiff = data.map((member) => {
      const currentMember0 = currentMembers.find((m) => m.storeCode === member.storeCode);
      const { _id, _timeCreate, _timeUpdate, _timeDelete, ...currentMember } =
        currentMember0 ?? ({} as HyosungCMSMemberDoc);
      _id;
      _timeCreate;
      _timeUpdate;
      _timeDelete;
      return {
        ...member,
        isNew: currentMember0 === undefined,
        diff: currentMember0 ? !isEqual(currentMember, member) : false,
      };
    });

    return dataWithDiff;
  } catch (error) {
    console.error(error);
    const description = errorObjectToString(error);
    notification.error({
      message: '파일 업로드 실패',
      description,
    });
  }
  return null;
};

/**
 * 효성CMS 회원정보를 서버에 업로드합니다.
 */
const onUploadHyosungCMSMembers = async (members: HyosungCMSMemberForUpload[]) => {
  try {
    firebaseManager.batchStart();
    for (const member0 of members) {
      const { isNew, diff, ...member } = member0;
      if (isNew || diff) {
        await setHyosungCMSMember(member.storeCode, member, {
          bMerge: false,
          bBatch: true,
        });
      }
    }
    await firebaseManager.batchEnd();
  } catch (error) {
    console.error(error);
    const description = errorObjectToString(error);
    notification.error({
      message: '회원정보 서버 업로드 실패',
      description,
    });
  }
};

interface HyosungCMSMemberUploadModalProps {
  currentMembers: HyosungCMSMemberDoc[];
}

/**
 * 효성CMS 회원정보 업로드 모달
 */
const HyosungCMSMemberUploadModal: FC<HyosungCMSMemberUploadModalProps> = ({ currentMembers }) => {
  const {
    open,
    data: members,
    loading,
    setLoading,
    fileToUpload,
    setFileToUpload,
    openModal,
    removeFile,
    closeModal,
    onSubmit,
    setDataFromExcel,
  } = useExcelUploadModal<HyosungCMSMemberForUpload>(onUploadHyosungCMSMembers);
  const needToUpload = (members?.filter((member) => member.isNew || member.diff) ?? []).length;

  useEffect(() => {
    if (fileToUpload) {
      setLoading(true);
      onReadExcelFile(fileToUpload, currentMembers)
        .then(setDataFromExcel)
        .finally(() => setLoading(false));
    }
  }, [currentMembers, fileToUpload, setDataFromExcel, setFileToUpload, setLoading]);

  return (
    <ConfigProvider theme={{ token: { colorPrimary: '#6c32f2' } }}>
      <Button type='primary' icon={<CloudUploadOutlined />} style={{ fontWeight: 600 }} onClick={openModal}>
        효성CMS 회원정보 업로드
      </Button>
      <Modal
        title='효성CMS 회원정보 업로드'
        open={open}
        onOk={onSubmit}
        onCancel={closeModal}
        okText={`${needToUpload}개 업로드(덮어쓰기)`}
        cancelText='취소'
        width='1200px'
        maskClosable={false}
        keyboard={false}
        centered
        okButtonProps={{ loading, disabled: needToUpload === 0 }}
        styles={{ body: { height: 'calc(100vh - 154px)', overflow: 'scroll' } }}>
        {/* 파일 없음 */}
        {!fileToUpload && (
          <FileUploadArea
            fileToUpload={fileToUpload}
            setFile={setFileToUpload}
            subTitle='효성CMS 회원정보 엑셀파일만 지원합니다.'
          />
        )}
        {/* 파일 있음 */}
        {fileToUpload && (
          <div className={classes.uploadModalContainer}>
            <div className={classes.uploadFile}>
              <span>{fileToUpload.name}</span>
              <Button type='text' icon={<DeleteOutlined />} danger onClick={removeFile} />
            </div>
            <section className={classes.tableContainer}>
              {members ? (
                <HyosungCMSMemberTable rowData={members} />
              ) : (
                <div className={classes.loadingBox}>
                  <p>매장 정보를 매칭하고 있습니다.</p>
                  <LoadingOutlined className={classes.loadingIcon} />
                </div>
              )}
            </section>
          </div>
        )}
      </Modal>
    </ConfigProvider>
  );
};

export default HyosungCMSMemberUploadModal;
