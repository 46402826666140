import { ProductDoc } from '@gooduncles/gu-app-schema';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { ColDef, ColGroupDef, NewValueParams, RowClassParams } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { Button, message } from 'antd';
import { differenceInHours } from 'date-fns';
import { FC, useCallback, useMemo, useState } from 'react';

import {
  productCategories,
  productPackings,
  productStates,
  productStorages,
  purchaseBundleToKr,
} from 'src/lib/1/constant';
import { formatDate } from 'src/lib/1/date-util';
import { StorePurchaseConversionItem } from 'src/lib/1/schema-purchase-conversion';
import { errorObjectToString, formatNumber, sleep } from 'src/lib/1/util';
import { SupplierDoc } from 'src/lib/3/schema-supplier';
import { updateProduct } from 'src/lib/4/firebase-short-cut';
import { preprocessProductData, productValueSanitizer, productValueValidator } from 'src/lib/4/product-util';
import {
  arrayValueFormatter,
  loadGridState,
  numberFormatter,
  onCellValueChangedForProductSupplierConversionItem,
  onSelectValueChangeForProductSupplier,
  resetGridState,
  saveGridState,
  suppressEnterKeyEvent,
  timestampFormatter2,
} from 'src/lib/6/ag-grid-util';

import SelectCellEditor from 'src/components/Common/CellEditor/SelectCellEditor';
import DownloadRawDataCell from 'src/components/Common/DownloadRawDataCell/DownloadRawDataCell';
import {
  ProductCategoryCellRenderer,
  ProductPackingCellRenderer,
  ProductStateCellRenderer,
  ProductStoragesCellRenderer,
} from 'src/components/Common/ProductCellRenderer/ProductCellRenderer';
import ProductImg from 'src/components/Common/ProductImg/ProductImg';

import classes from './ProductTable.module.scss';

import CreateProductButton from './CreateProductButton/CreateProductButton';
import ProductToggleCell from './ProductToggleCell/ProductToggleCell';

export type RowData = ProductDoc & { conversionItem?: StorePurchaseConversionItem; supplierName?: string };

////////////////////////////////////////////////////////////////////////////////////
// 상품 편집 기능

const productValueSetter = (params: any) => {
  const field = params.colDef.field;
  if (!field) return false;
  try {
    // 1. sanitize
    const value = productValueSanitizer(field, params.newValue);
    // 2. validate
    productValueValidator(field, value);
    // 3. change cell value
    params.data[field] = value;
    return true;
  } catch (error) {
    console.error(error);
    const msg = errorObjectToString(error);
    message.error(msg);
    return false;
  }
};

const productOnCellValueChanged = async (params: NewValueParams<RowData>) => {
  const field = params.colDef.field;
  if (params.oldValue === params.newValue || !field) return;

  try {
    const value = (params.data as any)[field];
    // 4. update db
    const data = preprocessProductData(params.data, field, value);
    await updateProduct(params.data._id, data);
    message.success('변경되었습니다');
    // 5. re-focus after data update
    if (params?.node && params?.node?.rowIndex !== null) {
      await sleep(0);
      params.api.setFocusedCell(params?.node?.rowIndex, field);
    }
  } catch (error) {
    console.error(error);
    const msg = errorObjectToString(error);
    message.error(msg);
    // 5. rollback
    if (params.node) {
      (params.data as any)[field] = params.oldValue;
      params.api.refreshCells({ rowNodes: [params.node], force: true });
    }
  }
};
////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////
// ag-grid settings

const defaultColDef: ColDef<RowData> = {
  sortable: true,
  resizable: true,
  filter: true,
  floatingFilter: true,
};

const now = new Date();
const productTable = 'productTable';

const getRowStyle = (params: RowClassParams<RowData>): any => {
  const { costUpdatedAt } = params.node.data ?? {};
  if (costUpdatedAt) {
    const diff = differenceInHours(now, new Date(costUpdatedAt));
    // 변경된지 18시간 이내면 노란색으로 표시한다.
    if (diff < 18) {
      return { backgroundColor: 'var(--yellow200)' };
    }
  }
  return { backgroundColor: 'white' };
};

////////////////////////////////////////////////////////////////////////////////////

interface ProductTableProps {
  rowData: RowData[];
  suppliers: SupplierDoc[];
}

const ProductTable: FC<ProductTableProps> = ({ rowData, suppliers }) => {
  const [gridRef, setGridRef] = useState<AgGridReact<RowData> | null>(null);

  const columnDefs: (ColDef<RowData> | ColGroupDef<RowData>)[] = useMemo(
    () => [
      { headerName: 'productId', field: '_id', minWidth: 200, hide: true },
      {
        headerName: '업데이트',
        field: '_timeUpdate',
        hide: true,
        width: 108,
        valueFormatter: (params: any) => (params.value ? timestampFormatter2(params) : 'New'),
        cellStyle: (params: any) => {
          if (!params.value) {
            return { color: 'var(--blue400)', fontWeight: 'bold' };
          }
        },
      },
      {
        headerName: '매입가 변경',
        field: 'costUpdatedAt',
        sortable: true,
        sort: 'desc',
        width: 140,
        valueGetter: (params: any) =>
          params.data.costUpdatedAt ? formatDate(params.data.costUpdatedAt, 'yyyy-MM-dd') : null,
      },
      { headerName: '코드', field: 'productId', width: 98, filter: 'agTextColumnFilter' },
      { headerName: '이미지', field: 'thumbnail', width: 96, cellRenderer: ProductImg },
      { headerName: '판매금액', field: 'salesAmount', width: 98, valueFormatter: numberFormatter },
      {
        headerName: '품명',
        field: 'fullName',
        filter: 'agTextColumnFilter',
        width: 240,
      },
      {
        headerName: '*품명1',
        field: 'name',
        filter: 'agTextColumnFilter',
        width: 120,
        editable: true,
        valueSetter: productValueSetter,
        onCellValueChanged: productOnCellValueChanged,
      },
      {
        headerName: '*구분',
        field: 'categories',
        width: 108,
        editable: true,
        cellEditorPopup: true,
        cellEditor: 'agRichSelectCellEditor',
        cellRenderer: ProductCategoryCellRenderer,
        cellEditorParams: {
          values: Object.keys(productCategories),
          cellRenderer: ProductCategoryCellRenderer,
        },
        valueSetter: productValueSetter,
        onCellValueChanged: productOnCellValueChanged,
        filterParams: {
          valueFormatter: (params: any) => productCategories[params.value],
        },
      },
      {
        headerName: '*과/면세',
        field: 'taxFree',
        width: 108,
        editable: true,
        cellEditorPopup: true,
        cellEditor: 'agRichSelectCellEditor',
        valueFormatter: ({ value }) => (value ? '면세' : '과세'),
        cellEditorParams: {
          values: [true, false],
        },
        valueSetter: productValueSetter,
        onCellValueChanged: productOnCellValueChanged,
      },
      {
        headerName: '*매입가',
        field: 'cost',
        width: 120,
        editable: true,
        filter: 'agNumberColumnFilter',
        valueSetter: productValueSetter,
        onCellValueChanged: productOnCellValueChanged,
        valueFormatter: ({ value }) => formatNumber(value),
      },
      {
        headerName: '*판매가',
        field: 'price',
        width: 120,
        editable: true,
        filter: 'agNumberColumnFilter',
        valueSetter: productValueSetter,
        onCellValueChanged: productOnCellValueChanged,
        valueFormatter: ({ value }) => formatNumber(value),
      },
      {
        headerName: '마진율',
        width: 110,
        filter: 'agNumberColumnFilter',
        valueGetter: (params) => {
          const { cost, price } = (params.data ?? {}) as RowData;
          if (!cost || !price) return null;
          return ((price - cost) / price) * 100;
        },
        valueFormatter: (params) => {
          const margin = params.value as number;
          if (!margin) return '';
          return `${formatNumber(margin, 2)}%`;
        },
      },
      {
        headerName: '단위가격',
        field: 'unitPrice',
        width: 120,
        valueGetter: (params) => {
          const { unitPrice } = (params.data ?? {}) as RowData;
          if (!unitPrice) return '';
          return unitPrice.price;
        },
        valueFormatter: (params) => {
          const price = params.value as number;
          const { unitPrice } = (params.data ?? {}) as RowData;
          if (!unitPrice) return '';
          return formatNumber(price) + ` / ${unitPrice.amount}${unitPrice.unit}`;
        },
      },
      {
        headerName: '*재고',
        field: 'state',
        width: 120,
        editable: true,
        cellEditorPopup: true,
        cellEditor: 'agRichSelectCellEditor',
        cellRenderer: ProductStateCellRenderer,
        cellEditorParams: {
          values: Object.keys(productStates),
          cellRenderer: ProductStateCellRenderer,
        },
        valueSetter: productValueSetter,
        onCellValueChanged: productOnCellValueChanged,
      },
      {
        field: 'stock',
        headerName: '재고량',
      },
      {
        headerName: '*숨김',
        field: 'hidden',
        width: 96,
        cellRenderer: (params: any) => {
          const data = params.data as RowData;
          const value = params.value ?? false;
          return <ProductToggleCell id={data._id} value={value} field='hidden' />;
        },
      },
      {
        headerName: '*가격알림',
        field: 'dailyNotification',
        width: 108,
        cellRenderer: (params: any) => {
          const data = params.data as RowData;
          const value = params.value ?? false;
          return <ProductToggleCell id={data._id} value={value} field='dailyNotification' />;
        },
      },
      {
        headerName: '*보관',
        field: 'storage',
        width: 96,
        editable: true,
        cellEditorPopup: true,
        cellEditor: 'agRichSelectCellEditor',
        cellRenderer: ProductStoragesCellRenderer,
        cellEditorParams: {
          values: Object.keys(productStorages),
          cellRenderer: ProductStoragesCellRenderer,
        },
        filterParams: {
          valueFormatter: (params: any) => productStorages[params.value],
        },
        valueSetter: productValueSetter,
        onCellValueChanged: productOnCellValueChanged,
      },
      {
        headerName: '*패킹',
        field: 'packing',
        width: 96,
        editable: true,
        cellEditorPopup: true,
        cellEditor: 'agRichSelectCellEditor',
        cellRenderer: ProductPackingCellRenderer,
        cellEditorParams: {
          values: Object.keys(productPackings),
          cellRenderer: ProductPackingCellRenderer,
        },
        filterParams: {
          valueFormatter: (params: any) => productPackings[params.value],
        },
        valueSetter: productValueSetter,
        onCellValueChanged: productOnCellValueChanged,
      },
      {
        headerName: '*단위',
        field: 'measure',
        width: 120,
        editable: true,
        valueSetter: productValueSetter,
        onCellValueChanged: productOnCellValueChanged,
        valueGetter: (params) => {
          const measure = params.data?.measure;
          return Array.isArray(measure) ? measure.map((m) => `${m.amount}${m.unit}`).join('*') : '-';
        },
      },
      {
        headerName: '*브랜드',
        field: 'brand',
        width: 96,
        editable: true,
        filter: 'agTextColumnFilter',
        valueSetter: productValueSetter,
        onCellValueChanged: productOnCellValueChanged,
      },
      {
        headerName: '*특징',
        field: 'features',
        width: 96,
        editable: true,
        valueSetter: productValueSetter,
        onCellValueChanged: productOnCellValueChanged,
        valueFormatter: arrayValueFormatter,
      },
      {
        headerName: '*원산지',
        field: 'origin',
        width: 96,
        editable: true,
        valueSetter: productValueSetter,
        onCellValueChanged: productOnCellValueChanged,
      },
      {
        headerName: '*대표태그',
        field: 'mainTag',
        width: 108,
        editable: true,
        valueSetter: productValueSetter,
        onCellValueChanged: productOnCellValueChanged,
      },
      {
        headerName: '*태그',
        field: 'tags',
        width: 96,
        editable: true,
        valueSetter: productValueSetter,
        onCellValueChanged: productOnCellValueChanged,
        valueFormatter: arrayValueFormatter,
      },
      {
        headerName: '*배지',
        field: 'badge',
        width: 96,
        editable: true,
        filter: 'agTextColumnFilter',
        valueSetter: productValueSetter,
        onCellValueChanged: productOnCellValueChanged,
        valueFormatter: arrayValueFormatter,
      },
      {
        headerName: '*이미지설명',
        field: 'imageDescription',
        width: 116,
        editable: true,
        filter: 'agTextColumnFilter',
        valueSetter: productValueSetter,
        onCellValueChanged: productOnCellValueChanged,
      },
      {
        headerName: '*패킹정보',
        field: 'packingInfo',
        width: 116,
        editable: true,
        filter: 'agTextColumnFilter',
        valueSetter: productValueSetter,
        onCellValueChanged: productOnCellValueChanged,
      },
      // 발주 관리 정보
      {
        field: 'suppliers',
        headerName: '*매입처',
        headerClass: 'bgBlue200',
        width: 100,
        editable: true,
        cellEditorPopup: true,
        cellEditor: (params: any) => {
          const oldSuppliers = params.value;
          const product = params.data;
          return (
            <SelectCellEditor
              placeholder='매입처 선택'
              defaultValue={params.value?.[0]}
              onValueChange={(value) =>
                onSelectValueChangeForProductSupplier(value, product, params.context.suppliers, oldSuppliers)
              }
              stopEditing={params.stopEditing as () => void}
              options={(params.context.suppliers as SupplierDoc[])?.map((supplier) => {
                return { label: `${supplier.label}-${supplier.name}`, value: supplier._id };
              })}
            />
          );
        },
        filter: 'agSetColumnFilter',
        filterParams: {
          valueFormatter: (params: any) => {
            const suppliers: SupplierDoc[] = params.context.suppliers;
            const supplier = suppliers.find((s) => s._id === params.value);
            return supplier ? `${supplier.label}-${supplier.name}` : 'unknown';
          },
        },
        suppressKeyboardEvent: suppressEnterKeyEvent,
        cellRenderer: (params: any) => <span>{params.data.supplierName}</span>,
      },
      {
        field: 'supplierName',
        headerName: '*매입처명',
        headerClass: 'bgBlue200',
      },
      {
        headerName: '*발주품명',
        headerClass: 'bgBlue200',
        field: 'conversionItem.purchaseProductName',
        width: 160,
        editable: true,
        filter: 'agTextColumnFilter',
        onCellValueChanged: onCellValueChangedForProductSupplierConversionItem,
      },
      {
        headerName: '*묶음방식',
        headerClass: 'bgBlue200',
        field: 'conversionItem.purchaseBundle',
        width: 160,
        valueFormatter: ({ value }) => {
          return purchaseBundleToKr[value as keyof typeof purchaseBundleToKr] ?? '';
        },
        editable: true,
        cellEditorPopup: true,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: {
          values: Object.keys(purchaseBundleToKr),
          cellRenderer: ({ value }: any) => <span>{purchaseBundleToKr[value] ?? ''}</span>,
        },
        filterParams: {
          valueFormatter: (params: any) => purchaseBundleToKr[params.value as keyof typeof purchaseBundleToKr] ?? '',
        },
        cellRenderer: ({ value }: any) => <span>{purchaseBundleToKr[value] ?? ''}</span>,
        onCellValueChanged: onCellValueChangedForProductSupplierConversionItem,
      },
      {
        headerName: '*최대묶음',
        headerClass: 'bgBlue200',
        field: 'conversionItem.purchaseMaxBundle',
        width: 120,
        editable: true,
        filter: 'agNumberColumnFilter',
        onCellValueChanged: onCellValueChangedForProductSupplierConversionItem,
      },
      {
        headerName: '*단위 변환 값',
        headerClass: 'bgBlue200',
        field: 'conversionItem.purchaseMultiplyUnit',
        width: 130,
        editable: true,
        filter: 'agNumberColumnFilter',
        onCellValueChanged: onCellValueChangedForProductSupplierConversionItem,
      },
      {
        headerName: '*단위',
        headerClass: 'bgBlue200',
        field: 'conversionItem.purchaseUnit',
        width: 100,
        editable: true,
        onCellValueChanged: onCellValueChangedForProductSupplierConversionItem,
      },
      {
        headerName: '액션',
        hide: true,
        width: 120,
        cellRenderer: DownloadRawDataCell,
      },
    ],
    []
  );

  const hideColumnsForPurchaseSetting = useCallback(() => {
    gridRef?.columnApi?.setColumnsVisible(
      [
        'name',
        'dailyNotification',
        'imageDescription',
        'thumbnail',
        'memo',
        'imageUrl',
        'categories',
        'taxFree',
        'stuck',
        'hidden',
        'brand',
        'packing',
        'storage',
        'origin',
        'features',
        'mainTag',
        'tags',
        'cost',
        'badge',
      ],
      false
    );
  }, [gridRef?.columnApi]);

  const autoSizeAll = useCallback(() => {
    gridRef?.columnApi?.autoSizeAllColumns(true);
    loadGridState(gridRef, productTable);
  }, [gridRef]);

  return (
    <div className={`ag-theme-alpine ${classes.tableContainer}`}>
      <div className='tableButtons'>
        <Button onClick={() => saveGridState(gridRef, productTable)}>설정 저장</Button>
        <Button onClick={() => loadGridState(gridRef, productTable)}>설정 불러오기</Button>
        <Button onClick={() => resetGridState(gridRef, productTable)}>설정 초기화</Button>
      </div>
      <div className='gridHeader' style={{ display: 'flex', justifyContent: 'space-between' }}>
        <CreateProductButton />
        <div style={{ flex: 1 }} />
        <Button onClick={hideColumnsForPurchaseSetting} style={{ marginRight: 8 }}>
          컬럼 간소화
        </Button>
      </div>
      <AgGridReact
        ref={setGridRef}
        rowData={rowData}
        // rowId를 지정해주지 않으면 데이터 변경시 refresh가 발생한다.
        getRowId={(params) => params.data._id}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        gridOptions={{ rowHeight: 63 }}
        onGridReady={autoSizeAll}
        context={{
          suppliers,
        }}
        // 편집 완료후 스크롤 이동을 막는다.
        suppressScrollOnNewData={true}
        alwaysShowHorizontalScroll={true}
        animateRows={true}
        getRowStyle={getRowStyle}
      />
    </div>
  );
};

export default ProductTable;
