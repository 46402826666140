import {
  InvoiceDoc,
  PaymentItemDoc,
  PaymentStatusCode,
  SettlementResultDoc,
  paymentStatusCodeKrMap,
} from '@gooduncles/gu-app-schema';
import { ColDef, ColGroupDef } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { FC, useMemo } from 'react';
import { cellStyleForPaymentMethod } from 'src/utils/payment-util';

import { formatDate } from 'src/lib/1/date-util';
import { getCellClassForPaymentStatus, numberFormatter } from 'src/lib/6/ag-grid-util';

const dateFormatter = ({ value }: { value: string | null }) => (value ? formatDate(value, 'yyyy-MM-dd HH:mm:ss') : '');

export type SettledInvoiceTableRowData = InvoiceDoc & {
  storeNickname: string | null;
  paymentItems: PaymentItemDoc[];
  settlementResult: SettlementResultDoc | null;
};

const defaultColDef = {
  sortable: true,
  resizable: true,
  filter: true,
};

interface SettledInvoiceTableProps {
  rowData: SettledInvoiceTableRowData[];
  setSelectedRows: React.Dispatch<React.SetStateAction<SettledInvoiceTableRowData[]>>;
}

const SettledInvoiceTable: FC<SettledInvoiceTableProps> = ({ rowData, setSelectedRows }) => {
  const columnDefs: (ColDef<SettledInvoiceTableRowData> | ColGroupDef<SettledInvoiceTableRowData>)[] = useMemo(
    () => [
      { headerName: 'id', field: '_id', hide: true },
      { headerName: '정산시각', field: 'settledAt', valueFormatter: dateFormatter },
      {
        headerName: '매장명',
        field: 'storeNickname',
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
      },
      { headerName: '매장Id', field: 'storeId', hide: true },
      { headerName: '매장코드', field: 'storeCode', hide: true },
      {
        headerName: '청구금액',
        field: 'settlementResult.invoiceAmount',
        width: 120,
        type: 'numericColumn',
        valueFormatter: numberFormatter,
      },
      {
        headerName: '정산금액',
        cellRenderer: 'agGroupCellRenderer',
        valueFormatter: numberFormatter,
        valueGetter: (params: any) => {
          const { settlementResult } = params.data as SettledInvoiceTableRowData;
          if (!settlementResult) {
            return 0;
          }
          const { paidSupplyPrice, paidTax } = settlementResult;
          return paidSupplyPrice + paidTax;
        },
        width: 140,
        type: 'numericColumn',
      },
      {
        headerName: '미납금',
        field: 'settlementResult.unpaidAmount',
        width: 120,
        type: 'numericColumn',
        valueFormatter: numberFormatter,
        cellStyle: (params: any) => {
          const value = params.value as number;
          if (value > 0) {
            return {
              backgroundColor: 'var(--red200)',
            };
          }
        },
      },
      {
        headerName: '정산(공급가)',
        field: 'settlementResult.paidSupplyPrice',
        width: 120,
        type: 'numericColumn',
        valueFormatter: numberFormatter,
      },
      {
        headerName: '정산(부가세)',
        field: 'settlementResult.paidTax',
        width: 120,
        type: 'numericColumn',
        valueFormatter: numberFormatter,
      },
    ],
    []
  );

  /**
   * 정산금액의 상세 내역을 보여주기 위한 렌더러 설정
   */
  const detailCellRendererParams = useMemo(() => {
    const detailColumnDefs: (ColDef<PaymentItemDoc> | ColGroupDef<PaymentItemDoc>)[] = [
      {
        field: 'transactionDate',
        headerName: '결제일시',
        valueFormatter: dateFormatter,
      },
      {
        field: 'payerInfo',
        headerName: '결제자',
      },
      {
        field: 'paymentStatus',
        headerName: '결제상태',
        width: 100,
        valueFormatter: ({ value }) => paymentStatusCodeKrMap[value as unknown as PaymentStatusCode],
        cellClass: getCellClassForPaymentStatus,
        filterParams: {
          valueFormatter: (params: any) => paymentStatusCodeKrMap[params.value as unknown as PaymentStatusCode],
        },
      },
      {
        field: 'paymentMethod',
        headerName: '결제수단',
        cellStyle: cellStyleForPaymentMethod,
        width: 100,
      },
      {
        field: 'paymentVendor',
        headerName: '출처',
        width: 100,
      },
      {
        field: 'amount',
        headerName: '결제금액',
        width: 120,
        valueFormatter: numberFormatter,
      },
      {
        field: 'supplyPrice',
        headerName: '공급가액',
        width: 120,
        valueFormatter: numberFormatter,
      },
      {
        field: 'tax',
        headerName: '부가세',
        width: 120,
        valueFormatter: numberFormatter,
      },
      {
        field: 'extraInfo',
        headerName: '기타정보',
      },
      {
        field: 'settledAt',
        headerName: '정산시각',
        valueFormatter: dateFormatter,
      },
      {
        field: 'settlementResultId',
        headerName: '정산결과',
        width: 100,
        valueFormatter: ({ value }) => (value ? '정산' : '미정산'),
      },
      {
        field: 'createdBy',
        headerName: '생성자',
      },
    ];

    return {
      detailGridOptions: {
        columnDefs: detailColumnDefs,
        defaultColDef,
      },
      getDetailRowData: (params: any) => {
        const paymentItems = params.data.paymentItems as PaymentItemDoc[];
        params.successCallback(paymentItems);
      },
    };
  }, []);

  const isRowMaster = (data: SettledInvoiceTableRowData) => data.paymentItems.length > 0;

  return (
    <div className='ag-theme-alpine height100 width100'>
      <AgGridReact
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        // detailRow
        isRowMaster={isRowMaster}
        keepDetailRows={true}
        masterDetail={true}
        detailRowAutoHeight={true}
        detailCellRendererParams={detailCellRendererParams}
        // 행 선택을 허용한다.
        rowSelection='multiple'
        onSelectionChanged={(e) => setSelectedRows(e.api.getSelectedRows())}
        // 행 선택을 켜놓더라도 개별 셀의 내용을 복사할 수 있도록한다.
        enableCellTextSelection={true}
      />
    </div>
  );
};

export default SettledInvoiceTable;
