import { Competitor, CompetitorProductMappingDoc, ProductDoc, ProductWeeklyPriceDoc } from '@gooduncles/gu-app-schema';
import { useEffect, useMemo, useState } from 'react';
import { MatchedCompetitorProduct } from 'src/utils/product-trend-util';

import { getPrevWeeksFromNow, getWeekStartedMonday } from 'src/lib/1/date-util';
import {
  getCJFreshwayProduct,
  getDabomFoodProduct,
  getDawnMarketProduct,
  getFoodpangProduct,
  getProductById,
  getSehyeonFnBProduct,
  observeCompetitorProductMapping,
  observeProductWeeklyPrice,
} from 'src/lib/4/firebase-short-cut';

/**
 * 현재 주간과 3주전까지의 주간 날짜를 구성한다.
 */
const getWeekList = () => {
  const now = new Date();
  const year = now.getFullYear();
  const currentWeek = getWeekStartedMonday(now);
  return getPrevWeeksFromNow(year, currentWeek, 3);
};

const getMappingsForCompetitor = (mappings: CompetitorProductMappingDoc[], competitor: Competitor) =>
  mappings
    .filter((mapping) => mapping.competitor === competitor)
    .map((mapping) => [mapping.guProductId, mapping.productId]);

const getArrayKeyForDeps = (mappings: CompetitorProductMappingDoc[], competitor: Competitor) =>
  mappings
    .filter((mapping) => mapping.competitor === competitor)
    .map((mapping) => mapping.productId)
    .join(',');

const getCompetitorProductsForWeek = async (mappings: string[][], competitor: Competitor) => {
  const weekList = getWeekList();
  const competitorProducts: MatchedCompetitorProduct[] = [];
  for (const { year, week } of weekList) {
    const yearWeek = `${year}-${week.toString().padStart(2, '0')}`;
    if (competitor === '다봄푸드') {
      // 다봄푸드 상품 가격 정보를 가져온다.
      const promises = mappings.map(async ([guProductId, productId]) => {
        const dabomFoodProduct = await getDabomFoodProduct(`${yearWeek}-${productId}`);

        return {
          guProductId,
          yearWeek,
          competitor,
          competitorProductName: dabomFoodProduct?.name ?? (dabomFoodProduct as any)?.goods_name ?? null,
          price: dabomFoodProduct?.price?.salePrice ?? (dabomFoodProduct as any)?.goods_price ?? null,
        };
      });
      const results = await Promise.all(promises);
      competitorProducts.push(...results);
    }

    if (competitor === 'CJ프레시웨이') {
      // CJ프레시웨이 상품 가격 정보를 가져온다.
      const promises = mappings.map(async ([guProductId, productId]) => {
        const CJFreshwayProduct = await getCJFreshwayProduct(`${yearWeek}-${productId}`);
        return {
          guProductId,
          yearWeek,
          competitor,
          competitorProductName: CJFreshwayProduct?.name ?? (CJFreshwayProduct as any)?.goods_name ?? null,
          price: CJFreshwayProduct?.price?.salePrice ?? (CJFreshwayProduct as any)?.goods_price ?? null,
        };
      });
      const results = await Promise.all(promises);
      competitorProducts.push(...results);
    }

    if (competitor === '세현F&B') {
      // 세현F&B 상품 가격 정보를 가져온다.
      const promises = mappings.map(async ([guProductId, productId]) => {
        const sehyeonFnBProduct = await getSehyeonFnBProduct(`${yearWeek}-${productId}`);
        return {
          guProductId,
          yearWeek,
          competitor,
          competitorProductName: sehyeonFnBProduct?.name ?? (sehyeonFnBProduct as any)?.goods_name ?? null,
          price: sehyeonFnBProduct?.price?.salePrice ?? (sehyeonFnBProduct as any)?.goods_price ?? null,
        };
      });
      const results = await Promise.all(promises);
      competitorProducts.push(...results);
    }

    if (competitor === '푸드팡') {
      // 푸드팡 상품 가격 정보를 가져온다.
      const promises = mappings.map(async ([guProductId, productId]) => {
        const foodpangProduct = await getFoodpangProduct(`${yearWeek}-${productId}`);
        return {
          guProductId,
          yearWeek,
          competitor,
          competitorProductName: foodpangProduct?.name ?? null,
          // foodpangProduct?.price도 있으나 우선 sale_price를 사용한다.
          price: foodpangProduct?.sale_price ?? null,
        };
      });
      const results = await Promise.all(promises);
      competitorProducts.push(...results);
    }

    if (competitor === '새벽시장') {
      // 새벽시장 상품 가격 정보를 가져온다.
      const promises = mappings.map(async ([guProductId, productId]) => {
        const dawnMarketProduct = await getDawnMarketProduct(`${yearWeek}-${productId}`);
        return {
          guProductId,
          yearWeek,
          competitor,
          competitorProductName: dawnMarketProduct?.goodsDispNm ?? null,
          // dawnMarketProduct?.slePrice 있으나 우선 dcPrice 사용한다.
          price: dawnMarketProduct?.dcPrice ?? null,
        };
      });
      const results = await Promise.all(promises);
      competitorProducts.push(...results);
    }
  }

  return competitorProducts;
};

const useCompetitorTrend = () => {
  const weekList = useMemo(() => getWeekList(), []);
  const [competitorProductMappings, setCompetitorProductMappings] = useState<CompetitorProductMappingDoc[]>([]);
  const [productWeeklyPriceList, setProductWeeklyPriceList] = useState<(ProductWeeklyPriceDoc | null)[]>([]);
  const productIds = useMemo(
    () => competitorProductMappings.map((mapping) => mapping.guProductId),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [competitorProductMappings.map((mapping) => mapping.guProductId).join(',')]
  );
  const dabomFoodProductMappings = useMemo(
    () => getMappingsForCompetitor(competitorProductMappings, '다봄푸드'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getArrayKeyForDeps(competitorProductMappings, '다봄푸드')]
  );
  const CJFreshwayProductMappings = useMemo(
    () => getMappingsForCompetitor(competitorProductMappings, 'CJ프레시웨이'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getArrayKeyForDeps(competitorProductMappings, 'CJ프레시웨이')]
  );
  const SehyeonFnBProductMappings = useMemo(
    () => getMappingsForCompetitor(competitorProductMappings, '세현F&B'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getArrayKeyForDeps(competitorProductMappings, '세현F&B')]
  );
  const foodpangProductMappings = useMemo(
    () => getMappingsForCompetitor(competitorProductMappings, '푸드팡'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getArrayKeyForDeps(competitorProductMappings, '푸드팡')]
  );
  const dawnMarketProductMappings = useMemo(
    () => getMappingsForCompetitor(competitorProductMappings, '새벽시장'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getArrayKeyForDeps(competitorProductMappings, '새벽시장')]
  );

  const [productMap, setProductMap] = useState<Record<string, ProductDoc | null>>({});
  const [matchedDabomFoodProducts, setMatchedDabomFoodProducts] = useState<MatchedCompetitorProduct[]>([]);
  const [matchedCJFreshwayProducts, setMatchedCJFreshwayProducts] = useState<MatchedCompetitorProduct[]>([]);
  const [matchedSehyeonFnBProducts, setMatchedSehyeonFnBProducts] = useState<MatchedCompetitorProduct[]>([]);
  const [matchedFoodpangProducts, setMatchedFoodpangProducts] = useState<MatchedCompetitorProduct[]>([]);
  const [matchedDawnMarketProducts, setMatchedDawnMarketProducts] = useState<MatchedCompetitorProduct[]>([]);

  // 1. 경쟁사 상품 맵핑 정보를 가져온다.
  useEffect(() => {
    const competitorProductMappingObservable =
      observeCompetitorProductMapping().subscribe(setCompetitorProductMappings);
    return () => {
      competitorProductMappingObservable.unsubscribe();
    };
  }, []);

  // 2. 경쟁사 상품 맵핑에 필요한 자사의 상품 데이터를 가져온다.
  useEffect(() => {
    if (productIds.length === 0) {
      setProductMap({});
    } else {
      // console.log('>>>>>> productIds changed!!');
      const promises = productIds.map(async (productId) => {
        const product = await getProductById(productId);
        return [productId, product ?? null];
      });
      Promise.all(promises).then((results) => {
        const productMap: Record<string, ProductDoc | null> = Object.fromEntries(results);
        setProductMap(productMap);
      });
    }
  }, [productIds]);

  // 3. 경쟁사 상품 가격 정보를 가져온다.
  // 1) 다봄푸드
  useEffect(() => {
    if (dabomFoodProductMappings.length === 0) {
      setMatchedDabomFoodProducts([]);
    } else {
      getCompetitorProductsForWeek(dabomFoodProductMappings, '다봄푸드').then(setMatchedDabomFoodProducts);
    }
  }, [dabomFoodProductMappings]);

  // 2) CJ프레시웨이
  useEffect(() => {
    if (CJFreshwayProductMappings.length === 0) {
      setMatchedCJFreshwayProducts([]);
    } else {
      getCompetitorProductsForWeek(CJFreshwayProductMappings, 'CJ프레시웨이').then(setMatchedCJFreshwayProducts);
    }
  }, [CJFreshwayProductMappings]);

  // 3) 세현F&B
  useEffect(() => {
    if (SehyeonFnBProductMappings.length === 0) {
      setMatchedSehyeonFnBProducts([]);
    } else {
      getCompetitorProductsForWeek(SehyeonFnBProductMappings, '세현F&B').then(setMatchedSehyeonFnBProducts);
    }
  }, [SehyeonFnBProductMappings]);

  // 4) 푸드팡
  useEffect(() => {
    if (foodpangProductMappings.length === 0) {
      setMatchedFoodpangProducts([]);
    } else {
      getCompetitorProductsForWeek(foodpangProductMappings, '푸드팡').then(setMatchedFoodpangProducts);
    }
  }, [foodpangProductMappings]);

  // 5) 새벽시장
  useEffect(() => {
    if (dawnMarketProductMappings.length === 0) {
      setMatchedDawnMarketProducts([]);
    } else {
      getCompetitorProductsForWeek(dawnMarketProductMappings, '새벽시장').then(setMatchedDawnMarketProducts);
    }
  }, [dawnMarketProductMappings]);

  useEffect(() => {
    const subscription = observeProductWeeklyPrice([
      ['yearWeek', 'in', weekList.map(({ year, week }) => `${year}-${week}`)],
    ]).subscribe(setProductWeeklyPriceList);
    return () => {
      subscription.unsubscribe();
    };
  }, [weekList]);

  return {
    weekList,
    productMap,
    productWeeklyPriceList,
    matchedDabomFoodProducts,
    matchedCJFreshwayProducts,
    matchedSehyeonFnBProducts,
    matchedFoodpangProducts,
    matchedDawnMarketProducts,
  };
};

export { useCompetitorTrend };
