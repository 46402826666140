import { combineReducers } from '@reduxjs/toolkit';

import bill from './bill';
import partners from './partners';

const rootReducer = combineReducers({
  bill,
  partners,
});

export default rootReducer;
