import { StoreDoc, UserDoc } from '@gooduncles/gu-app-schema';
import { Button, message } from 'antd';
import { FC, useCallback } from 'react';
import { useAuth } from 'src/stores/auth-context';

import { storeStateList, userRoleList, userStatusKr } from 'src/lib/1/constant';
import { getDataDogLinkForUser, storeCodeRegex } from 'src/lib/1/util';
import { UserRole } from 'src/lib/2/schema';
import { getStore, getStores } from 'src/lib/4/firebase-short-cut';

import ToggleEditor from 'src/components/Common/ToggleEditor/ToggleEditor';

import classes from './UserInfo.module.scss';

type UserInfoProps = {
  userWithStore: UserDoc & {
    store: StoreDoc | null;
  };
  mutateUser: (field: any, value: any) => Promise<void>;
  mutateStore: (field: any, value: any) => Promise<void>;
};

type FlexRowProps = {
  label: string;
  value: string | boolean | undefined;
  field: string;
};

const FlexRow: FC<FlexRowProps> = ({ label, value, field }) => (
  <div className={classes.flexRow}>
    <div className={classes.label}>
      {label}
      {field && <span className={classes.subLabel}>({field})</span>}
    </div>
    <p className={classes.value}>{value ?? '-'}</p>
  </div>
);

/**
 * @param targetRole 현재 대상의 역할
 * @param managerRole 변경하려는 관리자의 역할
 * @returns
 */
const roleValidator = (targetRole: string, managerRole: UserRole) => {
  if (['admin', 'manager'].includes(managerRole)) {
    // 관리자 끼리는 변경할 수 있으나 manager는 관리자의 역할을 변경할 수 없다.
    if (managerRole === 'manager' && targetRole === 'admin') {
      return false;
    }
    // 그 외의 역할은 admin 또는 manager만 변경할 수 있다.
    return true;
  }
  return false;
};

const UserInfo: FC<UserInfoProps> = ({ userWithStore, mutateUser, mutateStore }) => {
  const { user } = useAuth();
  const isAuthorized = roleValidator(userWithStore.role, user?.role ?? 'unknown');

  const validateStoreCode = useCallback(
    async (value: string) => {
      try {
        const codeDuplicatedStores = await getStores([['storeCode', '==', value]]);
        if (codeDuplicatedStores.length > 0) {
          message.error('이미 사용중인 매장코드입니다.');
          return false;
        }
        if (userWithStore.store?.storeCode && userWithStore.store.storeCode.match(storeCodeRegex) !== null) {
          message.error('규칙에 맞게 입력된 매장코드는 수정할 수 없습니다.');
          return false;
        }
        return true;
      } catch (error) {
        console.error(error);
        message.error('매장코드 중복검사에 실패했습니다.');
        return false;
      }
    },
    [userWithStore.store?.storeCode]
  );

  const validateStoreId = useCallback(async (value: string) => {
    try {
      const isStoreExist = await getStore(value);
      if (!isStoreExist) {
        message.error('존재하지 않는 매장 id입니다.');
        return false;
      }
      return true;
    } catch (error) {
      console.error(error);
      message.error('매장 id 검증에 실패했습니다.');
      return false;
    }
  }, []);

  return (
    <div className={classes.userInfoContainer}>
      {/* UserInfo */}
      <div className={classes.flexColumn}>
        <h2>
          사용자 정보
          <Button
            type='link'
            onClick={() => (userWithStore.email ? getDataDogLinkForUser(userWithStore.email) : undefined)}>
            Datadog Link
          </Button>
        </h2>
        <FlexRow label='식별자' value={userWithStore._id} field='_id' />
        <FlexRow label='이메일' value={userWithStore.email} field='email' />
        <ToggleEditor
          label='계정상태'
          value={userWithStore.status}
          type='select'
          field='status'
          isAuthorized={isAuthorized}
          handleOnEdit={mutateUser}
          options={Object.entries(userStatusKr).map(([value, label]) => ({ value, label }))}
        />
        <ToggleEditor
          label='역할'
          value={userWithStore.role}
          type='select'
          field='role'
          isAuthorized={isAuthorized}
          handleOnEdit={mutateUser}
          options={userRoleList.map((role: string) => ({ label: role, value: role }))}
        />
        <ToggleEditor
          label='전화번호'
          value={userWithStore.userTel}
          field='userTel'
          type='string'
          handleOnEdit={mutateUser}
          isAuthorized={isAuthorized}
        />
        <ToggleEditor
          label='매장 id'
          value={userWithStore.storeId}
          field='storeId'
          type='string'
          validation={validateStoreId}
          handleOnEdit={mutateUser}
          isAuthorized={isAuthorized}
        />
        <ToggleEditor
          label='즐겨찾기'
          value={userWithStore.favoriteProducts?.join(', ') ?? ''}
          field='favoriteProducts'
          type='string'
          handleOnEdit={mutateUser}
          isAuthorized={isAuthorized}
        />

        <ToggleEditor
          label='주문 마감시간 알림'
          value={(!!userWithStore.deadlineNotification).toString()}
          field='deadlineNotification'
          type='boolean'
          handleOnEdit={mutateUser}
          isAuthorized={isAuthorized}
        />
        <ToggleEditor
          label='푸시 알림'
          value={(!!userWithStore.allowNotification).toString()}
          field='allowNotification'
          type='boolean'
          handleOnEdit={mutateUser}
          isAuthorized={isAuthorized}
        />
        <ToggleEditor
          label='일일 카톡 알림'
          value={(!!userWithStore.kakaoNotification).toString()}
          field='kakaoNotification'
          type='boolean'
          handleOnEdit={mutateUser}
          isAuthorized={isAuthorized}
        />
        <ToggleEditor
          label='새벽 알림 금지'
          value={(!!userWithStore.disableNotificationAtDawn).toString()}
          field='disableNotificationAtDawn'
          type='boolean'
          handleOnEdit={mutateUser}
          isAuthorized={isAuthorized}
        />

        <FlexRow label='기기 고유 번호 *앱' value={userWithStore.deviceTokenId} field='deviceTokenId' />
        <FlexRow label='푸시 알림 고유 번호 *앱' value={userWithStore.notificationId} field='notificationId' />
        <FlexRow label='마지막 접속 세션' value={userWithStore.sessionId} field='sessionId' />
        <FlexRow label='마지막 접속 ip' value={userWithStore.ip} field='ip' />
        <FlexRow label='브라우저' value={userWithStore.userAgent?.browser?.name} field='userAgent' />
      </div>

      {/* StoreInfo */}
      <div className={classes.flexColumn}>
        <h2>매장 정보</h2>
        {userWithStore.store ? (
          <>
            <FlexRow label='매장 식별자' field='storeId' value={userWithStore.store._id} />
            <ToggleEditor
              label='매장코드'
              value={userWithStore.store.storeCode ?? '-'}
              field='storeCode'
              type='string'
              handleOnEdit={mutateStore}
              validation={validateStoreCode}
              isAuthorized={isAuthorized}
            />
            <ToggleEditor
              label='매장상태'
              value={storeStateList[userWithStore.store.state]}
              field='state'
              type='select'
              options={Object.entries(storeStateList).map(([value, label]) => ({ label, value }))}
              handleOnEdit={mutateStore}
              isAuthorized={isAuthorized}
            />
            <ToggleEditor
              label='매장명 *사용자 등록'
              value={userWithStore.store.storeName}
              field='storeName'
              type='string'
              handleOnEdit={mutateStore}
              isAuthorized={isAuthorized}
            />
            <ToggleEditor
              label='매장명 *관리자 등록'
              value={userWithStore.store.storeNickname}
              field='storeNickname'
              type='string'
              handleOnEdit={mutateStore}
              isAuthorized={isAuthorized}
            />
            <FlexRow label='지번 주소' field='jibunAddress' value={userWithStore.store.jibunAddress} />
            <FlexRow label='도로명 주소' field='roadAddress' value={userWithStore.store.roadAddress} />
            <ToggleEditor
              label='맞춤상품'
              value={userWithStore.store.unhiddenProducts?.join(', ') ?? ''}
              field='unhiddenProducts'
              type='string'
              handleOnEdit={mutateStore}
              isAuthorized={isAuthorized}
            />
            <ToggleEditor
              label='상호명'
              value={userWithStore.store.businessName ?? ''}
              field='businessName'
              type='string'
              handleOnEdit={mutateStore}
              isAuthorized={isAuthorized}
            />
            <ToggleEditor
              label='사업자번호'
              value={userWithStore.store.businessNumber ?? ''}
              field='businessNumber'
              type='string'
              handleOnEdit={mutateStore}
              isAuthorized={isAuthorized}
            />
            <ToggleEditor
              label='사업자주소'
              value={userWithStore.store.businessAddress ?? ''}
              field='businessAddress'
              type='string'
              handleOnEdit={mutateStore}
              isAuthorized={isAuthorized}
            />
            <ToggleEditor
              label='대표'
              value={userWithStore.store.ownerName ?? ''}
              field='ownerName'
              type='string'
              handleOnEdit={mutateStore}
              isAuthorized={isAuthorized}
            />
            <ToggleEditor
              label='대표생년월일'
              value={userWithStore.store.ownerBirthday ?? ''}
              field='ownerBirthday'
              type='string'
              handleOnEdit={mutateStore}
              isAuthorized={isAuthorized}
            />
            <FlexRow label='사업자등록상태' value={userWithStore.store.businessStatus} field='businessStatus' />
            <FlexRow label='과세유형' value={userWithStore.store.taxType} field='taxType' />
            <FlexRow label='폐업일' value={userWithStore.store.endDate ?? undefined} field='endDate' />
          </>
        ) : (
          <div>store 정보가 없습니다.</div>
        )}
      </div>
    </div>
  );
};

export default UserInfo;
