import { OrderDoc, StoreIssueDoc, TaskOrderCollection } from '@gooduncles/gu-app-schema';
import { Popover } from 'antd';
import { formatDate } from 'date-fns';
import { FC } from 'react';
import { TaskSpot } from 'src/utils/partners-util';

import { formatNumber } from 'src/lib/1/util';

import classes from './TaskSpotCard.module.scss';

interface TaskSpotCardProps {
  title: string;
  data: TaskSpot;
  showDetail: boolean;
}

const TaskSpotCard: FC<TaskSpotCardProps> = ({ title, data, showDetail }) => {
  const storeList = data.stores.map((item) => {
    const orderAmount = item.taskBundles
      .filter((bundle) => bundle.orderColleciton === TaskOrderCollection.Order)
      .reduce((acc, cur) => acc + (cur.data as OrderDoc).totalAmount, 0);
    const orderCount = item.taskBundles.filter((bundle) => bundle.orderColleciton === TaskOrderCollection.Order).length;
    const storeIssues = item.taskBundles
      .filter((bundle) => bundle.orderColleciton === TaskOrderCollection.StoreIssue)
      .map((bundle) => bundle.data as StoreIssueDoc);
    const issueCount = storeIssues.length;
    return {
      store: item.store,
      amount: orderAmount,
      orderCount,
      issueCount,
      storeIssues,
    };
  });

  const orderCount = storeList.reduce((acc, cur) => acc + cur.orderCount, 0);
  const issueCount = storeList.reduce((acc, cur) => acc + cur.issueCount, 0);

  const totalAmount = storeList.reduce((acc, cur) => acc + cur.amount, 0);

  return (
    <div className={classes.spotCard}>
      <div className={classes.cardTitle}>
        <h4>
          {title}({storeList.length})
        </h4>
        <span>{formatNumber(totalAmount)}</span>
      </div>
      {showDetail && (
        <>
          {storeList.length > 0 &&
            storeList.map((summary) => (
              <div key={summary.store?._id ?? 'unregistered'} className={classes.summary}>
                <p className={summary.store ? '' : classes.unregistered}>
                  • {summary.store?.storeNickname ?? 'unregistered'}
                  {summary.orderCount ? (
                    <span className={classes.orderAmount}>({formatNumber(summary.amount)})</span>
                  ) : (
                    ''
                  )}
                  {summary.storeIssues.map((issue) => (
                    <Popover
                      key={issue._id}
                      title={formatDate(issue.date, 'yyyy-MM-dd')}
                      content={
                        <div>
                          {issue.memo && <p>{issue.memo}</p>}
                          {issue.message && <p>{issue.message}</p>}
                          {issue.productName && (
                            <p>
                              {issue.productName} {issue.volume && <span>{issue.volume}개</span>}
                            </p>
                          )}
                        </div>
                      }>
                      <span className={`${classes.issue} ${issue.category ? classes[issue.category] : ''}`}>
                        {issue.category}
                      </span>
                    </Popover>
                  ))}
                </p>
              </div>
            ))}
          <div className={classes.amount}>
            <span>
              {orderCount} {issueCount > 0 && <span className={classes.issueCount}>({issueCount})</span>}
            </span>
            <span className={classes.totalAmount}>{formatNumber(totalAmount)}</span>
          </div>
        </>
      )}
    </div>
  );
};

export default TaskSpotCard;
