import { CheckCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { DeliveryTaskDoc, PickupTaskDoc } from '@gooduncles/gu-app-schema';
import { FC } from 'react';

import SubTitle from 'src/atomic-components/atoms/Content/SubTitle';
import DeliveryTaskLocationRow from 'src/atomic-components/atoms/TaskLocationRow/DeliveryTaskLocationRow';
import PickupTaskLocationRow from 'src/atomic-components/atoms/TaskLocationRow/PickupTaskLocationRow';

import classes from './TaskInfoBox.module.scss';

import NaverMapBox from '../NaverMapBox/NaverMapBox';

interface TaskInfoBoxProps {
  type: 'pickup' | 'delivery';
  task: PickupTaskDoc | DeliveryTaskDoc | undefined;
}

const TaskInfoBox: FC<TaskInfoBoxProps> = ({ type, task }) => {
  if (!task) {
    return <div>-</div>;
  }
  const taskStatusText = task.finishedAt
    ? task.cancelReason
      ? `취소(${task.cancelReason})`
      : `완료(${task.finishedAt})`
    : '진행중';
  const taskStatus = task.finishedAt ? (task.cancelReason ? 'canceled' : 'finished') : 'pending';
  const taskStatusIcon = task.finishedAt ? task.cancelReason ? <WarningOutlined /> : <CheckCircleOutlined /> : null;

  return (
    <div className={classes.taskInfoBox}>
      <SubTitle
        title='업무 상태'
        rightSection={
          <p className={`${classes.taskStatus} ${classes[taskStatus]}`}>
            {taskStatusIcon} {taskStatusText}
          </p>
        }
      />
      {type === 'pickup' && task && <PickupTaskLocationRow pickupTask={task as PickupTaskDoc} />}
      {type === 'delivery' && task && (
        <>
          {(task as DeliveryTaskDoc).taskIssue ? (
            <SubTitle title='이슈' rightSection={(task as DeliveryTaskDoc).taskIssue as string} />
          ) : null}
          <DeliveryTaskLocationRow deliveryTask={task as DeliveryTaskDoc} />
          {task.finishedCoords && (
            <NaverMapBox
              title='업무 완료 지점'
              lat={task.finishedCoords?.latitude}
              lng={task.finishedCoords?.longitude}
            />
          )}
        </>
      )}
    </div>
  );
};

export default TaskInfoBox;
