import { Tabs } from 'antd';
import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useTitle } from 'src/hooks/useTitle';

import SettledInvociePage from './SettledInvoicePage';
import SettledPaymentItemPage from './SettledPaymentItemPage';
import SettlementResultPage from './SettlementResultPage';

const tabItems = [
  {
    key: 'settlement',
    title: '정산결과',
    description: '지난 정산결과를 확인합니다.',
    component: <SettlementResultPage />,
  },
  {
    key: 'invoice',
    title: '청구서',
    description: '지난 청구서를 확인합니다.',
    component: <SettledInvociePage />,
  },
  {
    key: 'payment-item',
    title: '결제 내역',
    description: '지난 결제 내역을 확인합니다.',
    component: <SettledPaymentItemPage />,
  },
];

const SettlementHistoryPage: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab') || tabItems[0].key;
  useTitle(`GU 관리자 | 지난 내역 - ${tabItems.find((item) => item.key === tab)?.title}`);
  const onTabChange = (key: string) => {
    setSearchParams({ tab: key });
  };

  return (
    <div className='flexColumn height100'>
      <Tabs
        type='card'
        activeKey={tab}
        style={{ height: '100%' }}
        tabBarStyle={{ marginBottom: 0 }}
        onChange={onTabChange}
        destroyInactiveTabPane
        items={tabItems.map((item) => ({
          key: item.key,
          label: item.title,
          children: item.component,
        }))}
      />
    </div>
  );
};

export default SettlementHistoryPage;
