import { DeliveryPerformanceDoc } from '@gooduncles/gu-app-schema';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { ColDef, ColGroupDef } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { isNumber, sum } from 'lodash-es';
import { FC, useMemo, useState } from 'react';

import { formatDate } from 'src/lib/1/date-util';
import { formatNumber } from 'src/lib/1/util';

interface DeliveryPerformanceTableProps {
  rowData: DeliveryPerformanceDoc[];
}

const defaultColDef: ColDef<DeliveryPerformanceDoc> = {
  sortable: true,
  resizable: true,
  filter: true,
};

const DeliveryPerformanceTable: FC<DeliveryPerformanceTableProps> = ({ rowData }) => {
  const [, setGridRef] = useState<AgGridReact<DeliveryPerformanceDoc> | null>(null);

  const columnDefs: (ColDef<DeliveryPerformanceDoc> | ColGroupDef<DeliveryPerformanceDoc>)[] = useMemo(
    () => [
      {
        field: 'partner.nickname',
        headerName: '담당자',
        width: 160,
        valueGetter: (params: any) => {
          const data = params.data as DeliveryPerformanceDoc;
          if (data.courier?.name) {
            return data.courier.name;
          }
          return data.partner?.nickname ?? '알 수 없음';
        },
        rowGroup: true,
        hide: true,
      },
      {
        field: 'date',
        headerName: '날짜',
        valueGetter: (params: any) => {
          if (params.node.group) {
            return params.node.allLeafChildren
              .sort((a: any, b: any) => String(a.data.date).localeCompare(String(b.data.date)))
              .map((leaf: any) => formatDate(new Date(leaf.data.date), 'M.d'))
              .join(', ');
          }
          return params.data.date;
        },
      },
      {
        field: 'courierId',
        hide: true,
      },
      {
        field: 'partnerId',
        hide: true,
      },
      {
        field: 'deliverySpotCount',
        headerName: '스팟 수(평균)',
        width: 140,
        valueGetter: (params: any) => {
          if (params.node.group) {
            const countSum = sum(
              params.node.allLeafChildren.map((leaf: any) => {
                const leafData = leaf.data as DeliveryPerformanceDoc;
                return leafData.deliverySpotCount;
              })
            );
            const countAvg = formatNumber(countSum / params.node.allLeafChildren.length, 1);
            return `${formatNumber(countSum)} (${countAvg})`;
          }
          return params.data.deliverySpotCount;
        },
        valueFormatter: (params) => (isNumber(params.value) ? formatNumber(params.value, 0) : params.value),
      },
      {
        field: 'deliveryStoreCount',
        headerName: '매장 수(평균)',
        width: 140,
        valueGetter: (params: any) => {
          if (params.node.group) {
            const countSum = sum(
              params.node.allLeafChildren.map((leaf: any) => {
                const leafData = leaf.data as DeliveryPerformanceDoc;
                return leafData.deliveryStoreCount;
              })
            );
            const countAvg = formatNumber(countSum / params.node.allLeafChildren.length, 1);
            return `${formatNumber(countSum)} (${countAvg})`;
          }
          return params.data.deliveryStoreCount;
        },
        valueFormatter: (params) => (isNumber(params.value) ? formatNumber(params.value, 0) : params.value),
      },
      {
        field: 'totalDeliveryOrderAmount',
        headerName: '배송 주문 금액(평균)',
        valueGetter: (params: any) => {
          if (params.node.group) {
            const countSum = sum(
              params.node.allLeafChildren.map((leaf: any) => {
                const leafData = leaf.data as DeliveryPerformanceDoc;
                return leafData.totalDeliveryOrderAmount;
              })
            );
            const countAvg = formatNumber(countSum / params.node.allLeafChildren.length, 0);
            return `${formatNumber(countSum)} (${countAvg})`;
          }
          return params.data.totalDeliveryOrderAmount;
        },
        valueFormatter: (params) => (isNumber(params.value) ? formatNumber(params.value, 0) : params.value),
      },
      {
        field: 'averageDeliveryOrderAmount',
        headerName: '배송 객단가(평균)',
        valueGetter: (params: any) => {
          if (params.node.group) {
            const countSum = sum(
              params.node.allLeafChildren.map((leaf: any) => {
                const leafData = leaf.data as DeliveryPerformanceDoc;
                if (leafData.averageDeliverySpot) {
                  return leafData.averageDeliverySpot;
                }
                return leafData.averageDeliveryOrderAmount;
              })
            );
            const countAvg = formatNumber(countSum / params.node.allLeafChildren.length, 0);
            return `${formatNumber(countSum, 0)} (${countAvg})`;
          }
          return params.data.averageDeliverySpot;
        },
        valueFormatter: (params) => (isNumber(params.value) ? formatNumber(params.value, 0) : params.value),
      },
    ],
    []
  );

  return (
    <div className='ag-theme-alpine height100'>
      <AgGridReact
        ref={setGridRef}
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        autoGroupColumnDef={{
          headerName: '담당자(횟수)',
        }}
      />
    </div>
  );
};

export default DeliveryPerformanceTable;
