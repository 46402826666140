import { groupBy } from 'lodash-es';

import { billDetailAmountMappings } from '../1/constant';
import { dateFormat07, orderDateFormat03 } from '../1/date-util';
import { toNumberOrZero } from '../1/util';
import { readExcelFile } from '../1/xlsx-util';
import { InstallmentBill, InstallmentBillDetail, InstallmentBillPathRaw } from '../3/schema-bill';

/**
 * 엑셀파일에서 읽은 정산 상세 정보를 정제해서 반환합니다.
 */
export const parseRawDetailToBillDetail = (detail: Record<string, string>) => {
  const parseToRows = Object.entries(detail).map(([key, value]) => {
    const [date, amount] = key.split('-');
    const detailAmount = billDetailAmountMappings[amount];
    if (!detailAmount) {
      throw new Error(`읽을 수 없는 단어가 있습니다. 단어: ${amount}`);
    }

    return {
      date: dateFormat07(new Date(date.trim())),
      amount: billDetailAmountMappings[amount],
      value: toNumberOrZero(value),
    };
  });

  const groupedByDate = groupBy(parseToRows, 'date');
  const billDetail = Object.entries(groupedByDate).map(([date, rows]) => {
    const detailRow: Record<string, number> = rows.reduce((obj, row) => {
      obj[row.amount] = row.value;
      return obj;
    }, {} as Record<string, number>);
    return {
      date,
      ...detailRow,
    } as InstallmentBillDetail;
  });

  return billDetail;
};

/**
 * 엑셀파일에서 읽은 정산 정보를 정제해서 반환합니다.
 */
export const rawToBill = (raw: object) => {
  const rawData = Object.fromEntries(
    Object.entries(raw).map(([key, value]) => [key.replace(/\s/g, ''), value.trim()])
  ) as InstallmentBillPathRaw;
  const {
    매장코드,
    식당명,
    정산일,
    정산방식,
    금주주문금액,
    이전미수금,
    금주정산액,
    미정산금액,
    안내문구,
    ...detailRows
  } = rawData;

  const storeCode = 매장코드;
  const settlementDate = orderDateFormat03(new Date(정산일));

  const bill: InstallmentBill = {
    storeCode,
    storeName: 식당명,
    settlementDate,
    settlementType: 정산방식,
    orderAmount: toNumberOrZero(금주주문금액),
    prevUnpaidAmount: toNumberOrZero(이전미수금),
    settlementAmount: toNumberOrZero(금주정산액),
    unpaidAmount: toNumberOrZero(미정산금액),
    message: 안내문구,
    detail: parseRawDetailToBillDetail(detailRows),

    receiverTel: null,
    manualSending: false,
    isDeleted: false,

    alimtalkMessage: null,
  };
  return bill;
};

/**
 * 사용자가 올린 엑셀 파일을 파싱하여 bill로 변환합니다.
 * 변환과정에서 수신자(receiverTel)이 없는 경우 해당 매장을 알려주며, bill에서 제외합니다.
 */
export const parsingFile = async (file: File, receiverList: { [storeCode: string]: string }) => {
  try {
    // 1) excel file to json
    const sheet = await readExcelFile(file);
    // 2) json to bill
    const convertedBills = sheet.map(rawToBill).filter((bill) => bill.settlementType !== '거래없음');
    // 3) find receiver tel
    const billsWithReceiver = convertedBills.map((bill) => {
      const receiverTel = receiverList[bill.storeCode] ?? null;
      return {
        ...bill,
        receiverTel,
      };
    });

    return {
      billsWithReceiver,
      error: billsWithReceiver.length > 0 ? null : '변환된 정산 데이터가 없습니다.',
    };
  } catch (error: any) {
    return {
      billsWithReceiver: [],
      error: '오류가 발생했습니다.\n' + error.message,
    };
  }
};
