import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import CommerceLogPage from './CommerceLogPage';
import ConsoleLogPage from './ConsoleLogPage';
import SearchLogPage from './SearchLogPage';

const LogPage: FC = () => {
  return (
    <Routes>
      <Route path='/commerce' element={<CommerceLogPage title='앱 로그' />} />
      <Route path='/search' element={<SearchLogPage title='검색 로그' />} />
      <Route path='/console' element={<ConsoleLogPage title='어드민 로그' />} />
      <Route path='/' element={<Navigate to='/commerce' />} />
    </Routes>
  );
};

export default LogPage;
