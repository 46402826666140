import { FileDoneOutlined } from '@ant-design/icons';
import { Button, Popconfirm, notification } from 'antd';
import { FC, useEffect, useState } from 'react';
import { combineLatest } from 'rxjs';
import { settleInvoiceWithBatch } from 'src/utils/payment-util';

import { errorObjectToString } from 'src/lib/1/util';
import { observeInvoices, observePaymentItem } from 'src/lib/4/firebase-short-cut';

import useStores from 'src/hooks/useStores';

import PrevUnsettledInvoiceTable, {
  PrevUnsettledInvoiceTableRowData,
} from 'src/components/NewSettlement/InvoiceTable/PrevUnsettledInvoiceTable';

/**
 * 이전 청구서를 정산하는 페이지입니다.
 * 이곳에 있는 모든 청구서는 정산 대상이 됩니다.
 * 만약 잘못 생성된 청구서가 있다면 기존 목록에서 삭제가 필요하며 이곳에서는 진행할 수 없습니다.
 */
const PrevUnsettledInvoicePage: FC = () => {
  const stores = useStores();
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState<PrevUnsettledInvoiceTableRowData[]>([]);
  const [selectedRows, setSelectedRows] = useState<PrevUnsettledInvoiceTableRowData[]>([]);

  /**
   * 정산내역 확정
   */
  const handleSettleInvoices = async (data: PrevUnsettledInvoiceTableRowData[]) => {
    setLoading(true);
    try {
      await settleInvoiceWithBatch(data);
      setSelectedRows([]);
      notification.success({
        message: `${data.length}개의 청구서 정산 완료`,
      });
    } catch (error) {
      console.error(error);
      const description = errorObjectToString(error);
      notification.error({
        message: '청구서 정산 확정 실패',
        description,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (stores) {
      const paymentItemsObservable = observePaymentItem([
        // 정산이 완료되지 않은 결제내역
        ['settlementResultId', '==', null],
        ['deletedAt', '==', null],
      ]);
      const invoicesObservable = observeInvoices([
        // 정산이 완료되지 않은 청구서
        ['settledAt', '==', null],
        ['deletedAt', '==', null],
      ]);

      const subscription = combineLatest([paymentItemsObservable, invoicesObservable]).subscribe(
        ([paymentItems0, invoices]) => {
          const invoicesWithStore = invoices.map((invoice) => {
            const store = stores.find((store) => store._id === invoice.storeId);
            const paymentItems = paymentItems0.filter((paymentItem) => paymentItem.storeId === invoice.storeId);
            return {
              ...invoice,
              storeNickname: store?.storeNickname || null,
              paymentItems,
            };
          });
          setInvoices(invoicesWithStore);
        }
      );

      return () => {
        subscription.unsubscribe();
      };
    }
  }, [stores]);

  return (
    <div className='tabBody flexColumn height100'>
      <section className='flexRow flexSpaceBetween'>
        <Popconfirm
          title='정산을 진행하시겠습니까?'
          onConfirm={() => handleSettleInvoices(selectedRows)}
          okButtonProps={{ loading }}>
          <Button type='primary' icon={<FileDoneOutlined />} loading={loading} disabled={selectedRows.length <= 0}>
            {selectedRows.length}개 정산 확정
          </Button>
        </Popconfirm>
      </section>
      <section className='height100'>
        <PrevUnsettledInvoiceTable rowData={invoices} setSelectedRows={setSelectedRows} />
      </section>
    </div>
  );
};

export default PrevUnsettledInvoicePage;
