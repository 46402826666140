import { ColDef, ColGroupDef, RowClassParams } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { FC, useMemo } from 'react';

export type ProductBinLocationData = {
  productId: string;
  supplierLabel: string;
  supplierName: string;
  fullName: string;
  binLocation: string;
};

interface ProductBinLocationExcelTableProps {
  rowData: ProductBinLocationData[] | undefined;
}

const defaultColDef = {
  sortable: true,
  resizable: true,
  filter: true,
};

const ProductBinLocationExcelTable: FC<ProductBinLocationExcelTableProps> = ({ rowData }) => {
  const columnDefs: (ColDef<ProductBinLocationData> | ColGroupDef<ProductBinLocationData>)[] = useMemo(
    () => [
      {
        headerName: '상품ID',
        field: 'productId',
        width: 100,
      },
      {
        headerName: '구분',
        field: 'supplierLabel',
        width: 100,
      },
      {
        headerName: '매입처',
        field: 'supplierName',
        width: 140,
      },
      {
        headerName: '상품명',
        field: 'fullName',
        width: 300,
      },
      {
        headerName: '재고 위치',
        field: 'binLocation',
        width: 120,
      },
    ],
    []
  );
  return (
    <div className='ag-theme-alpine height100 flex1'>
      <AgGridReact
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        // rowId를 지정해주지 않으면 데이터 변경시 refresh가 발생한다.
        getRowId={(params) => params.data.productId}
        // 편집 완료후 스크롤 이동을 막는다.
        suppressScrollOnNewData={true}
        getRowStyle={(params: RowClassParams<ProductBinLocationData>) => ({
          opacity: params.data?.supplierLabel === 'E' ? 1 : 0.5,
          backgroundColor: params.data?.supplierLabel === 'E' ? 'white' : 'var(--gray200)',
        })}
      />
    </div>
  );
};

export default ProductBinLocationExcelTable;
