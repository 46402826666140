import { notification } from 'antd';
import { uniq } from 'lodash-es';
import { useCallback } from 'react';
import useSWR from 'swr';

import { errorObjectToString } from 'src/lib/1/util';
import { getStore, getUser, updateStore, updateUser } from 'src/lib/4/firebase-short-cut';
import { ConsoleLogger } from 'src/lib/5/logger';

const logger = ConsoleLogger.getInstance();

const getUserWithStore = async (request: [string, string]) => {
  const [userId] = request;
  const user = await getUser(userId);
  if (!user) throw new Error('User not found');

  if (user?.storeId) {
    const store = await getStore(user.storeId);
    return { ...user, store: store ?? null };
  } else {
    return { ...user, store: null };
  }
};

const useUserWithStore = (userId?: string) => {
  const {
    data: userWithStore,
    error,
    isValidating: userLoading,
    mutate,
  } = useSWR(userId ? [userId, 'user-with-store'] : null, getUserWithStore, {
    revalidateOnReconnect: false,
    revalidateOnFocus: false,
  });

  const mutateUser = useCallback(
    async (field: string, value: any) => {
      if (!userId) {
        console.error('userId is not defined');
        return;
      }

      if (userWithStore === undefined) {
        console.error('userWithStore is not defined');
        return;
      }

      try {
        if (field === 'favoriteProducts') {
          const favoriteProducts = uniq((value as string).split(',').map((id) => id.trim()));
          mutate(
            {
              ...userWithStore,
              favoriteProducts,
            },
            false
          );
          await updateUser(userId, {
            favoriteProducts,
          });
          mutate();
        } else {
          if (field === 'status' && value === 'deleted') {
            notification.info({
              message: '사용자 삭제는 개발자에게 요청해주세요.',
              description: '사용자 상태를 변경할 수 없습니다.',
            });
            logger.logConsole(`사용자 관리 - user/${userId}:: 사용자 삭제 요청`, { level: 'error' });
            return;
          }
          mutate({
            ...userWithStore,
            [field]: value,
          });
          await updateUser(userId, {
            [field]: value,
          });
          mutate();
        }
        notification.success({
          message: '사용자 상세 정보 수정 성공',
          description: `${field}를 ${value}로 수정하였습니다.`,
        });
      } catch (error: any) {
        notification.error({
          message: '사용자 상세 정보 수정 실패',
          description: error.message,
        });
      }
    },
    [mutate, userId, userWithStore]
  );

  const mutateStore = useCallback(
    async (field: string, value: any) => {
      if (!userWithStore || !userWithStore?.storeId || !userWithStore?.store) {
        console.error('storeId is not defined');
        return;
      }

      try {
        if (field === 'unhiddenProducts') {
          const unhiddenProducts = uniq((value as string).split(',').map((id) => id.trim()));
          mutate(
            {
              ...userWithStore,
              store: { ...userWithStore.store, unhiddenProducts },
            },
            false
          );
          await updateStore(userWithStore.storeId, {
            unhiddenProducts,
          });
          mutate();
        } else {
          mutate(
            {
              ...userWithStore,
              store: { ...userWithStore.store, [field]: value },
            },
            false
          );
          await updateStore(userWithStore.storeId, {
            [field]: value,
          });
          mutate();
        }
        notification.success({
          message: '업소 상세 정보 수정 성공',
          description: `${field}를 ${value}로 수정하였습니다.`,
        });
        logger.logConsole('업소 상세 정보 수정', {
          extraData: {
            userId: userWithStore._id,
            storeId: userWithStore.storeId,
            field,
            value,
          },
        });
      } catch (error) {
        const description = errorObjectToString(error);
        notification.error({
          message: '업소 상세 정보 수정 실패',
          description,
        });
        logger.logConsole(`업소 상세 정보 수정 실패: ${description}`, {
          level: 'error',
        });
      }
    },
    [mutate, userWithStore]
  );

  return { userWithStore, error, userLoading, mutateUser, mutateStore };
};

export default useUserWithStore;
