import { SyncOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import { FC, useCallback, useState } from 'react';
import { callSyncMobileMessageResult } from 'src/utils/firebase-callable';

import { errorObjectToString } from 'src/lib/1/util';
import { CallSyncMobileMessageResultRequest } from 'src/lib/3/schema-on-call';

const SyncMobileMessageResultButton: FC<CallSyncMobileMessageResultRequest> = ({
  type,
  mobileMessageDocId,
  requestId,
  recipientSeq,
}) => {
  const [loading, setLoading] = useState(false);

  const onClick = useCallback(async () => {
    setLoading(true);
    try {
      const result = await callSyncMobileMessageResult({
        type,
        mobileMessageDocId,
        requestId,
        recipientSeq,
      });
      if (result.data.result === 'success') {
        message.success('동기화 완료');
      } else {
        message.error(result.data.reason ?? '알 수 없는 오류 발생');
      }
    } catch (error) {
      console.error(error);
      const message = errorObjectToString(error);
      message.error({
        message,
      });
    }
    setLoading(false);
  }, [type, mobileMessageDocId, recipientSeq, requestId]);

  return (
    <Button type='primary' size='small' icon={<SyncOutlined />} loading={loading} onClick={onClick}>
      결과조회
    </Button>
  );
};

export default SyncMobileMessageResultButton;
