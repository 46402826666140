import { Select } from 'antd';
import { FC, useCallback } from 'react';

import useStoresForSearch from 'src/hooks/useStoresForSearch';

interface StoreSelectorProps {
  selectedStoreCode: string | null;
  setSelectedStoreCode: (storeId: string | null) => void;
  size?: 'large' | 'middle' | 'small';
  maxWidth?: number;
}

const StoreSelector: FC<StoreSelectorProps> = ({ selectedStoreCode, setSelectedStoreCode, size, maxWidth }) => {
  const { storeSearchValues, isValidating } = useStoresForSearch();

  const onStoreSearch = useCallback(
    (value: string) => {
      const selectedStoreCode = value.split('-')?.[0] ?? null;
      setSelectedStoreCode(selectedStoreCode);
    },
    [setSelectedStoreCode]
  );

  return (
    <Select
      showSearch
      loading={isValidating}
      placeholder='매장 선택'
      size={size}
      value={selectedStoreCode}
      onChange={onStoreSearch}
      style={{ width: '100%', maxWidth }}
      options={(storeSearchValues ?? []).map((storeKey) => {
        const storeCode = storeKey.split('-')?.[0];
        return { label: storeKey, value: storeCode };
      })}
      filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
    />
  );
};

export default StoreSelector;
