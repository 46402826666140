import { CloudUploadOutlined, DeleteOutlined, ExclamationCircleFilled, LoadingOutlined } from '@ant-design/icons';
import { PaymentItem, PaymentItemDoc } from '@gooduncles/gu-app-schema';
import { Button, ConfigProvider, Modal, notification } from 'antd';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { HyosungCMSTransactionRawData, KbBankTransactionRawData } from 'src/schema/schema-raw-payment-item';
import { useAuth } from 'src/stores/auth-context';
import { hyosungCmsTransToPaymentItems, kbTransToPaymentItems } from 'src/utils/payment-util';

import { errorObjectToString } from 'src/lib/1/util';
import { readExcelFile } from 'src/lib/1/xlsx-util';
import { FirebaseManager } from 'src/lib/3/firebase-manager';
import { createPaymentItem } from 'src/lib/4/firebase-short-cut';

import FileUploadArea from 'src/components/Common/FileUploadArea/FileUploadArea';

import classes from './UploadModal.module.scss';

import PaymentItemTable from '../PaymentItemTable/PaymentItemTable';

const firebaseManager = FirebaseManager.getInstance();
const { confirm } = Modal;

const onReadExcelFile = async (createdBy: string | null, file: File, type: 'kb' | 'hyosung') => {
  try {
    if (!createdBy) throw new Error('관리자 정보에 문제가 있습니다.');
    if (type === 'kb') {
      const sheet = await readExcelFile<KbBankTransactionRawData>(file, true);
      if (!Object.keys(sheet[0]).some((key) => key.includes('보낸분/받는분'))) {
        throw new Error('보낸분/받는분 컬럼이 존재하지 않습니다.');
      }
      const data = kbTransToPaymentItems(sheet, createdBy);
      return data;
    } else if (type === 'hyosung') {
      const sheet = await readExcelFile<HyosungCMSTransactionRawData>(file, true);
      if (!Object.keys(sheet[0]).some((key) => key.includes('결제상태'))) {
        throw new Error('결제상태 컬럼이 존재하지 않습니다.');
      }
      const data = await hyosungCmsTransToPaymentItems(sheet, createdBy);
      return data;
    }
  } catch (error) {
    console.error(error);
    const description = errorObjectToString(error);
    notification.error({
      message: '파일 업로드 실패',
      description,
    });
  }
  return null;
};

/**
 * 결제내역을 서버에 업로드합니다.
 */
const onUploadPaymentItems = async (paymentItems: PaymentItem[]) => {
  try {
    firebaseManager.batchStart();
    for (const paymentItem of paymentItems) {
      await createPaymentItem(paymentItem, true);
    }
    await firebaseManager.batchEnd();
  } catch (error) {
    console.error(error);
    const description = errorObjectToString(error);
    notification.error({
      message: '결제내역 서버 업로드 실패',
      description,
    });
  }
};

interface RawPaymentItemUploadModalProps {
  type: 'kb' | 'hyosung';
  unSettledItems?: PaymentItemDoc[];
}

/**
 * 국민은행 거래내역, 효성CMS 수납내역을 PaymentItem(결제내역)으로 변환하여 업로드하는 모달
 */
const RawPaymentItemUploadModal: FC<RawPaymentItemUploadModalProps> = ({ type, unSettledItems }) => {
  const { authUser } = useAuth();
  const email = useMemo(() => (authUser ? authUser.email : null), [authUser]);
  const title = type === 'kb' ? 'KB 거래내역' : '효성CMS 수납내역';
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileToUpload, setFileToUpload] = useState<File>();
  const [paymentItems, setPaymentItems] = useState<PaymentItemDoc[]>();
  const [selectedRows, setSelectedRows] = useState<PaymentItemDoc[]>([]);
  const duplicateItems = useMemo(() => {
    if (!selectedRows || !unSettledItems) return 0;
    return selectedRows.filter((item) =>
      unSettledItems.some((unSettled) => unSettled.transactionDate === item.transactionDate)
    ).length;
  }, [selectedRows, unSettledItems]);

  const openModal = () => setOpen(true);
  const removeFile = useCallback(() => {
    if (loading) {
      notification.warning({
        message: '파일 분석 & 업로드 중',
        description: '파일 분석 또는 업로드 중에는 삭제 및 취소할 수 없습니다.',
      });
      return;
    }
    setFileToUpload(undefined);
    setPaymentItems(undefined);
  }, [loading]);

  const closeModal = useCallback(() => {
    removeFile();
    setOpen(false);
  }, [removeFile]);

  const uploadPaymentItems = useCallback(async () => {
    if (selectedRows) {
      setLoading(true);
      await onUploadPaymentItems(selectedRows);
      setLoading(false);
      closeModal();
    }
  }, [closeModal, selectedRows]);

  const onSubmit = useCallback(() => {
    if (duplicateItems > 0) {
      confirm({
        title: '결제내역 중복 확인',
        icon: <ExclamationCircleFilled />,
        content: `${duplicateItems}건의 중복이 의심됩니다. 업로드하시겠습니까?`,
        okText: '업로드',
        cancelText: '취소',
        okButtonProps: { danger: true },
        onOk: uploadPaymentItems,
      });
    } else {
      uploadPaymentItems();
    }
  }, [duplicateItems, uploadPaymentItems]);

  useEffect(() => {
    if (fileToUpload) {
      setLoading(true);
      onReadExcelFile(email, fileToUpload, type)
        .then((data) => {
          if (data) {
            setPaymentItems(data);
          } else {
            setFileToUpload(undefined);
          }
        })
        .finally(() => setLoading(false));
    }
  }, [email, fileToUpload, type]);

  return (
    <ConfigProvider theme={{ token: { colorPrimary: type === 'kb' ? '#ffa200' : '#6c32f2' } }}>
      <Button type='primary' icon={<CloudUploadOutlined />} style={{ fontWeight: 600 }} onClick={openModal}>
        {title}
      </Button>
      <Modal
        title={title}
        open={open}
        onOk={onSubmit}
        onCancel={closeModal}
        okText='업로드'
        cancelText='취소'
        width='1200px'
        maskClosable={false}
        keyboard={false}
        centered
        destroyOnClose
        okButtonProps={{ loading, disabled: selectedRows.length === 0 }}
        styles={{ body: { height: 'calc(100vh - 154px)', overflow: 'scroll' } }}>
        {/* 파일 없음 */}
        {!fileToUpload && (
          <FileUploadArea
            fileToUpload={fileToUpload}
            setFile={setFileToUpload}
            subTitle={`${title} 엑셀파일만 지원합니다.`}
          />
        )}
        {/* 파일 있음 */}
        {fileToUpload && (
          <div className={classes.uploadModalContainer}>
            <div className={classes.uploadFile}>
              <span>{fileToUpload.name}</span>
              <Button type='text' icon={<DeleteOutlined />} danger onClick={removeFile} />
            </div>
            <section className={classes.tableContainer}>
              {paymentItems ? (
                <PaymentItemTable
                  rowData={paymentItems}
                  setSelectedRows={setSelectedRows}
                  forUpload
                  listForDuplicateCheck={unSettledItems}
                />
              ) : (
                <div className={classes.loadingBox}>
                  <p>매장 정보를 매칭하고 있습니다.</p>
                  <LoadingOutlined className={classes.loadingIcon} />
                </div>
              )}
            </section>
          </div>
        )}
      </Modal>
    </ConfigProvider>
  );
};

export default RawPaymentItemUploadModal;
