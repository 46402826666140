import useSWR from 'swr';

import { getDevices } from 'src/lib/4/firebase-short-cut';

const useDevices = () => {
  const { data: devices } = useSWR('devices', getDevices);

  return {
    devices,
  };
};

export default useDevices;
