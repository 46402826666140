import { OrderDoc, OrderProduct, OrderStatusCode, ProductStateCode } from '@gooduncles/gu-app-schema';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { ColDef, ColGroupDef, RowClassParams } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { Tabs, message } from 'antd';
import { groupBy, uniq } from 'lodash-es';
import { FC, useMemo, useState } from 'react';

import { settlementDayTable } from 'src/lib/1/constant';
import { formatDate } from 'src/lib/1/date-util';
import { formatNumber } from 'src/lib/1/util';
import {
  onCellValueChangedWithUpdateForOrderProduct,
  onValueSetterWithValidation,
  timestampFormatter,
} from 'src/lib/6/ag-grid-util';

import useOrderListForUser from 'src/hooks/useOrderListForUser';

import OrderMonthGroupRenderer from 'src/components/AgGrid/GroupRowInnerRenderer/OrderMonthGroupRenderer/OrderMonthGroupRenderer';
import DownloadRawDataCell from 'src/components/Common/DownloadRawDataCell/DownloadRawDataCell';
import SoldOutCellRenderer from 'src/components/Order/OrderTable/SoldOutCellRenderer/SoldOutCellRenderer';

import classes from './UserOrderList.module.scss';

type ProductWithOrder = OrderProduct & {
  order: OrderDoc;
};

type UserOrderListProps = {
  userId: string;
};

const logName = '사용자 상세';
const onCellValueChangedForOrderProduct = onCellValueChangedWithUpdateForOrderProduct(logName);

const getDetailRowStyle = (params: RowClassParams<ProductWithOrder>) => {
  if (params.data?.state === ProductStateCode.OUTOFSTOCK) {
    return { backgroundColor: 'hsl(357, 100%, 95%)' };
  }
};

const productVolumeChangeValidator = (params: any) => (value: number) => {
  const data: ProductWithOrder = params.data;
  if (value === 0) {
    message.error('유효한 값이 아닙니다.');
    return false;
  }

  if (!data.productId) {
    message.error('상품 id가 없습니다.');
    return false;
  }

  if (![OrderStatusCode.DELIVERED, OrderStatusCode.ACCEPTED].includes(data.order.orderStatus)) {
    message.error('수락완료 또는 배송완료 주문만 수량을 변경할 수 있습니다.');
    return false;
  }

  const orderDay = new Date(data.order.orderDate).getDay();
  const today = new Date().getDay();
  const unsettled = settlementDayTable[today].some((settlementDay) => settlementDay === orderDay);
  if (!unsettled) {
    message.error('정산일이 지난 주문입니다.');
    return false;
  }

  if (data.order.settledAt) {
    message.error('정산완료된 주문입니다.');
    return false;
  }

  return true;
};

const items = (groupedOrders: Record<string, OrderDoc[]>) => {
  return [
    {
      key: 'submitted',
      label: '주문완료',
      children: (
        <UserOrderTable
          orders={groupedOrders[OrderStatusCode.SUBMITTED] ?? []}
          orderStatus={OrderStatusCode.SUBMITTED}
        />
      ),
    },
    {
      key: 'accepted',
      label: '수락완료',
      children: (
        <UserOrderTable orders={groupedOrders[OrderStatusCode.ACCEPTED] ?? []} orderStatus={OrderStatusCode.ACCEPTED} />
      ),
    },
    {
      key: 'delivered',
      label: '배송완료',
      children: (
        <UserOrderTable
          orders={groupedOrders[OrderStatusCode.DELIVERED] ?? []}
          orderStatus={OrderStatusCode.DELIVERED}
          showRowGroup
        />
      ),
    },
    {
      key: 'canceled',
      label: '취소(사용자)',
      children: (
        <UserOrderTable orders={groupedOrders[OrderStatusCode.CANCELED] ?? []} orderStatus={OrderStatusCode.CANCELED} />
      ),
    },
    {
      key: 'rejected',
      label: '취소(관리자)',
      children: (
        <UserOrderTable orders={groupedOrders[OrderStatusCode.REJECTED] ?? []} orderStatus={OrderStatusCode.REJECTED} />
      ),
    },
  ];
};

const UserOrderList: FC<UserOrderListProps> = ({ userId }) => {
  const { orderList } = useOrderListForUser(userId);
  const groupByStatus = groupBy(orderList, 'orderStatus');
  const deliveredOrders = groupByStatus[OrderStatusCode.DELIVERED] ?? [];
  const totalOrderCount = deliveredOrders.length;
  const totalAmount = deliveredOrders.reduce((acc, order) => acc + order.totalAmount, 0);
  const avgUnit = Math.floor(totalAmount / totalOrderCount);
  const groupByMonth = groupBy(
    deliveredOrders.map((order) => ({ ...order, month: formatDate(order.orderDate, 'Y년 L월') })),
    'month'
  );
  const avgMonthlyAmount = Math.floor(totalAmount / Object.keys(groupByMonth).length);
  const productVarietyCount = uniq(deliveredOrders.flatMap((order) => order.products.map((p) => p.productId))).length;

  return (
    <div className={classes.userOrderListContainer}>
      <table className={classes.summary}>
        <tr>
          <td>총 주문</td>
          <td>
            <span>{totalOrderCount}</span>건 (배송완료 기준)
          </td>
          <td>총 주문금액</td>
          <td>
            <span>{formatNumber(totalAmount)}</span>원
          </td>
        </tr>
        <tr>
          <td>평균 단가</td>
          <td>
            <span>{formatNumber(avgUnit)}</span>원
          </td>
          <td>월 평균 주문금액</td>
          <td>
            <span>{formatNumber(avgMonthlyAmount)}</span>원
          </td>
        </tr>
        <tr>
          <td />
          <td />
          <td>총 주문 품목수</td>
          <td>
            <span>{formatNumber(productVarietyCount)}</span>개
          </td>
        </tr>
      </table>

      <Tabs defaultActiveKey='delivered' items={items(groupByStatus)} style={{ flex: 1 }} />
    </div>
  );
};

const defaultColDef: ColDef<OrderDoc> = {
  flex: 1,
  sortable: true,
  resizable: true,
  filter: true,
};

const groupRowRendererParams = {
  suppressCount: true,
  innerRenderer: OrderMonthGroupRenderer,
};

const columnTypes = {
  number: {
    aggFunc: 'sum',
  },
};

const UserOrderTable: FC<{ orders: OrderDoc[]; orderStatus: OrderStatusCode; showRowGroup?: boolean }> = ({
  orders,
  showRowGroup,
}) => {
  const [, setGridRef] = useState<AgGridReact<OrderDoc> | null>(null);
  const columnDefs: (ColDef<OrderDoc> | ColGroupDef<OrderDoc>)[] = useMemo(
    () => [
      { headerName: 'OrderId', field: '_id', minWidth: 300, hide: true },
      {
        headerName: '_timeCreate',
        field: '_timeCreate',
        minWidth: 300,
        valueFormatter: timestampFormatter,
        hide: true,
      },
      {
        headerName: '월',
        field: 'orderDate',
        minWidth: 160,
        rowGroup: showRowGroup,
        hide: true,
        valueGetter: (params) => {
          if (!params.data) return '';
          if (params.data?.orderStatus === OrderStatusCode.DELIVERED && params.data.deliveredAt) {
            return formatDate(params.data.deliveredAt, 'Y년 L월');
          }
          return params.data.orderDate ? formatDate(params.data.orderDate, 'Y년 L월') : '';
        },
      },
      { field: 'totalAmount', aggFunc: 'sum', valueFormatter: ({ value }) => formatNumber(value), hide: true },
      {
        headerName: '주문시각',
        field: 'orderDate',
        colId: 'orderDateField',
        minWidth: 160,
        valueFormatter: ({ value }) => (value ? formatDate(value, 'L월 d일(EEEEEE) HH:mm:ss') : ''),
      },
      {
        headerName: '배송시각',
        field: 'deliveredAt',
        colId: 'deliveredAtField',
        minWidth: 160,
        valueFormatter: ({ value }) => (value ? formatDate(value, 'L월 d일(EEEEEE) HH:mm:ss') : ''),
      },
      {
        headerName: '주문금액(세금포함)',
        field: 'totalAmount',
        minWidth: 140,
        cellRenderer: 'agGroupCellRenderer',
        valueFormatter: ({ value }) => formatNumber(value),
      },
      {
        headerName: '지불금액',
        field: 'paidAmount',
        minWidth: 140,
        valueFormatter: ({ value }) => value ?? formatNumber(value),
        hide: true,
      },
      { headerName: '품목수', minWidth: 88, valueGetter: (params) => params.data?.products.length ?? '' },
      {
        headerName: '가격동기화',
        field: 'products',
        pinned: 'right',
        maxWidth: 120,
        valueFormatter: ({ value, data }) => {
          if (data?._id === 'summary' || !data?._id) return (data as any)?.isAllSynced ? '✅' : '⚪️';
          const products = value as OrderDoc['products'];
          const isAllSynced = products.every((product) => product.priceDiff !== null);
          return products.length > 0 ? (isAllSynced ? '✅' : '⚪️') : '';
        },
      },
      {
        headerName: '배송 담당자',
        field: 'deliveryPartnerName',
      },
      {
        headerName: '액션',
        minWidth: 120,
        cellRenderer: DownloadRawDataCell,
        hide: true,
      },
    ],
    [showRowGroup]
  );

  const detailCellRendererParams = useMemo(() => {
    const detailColumnDefs: (ColDef<ProductWithOrder> | ColGroupDef<ProductWithOrder>)[] = [
      {
        headerName: '상품코드',
        field: 'productId',
        minWidth: 96,
      },
      {
        headerName: '상품명',
        field: 'fullName',
        minWidth: 180,
      },
      {
        headerName: '가격',
        field: 'snapshotPrice',
        minWidth: 100,
        maxWidth: 160,
        valueFormatter: (params) => {
          const { value } = params;
          const { price } = params.data as ProductWithOrder;
          return formatNumber(value ?? price ?? 0);
        },
      },
      {
        headerName: '주문 후 가격 변동',
        field: 'priceDiff',
        minWidth: 100,
        maxWidth: 160,
        valueFormatter: (params) => `${params.value > 0 ? '+' : ''}${formatNumber(params.value ?? 0)}`,
      },
      {
        headerName: '*수량',
        field: 'volume',
        minWidth: 100,
        maxWidth: 160,
        editable: true,
        valueFormatter: (params) => formatNumber(params.value),
        valueSetter: (params) =>
          onValueSetterWithValidation(params, ['order._id'], productVolumeChangeValidator(params), {
            type: 'number',
          }),
        onCellValueChanged: onCellValueChangedForOrderProduct,
      },
      {
        headerName: '총액',
        maxWidth: 160,
        valueFormatter: (params) => {
          const { price, snapshotPrice } = params.data as ProductWithOrder;
          return formatNumber((snapshotPrice ?? price ?? 0) * (params.data?.volume ?? 0));
        },
      },
      {
        headerName: '품절 처리',
        maxWidth: 160,
        cellRenderer: SoldOutCellRenderer,
      },
    ];

    return {
      refreshStrategy: 'rows',
      detailGridOptions: {
        columnDefs: detailColumnDefs,
        defaultColDef,
        enableCellChangeFlash: true,
        onFirstDataRendered: (params: any) => {
          params.api.sizeColumnsToFit();
        },
        getRowStyle: getDetailRowStyle,
      },
      getDetailRowData: (params: any) => {
        const order = params.data;
        const data = order?.products.map((product: any) => ({ ...product, order }));
        params.successCallback(data);
      },
    };
  }, []);

  const isRowMaster = useMemo(() => {
    return (dataItem: OrderDoc) => {
      return dataItem ? dataItem.products && dataItem.products.length > 0 : false;
    };
  }, []);

  return (
    <div className='ag-theme-alpine' style={{ height: '100%' }}>
      <AgGridReact
        ref={setGridRef}
        rowData={orders}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        suppressScrollOnNewData={true}
        alwaysShowHorizontalScroll={true}
        isRowMaster={isRowMaster}
        keepDetailRows={true}
        masterDetail={true}
        detailRowAutoHeight={true}
        enableCellChangeFlash={true}
        detailCellRendererParams={detailCellRendererParams}
        // rows group
        groupDisplayType={'groupRows'}
        groupRowRendererParams={groupRowRendererParams}
        columnTypes={columnTypes}
      />
    </div>
  );
};

export default UserOrderList;
