import {
  Product,
  ProductBase,
  ProductDoc,
  ProductMeasure,
  ProductSnapshotDoc,
  ProductStateCode,
} from '@gooduncles/gu-app-schema';
import { isNumber, partition } from 'lodash-es';

import {
  FEATURES_WITH_PRIORITY,
  productCategories,
  productPackings,
  productStates,
  productStorages,
  rawProductPackingToCode,
  rawProductStorageToCode,
} from '../1/constant';
import { getOrderDate } from '../1/date-util';
import { arraySanitizer, booleanSanitizer, numberSanitizer, stringSanitizer } from '../1/sanitizer-util';
import { ProductExcelItem } from '../1/schema-excel-product';
import { currencyToNumber, excelRowValidation, formatNumber, moveItemsToFrontInArray } from '../1/util';

/**
 * 단위를 변환해준다.
 */
const convertMassUnit = (unit0: string, amount: number) => {
  if (unit0 === 'kg') {
    return {
      unit: 'g',
      amount: amount * 1000,
    };
  }
  if (unit0 === 'l') {
    return {
      unit: 'ml',
      amount: amount * 1000,
    };
  }
  return {
    unit: unit0,
    amount,
  };
};

/**
 * 상품 단위별 가격을 baseAmount에 맞게 계산한다.
 * 이때 입력 단위가 kg처럼 mass unit인 경우에는 1000을 곱해준다.
 * @param totalPrice 10000
 * @returns
 */
export const calcProductUnitPrice = (measure: ProductMeasure[], totalPrice: number, baseAmount = 100) => {
  const validUnits = ['g', 'kg', 'ml', 'l'];
  // '1kg내외' 등의 특수한 경우를 계산하기 위해 추가했다.
  const ignore = '내외';

  // 1. 메인 단위를 찾는다.
  const mainMeasure = measure.find((m) => validUnits.includes(m.unit.replace(ignore, '').toLowerCase()));
  if (!mainMeasure) {
    return null;
  }
  // 2. 그 외 수량 단위를 찾는다.
  const multiple = measure.filter((m) => !validUnits.includes(m.unit.replace(ignore, '').toLowerCase()));
  const totalAmount = multiple.reduce((acc, cur) => acc * cur.amount, mainMeasure.amount ?? 1);
  const unit = mainMeasure.unit.replace(ignore, '');
  const lowercaseUnit = unit.toLowerCase();
  if (!validUnits.includes(lowercaseUnit)) {
    return null;
  }

  // 3. 메인 단위로 변환한다. kg -> g, l -> ml
  const convertedMeasure = convertMassUnit(lowercaseUnit, totalAmount);
  const unitPrice = Math.floor((totalPrice / convertedMeasure.amount) * baseAmount);

  return {
    unit: convertedMeasure.unit,
    price: unitPrice,
    amount: baseAmount,
  };
};

/**
 * Product의 내용중 이름에 영향을 주는 요소의 변화가 있는 경우
 * 변경된 fullName을 반환한다.
 */
export const getProductFullName = (product: Product) => {
  const { name, measure, brand = null, origin = null, features = null } = product;
  const subNameList = [brand, ...(features ?? []), origin].filter((item) => item && item.length > 0);
  const subName = subNameList.length > 0 ? ` (${subNameList.join('/')})` : '';
  const measureText = Array.isArray(measure) ? measure.map((m) => formatNumber(m.amount) + m.unit).join('*') : '';
  const fullName = `${name}${subName} ${measureText}`;
  return fullName;
};

/**
 * 상품 단위를 파싱한다.
 * ex) 1kg => { value: 1, unit: 'kg' }
 */
export const parseProductMeasure = (value: string) => {
  const measures = excelRowValidation(value)?.split('*');
  if (measures) {
    const productMeasures = measures
      .map((measure) => {
        const match = measure.trim().match(/(\d*\.?\d+)([^.]+)/);
        return match && match.length > 2
          ? {
              amount: Number(match[1]),
              unit: match[2],
            }
          : undefined;
      })
      .filter((measure) => measure) as ProductMeasure[];
    return productMeasures.length > 0 ? productMeasures : null;
  }
  return null;
};

/**
 * 상품의 필드를 업데이트하기 전에 연관된 데이터를 전처리한다.
 */
export const preprocessProductData = (product: ProductDoc, field: string, value: any): Partial<Product> => {
  const newProduct = { ...product, [field]: value };
  const fullName = ['name', 'measure', 'brand', 'origin', 'features'].includes(field)
    ? getProductFullName(newProduct)
    : null;

  const unitPrice = ['measure', 'price'].includes(field)
    ? calcProductUnitPrice(newProduct.measure, newProduct.price)
    : null;

  const costUpdatedAt = ['cost'].includes(field) ? getOrderDate() : null;

  return {
    [field]: value,
    ...(fullName ? { fullName } : {}),
    ...(unitPrice ? { unitPrice } : {}),
    ...(costUpdatedAt ? { costUpdatedAt } : {}),
  };
};

export const productValueSanitizer = (field: string, value: any) => {
  // Nullable 필드는 null을 허용한다.
  if (
    ['brand', 'origin', 'features', 'mainTag', 'tags', 'badge', 'imageDescription', 'packingInfo'].includes(field) &&
    value === null
  ) {
    return null;
  }

  if (field === 'measure') {
    const measure: ProductMeasure[] | null = parseProductMeasure(value);
    if (!measure) {
      throw new Error('입력한 상품 단위가 올바르지 않습니다.');
    }
    return measure;
  }

  // string
  if (['brand', 'name', 'origin', 'mainTag', 'imageDescription', 'state', 'packing', 'storage'].includes(field)) {
    return stringSanitizer(value);
  }

  // boolean
  if (['taxFree', 'hidden', 'dailyNotification'].includes(field)) {
    return booleanSanitizer(value);
  }

  // number
  if (['price', 'stuck', 'cost'].includes(field)) {
    return numberSanitizer(value);
  }

  // array
  if (['suppliers', 'tags', 'badge', 'state'].includes(field)) {
    return arraySanitizer(value);
  }

  if (field === 'features') {
    const sanitized = arraySanitizer(value, false) as string[];
    // 상품팀 요청에 따라 우선순위가 있는 특징들을 앞으로 이동시킨다.
    const orderedFeatures = moveItemsToFrontInArray(sanitized, FEATURES_WITH_PRIORITY);
    return orderedFeatures;
  }

  if (field === 'categories') {
    const sanitized = stringSanitizer(value);
    // 상품팀 요청에 따라 우선순위가 있는 특징들을 앞으로 이동시킨다.
    return [sanitized, 'all'];
  }

  if (field === 'state') {
    if (!Object.keys(productStates).includes(value)) {
      throw new Error('입력한 상품 판매 상태가 올바르지 않습니다.');
    }
    return value;
  }

  return value;
};

/**
 * 편집 가능한 상품 필드에 대해 유효성 검사를 수행한다.
 * @param field
 * @param value data after sanitization
 * @returns
 */
export const productValueValidator = (field: string, value: any) => {
  // Nullable 필드는 null을 허용한다.
  if (
    ['brand', 'origin', 'features', 'mainTag', 'tags', 'badge', 'imageDescription', 'packingInfo'].includes(field) &&
    value === null
  ) {
    return null;
  }

  // string
  if (['brand', 'name', 'origin', 'mainTag', 'imageDescription'].includes(field)) {
    if (typeof value !== 'string') {
      throw new Error('입력한 값이 문자열이 아닙니다.');
    }
    return value;
  }

  // boolean
  if (['taxFree', 'hidden', 'dailyNotification'].includes(field)) {
    if (typeof value !== 'boolean') {
      throw new Error('입력한 값이 불리언이 아닙니다.');
    }
    return value;
  }

  // number
  if (['price', 'stuck'].includes(field)) {
    const numberValue = Number(value);
    if (isNaN(numberValue)) {
      throw new Error('입력한 값이 숫자가 아닙니다.');
    }
    return numberValue;
  }

  // array
  if (['suppliers', 'features', 'tags', 'badge'].includes(field)) {
    if (!Array.isArray(value)) {
      throw new Error('입력한 값이 배열이 아닙니다.');
    }
    return value;
  }

  if (field === 'measure') {
    if (value.length < 1 || value[0]?.amount === undefined || value[0]?.unit === undefined) {
      throw new Error('입력한 단위가 올바르지 않습니다.');
    }
    return value;
  }

  if (field === 'categories') {
    const isValidCategory = Object.keys(productCategories).some((category) => value.includes(category));
    if (!isValidCategory) {
      throw new Error('입력한 상품 카테고리가 올바르지 않습니다.');
    }
    return value;
  }

  if (field === 'state') {
    if (!Object.keys(productStates).includes(value)) {
      throw new Error('입력한 상품 판매 상태가 올바르지 않습니다.');
    }
    return value;
  }

  if (field === 'packing') {
    if (!Object.keys(productPackings).includes(value)) {
      throw new Error('입력한 상품 포장 정보가 올바르지 않습니다.');
    }
    return value;
  }

  if (field === 'storage') {
    if (!Object.keys(productStorages).includes(value)) {
      throw new Error('입력한 상품 보관 정보가 올바르지 않습니다.');
    }
    return value;
  }

  return value;
};

/**
 * 상품의 기본 정보만 추출한다.
 */
export const getProductBaseFromProduct = (product: ProductDoc | ProductSnapshotDoc): ProductBase => {
  const {
    productId,
    categories,
    fullName,
    taxFree,
    price,
    unitPrice,
    stock,
    cost,
    suppliers,
    state,
    hidden,
    brand,
    packing,
    storage,
    origin,
    features,
    measure,
    mainTag,
    tags,
    packingInfo,
  } = product;
  return {
    productId,
    categories,
    fullName,
    taxFree,
    price,
    unitPrice,
    stock,
    cost,
    suppliers,
    state,
    hidden,
    brand,
    packing,
    storage,
    origin,
    features,
    measure,
    mainTag,
    tags,
    packingInfo,
  };
};

/**
 * 엑셀 파일의 필드명과 상품 필드명을 매핑한다.
 */
export const productExcelItemFieldMap: Record<string, string> = {
  /** 'P00001' */
  품목코드: 'productId',
  /** '기타' */
  구분: 'categories',
  /** '기타' */
  품명1: 'name',
  /** '면세' */
  '과/면세': 'taxFree',
  /** 'Z제이엘엠' */
  매입처1: 'suppliers',
  /** '13,100' */
  매입가: 'cost',
  /** '14,200' */
  판매가: 'price',
  /** 'Y' | 'N' | 'O'(Out of stuck - 품절) */
  사용유무: 'state',
  /** 'Y' | 'N' */
  숨김: 'hidden',
  /** 'Y' | 'N' */
  가격공유: 'dailyNotification',
  /** 실온 | 냉장 | 냉동 | 기타 */
  보관: 'storage',
  /** 소분 | 박스 | 기타 */
  패킹: 'packing',
  /** 1kg */
  단위: 'measure',
  /** 스파우트팩 */
  특징: 'features',
  /** 국내산 */
  원산지: 'origin',
  /** 오뚜기 */
  브랜드: 'brand',
  /** 마요네즈 */
  대표태그: 'mainTag',
  /** 드레싱,하인즈 */
  태그: 'tags',
  /** 스파우트팩 */
  이미지설명: 'imageDescription',
  /** 신제품,최저가 */
  배지: 'badge',
  /** 패킹시트에 표시되는 '비고'  */
  패킹표: 'packingInfo',
};

export const productExcelItemFieldMapReverse = Object.fromEntries(
  Object.entries(productExcelItemFieldMap).map(([key, value]) => [value, key])
);

/**
 * 엑셀 파일의 필드값을 상품 필드값으로 변환한다.
 */
const productExcelValueSanitizer = (field: string, value: string) => {
  if (field === 'measure') {
    return parseProductMeasure(value) ?? new Error('입력한 상품 단위가 올바르지 않습니다.');
  }

  if (field === 'price') {
    return currencyToNumber(value) ?? new Error('입력한 상품 가격이 올바르지 않습니다.');
  }

  if (field === 'cost') {
    return currencyToNumber(value) ?? new Error('입력한 상품 매입가가 올바르지 않습니다.');
  }

  if (['brand', 'origin', 'imageDescription', 'packingInfo'].includes(field)) {
    return value ?? null;
  }

  if (['features', 'tags', 'badge'].includes(field)) {
    return excelRowValidation(value)?.split(',') ?? null;
  }

  if (field === 'category') {
    return value ?? 'unknown';
  }

  if (['hidden', 'dailyNotification'].includes(field)) {
    return value === 'Y';
  }

  if (field === 'storage') {
    return rawProductStorageToCode[value];
  }

  if (field === 'packing') {
    return rawProductPackingToCode[value];
  }

  if (field === 'state') {
    return value === 'Y'
      ? ProductStateCode.STOCK
      : value === 'O'
      ? ProductStateCode.OUTOFSTOCK
      : ProductStateCode.DISCONTINUED;
  }

  if (field === 'mainTag') {
    return excelRowValidation(value);
  }

  return value;
};

/**
 * 여러개의 필드값을 조합해야하는 경우를 처리합니다.
 */
export const combineProductFields = (row: { [k: string]: any }) => {
  let fullName = null;
  let unitPrice = null;
  if (row.name) {
    const orderedFeatures = moveItemsToFrontInArray(row.features ?? [], FEATURES_WITH_PRIORITY);
    const subNameList = [row.brand, ...orderedFeatures, row.origin].filter((item) => item && item.length > 0);
    const subName = subNameList.length > 0 ? ` (${subNameList.join('/')})` : '';
    const measure = row.measure as ProductMeasure[];
    const measureText = Array.isArray(measure) ? measure.map((m) => formatNumber(m.amount) + m.unit).join('*') : '';
    fullName = `${row.name}${subName} ${measureText}`;
  }

  if (row.measure && isNumber(row.price)) {
    unitPrice = calcProductUnitPrice(row.measure, row.price);
  }

  return {
    ...row,
    ...(fullName ? { fullName } : {}),
    ...(unitPrice ? { unitPrice } : {}),
  };
};

/**
 * 엑셀 파일의 내용을 파싱한다.
 */
export const parseExcelProduct = (sheet: ProductExcelItem[]) => {
  const unsupportedFields = Object.keys(sheet[0]).filter((key) => productExcelItemFieldMap[key] === undefined);
  if (unsupportedFields.length > 0) {
    throw new Error(`지원하지 않는 열이 있습니다. ${unsupportedFields.join(', ')}`);
  }

  if (Object.keys(sheet[0]).includes('품목코드') === false) {
    throw new Error('품목코드가 없습니다.');
  }

  const sanitizedRows = sheet
    .map((row) => Object.fromEntries(Object.entries(row).map(([key, value]) => [productExcelItemFieldMap[key], value])))
    // 품목코드가 없거나 P10000(기타)인 경우는 제외한다.
    .filter((row) => row.productId && row.productId !== 'P10000')
    .map((row) =>
      Object.fromEntries(Object.entries(row).map(([key, value]) => [key, productExcelValueSanitizer(key, value)]))
    )
    .map((row) => combineProductFields(row) as Partial<Product> & { productId: string });

  // 오류가 있는 행과 없는 행을 분리한다.
  const [invalidRows, validRows] = partition(sanitizedRows, (row) =>
    Object.values(row).some((value) => value instanceof Error)
  );

  // 오류가 있는 행에 대한 내용을 출력한다.
  if (invalidRows.length > 0) {
    const errorMessage = invalidRows.map((row) => {
      const message = Object.entries(row)
        .filter(([, value]) => value instanceof Error)
        .map(([key]) => key)
        .join(', ');
      return `${row.productId}: ${message}`;
    });
    throw new Error(errorMessage.join('\n'));
  }

  return validRows;
};

export const initialProductBase: ProductBase = {
  productId: '',
  categories: ['all', 'unknown'],
  fullName: '상품이름이 없습니다.',
  taxFree: false,
  price: 0,
  unitPrice: null,
  stock: Infinity,
  cost: 0,
  suppliers: [],
  state: ProductStateCode.DISCONTINUED,
  hidden: false,
  brand: null,
  packing: 'others',
  storage: 'others',
  origin: null,
  features: null,
  measure: [],
  mainTag: null,
  tags: null,
  packingInfo: null,
};

/**
 * 상품의 마진율을 계산한다.
 */
export const calcProductMargin = (price: number, cost: number) => {
  if (price && cost) {
    const margin = Math.floor(((price - cost) / price) * 100);
    return margin;
  }
  return null;
};
