import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { useTitle } from 'src/hooks/useTitle';

import PromotionSectionTab from './PromotionSectionTab/PromotionSectionTab';

const PromotionPage: FC<{ title: string }> = ({ title }) => {
  useTitle(`GU 관리자 | ${title}`);
  return (
    <Routes>
      <Route path='/:tab' element={<PromotionSectionTab />} />
      <Route path='*' element={<Navigate to='/promotion/common' replace />} />
    </Routes>
  );
};

export default PromotionPage;

/**
 * 1. 하나의 페이지에서 공통 프로모션, 매장별 프로모션 탭으로 분리
 * 3. 매장별 프로모션 탭의 경우 배치 순서를 직접 변경하도록 수정
 */
