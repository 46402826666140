import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { useTitle } from '../hooks/useTitle';

import UserList from '../components/User/UserList';
import UserDetail from 'src/components/User/UserDetail/UserDetail';

const UserPage: FC<{ title: string }> = ({ title }) => {
  useTitle(`GU 관리자 | ${title}`);
  return (
    <Routes>
      <Route path='/all' element={<UserList />} />
      <Route path='/detail/:userId' element={<UserDetail />} />
      <Route path='/detail' element={<UserDetail />} />
      <Route path='/' element={<Navigate to='./detail' replace />} />
    </Routes>
  );
};

export default UserPage;
