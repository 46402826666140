/**
 * 칸반 보드를 구성하는 컬럼
 */
export interface DndColumnData {
  id: string;
  title: string;
  headerComponent?: JSX.Element;
  /** 오른쪽 항목에 고정하는 컬럼 */
  pinRight?: boolean;
}

export const DndItemType = {
  BOX: 'box',
  CARD: 'card',
};

export interface DndCardItem {
  id: string;
  groupId: string;
  title: string;
  index: number;
  data: any;
  cardStyle?: React.CSSProperties;
  element?: JSX.Element;
  detailElement?: JSX.Element;
}

export interface DndCardDragResult {
  /** 사용자가 드래그한 (대표) 아이템 */
  item: DndCardItem;
  /**
   * 이동할 아이템들
   */
  draggedItems: DndCardItem[];
  /**
   * 미리보기에 보여줄 아이템들
   */
  previewStack: DndCardItem[];
}

export interface DndBoxItem {
  id: string;
  groupId: string;
  title: string;
  index: number;
  data: any;
}

export interface DndColumnDropResult {
  groupId: string;
}

export type DndBoxState = Record<string, DndBoxItem[]>;
