import { cloneDeepWith, isString } from 'lodash-es';
import * as XLSX from 'xlsx';

/**
 * Event에서 받은 파일 데이터를 읽는다.
 * {@link https://developer.mozilla.org/en-US/docs/Web/API/FileReader/FileReader}
 */
export function readExcelFile<T extends object>(file: File, normalize?: boolean) {
  return new Promise<T[]>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      if (!e.target?.result) {
        throw new Error('파일을 읽는 도중 알 수 없는 에러발생');
      }
      const binaryString = e.target.result;
      try {
        const sheet = excelFileToJson<T>(binaryString, normalize);
        resolve(sheet);
      } catch (error: any) {
        reject(error);
      }
    };
    reader.readAsBinaryString(file);
  });
}

/**
 * 엑셀 데이터를 object배열로 변환한다.
 * {@link https://www.npmjs.com/package/xlsx}
 */
export function excelFileToJson<T>(excelData: string | ArrayBuffer, normalize?: boolean) {
  const workBook = XLSX.read(excelData, { type: 'binary' });
  const sheetName = workBook.SheetNames[0];
  const workSheet = workBook.Sheets[sheetName];
  trimHeaders(workSheet);

  if (normalize) {
    const result = XLSX.utils.sheet_to_json<T>(workSheet, {
      raw: false,
      blankrows: false,
    });
    return deepReplaceStrings(result, /\u3000/, ' ');
  }

  return XLSX.utils.sheet_to_json<T>(workSheet, {
    raw: false,
    blankrows: false,
  });
}

/**
 * header영역(start row)의 모든 cell에 접근하여 trim을 해준다.
 */
const trimHeaders = (workSheet: XLSX.WorkSheet) => {
  if (!workSheet['!ref']) {
    throw new Error('범위를 알 수 없습니다.');
  }

  const range = XLSX.utils.decode_range(workSheet['!ref']);
  const headers: string[] = [];
  // 첫 행(range.s.r)의 시작 cell(range.s.c)부터 끝 cell(range.e.c)
  for (let C = range.s.c; C <= range.e.c; ++C) {
    const cellAddress = { c: C, r: range.s.r };
    const cellRef = XLSX.utils.encode_cell(cellAddress);
    const cell: XLSX.CellObject = workSheet[cellRef];

    if (cell === undefined) {
      throw new Error(`필드명이 없는 열(${cellRef})이 존재합니다.`);
    }

    if (cell.w) {
      // w: formatted text 영역
      cell.w = cell.w.trim();
      headers.push(cell.w);
    }
  }

  const checkDuplicatedField = headers.filter((item, index) => headers.indexOf(item) !== index);

  if (checkDuplicatedField.length > 0) {
    throw new Error(`중복필드가 존재합니다. ${JSON.stringify(checkDuplicatedField)}`);
  }
};

/**
 * json파일을 엑셀로 변환하여 다운받는다.
 * @param json
 * @param fileName
 */
export function jsonToExcelFile<T>(json: T[], fileName: string, heading?: string[][]) {
  const workBook = XLSX.utils.book_new();
  if (heading) {
    const workSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(workSheet, heading);
    XLSX.utils.sheet_add_json(workSheet, json, { skipHeader: true, origin: 'A2' });
    XLSX.utils.book_append_sheet(workBook, workSheet, 'sheet1');
  } else {
    const workSheet = XLSX.utils.json_to_sheet(json);
    XLSX.utils.book_append_sheet(workBook, workSheet, 'sheet1');
  }
  XLSX.writeFile(workBook, `${fileName}.xlsx`);
}

/**
 * 대상을 순회하며 문자열을 치환한다.
 * @param obj
 * @param target
 * @param replacement
 * @returns
 */
export function deepReplaceStrings(obj: Record<string, any>, target: string | RegExp, replacement: string) {
  return cloneDeepWith(obj, (value) => {
    if (isString(value)) {
      return value.replace(new RegExp(target, 'g'), replacement);
    }
  });
}
