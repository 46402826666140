import { ProductDoc } from '@gooduncles/gu-app-schema';
import { ICellRendererParams } from 'ag-grid-community';
import { notification } from 'antd';
import { FC, ImgHTMLAttributes, useCallback } from 'react';

import { errorObjectToString } from 'src/lib/1/util';
import { deleteProductImage } from 'src/lib/4/firebase-short-cut';

import ImageUploader from '../ImageUploader/ImageUploader';

const ProductImg: FC<ICellRendererParams<ProductDoc> & ImgHTMLAttributes<HTMLImageElement>> = (props) => {
  const thumbnail = props.value;
  const { productId, fullName } = props.data ?? {};
  const path = `product-image/${productId}`;

  const onDeleteImage = useCallback(async () => {
    try {
      await deleteProductImage(path);
      notification.success({
        message: '이미지 삭제 성공',
      });
    } catch (error) {
      console.error(error);
      const description = errorObjectToString(error);
      notification.error({
        message: '이미지 삭제 실패',
        description,
      });
    }
  }, [path]);

  return <ImageUploader path={path} src={thumbnail} alt={fullName} onDeleteImage={onDeleteImage} />;
};

export default ProductImg;
