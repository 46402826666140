/**
 * 토글을 통해 개별 필드에 대한 수정모드를 on/off 할 수 있는 컴포넌트다.
 * 컴포넌트를 더블클릭하면 수정모드로 변경
 * foucus가 해제 또는 esc 키를 누르면 수정모드 해제
 * enter키를 누르면 수정이 완료된다.
 */
import { EditOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Input, Select, Switch, message } from 'antd';
import { FC, useCallback, useRef, useState } from 'react';

import { sleep } from 'src/lib/1/util';

import classes from './ToggleEditor.module.scss';

interface ToggleEditorProps {
  label: string;
  value: string;
  field: string;
  type: 'string' | 'select' | 'boolean';
  isAuthorized: boolean;
  handleOnEdit: (field: string, value: string | boolean) => Promise<void>;
  validation?: (value: string) => Promise<boolean> | boolean;
  /** Select 모드에서만 사용된다. */
  options?: { value: string; label: string }[];
}

const ToggleEditor: FC<ToggleEditorProps> = ({
  label,
  value,
  type,
  isAuthorized,
  handleOnEdit,
  validation,
  options,
  field,
}) => {
  const ref = useRef<any>(null);
  const [onEdit, setOnEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState(value);

  const handleClick = useCallback(() => {
    if (isAuthorized) {
      setOnEdit(true);
      setTimeout(() => ref?.current?.focus());
    } else {
      message.error('수정 권한이 없습니다.');
    }
  }, [isAuthorized]);

  const handleKeyDown = useCallback(
    async (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        const isValid = validation ? await validation(inputValue) : true;
        if (!isValid) {
          message.error('입력값이 올바르지 않습니다.');
          return;
        } else {
          setLoading(true);
          await handleOnEdit(field, inputValue);
          await sleep(2000);
          setLoading(false);
          setOnEdit(false);
          return;
        }
      }
      if (e.key === 'Escape') {
        setOnEdit(false);
        setInputValue(value);
      }
    },
    [field, handleOnEdit, inputValue, validation, value]
  );

  const handleSelectChange = useCallback(
    async (value: string) => {
      if (validation && !validation(value)) {
        message.error('변경할 수 없습니다.');
        return;
      }

      setLoading(true);
      await handleOnEdit(field, value);
      setOnEdit(false);
      setLoading(false);
      return;
    },
    [field, handleOnEdit, validation]
  );

  const handleSwitchChange = useCallback(
    async (value: boolean) => {
      setLoading(true);
      handleOnEdit(field, value);
      setLoading(false);
      return;
    },
    [field, handleOnEdit]
  );

  const handleBlur = useCallback(() => {
    setOnEdit(false);
    setInputValue(value);
  }, [value]);

  const handleDropdownVisibleChange = useCallback(
    (open: boolean) => {
      if (!open) {
        setOnEdit(false);
        setInputValue(value);
      }
    },
    [value]
  );

  return (
    <div className={classes.flexRow}>
      <div className={classes.label}>
        {label}
        {field && <span className={classes.description}>({field})</span>}
      </div>
      {type === 'boolean' ? (
        <div className={classes.value}>
          <Switch
            defaultChecked={value === 'true'}
            disabled={!isAuthorized}
            onChange={handleSwitchChange}
            loading={loading}
          />
        </div>
      ) : (
        <>
          {type === 'string' && (
            <div className={classes.value}>
              <Input
                ref={ref}
                value={inputValue}
                disabled={!onEdit || loading}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={handleKeyDown}
                onBlur={handleBlur}
              />
              {loading && (
                <div className={classes.loading}>
                  <LoadingOutlined />
                </div>
              )}
              {!onEdit && (
                <Button className={classes.onEditButton} type='text' icon={<EditOutlined />} onClick={handleClick} />
              )}
            </div>
          )}
          {type === 'select' && (
            <div className={classes.value} onClick={handleClick}>
              <Select
                ref={ref}
                value={inputValue}
                disabled={!onEdit}
                onBlur={handleBlur}
                onDropdownVisibleChange={handleDropdownVisibleChange}
                options={options}
                onChange={handleSelectChange}
                style={{ width: '100%' }}
                loading={loading}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ToggleEditor;
