export const stringSanitizer = (value: string): string | null => {
  const trimmed = value?.trim();
  return trimmed === '' ? null : trimmed;
};

export const numberSanitizer = (value: string | number): number | null => {
  const num = typeof value === 'string' ? Number(value) : value;
  return isNaN(num) ? null : num;
};

export const booleanSanitizer = (value: string | boolean): boolean => {
  const bool = typeof value === 'string' ? value === 'true' : value;
  return bool;
};

export const arraySanitizer = (value: string | any[], nullable = false): any[] | null => {
  const arr = typeof value === 'string' ? value.split(',').map((s) => s.trim()) : value;
  return nullable && arr.length === 0 ? null : arr;
};
