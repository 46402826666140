import { LeftOutlined, LoadingOutlined } from '@ant-design/icons';
import { DeliverySpotDoc, PartnersUserDoc } from '@gooduncles/gu-app-schema';
import { Button, Select, Switch, Tabs, Tooltip, message } from 'antd';
import { ColorFactory } from 'antd/es/color-picker/color';
import { groupBy } from 'lodash-es';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { v4 } from 'uuid';

import { createClickEventToMarker, createMarker, setMarkerIconClass } from 'src/lib/1/ncp-map-api-util';
import { observeDeliverySpot, updateDeliverySpot } from 'src/lib/4/firebase-short-cut';

import { selectPartnersUsers } from 'src/redux/slices/partners';
import { useAppSelector } from 'src/redux/store';

import useNaverMapApi from 'src/hooks/useNaverMapApi';
import { useTitle } from 'src/hooks/useTitle';

import classes from './DeliverySpotMap.module.scss';

import DeliverySpotMapModal from '../DeliverySpotMapModal/DeliverySpotMapModal';
import DeliverySpotTable from '../DeliverySpotTable/DeliverySpotTable';

type DeliverySpotsForPartner = {
  partner: PartnersUserDoc | null;
  spots: DeliverySpotDoc[];
};

const updateCoord = (id: string) => async (lat: number, lng: number) => {
  try {
    const messageKey = v4();
    message.open({
      type: 'loading',
      content: '배송지 좌표 수정 중...',
      duration: 0,
      key: messageKey,
    });
    await updateDeliverySpot(id, { lat, lng });
    message.destroy(messageKey);
    message.success('배송지 좌표 수정 완료');
  } catch (error) {
    console.error(error);
    message.error('배송지 좌표 수정 실패');
  }
};

/**
 * 배송지를 지도에 표시합니다.
 * 지도: 네이버 지도
 */
const DeliverySpotMap: FC = () => {
  useTitle('GU 관리자 | 배송지 지도');
  const partnersUsers = useAppSelector(selectPartnersUsers);
  const [showTab, setShowTab] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState('all');
  const [showPositiveSortKeySpots, setShowPositiveSortKeySpots] = useState(true);
  const [draggable, setDraggable] = useState(false);
  const [selectedTab, setSelectedTab] = useState<string | null>(null);
  const [selectedDom, setSelectedDom] = useState<HTMLElement | null>(null);
  const [selectedRowId, setSelectedRowId] = useState<string | null>(null);
  const { scriptLoaded, map, markerGroups, setMarkerGroups, triggerClickMarkerBySeq } = useNaverMapApi(
    process.env.REACT_APP_NCP_CLIENT_ID
  );
  const [deliverySpotsForPartnerMap, setDeliverySpotsForPartnerMap] = useState<Record<string, DeliverySpotsForPartner>>(
    {}
  );
  const partnerList = useMemo(
    () =>
      Object.entries(deliverySpotsForPartnerMap)
        .map(([id, v]) => ({
          label: v.partner?.name ?? '미지정',
          value: id,
          sortKey: v.partner?.deliveryDriverStatus?.sortKey ?? 9999,
        }))
        .sort((a, b) => a.sortKey - b.sortKey),
    [deliverySpotsForPartnerMap]
  );
  const selectedSpot = useMemo(() => {
    if (!selectedRowId) return null;
    return (
      Object.values(deliverySpotsForPartnerMap)
        .flatMap((v) => v.spots)
        .find((doc) => doc._id === selectedRowId) ?? null
    );
  }, [deliverySpotsForPartnerMap, selectedRowId]);
  const items = useMemo(() => {
    if (!map) return [];
    return Object.entries(deliverySpotsForPartnerMap)
      .map(([partnerId, mapItem]) => ({
        key: partnerId,
        label: mapItem.partner?.name ?? '미지정',
        sortKey: mapItem.partner?.deliveryDriverStatus?.sortKey ?? 9999,
        children: (
          <DeliverySpotTable
            map={map}
            rowData={mapItem.spots}
            selectedRowId={selectedRowId}
            triggerClickMarkerBySeq={triggerClickMarkerBySeq}
          />
        ),
      }))
      .sort((a, b) => a.sortKey - b.sortKey);
  }, [deliverySpotsForPartnerMap, map, selectedRowId, triggerClickMarkerBySeq]);

  const onTabChange = (key: string) => {
    setSelectedTab(key);
  };

  useEffect(() => {
    const subscription = observeDeliverySpot([['isDeleted', '!=', true]]).subscribe((spots) => {
      const filteredSpots = spots.filter((doc) => (showPositiveSortKeySpots ? doc.sortKey > 0 : true));
      const groupedSpotsByPartnerId = groupBy(filteredSpots, 'partnerId');
      const deliverySpotsForPartner0 = Object.fromEntries(
        Object.entries(groupedSpotsByPartnerId).map(([partnerId, spotList]) => {
          const partner = partnersUsers.find((doc) => doc._id === partnerId);
          return [
            partnerId,
            {
              partner: partner ?? null,
              spots: spotList,
            },
          ];
        })
      );
      setDeliverySpotsForPartnerMap(deliverySpotsForPartner0);
    });
    return () => subscription.unsubscribe();
  }, [showPositiveSortKeySpots, partnersUsers]);

  // 마커를 그린다.
  useEffect(() => {
    if (map && Object.keys(deliverySpotsForPartnerMap).length > 0) {
      const markerGroupList = Object.entries(deliverySpotsForPartnerMap)
        .filter(([partnerId]) => (selectedFilter === 'all' ? true : partnerId === selectedFilter))
        .map(([partnerId, mapItem]) => {
          const hsb = new ColorFactory(mapItem.partner?.deliveryDriverStatus?.color ?? 0).toHsb();
          return {
            // grouping 기준
            title: partnerId,
            hue: hsb.h,
            show: selectedFilter === 'all' ? true : partnerId === selectedFilter,
            markers: mapItem.spots.map(({ _id, lat, lng, sortKey }) =>
              createMarker(
                map,
                lat,
                lng,
                _id,
                {
                  title: mapItem.partner?.name ?? '미지정',
                  body: sortKey,
                  color: hsb.h,
                },
                draggable
              )
            ),
          };
        });

      const addClickEventToMarker = createClickEventToMarker(map);

      markerGroupList.forEach((group) => {
        group.markers.forEach((marker) => {
          // 클릭 이벤트
          const callback = (id: string) => {
            setSelectedTab(group.title);
            setSelectedRowId(id);
            // 마커가 준비되는 시간을 고려하여 순서를 뒤로 미룬다.
            setTimeout(() => {
              const dom = document.getElementById(id);
              if (dom) {
                setSelectedDom(dom);
              }
            }, 100);
          };

          // 드래그 이벤트
          const dragendCallback = updateCoord(marker.get('seq'));
          addClickEventToMarker(marker, callback, dragendCallback);
        });
      });

      setMarkerGroups(markerGroupList);

      return () => {
        markerGroupList.forEach((group) => {
          group.markers.forEach((marker) => {
            naver.maps.Event.clearInstanceListeners(marker);
            marker.setMap(null);
          });
        });
      };
    }
  }, [draggable, deliverySpotsForPartnerMap, map, setMarkerGroups, selectedFilter]);

  useEffect(() => {
    if (selectedRowId) {
      Object.values(markerGroups)
        .flatMap((group) => group.markers)
        .forEach((marker) => {
          const mid = marker.get('seq');
          if (mid === selectedRowId) {
            const icon = marker.getIcon() as naver.maps.HtmlIcon;
            const newContent = setMarkerIconClass(icon.content as string, true);
            marker.setIcon(newContent);
          } else {
            const icon = marker.getIcon() as naver.maps.HtmlIcon;
            const newContent = setMarkerIconClass(icon.content as string, false);
            marker.setIcon(newContent);
          }
        });
    }
  }, [markerGroups, selectedRowId]);

  const closeModal = useCallback(() => {
    setSelectedRowId(null);
    setSelectedDom(null);
  }, []);

  const onShowPositiveSortKeySpotsChange = useCallback(() => {
    setShowPositiveSortKeySpots((prev) => !prev);
  }, []);

  const onDraggableChange = useCallback(() => {
    setDraggable((prev) => !prev);
  }, []);

  const onShowTabChange = useCallback(() => {
    setShowTab((prev) => !prev);
    setTimeout(() => {
      map?.autoResize();
    }, 200);
  }, [map]);

  const onChangeFilter = useCallback((partnerId: string) => {
    setSelectedFilter(partnerId);
  }, []);

  return (
    <div className={classes.deliverySpotContainer}>
      {showTab && (
        <section className={classes.leftSection}>
          <div className={classes.options}>
            <div className={classes.option}>
              <Tooltip title='"활성 배송지"는 지정된 배송 순서가 0보다 큰 배송지입니다.'>활성 배송지만 표시:</Tooltip>
              <Switch checked={showPositiveSortKeySpots} onChange={onShowPositiveSortKeySpotsChange} />
            </div>
            <div className={classes.option}>
              좌표 변경 기능 활성화:
              <Switch checked={draggable} onChange={onDraggableChange} />
            </div>
          </div>
          <Tabs
            type='card'
            style={{ flex: 1, overflow: 'scroll' }}
            tabBarStyle={{ marginBottom: 0 }}
            items={items}
            activeKey={selectedTab ?? items[0]?.key}
            onChange={onTabChange}
          />
        </section>
      )}

      <section className={classes.midSection}>
        <Button
          type='text'
          icon={<LeftOutlined />}
          style={{ height: '100%', transform: showTab ? undefined : 'rotateY(180deg)' }}
          onClick={onShowTabChange}
        />
      </section>

      <section className={classes.rightSection}>
        {!scriptLoaded && (
          <div className={classes.mapLoadingOverlay}>
            <LoadingOutlined style={{ fontSize: '3rem' }} />
          </div>
        )}
        <div id='map' style={{ width: '100%', height: '100%', border: '1px solid #babfc7' }}></div>
        <div className={classes.mapFloatButtons}>
          <Select
            defaultValue={selectedFilter}
            style={{ minWidth: 100 }}
            options={[{ value: 'all', label: '모두보기' }, ...partnerList]}
            onClick={(e) => e.stopPropagation()}
            onChange={onChangeFilter}
          />
        </div>
        {/* 선택한 마커 위에 팝업을 띄운다. */}
        {selectedDom &&
          // https://react.dev/reference/react-dom/createPortal#rendering-a-modal-dialog-with-a-portal
          createPortal(
            <DeliverySpotMapModal deliverySpot={selectedSpot} partnerList={partnerList} closeModal={closeModal} />,
            selectedDom
          )}
      </section>
    </div>
  );
};

export default DeliverySpotMap;
