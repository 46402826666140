import { OrderDoc } from '@gooduncles/gu-app-schema';
import { BareFetcher } from 'swr';
import useImmutableSWR from 'swr/immutable';

import { getDeliveredOrdersWithRange } from 'src/lib/4/firebase-short-cut';

const fetcher: BareFetcher<OrderDoc[]> = ([start, end]: [string, string]) => getDeliveredOrdersWithRange(start, end);

/**
 *
 * @param start
 * @param end 선택적으로 제공하기 위해 null을 추가한다.
 * @returns
 */
const useDeliveredOrdersWithRange = (start: string, end: string | null) => {
  const { data: orders, isValidating } = useImmutableSWR(
    start && end ? [start, end, 'ordersWithRange'] : null,
    fetcher
  );
  return {
    orders,
    isValidating,
  };
};

export default useDeliveredOrdersWithRange;
