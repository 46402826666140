import { Button, Popconfirm, notification } from 'antd';
import { useState } from 'react';

import { errorObjectToString } from 'src/lib/1/util';
import { SupplierDoc } from 'src/lib/3/schema-supplier';
import { updateProduct, updateSupplier } from 'src/lib/4/firebase-short-cut';

/**
 * 매입 상품(발주 규칙) 삭제
 */
export const DeleteConversionItemCellRenderer = (props: any) => {
  const [loading, setLoading] = useState(false);
  const productId: string = props.value;
  const supplierDoc: SupplierDoc = props.data.supplier;
  if (!productId || !supplierDoc) return null;
  const onDeleteSupplierConversionItem = async () => {
    try {
      setLoading(true);
      await updateSupplier(supplierDoc._id, {
        conversionTable: supplierDoc.conversionTable.filter((item) => item.productId !== productId),
      });
      await updateProduct(productId, {
        suppliers: [],
      });
      notification.success({
        message: '매입 상품 삭제 성공',
      });
    } catch (error) {
      console.error(error);
      const errorMessage = errorObjectToString(error);
      notification.error({
        message: '매입 상품 삭제 실패',
        description: errorMessage ?? '알 수 없는 에러 발생!',
      });
    }
    setLoading(false);
  };
  return (
    <Popconfirm
      title='매입 상품을 삭제하시겠습니까?'
      onConfirm={onDeleteSupplierConversionItem}
      okButtonProps={{
        loading,
      }}>
      <Button danger>삭제</Button>
    </Popconfirm>
  );
};
