import { DeliveryTaskDoc } from '@gooduncles/gu-app-schema';
import { Image } from 'antd';
import { FC } from 'react';

import classes from './TaskLocationRow.module.scss';

import OpenDeliverySpotMapButton from '../Button/OpenDeliverySpotMapButton';

interface DeliveryTaskLocationRowProps {
  deliveryTask: DeliveryTaskDoc;
}

const DeliveryTaskLocationRow: FC<DeliveryTaskLocationRowProps> = ({ deliveryTask }) => {
  return (
    <div className={classes.taskLocationRowContainer}>
      <div className={classes.locationCard}>
        <div className='flexColumn'>
          <span>배송 위치</span>
          <p>{deliveryTask.locationName}</p>
        </div>
        <OpenDeliverySpotMapButton deliverySpotId={deliveryTask.locationGroupId} />
      </div>
      <div className={classes.deliveryImageList}>
        {deliveryTask.deliveryImages && (
          <Image.PreviewGroup>
            {deliveryTask.deliveryImages.map((image, index) => (
              <Image
                key={index}
                width={78}
                height={78}
                style={{ objectFit: 'cover', borderRadius: 4 }}
                src={image.thumbnail.url}
                preview={{
                  src: image.original.url,
                }}
              />
            ))}
          </Image.PreviewGroup>
        )}
      </div>
    </div>
  );
};

export default DeliveryTaskLocationRow;
