import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import ProductPriceMappingPage from 'src/pages/ProductPage/ProductPriceMappingPage';

import Product from 'src/components/Product/Product';
import ProductStock from 'src/components/ProductStock/ProductStock';

import ProductCompetitorTrendPage from './ProductCompetitorTrendPage';
import ProductMarketTrendPage from './ProductMarketTrendPage';
import ProductStockIssuePage from './ProductStockIssuePage';
import ProductUploadPage from './ProductUploadPage';

const ProductPage: FC = () => {
  return (
    <Routes>
      <Route path='/list' element={<Product />} />
      <Route path='/upload' element={<ProductUploadPage title='상품 수정/추가' />} />
      <Route path='/stock' element={<ProductStock />} />
      <Route path='/stock-issue' element={<ProductStockIssuePage />} />
      <Route path='/price-mapping' element={<ProductPriceMappingPage />} />
      <Route path='/market-price-trend' element={<ProductMarketTrendPage />} />
      <Route path='/competitor-price-trend' element={<ProductCompetitorTrendPage />} />
      <Route path='*' element={<Navigate to='./list' replace />} />
    </Routes>
  );
  // return <Product />;
};

export default ProductPage;
