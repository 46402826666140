import { RowClassParams } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { ColDef, GridApi, RowNode } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { notification } from 'antd';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { StoreCategory } from 'src/lib/1/constant';
import { errorObjectToString, formatNumber } from 'src/lib/1/util';
import {
  ProductGroupForCategoryDoc,
  ProductGroupForTag,
  ProductInfoForTag,
} from 'src/lib/3/schema-product-group-by-category';
import { mergeProductGroupForCategory } from 'src/lib/4/firebase-short-cut';
import { ConsoleLogger } from 'src/lib/5/logger';

import ProductName from 'src/components/Common/ProductName';

import TagHeaderWithSortButton from './TagHeaderWithSortButton/TagHeaderWithSortButton';
import { ToogleProductCell } from './ToggleProductCell/ToggleProductCell';

const logger = ConsoleLogger.getInstance();
const logName = '업종 관리';

interface StoreCategoryTableProps {
  category: StoreCategory;
  rowData: ProductGroupForCategoryDoc | undefined;
  loading: boolean;
}

const defaultColDef: ColDef<ProductGroupForTag> = {
  flex: 1,
  sortable: true,
  resizable: true,
  filter: true,
};

/**
 * 판매기록이 있는 상품과 그렇지 않은 상품을 구분하기 위한 스타일
 */
const getProductRowStyle = (params: RowClassParams<ProductInfoForTag>) => {
  const { hasSales, hide } = params.node.data ?? {};
  return {
    ...(!hasSales ? {} : { color: 'var(--blue400)' }),
    ...(hide ? { backgroundColor: 'var(--gray200)' } : {}),
  };
};

/**
 * 현재 노출되는 상품과 그렇지 않은 상품을 구분하기 위한 스타일
 */
const getRowStyle = (maxItemsToShow?: number) =>
  maxItemsToShow
    ? (params: RowClassParams<ProductGroupForTag>) => {
        if (params.node.master && params.rowIndex + 1 > maxItemsToShow) {
          return { backgroundColor: 'var(--gray200)' };
        }
      }
    : undefined;

/**
 * @description 그리드에서 드래그가 끝난 후 호출되는 콜백
 */
const onRowDragEnd = async (params: any) => {
  const category = params?.context.category;
  const chagnedNodes: RowNode[] = [];
  (params?.api as GridApi)?.forEachNode((node) => {
    const { rowIndex, data } = node;
    // 1. 순서가 변경된 노드 즉 rowIndex + 1과 data.index가 다른 노드만 추출한다.
    if (rowIndex !== null && rowIndex + 1 !== data?.index) {
      chagnedNodes.push(node);
    }
  });

  if (!chagnedNodes || chagnedNodes?.length === 0) {
    return;
  }

  try {
    // 2. master는 업종이고, detail은 상품이다.
    if (params.node.master) {
      const updateData: { [mainTag: string]: any } = Object.fromEntries(
        chagnedNodes
          ?.filter((node) => node.rowIndex !== null && node.data?.mainTag)
          .map((node) => [node.data?.mainTag, { index: (node.rowIndex ?? 999) + 1 }])
      );
      await mergeProductGroupForCategory(category, {
        mainTags: {
          ...updateData,
        },
      });
      logger.logConsole(`${logName} - ${category} 상품군 순서 변경`);
    } else {
      const mainTag: string = params.node.data.mainTag;
      const updateData: {
        [productId: string]: any;
      } = Object.fromEntries(
        chagnedNodes
          ?.filter((node) => node.rowIndex !== null && node.data?.productId)
          .map((node) => [node.data?.productId, { index: (node.rowIndex ?? 999) + 1 }])
      );
      await mergeProductGroupForCategory(category, {
        mainTags: {
          [mainTag]: {
            productsForMainTag: {
              ...updateData,
            },
          },
        },
      });
      logger.logConsole(`${logName} - ${category}/${mainTag} 상품 순서 변경`);
    }
  } catch (error) {
    console.error(error);
    const description = errorObjectToString(error);
    logger.logConsole(`${logName} - ${category} 순서 변경 실패\nerror: ${description}`, {
      level: 'error',
    });
    notification.error({
      message: '순서 변경에 실패했습니다.',
      description,
    });
  }
};

const StoreCategoryTable: FC<StoreCategoryTableProps> = ({ category, rowData, loading }) => {
  // 데이터가 변경되어도 현재 상태를 유지하려면 id는 필수로 지정해줘야한다.
  const processedRowData = useMemo(
    () =>
      rowData
        ? Object.values(rowData.mainTags)
            .sort((a, b) => a.index - b.index)
            .map((tag) => ({
              ...tag,
              id: category + tag.mainTag,
            }))
        : [],
    [category, rowData]
  );
  const [gridRef, setGridRef] = useState<AgGridReact<ProductGroupForTag> | null>(null);

  /** 상품군 목록 */
  const columnDefs: ColDef<ProductGroupForTag>[] = useMemo(
    () => [
      {
        field: 'index',
        headerName: '순서',
        maxWidth: 108,
        rowDrag: true,
      },
      {
        field: 'mainTag',
        headerName: '상품군',
        cellRenderer: 'agGroupCellRenderer',
      },
      {
        field: 'productsForMainTag',
        headerName: '판매됨 / 전체',
        valueGetter: ({ data }) => {
          if (!data) return null;
          const productList = Object.values(data.productsForMainTag);
          const hasSalesLength = productList.filter((v) => v.hasSales).length;
          return `${hasSalesLength} / ${productList.length}`;
        },
      },
      {
        field: 'maxItemsToShow',
        headerName: '최대 노출 수',
      },
      {
        field: 'totalVolume',
        headerName: '판매량',
        valueFormatter: ({ value }) => formatNumber(value),
      },
      {
        field: 'totalAmount',
        headerName: '판매금액',
        valueFormatter: ({ value }) => formatNumber(value),
      },
    ],
    []
  );

  /** 상품 목록 */
  const detailCellRendererParams = useCallback(
    (params0: any) => {
      const detailColumnDefs: ColDef<ProductInfoForTag>[] = [
        {
          field: 'index',
          headerName: '순서',
          maxWidth: 108,
          rowDrag: true,
        },
        {
          field: 'productId',
          headerName: '코드',
          maxWidth: 96,
        },
        {
          field: 'productId',
          headerName: '상품명',
          cellRenderer: (params: any) => <ProductName productId={params.value} />,
        },
        {
          field: 'totalVolume',
          minWidth: 130,
          headerComponent: () => (
            <TagHeaderWithSortButton
              field='판매량'
              category={params0.context.category}
              mainTag={params0.data.mainTag}
              productsForMainTag={params0.data.productsForMainTag}
            />
          ),
          valueFormatter: ({ value }) => formatNumber(value),
        },
        {
          field: 'totalAmount',
          minWidth: 160,
          headerComponent: () => (
            <TagHeaderWithSortButton
              field='판매금액'
              category={params0.context.category}
              mainTag={params0.data.mainTag}
              productsForMainTag={params0.data.productsForMainTag}
            />
          ),
          valueFormatter: ({ value }) => formatNumber(value),
        },
        {
          field: 'hide',
          headerName: '숨김',
          maxWidth: 120,
          pinned: 'right',
          cellRenderer: (params: any) => <ToogleProductCell {...params} />,
        },
      ];

      return {
        refreshStrategy: 'rows',
        detailGridOptions: {
          columnDefs: detailColumnDefs,
          defaultColDef,
          onFirstDataRendered: (params: any) => {
            params.api.sizeColumnsToFit();
          },
          onRowDragEnd,
          suppressScrollOnNewData: true,
          rowDragManaged: true,
          rowDragMultiRow: true,
          alwaysShowHorizontalScroll: true,
          rowSelection: 'multiple',
          suppressMoveWhenRowDragging: true,
          context: { category },
          getRowStyle: getProductRowStyle,
        },
        getDetailRowData: (params: any) => {
          const productGroup = params.data as ProductGroupForTag;
          const data = Object.values(productGroup.productsForMainTag)
            .sort((a, b) => a.index - b.index)
            .map((product) => ({
              ...product,
              mainTag: productGroup.mainTag,
              id: params.context.category + productGroup.mainTag + product.productId,
            }));
          params.successCallback(data);
        },
      };
    },
    [category]
  );

  const autoSizeAll = useCallback(() => {
    gridRef?.columnApi?.autoSizeAllColumns(true);
  }, [gridRef]);

  // 로딩중일때 로딩오버레이를 보여준다.
  useEffect(() => {
    if (loading) {
      gridRef?.api?.showLoadingOverlay();
    } else {
      gridRef?.api?.hideOverlay();
    }
  }, [gridRef?.api, loading]);

  return (
    <div className='ag-theme-alpine height100' style={{ display: 'flex', flexDirection: 'column' }}>
      <AgGridReact
        ref={setGridRef}
        rowData={processedRowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        onGridReady={autoSizeAll}
        context={{ category }}
        // rowId를 지정해주지 않으면 데이터 변경시 refresh가 발생한다.// rowId를 지정해주지 않으면 자꾸 새로그린다.
        getRowId={(params) => (params.data as any).id}
        // 편집 완료후 스크롤 이동을 막는다.
        suppressScrollOnNewData={true}
        rowDragManaged={true}
        rowDragMultiRow={true}
        alwaysShowHorizontalScroll={true}
        rowSelection={'multiple'}
        onRowDragEnd={onRowDragEnd}
        suppressMoveWhenRowDragging={true}
        // 하위노드
        keepDetailRows={true}
        masterDetail={true}
        detailRowAutoHeight={true}
        detailCellRendererParams={detailCellRendererParams}
        getRowStyle={getRowStyle(rowData?.maxItemsToShow)}
      />
    </div>
  );
};

export default StoreCategoryTable;
