import { StoreDoc } from '@gooduncles/gu-app-schema';
import { useEffect, useState } from 'react';

import { WHERE } from 'src/lib/3/firebase-manager';
import { getStores } from 'src/lib/4/firebase-short-cut';

const useStores = (wheres?: WHERE[]) => {
  const [stores, setStores] = useState<StoreDoc[]>();

  useEffect(() => {
    getStores(wheres).then((stores) => {
      setStores(stores);
    });
  }, [wheres]);
  return stores;
};

export default useStores;
