import { DndCardDragResult, DndCardItem } from 'src/schema/schema-drag-and-drop';
import { createAction } from 'typesafe-actions';

export const setItems = createAction('kanban-board/SET_ITEMS')<DndCardItem[]>();
export const setInsertGroupId = createAction('kanban-board/SET_INSERT_GROUP_ID')<string>();
export const setInsertIndex = createAction('kanban-board/SET_INSERT_INDEX')<number>();
export const setLastSelectedIndex = createAction('kanban-board/SET_LAST_SELECTED_INDEX')<number>();
export const setHoverIndex = createAction('kanban-board/SET_HOVER_INDEX')<number>();
export const setSelectedItems = createAction('kanban-board/SET_SELECTED_ITEMS')<DndCardItem[]>();
export const setShowDetail = createAction('kanban-board/SET_SHOW_DETAIL')<boolean>();
export const clearSelection = createAction('kanban-board/CLEAR_SELECTION')();
export const setInsertPosition = createAction('kanban-board/SET_INSERT_POSITION')<{
  groupId: string;
  hoverIndex: number;
  insertIndex: number;
}>();

export const changeSelectedItems = createAction('kanban-board/CHANGE_SELECTED_ITEMS')<{
  index: number;
  groupId: string;
  cmdKey: boolean;
  shiftKey: boolean;
}>();
export const moveItems = createAction('kanban-board/MOVE_ITEMS')<DndCardDragResult>();
