import { StoreDoc } from '@gooduncles/gu-app-schema';
import { FC, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useSettlementByStore from 'src/hooks/useSettlementByStore';
import useStoresForSearch from 'src/hooks/useStoresForSearch';
import { useTitle } from 'src/hooks/useTitle';

import Loading from 'src/components/Loading/Loading';
import SettlementByStoreTable from 'src/components/NewSettlement/SettlementByStoreTable/SettlementByStoreTable';
import StoreSelector from 'src/components/Selector/StoreSelector';

const SettlementByStorePage: FC = () => {
  useTitle('GU 관리자 | 정산관리 - 매장별 내역');
  const { code } = useParams();
  const navigate = useNavigate();
  const selectedStoreCode = code || null;
  const { stores } = useStoresForSearch();
  const selectedStore: StoreDoc | null = useMemo(() => {
    return selectedStoreCode ? (stores ?? []).find((store) => store.storeCode === selectedStoreCode) ?? null : null;
  }, [stores, selectedStoreCode]);
  const { rowData, isLoading } = useSettlementByStore(selectedStore?._id);

  const handleSetSelectedStoreId = (value: string | null) => {
    if (value === null) {
      navigate('/settlement/store');
      return;
    }
    navigate(`/settlement/store/${value}`);
  };

  return (
    <div className='flexColumn height100'>
      <section className='flexRow'>
        <div style={{ width: 400 }}>
          <StoreSelector selectedStoreCode={selectedStoreCode} setSelectedStoreCode={handleSetSelectedStoreId} />
        </div>
      </section>
      <section className='height100'>
        {isLoading && <Loading title='잠시만 기다려주세요.' />}
        {selectedStore && <SettlementByStoreTable rowData={rowData} />}
      </section>
    </div>
  );
};

export default SettlementByStorePage;
