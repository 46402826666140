import { DeliveryPerformanceDoc } from '@gooduncles/gu-app-schema';
import { AgCartesianSeriesOptions, AgChartOptions } from 'ag-charts-community';
import { AgChartsReact } from 'ag-charts-react';
import { groupBy } from 'lodash-es';
import { FC, useEffect, useState } from 'react';

import { formatNumber } from 'src/lib/1/util';

type SeriesYKey = 'OrderAmount' | 'SpotCount' | 'StoreCount';
const seriesYKeyMap: Record<SeriesYKey, string> = {
  OrderAmount: '배송금액',
  SpotCount: '배송스팟',
  StoreCount: '배송매장',
};

interface DeliveryPerformanceChartProps {
  rowData: DeliveryPerformanceDoc[];
  seriesYKey: 'OrderAmount' | 'SpotCount' | 'StoreCount';
}

const initialLineChartOptions = (rowData: DeliveryPerformanceDoc[], seriesYKey: SeriesYKey) => {
  const groupByCourier = groupBy(rowData, (d) => {
    return d.courier?.name ?? d.partner?.nickname ?? '알 수 없음';
  });
  const data = Object.entries(groupByCourier)
    .flatMap(([name, docs]) => {
      return docs.map((doc) => {
        const partnerId = doc.courierId ?? doc.partnerId;
        return {
          date: doc.date,
          name,
          partnerId,
          [partnerId + 'OrderAmount']: doc.totalDeliveryOrderAmount,
          [partnerId + 'SpotCount']: doc.deliverySpotCount,
          [partnerId + 'StoreCount']: doc.deliveryStoreCount,
        };
      });
    })
    .sort((a, b) => a.date.localeCompare(b.date))
    .sort((a, b) => a.partnerId.localeCompare(b.partnerId));
  const series: AgCartesianSeriesOptions[] = Object.entries(groupBy(data, 'name')).map(([name, docs]) => {
    return {
      type: 'line',
      xKey: 'date',
      yKey: `${docs[0].partnerId}${seriesYKey}`,
      yName: name,
    };
  });

  const options: AgChartOptions = {
    autoSize: true,
    data,
    theme: {
      overrides: {
        line: {
          series: {
            highlightStyle: {
              series: {
                strokeWidth: 3,
                dimOpacity: 0.2,
              },
            },
          },
        },
      },
    },
    title: {
      text: '일간 배송 실적',
      fontSize: 18,
    },
    series,
    axes: [
      {
        position: 'bottom',
        type: 'category',
      },
      {
        position: 'left',
        type: 'number',
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        keys: series.map((s) => s.yKey!),
        title: {
          text: seriesYKeyMap[seriesYKey],
        },
        label: {
          formatter: (params) => {
            if (seriesYKey === 'OrderAmount') {
              return `${formatNumber(params.value / 10000)}만원`;
            }
            return params.value;
          },
        },
      },
    ],
    legend: {
      position: 'bottom',
    },
  };
  return options;
};

const DeliveryPerformanceChart: FC<DeliveryPerformanceChartProps> = ({ rowData, seriesYKey }) => {
  const [chartOptions, setChartOptions] = useState<AgChartOptions>({});
  useEffect(() => {
    setChartOptions(initialLineChartOptions(rowData, seriesYKey));
  }, [rowData, seriesYKey]);
  return <AgChartsReact options={chartOptions} />;
};

export default DeliveryPerformanceChart;
