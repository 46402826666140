import { CSSProperties, FC, PropsWithChildren } from 'react';

const style: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  border: '1px solid #babfc7',
  borderBottom: 'none',
  color: '#474747',
  backgroundColor: '#fafafa',
  padding: 10,
  gap: 10,
};

type TableHeaderProps = {
  justifyContent?: CSSProperties['justifyContent'];
};

const TableHeader: FC<PropsWithChildren<TableHeaderProps>> = ({ children, justifyContent }) => {
  return <div style={{ ...style, justifyContent }}>{children}</div>;
};

export default TableHeader;
