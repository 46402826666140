import { Button, DatePicker, message } from 'antd';
import { format } from 'date-fns';
import { FC, useCallback, useMemo, useState } from 'react';
import { callGetSeoulStores } from 'src/utils/firebase-callable';
import * as XLSX from 'xlsx';

import { RangeEventValue, dayjsesToDateStringForSeoulStore, getDefaultDates } from 'src/lib/1/date-util';
import { SeoulStoreResponse } from 'src/lib/1/schema-seoul-store';

const { RangePicker } = DatePicker;

const defaultValue = getDefaultDates(9, 0);

const parseSeoulStoresToExcel = async (fileName: string, data: SeoulStoreResponse[]) => {
  const parsed = data.map((item) => {
    const apvpermymd = item['APVPERMYMD'];
    const sitewhladdr = item['SITEWHLADDR'];
    const date = new Date(apvpermymd);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const dateToNumber = Number(format(date, 'yyyyMMdd'));
    return {
      인허가연도: year,
      인허가월: month,
      인허가일: day,
      인허가일자: dateToNumber,
      지역구: sitewhladdr.split(' ')[1],
      행정동: sitewhladdr.split(' ')[2],
      지번주소: sitewhladdr,
      도로명주소: item['RDNWHLADDR'],
      사업장명: item['BPLCNM'],
      업태구분명: item['UPTAENM'],
    };
  });

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(parsed);
  XLSX.utils.book_append_sheet(workbook, worksheet, 'sheet1');
  XLSX.writeFile(workbook, `${fileName}.xlsx`);
};

const SeoulStoreList: FC = () => {
  const [dates, setDates] = useState<RangeEventValue>(null);
  const [loading, setLoading] = useState(false);
  const [from, to] = useMemo(() => {
    return dates?.[0] && dates?.[1]
      ? dayjsesToDateStringForSeoulStore(dates[0], dates[1])
      : dayjsesToDateStringForSeoulStore(defaultValue[0], defaultValue[1]);
  }, [dates]);

  const downloadSeoulStores = useCallback(async () => {
    setLoading(true);
    try {
      const response = await callGetSeoulStores({
        from,
        to,
      });
      if (response.data.data) {
        parseSeoulStoresToExcel(`서울시 인허가음식점 ${from} ~ ${to}`, response.data.data);
      } else {
        throw new Error(response.data.reason ?? '서울시 인허가음식점 데이터를 받아오는데 실패했습니다.');
      }
    } catch (error: any) {
      console.error(error);
      message.error(error.message);
    }
    setLoading(false);
  }, [from, to]);

  return (
    <div className='tabBody'>
      <h3>서울시 인허가음식점 정보 엑셀 다운로드</h3>
      <p style={{ marginTop: 8 }}>
        서울시 인허가음식점 정보를 엑셀로 다운로드합니다.
        <br />
        기간을 선택하고 다운로드 버튼을 눌러주세요.
      </p>
      <div style={{ marginTop: 16 }}>
        <RangePicker defaultValue={defaultValue} onCalendarChange={(val) => setDates(val)} />
        <Button loading={loading} onClick={downloadSeoulStores} style={{ marginLeft: 8 }}>
          다운로드
        </Button>
      </div>
    </div>
  );
};

export default SeoulStoreList;
