import { Popover } from 'antd';

const MobileMessageContentPopover = (props: any) => {
  const body: string = props.value;
  return (
    <Popover
      placement='rightTop'
      color='#464646'
      overlayInnerStyle={{ backgroundColor: '#464646' }}
      content={
        <div style={{ whiteSpace: 'pre-wrap', lineHeight: 1.5 }}>
          <p style={{ color: 'white', fontWeight: 600 }}>{body}</p>
        </div>
      }>
      <span>{body}</span>
    </Popover>
  );
};

export default MobileMessageContentPopover;
