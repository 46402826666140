import { FC } from 'react';
import { PackingSheet } from 'src/schema/schema-packing-sheet';

import classes from './PackingSheetTable.module.scss';

interface PackingSheetTableProps {
  packingSheet: PackingSheet;
}

const PackingSheetTable: FC<PackingSheetTableProps> = ({ packingSheet: { deliverySpots } }) => {
  // 1. order와 storeIssue를 개별 item으로 병합 및 변환한다.
  return (
    <div className={classes.packingSheetContainer}>
      {deliverySpots.map((spot) => {
        return (
          <div key={spot.deliverySpotId}>
            <div className={classes.deliverySpot}>
              <div className={classes.spotTitle}>{spot.title}</div>
              {spot.stores.map((store) => {
                return (
                  <div key={store.storeId} className={classes.storeCard}>
                    <h2 className={classes.storeName}>{store.storeName}</h2>
                    <div className={classes.gridContainer}>
                      <div className={classes.gridItem}>
                        {store.deliveryItems
                          .filter((i) => i.placement === 'indoors')
                          .map((item) => {
                            return (
                              <div key={item.label} className={`${classes.flexContainer} ${classes.indoors}`}>
                                <div className={`${classes.itemLabel} ${item.storage ? classes[item.storage] : ''}`}>
                                  <span className={classes.label}>{item.label}</span>
                                  {item.productFeature && (
                                    <span className={classes.productFeature}>{item.productFeature}</span>
                                  )}
                                </div>
                                <div className={classes.itemQuantity}>{item.quantity}</div>
                              </div>
                            );
                          })}
                      </div>
                      <div className={classes.gridItem}>
                        {store.deliveryItems
                          .filter((i) => i.placement === 'outdoors')
                          .map((item) => {
                            return (
                              <div key={item.label} className={classes.flexContainer}>
                                <div className={`${classes.itemLabel} ${item.storage ? classes[item.storage] : ''}`}>
                                  <span className={classes.label}>{item.label}</span>
                                  {item.productFeature && (
                                    <span className={classes.productFeature}>{item.productFeature}</span>
                                  )}
                                </div>
                                <div className={classes.itemQuantity}>{item.quantity}</div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className={classes.divider} />
          </div>
        );
      })}
    </div>
  );
};

export default PackingSheetTable;
