import { LoadingOutlined } from '@ant-design/icons';
import { Button, Modal, message, notification } from 'antd';
import { HttpsCallableResult } from 'firebase/functions';
import { FC, ForwardRefRenderFunction, createRef, forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { callSendMobileMessage } from 'src/utils/firebase-callable';

import { errorObjectToString } from 'src/lib/1/util';
import { SMSPostRequest } from 'src/lib/2/schema-nhn-notification';
import { CallSendMobileMessageResponse } from 'src/lib/3/schema-on-call';
import { ConsoleLogger } from 'src/lib/5/logger';

import classes from './SendSmsRequestModal.module.scss';

const logger = ConsoleLogger.getInstance();
const logName = '발주서 문자 일괄 발송';

interface RequestItemProps {
  request: SMSPostRequest;
}

interface RequestItemHandle {
  result: HttpsCallableResult<CallSendMobileMessageResponse> | null | undefined;
  send: () => Promise<HttpsCallableResult<CallSendMobileMessageResponse> | null>;
}

const RequestItem: ForwardRefRenderFunction<RequestItemHandle, RequestItemProps> = ({ request }, forwardRef) => {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<HttpsCallableResult<CallSendMobileMessageResponse> | null>();

  useImperativeHandle(forwardRef, () => ({
    send: async () => {
      setLoading(true);
      try {
        const result = await callSendMobileMessage(request);
        setResult(result);
        return result;
      } catch (err) {
        const errorMsg = errorObjectToString(err);
        message.error(errorMsg);
        setResult(null);
        return null;
      } finally {
        setLoading(false);
      }
    },
    result,
  }));

  return (
    <div>
      {request.recipientList.map((recipient, idx) => {
        return (
          <div key={`recipient-${idx}`} className={classes.recipient}>
            <p>{recipient.recipientNo}</p>
            <p>{recipient.recipientGroupingKey}</p>
            <p>
              {loading ? (
                <LoadingOutlined style={{ color: 'var(--blue400)' }} />
              ) : result ? (
                result.data.result === 'success' ? (
                  '🟢'
                ) : (
                  '❌'
                )
              ) : (
                '-'
              )}
            </p>
          </div>
        );
      })}
    </div>
  );
};

const RequestItemWithRef = forwardRef(RequestItem);

interface SendSmsRequestModalProps {
  requests: SMSPostRequest[];
}

const SendSmsRequestModal: FC<SendSmsRequestModalProps> = ({ requests }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const requestRefs = requests.map(() => createRef<RequestItemHandle>());
  const requestCount = requests.flatMap((request) => request.recipientList).length;
  const waitingCount = requestRefs.filter((request) => request.current?.result === undefined).length;

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleOk = useCallback(async () => {
    setLoading(true);
    const promises = requestRefs.map(async (ref) => {
      try {
        await ref.current?.send();
      } catch (error) {
        console.error(error);
        const description = errorObjectToString(error);
        notification.error({
          message: '발송에 실패했습니다.',
          description,
        });
        logger.logConsole(`[${logName}] ${description}`, {
          level: 'error',
        });
      }
    });
    await Promise.all(promises);
    setLoading(false);
  }, [requestRefs]);

  return (
    <>
      <Button type='primary' onClick={showModal} disabled={requests.length === 0}>
        문자 일괄 발송
      </Button>
      <Modal
        title={`문자 일괄 발송 - ${requestCount}건`}
        open={open}
        okText='일괄 발송'
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText='닫기'
        okButtonProps={{ loading, disabled: requests.length === 0 || waitingCount === 0 }}
        cancelButtonProps={{ loading }}
        maskClosable={false}
        bodyStyle={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}
        destroyOnClose>
        <div className={classes.recipientHeader}>
          <p>수신번호</p>
          <p>수신자</p>
          <p>결과</p>
        </div>
        {requests.map((request, idx) => (
          <RequestItemWithRef key={`request-${idx}`} ref={requestRefs[idx]} request={request} />
        ))}
      </Modal>
    </>
  );
};

export default SendSmsRequestModal;
