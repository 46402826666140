import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import { HyosungCMSMemberDoc, InvoiceDoc } from '@gooduncles/gu-app-schema';
import { Button, Popconfirm, notification } from 'antd';
import { partition } from 'lodash-es';
import { FC, useCallback, useEffect, useState } from 'react';
import { combineLatest } from 'rxjs';
import {
  callCreateInvoiceDetail,
  deleteInvociesWithBatch,
  downloadHyosungCmsTransactions,
} from 'src/utils/payment-util';

import { errorObjectToString } from 'src/lib/1/util';
import { observeInvoices, observePaymentItem } from 'src/lib/4/firebase-short-cut';
import { ConsoleLogger } from 'src/lib/5/logger';

import useHyosungCMSMembers from 'src/hooks/useHyosungCMSMembers';
import useStores from 'src/hooks/useStores';

import NewUnsettledInvoiceTable, {
  NewUnsettledInvoiceTableRowData,
} from 'src/components/NewSettlement/InvoiceTable/NewUnsettledInvoiceTable';

const logger = ConsoleLogger.getInstance();

const NewUnsettledInvoicePage: FC = () => {
  const stores = useStores();
  const hyosungCMSMembers = useHyosungCMSMembers();
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState<NewUnsettledInvoiceTableRowData[]>([]);
  const [selectedRows, setSelectedRows] = useState<NewUnsettledInvoiceTableRowData[]>([]);

  const handleSendInvoice = useCallback(async () => {
    try {
      setLoading(true);
      const invoiceIds = selectedRows.map((row) => row._id);
      if (invoiceIds.length === 0) return;
      await callCreateInvoiceDetail({ invoiceIds });
      notification.success({ message: '청구서 상세 내역 발송 성공' });
    } catch (error) {
      console.error(error);
      const description = errorObjectToString(error);
      notification.error({ message: '청구서 상세 내역 발송 실패', description });
    }
    setLoading(false);
  }, [selectedRows]);

  const onDeleteInvocies = useCallback(async () => {
    try {
      setLoading(true);
      await deleteInvociesWithBatch(selectedRows);
      setSelectedRows([]);
    } catch (error) {
      const description = errorObjectToString(error);
      notification.error({
        message: '삭제 실패',
        description,
      });
    }
    setLoading(false);
  }, [selectedRows]);

  const handleDownloadHyosungCmsTransactions = () => {
    try {
      if (!hyosungCMSMembers || hyosungCMSMembers.length === 0) {
        throw new Error('효성 CMS 회원 정보가 없습니다.');
      }
      if (!stores || stores.length === 0) {
        throw new Error('매장 정보가 없습니다.');
      }
      const data = invoices
        .filter((invoice) => invoice.paymentInfo.paymentVendor === '효성CMS')
        .map((invoice) => {
          const storeCode = stores.find((store) => store._id === invoice.storeId)?.storeCode;
          const hyosungCMSMember = hyosungCMSMembers.find((member) => member.storeCode === storeCode);
          return {
            invoice,
            hyosungCMSMember,
          };
        });
      const [withHyosungCMSMember, withoutHyosungCMSMember] = partition(data, (i) => i.hyosungCMSMember) as [
        { invoice: InvoiceDoc; hyosungCMSMember: HyosungCMSMemberDoc }[],
        { invoice: InvoiceDoc; hyosungCMSMember: undefined }[]
      ];
      if (withoutHyosungCMSMember.length > 0) {
        const invoiceIds = withoutHyosungCMSMember.map((i) => i.invoice._id);
        logger.logConsole(`효성 CMS 회원 정보가 없는 매장의 청구내역이 있습니다. ${invoiceIds.join(', ')}`, {
          level: 'error',
        });
        notification.error({
          message: '효성 CMS 회원 정보가 없는 매장의 청구내역이 있습니다.',
          description: invoiceIds.join(', '),
        });
      }
      notification.success({ message: `${withHyosungCMSMember.length}개의 목록을 다운로드합니다.` });
      downloadHyosungCmsTransactions(withHyosungCMSMember);
    } catch (error) {
      console.error(error);
      const description = errorObjectToString(error);
      notification.error({
        message: '다운로드 실패',
        description,
      });
    }
  };

  useEffect(() => {
    if (stores) {
      const paymentItemsObservable = observePaymentItem([
        ['settlementResultId', '==', null],
        ['deletedAt', '==', null],
      ]);
      const invoicesObservable = observeInvoices([
        ['settledAt', '==', null],
        ['deletedAt', '==', null],
      ]);

      const subscription = combineLatest([paymentItemsObservable, invoicesObservable]).subscribe(
        ([paymentItems0, invoices]) => {
          const invoicesWithStore = invoices.map((invoice) => {
            const store = stores.find((store) => store._id === invoice.storeId);
            const paymentItems = paymentItems0.filter((paymentItem) => paymentItem.storeId === invoice.storeId);
            return {
              ...invoice,
              storeNickname: store?.storeNickname || null,
              paymentItems,
            };
          });
          setInvoices(invoicesWithStore);
        }
      );

      return () => {
        subscription.unsubscribe();
      };
    }
  }, [stores]);

  return (
    <div className='tabBody flexColumn height100'>
      <section className='flexRow flexSpaceBetween'>
        <div className='flexRow'>
          <Button
            disabled={invoices.length <= 0}
            onClick={handleDownloadHyosungCmsTransactions}
            icon={<DownloadOutlined />}>
            효성 CMS 청구내역 다운로드
          </Button>
          <Button disabled={selectedRows.length <= 0} loading={loading} onClick={handleSendInvoice}>
            {selectedRows.length}개 청구서 발송 목록 생성
          </Button>
        </div>
        <div className='flexRow'>
          <Popconfirm title='삭제를 진행하시겠습니까?' onConfirm={onDeleteInvocies} okButtonProps={{ loading }}>
            <Button icon={<DeleteOutlined />} danger loading={loading} disabled={selectedRows.length <= 0}>
              {`${selectedRows.length}개`}항목 삭제
            </Button>
          </Popconfirm>
        </div>
      </section>
      <section className='height100'>
        <NewUnsettledInvoiceTable rowData={invoices} setSelectedRows={setSelectedRows} />
      </section>
    </div>
  );
};

export default NewUnsettledInvoicePage;
