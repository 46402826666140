import { LoadingOutlined, SendOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, message } from 'antd';
import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { requiredRule } from '../../lib/1/constant';
import { FirebaseManager } from '../../lib/3/firebase-manager';

import { useAuth } from '../../stores/auth-context';
import classes from './FindPassword.module.scss';

const { Item } = Form;

type SigninFormValues = {
  email: string;
  password: string;
};

const firebaseManager = FirebaseManager.getInstance();

const FindPassword: FC<{ backToSignin: () => void }> = ({ backToSignin }) => {
  const { authLoading: loading, setAuthLoading: setLoading, authUser } = useAuth();
  const navigate = useNavigate();

  const onFinish = useCallback(
    async (values: SigninFormValues) => {
      const { email } = values;

      setLoading(true);
      try {
        await firebaseManager.sendPasswordResetEmail(email);
        Modal.confirm({
          title: '이메일이 발송되었습니다.',
          content: (
            <p>
              비밀번호 재설정후 로그인해주세요.
              <br />※ 미수신시 스팸메일함을 확인해주세요.
            </p>
          ),
          onOk: () => navigate('/signin'),
          okText: '확인',
          okCancel: false,
        });
      } catch (error: any) {
        console.error(error);
        message.error(error?.message ?? '알 수 없는 에러 발생!');
      }
      setLoading(false);
    },
    [navigate, setLoading]
  );

  const onFinishFailed = useCallback((errorInfo: any) => {
    message.error(errorInfo ?? '알 수 없는 에러발생');
  }, []);

  return (
    <>
      <div className={classes.findPasswordContainer}>
        {!authUser ? (
          <Form id='findPassword' onFinish={onFinish} onFinishFailed={onFinishFailed} size='large'>
            <Item
              name='email'
              label='이메일'
              rules={[...requiredRule, { type: 'email', message: '이메일 형식이 올바르지 않습니다.' }]}>
              <Input />
            </Item>

            <Item>
              <Button
                type='primary'
                icon={<SendOutlined />}
                className={classes.submitSigninButton}
                htmlType='submit'
                block
                loading={loading}>
                비밀번호 재설정 이메일 발송
              </Button>
            </Item>
          </Form>
        ) : (
          <div className={classes.loadingBox}>
            <LoadingOutlined />
          </div>
        )}
        <button className={classes.textButton} onClick={backToSignin}>
          로그인 페이지로 이동
        </button>
      </div>
    </>
  );
};

export default FindPassword;
