/**
 * {@link https://www.algolia.com/doc/api-client/getting-started/install/javascript/?client=javascript | Algolia Doc}
 */
import { ProductDoc } from '@gooduncles/gu-app-schema';
import algoliasearch from 'algoliasearch';
import useSWR from 'swr';

import { isProduction } from 'src/lib/1/constant';

const applicationId = isProduction ? 'ESJZE44DAV' : 'DNH45MYQTF';
const searchApiKey = isProduction ? '9ff539f22ea2ea850f2b8d448f7381b4' : '2764bcfedffe33f7edeba86548d543eb';
const searchClient = algoliasearch(applicationId, searchApiKey);

const indexName = 'product';

const fetcher = (request: [string, number]) => {
  const [keywords, hitsPerPage] = request;
  const index = searchClient.initIndex(indexName);
  return index.search<ProductDoc>(keywords, { hitsPerPage });
};

const useAlgoliaSearch = (keywords: string, hitsPerPage: number) => {
  const { data, isValidating, error } = useSWR(keywords ? [keywords, hitsPerPage] : null, fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    errorRetryCount: 1,
  });

  return {
    hits: data?.hits,
    total: data?.nbHits,
    loading: isValidating,
    error,
  };
};

export default useAlgoliaSearch;
