import { StoreDoc, UserDoc } from '@gooduncles/gu-app-schema';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { dayjsToStringDateWhere, getDefaultDate } from 'src/lib/1/date-util';
import { InstallmentBillDoc } from 'src/lib/3/schema-bill';
import { getUsers, observeInstallmentBill } from 'src/lib/4/firebase-short-cut';

import useStoresForSearch from 'src/hooks/useStoresForSearch';

import classes from './Settlement.module.scss';

import InstallmentBillTable from './InstallmentBillTable/InstallmentBillTable';
import InstallmentBillUploadModal from './InstallmentBillUploadModal/InstallmentBillUploadModal';

const defaultValue = getDefaultDate();

/**
 * 정산서를 받는 매장의 수신자 번호를 가져옵니다.
 * @param stores
 * @param users
 * @returns
 */
const getReceiverList = (stores: StoreDoc[], users: UserDoc[]) =>
  Object.fromEntries(
    stores.map((store) => {
      if (store.ownerTel && store.ownerTel.length > 0) return [store.storeCode, store.ownerTel];
      // TODO: 대표 사용자 번호를 가져오도록 변경예정
      const userTels = users
        .filter((user) => user.storeId === store._id && user.userTel.startsWith('010'))
        .map((user) => user.userTel);
      return [store.storeCode, userTels[0]];
    })
  );

const Settlement: FC = () => {
  const { stores } = useStoresForSearch();
  const [users, setUsers] = useState<UserDoc[]>([]);
  const [date, setDate] = useState<dayjs.Dayjs | null>(defaultValue);
  const dateForWhere = useMemo(() => date && dayjsToStringDateWhere(date), [date]);
  const [rowData, setRowData] = useState<InstallmentBillDoc[]>([]);

  const getUserList = useCallback(async () => {
    const users0 = await getUsers();
    setUsers(users0);
  }, []);

  useEffect(() => {
    if (dateForWhere) {
      const subscription = observeInstallmentBill([['settlementDate', '==', dateForWhere]]).subscribe(
        (installmentBills) => {
          const rowData0 = installmentBills.filter((bill) => bill.isDeleted !== true);
          setRowData(rowData0);
        }
      );

      return () => subscription.unsubscribe();
    }
  }, [dateForWhere]);

  useEffect(() => {
    getUserList();
  }, [getUserList]);

  return (
    <div className={classes.settlementContainer}>
      <header>
        <DatePicker onChange={setDate} defaultValue={defaultValue} />
        {stores && <InstallmentBillUploadModal receiverList={getReceiverList(stores, users)} />}
      </header>
      <section>
        <InstallmentBillTable rowData={rowData} />
      </section>
    </div>
  );
};

export default Settlement;
