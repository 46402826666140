import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { ColDef, ColGroupDef, RowClassParams, RowStyle } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { add } from 'date-fns';
import { groupBy } from 'lodash-es';
import { FC, useMemo, useState } from 'react';

import { formatDate } from 'src/lib/1/date-util';
import { ParchaseOrderProduct, PurchaseOrderPreview } from 'src/lib/3/schema-purchase-order';

import { CopyCellValueRenderer } from 'src/components/AgGrid/CellRenderer/CopyCellValueRenderer/CopyCellValueRenderer';

import DownloadPurchaseOrderCellRenderer from '../DownloadPurchaseOrderCellRenderer';

interface PurchaseOrderPreviewTableProps {
  rowData: PurchaseOrderPreview[];
  showHeader: boolean;
}

const defaultColDef: ColDef<PurchaseOrderPreview> = {
  flex: 1,
  sortable: true,
  resizable: true,
  filter: true,
};

const getRowStyle = (params: RowClassParams<PurchaseOrderPreview>): RowStyle | undefined => {
  const data = params.data as PurchaseOrderPreview;
  if (data.supplierName === 'unknown') {
    return { backgroundColor: 'var(--red100)' };
  }
};

const PurchaseOrderPreviewTable: FC<PurchaseOrderPreviewTableProps> = ({ rowData, showHeader }) => {
  const [, setGridRef] = useState<AgGridReact<PurchaseOrderPreview> | null>(null);

  const columnDefs: ColDef<PurchaseOrderPreview>[] = useMemo(() => {
    return [
      {
        field: '_id',
        hide: true,
      },
      {
        headerName: '매입처ID',
        field: 'supplierId',
        hide: true,
      },
      {
        headerName: '매입처',
        field: 'supplierName',
        cellRenderer: 'agGroupCellRenderer',
        maxWidth: 200,
      },
      {
        headerName: '발주 품목 수(종류)',
        maxWidth: 150,
        valueGetter: (params) => {
          const parchaseOrder = params.data;
          if (parchaseOrder) {
            const productIds = groupBy(parchaseOrder.parchaseOrderProducts, (product) => product.productId);
            return Object.keys(productIds).length;
          }
          return null;
        },
      },
      {
        headerName: '주문 매장 수',
        maxWidth: 120,
        valueGetter: (params) => {
          const parchaseOrder = params.data;
          if (parchaseOrder) {
            const storeIds = groupBy(parchaseOrder.parchaseOrderProducts, (product) => product.storeId);
            return Object.keys(storeIds).length;
          }
          return null;
        },
      },
      {
        headerName: '발주서 내용',
        field: 'message',
        minWidth: 254,
        wrapText: true,
        autoHeight: true,
        cellStyle: { whiteSpace: 'pre-wrap', lineHeight: '1.5', padding: 8 },
        valueFormatter: (params) => {
          const purchaseOrder = params.data as PurchaseOrderPreview;
          const { purchaseMethod, messagePrefix, messageSuffix } = purchaseOrder;
          if (!purchaseOrder?.supplierName || purchaseOrder?.supplierName === 'unknown') return '';
          // 전산 방식은 불필요한 메시지를 제거한다.
          if (purchaseMethod === 'api') {
            return '';
          }
          return showHeader ? messagePrefix + params.value + messageSuffix : params.value;
        },
      },
      {
        headerName: '매입처 번호',
        maxWidth: 180,
        field: 'supplierTel',
      },
      {
        headerName: '발주서 복사',
        maxWidth: 164,
        valueGetter: (params): any => {
          const purchaseOrder = params.data as PurchaseOrderPreview;
          if (!purchaseOrder?.supplierName || purchaseOrder?.supplierName === 'unknown') return null;
          const { messagePrefix, message, messageSuffix, purchaseMethod, purchaseList } = purchaseOrder;
          if (purchaseMethod === 'api') {
            return purchaseList;
          }
          return `${messagePrefix ?? ''}${message}${messageSuffix ?? ''}`;
        },
        cellRenderer: (params: any): any => {
          const purchaseOrder = params.data as PurchaseOrderPreview;
          return purchaseOrder.purchaseMethod === 'api' ? (
            <DownloadPurchaseOrderCellRenderer {...params} />
          ) : (
            <CopyCellValueRenderer {...params} />
          );
        },
        cellRendererParams: (params: any): any => {
          const purchaseOrder = params.data as PurchaseOrderPreview;
          if (!purchaseOrder?.supplierName || purchaseOrder?.supplierName === 'unknown') return null;
          const date = formatDate(add(new Date(), { days: 1 }), 'yyyy-MM-dd');
          return {
            name:
              purchaseOrder.purchaseMethod === 'api'
                ? `${purchaseOrder.supplierName}-${date}`
                : `매입처 '${purchaseOrder.supplierName}' 발주서`,
          };
        },
      },
    ];
  }, [showHeader]);

  const detailCellRendererParams = useMemo(() => {
    const detailColumnDefs: (ColDef<ParchaseOrderProduct> | ColGroupDef<ParchaseOrderProduct>)[] = [
      {
        headerName: '주문매장',
        field: 'storeNickname',
      },
      {
        headerName: '상품ID',
        field: 'productId',
        maxWidth: 120,
      },
      {
        headerName: '상품명',
        field: 'fullName',
      },
      {
        headerName: '매입처 발주명',
        field: 'purchaseProductName',
      },
      {
        headerName: '단위 변환 값',
        field: 'purchaseMultiplyUnit',
      },
      {
        headerName: '매장맞춤 발주명',
        field: 'conversionItem.purchaseProductName',
      },
      {
        headerName: '매장맞춤 묶음',
        field: 'conversionItem.purchaseMaxBundle',
      },
    ];

    return {
      // 부모 행이 변경될 때 같이 변경된다.
      refreshStrategy: 'rows',
      detailGridOptions: {
        columnDefs: detailColumnDefs,
        defaultColDef,
        enableCellChangeFlash: true,
      },
      getDetailRowData: (params: any) => {
        const purchaseOrder = params.data as PurchaseOrderPreview;
        const purchaseOrderProducts = purchaseOrder.parchaseOrderProducts;
        params.successCallback(purchaseOrderProducts);
      },
    };
  }, []);

  return (
    <div className='ag-theme-alpine height100'>
      <AgGridReact
        ref={setGridRef}
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        // onGridReady={autoSizeAll}
        getRowStyle={getRowStyle}
        // rowId를 지정해주지 않으면 데이터 변경시 refresh가 발생한다.
        getRowId={(params) => params.data.supplierId}
        // 편집 완료후 스크롤 이동을 막는다.
        suppressScrollOnNewData={true}
        // 툴팁의 노출 속도
        tooltipShowDelay={0}
        // 하위 노드 관련 설정
        masterDetail={true}
        keepDetailRows={true}
        detailRowAutoHeight={true}
        detailCellRendererParams={detailCellRendererParams}
      />
    </div>
  );
};

export default PurchaseOrderPreviewTable;
