import { OrderProduct } from '@gooduncles/gu-app-schema';
import { createAction } from 'typesafe-actions';

export const setOrderProducts = createAction('order-product/SET_ORDER_PRODUCTS')<OrderProduct[]>();
export const addProduct = createAction('order-product/ADD_PRODUCT')<OrderProduct>();
export const setProductVolume = createAction('order-product/SET_PRODUCT_VOLUME')<{
  productId: string;
  volume: number;
}>();
export const removeProduct = createAction('order-product/REMOVE_PRODUCT')<string>();
export const resetProducts = createAction('order-product/RESET_PRODUCTS')();
