import { FC } from 'react';

import { useTitle } from '../hooks/useTitle';

import Order from 'src/components/Order/Order';

const OrderPage: FC<{ title: string }> = ({ title }) => {
	useTitle(`GU 관리자 | ${title}`);
	return <Order />;
};

export default OrderPage;
