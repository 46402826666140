import { FC } from 'react';

import classes from './Content.module.scss';

interface SubTitleProps {
  title: string | JSX.Element;
  rightSection?: string | JSX.Element;
}

const SubTitle: FC<SubTitleProps> = ({ title, rightSection }) => {
  return (
    <div className={classes.subTitleBox}>
      <b>{title}</b>
      {rightSection && <div className={classes.rightSection}>{rightSection}</div>}
    </div>
  );
};

export default SubTitle;
