import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { ColDef, IsRowSelectable, RowClassParams, RowStyle } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { add } from 'date-fns';
import { FC, useMemo, useState } from 'react';

import { purchaseMethodToKr } from 'src/lib/1/constant';
import { formatDate } from 'src/lib/1/date-util';
import { PurchaseOrderDoc } from 'src/lib/3/schema-purchase-order';
import { onCellValueChangedWithUpdate, onValueSetterWithValidation } from 'src/lib/6/ag-grid-util';

import { CopyCellValueRenderer } from 'src/components/AgGrid/CellRenderer/CopyCellValueRenderer/CopyCellValueRenderer';
import DownloadRawDataCell from 'src/components/Common/DownloadRawDataCell/DownloadRawDataCell';

import DownloadPurchaseOrderCellRenderer from '../DownloadPurchaseOrderCellRenderer';
import { SendSmsCellRenderer } from './SendSmsCellRenderer';

const onCellValueChanged = onCellValueChangedWithUpdate<PurchaseOrderDoc>('발주서');

interface PurchaseOrderTableProps {
  rowData: PurchaseOrderDoc[];
  setSelectedRows: (rows: PurchaseOrderDoc[]) => void;
  showHeader: boolean;
}

const defaultColDef: ColDef<PurchaseOrderDoc> = {
  flex: 1,
  sortable: true,
  resizable: true,
  filter: true,
};

const getRowStyle = (params: RowClassParams<PurchaseOrderDoc>): RowStyle | undefined => {
  const data = params.data as PurchaseOrderDoc;
  if (data.supplierName === 'unknown') {
    return { backgroundColor: 'var(--red100)' };
  }
};

export const PurchaseMethodRenderer = (props: any) => <span>{purchaseMethodToKr[props.value as string]}</span>;

const PurchaseOrderTable: FC<PurchaseOrderTableProps> = ({ rowData, setSelectedRows, showHeader }) => {
  const [, setGridRef] = useState<AgGridReact<PurchaseOrderDoc> | null>(null);
  const isRowSelectable: IsRowSelectable<PurchaseOrderDoc> = useMemo(() => {
    return (params) => {
      return !!params.data && ['sms'].includes(params.data.purchaseMethod);
    };
  }, []);

  const columnDefs: ColDef<PurchaseOrderDoc>[] = useMemo(() => {
    return [
      {
        field: '_id',
        hide: true,
      },
      {
        field: '_timeUpdate',
        hide: true,
      },
      {
        headerName: '매입처ID',
        field: 'supplierId',
        hide: true,
      },
      {
        headerName: '매입처',
        field: 'supplierName',
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        maxWidth: 200,
      },
      {
        headerName: '발주서 내용',
        field: 'message',
        minWidth: 254,
        wrapText: true,
        autoHeight: true,
        cellStyle: { whiteSpace: 'pre-wrap', lineHeight: '1.5', padding: 8 },
        editable: true,
        cellEditor: 'agLargeTextCellEditor',
        cellEditorParams: {
          maxLength: 3000,
        },
        cellEditorPopup: true,
        valueFormatter: (params) => {
          const purchaseOrder = params.data as PurchaseOrderDoc;
          const { purchaseMethod, messagePrefix, messageSuffix } = purchaseOrder;
          if (!purchaseOrder?.supplierName || purchaseOrder?.supplierName === 'unknown') return '';
          // 전산 방식은 불필요한 메시지를 제거한다.
          if (purchaseMethod === 'api') {
            return '';
          }
          return showHeader ? messagePrefix + params.value + messageSuffix : params.value;
        },
        valueSetter: onValueSetterWithValidation,
        onCellValueChanged: (params) => onCellValueChanged(params, 'purchaseOrder'),
      },
      {
        headerName: '개별 발송',
        filter: false,
        field: '_id',
        cellRendererFramework: (params: any) => {
          const purchaseOrder = params.data as PurchaseOrderDoc;
          if (!['sms'].includes(purchaseOrder.purchaseMethod)) return null;
          return <SendSmsCellRenderer {...params} />;
        },
      },
      {
        headerName: '매입처 번호',
        maxWidth: 180,
        minWidth: 160,
        field: 'supplierTel',
        editable: true,
        onCellValueChanged: (params) => onCellValueChanged(params, 'purchaseOrder'),
      },
      {
        headerName: '매입처 추가번호',
        maxWidth: 180,
        minWidth: 160,
        field: 'supplierSubTels',
        editable: true,
        valueSetter: (params) =>
          onValueSetterWithValidation(params, ['_id'], undefined, {
            type: 'array',
            removable: true,
          }),
        onCellValueChanged: (params) => onCellValueChanged(params, 'purchaseOrder', undefined, undefined, true),
      },
      {
        headerName: '발주서 복사',
        filter: false,
        maxWidth: 164,
        minWidth: 138,
        valueGetter: (params): any => {
          const purchaseOrder = params.data as PurchaseOrderDoc;
          if (!purchaseOrder?.supplierName || purchaseOrder?.supplierName === 'unknown') return null;
          const { messagePrefix, message, messageSuffix, purchaseMethod } = purchaseOrder;
          if (purchaseMethod === 'api') {
            return message.split('\n');
          }
          return `${messagePrefix ?? ''}${message}${messageSuffix ?? ''}`;
        },
        cellRenderer: (params: any): any => {
          const purchaseOrder = params.data as PurchaseOrderDoc;
          return purchaseOrder.purchaseMethod === 'api' ? (
            <DownloadPurchaseOrderCellRenderer {...params} />
          ) : (
            <CopyCellValueRenderer {...params} />
          );
        },
        cellRendererParams: (params: any): any => {
          const purchaseOrder = params.data as PurchaseOrderDoc;
          if (!purchaseOrder?.supplierName || purchaseOrder?.supplierName === 'unknown') return null;
          const date = formatDate(add(new Date(), { days: 1 }), 'yyyy-MM-dd');
          return {
            name:
              purchaseOrder.purchaseMethod === 'api'
                ? `${purchaseOrder.supplierName}-${date}`
                : `매입처 '${purchaseOrder.supplierName}' 발주서`,
          };
        },
      },
      {
        headerName: '발주방식',
        field: 'purchaseMethod',
        editable: true,
        cellEditorPopup: true,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: {
          values: Object.keys(purchaseMethodToKr),
          cellRenderer: PurchaseMethodRenderer,
        },
        filterParams: {
          valueFormatter: (params: any) => purchaseMethodToKr[params.value as string],
        },
        cellRenderer: PurchaseMethodRenderer,
        valueSetter: onValueSetterWithValidation,
        onCellValueChanged: (params) => onCellValueChanged(params, 'purchaseOrder'),
      },
      {
        headerName: '액션',
        hide: true,
        minWidth: 120,
        cellRenderer: DownloadRawDataCell,
      },
    ];
  }, [showHeader]);

  return (
    <div className='ag-theme-alpine height100'>
      <AgGridReact
        ref={setGridRef}
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        // onGridReady={autoSizeAll}
        getRowStyle={getRowStyle}
        // rowId를 지정해주지 않으면 데이터 변경시 refresh가 발생한다.
        getRowId={(params) => params.data._id}
        enableCellChangeFlash={true}
        // 편집 완료후 스크롤 이동을 막는다.
        suppressScrollOnNewData={true}
        alwaysShowHorizontalScroll={true}
        // 툴팁의 노출 속도
        tooltipShowDelay={0}
        // 행 선택
        rowSelection={'multiple'}
        isRowSelectable={isRowSelectable}
        onSelectionChanged={(e) => setSelectedRows(e.api.getSelectedRows())}
        onCellValueChanged={(e) => {
          const changedCell = e;
          if (changedCell.colDef.field === 'purchaseMethod') {
            e.api.redrawRows({ rowNodes: [e.node] });
          }
        }}
      />
    </div>
  );
};

export default PurchaseOrderTable;
