import { BareFetcher } from 'swr';
import useImmutableSWR from 'swr/immutable';

import { AvailableYearForStats } from 'src/lib/1/constant';
import { DailyOrderStatsDoc } from 'src/lib/2/schema';
import { WHERE } from 'src/lib/3/firebase-manager';
import { getDailyOrderStats } from 'src/lib/4/firebase-short-cut';

type options = {
  selectedYears: AvailableYearForStats[];
};

const fetcher: BareFetcher<DailyOrderStatsDoc[]> = ([, options]: [string, options]) => {
  const where: WHERE[] = options.selectedYears ? [['year', 'in', options.selectedYears]] : [];
  return getDailyOrderStats(where);
};

const useDailyOrderStats = (options: options) => {
  const { data: dailyOrderStats, isValidating: dailyOrderStatsLoading } = useImmutableSWR(
    options.selectedYears.length > 0 ? ['dailyOrderStats', options] : null,
    fetcher
  );

  return {
    dailyOrderStats,
    dailyOrderStatsLoading,
  };
};

export default useDailyOrderStats;
