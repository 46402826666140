import { OrderProduct, OrderStatusCode } from '@gooduncles/gu-app-schema';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { ColDef, ColGroupDef } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { FC, useMemo, useState } from 'react';

import { orderDateFormat01 } from 'src/lib/1/date-util';
import { formatNumber } from 'src/lib/1/util';
import { numberFormatter } from 'src/lib/6/ag-grid-util';

import useOrderListForUser from 'src/hooks/useOrderListForUser';

interface UserOrderProductListProps {
  userId: string;
}

type OrderProductForRow = OrderProduct & {
  orderId: string;
  orderDate: string;
  deliveredAt: string | null;
};

const defaultColDef: ColDef<OrderProductForRow> = {
  sortable: true,
  resizable: true,
  filter: true,
};

/**
 * 동일한 상품의 그룹행 설정
 * {@link https://www.ag-grid.com/react-data-grid/row-selection/#example-group-selection}
 */
const autoGroupColumnDef = {
  headerName: '상품명',
  field: 'productId',
  width: 300,
  cellRenderer: 'agGroupCellRenderer',
  valueGetter: (params: any) => {
    const { fullName } = (params?.data as OrderProductForRow) ?? {};
    return fullName;
  },
};

const UserOrderProductList: FC<UserOrderProductListProps> = ({ userId }) => {
  const [, setGridRef] = useState<AgGridReact<OrderProductForRow> | null>(null);
  const { orderList } = useOrderListForUser(userId);
  const rowData: OrderProductForRow[] = useMemo(
    () =>
      orderList
        ? orderList
            .filter((order) => order.orderStatus === OrderStatusCode.DELIVERED)
            ?.flatMap((order) =>
              order.products.map((p) => ({
                orderId: order._id,
                orderDate: order.orderDate,
                deliveredAt: order.deliveredAt,
                ...p,
              }))
            )
        : [],
    [orderList]
  );

  const columnDefs: (ColDef<OrderProductForRow> | ColGroupDef<OrderProductForRow>)[] = useMemo(
    () => [
      {
        headerName: '상품코드',
        field: 'productId',
        width: 120,
        valueFormatter: (params: any) => {
          const value = params.value;
          const isGroup = params.node.group;
          return isGroup ? params.node?.childrenAfterGroup?.[0].data?.productId : value;
        },
      },
      { headerName: '상품명', field: 'fullName', rowGroup: true, hide: true },
      {
        headerName: '가격',
        field: 'snapshotPrice',
        aggFunc: 'avg',
        width: 120,
        valueGetter: (params) => {
          const { price, snapshotPrice } = (params?.data as OrderProductForRow) ?? {};
          return snapshotPrice ?? price ?? 0;
        },
        cellRenderer: (params: any) => formatNumber(params.value, 0),
      },
      {
        headerName: '주문 후 가격 변동',
        field: 'priceDiff',
        aggFunc: 'avg',
        width: 180,
        valueGetter: (params) => {
          const { priceDiff } = (params?.data as OrderProductForRow) ?? {};
          return priceDiff ?? 0;
        },
        cellRenderer: (params: any) => formatNumber(params.value, 0),
      },
      {
        headerName: '구매수량',
        field: 'volume',
        aggFunc: 'sum',
        valueFormatter: numberFormatter,
      },
      {
        headerName: '총액',
        field: 'totalAmount',
        maxWidth: 160,
        aggFunc: 'sum',
        sort: 'desc',
        valueGetter: (params) => {
          const { price, snapshotPrice, volume } = (params.data as OrderProductForRow) ?? {};
          return (snapshotPrice ?? price ?? 0) * (volume ?? 0);
        },
        valueFormatter: numberFormatter,
      },
      {
        headerName: '주문일',
        field: 'orderDate',
        width: 180,
        hide: true,
        valueFormatter: ({ value }) => (value ? orderDateFormat01(value) : ''),
      },
      {
        headerName: '배송일',
        field: 'deliveredAt',
        width: 180,
        valueFormatter: ({ value }) => (value ? orderDateFormat01(value) : ''),
      },
    ],
    []
  );

  return (
    <div className='ag-theme-alpine' style={{ height: '100%' }}>
      <AgGridReact
        ref={setGridRef}
        rowData={rowData}
        getRowId={(params) => params.data.orderId + params.data.productId}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        autoGroupColumnDef={autoGroupColumnDef}
      />
    </div>
  );
};

export default UserOrderProductList;
