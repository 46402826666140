import useSWR from 'swr';

import { dayjsesToStringDateWhere, getDefaultDates } from 'src/lib/1/date-util';
import { getProductStockHistories } from 'src/lib/4/firebase-short-cut';

const getProductStockHistoriesForMonth = async (request: [string, string]) => {
  const [productId] = request;
  const defaultValue = getDefaultDates(30, 1);
  const [startValue, endValue] = dayjsesToStringDateWhere(defaultValue[0], defaultValue[1]);
  return getProductStockHistories([['productId', '==', productId]], {
    sortKey: 'timestamp',
    orderBy: 'desc',
    startValue,
    endValue,
  });
};

const useProductStockHistory = (productId: string) => {
  const { data } = useSWR(productId ? [productId, 'productStockHistory'] : null, getProductStockHistoriesForMonth, {
    revalidateOnFocus: false,
    errorRetryCount: 2,
  });
  return {
    stockHistories: data,
  };
};

export default useProductStockHistory;
