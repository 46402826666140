import { FC, useEffect, useState } from 'react';

import { SupplierDoc } from 'src/lib/3/schema-supplier';
import { observeSupplier } from 'src/lib/4/firebase-short-cut';

import { useTitle } from 'src/hooks/useTitle';

import SupplierTable from './SupplierTable/SupplierTable';

const Supplier: FC = () => {
  useTitle('GU 관리자 | 매입처 관리');
  const [rowData, setRowData] = useState<SupplierDoc[]>([]);

  useEffect(() => {
    const subscription = observeSupplier([['isDeleted', '==', false]]).subscribe((data) => {
      setRowData(data.sort((a, b) => a.label.localeCompare(b.label)));
    });
    return () => subscription.unsubscribe();
  }, []);

  return <SupplierTable rowData={rowData} />;
};

export default Supplier;
