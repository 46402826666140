import { useCallback } from 'react';

import { InstallmentBill } from 'src/lib/3/schema-bill';

import { clearBills, setBillWithId, setBills } from '../slices/bill';
import { useAppDispatch } from '../store';

const useBill = () => {
  const dispatch = useAppDispatch();

  const onClearBills = useCallback(() => {
    dispatch(clearBills());
  }, [dispatch]);

  const onSetBills = useCallback(
    (bills: InstallmentBill[]) => {
      dispatch(setBills(bills));
    },
    [dispatch]
  );

  const onSetBill = useCallback(
    (storeCode: string, bill: InstallmentBill) => {
      dispatch(setBillWithId({ bill, storeCode }));
    },
    [dispatch]
  );

  return { onClearBills, onSetBills, onSetBill };
};

export default useBill;
