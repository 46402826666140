import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import DailyOrderStatsPage from './DailyOrderStatsPage';
import MonthlyOrderStatsPage from './MonthlyOrderStatsPage';
import ProductChartPage from './ProductChartPage';

const StatsPage: FC = () => {
  return (
    <Routes>
      <Route path='monthly-order' element={<MonthlyOrderStatsPage title='월간 주문 통계' />} />
      <Route path='daily-order' element={<DailyOrderStatsPage title='일일 주문 통계' />} />
      <Route path='/product/:productId' element={<ProductChartPage />} />
      <Route path='/product' element={<ProductChartPage />} />
      <Route path='/' element={<Navigate to='/montly-order?tab=stacked-bar&year=2024' replace />} />
    </Routes>
  );
};

export default StatsPage;
