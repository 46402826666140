import { FC } from 'react';

import { useTitle } from '../hooks/useTitle';

import Store from 'src/components/Store/Store';

const StorePage: FC<{ title: string }> = ({ title }) => {
  useTitle(`GU 관리자 | ${title}`);
  return <Store />;
};

export default StorePage;
