import { FC } from 'react';

import { useTitle } from 'src/hooks/useTitle';

import CommerceLog from 'src/components/CommerceLog/CommerceLog';

const CommerceLogPage: FC<{ title: string }> = ({ title }) => {
  useTitle(`GU 관리자 | ${title}`);
  return <CommerceLog />;
};

export default CommerceLogPage;
