import { useCallback, useState } from 'react';

import { errorObjectToString } from 'src/lib/1/util';
import { FirebaseManager } from 'src/lib/3/firebase-manager';

const firebaseManager = FirebaseManager.getInstance();

type UploadStatus = 'idle' | 'uploading' | 'success' | 'error';

/**
 * Firebase Storage에 파일을 업로드하는 훅
 */
const useUploadTask = (path: string) => {
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>('idle');
  const [percent, setPercent] = useState(0);
  const [error, setError] = useState<string | null>(null);

  const uploadFile = useCallback(
    (file: File): Promise<boolean> => {
      setPercent(0);
      setError(null);
      setUploadStatus('uploading');

      try {
        const task = firebaseManager.uploadTask(path, file, {
          contentType: file.type,
          cacheControl: 'public,max-age=864000',
        });
        return new Promise((resolve, reject) => {
          task.on(
            'state_changed',
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              setPercent(progress);
              console.log('Upload is ' + progress + '% done');
              switch (snapshot.state) {
                case 'paused':
                  console.log('Upload is paused');
                  break;
                case 'running':
                  console.log('Upload is running');
                  break;
              }
            },
            (error) => {
              reject(error.message);
              setUploadStatus('error');
            },
            () => {
              resolve(true);
              setUploadStatus('success');
            }
          );
        });
      } catch (error) {
        console.error(error);
        const msg = errorObjectToString(error);
        setError(msg);
        setUploadStatus('error');
        return Promise.resolve(false);
      }
    },
    [path]
  );

  const initUploadTask = useCallback(() => {
    setPercent(0);
    setError(null);
    setUploadStatus('idle');
  }, []);

  const removeFile = useCallback(async (path: string) => firebaseManager.deleteFile(path), []);

  return {
    percent,
    error,
    uploadStatus,
    initUploadTask,
    uploadFile,
    removeFile,
  };
};

export default useUploadTask;
