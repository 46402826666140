import { Button, Modal } from 'antd';
import { FC, useState } from 'react';

import useSettlementByStore from 'src/hooks/useSettlementByStore';

import Loading from 'src/components/Loading/Loading';

import SettlementByStoreTable from '../SettlementByStoreTable/SettlementByStoreTable';

interface SettlementByStoreModalProps {
  storeId: string;
}

const SettlementByStoreModal: FC<SettlementByStoreModalProps> = ({ storeId }) => {
  const [open, setOpen] = useState(false);
  const { rowData, isLoading } = useSettlementByStore(open ? storeId : undefined);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button onClick={handleOpen} type='link'>
        정산이력
      </Button>
      <Modal
        open={open}
        onOk={handleClose}
        onCancel={handleClose}
        okText='닫기'
        closable={false}
        destroyOnClose={true}
        width={'90vw'}
        centered
        styles={{ body: { height: '80vh', overflow: 'scroll' } }}
        cancelButtonProps={{
          style: {
            display: 'none',
          },
        }}>
        <section className='height100'>
          {isLoading && <Loading title='잠시만 기다려주세요.' />}
          {rowData && <SettlementByStoreTable rowData={rowData} />}
        </section>
      </Modal>
    </>
  );
};

export default SettlementByStoreModal;
