import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { FC, ReactNode } from 'react';

interface HelpModalProps {
  title: string;
  content: string | ReactNode;
}

const HelpModal: FC<HelpModalProps> = ({ title, content }) => {
  const openModal = () => {
    Modal.info({
      title,
      content,
    });
  };

  return (
    <>
      <Button type='text' icon={<QuestionCircleOutlined />} onClick={openModal} />
    </>
  );
};

export default HelpModal;
