import { Button, message, notification } from 'antd';
import { FC, useCallback, useState } from 'react';

import { DaumPostcodeResponse } from 'src/lib/1/schema-daum-postcode-api';
import { parseIfObject } from 'src/lib/1/util';
import { StoreBase } from '@gooduncles/gu-app-schema';
import { FirebaseManager } from 'src/lib/3/firebase-manager';
import { ConsoleLogger } from 'src/lib/5/logger';

import DaumPostcodeModal from 'src/components/Modal/DaumPostcodeModal/DaumPostcodeModal';

const logger = ConsoleLogger.getInstance();
const logName = '사용자 관리';

const firebaseManager = FirebaseManager.getInstance();
const storePath = 'store';

const AddressInputButton: FC<{ storeId: string }> = ({ storeId }) => {
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [daumResponse, setDaumResponse] = useState<DaumPostcodeResponse>();

  const onComplete = useCallback(async (data: DaumPostcodeResponse) => {
    setIsLoading(true);
    setDaumResponse(data);
    notification.success({
      message: '선택한 주소로 변경을 원하시면 "업데이트" 버튼을 눌러주세요.',
      description: data.address,
      duration: 10,
    });
    setIsLoading(false);
  }, []);

  const updateAddress = useCallback(async () => {
    if (daumResponse) {
      setIsLoading(true);
      try {
        const {
          address = '',
          roadAddress = '',
          jibunAddress = '',
          sido = '',
          sigungu = '',
          bname = '',
          hname = '',
          roadname = '',
        } = daumResponse ?? {};
        const doc: Partial<StoreBase> = {
          address,
          roadAddress,
          jibunAddress,
          sido,
          sigungu,
          bname,
          hname,
          roadname,
        };
        await firebaseManager.updateDoc(storePath, storeId, doc);
        notification.success({
          message: '주소가 변경되었습니다.',
          description: `address: ${address}\nroadAddress: ${roadAddress}\njibunAddress: ${jibunAddress}\nsido: ${sido}\nsigungu: ${sigungu}\nbname: ${bname}\nhname: ${hname}\nroadname: ${roadname}`,
          duration: 10,
          style: {
            whiteSpace: 'pre-line',
          },
        });
        logger.logConsole(`${logName} - store/${storeId}:: ${parseIfObject(doc)}`);
      } catch (error: any) {
        message.error(error.message ?? '알 수 없는 오류가 발생했습니다.');
        console.error(error);
        logger.logConsole(`${logName} - store/${storeId}:: ${parseIfObject(error)}`, {
          level: 'error',
        });
      }
      setDaumResponse(undefined);
      setIsLoading(false);
    }
  }, [daumResponse, storeId]);

  return (
    <>
      <DaumPostcodeModal visible={visible} setVisible={setVisible} onComplete={onComplete} />
      {daumResponse ? (
        <>
          <Button size='small' type='primary' style={{ fontSize: 12 }} onClick={updateAddress} loading={isLoading}>
            업데이트
          </Button>
          <Button
            size='small'
            type='text'
            style={{ fontSize: 12 }}
            onClick={() => setDaumResponse(undefined)}
            loading={isLoading}>
            취소
          </Button>
        </>
      ) : (
        <Button size='small' type='link' style={{ fontSize: 12 }} onClick={() => setVisible(true)} loading={isLoading}>
          주소수정
        </Button>
      )}
    </>
  );
};

export default AddressInputButton;
