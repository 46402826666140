import { FC } from 'react';

import { useTitle } from 'src/hooks/useTitle';

import DailyIssueDashboard from 'src/components/DailyIssueDashboard/DailyIssueDashboard';

const DashboardPage: FC = () => {
  useTitle('GU 관리자 | 대시보드');
  return <DailyIssueDashboard />;
};

export default DashboardPage;
