import { ProductStockHistoryDoc } from '@gooduncles/gu-app-schema';
import { FC, useEffect, useState } from 'react';
import { map } from 'rxjs';

import { observeProductStockHistories } from 'src/lib/4/firebase-short-cut';

import { useTitle } from 'src/hooks/useTitle';

import ProductStockIssueTable from 'src/components/ProductStock/ProductStockIssueTable/ProductStockIssueTable';

const ProductStockIssuePage: FC = () => {
  useTitle('GU 관리자 | 상품 재고 이슈');
  const [rowData, setRowData] = useState<ProductStockHistoryDoc[]>([]);

  useEffect(() => {
    const subscription = observeProductStockHistories([['reason', '!=', null]])
      .pipe(map((docs) => docs.filter((d) => d.reason !== '초기 재고 설정')))
      .subscribe(setRowData);
    return () => subscription.unsubscribe();
  }, []);

  return (
    <div className='tabBody flexColumn height100'>
      {/* <section className='flexRow flexSpaceBetween'>
        <TableOptions setDates={setDates} defaultValue={defaultValue} />
      </section> */}
      <section className='height100'>
        <ProductStockIssueTable rowData={rowData} />
      </section>
    </div>
  );
};

export default ProductStockIssuePage;
