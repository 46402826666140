import { DatePicker } from 'antd';
import { FC, PropsWithChildren } from 'react';

import { RangeEventValue } from 'src/lib/1/date-util';

import classes from './TableOptions.module.scss';

const { RangePicker } = DatePicker;

interface TableOptionsProps {
  setDates: React.Dispatch<React.SetStateAction<RangeEventValue>>;
  defaultValue?: RangeEventValue;
  marginBottom?: number;
  disabled?: boolean;
}

const TableOptions: FC<PropsWithChildren<TableOptionsProps>> = ({
  children,
  setDates,
  defaultValue,
  marginBottom,
  disabled,
}) => {
  return (
    <div className={classes.tableOptions} style={{ marginBottom }}>
      <RangePicker defaultValue={defaultValue} onCalendarChange={(val) => setDates(val)} disabled={disabled} />
      <div className={classes.marginBox} />
      {children}
    </div>
  );
};

export default TableOptions;
