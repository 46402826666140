import { useEffect, useState } from 'react';

import { SupplierDoc } from 'src/lib/3/schema-supplier';
import { getSuppliers } from 'src/lib/4/firebase-short-cut';

const useSuppliers = () => {
  const [suppliers, setSuppliers] = useState<SupplierDoc[]>();

  useEffect(() => {
    getSuppliers().then((suppliers0) => {
      setSuppliers(suppliers0);
    });
  }, []);
  return suppliers;
};

export default useSuppliers;
