import { OrderDoc, OrderProduct } from '@gooduncles/gu-app-schema';
import { Button, Modal } from 'antd';
import { uniqBy } from 'lodash-es';
import { useState } from 'react';

import { formatNumber } from 'src/lib/1/util';

import classes from './OrderMonthGroupRenderer.module.scss';

const OrderMonthGroupRenderer = (props: any) => {
  const [open, setOpen] = useState(false);
  const { node } = props;
  const { aggData, key, allChildrenCount } = node;
  const { totalAmount } = aggData;
  const pricePerUnit = Math.floor(totalAmount / allChildrenCount);
  const productVariety = uniqBy(
    node.allLeafChildren.flatMap((leafNode: any) => (leafNode.data as OrderDoc).products) as OrderProduct[],
    'productId'
  )
    .map((product: OrderProduct) => [product.productId, product.fullName])
    .sort((a, b) => a[1].localeCompare(b[1]));
  const productVarietyCount = productVariety.length;
  return (
    <div className={classes.orderMonthGroup}>
      <span className={classes.month}>{key}</span> ({allChildrenCount}) -{' '}
      <span className={classes.totalAmount}>총 주문금액: {formatNumber(totalAmount)}원</span>
      <span className={classes.avgUnit}> (단가: {formatNumber(pricePerUnit)}원)</span>
      <Button type='text' style={{ marginLeft: 8 }} onClick={() => setOpen(true)}>
        품목수: {productVarietyCount}
      </Button>
      <Modal
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        okText='닫기'
        styles={{ body: { maxHeight: '70vh', overflow: 'scroll' } }}
        cancelButtonProps={{
          style: {
            display: 'none',
          },
        }}>
        <div className={classes.productVariety}>
          {productVariety.map(([productId, fullName]) => (
            <div key={productId} className={classes.productVarietyItem}>
              {fullName}
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default OrderMonthGroupRenderer;
