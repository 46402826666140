import { Tabs } from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { RangeEventValue, dayjsesToDateWhere, getDefaultDates } from 'src/lib/1/date-util';
import { FriendtalkSenderGroupingKey } from 'src/lib/2/schema-nhn-notification';
import { observeFriendtalkMessage } from 'src/lib/4/firebase-short-cut';

import { useTitle } from 'src/hooks/useTitle';

import TableOptions from '../Common/TableOptions/TableOptions';
import FriendtalkMessageTable, { FriendtalkMessageTableRowData } from './FriendtalkMessageTable';

const defaultValue = getDefaultDates(1, 1);

interface FriendtalkMessageProps {
  tab: FriendtalkSenderGroupingKey;
}

const FriendtalkMessage: FC<FriendtalkMessageProps> = ({ tab }) => {
  useTitle(`GU 관리자 | 친구톡 메시지 - ${tab}`);
  const navigate = useNavigate();
  const [rowData, setRowData] = useState<FriendtalkMessageTableRowData[]>([]);
  const [dates, setDates] = useState<RangeEventValue>(null);
  const [leftDate, rightDate] = useMemo(() => {
    return dates?.[0] && dates?.[1]
      ? dayjsesToDateWhere(dates[0], dates[1])
      : dayjsesToDateWhere(defaultValue[0], defaultValue[1]);
  }, [dates]);

  const onTabChange = (key: string) => {
    navigate(`../${key}`);
  };

  useEffect(() => {
    const subscription = observeFriendtalkMessage([], {
      sortKey: '_timeCreate',
      orderBy: 'desc',
      startValue: leftDate,
      endValue: rightDate,
    }).subscribe((docs) => {
      const flatten = docs
        .map((doc) => {
          const { recipientWithResultList, ...data } = doc;
          return recipientWithResultList.map((recipientWithResult, idx) => ({
            ...data,
            ...recipientWithResult,
            recipientSeq: idx + 1,
          }));
        })
        .flat();
      setRowData(flatten);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [leftDate, rightDate, tab]);

  return (
    <div className='height100 flexColumn'>
      <TableOptions setDates={setDates} defaultValue={defaultValue} />
      <Tabs
        type='card'
        activeKey={tab}
        style={{ height: '100%', overflow: 'scroll' }}
        tabBarStyle={{ marginBottom: 0 }}
        onChange={onTabChange}
        items={[
          {
            key: 'daily-price-list',
            label: '일일 가격 알림',
            children: (
              <FriendtalkMessageTable
                rowData={rowData.filter((data) => data.senderGroupingKey === 'daily-price-list')}
              />
            ),
          },
          {
            key: 'custom',
            label: '커스텀 메시지',
            children: (
              <FriendtalkMessageTable rowData={rowData.filter((data) => data.senderGroupingKey === 'custom')} />
            ),
          },
        ]}
      />
    </div>
  );
};

export default FriendtalkMessage;
