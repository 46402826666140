import useSWR from 'swr';

import { getOrdersForUserId } from 'src/lib/4/firebase-short-cut';

const fetcher = async (request: [string, string]) => {
  const [userId] = request;
  return getOrdersForUserId(userId);
};

const useOrderListForUser = (userId?: string) => {
  const {
    data,
    error,
    isValidating: orderListLoading,
  } = useSWR(userId ? [userId, 'order-list'] : null, fetcher, {
    revalidateOnReconnect: false,
    revalidateOnFocus: false,
  });

  return {
    orderList: data?.sort((a, b) => (a.orderDate < b.orderDate ? 1 : a.orderDate === b.orderDate ? 0 : -1)),
    error,
    orderListLoading,
  };
};

export default useOrderListForUser;
