import { ColDef, ColGroupDef } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { FC, useMemo } from 'react';
import { ProductMarketTrendTableRowOption } from 'src/utils/product-trend-util';

import { formatDate } from 'src/lib/1/date-util';
import { formatNumber } from 'src/lib/1/util';
import { numberFormatter, onCellValueChangedWithUpdate } from 'src/lib/6/ag-grid-util';

import classes from './ProductMarketTrendTable.module.scss';

export interface ProductMarketTrendTableRowData {
  productId: string;
  productName: string;
  salesAmount: number;
  multFactor: number | null;
  garakMarketProductName: string;
  dailyColumns: {
    [date: string]: {
      garak: number | null;
      gu: number | null;
      diff: number | null;
      percent: number | null;
    };
  };
  weeklyColumns: {
    [week: string]: {
      garak: number | null;
      gu: number | null;
      diff: number | null;
      percent: number | null;
    };
  };
}

const onCellValueChanged = onCellValueChangedWithUpdate<ProductMarketTrendTableRowData>('상품 가격 동향');

const formatNumberWithNull = (value: number | null, fraction = 0) =>
  value !== null ? (isNaN(value) ? '.' : formatNumber(value, fraction)) : '.';

const multiRowCellRenderer = (params: any, options: ProductMarketTrendTableRowOption[]) => {
  const { garak, gu, diff, percent } = params.value as ProductMarketTrendTableRowData['dailyColumns'][string];
  const style = getBackgroundColorForPercentage(percent || 100);
  return (
    <div className={classes.trendValue}>
      {options.includes('경매가') && <div className={classes.valueCell}>{formatNumberWithNull(garak)}</div>}
      {options.includes('매입가') && <div className={classes.valueCell}>{formatNumberWithNull(gu)}</div>}
      {options.includes('차액') && (
        <div className={classes.valueCell} style={style}>
          {formatNumberWithNull(diff)}
        </div>
      )}
      {options.includes('비율') && (
        <div className={classes.valueCell} style={style}>
          {formatNumberWithNull(percent, 2)}
          {formatNumberWithNull(percent, 2) !== '.' && '%'}
        </div>
      )}
    </div>
  );
};

interface ProductMarketTrendTableProps {
  rowData: ProductMarketTrendTableRowData[];
  options: ProductMarketTrendTableRowOption[];
  column: 'Daily' | 'Weekly';
}

/**
 * 백분율에 따라 배경색을 반환한다.
 */
const getBackgroundColorForPercentage = (percentage: number) => {
  if (percentage < 90) {
    // 파란색 계통: 밝기를 조절하여 90%에 가까울수록 밝게
    // lightness가 낮을수록 어둡다
    const lightness = Math.max(20, 100 - (90 - percentage));
    return {
      backgroundColor: `hsl(200, 100%, ${lightness}%)`,
    };
  } else if (percentage > 110) {
    // 빨간색 계통: 밝기를 조절하여 110%를 많이 초과할수록 어둡게
    const lightness = Math.max(50, 100 - (percentage - 110));
    return {
      backgroundColor: `hsl(0, 100%, ${lightness}%)`,
    };
  }
  return {};
};

const defaultColDef = {
  sortable: true,
  resizable: true,
  filter: true,
  floatingFilter: true,
};

const ProductMarketTrendTable: FC<ProductMarketTrendTableProps> = ({ rowData, options, column }) => {
  const dailyColumns = useMemo(() => {
    if (rowData[0]?.dailyColumns) {
      return Object.keys(rowData[0].dailyColumns)
        .sort((a, b) => (a > b ? -1 : 1))
        .map((date) => {
          return {
            field: `dailyColumns.${date}`,
            width: 94,
            headerName: formatDate(date, 'MM.dd'),
            autoHeight: true,
            cellStyle: { padding: 0 },
            cellRenderer: (params: any) => multiRowCellRenderer(params, options),
          };
        });
    }
    return [];
  }, [rowData, options]);

  const weeklyColumns = useMemo(() => {
    if (rowData[0]?.weeklyColumns) {
      return Object.keys(rowData[0].weeklyColumns)
        .sort((a, b) => (a > b ? -1 : 1))
        .map((week) => {
          return {
            field: `weeklyColumns.${week}`,
            width: 120,
            headerName: week,
            autoHeight: true,
            cellStyle: { padding: 0 },
            cellRenderer: (params: any) => multiRowCellRenderer(params, options),
          };
        });
    }
    return [];
  }, [rowData, options]);

  const columnDefs: (ColDef<ProductMarketTrendTableRowData> | ColGroupDef<ProductMarketTrendTableRowData>)[] = useMemo(
    () => [
      { field: 'productId', hide: true },
      {
        field: 'salesAmount',
        headerName: '판매량',
        width: 120,
        type: 'numericColumn',
        sort: 'desc',
        valueFormatter: numberFormatter,
      },
      { field: 'garakMarketProductName', headerName: '경매상품명', filter: 'agTextColumnFilter', hide: true },
      { field: 'productName', headerName: '상품명', filter: 'agTextColumnFilter', hide: true },
      {
        headerName: '상품',
        field: 'productName',
        autoHeight: true,
        cellRenderer: (params: any) => {
          const { productName, garakMarketProductName } = params.data as ProductMarketTrendTableRowData;
          return (
            <div className={classes.trendLabel}>
              {options.includes('경매가') && <div>{garakMarketProductName}</div>}
              {options.includes('매입가') && <div>{productName}</div>}
              {options.includes('차액') && <div style={{ color: 'var(--gray400)' }}>차액(매입가 - 경매가)</div>}
              {options.includes('비율') && <div style={{ color: 'var(--gray400)' }}>비율(매입가 / 경매가)</div>}
            </div>
          );
        },
      },
      {
        field: 'multFactor',
        headerName: '보정값',
        editable: (params) => params.data?.multFactor !== null,
        width: 88,
        valueFormatter: (params) => formatNumberWithNull(params.value as number, 6),
        valueSetter: (params) => {
          const value = Number(params.newValue);
          if (isNaN(value)) {
            return false;
          }
          params.data.multFactor = value;
          return true;
        },
        onCellValueChanged: (params) =>
          onCellValueChanged(params, 'garakMarketProductPriceTrend', params.data.productId, 'multFactor'),
      },
      ...(column === 'Daily' ? dailyColumns : weeklyColumns),
      // ...dateColumns,
    ],
    [column, dailyColumns, weeklyColumns, options]
  );

  return (
    <div className='ag-theme-alpine height100 width100'>
      <AgGridReact
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        // rowId를 지정해주지 않으면 데이터 변경시 refresh가 발생한다.
        getRowId={(params) => params.data.productId}
        // 편집 완료후 스크롤 이동을 막는다.
        suppressScrollOnNewData={true}
      />
    </div>
  );
};

export default ProductMarketTrendTable;
