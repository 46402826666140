import { FC, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useProductSnapshotsForId from 'src/hooks/useProductSnapshotsForId';
import { useTitle } from 'src/hooks/useTitle';

import AlgoliaSearch from 'src/components/AlgoriaSearch/AlgoriaSearch';
import ProductLineChart from 'src/components/Stats/ProductLineChart';

const ProductChartPage: FC = () => {
  useTitle('GU 관리자 | 개별 상품 통계');
  const navigate = useNavigate();
  const { productId } = useParams();
  const { snapshots } = useProductSnapshotsForId(productId);

  const onProductSearch = useCallback(
    (productId: string) => {
      navigate(`/stats/product/${productId}`);
    },
    [navigate]
  );

  return (
    <div className='height100 flexColumn'>
      <div style={{ paddingBottom: 10 }}>
        <AlgoliaSearch placeholder='상품코드 또는 상품명으로 검색' onSearch={onProductSearch} />
      </div>
      <ProductLineChart rowData={snapshots ?? []} />
    </div>
  );
};

export default ProductChartPage;
