import { Button, Modal, Switch, notification } from 'antd';
import { useCallback, useState } from 'react';
import { callSendKakaoAlimTalkForBill } from 'src/utils/firebase-callable';

import { isProduction, senderKey } from 'src/lib/1/constant';
import { dateFormat08 } from 'src/lib/1/date-util';
import { InstallmentBillTemplate } from 'src/lib/1/schema-alimtalk-template';
import { encryptAES, errorObjectToString, formatNumber } from 'src/lib/1/util';
import { InstallmentBillDoc } from 'src/lib/3/schema-bill';
import { getAlimtalkMessage } from 'src/lib/4/firebase-short-cut';
import { organizeKakaotalkResultMessage } from 'src/lib/4/nhn-notification-util';
import { ConsoleLogger } from 'src/lib/5/logger';

import Bill, { BillHandle } from 'src/components/Common/Bill/Bill';

const logger = ConsoleLogger.getInstance();
const logName = '개별 정산서 모달';

const InstallmentBillModal = (params: { data: InstallmentBillDoc }) => {
  const { data: bill } = params ?? {};
  const [open, setOpen] = useState(false);
  const [noMessage, setNoMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [billRef, setBillRef] = useState<BillHandle | null>(null);
  const disabled = !bill.receiverTel;

  const openModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const sendBillWithImageUpload = useCallback(async () => {
    if (!bill.receiverTel) return;
    setLoading(true);
    try {
      if (!billRef) {
        throw new Error('billHandle가 없습니다.');
      }

      if (!senderKey) {
        throw new Error('senderKey가 없습니다.');
      }

      const recipientList = [
        {
          recipientNo: bill.receiverTel,
          templateParameter: {
            date: dateFormat08(new Date(bill.settlementDate)),
            store: bill.storeName,
            amount: formatNumber(bill.unpaidAmount),
            base: isProduction ? 'app.gooduncles.com/bill' : 'dev.app.gooduncles.com/bill',
            user: encodeURIComponent(encryptAES(bill.receiverTel)),
            bill: bill._id,
          },
          recipientGroupingKey: bill._id,
        },
      ];
      const result = await callSendKakaoAlimTalkForBill({
        senderKey,
        recipientList,
        templateCode: 'installment-bill',
      });

      if (result.data.result !== 'success') {
        throw new Error(result.data.reason);
      }

      if (!result.data.alimtalkMessageDocId) {
        throw new Error('요청은 성공했으나, ID를 받지 못했습니다.');
      }

      const alimtalkMessageDoc = await getAlimtalkMessage<InstallmentBillTemplate>(result.data.alimtalkMessageDocId);
      if (!alimtalkMessageDoc) {
        throw new Error('요청은 성공했으나, 메시지 정보를 받지 못했습니다.');
      }

      // 요청 결과를 메시지로 정리한다.
      const description = organizeKakaotalkResultMessage(alimtalkMessageDoc.recipientWithResultList);

      notification.success({
        message: '전송 요청 성공',
        description,
        className: 'pre-line-notification',
      });
    } catch (error) {
      console.error(error);
      const errorMessage = errorObjectToString(error);
      notification.error({
        message: '전송 요청 실패',
        description: errorMessage,
      });
      logger.logConsole(`${logName} - installmentBill/${bill._id}:: ${errorMessage}`, {
        level: 'error',
      });
    }
    setLoading(false);
  }, [bill._id, bill.receiverTel, bill.settlementDate, bill.storeName, bill.unpaidAmount, billRef]);

  return (
    <>
      <Button onClick={openModal}>개별 전송</Button>
      <Modal
        title={bill.storeName}
        open={open}
        onCancel={handleCancel}
        footer={[
          <div key='flex' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {bill.receiverTel && (
              <div>
                메시지:{' '}
                <Switch
                  key='text'
                  checkedChildren='포함'
                  unCheckedChildren='제외'
                  checked={!noMessage}
                  onClick={() => setNoMessage(!noMessage)}
                />
              </div>
            )}
            <div />
            <div>
              <Button key='cancel' onClick={handleCancel} loading={loading}>
                취소
              </Button>
              <Button key='download' loading={loading} onClick={() => billRef?.downloadBillImage()}>
                이미지 다운로드
              </Button>
              <Button
                key='submit'
                type='primary'
                disabled={disabled}
                loading={loading}
                onClick={sendBillWithImageUpload}>
                전송
              </Button>
            </div>
          </div>,
        ]}>
        <Bill ref={setBillRef} bill={bill} noMessage={noMessage} />
      </Modal>
    </>
  );
};

export default InstallmentBillModal;
