import { FileExcelOutlined } from '@ant-design/icons';
import { ProductStateCode, ProductStockHistoryDoc } from '@gooduncles/gu-app-schema';
import { Button, Checkbox, Popconfirm, notification } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { combineLatest, map } from 'rxjs';
import { useAuth } from 'src/stores/auth-context';
import { stockHistoryTypeOrder } from 'src/utils/product-stock-history-util';

import { productStorages } from 'src/lib/1/constant';
import { RangeEventValue, dayjsesToStringDateWhere, getDefaultDates, getOrderDate } from 'src/lib/1/date-util';
import { errorObjectToString } from 'src/lib/1/util';
import { jsonToExcelFile } from 'src/lib/1/xlsx-util';
import { FirebaseManager, WHERE } from 'src/lib/3/firebase-manager';
import {
  createProductStockHistory,
  observeProduct,
  observeProductStockHistoryForProductId,
  observeSupplier,
  updateProduct,
} from 'src/lib/4/firebase-short-cut';
import { ConsoleLogger } from 'src/lib/5/logger';

import { useTitle } from 'src/hooks/useTitle';

import classes from './ProductStock.module.scss';

import TableOptions from '../Common/TableOptions/TableOptions';
import ProductStockHistoryTable from './ProductStockTable/ProductStockHistoryTable';
import ProductStockTable, {
  ProductStockTableHandler,
  ProductStockTableRowData,
} from './ProductStockTable/ProductStockTable';

const logger = ConsoleLogger.getInstance();
const logName = '재고 관리';
const firebaseManager = FirebaseManager.getInstance();

const defaultValue = getDefaultDates(30, 3);

const ProductStock: FC = () => {
  useTitle('GU 관리자 | 재고 관리');
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState<ProductStockTableRowData[]>([]);
  const [showOnlyStockProduct, setShowOnlyStockProduct] = useState(true);
  const [inboundMode, setInboundMode] = useState(false);
  const [outboundMode, setOutboundMode] = useState(false);
  const editalbe = inboundMode || outboundMode;
  const [modifiedRows, setModifiedRows] = useState<ProductStockTableRowData[]>([]); // 수정된 항목들을 추적할 배열
  // product stock history
  const [selectedRow, setSelectedRow] = useState<ProductStockTableRowData>();
  const selectedRowId = selectedRow?._id;
  const [stockHistories, setStockHistories] = useState<ProductStockHistoryDoc[]>([]);
  // Date Range
  const [dates, setDates] = useState<RangeEventValue>(null);
  const [startValue, endValue] = useMemo(() => {
    return dates?.[0] && dates?.[1]
      ? dayjsesToStringDateWhere(dates[0], dates[1])
      : dayjsesToStringDateWhere(defaultValue[0], defaultValue[1]);
  }, [dates]);

  const rawDataTableRef = useRef<ProductStockTableHandler>(null);
  const onCheckboxChange = (e: CheckboxChangeEvent) => setShowOnlyStockProduct(e.target.checked);
  const onInboundMode = () => setInboundMode(true);
  const onOutboundMode = () => setOutboundMode(true);
  const offEditable = () => {
    rawDataTableRef.current?.handleCancelChanges();
    setInboundMode(false);
    setOutboundMode(false);
  };

  /**
   * 입고를 진행한다.
   */
  const inboundProductStocks = useCallback(async () => {
    if (!user) {
      notification.error({
        message: '로그인이 필요합니다.',
      });
      return;
    }
    const timestamp = getOrderDate();
    try {
      const filteredRows = modifiedRows.filter((row) => row.inbound);
      setLoading(true);
      firebaseManager.batchStart();
      let successCount = 0;
      for (const row of filteredRows) {
        if (!row.inbound) {
          notification.error({ message: `${row.fullName}의 입고 수량이 0입니다.` });
          continue;
        }
        const inbound = Number(row.inbound);
        if (inbound <= 0) {
          notification.error({ message: `${row.fullName}의 입고 수량이 양수가 아닙니다.` });
          continue;
        }
        const currentStock = row.stock;
        const newStock = Number(currentStock === Infinity ? 0 : currentStock) + inbound;
        await createProductStockHistory(
          {
            timestamp,
            userEmail: user.email,
            productId: row.productId,
            stock: inbound,
            beforeStock: currentStock,
            afterStock: newStock,
            type: 'INBOUND',
            reason: row.reason ?? null,
            requestId: null,
            relatedHistoryId: null,
            canceled: false,
          },
          true
        );
        await updateProduct(
          row._id,
          {
            stock: newStock,
          },
          true
        );
        successCount++;
      }
      await firebaseManager.batchEnd();
      setInboundMode(false);
      setModifiedRows([]);
      notification.success({
        message: `${successCount}건 입고되었습니다.`,
      });
    } catch (error) {
      const errorMessage = errorObjectToString(error);
      console.error(error);
      logger.logConsole(`${logName} - ${errorMessage}`, {
        level: 'error',
      });
      notification.error({
        message: '입고에 실패했습니다.',
      });
    }
    setLoading(false);
  }, [modifiedRows, user]);

  /**
   * 수동 출고를 진행한다.
   */
  const outboundProductStocks = useCallback(async () => {
    if (!user) {
      notification.error({
        message: '로그인이 필요합니다.',
      });
      return;
    }
    const timestamp = getOrderDate();
    try {
      const filteredRows = modifiedRows.filter((row) => row.outbound);
      setLoading(true);
      firebaseManager.batchStart();
      let successCount = 0;
      for (const row of filteredRows) {
        if (!row.outbound) {
          notification.error({ message: `${row.fullName}의 출고 수량이 0입니다.` });
          continue;
        }
        const outbound = Number(row.outbound);
        if (outbound <= 0) {
          notification.error({ message: `${row.fullName}의 출고 수량이 양수가 아닙니다.` });
          continue;
        }
        const currentStock = row.stock;
        if (currentStock === Infinity) {
          notification.error({ message: `${row.fullName}의 현재 재고가 무제한입니다.` });
          continue;
        }
        const newStock = Number(currentStock - outbound);
        if (newStock < 0) {
          notification.error({ message: `${row.fullName}의 재고가 출고 수량보다 적습니다.` });
          continue;
        }
        await createProductStockHistory(
          {
            timestamp,
            userEmail: user.email,
            productId: row.productId,
            stock: outbound,
            beforeStock: currentStock,
            afterStock: newStock,
            type: 'OUTBOUND',
            reason: row.reason ?? null,
            requestId: null,
            relatedHistoryId: null,
            canceled: false,
          },
          true
        );
        await updateProduct(
          row._id,
          {
            stock: newStock,
          },
          true
        );
        successCount++;
      }
      await firebaseManager.batchEnd();
      setOutboundMode(false);
      setModifiedRows([]);
      notification.success({
        message: `${successCount}건 출고되었습니다.`,
      });
    } catch (error) {
      const errorMessage = errorObjectToString(error);
      console.error(error);
      logger.logConsole(`${logName} - ${errorMessage}`, {
        level: 'error',
      });
      notification.error({
        message: '출고에 실패했습니다.',
      });
    }
    setLoading(false);
  }, [modifiedRows, user]);

  useEffect(() => {
    // product.stock이 Infinity가 아닌 경우에는 일정 기간 동안의 productStock document를 가져온다.
    const wheres: WHERE[] = showOnlyStockProduct ? [['stock', '!=', Infinity]] : [];
    const productObservable = observeProduct([
      ['state', 'in', [ProductStateCode.STOCK, ProductStateCode.OUTOFSTOCK]],
      ...wheres,
    ]);
    const supplierObservable = observeSupplier([['isDeleted', '==', false]]);
    const subscription = combineLatest([productObservable, supplierObservable]).subscribe(([products, suppliers]) => {
      const rowData = products.map((product) => {
        const supplier = suppliers.find((s) => s.conversionTable.map((c) => c.productId).includes(product.productId));
        return {
          ...product,
          supplierName: supplier?.name ?? 'unknown',
        };
      });
      setRowData(rowData);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [showOnlyStockProduct]);

  useEffect(() => {
    if (selectedRowId) {
      const subscription = observeProductStockHistoryForProductId(selectedRowId, {
        sortKey: 'timestamp',
        orderBy: 'desc',
        startValue,
        endValue,
      })
        .pipe(
          map((docs) =>
            docs
              .sort((a, b) => a.afterStock - b.afterStock)
              .sort((a, b) => stockHistoryTypeOrder.indexOf(a.type) - stockHistoryTypeOrder.indexOf(b.type))
          )
        )
        .subscribe(setStockHistories);
      return () => {
        subscription.unsubscribe();
      };
    }
  }, [endValue, selectedRowId, startValue]);

  const onExcelExport = useCallback(() => {
    jsonToExcelFile(
      rowData.map((row) => ({
        상품명: row.fullName,
        재고: row.stock,
        매입처: row.supplierName,
        보관: productStorages[row.storage],
      })),
      '재고목록'
    );
  }, [rowData]);

  return (
    <div className={`${classes.productStockContainer} height100`}>
      <div className={classes.toolbarContainer}>
        <div>
          <div>
            <Checkbox checked={showOnlyStockProduct} onChange={onCheckboxChange}>
              관리 상품만 보기
            </Checkbox>
            <Button icon={<FileExcelOutlined />} onClick={onExcelExport}>
              export
            </Button>
          </div>
          <div>
            {!editalbe && (
              <>
                <Popconfirm
                  title='수동 출고를 진행하시겠습니까?'
                  onConfirm={onOutboundMode}
                  okButtonProps={{ danger: true }}>
                  <Button danger>수동 출고 하기</Button>
                </Popconfirm>
                <Button type='primary' onClick={onInboundMode} style={{ marginLeft: 8 }}>
                  입고 하기
                </Button>
              </>
            )}
            {editalbe && (
              <>
                <Button danger onClick={offEditable} loading={loading}>
                  취소
                </Button>
                <Button
                  type='primary'
                  danger={outboundMode}
                  onClick={inboundMode ? inboundProductStocks : outboundProductStocks}
                  style={{ marginLeft: 8 }}
                  loading={loading}
                  disabled={modifiedRows.length === 0}>
                  {modifiedRows.length}건 {inboundMode ? '입고' : '수동 출고'} 하기
                </Button>
              </>
            )}
          </div>
        </div>
        <div>
          <TableOptions setDates={setDates} defaultValue={defaultValue} marginBottom={0} />
        </div>
      </div>
      <div className={classes.bodyContainer}>
        <ProductStockTable
          style={{ flex: 1 }}
          ref={rawDataTableRef}
          userEmail={user?.email ?? ''}
          rowData={rowData}
          inboundMode={inboundMode}
          outboundMode={outboundMode}
          modifiedRows={modifiedRows}
          // 행 선택
          setModifiedRows={setModifiedRows}
          setSelectedRow={setSelectedRow}
        />
        <ProductStockHistoryTable
          style={{ flex: 1 }}
          stockHistories={stockHistories}
          currentStock={selectedRow?.stock}
          userEmail={user?.email}
        />
      </div>
    </div>
  );
};

export default ProductStock;
