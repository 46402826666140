import { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { isProduction, logArt } from './lib/1/constant';
import { getPublicIp, sessionId } from './lib/1/util';
import { ConsoleLogger } from './lib/5/logger';

import './App.scss';
import Router from './Router';
import { AuthProvider } from './stores/auth-context';

const logger = ConsoleLogger.getInstance();
getPublicIp().then((ip) => {
  logger.setPublicIp(ip);
});
logger.logConsole(`세션 시작: ${sessionId}`);

const App: FC = () => {
  console.log(`%c\n${logArt}`, isProduction ? 'color:orange' : 'color:black');
  return (
    <BrowserRouter>
      <AuthProvider>
        <Router />
      </AuthProvider>
    </BrowserRouter>
  );
};

export default App;
