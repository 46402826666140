import { HyosungCMSMemberDoc } from '@gooduncles/gu-app-schema';
import { FC, useEffect, useState } from 'react';

import { observeHyosungCMSMember } from 'src/lib/4/firebase-short-cut';

import { useTitle } from 'src/hooks/useTitle';

import HyosungCMSMemberTable from 'src/components/NewSettlement/HyosungCMSMemberTable/HyosungCMSMemberTable';
import HyosungCMSMemberUploadModal from 'src/components/NewSettlement/UploadModal/HyosungCMSMemberUploadModal';

/**
 * 효성 회원정보 확인 및 업로드 하는 페이지
 */
const HyosungMemberPage: FC = () => {
  useTitle('GU 관리자 | 효성CMS회원 관리');
  const [rowData, setRowData] = useState<HyosungCMSMemberDoc[]>([]);

  useEffect(() => {
    const subscription = observeHyosungCMSMember([]).subscribe(setRowData);
    return () => subscription.unsubscribe();
  }, []);

  return (
    <div className='tabBody flexColumn height100'>
      <section className='flexRow flexSpaceBetween flexAlignCenter'>
        <span>회원수: {rowData.length}</span>
        <HyosungCMSMemberUploadModal currentMembers={rowData} />
      </section>
      <section className='flexRow height100'>
        <HyosungCMSMemberTable rowData={rowData} />
      </section>
    </div>
  );
};

export default HyosungMemberPage;
