import useSWR from 'swr';

import { getDeliverySpots } from 'src/lib/4/firebase-short-cut';

const useDeliverySpots = () => {
  const { data: deliverySpots } = useSWR('deliverySpots', () => getDeliverySpots());

  return {
    deliverySpots,
  };
};

export default useDeliverySpots;
