import { AgChartOptions } from 'ag-charts-community';
import { AgChartsReact } from 'ag-charts-react';
import { FC, useEffect, useState } from 'react';

import { dayOfWeekAsString, getWeeksInMonthStartMonday } from 'src/lib/1/date-util';
import { DailyOrderStatsDoc } from 'src/lib/2/schema';

interface DailyOrderChartProps {
  dailyOrderStats: DailyOrderStatsDoc[] | undefined;
  mode: 'line' | 'bubble';
}

const initialLineChartOptions = (dailyOrderStats: DailyOrderStatsDoc[]) => {
  const options: AgChartOptions = {
    autoSize: true,
    data: dailyOrderStats,
    theme: {
      overrides: {
        line: {
          series: {
            highlightStyle: {
              series: {
                strokeWidth: 3,
                dimOpacity: 0.2,
              },
            },
          },
        },
      },
    },
    title: {
      text: '일별 주문 통계',
      fontSize: 18,
    },
    subtitle: {
      text: '배송 완료일 기준',
    },
    series: [
      {
        type: 'line',
        xKey: 'date',
        yKey: 'orderSum',
        yName: '주문 총액',
        stroke: '#5BC0EB',
        strokeOpacity: 0.65,
        marker: {
          stroke: '#5BC0EB',
          fill: '#5BC0EB',
          enabled: false,
        },
      },
      {
        type: 'line',
        xKey: 'date',
        yKey: 'orderCount',
        yName: '주문 수',
        stroke: '#FA7921',
        strokeOpacity: 0.65,
        marker: {
          stroke: '#FA7921',
          fill: '#FA7921',
          enabled: false,
        },
      },
      {
        type: 'line',
        xKey: 'date',
        yKey: 'orderAvg',
        yName: '주문 단가',
        stroke: '#9BC53D',
        strokeOpacity: 0.65,
        marker: {
          stroke: '#9BC53D',
          fill: '#9BC53D',
          enabled: false,
        },
      },
    ],
    axes: [
      {
        position: 'bottom',
        type: 'category',
      },
      {
        position: 'left',
        type: 'number',
        keys: ['orderSum'],
        title: {
          text: '총액(₩)',
        },
      },
      {
        position: 'right',
        type: 'number',
        keys: ['orderCount'],
        title: {
          text: '주문 수',
        },
      },
      {
        position: 'right',
        type: 'number',
        keys: ['orderAvg'],
        title: {
          text: '단가(₩)',
        },
      },
    ],
    legend: {
      position: 'bottom',
    },
  };
  return options;
};

const initialBubbleChartOptions = (dailyOrderStats: DailyOrderStatsDoc[]) => {
  const data = dailyOrderStats
    .map((dailyOrderStat) => {
      const { date, dayOfWeek, orderCount, orderAvg, orderSum } = dailyOrderStat;
      return {
        date,
        weekNumber: getWeeksInMonthStartMonday(new Date(date.replace(/_/g, '-'))),
        dayOfWeek: String(dayOfWeek + 1),
        dayOfWeekKr: dayOfWeekAsString(dayOfWeek),
        orderCount,
        orderAvg: Math.round(orderAvg),
        orderSum: Math.round(orderSum),
      };
    })
    .sort((a, b) => Number(a.weekNumber) - Number(b.weekNumber))
    .sort((a, b) => Number(a.dayOfWeek) - Number(b.dayOfWeek));
  const options: AgChartOptions = {
    autoSize: true,
    data,
    title: {
      text: '일별 주문 통계(주간 변동 추이)',
      fontSize: 18,
    },
    subtitle: {
      text: '배송 완료일 기준',
    },
    series: [
      {
        type: 'scatter',
        xKey: 'weekNumber',
        xName: '주차',
        yKey: 'dayOfWeekKr',
        yName: '요일',
        sizeKey: 'orderSum',
        sizeName: '주문 총액',
        title: '요일별 주문 총액',
        marker: {
          size: 0,
          fill: '#5BC0EB',
          stroke: '#5BC0EB',
          fillOpacity: 0.65,
          strokeOpacity: 0.65,
        },
      },
      {
        type: 'scatter',
        xKey: 'weekNumber',
        xName: '일자',
        yKey: 'dayOfWeekKr',
        yName: '요일',
        sizeKey: 'orderCount',
        sizeName: '주문 수',
        title: '요일별 주문 수',
        marker: {
          size: 0,
          fill: '#FA7921',
          stroke: '#FA7921',
          fillOpacity: 0.65,
          strokeOpacity: 0.65,
        },
      },
      {
        type: 'scatter',
        xKey: 'weekNumber',
        xName: '일자',
        yKey: 'dayOfWeekKr',
        yName: '요일',
        sizeKey: 'orderAvg',
        sizeName: '주문 단가',
        title: '요일별 주문 단가',
        marker: {
          size: 0,
          fill: '#9BC53D',
          stroke: '#9BC53D',
          fillOpacity: 0.65,
          strokeOpacity: 0.65,
        },
      },
    ],
    axes: [
      {
        position: 'bottom',
        type: 'category',
        gridStyle: [
          {
            stroke: 'rgba(0,0,0,0.2)',
            lineDash: [0, 5, 0],
          },
        ],
        paddingInner: 0.2,
        paddingOuter: 0.3,
        tick: {
          color: 'black',
        },
        line: {
          color: undefined,
        },
      },
      {
        position: 'left',
        type: 'category',
        gridStyle: [],
        paddingInner: 0.2,
        paddingOuter: 0.3,
        tick: {
          color: 'black',
        },
        line: {
          color: undefined,
        },
      },
    ],
    legend: {
      position: 'bottom',
    },
  };
  return options;
};

const DailyOrderChart: FC<DailyOrderChartProps> = ({ dailyOrderStats, mode }) => {
  const [chartOptions, setChartOptions] = useState<AgChartOptions>({});
  useEffect(() => {
    if (dailyOrderStats) {
      if (mode === 'line') setChartOptions(initialLineChartOptions(dailyOrderStats));
      if (mode === 'bubble') setChartOptions(initialBubbleChartOptions(dailyOrderStats));
    }
  }, [dailyOrderStats, mode]);

  if (!dailyOrderStats) return <div>데이터가 존재하지 않습니다.</div>;

  return <AgChartsReact options={chartOptions} />;
};

export default DailyOrderChart;
