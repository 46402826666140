import { CrownOutlined, DownloadOutlined, SafetyOutlined } from '@ant-design/icons';
import { StoreDoc, UserDoc } from '@gooduncles/gu-app-schema';
import { NewValueParams } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { ColDef, ColGroupDef } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { Button, message, notification } from 'antd';
import confirm from 'antd/es/modal/confirm';
import { BaseOptionType } from 'antd/es/select';
import { get, set } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import { FC } from 'react';
import { useAuth } from 'src/stores/auth-context';
import { callSetUserStatus } from 'src/utils/firebase-callable';

import { storeStateList, userRoleList, userStatusKr } from 'src/lib/1/constant';
import { getDataDogLinkForUser, parseIfObject, storeCodeRegex, textSort } from 'src/lib/1/util';
import { UserStatus } from 'src/lib/2/schema';
import { FirebaseManager } from 'src/lib/3/firebase-manager';
import { ConsoleLogger } from 'src/lib/5/logger';
import {
  loadGridState,
  onValueSetterWithValidation,
  resetGridState,
  saveGridState,
  timestampFormatter,
} from 'src/lib/6/ag-grid-util';

import { StateCellRenderer } from 'src/components/AgGrid/CellRenderer/StoreStateCellRenderer/StoreStateCellRenderer';
import DownloadRawDataCell from 'src/components/Common/DownloadRawDataCell/DownloadRawDataCell';
import SelectableCell from 'src/components/Common/SelectableCell/SelectableCell';

import classes from './UserTable.module.scss';

import AddressInputButton from './AddressInputButton/AddressInputButton';
import DeadlineNotificationToggleCell from './DeadlineNotificationToggleCell/DeadlineNotificationToggleCell';
import DeviceInfoCell from './DeviceInfoCell/DeviceInfoCell';
import DisableNotificationAtDawnToggleCell from './DisableNotificationAtDawnToggleCell/DisableNotificationAtDawnToggleCell';
import KakaoNotificatoinToggleCell from './KakaoNotificatoinToggleCell/KakaoNotificatoinToggleCell';

const firebaseManager = FirebaseManager.getInstance();
const logger = ConsoleLogger.getInstance();
const logName = '사용자 관리';

const defaultColDef: ColDef<UserWithStore> = {
  sortable: true,
  resizable: true,
  filter: true,
};

export type UserWithStore = UserDoc & {
  key: string;
  store?: StoreDoc;
};

const UserRoleCellRenderer = (props: any) => {
  const role: UserWithStore['role'] = props.value;
  if (!role) return null;
  return (
    <span className={role}>
      {role === 'admin' ? <CrownOutlined /> : role === 'manager' ? <SafetyOutlined /> : ''}
      {role}
    </span>
  );
};

/**
 * 로그등 커스텀 요소 때문에 ag-grid-util에 있는 것을 사용하지 않았다.
 * @param paths field path가 직접적인 경로가 아닌 경우 따로 입력한다.
 */
const onCellValueChangedWithUpdate = async (
  params: NewValueParams<UserWithStore>,
  collection: string,
  docId?: string,
  paths?: string,
  options?: { nullable?: boolean }
) => {
  const { nullable = false } = options ?? {};
  const field = params.colDef.field;
  if (!field) {
    message.error('수정할 수 없는 필드정보입니다.');
    return;
  }

  if (!docId) {
    message.error('id값이 없습니다.');
    return;
  }

  const newValue = get(params.data, field);
  if ((newValue === undefined || newValue === null) && !nullable) {
    message.error('빈 값을 허용하지 않습니다.');
    return;
  }

  if (newValue !== params.oldValue) {
    try {
      if (Array.isArray(newValue)) {
        const uniqueArray = [...new Set(newValue)];
        await firebaseManager.updateDoc(collection, docId, { [paths ?? field]: uniqueArray });
      } else {
        await firebaseManager.updateDoc(collection, docId, { [paths ?? field]: newValue });
      }
      logger.logConsole(
        `${logName} - ${collection}/${docId}.${field}:: ${parseIfObject(params.oldValue)} -> ${parseIfObject(newValue)}`
      );
      message.success('변경완료');

      // 신규등록 대응 여부를 확인 하기위해 최초 등록하는 경우 알린다.
      if (!params.oldValue && field === 'store.storeCode') {
        const email = params.context.user.email;
        logger.logConsole(
          `${logName} - 매장코드 최초 등록\n매장명: ${params.data.store?.storeName}, 매장코드: ${newValue}\n작업자: ${email}`,
          {
            channel: '4-앱-알림',
          }
        );
      }
    } catch (error: any) {
      console.error(error);
      message.error(error?.message ?? '알 수 없는 에러 발생!');
      logger.logConsole(`${logName} - ${collection}/${docId}.${field}:: ${parseIfObject(error)}`, {
        level: 'error',
      });
    }
  }
};

const updateUserStatus = async (userId: string, status: UserStatus) => {
  const response = await callSetUserStatus({ userId, status });
  if (response.data.result === 'success') {
    notification.success({
      message: '사용자 상태 변경 완료',
    });
  } else {
    notification.error({
      message: '사용자 상태 변경 실패',
      description: '관리자에게 문의해주세요.',
    });
  }
  logger.logConsole(
    `${logName} - user/${userId}:: 사용자 상태변경${response.data.result === 'success' ? '' : ' 실패'}`,
    { level: response.data.result === 'success' ? undefined : 'error' }
  );
};

const onUserStatusCellChange = async (userId: string, status: UserStatus) => {
  switch (status) {
    case 'active':
      await updateUserStatus(userId, status);
      break;
    case 'inactive':
      confirm({
        title: '사용자 상태를 변경하시겠습니까?',
        content: '정지 상태로 변경하면 앱에서 로그인이 불가능합니다.',
        onOk: () => updateUserStatus(userId, status),
      });
      break;
    default:
      notification.info({
        message: '사용자 삭제는 개발자에게 요청해주세요.',
        description: '사용자 상태를 변경할 수 없습니다.',
      });
      logger.logConsole(`${logName} - user/${userId}:: 사용자 삭제 요청`, { level: 'error' });
      break;
  }
};

const UserStatusCell: FC = (params: any) => {
  const { value } = params;
  if (!value) return null;
  const user = params.data as UserWithStore;
  const options: BaseOptionType[] = Object.entries(userStatusKr).map(([value, label]) => ({ value, label }));
  return (
    <SelectableCell
      options={options}
      value={value}
      onChange={(newValue: UserStatus) => onUserStatusCellChange(user._id, newValue)}
      minWidth={100}
    />
  );
};

const autoGroupColumnDef = {
  minWidth: 200,
};

const UserTable: FC<{ rowData: UserWithStore[] }> = ({ rowData }) => {
  const { user } = useAuth();
  const [gridRef, setGridRef] = useState<AgGridReact<UserWithStore> | null>(null);
  const storeCodeList =
    rowData.length > 0
      ? (rowData.map((d) => d.store?.storeCode).filter((code) => code) as string[]).sort(textSort)
      : [];

  const columnDefs: (ColDef<UserWithStore> | ColGroupDef<UserWithStore>)[] = useMemo(
    () => [
      { headerName: 'UserId', field: '_id', width: 300, hide: true },
      { headerName: 'StoreId', field: 'store._id', width: 240, hide: true },
      { headerName: 'DeviceId', field: 'deviceTokenId', width: 240, hide: true },
      {
        headerName: 'DeviceInfo',
        field: 'deviceTokenId',
        width: 240,
        hide: true,
        cellRenderer: (params: any) => {
          return <DeviceInfoCell deviceTokenId={params.value ?? null} />;
        },
      },
      {
        headerName: '앱',
        field: 'deviceTokenId',
        enableRowGroup: true,
        width: 72,
        valueFormatter: (params) => {
          const token = params.value;
          return token ? '🟢' : '';
        },
      },
      {
        headerName: 'browser',
        field: 'userAgent.browser.name',
        width: 120,
        hide: true,
      },
      {
        headerName: 'version',
        field: 'version',
        width: 120,
      },
      {
        headerName: 'os',
        field: 'userAgent.os.name',
        width: 120,
      },
      {
        headerName: '*권한',
        field: 'role',
        enableRowGroup: true,
        width: 116,
        editable: true,
        cellRenderer: UserRoleCellRenderer,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: {
          values: userRoleList,
          cellRenderer: UserRoleCellRenderer,
        },
        valueSetter: (params) =>
          onValueSetterWithValidation(params, ['role', '_id'], (value: any) => {
            // value가 admin이면 admin만 가능
            if (params.context.user?.role) {
              if (value === 'admin' && params.context.user?.role === 'admin') {
                return true;
                // 다른 값은 manager, admin면 변경 가능
              } else if (['admin', 'manager'].includes(params.context.user.role)) {
                return true;
              }
            }
            return false;
          }),
        onCellValueChanged: async (params) => {
          const role = params.data.role;
          try {
            await firebaseManager.updateDoc('user', params.data._id, {
              role,
            });
            logger.logConsole(
              `${logName} - user/${params.data._id}.role:: ${parseIfObject(params.oldValue)} -> ${parseIfObject(role)}`
            );
            message.success('변경완료');
          } catch (error: any) {
            message.error(error?.message ?? '알 수 없는 에러 발생!');
            logger.logConsole(`${logName} - user/${params.data._id}.role:: ${parseIfObject(error)}`, {
              level: 'error',
            });
          }
        },
      },
      {
        headerName: '생성일',
        field: '_timeCreate',
        width: 200,
        valueFormatter: timestampFormatter,
      },
      {
        headerName: '업데이트일',
        field: '_timeUpdate',
        width: 200,
        valueFormatter: timestampFormatter,
        hide: true,
      },
      {
        headerName: '이메일',
        field: 'email',
        width: 240,
      },
      {
        headerName: '*전화번호',
        field: 'userTel',
        width: 180,
        editable: true,
        cellEditor: 'agTextCellEditor',
        valueSetter: (params) => onValueSetterWithValidation(params, ['_id']),
        onCellValueChanged: (params) => onCellValueChangedWithUpdate(params, 'user', params.data._id, 'userTel'),
      },
      {
        headerName: '*매장상태',
        field: 'store.state',
        enableRowGroup: true,
        width: 120,
        editable: true,
        cellRenderer: StateCellRenderer,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: {
          values: Object.keys(storeStateList),
          cellRenderer: StateCellRenderer,
        },
        valueSetter: (params) =>
          onValueSetterWithValidation(params, ['store.state'], (value) => {
            if (value === 'open' && !params.data.store?.storeCode) {
              notification.error({
                message: '매장코드가 없습니다.',
                description: '작업이 어려운 경우 슬랙으로 요청해주세요.',
              });
              const email = params.context.user.email;
              logger.logConsole(
                `${logName} - 코드가 없는 매장을 '운영'상태로 변경하려는 시도가 감지되었습니다.\n매장명: ${params.data.store?.storeNickname}\n요청자: ${email}`,
                {
                  channel: '4-앱-알림',
                }
              );
              return false;
            }
            return true;
          }),
        onCellValueChanged: (params) => onCellValueChangedWithUpdate(params, 'store', params.data.store?._id, 'state'),
      },
      {
        headerName: '*매장명',
        field: 'store.storeName',
        width: 180,
        editable: true,
        cellEditor: 'agTextCellEditor',
        valueSetter: (params) => onValueSetterWithValidation(params, ['store.storeName']),
        onCellValueChanged: (params) =>
          onCellValueChangedWithUpdate(params, 'store', params.data.store?._id, 'storeName'),
      },
      {
        headerName: '*매장명(관리)',
        field: 'store.storeNickname',
        width: 180,
        editable: true,
        cellStyle: { color: '#2C2C2C', fontWeight: 600 },
        cellEditor: 'agTextCellEditor',
        valueSetter: (params) => onValueSetterWithValidation(params, ['store.storeNickname']),
        onCellValueChanged: (params) =>
          onCellValueChangedWithUpdate(params, 'store', params.data.store?._id, 'storeNickname'),
      },
      {
        headerName: '*매장코드',
        field: 'store.storeCode',
        width: 120,
        editable: true,
        cellEditor: 'agTextCellEditor',
        valueSetter: (params) => {
          const result = onValueSetterWithValidation(
            params,
            ['store._id'],
            (value: any) => {
              if (params.context.storeCodeList.includes(value)) {
                message.error('이미 사용중인 매장코드입니다.');
                return false;
              }
              return true;
            },
            {
              nullify: true,
            }
          );
          const mathced = params.oldValue ? (params.oldValue as string).match(storeCodeRegex) : null;
          if (mathced === null) {
            return result;
          } else {
            message.error('규칙에 맞게 입력된 매장코드는 수정할 수 없습니다.');
            set(params.data, params.colDef.field as string, params.oldValue);
            return false;
          }
        },
        onCellValueChanged: (params) =>
          onCellValueChangedWithUpdate(params, 'store', params.data.store?._id, 'storeCode'),
      },
      {
        headerName: '주소',
        width: 260,
        field: 'store.address',
      },
      {
        hide: true,
        headerName: '시도',
        width: 80,
        field: 'store.sido',
      },
      {
        headerName: '시군구',
        width: 96,
        field: 'store.sigungu',
      },
      {
        headerName: '*상세주소',
        width: 200,
        field: 'store.addressDetail',
        editable: true,
        cellEditor: 'agTextCellEditor',
        valueSetter: (params) => onValueSetterWithValidation(params, ['store._id']),
        onCellValueChanged: (params) =>
          onCellValueChangedWithUpdate(params, 'store', params.data.store?._id, 'addressDetail'),
      },
      {
        headerName: '*즐겨찾기',
        headerTooltip: '개수',
        field: 'favoriteProducts',
        width: 108,
        editable: true,
        valueFormatter: (params) => {
          if (!params.value || !params.value.length) return '';
          return params.value.length;
        },
        valueSetter: (params) =>
          onValueSetterWithValidation(params, ['_id'], undefined, {
            type: 'array',
          }),
        onCellValueChanged: (params) => {
          onCellValueChangedWithUpdate(params, 'user', params.data._id, 'favoriteProducts');
        },
      },
      {
        headerName: '*맞춤상품',
        headerTooltip: '개수',
        field: 'store.unhiddenProducts',
        width: 108,
        editable: true,
        valueSetter: (params) =>
          onValueSetterWithValidation(params, ['store._id'], undefined, {
            type: 'array',
          }),
        onCellValueChanged: (params) => {
          onCellValueChangedWithUpdate(params, 'store', params.data.store?._id, 'unhiddenProducts');
        },
      },
      {
        headerName: '주소입력',
        colId: 'action02',
        width: 160,
        cellRenderer: (params: any) => {
          const data = params.data as UserWithStore;
          const storeId = data?.store?._id;
          if (!storeId) {
            return '';
          }
          return <AddressInputButton storeId={storeId} />;
        },
      },
      {
        headerName: '카톡알림',
        field: 'kakaoNotification',
        width: 160,
        cellRenderer: (params: any) => {
          const data = params.data as UserWithStore;
          if (!data?._id) {
            return null;
          }
          const value = params.value ?? false;
          return <KakaoNotificatoinToggleCell id={data._id} value={value} />;
        },
      },
      {
        headerName: '마감알림',
        field: 'deadlineNotification',
        width: 160,
        cellRenderer: (params: any) => {
          const data = params.data as UserWithStore;
          if (!data?._id) {
            return null;
          }
          const value = params.value ?? false;
          return <DeadlineNotificationToggleCell id={data._id} value={value} />;
        },
      },
      {
        headerName: '새벽 알림 금지',
        field: 'disableNotificationAtDawn',
        width: 160,
        cellRenderer: (params: any) => {
          const data = params.data as UserWithStore;
          if (!data?._id) {
            return null;
          }
          const value = params.value ?? false;
          return <DisableNotificationAtDawnToggleCell id={data._id} value={value} />;
        },
      },
      {
        headerName: '*계정상태',
        field: 'status',
        width: 136,
        cellRenderer: UserStatusCell,
      },
      {
        headerName: '액션',
        width: 160,
        hide: true,
        cellRenderer: DownloadRawDataCell,
      },
      {
        headerName: 'DataDog',
        colId: 'action03',
        width: 140,
        cellRenderer: (params: any) => {
          const data = params?.data as UserWithStore;
          const email = data?.email;
          if (!email) {
            return null;
          }
          return (
            <Button type='link' onClick={() => getDataDogLinkForUser(email)}>
              Link
            </Button>
          );
        },
      },
    ],
    []
  );

  const excelExport = useCallback(() => {
    const columns = gridRef?.columnApi.getColumns();
    if (!gridRef || !columns) {
      notification.error({ message: '파일이 준비되지 않았습니다.' });
      logger.logConsole(`${logName} - Excel export 실패`, {
        level: 'error',
      });
      return;
    }

    const columnsToExport = columns
      .map((column) => column.getColId())
      .filter((colId) => !['action01', 'action02'].includes(colId));
    gridRef?.api.exportDataAsExcel({
      author: 'gooduncles',
      sheetName: '사용자 관리',
      columnKeys: columnsToExport,
    });
    logger.logConsole(`${logName} - Excel export`);
  }, [gridRef]);

  // useEffect(() => {
  //   if (gridRef) {
  //     setTimeout(() => {
  //       gridRef?.api?.setFilterModel({
  //         role: {
  //           values: ['user'],
  //           filterType: 'set',
  //         },
  //       });
  //     }, 1000);
  //   }
  // }, [gridRef]);

  return (
    <div className='ag-theme-alpine height100'>
      <div className={classes.tableButtons}>
        <Button onClick={() => saveGridState(gridRef, 'userTable')}>설정 저장</Button>
        <Button onClick={() => loadGridState(gridRef, 'userTable')}>설정 불러오기</Button>
        <Button onClick={() => resetGridState(gridRef, 'userTable')}>설정 초기화</Button>
        <Button type='primary' onClick={excelExport} icon={<DownloadOutlined />}>
          Excel
        </Button>
      </div>
      <AgGridReact
        className={classes.tableContainer}
        ref={setGridRef}
        onGridReady={() => loadGridState(gridRef, 'userTable')}
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        context={{ user, storeCodeList }}
        // 편집 완료후 스크롤 이동을 막는다.
        suppressScrollOnNewData={true}
        alwaysShowHorizontalScroll={true}
        animateRows={true}
        autoGroupColumnDef={autoGroupColumnDef}
        rowGroupPanelShow={'always'}
        // sideBar={'columns'}
      />
    </div>
  );
};

export default UserTable;
