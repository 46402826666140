import { ProductStockChangeType, ProductStockHistory } from '@gooduncles/gu-app-schema';

/**
 * 상품 요청 수동 변경으로 인해 발생한 변경 사항의 정렬 순서를 정의합니다.
 */
export const stockHistoryTypeOrder = ['INBOUND', 'OUTBOUND', 'CANCEL_INBOUND', 'CANCEL_OUTBOUND'];

/**
 * 재고 변경 타입에 따라 변경 후 재고량을 계산합니다.
 * @param currentStock
 * @param changeVolume
 * @param type
 * @returns afterStock
 */
export const calcAfterStock = (currentStock: number, changeVolume: number, type: ProductStockChangeType) => {
  switch (type) {
    // 신규 입고 & 출고 취소의 경우 기존 재고량을 증가시킨다.
    case 'INBOUND':
    case 'CANCEL_OUTBOUND':
      // 무한대인 경우 0으로 초기화
      return (currentStock === Infinity ? 0 : currentStock) + changeVolume;
    // 신규 출고 & 입고 취소의 경우 기존 재고량을 감소시킨다.
    case 'OUTBOUND':
    case 'CANCEL_INBOUND':
      // 무한대인 경우 0으로 초기화
      return (currentStock === Infinity ? 0 : currentStock) - changeVolume;
    // 무한대 = 재고 관리 해제
    case 'INFINITY':
      return Infinity;
    case 'ETC':
      return currentStock;
  }
};

const productStockHsitoryTypeMapForSync: Record<string, ProductStockChangeType> = {
  OUTBOUND: 'INBOUND',
  CANCEL_OUTBOUND: 'OUTBOUND',
};

/**
 * 관리자의 수동 발주 <-> 출고 변경에 따라{@link createProductRequestDataManually} 상품의 출고량이 변경되는 경우,
 * 선택적으로 해당 변경량을 그대로 재고에 반영할 수 있다.
 *
 */
export const createProductStockHistoryForSync = (productStockHistory: ProductStockHistory) => {
  if (!productStockHsitoryTypeMapForSync[productStockHistory.type]) {
    throw new Error(`해당 타입은 재고 변경에 따른 동기화를 지원하지 않습니다. type: ${productStockHistory.type}`);
  }
  const type = productStockHsitoryTypeMapForSync[productStockHistory.type];
  const stock = productStockHistory.stock;
  const currentStock = productStockHistory.afterStock;
  const afterStock = calcAfterStock(currentStock, stock, type);
  const newProductStockHistoryForSync: ProductStockHistory = {
    ...productStockHistory,
    reason: '[자동] 재고 동기화',
    type,
    stock,
    beforeStock: currentStock,
    afterStock,
  };
  return newProductStockHistoryForSync;
};
