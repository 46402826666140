import { Tabs } from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { combineLatest } from 'rxjs';

import { RangeEventValue, dayjsesToDateWhere, getDefaultDates } from 'src/lib/1/date-util';
import { AlimtalkTemplateCode } from 'src/lib/2/schema-nhn-notification';
import { observeAlimtalkMessage, observeStore } from 'src/lib/4/firebase-short-cut';

import { useTitle } from 'src/hooks/useTitle';

import TableOptions from '../Common/TableOptions/TableOptions';
import AlimtalkMessageTable, { AlimtalkMessageTableRowData } from './AlimtalkMessageTable';

const defaultValue = getDefaultDates(3, 1);

interface AlimtalkMessageProps {
  tab: AlimtalkTemplateCode;
}

const AlimtalkMessage: FC<AlimtalkMessageProps> = ({ tab }) => {
  useTitle(`GU 관리자 | 알림톡 메시지 - ${tab}`);
  const navigate = useNavigate();
  const [rowData, setRowData] = useState<AlimtalkMessageTableRowData[]>([]);
  const [dates, setDates] = useState<RangeEventValue>(null);
  const [leftDate, rightDate] = useMemo(() => {
    return dates?.[0] && dates?.[1]
      ? dayjsesToDateWhere(dates[0], dates[1])
      : dayjsesToDateWhere(defaultValue[0], defaultValue[1]);
  }, [dates]);

  const onTabChange = (key: string) => {
    navigate(`../${key}`);
  };

  useEffect(() => {
    const storeObservable = observeStore();
    const alimtalkMessageObservable = observeAlimtalkMessage([], {
      sortKey: '_timeCreate',
      orderBy: 'desc',
      startValue: leftDate,
      endValue: rightDate,
    });

    const subscription = combineLatest([storeObservable, alimtalkMessageObservable]).subscribe(([stores, docs]) => {
      const flatten = docs
        .map((doc) => {
          const { recipientWithResultList, ...data } = doc;
          return recipientWithResultList.map((recipientWithResult, idx) => ({
            ...data,
            ...recipientWithResult,
            recipientSeq: idx + 1,
            // 정산서의 경우 수신자의 이름이 담겨있다.
            storeNickname:
              tab === 'installment-bill'
                ? recipientWithResult.templateParameter.store ?? ''
                : stores.find((store) => store._id === recipientWithResult.recipientGroupingKey)?.storeNickname ?? '',
          }));
        })
        .flat();
      setRowData(flatten);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [leftDate, rightDate, tab]);

  return (
    <div className='height100 flexColumn'>
      <TableOptions setDates={setDates} defaultValue={defaultValue} />
      <Tabs
        type='card'
        activeKey={tab}
        style={{ height: '100%', overflow: 'scroll' }}
        tabBarStyle={{ marginBottom: 0 }}
        onChange={onTabChange}
        items={[
          {
            key: 'installment-bill',
            label: '정산서',
            children: (
              <AlimtalkMessageTable rowData={rowData.filter((data) => data.templateCode === 'installment-bill')} />
            ),
          },
          {
            key: 'volume-change',
            label: '수량 변경 알림',
            children: (
              <AlimtalkMessageTable rowData={rowData.filter((data) => data.templateCode === 'volume-change')} />
            ),
          },
          {
            key: 'sold-out-product',
            label: '품절 알림',
            children: (
              <AlimtalkMessageTable rowData={rowData.filter((data) => data.templateCode === 'sold-out-product')} />
            ),
          },
          {
            key: 'deadline-notice',
            label: '주문 마감 알림',
            children: (
              <AlimtalkMessageTable rowData={rowData.filter((data) => data.templateCode === 'deadline-notice')} />
            ),
          },
        ]}
      />
    </div>
  );
};

export default AlimtalkMessage;
