import { DeliveryRouteHistoryDoc, OrderStatusCode } from '@gooduncles/gu-app-schema';
import { notification } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { TaskSpot, createTaskSpots } from 'src/utils/partners-util';

import { LocalStorageKey } from 'src/lib/1/constant';
import { formatDate } from 'src/lib/1/date-util';
import { errorObjectToString, getLocalStorageValue } from 'src/lib/1/util';
import {
  getDeliveryRouteHistory,
  getDeliverySpots,
  getOrders,
  getStoreIssues,
  getStores,
} from 'src/lib/4/firebase-short-cut';

/**
 * 임시 저장된 배송 동선을 가져온다.
 */
const getTemporaryDeliveryRoute = async (date: string) => {
  const fromLocal = getLocalStorageValue<DeliveryRouteHistoryDoc>(LocalStorageKey.temporaryDeliveryRoute);
  return fromLocal && fromLocal.date === date ? fromLocal : null;
};

const useTaskSpots = (date: string, preview = false) => {
  const [loading, setLoading] = useState(false);
  const [taskSpots, setTaskSpots] = useState<TaskSpot[]>([]);
  const [isRouteConfirmed, setIsRouteConfirmed] = useState(false);

  const mutateTaskSpots = useCallback(async () => {
    setLoading(true);
    try {
      const stores = await getStores([['storeCode', '!=', null]]);
      const deliverySpots = await getDeliverySpots([['isDeleted', '!=', true]]);
      const deliveryRouteHistory = await getDeliveryRouteHistory(date);
      setIsRouteConfirmed(!!deliveryRouteHistory);
      const orders = await getOrders(
        preview
          ? [['orderStatus', 'in', [OrderStatusCode.ACCEPTED, OrderStatusCode.SUBMITTED]]]
          : [['orderStatus', '==', OrderStatusCode.ACCEPTED]]
      );
      const issueDate = formatDate(date, "yyyy-MM-dd'T'00:00:00+0900");
      const storeIssues = await getStoreIssues([
        ['status', '==', '입력'],
        ['date', '==', issueDate],
      ]);
      const filteredStoreIssues = storeIssues.filter((issue) => ['배송', '회수'].includes(issue.category ?? ''));
      // 임시 저장된 배송 동선
      const temporaryDeliveryRoute = await getTemporaryDeliveryRoute(date);

      const taskSpots0 = createTaskSpots(
        date,
        deliveryRouteHistory,
        stores,
        deliverySpots,
        orders,
        filteredStoreIssues,
        temporaryDeliveryRoute
      );
      setTaskSpots(taskSpots0);
    } catch (error) {
      console.error(error);
      const description = errorObjectToString(error);
      notification.error({
        message: '배송 동선을 불러오는 중에 오류가 발생했습니다.',
        description,
      });
    }
    setLoading(false);
  }, [date, preview]);

  useEffect(() => {
    mutateTaskSpots();
  }, [mutateTaskSpots]);

  return {
    loading,
    taskSpots,
    isRouteConfirmed,
    mutateTaskSpots,
  };
};

export default useTaskSpots;
