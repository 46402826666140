import { ProductDoc } from '@gooduncles/gu-app-schema';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { ColDef, ColGroupDef } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { uniqBy } from 'lodash-es';
import { ForwardRefRenderFunction, HTMLAttributes, forwardRef, useImperativeHandle, useMemo, useState } from 'react';

import { formatNumber } from 'src/lib/1/util';
import { onCellValueValidation } from 'src/lib/6/ag-grid-util';

import CancleStockButton from './CancelStockButton';
import StockDepletionDateCellRenderer from './StockDepletionDateCellRenderer';

export type ProductStockTableRowData = ProductDoc & {
  supplierName: string;
  inbound?: number;
  outbound?: number;
  reason?: string;
};

type ProductStockTableProps = HTMLAttributes<HTMLDivElement> & {
  rowData: ProductStockTableRowData[];
  inboundMode: boolean;
  outboundMode: boolean;
  modifiedRows: ProductStockTableRowData[];
  userEmail?: string;
  setModifiedRows: React.Dispatch<React.SetStateAction<ProductStockTableRowData[]>>;
  setSelectedRow: React.Dispatch<React.SetStateAction<ProductStockTableRowData | undefined>>;
};

export interface ProductStockTableHandler {
  handleCancelChanges: () => void;
}

const defaultColDef: ColDef<ProductStockTableRowData> = {
  flex: 1,
  sortable: true,
  resizable: true,
  filter: true,
  minWidth: 98,
};

const ProductStockTable: ForwardRefRenderFunction<ProductStockTableHandler, ProductStockTableProps> = (
  { rowData, inboundMode, outboundMode, modifiedRows, setModifiedRows, setSelectedRow, userEmail, ...props },
  forwardRef
) => {
  const [gridApi, setGridRef] = useState<AgGridReact<ProductStockTableRowData> | null>(null);

  const columnDefs: (ColDef<ProductStockTableRowData> | ColGroupDef<ProductStockTableRowData>)[] = useMemo(
    () => [
      { headerName: '상품코드', field: '_id', sort: 'asc' },
      {
        headerName: '품명',
        field: 'fullName',
        minWidth: 200,
      },
      {
        headerName: '매입처',
        field: 'supplierName',
        minWidth: 120,
      },
      {
        headerName: '재고',
        field: 'stock',
        valueGetter: (params: any) => (params.data.stock === undefined ? Infinity : params.data.stock),
        valueFormatter: ({ value }) => (value === Infinity ? '∞' : formatNumber(value)),
      },
      {
        headerName: '입고',
        field: 'inbound',
        hide: !inboundMode,
        editable: inboundMode,
        cellDataType: 'number',
        valueSetter: (params) => onCellValueValidation(params, 'natural'),
        cellStyle: {
          color: 'var(--blue300)',
          fontWeight: 'bold',
        },
      },
      {
        headerName: '수동 출고',
        field: 'outbound',
        hide: !outboundMode,
        editable: outboundMode,
        cellDataType: 'number',
        valueSetter: (params) => onCellValueValidation(params, 'natural'),
        cellStyle: {
          color: 'var(--red300)',
          fontWeight: 'bold',
        },
      },
      {
        headerName: '메모',
        field: 'reason',
        hide: !(inboundMode || outboundMode),
        editable: inboundMode || outboundMode,
        cellDataType: 'string',
      },
      {
        headerName: '무제한',
        minWidth: 120,
        hide: inboundMode || outboundMode,
        cellRenderer: (params: any) => {
          const data = params.data as ProductStockTableRowData;
          return data.productId && data.stock !== Infinity && data.stock !== undefined && userEmail ? (
            <CancleStockButton productId={data.productId} stock={data.stock} userEmail={userEmail} />
          ) : null;
        },
      },
      {
        headerName: '예상 소진일 (30일간 출고량)',
        field: 'stock',
        width: 120,
        filter: false,
        cellRenderer: (params: any) => {
          const data = params.data as ProductStockTableRowData;
          if (!data.productId || data.stock === Infinity || data.stock === undefined) return null;
          return <StockDepletionDateCellRenderer productId={data.productId} stock={data.stock} />;
        },
      },
    ],
    [inboundMode, outboundMode, userEmail]
  );

  useImperativeHandle(forwardRef, () => ({
    handleCancelChanges: () => {
      gridApi?.api.stopEditing(true);
      // 수정 모드 해제에 따라 수정 항목들을 초기화한다.
      gridApi?.api.applyTransaction({
        update: modifiedRows.map((row) => ({ ...row, inbound: undefined, outbound: undefined, reason: undefined })),
      });
      setModifiedRows([]);
    },
  }));

  return (
    <div className={inboundMode || outboundMode ? 'ag-theme-alpine-dark' : 'ag-theme-alpine'} {...props}>
      <AgGridReact
        ref={setGridRef}
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        suppressCopyRowsToClipboard={true}
        getRowId={(params) => params.data._id}
        onCellValueChanged={(params) => {
          const { field } = params.colDef;
          if (!field) return;
          if (params.oldValue !== params.newValue) {
            setModifiedRows((prev) => {
              // 취소된 수정은 배열에서 제거
              if (!params.newValue) {
                return prev.filter((row) => row._id !== params.data._id);
              }
              const newRows = [...prev, params.data];
              return uniqBy(newRows, '_id');
            });
          }
        }}
        // 행 선택
        rowSelection='single'
        onSelectionChanged={(e) => {
          const selectedRow = e.api.getSelectedRows()[0];
          setSelectedRow(selectedRow);
        }}
      />
    </div>
  );
};

export default forwardRef(ProductStockTable);
