import { FC } from 'react';

import useDevices from 'src/hooks/useDevices';

type DeviceInfoCellProps = {
  deviceTokenId: string | null;
};

const DeviceInfoCell: FC<DeviceInfoCellProps> = ({ deviceTokenId }) => {
  const { devices } = useDevices();
  if (!devices) return <span>loading</span>;

  const device = devices?.find((d) => d.tokenId === deviceTokenId);

  if (!device) return <span></span>;

  const warn = device.systemVersion === '12' && device.readableVersion === '1.0.4.5004';
  const error = device.systemVersion === '13' && device.readableVersion === '1.0.4.5004';
  return (
    <span>
      {warn ? '🟡' : ''}
      {error ? '🔴' : ''}
      {device.systemName} / {device.systemVersion} / {device.readableVersion}
    </span>
  );
};

export default DeviceInfoCell;
