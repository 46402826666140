import { ProductSnapshotDoc } from '@gooduncles/gu-app-schema';
import { AgCartesianChartOptions } from 'ag-charts-community';
import { AgChartsReact } from 'ag-charts-react';
import { min } from 'lodash-es';
import { FC, useEffect, useState } from 'react';

import { formatNumber } from 'src/lib/1/util';
import { calcProductMargin } from 'src/lib/4/product-util';

/**
 * 스냅샷 id에서 날짜 정보만 추출합니다.
 * @param snapshotId '2023-11-15-P123456'
 */
const getDateFromSnapshotId = (snapshotId: string) => {
  const datePattern = /\d{4}-\d{2}-\d{2}/;
  const date = snapshotId.match(datePattern)?.[0];
  return date;
};

const initialLineChartOptions = (rowData: ProductSnapshotDoc[]) => {
  const data = rowData
    .map((doc) => ({
      date: getDateFromSnapshotId(doc._id),
      price: doc.price,
      cost: doc.cost,
      margin: min([calcProductMargin(doc.price, doc.cost), 100]),
    }))
    .filter((doc) => doc.date && doc.price);

  const options: AgCartesianChartOptions = {
    autoSize: true,
    data,
    title: {
      text: `${rowData?.[0]?.fullName} 최근 3개월간 가격 추이`,
      fontSize: 14,
    },
    series: [
      {
        type: 'area',
        xKey: 'date',
        yKey: 'price',
        yName: '가격',
        fillOpacity: 0.3,
      },
      {
        type: 'area',
        xKey: 'date',
        yKey: 'cost',
        yName: '매입가',
        fillOpacity: 0.3,
      },
      {
        type: 'line',
        xKey: 'date',
        yKey: 'margin',
        yName: '마진율',
      },
    ],
    axes: [
      {
        position: 'bottom',
        type: 'category',
      },
      {
        position: 'left',
        type: 'number',
        keys: ['price', 'cost'],
        title: {
          text: '금액(₩)',
        },
        label: {
          formatter: (params) => formatNumber(params.value),
        },
      },
      {
        position: 'right',
        type: 'number',
        keys: ['margin'],
        title: {
          text: '마진율(%)',
        },
        min: 0,
        max: 100,
        nice: false,
      },
    ],
  };

  return options;
};

interface ProductLineChartProps {
  rowData: ProductSnapshotDoc[];
}

const ProductLineChart: FC<ProductLineChartProps> = ({ rowData }) => {
  const [options, setOptions] = useState<AgCartesianChartOptions>({
    data: [],
    series: [],
  });

  useEffect(() => {
    setOptions(initialLineChartOptions(rowData));
  }, [rowData]);

  return <AgChartsReact options={options} />;
};

export default ProductLineChart;
