import { Switch, message, notification } from 'antd';
import { FC, useCallback, useState } from 'react';

import { parseIfObject } from 'src/lib/1/util';
import { updateUser } from 'src/lib/4/firebase-short-cut';
import { ConsoleLogger } from 'src/lib/5/logger';

const logger = ConsoleLogger.getInstance();
const logName = '사용자 관리';

interface DeadlineNotificationToggleCellProps {
  id: string;
  value: boolean;
}

const DeadlineNotificationToggleCell: FC<DeadlineNotificationToggleCellProps> = ({ id, value }) => {
  const [loading, setLoading] = useState(false);

  const handleSwitchChange = useCallback(async (value: any, userId: string) => {
    setLoading(true);
    try {
      await updateUser(userId, { deadlineNotification: value });
      message.success('변경완료.');
    } catch (error: any) {
      notification.error({
        message: '에러발생',
        description: error.message,
      });
      logger.logConsole(`${logName} - user/${userId}.deadlineNotification:: ${parseIfObject(error)}`, {
        level: 'error',
      });
    }
    setLoading(false);
  }, []);

  return <Switch loading={loading} checked={value} onChange={(v) => handleSwitchChange(v, id)} />;
};

export default DeadlineNotificationToggleCell;
