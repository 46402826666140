import { FC } from 'react';

interface FlexRowItemProps {
  label: string;
  ratio?: [number, number];
  value?: string | JSX.Element;
  labelStyle?: React.CSSProperties;
  valueStyle?: React.CSSProperties;
}

const initLabelStyle = {
  fontSize: 14,
  color: 'var(--gray700)',
};

const initValueStyle = {
  fontSize: 15,
  color: 'var(--gray900)',
  fontWeight: 500,
};

const FlexRowItem: FC<FlexRowItemProps> = ({ label, value, ratio = [1, 2], labelStyle, valueStyle }) => (
  <div
    style={{
      display: 'flex',
      width: '100%',
      lineHeight: 1.5,
      padding: '6px 0',
    }}>
    <div
      style={{
        ...initLabelStyle,
        ...labelStyle,
        flex: ratio[0],
      }}>
      {label}
    </div>
    {value && (
      <div
        style={{
          ...initValueStyle,
          ...valueStyle,
          flex: ratio[1],
        }}>
        {value}
      </div>
    )}
  </div>
);

export default FlexRowItem;
