import { FC, useEffect, useMemo, useState } from 'react';

import { RangeEventValue, dayjsesToDateWhere, getDefaultDates } from 'src/lib/1/date-util';
import { observeMobileMessage } from 'src/lib/4/firebase-short-cut';

import { useTitle } from 'src/hooks/useTitle';

import TableOptions from '../Common/TableOptions/TableOptions';
import MobileMessageTable, { MobileMessageTableRowData } from './MobileMessageTable';

const defaultValue = getDefaultDates(1, 1);

const MobileMessageDashboard: FC = () => {
  useTitle('GU 관리자 | 문자 메시지');
  const [rowData, setRowData] = useState<MobileMessageTableRowData[]>([]);
  const [dates, setDates] = useState<RangeEventValue>(null);
  const [leftDate, rightDate] = useMemo(() => {
    return dates?.[0] && dates?.[1]
      ? dayjsesToDateWhere(dates[0], dates[1])
      : dayjsesToDateWhere(defaultValue[0], defaultValue[1]);
  }, [dates]);

  useEffect(() => {
    const subscription = observeMobileMessage([], {
      sortKey: '_timeCreate',
      orderBy: 'desc',
      startValue: leftDate,
      endValue: rightDate,
    }).subscribe((docs) => {
      const flatten = docs
        .map((doc) => {
          const { recipientWithResultList, ...data } = doc;
          return recipientWithResultList.map((recipientWithResult, idx) => ({
            ...data,
            ...recipientWithResult,
            recipientSeq: idx + 1,
          }));
        })
        .flat();
      setRowData(flatten);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [leftDate, rightDate]);

  return (
    <div className='height100 flexColumn'>
      <TableOptions setDates={setDates} defaultValue={defaultValue}>
        count: {rowData.length}
      </TableOptions>
      <MobileMessageTable rowData={rowData} />
    </div>
  );
};

export default MobileMessageDashboard;
