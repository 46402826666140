import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Input, InputNumber, Row, Switch, TimePicker, Tooltip, notification } from 'antd';
import dayjs from 'dayjs';
import { FC, useCallback, useState } from 'react';

import { weekdayToKr } from 'src/lib/1/constant';
import { formatNumber } from 'src/lib/1/util';
import { ConsoleLogger } from 'src/lib/5/logger';

import useCommerceConf from 'src/hooks/useCommerceConf';

import Loading from 'src/components/Loading/Loading';

import classes from './AppConfig.module.scss';

type OperationFormValue = {
  openAt: dayjs.Dayjs;
  closeAt: dayjs.Dayjs;
  sun: number;
  mon: number;
  tue: number;
  wed: number;
  thu: number;
  fri: number;
  sat: number;
  deliveryFee: number;
  minAmountForFreeDelivery: number;
};

type UrgentFormValue = {
  isClosed: boolean;
  msgClosed: string;
};

type BasicFormValue = {
  notice: string;
};

const logger = ConsoleLogger.getInstance();
const logName = '설정';

const timeFormat = 'HH';
const formLayout = {
  content: {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  },
  tail: {
    wrapperCol: { offset: 8, span: 16 },
  },
};

const AppConfig: FC = () => {
  const { commerceConf, isConfLoading, mutateCommerceConf } = useCommerceConf();
  const [isFormLocked, setIsFormLocked] = useState<boolean>(true);
  const [operationForm] = Form.useForm();
  const [urgentForm] = Form.useForm();
  const [basicForm] = Form.useForm();

  const toggleForm = useCallback((isLocked: boolean) => {
    setIsFormLocked(isLocked);
    if (!isLocked) {
      logger.logConsole(`${logName} - 운영 설정 잠금 해제`);
    }
  }, []);

  const onSubmitOperation = useCallback(
    async (values: OperationFormValue) => {
      const openAt = values.openAt.hour();
      const closeAt = values.closeAt.hour();
      const deliveryDateRules = [values.sun, values.mon, values.tue, values.wed, values.thu, values.fri, values.sat];
      const deliveryFee = values.deliveryFee;
      const minAmountForFreeDelivery = values.minAmountForFreeDelivery;
      try {
        await mutateCommerceConf({
          openAt,
          closeAt,
          deliveryDateRules,
          deliveryFee,
          minAmountForFreeDelivery,
        });
        notification.success({
          message: '반영 완료',
        });
        logger.logConsole(`${logName} - 운영 설정이 변경되었습니다.`, {
          channel: '4-앱-알림',
        });
      } catch (error) {
        console.error(error);
        notification.error({
          message: '에러 발생',
          description: `에러: ${error}`,
        });
      }
    },
    [mutateCommerceConf]
  );

  const onSubmitUrgent = useCallback(
    async (values: UrgentFormValue) => {
      try {
        await mutateCommerceConf({
          closed: {
            isClosed: values.isClosed,
            msgClosed: values.msgClosed,
          },
        });
        notification.success({
          message: '반영 완료',
        });
        logger.logConsole(`${logName} - 긴급 설정이 변경되었습니다.`, {
          channel: '4-앱-알림',
        });
      } catch (error) {
        console.error(error);
        notification.error({
          message: '에러 발생',
          description: `에러: ${error}`,
        });
      }
    },
    [mutateCommerceConf]
  );

  const onSubmitBasic = useCallback(
    async (values: BasicFormValue) => {
      try {
        await mutateCommerceConf({
          notice: values.notice,
        });
        notification.success({
          message: '반영 완료',
        });
        logger.logConsole(`${logName} - 메인 화면 한 줄 공지를 변경했습니다.\n${values.notice}`, {
          channel: '4-앱-알림',
        });
      } catch (error) {
        console.error(error);
        notification.error({
          message: '에러 발생',
          description: `에러: ${error}`,
        });
      }
    },
    [mutateCommerceConf]
  );

  const onDeleteNotice = useCallback(async () => {
    try {
      await mutateCommerceConf({
        notice: null,
      });
      notification.success({
        message: '반영 완료',
      });
      logger.logConsole(`${logName} - 메인 화면 한 줄 공지를 삭제했습니다.`, {
        channel: '4-앱-알림',
      });
    } catch (error) {
      console.error(error);
      notification.error({
        message: '에러 발생',
        description: `에러: ${error}`,
      });
    }
  }, [mutateCommerceConf]);

  if (!commerceConf) {
    return <div>현재 설정을 불러오지 못했습니다.</div>;
  }

  return isConfLoading ? (
    <Loading title='설정을 불러오고 있습니다.' />
  ) : (
    <div className={classes.appConfigContainer}>
      <div>
        <h2>기본 설정</h2>
        <div className={classes.formContainer}>
          <Form form={basicForm} {...formLayout.content} layout='horizontal' onFinish={onSubmitBasic}>
            <Form.Item name='notice' label='메인 화면 한 줄 공지' initialValue={commerceConf.notice}>
              <Input />
            </Form.Item>
            <Form.Item {...formLayout.tail}>
              <Button type='primary' htmlType='submit'>
                변경
              </Button>
              <Button danger onClick={onDeleteNotice} style={{ marginLeft: 8 }}>
                삭제
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <div>
        <h2>운영 설정</h2>
        <div>
          <Checkbox checked={isFormLocked} onChange={(e) => toggleForm(e.target.checked)}>
            잠금
          </Checkbox>
        </div>
        <div className={`${classes.formContainer} ${isFormLocked ? classes.locked : ''}`}>
          <Form
            form={operationForm}
            layout='horizontal'
            {...formLayout.content}
            disabled={isFormLocked}
            onFinish={onSubmitOperation}>
            <Form.Item
              name='openAt'
              label='주문 시작 시간'
              initialValue={dayjs(`${String(commerceConf.openAt).padStart(2, '0')}:00`, timeFormat)}>
              <TimePicker format={timeFormat} />
            </Form.Item>
            <Form.Item
              name='closeAt'
              label='주문 마감 시간'
              initialValue={dayjs(`${String(commerceConf.closeAt).padStart(2, '0')}:00`, timeFormat)}>
              <TimePicker format={timeFormat} />
            </Form.Item>
            <Form.Item label='배송에 걸리는 기간(일)'>
              <Tooltip title='1: 다음날 도착, 2: 다다음날 도착, 3: 3일 후 도착...'>
                <QuestionCircleOutlined />
              </Tooltip>
            </Form.Item>
            <Row>
              <Col span={8}></Col>
              <Col span={16}>
                <Row>
                  {Object.entries(weekdayToKr).map(([name, label], idx) => (
                    <Col key={name} span={6}>
                      <Form.Item name={name} initialValue={commerceConf.deliveryDateRules[idx]}>
                        <InputNumber addonBefore={label} min={1} max={10} />
                      </Form.Item>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
            <Form.Item name='deliveryFee' label='배송비' initialValue={commerceConf.deliveryFee}>
              <InputNumber min={0} formatter={(n) => formatNumber(n ?? 0)} addonAfter='원' />
            </Form.Item>
            <Form.Item
              name='minAmountForFreeDelivery'
              label='배송비 면제를 위한 최소 주문 금액'
              initialValue={commerceConf.minAmountForFreeDelivery}>
              <InputNumber min={0} formatter={(n) => formatNumber(n ?? 0)} addonAfter='원' />
            </Form.Item>
            <Form.Item {...formLayout.tail}>
              <Button type='primary' htmlType='submit'>
                변경
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <div>
        <h2>긴급 설정</h2>
        <div className={classes.formContainer}>
          <Form form={urgentForm} {...formLayout.content} layout='horizontal' onFinish={onSubmitUrgent}>
            <Form.Item
              name='isClosed'
              label='서비스 일시 중지'
              valuePropName='checked'
              initialValue={commerceConf.closed.isClosed}>
              <Switch />
            </Form.Item>
            <Form.Item name='msgClosed' label='서비스 일시 중지 메시지' initialValue={commerceConf.closed.msgClosed}>
              <Input />
            </Form.Item>
            <Form.Item {...formLayout.tail}>
              <Button type='primary' htmlType='submit'>
                변경
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AppConfig;
