import { ProductDoc, UserDoc } from '@gooduncles/gu-app-schema';

import { getOrderForStore, getUser, getUsersWithWhere } from '../4/firebase-short-cut';

/**
 * Noti설정이 on인 사용자 목록을 가져온다.
 * @param userIds 사용자 id 목록. null인 경우 모두 가져온다.
 */
export const getAllowedNotiUsers = async (userIds: string[] | null) => {
  if (userIds === null) {
    return getUsersWithWhere([['kakaoNotification', '==', true]]);
  }
  // 1. 사용자 정보 조회
  const users = await Promise.all(
    userIds.map(async (id) => {
      const user = await getUser(id);
      return user;
    })
  );
  // 2. 사용자 정보가 없는 경우 제거
  const filteredUsers = users.filter((user) => user?.kakaoNotification === true) as UserDoc[];
  return filteredUsers;
};

/**
 * 관심 상품 목록 조회
 * 가격공유 품목코드 Y 중에서 1번과 2번의 합집합
 * 1. 최근 2개월 이내 주문한 적이 있는 품목
 * 2. 즐겨찾기 포함 품목
 * @param user
 * @param month
 * @param products 알림 대상 품목 목록
 * @returns [userId, interestProductList]
 */
export const getInterestProductListForUser = async (
  user: UserDoc,
  month: number,
  products: ProductDoc[]
): Promise<[UserDoc, ProductDoc[] | undefined]> => {
  try {
    // 1. 최근 n개월(default: 2개월) 이내 주문한 적이 있는 품목
    const orders = await getOrderForStore(user.storeId, month);
    const orderProductList = [
      ...new Set(
        orders
          .map((order) => order.products)
          .flat()
          .map((p) => p.productId)
      ),
    ];
    const favoriteProducts = user.favoriteProducts ?? [];
    const interestProductList = [...new Set([...orderProductList, ...favoriteProducts])];
    const filteredProductList = products.filter((p) => interestProductList.includes(p._id));
    return [user, filteredProductList.length > 0 ? filteredProductList : undefined];
  } catch (error) {
    console.error(error);
    return [user, undefined];
  }
};

/**
 * 알림톡, 친구톡 메시지는 모두 1000자 이하로 제한되어 있으며
 * 현재 header와 footer 영역으로 고정 80정도를 사용하고 있다.
 * 이와 더불어 알림톡 특성상 14의 배수로 끊어야 하므로
 * 910(14*65)자 이하로 메시지를 분할한다.
 */
export const messageDivider = (words: string[], msgMaxLength = 910) => {
  const result = [];
  let message = '';
  for (const [i, word] of words.entries()) {
    if (message.length + word.length < msgMaxLength - 2) {
      message += word + '\n';
      if (i === words.length - 1) {
        result.push(message);
      }
    } else {
      result.push(message);
      message = '';
    }
  }
  return result;
};
