import { Competitor, CompetitorProductList } from '@gooduncles/gu-app-schema';
import { Select, message } from 'antd';
import { FC, useCallback, useEffect, useMemo, useRef } from 'react';

import { errorObjectToString } from 'src/lib/1/util';
import { createCompetitorProductMapping } from 'src/lib/4/firebase-short-cut';

interface CompetitorProductSelectorProps {
  competitor: Competitor;
  competitorProductList: CompetitorProductList | null;
  productId: string;
  selectedProductName: string | null;
  stopEditing: () => void;
}

const CompetitorProductSelector: FC<CompetitorProductSelectorProps> = ({
  competitor,
  competitorProductList,
  productId,
  selectedProductName,
  stopEditing,
}) => {
  const selectRef = useRef<any>(null);
  const [messageApi, contextHolder] = message.useMessage();
  const productList = useMemo(() => competitorProductList?.productList ?? {}, [competitorProductList]);

  const onProductSearch = useCallback(
    async (
      _: string,
      option:
        | {
            label: string;
            value: string;
          }
        | {
            label: string;
            value: string;
          }[]
    ) => {
      const key = 'createCompetitorProductPriceTrend';
      try {
        if (!option) {
          throw new Error(`${competitor} 상품 정보를 찾지 못했습니다.`);
        }
        const selectedOption = Array.isArray(option) ? option[0] : option;
        await createCompetitorProductMapping({
          competitor,
          guProductId: productId,
          productName: selectedOption.label,
          productId: selectedOption.value,
        });
        stopEditing();
      } catch (error) {
        console.error(error);
        const description = errorObjectToString(error);
        messageApi.open({
          key,
          type: 'error',
          content: description,
        });
      }
    },
    [competitor, messageApi, productId, stopEditing]
  );

  useEffect(() => {
    if (selectRef?.current) {
      selectRef.current.focus();
    }
  }, [selectRef]);

  return (
    <>
      {contextHolder}
      <Select
        showSearch
        ref={selectRef}
        loading={Object.keys(productList).length === 0}
        placeholder={`${competitor} 상품 선택`}
        size={'large'}
        value={selectedProductName ?? null}
        onChange={onProductSearch}
        style={{ width: '300px' }}
        options={Object.entries(productList).map(([name, id]) => {
          return { label: name, value: id };
        })}
        filterOption={(input, option) => ((option?.label ?? '') as string).includes(input)}
      />
    </>
  );
};

export default CompetitorProductSelector;
