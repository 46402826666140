import { CalculatorOutlined } from '@ant-design/icons';
import {
  PaymentItem,
  PaymentStatusCode,
  StoreDoc,
  paymentMethods,
  paymentStatusCodeKrMap,
  paymentVendors,
} from '@gooduncles/gu-app-schema';
import { Button, DatePicker, Form, Input, InputNumber, Select, notification } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useAuth } from 'src/stores/auth-context';

import { requiredRule } from 'src/lib/1/constant';
import { formatDate } from 'src/lib/1/date-util';
import { errorObjectToString, formatNumber } from 'src/lib/1/util';
import { createPaymentItem, getStore } from 'src/lib/4/firebase-short-cut';
import { calculatePreTaxPrice } from 'src/lib/5/order-util';

import useStoresForSearch from 'src/hooks/useStoresForSearch';

import classes from './PaymentItemForm.module.scss';

const initialValues: Omit<PaymentItem, 'transactionDate' | 'paymentStatus' | 'createdBy'> & {
  transactionDate: dayjs.Dayjs;
  paymentStatus: string;
} = {
  storeId: null,
  storeName: null,
  paymentStatus: PaymentStatusCode.COMPLETED.toString(),
  paymentMethod: '카드',
  paymentVendor: '페이앱',
  amount: 0,
  supplyPrice: 0,
  tax: 0,
  payerInfo: '',
  extraInfo: '',
  transactionDate: dayjs(),
  settledAt: null,
  settlementResultId: null,
  deletedAt: null,
};

const PaymentItemForm: FC = () => {
  const { authUser } = useAuth();
  const email = useMemo(() => (authUser ? authUser.email : null), [authUser]);
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const [isValiad, setIsValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const { stores } = useStoresForSearch();
  const [store, setStore] = useState<StoreDoc | null>(null);
  const [date, setDate] = useState<dayjs.Dayjs | null>(null);
  const disabledDate: RangePickerProps['disabledDate'] = useCallback(
    (current: dayjs.Dayjs) =>
      store?.latestSettledAt ? current.isBefore(dayjs(store?.latestSettledAt).endOf('day')) : false,
    [store?.latestSettledAt]
  );

  const onReset = useCallback(() => {
    form.resetFields();
    setStore(null);
  }, [form]);

  const onFinish = useCallback(
    async (values: PaymentItem) => {
      setLoading(true);
      try {
        if (!email) throw new Error('로그인이 필요합니다.');
        if (!store) throw new Error('가게를 선택해주세요.');
        if (!values.transactionDate) throw new Error('결제 일시를 선택해주세요.');
        const { _id, storeNickname } = store;
        const paymentStatus = Number(values.paymentStatus);
        const amount = Number(values.amount);
        const supplyPrice = Number(values.supplyPrice);
        const tax = Number(values.tax);
        const transactionDate = formatDate(values.transactionDate, "yyyy-MM-dd'T'00:00:00+0900");
        const paymentItem: PaymentItem = {
          ...values,
          storeId: _id,
          storeName: storeNickname,
          paymentStatus,
          amount,
          supplyPrice,
          tax,
          transactionDate,
          createdBy: email,
          settledAt: null,
          settlementResultId: null,
          deletedAt: null,
        };
        await createPaymentItem(paymentItem);
        notification.success({
          message: '결제 내역 생성 성공',
        });
        onReset();
      } catch (error) {
        console.error(error);
        notification.error({
          message: errorObjectToString(error),
        });
      }
      setLoading(false);
    },
    [email, onReset, store]
  );

  const onSelectStore = useCallback(async (id: string) => {
    setLoading(true);
    const store = await getStore(id);
    if (!store) {
      console.error('가게를 찾을 수 없습니다.');
      notification.error({
        message: '가게를 찾을 수 없습니다.',
      });
      setStore(null);
      setLoading(false);
      return;
    }
    setStore(store);
    setLoading(false);
  }, []);

  const calculateValueAddedTax = useCallback(() => {
    const amount = form.getFieldValue('amount');
    const { price, tax } = calculatePreTaxPrice(amount);
    form.setFieldsValue({ supplyPrice: price, tax });
  }, [form]);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setIsValid(!!store))
      .catch(() => setIsValid(false));
  }, [form, store, values]);

  return (
    <div className={classes.formBox}>
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        initialValues={initialValues}
        onFinish={onFinish}>
        <h3>수동입력</h3>
        <Form.Item label='매장'>
          <Select
            showSearch
            loading={loading}
            placeholder='매장 선택'
            size='middle'
            value={store?._id ?? null}
            onChange={onSelectStore}
            options={(stores ?? []).map((store) => {
              const label = store.storeCode ? `${store.storeCode} - ${store.storeNickname}` : store.storeNickname;
              return { label, value: store._id };
            })}
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
          />
        </Form.Item>
        <Form.Item label='결제 상태' name='paymentStatus' rules={requiredRule}>
          <Select options={Object.entries(paymentStatusCodeKrMap).map(([value, label]) => ({ label, value }))} />
        </Form.Item>
        <Form.Item label='결제 일시' name='transactionDate' rules={requiredRule}>
          <DatePicker
            format='YYYY-MM-DD HH:mm:ss'
            onChange={setDate}
            showTime
            value={date}
            disabledDate={disabledDate}
          />
        </Form.Item>
        <Form.Item label='결제 수단' name='paymentMethod' rules={requiredRule}>
          <Select options={paymentMethods.map((v) => ({ label: v, value: v }))} />
        </Form.Item>
        <Form.Item label='결제처' name='paymentVendor' rules={requiredRule}>
          <Select options={paymentVendors.map((v) => ({ label: v, value: v }))} />
        </Form.Item>
        <Form.Item label='결제 금액' name='amount' rules={requiredRule}>
          <InputNumber
            formatter={(v) => formatNumber(v ?? 0)}
            addonAfter={<CalculatorOutlined onClick={calculateValueAddedTax} />}
          />
        </Form.Item>
        <Form.Item label='공급가액' name='supplyPrice'>
          <InputNumber formatter={(v) => formatNumber(v ?? 0)} />
        </Form.Item>
        <Form.Item label='부가세' name='tax'>
          <InputNumber formatter={(v) => formatNumber(v ?? 0)} />
        </Form.Item>
        <Form.Item label='결제자' name='payerInfo'>
          <Input />
        </Form.Item>
        <Form.Item label='기타정보' name='extraInfo'>
          <Input />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 18, offset: 6 }}>
          <div className='flexRow'>
            <Button type='primary' htmlType='submit' disabled={!isValiad}>
              결제 내역 수동 생성
            </Button>
            <Button disabled={!isValiad} onClick={onReset}>
              초기화
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default PaymentItemForm;
