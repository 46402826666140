import { GarakMarketProductItem, GarakMarketProductListDoc } from '@gooduncles/gu-app-schema';
import { Select, message } from 'antd';
import { FC, useCallback, useEffect, useMemo, useRef } from 'react';

import { errorObjectToString } from 'src/lib/1/util';
import { createGarakMarketProductPriceTrend } from 'src/lib/4/firebase-short-cut';

import { ProductPriceMappingTableRowdata } from '../ProductPriceMapping/ProductPriceMappingTable';

interface GarakMarketProductSelectorProps {
  garakMarketProductList: GarakMarketProductListDoc | null;
  productId: string;
  garakMarketProduct: ProductPriceMappingTableRowdata['garakMarket'] | null;
  stopEditing: () => void;
}

const GarakMarketProductSelector: FC<GarakMarketProductSelectorProps> = ({
  garakMarketProductList,
  productId,
  garakMarketProduct,
  stopEditing,
}) => {
  const selectRef = useRef<any>(null);
  const [messageApi, contextHolder] = message.useMessage();
  const productList = useMemo(() => garakMarketProductList?.productList ?? [], [garakMarketProductList]);

  const setSelectedProductName = useCallback(
    async (selectedGMProduct: GarakMarketProductItem | null) => {
      const key = 'createGarakMarketProductPriceTrend';
      try {
        if (!selectedGMProduct) {
          throw new Error('가락시장 상품 정보를 찾지 못했습니다.');
        }
        messageApi.open({
          key,
          type: 'loading',
          content: '가락시장 상품 정보를 추가 중입니다.',
        });
        await createGarakMarketProductPriceTrend(productId, {
          _id: productId,
          ...selectedGMProduct,
          multFactor: 1,
          prices: {},
        });
        messageApi.open({
          key,
          type: 'success',
          content: '가락시장 상품 정보를 추가했습니다.',
        });
        stopEditing();
      } catch (error) {
        console.error(error);
        const description = errorObjectToString(error);
        messageApi.open({
          key,
          type: 'error',
          content: description,
        });
      }
    },
    [messageApi, productId, stopEditing]
  );

  const onProductSearch = useCallback(
    (value: string) => {
      const selectedProduct = productList.find((product) => product.fullName === value) ?? null;
      setSelectedProductName(selectedProduct);
    },
    [productList, setSelectedProductName]
  );

  useEffect(() => {
    if (selectRef?.current) {
      selectRef.current.focus();
    }
  }, [selectRef]);

  return (
    <>
      {contextHolder}
      <Select
        showSearch
        ref={selectRef}
        loading={productList.length === 0}
        placeholder='가락시장 상품 선택'
        size={'large'}
        disabled={!!garakMarketProduct?.fullName}
        value={garakMarketProduct?.fullName ?? null}
        onChange={onProductSearch}
        style={{ width: '300px' }}
        options={productList.map((product) => {
          return { label: product.fullName, value: product.fullName };
        })}
        filterOption={(input, option) => ((option?.label ?? '') as string).includes(input)}
      />
    </>
  );
};

export default GarakMarketProductSelector;
