import { Popover } from 'antd';

import { AlimtalkMessageResult } from 'src/lib/3/schema-alimtalk';
import { FriendtalkMessageResult } from 'src/lib/3/schema-friendtalk';

import classes from './AlimtalkContentPopover.module.scss';

type Buttons =
  | NonNullable<AlimtalkMessageResult['messageResult']>['buttons']
  | NonNullable<FriendtalkMessageResult['messageResult']>['buttons'];

const KakaotalkContentPopover = (props: any) => {
  const content: string = props.value;
  const buttons: Buttons = props.buttons ?? [];
  const { prefix, suffix } = props;

  if (!content) return null;
  return (
    <Popover
      placement='rightTop'
      color='#bacee1'
      overlayInnerStyle={{ backgroundColor: '#bacee1' }}
      content={
        <div className={classes.whiteBoard}>
          <p>
            {prefix ?? ''}
            {content}
            {suffix ?? ''}
          </p>
          {buttons.length > 0 && (
            <div className={classes.alimtalkButtons}>
              {buttons.map((button) => {
                return (
                  <a key={button.name + button.ordering} target='_blank' rel='noreferrer' href={button.linkMo}>
                    {button.name}
                  </a>
                );
              })}
            </div>
          )}
        </div>
      }>
      <span>{content}</span>
    </Popover>
  );
};

export default KakaotalkContentPopover;
